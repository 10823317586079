import React, { useState } from "react";
import Select from "react-select-me";
import makeVirtualized from "react-select-me/lib/hoc/makeVirtualized";
import { Label } from "semantic-ui-react";
import styles from "./style/DropdownSelectMe.module.css";

const VirtualizedSelect = makeVirtualized(Select);

const DropdownSelectMe = ({
  placeholder = "",
  labelText = "",
  options,
  isSearchable = true,
  listPosition = "bottom",
  listMaxHeight,
  virtualized = true,
  value,
  onChange = function() {},
  fieldName,
  parameters
}) => {
  const [optionsLocaly, setOptionsLocaly] = useState(options);

  const handleSearch = searchString => {
    if (searchString) {
      let filtteredOptions = options.filter(item =>
        item.label.toLowerCase().includes(searchString)
      );
      setOptionsLocaly(filtteredOptions);
    } else {
      setOptionsLocaly(options);
    }
  };

  return (
    <>
      <label>{labelText}</label>
      <div
        className={`${styles.mu_select_dd} ${
          !value || value === "" ? styles.dd__placeholder : ""
        } ${parameters && parameters[fieldName] ? styles.dd__error : ""}`}
      >
        <VirtualizedSelect
          searchable={isSearchable}
          onSearch={handleSearch}
          options={optionsLocaly}
          listPosition={listPosition}
          listMaxHeight={listMaxHeight}
          virtualized={virtualized}
          value={value ? value : undefined}
          placeholder={placeholder}
          onChange={onChange}
          searchClearOnClose={true}
        />
      </div>
      {parameters && parameters[fieldName] && (
        <Label basic color="red" pointing content={parameters[fieldName]} />
      )}
    </>
  );
};

export default DropdownSelectMe;
