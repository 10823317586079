import React, { useEffect } from "react";
import Localize from "react-intl-universal";
import { Dimmer, Loader } from "semantic-ui-react";
import useAccountingServices from "../../RestServicesNew/useAccountingServices";
import styles from "./CustomAccountingSystem.module.css";
import CustomSystemForm from "./CustomSystemForm";
import GlobalHederRightPanel from "../../Components/Details/GlobalHederRightPanel";

const CustomAccountingSystem = ({ currentView, setCurrentView }) => {
  const {
    customChartAccountsList,
    fetchCustomChartLoading,
    fetchCustomChartCall
  } = useAccountingServices(setCurrentView, currentView);

  useEffect(() => {
    currentView === "details" && fetchCustomChartCall();
  }, [currentView]);

  return (
    <div>
      <Dimmer active={fetchCustomChartLoading} inverted>
        <Loader size="large" disabled={!fetchCustomChartLoading}>
          {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
        </Loader>
      </Dimmer>

      {currentView === "details" ? (
        <>
          <div>
            <GlobalHederRightPanel
              selectedItem={
                customChartAccountsList && customChartAccountsList.length > 0
                  ? { id: 3 }
                  : undefined
              }
              disabledIcons={["delete"]}
              currentView={currentView}
              setCurrentView={setCurrentView}
              formHeaderText={"SETTINGS.SIDEBAR_OPTIONS.EDIT_ACCOUNT"}
              detailsHeaderText={"SETTINGS.HEADERS.ACCOUNTING_DETAILS"}
            />
          </div>
          <div className={styles.custom_system_details}>
            {customChartAccountsList &&
              customChartAccountsList.length > 0 &&
              customChartAccountsList.map((account, index) => {
                const { accountKey, description, enabled } = account;
                return <div key={index}>{enabled ? `${accountKey} ${description}` : null}</div>;
              })}
          </div>
        </>
      ) : (
        <CustomSystemForm setCurrentView={setCurrentView} />
      )}
    </div>
  );
};

export default CustomAccountingSystem;
