import { useState, useEffect } from "react";
import useIsMounted from "./useIsMounted";

function useFetch(
  fetchFn,
  initType = [],
  customPath,
  parameters,
  customCBSuccess,
  customCBError,
  responseType
) {
  const [isMounted] = useIsMounted();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [data, setData] = useState(initType);
  const [paging, setPaging] = useState({
    perPage: 0,
    pageCount: 0,
    totalCount: 0
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      if (shouldFetch) {
        try {
          setLoading(true);
          const response = await fetchFn(customPath, parameters, responseType);
          if (isMounted && response) {
            if (response.data) {
              setData(response.data);
            }
            if (response.data && response.data.paging) {
              setPaging(response.data.paging);
            } else if (response && response.paging) {
              setPaging(response.paging);
            } else {
              setPaging({ perPage: 0, pageCount: 0, totalCount: 0 });
            }
            if (customCBSuccess) {
              customCBSuccess(response, setData, setPaging);
            }
          }
        } catch (err) {
          if (isMounted && customCBError) {
            if (responseType === "blob") {
              let responseObj = await err.response.data.text();
              customCBError(JSON.parse(responseObj));
            } else {
              customCBError(err);
            }
          }
        } finally {
          if (isMounted) {
            setLoading(false);
            setShouldFetch(false);
          }
        }
      }
    }
    fetchData();
  }, [shouldFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, paging, loading, setShouldFetch.bind(null, true), setData];
}

export default useFetch;
