import React, { useState, useEffect } from "react";
import { Menu, Grid, Message, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DatevListItem from "./DatevListItem";
import * as RestService from "../RestService";
import { formatDateDisplay } from "./Util";
import styles from "./style/DatevListStatus.module.css";

const DatevListStatus = () => {
  const [fileExports, setFileExports] = useState([]);

  const callbackGetAllSuccess = response => {
    setFileExports(
      response.data.map(c => (
        <DatevListItem
          key={c.id}
          id={c.id}
          fileName={c.fileName}
          fromDate={formatDateDisplay(c.fromDate)}
          toDate={formatDateDisplay(c.toDate)}
          packageCreationDate={formatDateDisplay(c.packageCreationDate)}
        />
      ))
    );
  };

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = () => {};

  const resolveServiceCall = () => {
    const exportFileService = new RestService.Document.GetAllPackages.Service();
    exportFileService.setCallbackSuccess(callbackGetAllSuccess);
    exportFileService.setCallbackError(callbackGetAllError);
    exportFileService.setCallbackFinally(callbackGetAllFinally);
    return exportFileService;
  };

  useEffect(() => {
    resolveServiceCall().call();
  }, []);

  return (
    <>
      <div className={styles.middle_panel}>
        <div className={styles.header}>
          <Menu.Item>
            <Grid className={styles.header_grid} columns="6" verticalAlign="middle">
              <Grid.Column width={1} className={styles.id_header}>
                <div className={styles.list_name}>ID</div>
              </Grid.Column>
              <Grid.Column width={4} className={styles.file_name_header}>
                <div className={styles.list_name}>{Localize.get("EXPORT.DATEV.HEADER_NAME")}</div>
              </Grid.Column>
              <Grid.Column width={3} className={styles.start_export_date_header}>
                <div className={styles.list_name}>{Localize.get("EXPORT.DATEV.HEADER_FROM")}</div>
              </Grid.Column>
              <Grid.Column width={3} className={styles.end_export_date_header}>
                <div className={styles.list_name}>{Localize.get("EXPORT.DATEV.HEADER_TO")}</div>
              </Grid.Column>
              <Grid.Column width={3} className={styles.creation_date_header}>
                <div className={styles.list_name}>
                  {Localize.get("EXPORT.DATEV.HEADER_CREATION_DATE")}
                </div>
              </Grid.Column>
              <Grid.Column width={2} className={styles.download_header}>
                <div className={styles.list_name}>
                  {Localize.get("EXPORT.DATEV.HEADER_DOWNLOAD")}
                </div>
              </Grid.Column>
            </Grid>
          </Menu.Item>
        </div>
        {fileExports.length !== 0 ? (
          <div className={styles.middle_panel_list}>{fileExports}</div>
        ) : (
          <Message icon>
            <Icon name="info circle" />
            {Localize.get("EXPORT.DATEV.EMPTY_LIST")}
          </Message>
        )}
      </div>
    </>
  );
};

export default DatevListStatus;
