import React, { useState, useEffect, useContext } from "react";
import GlobalDetails from "../../Components/Panels/GlobalDetails";
import GlobalForm from "../../Components/Panels/GlobalForm";
import PropertiesForm from "./PropertiesForm";
import PropertiesDetails from "./PropertiesDetails";
import * as property from "./PropertiesConfig";
import Localize from "react-intl-universal";
import { AuthContext } from "../../Auth/AuthContext";

const PropertiesRightPanel = props => {
  const { customConfig } = useContext(AuthContext);
  const { currentView, setCurrentView, currentModule, selectedItem } = props;
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [errorFields, setErrorFields] = useState({});

  const getDetailsConfig = () => {
    let detailsConfig;
    if (customConfig.settings.hiddenSubcategories.includes(selectedItem.name.subcategoryName)) {
      detailsConfig = [];
    } else {
      detailsConfig = property[selectedItem.name.subcategoryName];
    }
    return detailsConfig;
  };

  const myErrorHander = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorResponse = error.response.data;
      let errorObj = {};
      // eslint-disable-next-line array-callback-return
      errorResponse.map(item => {
        errorObj[item.validationSubject] = Localize.get(item.validationMessage);
      });
      setErrorFields(errorObj);
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };

  useEffect(() => {
    setErrorFields(prevPara => {
      return {
        ...Object.keys(prevPara).reduce((reduced, key) => ({ ...reduced, [key]: "" }), {})
      };
    });
  }, [currentView]);

  return (
    <>
      {selectedItem && currentView === "details" ? (
        <GlobalDetails
          selectedItem={selectedItem}
          currentView={currentView}
          currentModule={currentModule}
          setCurrentView={setCurrentView}
          detailsHeaderText={"SETTINGS.HEADERS.PROP_DETAILS"}
          availableItemFieldsForDetails={getDetailsConfig()}
          disabledIcons={selectedProperties.length > 0 ? ["delete"] : ["delete", "edit"]}
          CustomDetails={PropertiesDetails}
          customProps={{
            selectedProperties,
            setSelectedProperties,
            setCurrentView
          }}
        />
      ) : null}
      {currentView === "edit" || currentView === "create" ? (
        <GlobalForm
          selectedItem={selectedProperties}
          setCurrentView={setCurrentView}
          currentView={currentView}
          currentModule={currentModule}
          formHeaderText={"SETTINGS.HEADERS.EDIT_PROP"}
          availableItemFieldsForDetails={getDetailsConfig()}
          CustomForm={PropertiesForm}
          customErrorHandler={myErrorHander}
          parameters={errorFields}
        />
      ) : null}
    </>
  );
};
export default PropertiesRightPanel;
