import * as constant from "./Constants.js";
import Localize from "react-intl-universal";
import { subMonths } from "date-fns";

export const getUniqueAccounts = nTransactions => {
  const bankAccounts = [];
  bankAccounts.push({
    key: constant.bankAccount.all,
    text: Localize.get("TRANSACTIONS.ITEMLIST.ALLACCOUNTS"),
    value: constant.bankAccount.all
  });
  for (let i = 0; i < nTransactions.length; i++) {
    const bankAccount = {};
    bankAccount.bankname = nTransactions[i].bankAccount.bankName;
    bankAccount.accountowner = nTransactions[i].bankAccount.accountOwner;
    bankAccount.id = nTransactions[i].bankAccount.id;
    bankAccount.key = i;
    bankAccount.text = nTransactions[i].bankAccount.bankName;
    bankAccount.value = nTransactions[i].bankAccount.id;
    bankAccounts.push(bankAccount);
  }

  return bankAccounts.reduce(
    (x, y) => (x.findIndex(e => e.text === y.text) < 0 ? [...x, y] : x),
    []
  );
};

export const getInitBankAccountOption = () => ({
  key: 0,
  text: Localize.get("TRANSACTIONS.ITEMLIST.ALLACCOUNTS"),
  value: "All Accounts"
});

export const convert2Transactions = nTransactions => {
  const bankTransactions = [];

  for (let i = 0; i < nTransactions.length; i++) {
    const bankTransaction = {};
    bankTransaction.id = i;
    if (nTransactions[i].id !== null) {
      bankTransaction.realId = nTransactions[i].id; // id in our database (only linked and linked without document transactions will have this)
    }
    bankTransaction.checked = false;
    bankTransaction.assignmentType = nTransactions[i].assignment;
    bankTransaction.directionType = nTransactions[i].direction;
    bankTransaction.amount = nTransactions[i].amount;
    bankTransaction.companyName = nTransactions[i].counterpartName;
    bankTransaction.bankName = nTransactions[i].counterpartBankName;
    bankTransaction.customerId = nTransactions[i].counterpartIban;
    bankTransaction.price = nTransactions[i].amount;
    bankTransaction.purpose = nTransactions[i].purpose;
    let newDate = new Date(nTransactions[i].transactionDate);
    bankTransaction.date = newDate;
    if (nTransactions[i].documents) {
      bankTransaction.documents = nTransactions[i].documents;
    } else {
      bankTransaction.documents = [];
    }
    bankTransaction.finApiId = nTransactions[i].finApiId;
    bankTransaction.bankAccount = nTransactions[i].account;

    bankTransactions.push(bankTransaction);
  }

  return bankTransactions;
};

export const convert2Invoices = nInvoices => {
  const invoices = [];

  for (let i = 0; i < nInvoices.length; i++) {
    const invoice = {};
    invoice.amount = nInvoices[i].amount;
    invoice.direction = nInvoices[i].direction;
    invoice.id = nInvoices[i].id;
    invoice.checked = false;
    invoice.verificationStatus = nInvoices[i].verificationStatus;
    invoice.approvalStatus = nInvoices[i].approvalStatus;
    invoice.documentType = nInvoices[i].documentType;
    invoice.binaryContent = {};
    invoice.binaryContent.id = nInvoices[i].binaryContent.id;
    invoice.documentNumber = nInvoices[i].documentNumber;
    invoices.push(invoice);
  }

  return invoices;
};

export function formatDate(date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const getLinkedObject = (dataInvoice, dataTransaction) => {
  const docs = dataInvoice.map(invoice => ({
    id: invoice.id,
    direction: invoice.direction,
    verificationStatus: invoice.verificationStatus,
    documentType: invoice.documentType,
    approvalStatus: invoice.approvalStatus,
    documentNumber: invoice.documentNumber
  }));

  let linked = {
    finApiId: dataTransaction.finApiId,
    account: { id: dataTransaction.bankAccount.id },
    assignment: constant.assignment.assigned,
    documents: docs
  };

  return linked;
};

export const getLinkedWithoutDocObject = dataTransaction => {
  let linkedWithoutDocument = {
    finApiId: dataTransaction.finApiId,
    account: { id: dataTransaction.bankAccount.id },
    assignment: constant.assignment.assignedWithoutDoc
  };

  return linkedWithoutDocument;
};

export const getEditedTransacionObject = dataInvoice => {
  const docs = dataInvoice.map(invoice => ({
    id: invoice.id,
    documentType: "invoice"
  }));

  return {
    documents: docs
  };
};

// today
export const getCurrentDate = () => {
  return formatDate(new Date());
};

// three months from now
export const getBackThreeMonthsFromNow = () => {
  return formatDate(subMonths(new Date(), 3));
};
