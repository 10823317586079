import React, { useEffect } from "react";
import GlobalDetails from "../../Components/Panels/GlobalDetails";
import GlobalForm from "../../Components/Panels/GlobalForm";
import UsersForm from "./UsersForm";
import UsersDetails from "./UsersDetails";
import { availableItemFieldsForDetails, emptyUserObject } from "./UserConfig";
import useValidator from "../../Utils/useValidator";
import Localize from "react-intl-universal";
import { settingsUserSchema } from "../../Utils/SettingsSchema";

const UsersRightPanel = props => {
  const {
    currentView,
    setCurrentView,
    currentModule,
    deleteItem,
    selectedItem,
    callGetAll
  } = props;

  const [parameters, , validateOnSave, validateField, setParameters] = useValidator(
    settingsUserSchema
  );

  const customProperties = {
    selectedItem: currentView === "create" ? emptyUserObject : selectedItem,
    setCurrentView,
    currentView,
    currentModule,
    availableItemFieldsForDetails
  };

  const customOnSaveValidation = currentItem => {
    let validateUser = validateOnSave(currentItem);

    if (validateUser) {
      return true;
    }
    return false;
  };

  const customOnChangeValidation = (field, value) => {
    if (parameters && parameters[field] && parameters[field] !== "") {
      validateField(field, value);
    }
  };

  const myErrorHander = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorMessages = {};
      if (error.response && error.response.data && error.response.data.errors) {
        let errorList = error.response.data.errors;
        // eslint-disable-next-line array-callback-return
        errorList.map(item => {
          errorMessages[item.field] = Localize.get(item.defaultMessage);
        });
        setParameters(prevPara => {
          return { ...prevPara, ...errorMessages };
        });
        showNotificationMessage({
          type: "warning",
          serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
        });
      } else {
        showNotificationMessage({
          type: "warning",
          serverMessage: error.response.data.message
        });
      }
    }
  };

  useEffect(() => {
    setParameters(prevPara => {
      return {
        ...Object.keys(prevPara).reduce((reduced, key) => ({ ...reduced, [key]: "" }), {})
      };
    });
  }, [currentView]);

  return (
    <>
      {selectedItem && currentView === "details" && (
        <GlobalDetails
          detailsHeaderText={"SETTINGS.HEADERS.USER_DETAILS"}
          {...customProperties}
          CustomDetails={UsersDetails}
          deleteItem={deleteItem}
        />
      )}

      {(currentView === "edit" || currentView === "create") && (
        <GlobalForm
          formHeaderText={
            currentView === "edit" ? "SETTINGS.HEADERS.EDIT_USER" : "SETTINGS.HEADERS.CREATE_USER"
          }
          {...customProperties}
          CustomForm={UsersForm}
          emptyObject={emptyUserObject}
          customOnSaveValidation={customOnSaveValidation}
          customOnChangeValidation={customOnChangeValidation}
          callGetAll={callGetAll}
          parameters={parameters}
          customErrorHandler={myErrorHander}
        />
      )}
    </>
  );
};
export default UsersRightPanel;
