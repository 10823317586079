import React, { useState, useEffect } from "react";
import GlobalList from "../../Components/Lists/GlobalList";
import { partnerListConfig } from "../../Documents/Constants";
import usePartnerServices from "../../RestServicesNew/usePartnerServices";
import { Dimmer, Loader, Divider } from "semantic-ui-react";
import Localize from "react-intl-universal";
import GlobalOkButton from "../../Components/GlobalOkBtn";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import styles from "./style/InvoiceNotifiers.module.css";
import { toggleOnlyOne, togleOne } from "../../Components/Utils/GlobalListsUtil";
import ContactListItem from "../MiddlePanel/ContactListItem";

const PartnerListFormated = ({
  parent,
  childrenArr,
  isParent,
  setShowModal,
  confirmParentPartnerModal,
  confirmChildren,
  setShowWarning,
  selectedCompanyId
}) => {
  const [contactListState, setContactListState] = useState({
    activeTypeFilter: "company",
    noParent: "true",
    noChildren: isParent ? "" : "true",
    activeRoleFilter: "",
    activeArchiveFilter: "",
    name: "",
    city: "",
    number: "",
    search: ""
  });
  const [checkedPartner, setCheckedPartner] = useState(parent);
  const [checkedPartners, setCheckedPartners] = useState(childrenArr);

  const formatFetch = (response = []) => {
    let newArr = response.filter(item => item.id !== selectedCompanyId);
    if (isParent) {
      return newArr.map(item => {
        if (parent && item.id === parent.id) {
          item.checked = true;
        }
        return item;
      });
    } else {
      let childrenArr = newArr.map(firstItem => {
        firstItem.checked = checkedPartners.some(secondItem => firstItem.id === secondItem.id);
        return firstItem;
      });
      return childrenArr;
    }
  };

  const {
    listOfPartners,
    paging,
    partnersFetchLoading,
    fetchPartnersCall,
    activePaginationPage,
    setActivePaginationPage,
    setListOfPartners
  } = usePartnerServices(contactListState, undefined, formatFetch);

  useEffect(() => {
    fetchPartnersCall();
  }, [contactListState, activePaginationPage]);

  //function used for search
  const searchFilter = (e, data, currentFilters, reset = false) => {
    setActivePaginationPage(1);
    setContactListState({ ...currentFilters, [data.name]: data.value });
  };

  //function used for sort
  const sortHandler = (fieldName, fieldValue, currentFilters) => {
    if (currentFilters.sortBy === fieldName) {
      if (currentFilters.sortDirection === fieldValue) {
        setContactListState({
          ...currentFilters,
          sortBy: "",
          sortDirection: ""
        });
      } else {
        setContactListState({
          ...currentFilters,
          sortBy: fieldName,
          sortDirection: fieldValue
        });
      }
    } else {
      setContactListState({
        ...currentFilters,
        sortBy: fieldName,
        sortDirection: fieldValue
      });
    }
  };

  const onConfirm = () => {
    if (isParent) {
      if (parent && !checkedPartner) {
        setShowWarning(true);
      } else {
        confirmParentPartnerModal(checkedPartner);
      }
    } else {
      confirmChildren(checkedPartners);
    }
  };

  const handleToggleClick = (data, item) => {
    if (isParent) {
      toggleOnlyOne(item.id, setListOfPartners);
      if (checkedPartner && checkedPartner.id === item.id) {
        setCheckedPartner(null);
      } else {
        setCheckedPartner(item);
      }
    } else {
      let newArr = [...checkedPartners];
      if (data.checked) {
        newArr.push({ ...item, checked: true });
        setCheckedPartners(newArr);
      } else {
        let filteredArr = newArr.filter(secondItem => secondItem.id !== item.id);
        setCheckedPartners(filteredArr);
      }

      togleOne(item.id, setListOfPartners);
    }
  };

  return (
    <>
      <Dimmer active={partnersFetchLoading} inverted>
        <Loader size="large" disabled={!partnersFetchLoading}>
          {Localize.get("CONTACTS.LIST.TOOLBAR.PARTNERS_LOADING")}
        </Loader>
      </Dimmer>
      <GlobalList
        listState={listOfPartners}
        availableItemFieldsForList={partnerListConfig}
        showAtBottom={true}
        searchObject={contactListState}
        filterObject={contactListState}
        paginationStatus={paging}
        activePaginationPage={activePaginationPage}
        handlePageChange={setActivePaginationPage}
        searchFilter={searchFilter}
        sortHandler={sortHandler}
        itemHandler={handleToggleClick}
        listPaginationCustomCSS={styles.item_in_list}
        CustomItemComponent={ContactListItem}
      />
      <Divider className={styles.divider_actions} />
      <div className={styles.div_actions}>
        <GlobalOkButton onOkClicked={onConfirm} />
        <GlobalCancelBtn onCancel={() => setShowModal(false)} />
      </div>
    </>
  );
};

export default PartnerListFormated;
