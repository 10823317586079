import Localize from "react-intl-universal";

export const panelMode = {
  edit: "edit",
  create: "create",
  details: "details"
};

export const initialStatusFormState = {
  id: "",
  name: "",
  status: ""
};

export const bmsStatusOptions = () => [
  {
    key: 0,
    value: "01_NEW",
    text: Localize.get("PROJECTS.STATUSES.STATUS_NEW")
  },
  {
    key: 1,
    value: "02_IN_PROCESS",
    text: Localize.get("PROJECTS.STATUSES.STATUS_PROCESS")
  },
  {
    key: 2,
    value: "03_CLOSED",
    text: Localize.get("PROJECTS.STATUSES.STATUS_CLOSED")
  }
];

export const autoComplete = {
  none: "none",
  off: "off"
};
