export const mappedPaths = {
  "/documents": "document",
  "/finances/accounts": "account",
  "/finances/transactions": "transaction",
  "/projects/management": "project",
  "/projects/roles": "resourceRole",
  "/projects/types": "projectType",
  "/projects/templates": "projectTemplate",
  "/projects/statuses": "projectStatus",
  "/contacts": "partner",
  "/bookkeeping/booking": "transaction",
  "/bookkeeping/account": "transaction"
};
