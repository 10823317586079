export const archive = {
  active: "active",
  archived: "archived"
};

export const contactType = {
  project: "project",
  person: "person",
  company: "company",
  employee: "employee",
  all: "all"
};

export const contactRole = {
  customer: "customer",
  supplier: "supplier",
  customer_supplier: "customer_supplier",
  all: "all"
};

export const contactListInitState = {
  activeListItem: undefined,
  selectAll: false,
  scrollToTop: false,
  scrollIntoView: false
};

export const panelMode = {
  details: "details",
  edit: "edit",
  create: "create"
};

export const panelInitState = {
  activeMode: panelMode.details,
  contact: undefined
};

export const searchPartnerParameter = {
  name: "name",
  number: "number",
  city: "city",
  emptyStringValue: ""
};

export const autoComplete = {
  none: "none",
  off: "off"
};

export const addressFormRequiredFields = {
  street: ["min1Character", "whitespaceBeginFilter", "longerThan64"],
  city: ["min1Character", "whitespaceBeginFilter", "longerThan64"],
  zip: ["min1Character", "onlyNumberFilter", "whitespaceBeginFilter", "longerThan12"],
  country: ["min1Character", "whitespaceBeginFilter", "longerThan64"],
  state: ["whitespaceBeginFilter", "longerThan64"]
};
