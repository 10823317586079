export const createNewStatus = data => {
  return {
    id: data.id,
    name: data.name,
    status: {
      id: data.status
    }
  };
};

export const getUpdateObject = newStatus => {
  if (newStatus) {
    return newStatus.editedFields.map(function(field) {
      let fieldNameForDB = field;
      let val = newStatus[field];

      switch (field) {
        case "status":
          val = {
            id: newStatus.status
          };

          break;
        default:
          break;
      }

      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  }

  return [];
};
