import React, { useEffect, useState } from "react";
import { Icon, Segment, Item, Popup } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/InvoiceHierarchy.module.css";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

const InvoiceHierarchy = ({ contact, isDetails, currentComponent, deleteChildClicked }) => {
  const [treeData, setTreeData] = useState();

  const getItem = (companyName, iconType, deleteIcon, index) => {
    return (
      <Item className={`${styles.item_component} ${deleteIcon ? styles.del_icon_grid : ""}`}>
        <div className={styles.text_item}>{companyName}</div>
        <div>
          {deleteIcon && (
            <Popup
              content={Localize.get("CONTACTS.DETAILS.FORM.REMOVE_COMPANY")}
              size="small"
              trigger={
                <Icon
                  className={styles.delete_icon}
                  name={deleteIcon}
                  onClick={() => deleteChildClicked(index)}
                />
              }
            />
          )}
          {iconType && (
            <Popup
              content={Localize.get("CONTACTS.NEWCONTACT.FORM.BILL_TO_PARENT")}
              size="small"
              trigger={<Icon className={styles.item_icon} name={iconType} />}
            />
          )}
        </div>
      </Item>
    );
  };

  const getChildrenCompanies = (companies = []) => {
    return companies.map((company, index) => {
      return {
        title: getItem(
          company.name,
          company.sendInvoiceToParent ? "random" : false,
          currentComponent === "children_companies" ? "minus circle" : false,
          index
        )
      };
    });
  };

  const getMyTreeData = company => {
    if (company && company.children && company.children.length > 0) {
      return [
        {
          title: getItem(company.name),
          expanded: true,
          children: company && company.children ? getChildrenCompanies(company.children) : []
        }
      ];
    } else if (company && company.parent) {
      return [
        {
          title: getItem(company.parent.name),
          expanded: true,
          children: [
            {
              title: getItem(company.name, company.sendInvoiceToParent ? "random" : false)
            }
          ]
        }
      ];
    } else {
      return [
        {
          title: company.name
        }
      ];
    }
  };

  useEffect(() => {
    setTreeData(getMyTreeData(contact));
  }, [contact]);

  const calculateHightOfTree = company => {
    let myHight = 50;
    if (company && company.children && company.children.length > 0) {
      myHight = 50 + 45 * company.children.length;
    } else if (company && company.parent) {
      myHight = 50 + 45;
    } else {
      myHight = 50;
    }
    return myHight;
  };
  return (
    <>
      <Segment.Group className={styles.segment_group}>
        <Segment className={styles.title_details}>
          <div>
            <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICE_HIERARCHY")}</strong>
          </div>
        </Segment>
        {(!isDetails && contact.parent) ||
        (contact.children && contact.children.length > 0) ||
        (isDetails && (contact.parent || (contact.children && contact.children.length > 0))) ? (
          <Segment className={styles.form_lines}>
            <div
              style={{
                height: calculateHightOfTree(contact),
                maxHeight: "350px"
              }}
            >
              <SortableTree
                className={styles.company_tree}
                treeData={treeData}
                canDrag={false}
                maxDepth={1}
                onChange={() => {}}
                rowHeight={45}
              />
            </div>
          </Segment>
        ) : (
          <Segment className={styles.form_lines}>
            <div>{Localize.get("CONTACTS.DETAILS.FORM.THIS_IS_SINGLE")}</div>
          </Segment>
        )}
      </Segment.Group>
    </>
  );
};

export default InvoiceHierarchy;
