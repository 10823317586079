import React, { useState, useEffect, useContext } from "react";
import ContactSideBar from "./LeftPanel/ContactSideBar";
import ContactList from "./MiddlePanel/ContactList";
import ContactListItem from "./MiddlePanel/ContactListItem";
import ContactPanel from "./RightPanel/ContactPanel";
import ConfirmDeleteDialog from "../Dialogs/ConfirmDeleteDialog";
import ErrorDeleteDialog from "../Dialogs/ErrorDeleteDialog.js";
import ConfirmArchiveDialog from "../Dialogs/ConfirmArchiveDialog.js";
import SelectWarningDialog from "../Dialogs/SelectWarningDialog";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";
import * as notificationConstants from "../Notifications/Constants.js";
import {
  createUpdateArchiveObject,
  createMultipleUpdateArchiveObject,
  getNewEmptyContact
} from "./Util.js";
import { Loader, Dimmer, Sidebar, Segment, Modal } from "semantic-ui-react";
import * as constant from "./Constants.js";
import { AuthContext } from "../Auth/AuthContext";
import * as RestService from "../RestService";
import styles from "./style/Contacts.module.css";
import GlobalOkCancelBtn from "../Components/GlobalOkCancelBtn";
import usePartnerServices from "../RestServicesNew/usePartnerServices";
import useFilterStates from "../CustomHooks/useFilterStates";

const CHECKALL_INIT = { 1: false };

const Contacts = props => {
  // eslint-disable-next-line no-unused-vars
  const { rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["partner"];
  const [contactListState] = useState(constant.contactListInitState);
  const [panelState, setPanelState] = useState(constant.panelInitState);
  const [activeDeleteItem, setActiveDeleteItem] = useState();
  // eslint-disable-next-line no-unused-vars
  const [deleteItemid, setDeleteItemId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showSelectWarningModal, setShowSelectWarningModal] = useState(false);
  const [deleteLinkedContactsModal, setDeleteLinkedContactsModal] = useState({
    show: false,
    listOfLinkedContacts: [],
    disableArchiveBtn: true
  });
  const [scrollListBottom, setScrollListBottom] = useState(false);
  const [scrollListTop, setScrollListTop] = useState(false);
  const [singleArchive, setSingleArchive] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [checkedItemsIds, setCheckedItemsIds] = useState([]);
  const [checkedAll, setCheckedAll] = useState(CHECKALL_INIT);

  const { setPathOnlyOneAtTime, partnersPanelState, resetPartnersFilters } = useFilterStates(
    "partners"
  );

  const {
    listOfPartners,
    paging,
    partnersFetchLoading,
    fetchPartnersCall,
    selectedContact,
    activePaginationPage,
    setActivePaginationPage,
    setListOfPartners,
    postPartnerCall,
    postLoading,
    partnerFetchLoading,
    fetchPartnerCall
  } = usePartnerServices(
    { ...contactListState, ...partnersPanelState },
    setPanelState,
    selectedId,
    setSelectedId
  );

  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const [sideBarVisiblity, setSideBarVisiblity] = useState(false);
  const settingVisibleSidebar = () => {
    setSideBarVisiblity(true);
  };
  const { setHeaderDimmed } = props;

  setHeaderDimmed(
    panelState.activeMode === "create" || panelState.activeMode === "edit" || partnersFetchLoading
  );

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const callbackDeleteError = async response => {
    handle.confirmDeleteDialog.declineClick();
    fetchPartnersCall();
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.delete
    });
  };

  const callbackDeleteSuccess = async (response, contactId, contactsLenght) => {
    if (response && response.status === 226) {
      if (partnersPanelState.activeArchiveFilter === "archived") {
        showNotificationMessage({
          serverMessage: Localize.get("CONTACTS.CONTACTS_CANNOT_BE_DELETED"),
          type: "warning",
          operation: "delete"
        });
      } else {
        let myArr = JSON.parse(response.data.message);
        setDeleteLinkedContactsModal({
          show: true,
          listOfLinkedContacts: myArr,
          disableArchiveBtn: true
        });
      }
    } else if (contactsLenght > 1) {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNERS"),
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.delete
      });
    } else {
      showNotificationMessage({
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.delete
      });
    }
    setCheckedAll(CHECKALL_INIT);
    setCheckedItemsIds([]);

    setPanelState({
      ...panelState,
      activeMode: constant.panelMode.details
    });
    if (listOfPartners.length <= checkedItemsIds.length && activePaginationPage > 0) {
      setActivePaginationPage(activePaginationPage - 1);
      setScrollListBottom(true);
    }
    fetchPartnersCall();
  };

  const callbackArchiveSuccess = async (response, contactsIds, archiveAction) => {
    if (contactsIds && contactsIds.length > 1) {
      if (archiveAction) {
        showNotificationMessage({
          entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNERS"),
          type: notificationConstants.notificationType.info,
          operation: notificationConstants.notificationOperation.unarchive
        });
      } else {
        showNotificationMessage({
          entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNERS"),
          type: notificationConstants.notificationType.info,
          operation: notificationConstants.notificationOperation.archive
        });
      }
    } else {
      if (archiveAction) {
        showNotificationMessage({
          type: notificationConstants.notificationType.info,
          operation: notificationConstants.notificationOperation.unarchive
        });
      } else {
        showNotificationMessage({
          type: notificationConstants.notificationType.info,
          operation: notificationConstants.notificationOperation.archive
        });
      }
    }
    setCheckedAll(CHECKALL_INIT);
    setCheckedItemsIds([]);
    setPanelState({
      ...panelState,
      activeMode: constant.panelMode.details
    });

    if (listOfPartners.length <= checkedItemsIds.length && activePaginationPage > 0) {
      setActivePaginationPage(activePaginationPage - 1);
      setScrollListBottom(true);
    }
    fetchPartnersCall();
  };

  const callbackGetAllError = error => {
    setShowDeleteModal(false);
    setShowArchiveModal(false);
  };

  const callbackGetAllFinally = (response, saveAndNew) => {
    fetchPartnersCall();
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  const functionForUseEffect = () => {
    fetchPartnersCall();
  };

  useEffect(() => {
    let isSubscribed = true;
    delayTimer = setTimeout(
      function() {
        if (isSubscribed && !firstBuild) {
          functionForUseEffect();
        }
      },
      partnersPanelState.search === null &&
        partnersPanelState.city === "" &&
        partnersPanelState.name === "" &&
        partnersPanelState.number === ""
        ? 0
        : 800
    );
    setFirstBild(false);

    return () => (isSubscribed = false);
  }, [
    partnersPanelState.search,
    partnersPanelState.city,
    partnersPanelState.name,
    partnersPanelState.number
  ]);

  useEffect(() => {
    clearTimeout(delayTimer);
    functionForUseEffect();
  }, [
    partnersPanelState.activeArchiveFilter,
    partnersPanelState.activeTypeFilter,
    partnersPanelState.activeRoleFilter,
    partnersPanelState.sortColumn,
    partnersPanelState.sortDirection,
    partnersPanelState.activeRoleFilter,
    partnersPanelState.activeNotification,
    activePaginationPage
  ]);

  const deleteSelectedContact = async (contactId, constIds, contactsLenght) => {
    let serviceDelete = new RestService.Partner.Delete.Service(contactId);

    serviceDelete.setCallbackSuccess(res => {
      callbackDeleteSuccess(res, constIds, contactsLenght);
    });
    serviceDelete.setCallbackError(callbackDeleteError);
    serviceDelete.setCallbackFinally(callbackGetAllFinally);
    await serviceDelete.call();
  };

  const archiveContactFromDetails = contactToArchive => {
    if (!contactToArchive) {
      return;
    }

    let partnerUpdateService = null;
    let updatedObject = null;

    switch (contactToArchive.dtype) {
      case constant.contactType.company:
        partnerUpdateService = new RestService.Company.Update.Service(contactToArchive.id);
        break;
      case constant.contactType.person:
        partnerUpdateService = new RestService.Person.Update.Service(contactToArchive.id);
        break;
      case constant.contactType.employee:
        partnerUpdateService = new RestService.Employee.Update.Service(contactToArchive.id);
        break;
      default:
        break;
    }

    updatedObject = createUpdateArchiveObject(contactToArchive.archived);

    partnerUpdateService.setPayload(updatedObject);
    partnerUpdateService.setCallbackError(callbackGetAllError);
    partnerUpdateService.setCallbackSuccess(res => {
      callbackArchiveSuccess(res, [contactToArchive.id], contactToArchive.archived);
    });
    partnerUpdateService.setCallbackFinally(callbackGetAllFinally);
    partnerUpdateService.call();
  };

  const archiveMultipleSelectedContacts = (contactsIds, archiveAction) => {
    let serviceArchive = new RestService.Partner.Update.Service();
    const updatedObject = createMultipleUpdateArchiveObject(contactsIds, archiveAction);

    serviceArchive.setPayload(updatedObject);
    serviceArchive.setCallbackSuccess(res => {
      callbackArchiveSuccess(res, contactsIds, archiveAction);
    });
    serviceArchive.setCallbackError(callbackGetAllError);
    serviceArchive.setCallbackFinally(callbackGetAllFinally);
    serviceArchive.call();
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo.activePage);
    setScrollListTop(true);
  };

  const handle = {
    contactPanel: {
      details: {
        onEditClick: contact => {
          if (selectedContact) {
            setPanelState({
              ...panelState,
              activeMode: constant.panelMode.edit
            });
          } else {
            setShowSelectWarningModal(true);
          }
        },
        onArchiveClick: contactToArchive => {
          if (selectedContact) {
            setListOfPartners(
              listOfPartners.map(contact => {
                contact.checked = false;
                return contact;
              })
            );
            setShowArchiveModal(true);
            setSingleArchive(true);
          } else {
            setShowSelectWarningModal(true);
          }
        },
        onDeleteClick: contactToDelete => {
          if (selectedContact) {
            setListOfPartners(
              listOfPartners.map(contact => {
                contact.checked = false;
                return contact;
              })
            );
            setActiveDeleteItem(contactToDelete);
            setDeleteItemId(contactToDelete.id);
            setShowDeleteModal(true);
          } else {
            setShowSelectWarningModal(true);
          }
        }
      }
    },
    contactHeader: {
      onResetToolbarSearch: () => {
        setPathOnlyOneAtTime({
          ...partnersPanelState,
          search: ""
        });
      },
      onSearchChange: async (e, data, reset = false) => {
        clearTimeout(delayTimer);
        setPathOnlyOneAtTime({
          ...partnersPanelState,
          [data.name]: data.value
        });
        setActivePaginationPage(1);
      },

      onSortButtonClick: async (field, direction) => {
        const sortColumn = field.toLowerCase();
        setPathOnlyOneAtTime({
          ...partnersPanelState,
          sortColumn: sortColumn,
          sortDirection: direction
        });
        setActivePaginationPage(1);
      }
    },
    contactSideBar: {
      onNotificationClick: () => {
        setPathOnlyOneAtTime({
          ...partnersPanelState,
          activeNotification: !partnersPanelState.activeNotification
        });
        setActivePaginationPage(1);
      },
      onNewContactButtonClick: (event, data) => {
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.create
        });
        setSideBarVisiblity(false);
      },

      onTypeFilterClick: async typeFilter => {
        setPathOnlyOneAtTime({
          ...partnersPanelState,
          activeTypeFilter:
            partnersPanelState.activeTypeFilter === typeFilter
              ? constant.contactType.all
              : typeFilter
        });
        setActivePaginationPage(1);
      },

      onRoleFilterClick: async roleFilter => {
        if (partnersPanelState.activeRoleFilter !== constant.contactRole.customer_supplier) {
          if (partnersPanelState.activeRoleFilter.includes(constant.contactRole.customer)) {
            if (roleFilter !== constant.contactRole.customer) {
              roleFilter = "customer_" + roleFilter;
            }
          } else {
            if (partnersPanelState.activeRoleFilter.includes(constant.contactRole.supplier)) {
              if (roleFilter !== constant.contactRole.supplier) {
                roleFilter = roleFilter + "_supplier";
              }
            }
          }
        } else {
          if (roleFilter === constant.contactRole.customer) {
            roleFilter = constant.contactRole.supplier;
          } else {
            roleFilter = constant.contactRole.customer;
          }
        }

        setPathOnlyOneAtTime({
          ...partnersPanelState,
          activeRoleFilter:
            partnersPanelState.activeRoleFilter === roleFilter
              ? constant.contactType.all
              : roleFilter
        });
        setActivePaginationPage(1);
      },

      onArchiveFilterClick: async archiveFilter => {
        partnersPanelState.activeArchiveFilter !== "archived"
          ? setPathOnlyOneAtTime({
              ...partnersPanelState,
              activeArchiveFilter: archiveFilter
            })
          : setPathOnlyOneAtTime({
              ...partnersPanelState,
              activeArchiveFilter: ""
            });

        setActivePaginationPage(1);
      }
    },
    contactList: {
      onListItemClick: (itemId, item) => {
        setSelectedId(itemId);
        fetchPartnerCall();
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.details
        });
        setDeleteItemId(itemId);
      },
      onToggleAll: () => {
        let listIds = listOfPartners.map(el => el.id);
        if (checkedAll[activePaginationPage]) {
          let checkedIds = [...checkedItemsIds];
          let deleted = checkedIds.filter(el => !listIds.includes(el));
          setCheckedItemsIds(deleted);
        } else {
          let checked = [...checkedItemsIds, ...listIds];
          setCheckedItemsIds(checked);
        }
        setCheckedAll(prevState => ({
          ...prevState,
          [activePaginationPage]: !prevState[activePaginationPage]
        }));
      },
      onToggleItem: data => {
        let ids = [...checkedItemsIds];
        if (checkedItemsIds.some(el => el === data.item_id)) {
          ids = checkedItemsIds.filter(el => el !== data.item_id);
        } else {
          ids.push(data.item_id);
        }
        setCheckedItemsIds(ids);
        setDeleteItemId(data.item_id);
      },
      onArchiveClick: (e, data) => {
        if (checkedItemsIds.length > 0) {
          setShowArchiveModal(true);
          setSingleArchive(false);
        }
      },
      onClearClick: (e, data) => {
        clearCheckedAll();
        resetPartnersFilters();
        setCheckedAll(CHECKALL_INIT);
        setCheckedItemsIds([]);
        setActivePaginationPage(1);
      },

      onDeleteClick: e => {
        if (checkedItemsIds.length > 0) {
          setShowDeleteModal(true);
        }
      }
    },
    errorDeleteDialog: {
      handleOnConfirmButtonClick: () => {
        setShowModalError(false);
      }
    },
    confirmArchiveDialog: {
      declineClicked: () => {
        setShowArchiveModal(false);
      },
      confirmClicked: () => {
        setShowArchiveModal(false);
        if (singleArchive) {
          if (selectedContact) {
            archiveContactFromDetails(
              listOfPartners.filter(contact => selectedContact.id === contact.id)[0]
            );
          }
        } else {
          if (checkedItemsIds.length > 0) {
            archiveMultipleSelectedContacts(checkedItemsIds, listOfPartners[0].archived);
          }
        }
        setSingleArchive(null);
        setDeleteLinkedContactsModal({
          show: false,
          listOfLinkedContacts: [],
          disableArchiveBtn: false
        });
      }
    },
    confirmDeleteDialog: {
      declineClick: () => {
        setShowDeleteModal(false);
      },
      confirmClick: () => {
        setShowDeleteModal(false);

        if (checkedItemsIds !== null && checkedItemsIds.length > 0) {
          if (checkedItemsIds.length === 1) {
            deleteSelectedContact(
              "ids=" + checkedItemsIds[0],
              checkedItemsIds[0],
              checkedItemsIds.length
            );
          } else {
            deleteSelectedContact(
              "ids=" + checkedItemsIds.join(","),
              checkedItemsIds[0],
              checkedItemsIds.length
            );
          }
        } else {
          deleteSelectedContact(
            "ids=" + activeDeleteItem.id,
            activeDeleteItem.id,
            checkedItemsIds.length
          );
        }
        setCheckedItemsIds([]);
      }
    },
    selectWarningDialog: {
      handleSelectWarningOkButtonClick: () => {
        setShowSelectWarningModal(false);
      }
    }
  };

  const clearCheckedAll = () => {
    setListOfPartners(
      listOfPartners.map(contact => {
        contact.checked = false;
        return contact;
      })
    );
  };

  const getWarningSelectDialog = () => {
    if (showSelectWarningModal) {
      return <SelectWarningDialog handle={handle.selectWarningDialog} />;
    }
  };

  const contactsList = () => {
    let filteredContacts = listOfPartners.filter(contact => {
      let machedIds = deleteLinkedContactsModal.listOfLinkedContacts.filter(linkedContactId => {
        return linkedContactId === contact.id && contact.archived !== true;
      });

      return machedIds > 0;
    });

    return filteredContacts.map(contact => (
      <div className={`${styles.list_of_items} ${styles.status_aligment}`} key={contact.id}>
        <ContactListItem
          item={contact}
          selectedItem={selectedContact && selectedContact.id}
          itemHandler={onToggleItemHandler}
        />
      </div>
    ));
  };

  const onToggleItemHandler = data => {
    if (data.checked) {
      handle.contactList.onToggleItem(data);
      setDeleteLinkedContactsModal({
        ...deleteLinkedContactsModal,
        disableArchiveBtn: false
      });
    } else {
      handle.contactList.onToggleItem(data);
      setDeleteLinkedContactsModal({
        ...deleteLinkedContactsModal,
        disableArchiveBtn: true
      });
    }
  };

  const declineClicked = () => {
    setDeleteLinkedContactsModal({
      show: false,
      listOfLinkedContacts: []
    });
    setListOfPartners(
      listOfPartners.map(contact => {
        contact.checked = false;
        return contact;
      })
    );
  };

  const deleteLinkedContactsDialog = () => {
    let dialog = null;
    let modalColor = "#c00";
    let modalHeader = Localize.get("CONTACTS.CONTACTS_CANNOT_BE_DELETED");
    let modalHeaderChooseArchive = Localize.get("CONTACTS.CONTACTS_CAN_BE_ONLY_ARCHIVED");
    if (deleteLinkedContactsModal.show) {
      dialog = (
        <Modal
          size="small"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
            <br />
            {modalHeaderChooseArchive}
          </Modal.Header>
          <Modal.Content scrolling={true}>{contactsList()}</Modal.Content>
          <Modal.Actions>
            <GlobalOkCancelBtn
              disabledBtn={deleteLinkedContactsModal.disableArchiveBtn}
              onConfirmClicked={handle.confirmArchiveDialog.confirmClicked}
              onCancel={declineClicked}
              btnText={Localize.get("CONTACTS.LIST.TOOLBAR.ARCHIVE")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const mainClass =
    panelState.activeMode === constant.panelMode.details
      ? "ui container fluid"
      : "ui container fluid";

  return (
    <>
      <div className={mainClass}>
        <div>{deleteLinkedContactsDialog()}</div>
        <div>
          <Dimmer
            active={
              partnersFetchLoading ||
              panelState.activeMode === "create" ||
              panelState.activeMode === "edit" ||
              postLoading
            }
            inverted
          >
            <Loader className={styles.loader_center} size="large" disabled={!partnersFetchLoading}>
              {Localize.get("CONTACTS.LIST.TOOLBAR.PARTNERS_LOADING")}
            </Loader>
          </Dimmer>

          <div
            className={`ui right attached button ${styles.bms_fixed} ${
              sideBarVisiblity ||
              panelState.activeMode === "create" ||
              panelState.activeMode === "edit"
                ? styles.bms_zindex
                : null
            }`}
            onClick={settingVisibleSidebar}
          >
            <i className="ellipsis vertical icon"></i>
          </div>

          <Sidebar.Pushable
            as={Segment}
            className={
              panelState.activeMode === "create" || panelState.activeMode === "edit"
                ? styles.bms_segment_zi
                : styles.bms_segment
            }
          >
            <ContactSideBar
              state={partnersPanelState}
              privileges={privileges}
              handle={handle.contactSideBar}
              sideBarVisiblity={sideBarVisiblity}
              setSideBarVisiblity={setSideBarVisiblity}
              fetchPartnersCall={fetchPartnersCall}
              contactListState={contactListState}
              partnersPanelState={partnersPanelState}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              setContactPartners={setListOfPartners}
            />
            <Sidebar.Pusher
              dimmed={sideBarVisiblity}
              className={
                panelState.activeMode === "create" || panelState.activeMode === "edit"
                  ? styles.bms_zi
                  : null
              }
            >
              <div
                className={`${styles.bms_partners_content} ${
                  paging && paging.pageCount && paging.pageCount > 1 ? styles.pag_list_size : ""
                }`}
              >
                <div className="ui two column stackable grid padded">
                  <ContactList
                    partnersPanelState={partnersPanelState}
                    state={contactListState}
                    privileges={privileges}
                    handle={handle.contactList}
                    contacts={listOfPartners}
                    handleHeader={handle.contactHeader}
                    listDimmed={
                      panelState.activeMode === "create" || panelState.activeMode === "edit"
                    }
                    handlePageChange={handlePageChange}
                    paginationStatus={paging}
                    activePaginationPage={activePaginationPage}
                    scrollListBottom={scrollListBottom}
                    setScrollListBottom={setScrollListBottom}
                    scrollListTop={scrollListTop}
                    setScrollListTop={setScrollListTop}
                    selectedContact={selectedContact}
                    postPartnerCall={postPartnerCall}
                    checkedItemsIds={checkedItemsIds}
                    checkedAll={checkedAll}
                  />
                  <ContactPanel
                    activeMode={panelState.activeMode}
                    privileges={privileges}
                    handle={handle.contactPanel}
                    selectedContact={
                      panelState.activeMode === "create" ? getNewEmptyContact() : selectedContact
                    }
                    panelState={panelState}
                    setPanelState={setPanelState}
                    postPartnerCall={postPartnerCall}
                    fetchPartnerCall={fetchPartnerCall}
                    postLoading={postLoading}
                    partnerFetchLoading={partnerFetchLoading}
                  />
                  <ConfirmDeleteDialog
                    numberOfCheckedItems={
                      listOfPartners.filter(contact => contact.checked === true).length
                    }
                    showModal={showDeleteModal}
                    confirmClicked={handle.confirmDeleteDialog.confirmClick}
                    declineClicked={handle.confirmDeleteDialog.declineClick}
                  />
                  <ErrorDeleteDialog state={showModalError} handle={handle.errorDeleteDialog} />
                  <ConfirmArchiveDialog
                    showModal={showArchiveModal}
                    activeArchiveFilter={partnersPanelState.activeArchiveFilter}
                    confirmClicked={handle.confirmArchiveDialog.confirmClicked}
                    declineClicked={handle.confirmArchiveDialog.declineClicked}
                    numberOfArchivedItems={checkedItemsIds.length}
                  />
                  <div>{getWarningSelectDialog()}</div>
                </div>
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </div>
      <div className={styles.clear_both} />
    </>
  );
};

export default Contacts;
