import React from "react";
import { get } from "lodash";

import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import Localize from "react-intl-universal";
import { Message } from "semantic-ui-react";

const DefaultTemplateDetails = props => {
  const {
    customProps,
    availableItemFieldsForDetails //from fe
  } = props;

  const { selectedTemplate } = customProps; //from  be

  const findProperty = (propertyName, mySelectedProperties) => {
    let tempItem = mySelectedProperties.find(item => item.categoryKey === propertyName);

    return get(tempItem, "defaultTemplate.name");
  };

  const getPropertiesDetails = (listOfProperties, mySelectedProperties) => {
    if (listOfProperties.length === 0) {
      return <Message>{Localize.get("PROPERTIES.NO_PROPERTIES")}</Message>;
    }

    return listOfProperties.map((field, index) => {
      const property = findProperty(field.name, mySelectedProperties);

      return (
        <GlobalDetailsItem
          key={index}
          itemName={field.hederName}
          type={field.type}
          itemValue={property}
        />
      );
    });
  };

  return (
    selectedTemplate &&
    Array.isArray(selectedTemplate.subCategories) &&
    getPropertiesDetails(availableItemFieldsForDetails, selectedTemplate.subCategories)
  );
};

export default DefaultTemplateDetails;
