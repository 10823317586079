import React, { useState } from "react";
import * as RestService from "../RestService";
import showNotification from "../Notifications/NotificationFunction";
import ConfirmArchiveDialog from "../Dialogs/ConfirmArchiveDialog";
import { initialArchive } from "./Constants";
import propTypes from "prop-types";

const useArchiveItems = (
  callGetAll = function() {},
  setIsLoading = function(arg) {},
  currentModule,
  activePaginationPage,
  handlePageChange = function(arg) {},
  lenghtOfList
) => {
  const [openModal, setOpenModal] = useState(initialArchive);

  const callbackUpdateSuccess = response => {
    showNotificationMessage({
      type: "info",
      operation: "update"
    });

    if (activePaginationPage > 1 && openModal.lenghtOfItems === lenghtOfList) {
      handlePageChange({ activePage: activePaginationPage - 1 });
    } else {
      callGetAll();
    }
  };

  const callbackUpdateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "update"
    });
  };

  const callbackUpdateFinally = response => {
    setIsLoading(false);
    setOpenModal(initialArchive);
  };

  const updateProjectRoleServiceCall = async () => {
    const projectTypeService = new RestService.GlobalRest.Update.Service(
      `/${currentModule}${openModal.customPath}`
    );

    projectTypeService.setPayload(openModal.newObjects);
    projectTypeService.setCallbackSuccess(callbackUpdateSuccess);
    projectTypeService.setCallbackError(callbackUpdateError);
    projectTypeService.setCallbackFinally(callbackUpdateFinally);
    setIsLoading(true);
    await projectTypeService.call();
  };

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: currentModule,
      ...param
    });
  };

  const archiveItems = (customPath, newObjects, lenghtOfItems, activeOrArchived) => {
    setOpenModal({
      show: true,
      customPath,
      newObjects,
      lenghtOfItems,
      activeOrArchived
    });
  };

  return [
    archiveItems,
    <ConfirmArchiveDialog
      numberOfCheckedItems={openModal.lenghtOfItems}
      confirmClicked={updateProjectRoleServiceCall}
      declineClicked={() => setOpenModal(initialArchive)}
      activeArchiveFilter={openModal.activeOrArchived}
      showModal={openModal.show}
      cannotBeDeleted={[]}
      archiveBtnEnable={false}
    />
  ];
};

useArchiveItems.propTypes = {
  callGetAll: propTypes.func,
  setIsLoading: propTypes.func,
  currentModule: propTypes.string.isRequired,
  activePaginationPage: propTypes.number.isRequired,
  handlePageChange: propTypes.func,
  lenghtOfList: propTypes.number.isRequired
};

export default useArchiveItems;
