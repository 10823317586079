export const leftPanel = {
  form: {
    hideUnder: 1281,
    hideMenuIconUnder: 1281
  },
  details: {
    hideUnder: 1281,
    hideMenuIconUnder: 1281
  }
};

export const middlePanel = {
  form: {
    hideUnder: 0
  },
  details: {
    hideUnder: 0
  }
};

export const rightPanel = {
  form: {
    hideUnder: 0
  },
  details: {
    hideUnder: 0
  }
};

export const panelDefaultConf = {
  leftPanel,
  middlePanel,
  rightPanel
};

export const panelView = {
  create: "create",
  edit: "edit",
  details: "details",
  display: "display",
  list: "list"
};
