import { useState } from "react";
import * as constant from "./Constant";
import Localize from "react-intl-universal";
import has from "lodash/has";
import split from "lodash/split";
import get from "lodash/get";
import update from "lodash/update";
import isEmpty from "lodash.isempty";
import hasIn from "lodash/hasIn";

const useValidator = (myGlobalConfig, typeOfElement = "object", whenToValidate = "always") => {
  const [parameters, setParameters] = useState(typeOfElement === "list" ? [] : {});
  const [isValid, setIsValid] = useState(true);

  // const getObjectConfig = objectSchemaConfig => {
  //   return {
  //     // eslint-disable-next-line array-callback-return
  //     ...Object.keys(objectSchemaConfig).reduce((reduced, key) => {
  //       if (objectSchemaConfig[key].type === "string") {
  //         return { ...reduced, [key]: "" };
  //       }

  //       if (objectSchemaConfig[key].type === "object") {
  //         return {
  //           ...reduced,
  //           [key]: getObjectConfig(objectSchemaConfig[key].myObject)
  //         };
  //       }
  //     }, {})
  //   };
  // };

  const checkFilters = (listOfFilterNames = [], value = "") => {
    let errorMessage = "";
    if (Array.isArray(listOfFilterNames)) {
      listOfFilterNames.forEach(regexFilter => {
        let valid = constant.filterStrings[regexFilter].test(value);
        if (regexFilter.includes("whitespace")) {
          valid = !valid;
        }

        if (!valid) {
          errorMessage = Localize.get(constant.filterMessages[regexFilter]);
        }
      });
    }

    return errorMessage;
  };

  const checkObject = (objectSchemaConfig, myObject = {}) => {
    const fields = Reflect.ownKeys(objectSchemaConfig);
    let tempParameters = {};
    fields.forEach(field => {
      if (objectSchemaConfig[field].type === "string") {
        tempParameters[field] = checkFilters(objectSchemaConfig[field].filters, myObject[field]);
      }
    });

    return tempParameters;
  };

  const getListOfFilters = myPath => {
    let arrayOfPath = split(myPath, ".");
    let stingPathToFilters = "";

    arrayOfPath.forEach((pathStep, index, array) => {
      if (index + 1 === array.length) {
        stingPathToFilters = stingPathToFilters + pathStep + ".filters";
      } else {
        stingPathToFilters = stingPathToFilters + pathStep + ".myObject.";
      }
    });

    return get(myGlobalConfig, stingPathToFilters);
  };

  const validateObjectField = (field = "", value) => {
    if (has(parameters, field)) {
      let listOfFilters = getListOfFilters(field);
      setIsValid(true);
      setParameters(previousParameters => {
        let tempObj = { ...previousParameters };
        update(tempObj, field, myField => {
          myField = checkFilters(listOfFilters, value);
          return myField;
        });
        return tempObj;
      });
    }
  };

  const validateObjectOnSave = providedObjectForSave => {
    let allAreThere = false;
    if (isEmpty(providedObjectForSave)) {
      if (whenToValidate === "ifAnyFilled") {
        let myEmptyObj = {};
        Object.keys(myGlobalConfig).map(key => (myEmptyObj[key] = ""));
        setParameters(myEmptyObj);
        allAreThere = true;
      }
      if (whenToValidate === "always") {
        let myEmptyObj = {};
        Object.keys(myGlobalConfig).map(
          key => (myEmptyObj[key] = Localize.get(constant.filterMessages["min1Character"]))
        );
        setParameters(myEmptyObj);
        allAreThere = false;
      }
    } else {
      if (typeOfElement === "object") {
        if (whenToValidate === "always") {
          let tempParameters = checkObject(myGlobalConfig, providedObjectForSave);

          setParameters(tempParameters);
          allAreThere = Object.values(tempParameters).every(val => val === "");
        }
        if (whenToValidate === "ifAnyFilled") {
          let areAllEmpty = Object.keys(providedObjectForSave).every(key =>
            isEmpty(providedObjectForSave[key])
          );
          if (areAllEmpty) {
            let myEmptyObj = {};
            Object.keys(key => (myEmptyObj[key] = ""));
            setParameters(myEmptyObj);
          } else {
            let tempParameters = checkObject(myGlobalConfig, providedObjectForSave);
            setParameters(tempParameters);
            allAreThere = Object.values(tempParameters).every(val => val === "");
          }
        }
      }
    }
    setIsValid(allAreThere);
    return allAreThere;
  };

  const validateListField = (field = "", value, index) => {
    if (hasIn(myGlobalConfig, field)) {
      let listOfFilters = getListOfFilters(field);
      setIsValid(true);
      setParameters(prevParameters => {
        let newArr = [...prevParameters];
        newArr[index] = update(newArr[index], field, myField => {
          myField = checkFilters(listOfFilters, value);
          return myField;
        });
        return newArr;
      });
    }
  };

  const validateListOnSave = (providedList = []) => {
    let allAreThere = true;
    let tempParameterList = [];
    if (whenToValidate === "always") {
      // eslint-disable-next-line array-callback-return
      providedList.map((itemToSave, index) => {
        tempParameterList[index] = checkObject(myGlobalConfig, itemToSave);
        let areAllEmpty = Object.keys(tempParameterList[index]).every(key =>
          isEmpty(tempParameterList[index][key])
        );
        if (!areAllEmpty) {
          allAreThere = false;
        }
      });
    }

    if (whenToValidate === "ifAnyFilled") {
      // eslint-disable-next-line array-callback-return
      providedList.map((itemToSave, index) => {
        let areAllEmpty = Object.keys(itemToSave).every(key => isEmpty(itemToSave[key]));
        if (areAllEmpty) {
          let myEmptyObj = {};
          Object.keys(myGlobalConfig).map(key => (myEmptyObj[key] = ""));
          tempParameterList[index] = myEmptyObj;
        } else {
          tempParameterList[index] = checkObject(myGlobalConfig, itemToSave);
          let areAllEmpty = Object.keys(tempParameterList[index]).every(key =>
            isEmpty(tempParameterList[index][key])
          );
          if (!areAllEmpty) {
            allAreThere = false;
          }
        }
      });
    }

    setParameters(tempParameterList);
    setIsValid(allAreThere);
    return allAreThere;
  };

  const addValidatorObjectInList = newObj => {
    if (typeOfElement === "list") {
      setParameters(prevState => {
        return [newObj, ...prevState];
      });
    }
  };

  const removeValidatorObjectFromList = providedIndex => {
    if (typeOfElement === "list") {
      const newArr = parameters.filter((item, i) => i !== providedIndex);
      setParameters(newArr);
    }
  };

  return [
    parameters,
    isValid,
    validateObjectOnSave,
    validateObjectField,
    setParameters,
    validateListOnSave,
    validateListField,
    addValidatorObjectInList,
    removeValidatorObjectFromList
  ];
};

export default useValidator;
