import isEmpty from "lodash.isempty";
import { get } from "lodash";

export const convert2Contacts = data => {
  return data.map(contact => {
    const newContact = contact;
    newContact.active = false;
    newContact.checked = false;
    newContact.isSupplier = get(contact, "supplier") ? true : false;
    newContact.isCollective = false;
    newContact.isCustomer = get(contact, "customer") ? true : false;
    newContact.type = get(contact, "dtype");
    newContact.note = get(contact, "note") ? get(contact, "note") : "";
    if (get(contact, "dtype") === "employee") {
      newContact.company = {
        id: get(contact, "company.id") ? get(contact, "company.id") : "",
        name: get(contact, "company.name") ? get(contact, "company.name") : ""
      };
    }
    if (get(contact, "contacts")) {
      let localRecipients = [];
      let localcontactPersons = [];
      // eslint-disable-next-line array-callback-return
      contact.contacts.map(currentContact => {
        currentContact.name = currentContact.first + " " + currentContact.last;
        if (currentContact.contactType === "invoice") {
          localRecipients.push(currentContact);
        } else {
          localcontactPersons.push(currentContact);
        }
      });
      newContact.recipients = localRecipients;
      newContact.contacts = localcontactPersons;
    } else {
      newContact.recipients = [];
      newContact.contacts = [];
    }
    if (get(contact, "accounts")) {
      newContact.accounts = contact.accounts.map(accounts => {
        accounts.owner = accounts.accountOwner;
        return accounts;
      });
    } else {
      newContact.accounts = [];
    }

    newContact.firstName = get(contact, "first");
    newContact.lastName = get(contact, "last");
    newContact.jobTitle = get(contact, "jobTitle");
    newContact.inoviceNotifiers = [];
    newContact.url = get(contact, "web");
    newContact.customerExternalNumber = get(contact, "customer")
      ? get(contact, "customer.original")
      : "";
    newContact.supplierExternalNumber = get(contact, "supplier") ? contact.supplier.original : "";
    newContact.employeeExternalNumber = get(contact, "uncategorized")
      ? get(contact, "uncategorized.original")
      : "";
    newContact.documentTemplates = get(contact, "defaultTemplates")
      ? get(contact, "defaultTemplates ")
      : [];
    newContact.customerNumber = get(contact, "customer") ? get(contact, "customer.id") : "";
    newContact.supplierNumber = get(contact, "supplier") ? get(contact, "supplier.id") : "";
    newContact.employeeNumber = get(contact, "uncategorized")
      ? get(contact, "uncategorized.id")
      : "";

    // address part
    if (contact.address) {
      contact.address.asBillingAddress = get(contact, "asBillingAddress");
      contact.address.asShippingAddress = get(contact, "asShippingAddress");
    }
    return newContact;
  });
};

export const getNewEmptyContact = () => {
  return {
    id: new Date().getTime().toString(),
    checked: false,
    active: false,
    archived: false,
    isSupplier: false,
    isCustomer: false,
    type: "",
    name: "",
    firstName: "",
    lastName: "",
    note: "",
    first: "",
    last: "",
    phone: "",
    mobile: "",
    email: "",
    url: "",
    address: { street: "", city: "", postcode: "", country: "", state: "" },
    billingAddress: null,
    shipToAddress: null,
    asBillingAddress: false,
    asShippingAddress: false,
    contacts: [],
    recipients: [],
    accounts: [],
    vatin: "",
    taxNumber: "",
    fax: "",
    upin: "",
    jobTitle: "",
    company: null,
    customerNumber: "",
    supplierNumber: "",
    children: [],
    parent: null,
    sendToPost: false,
    sendToMail: false,
    sendInvoiceToParent: false,
    editedFields: [],
    documentTemplates: [],
    verified: true
  };
};

const removeObjectsWithEmptyFields = (myList = []) => {
  let myNewList = myList.filter(item => !Object.values(item).every(val => val === ""));

  return myNewList;
};

const mergeContactsAndRecipients = (contacts = [], recipients = []) => {
  let localContacts = removeObjectsWithEmptyFields(contacts);
  let localRecipients = removeObjectsWithEmptyFields(recipients);
  let newArr = localContacts.concat(localRecipients);
  return newArr;
};

export const createNewCompanyObject = company => {
  return {
    archived: false,
    address: company.address ? company.address : "",
    shipToAddress:
      company.shipToAddress && !isEmpty(company.shipToAddress) ? company.shipToAddress : null,
    billingAddress:
      company.billingAddress && !isEmpty(company.billingAddress) ? company.billingAddress : null,
    asBillingAddress: company.asBillingAddress,
    asShippingAddress: company.asShippingAddress,
    phone: company.phone,
    web: company.url,
    name: company.name,
    supplier: company && company.isSupplier ? "" : null,
    // mobile: company.mobile,
    // TODO Creating a mobile for company is supported on a backend side, it is disabled for now,
    // TODO because of specific request but it can be returned if it is needed in the future
    // TODO https://jira.contrimo.com/browse/BMS-595 17.10.2019 #Predrag Stosic
    fax: company.fax,
    accounts: removeObjectsWithEmptyFields(company.accounts),
    email: company.email,
    contacts: mergeContactsAndRecipients(company.contacts, company.recipients),
    customer: company.isCustomer ? "" : null,
    vatin: company.vatin,
    parent: company.parent,
    sendToPost: company.sendToPost,
    sendToMail: company.sendToMail,
    // documentTemplates: company.documentTemplates, ---Waiting for BE refactoring
    sendInvoiceToParent: company.sendInvoiceToParent,
    taxNumber: company.taxNumber,
    note: company.note ? company.note : "",
    type: "company",
    verified: company.verified
  };
};

export const createNewPersonObject = person => {
  return {
    archived: false,
    address: person.address ? person.address : "",
    shipToAddress:
      person.shipToAddress && !isEmpty(person.shipToAddress) ? person.shipToAddress : null,
    billingAddress:
      person.billingAddress && !isEmpty(person.billingAddress) ? person.billingAddress : null,
    asBillingAddress: person.asBillingAddress,
    asShippingAddress: person.asShippingAddress,
    phone: person.phone,
    web: person.url,
    first: person.firstName,
    last: person.lastName,
    supplier: person.supplier ? person.supplier : null,
    mobile: person.mobile,
    accounts: removeObjectsWithEmptyFields(person.accounts),
    email: person.email,
    customer: "",
    vatin: person.vatin,
    upin: person.upin,
    note: person.note ? person.note : "",
    type: "person"
  };
};

export const createNewEmployeeObject = employee => {
  return {
    archived: false,
    address: employee.address ? employee.address : "",
    phone: employee.phone,
    web: employee.url,
    first: employee.first,
    last: employee.last,
    jobTitle: employee.jobTitle,
    mobile: employee.mobile,
    email: employee.email,
    uncategorized: employee.uncategorized ? employee.uncategorized : null,
    company: {
      name: employee.company.name,
      id: employee.company.id
    },
    note: employee.note ? employee.note : "",
    type: "employee"
  };
};

export const createUpdateCompanyObject = company => {
  if (!company.editedFields) {
    return null;
  }
  if (company.editedFields.includes("address")) {
    delete company.address.asBillingAddress;
    delete company.address.asShippingAddress;
  }
  if (
    company.editedFields.includes("shipToAddress") ||
    company.editedFields.includes("asShippingAddress")
  ) {
    if (!isEmpty(company.shipToAddress)) {
      delete company.shipToAddress.asBillingAddress;
      delete company.shipToAddress.asShippingAddress;
      delete company.shipToAddress.id;
    } else {
      // clear shippingAddress
      if (!company.editedFields.includes("shipToAddress")) {
        company.editedFields.push("shipToAddress");
      }
      company.shipToAddress = null;
    }
  }
  if (
    company.editedFields.includes("billingAddress") ||
    company.editedFields.includes("asBillingAddress")
  ) {
    if (!isEmpty(company.billingAddress)) {
      delete company.billingAddress.asBillingAddress;
      delete company.billingAddress.asShippingAddress;
      delete company.billingAddress.id;
    } else {
      // clear billingAddress
      if (!company.editedFields.includes("billingAddress")) {
        company.editedFields.push("billingAddress");
      }
      company.billingAddress = null;
    }
  }

  return company.editedFields.map(function(field) {
    let fieldNameForDB = field;
    switch (field) {
      case "name":
        fieldNameForDB = "name";
        break;
      case "url":
        fieldNameForDB = "web";
        break;
      case "contacts":
        fieldNameForDB = "contacts";
        company[field] = mergeContactsAndRecipients(company.contacts, company.recipients);
        company[field].forEach(function(cmp) {
          delete cmp.name;
        });

        break;
      case "accounts":
        company[field].forEach(function(cmp) {
          delete cmp.transations;
          delete cmp.owner;
        });
        company[field] = removeObjectsWithEmptyFields(company[field]);
        break;
      case "isCustomer":
        field = "customerNumber";
        fieldNameForDB = "customer";

        if (company.isCustomer) {
          company[field] = "";
        } else {
          company[field] = null;
        }

        break;
      case "isSupplier":
        field = "supplierNumber";
        fieldNameForDB = "supplier";

        if (company.isSupplier) {
          company[field] = "";
        } else {
          company[field] = null;
        }
        break;
      case "vatin":
        company[field] = company[field] === "" ? null : company[field];
        break;
      case "parent":
        company[field] = company[field] ? { id: company[field].id } : null;
        break;
      case "taxNumber":
        company[field] = company[field] === "" ? null : company[field];
        break;
      default:
        break;
    }

    return {
      op: "replace",
      path: "/" + fieldNameForDB,
      value: company[field]
    };
  });
};

export const createUpdatePersonObject = person => {
  if (!person.editedFields) {
    return null;
  }
  if (person.editedFields.includes("address")) {
    delete person.address.asBillingAddress;
    delete person.address.asShippingAddress;
  }

  if (
    person.editedFields.includes("shipToAddress") ||
    person.editedFields.includes("asShippingAddress")
  ) {
    if (!isEmpty(person.shipToAddress)) {
      delete person.shipToAddress.asBillingAddress;
      delete person.shipToAddress.asShippingAddress;
      delete person.shipToAddress.id;
    } else {
      // clear shippingAddress
      if (!person.editedFields.includes("shipToAddress")) {
        person.editedFields.push("shipToAddress");
      }
      person.shipToAddress = null;
    }
  }
  if (
    person.editedFields.includes("billingAddress") ||
    person.editedFields.includes("asBillingAddress")
  ) {
    if (!isEmpty(person.billingAddress)) {
      delete person.billingAddress.asBillingAddress;
      delete person.billingAddress.asShippingAddress;
      delete person.billingAddress.id;
    } else {
      // clear billingAddress
      if (!person.editedFields.includes("billingAddress")) {
        person.editedFields.push("billingAddress");
      }
      person.billingAddress = null;
    }
  }

  if (person.editedFields.includes("customerOwnNumber")) {
    person.editedFields.splice(person.editedFields.indexOf("customerOwnNumber"), 1);
  }

  if (person.editedFields.includes("supplierOwnNumber")) {
    person.editedFields.splice(person.editedFields.indexOf("supplierOwnNumber"), 1);
  }

  return person.editedFields.map(function(field) {
    let fieldNameForDB = field;
    switch (field) {
      case "firstName":
        fieldNameForDB = "first";
        break;
      case "lastName":
        fieldNameForDB = "last";
        break;
      case "url":
        fieldNameForDB = "web";
        break;
      case "accounts":
        person[field].forEach(function(cmp) {
          delete cmp.transations;
          delete cmp.owner;
        });
        person[field] = removeObjectsWithEmptyFields(person[field]);
        break;
      case "upin":
        fieldNameForDB = "upin";
        break;
      default:
        break;
    }
    return {
      op: "replace",
      path: "/" + fieldNameForDB,
      value: person[field]
    };
  });
};

export const createUpdateEmployeeObject = employee => {
  if (!employee.editedFields) {
    return null;
  }
  if (employee.editedFields.includes("address")) {
    delete employee.address.asBillingAddress;
    delete employee.address.asShippingAddress;
  }

  return employee.editedFields.map(function(field) {
    let fieldNameForDB = field;
    let valueForDB = employee[field];
    switch (field) {
      case "firstName":
        fieldNameForDB = "first";
        break;
      case "lastName":
        fieldNameForDB = "last";
        break;
      case "url":
        fieldNameForDB = "web";
        break;
      case "company":
        fieldNameForDB = "company";
        valueForDB = {
          id: employee[field].id
        };
        break;
      default:
        break;
    }
    return {
      op: "replace",
      path: "/" + fieldNameForDB,
      value: valueForDB
    };
  });
};

export const createUpdateArchiveObject = partnerArchived => {
  return [
    {
      op: "replace",
      path: "/archived",
      value: !partnerArchived
    }
  ];
};

export const createMultipleUpdateArchiveObject = (contactsIds, partnerArchived) => {
  return contactsIds.map(id => ({
    id: id,
    operation: createUpdateArchiveObject(partnerArchived)
  }));
};

export const clearEmptyEntries = data => {
  if (!data) {
    return true;
  }
  return Object.keys(data).forEach(key => !data[key] && delete data[key]);
};
