import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./style/DocumentThumb.module.css";
import { getDocumentContent } from "../../../Components/Utils/GlobalUtil";

const DocumentThumb = props => {
  const { documentLinkThumbnailDetails, listName, document } = props;

  const callbackGetBinaryContentSuccess = response => {
    var reader = new window.FileReader();
    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        let documentPreviewPDF = {
          isPDF: response.headers["content-type"].includes("pdf"),
          data: reader.result
        };
        documentLinkThumbnailDetails.onThumbClick(documentPreviewPDF, document, listName);
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });

      let documentPreview = {
        isPDF: response.headers["content-type"].includes("pdf"),
        data: urlCreator.createObjectURL(file)
      };
      documentLinkThumbnailDetails.onThumbClick(documentPreview, props.document, listName);
    }
  };
  const callbackGetBinaryContentError = response => {};
  const callbackGetBinaryContentFinally = response => {};

  let service =
    document &&
    document.binaryContent &&
    getDocumentContent(
      document.binaryContent.id,
      callbackGetBinaryContentSuccess,
      callbackGetBinaryContentError,
      callbackGetBinaryContentFinally
    );

  const handleThumbClick = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    service.call();
  };

  return (
    <div className={styles.invoice_thumbnails} onClick={handleThumbClick}>
      <div>
        <div onClick={handleThumbClick}>
          <Icon
            name="eye"
            size="large"
            className={`pdfPreview ${styles.eye_icon}`}
            type="application/pdf"
          ></Icon>
        </div>
      </div>
    </div>
  );
};

export default DocumentThumb;
