import React, { useState } from "react";
import { Form, Input, Dropdown, Label, TextArea } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import styles from "./style/DocNewProjectForm.module.css";
import * as constant from "../../Constants.js";
import { formatAmount } from "../../Util";
import GlobalOkCancelBtn from "../../../Components/GlobalOkCancelBtn";

const EditProjectForm = props => {
  const {
    projectPrivileges,
    currentProject,
    setCurrentProject,
    projectNameText,
    projectExternalNumberText,
    projectNumberText,
    projectAmountText,
    cancelProject,
    updateProject,
    projectsOptions,
    setEditedDropdown,
    restAmount,
    previousSelectedProject
  } = props;

  // eslint disable next line no unused vars
  const [validator, setValidator] = useState(
    props.validator === undefined
      ? new Validator(validatorConstants.formFieldsNewProject)
      : props.validator
  );
  const [charCounter, setCharCounter] = useState({
    charsLeft:
      255 - (currentProject && currentProject.description ? currentProject.description.length : 0)
  });

  const [refreshValidation, setRefreshValidation] = useState(false);

  const errorBorder = "3px red solid";

  const handleOnChange = (event, data) => {
    if (!data.value && data.name === "amount") {
      // clear validation messages after field is cleared out
      validator.validateField(data.value, data.name);
    } else {
      const validationOnField = validator.parameter[data.name];

      if (validationOnField) {
        validator.validateField(data.value, data.name);
      }
    }

    if (data.name === "amount") {
      let amount = data.value;

      if (!validator.isValidNumber(amount)) {
        return;
      }

      if (amount.indexOf(".") >= 0) {
        amount = amount.substr(0, amount.indexOf(".")) + amount.substr(amount.indexOf("."), 3);
      } else if (amount.indexOf(",") >= 0) {
        amount = amount.substr(0, amount.indexOf(",")) + amount.substr(amount.indexOf(","), 3);
      }

      setCurrentProject({
        ...currentProject,
        amount: amount
      });
    }

    if (data.name === "description") {
      let inputChar = data.value;

      setCurrentProject({
        ...currentProject,
        description: inputChar
      });

      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }
  };

  const handleOnBlur = (event, data) => {
    if (event.target.name in validatorConstants.formFieldsNewProject) {
      validator.validateField(event.target.value, event.target.name);
      if (validator.isThereValidation()) {
        setRefreshValidation(!refreshValidation);
      }
    }
  };

  const handleOnDropdownChange = (event, data) => {
    setEditedDropdown(true);

    let selectedProjectFromDropdown = projectsOptions.find(project => project.key === data.value);

    setCurrentProject({
      ...selectedProjectFromDropdown,
      amount: currentProject.amount,
      description: currentProject.description
    });

    if (data.name in validatorConstants.formFieldsNewProject) {
      validator.validateField(data.value, data.name);
    }
  };

  const onUpdateClicked = (event, projectUpdated) => {
    let previousAmount = Number(restAmount) + Number(previousSelectedProject.amount);
    if (
      validator.compareNumbers(
        "amount",
        Number(formatAmount(projectUpdated.amount)),
        formatAmount(previousAmount)
      )
    ) {
      setValidator({ ...validator });
      return false;
    }

    event.preventDefault();

    if (
      !validator.validateOnSave(validatorConstants.documentProjectRequiredFields, currentProject)
    ) {
      setValidator({ ...validator });
      return false;
    }

    updateProject(projectUpdated);
    setEditedDropdown(false);
  };

  return (
    <>
      <Form>
        <Form.Field>
          <label>{projectNameText}</label>
          <Dropdown
            icon="dropdown"
            floating
            labeled
            fluid
            direction="left"
            selection
            search
            closeOnChange
            options={projectsOptions}
            placeholder={currentProject ? currentProject.projectname : ""}
            onChange={handleOnDropdownChange}
            onSearchChange={props.handleProjectSearch}
            name="projectname"
          />
        </Form.Field>
        <Form.Field>
          <label>{projectNumberText}</label>
          <Input
            autoComplete={constant.autoComplete.none}
            disabled
            name="projectNumber"
            placeholder={currentProject.id}
            value={currentProject.id}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </Form.Field>
        {currentProject.projectexternalnumber && (
          <Form.Field>
            <label>{projectExternalNumberText}</label>
            <Input
              disabled
              name="projectExternalNumber"
              placeholder={currentProject.projectexternalnumber}
              value={
                currentProject.projectexternalnumber ? currentProject.projectexternalnumber : ""
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
          </Form.Field>
        )}
        <Form.Field>
          <label>{projectAmountText}</label>
          <Input
            autoComplete={constant.autoComplete.none}
            name="amount"
            placeholder={projectAmountText}
            value={currentProject.amount}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            style={{ border: validator.parameter.amount ? errorBorder : "" }}
          />
          {validator.parameter.amount && (
            <Label basic color="red" pointing content={validator.parameter.amount} />
          )}
        </Form.Field>
        <Form.Field className={styles.input_fields_padding_right}>
          <label className={styles.required_label}>
            {Localize.get("DOCUMENTS.FORM.DESCRIPTION")}
          </label>
          <TextArea
            placeholder={Localize.get("DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER")}
            value={currentProject.description}
            onChange={handleOnChange}
            name="description"
            maxLength={255}
            onBlur={handleOnBlur}
            style={{
              border: validator.parameter.description ? errorBorder : ""
            }}
          />
          <p className="right floated content">
            {charCounter.charsLeft}
            {"/255"}
          </p>
        </Form.Field>

        <div className={styles.buttons_container}>
          <GlobalOkCancelBtn
            disabledBtn={!projectPrivileges.get}
            onConfirmClicked={event => {
              onUpdateClicked(event, currentProject);
            }}
            onCancel={cancelProject}
            btnText={Localize.get("DOCUMENTS.FORM.UPDATE")}
            icon={" "}
          />
        </div>
      </Form>
    </>
  );
};

export default EditProjectForm;
