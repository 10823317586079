import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const HorizontalChart = props => {
  const m = {
    title: {
      display: true,
      text: props.title,
      fontSize: 14
    },
    legend: {
      display: false,
      position: "bottom"
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: props.minDoc,
            suggestedMax: props.docMax,
            stepSize: 1,
            precision: 1
          }
        }
      ]
    },
    maintainAspectRatio: false
  };

  return <HorizontalBar data={props.data} options={m} />;
};

export default HorizontalChart;
