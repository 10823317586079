export const booking_leftPanelConfig = [
  {
    type: "sectionHeader",
    headerText: "APPNAVBAR.BOOKING_OVERVIEW",
    itemList: [
      {
        type: "filter",
        name: "allBookings",
        itemText: "BOOKKEEPING.SIDEBAR_ALL_BOOKINGS",
        iconName: "file outline"
      },
      {
        type: "filter",
        name: "expense",
        itemText: "BOOKKEEPING.SIBEBAR_EXPENSE",
        iconName: "sign-in"
      },
      {
        type: "filter",
        name: "revenue",
        itemText: "BOOKKEEPING.SIDEBAR_REVENUE",
        iconName: "sign-out"
      },
      {
        type: "filter",
        name: "fixed",
        itemText: "BOOKKEEPING.SIDEBAR_FIXED",
        iconName: "briefcase"
      },
      {
        type: "filter",
        name: "corrections",
        itemText: "BOOKKEEPING.SIDEBAR_CORRECTIONS",
        iconName: "pencil"
      }
    ]
  }
];

export const availableItemFieldsForListBooking = [
  {
    name: "documentDate",
    hederName: "BOOKKEEPING.HEADER_DOCUMENT_DATE",
    type: "text",
    width: 2,
    visible: true
    // column_customized_css: styles.first_header_only
  },
  {
    name: "documentNumber",
    hederName: "BOOKKEEPING.HEADER_DOCUMENT_NUMBER",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "description",
    hederName: "BOOKKEEPING.HEADER_DESCRIPTION",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "amount",
    hederName: "BOOKKEEPING.HEADER_AMOUNT",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "tax",
    hederName: "BOOKKEEPING.HEADER_TAX",
    type: "text",
    width: 1,
    visible: true
  },
  {
    name: "taxAmount",
    hederName: "BOOKKEEPING.HEADER_TAX_AMOUNT",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "debit",
    hederName: "BOOKKEEPING.HEADER_DEBIT",
    type: "text",
    width: 2
  },
  {
    name: "credit",
    hederName: "BOOKKEEPING.HEADER_CREDIT",
    type: "text",
    width: 2,
    visible: true
  }
];

export const myConfigForBooking = {
  leftPanel: {
    form: {
      hideUnder: 30000,
      hideMenuIconUnder: 30000
    },
    details: {
      hideUnder: 1281,
      hideMenuIconUnder: 1281
    }
  },
  middlePanel: {
    form: {
      hideUnder: 30000
    },
    details: {
      hideUnder: 0
    }
  },
  rightPanel: {
    form: {
      hideUnder: 0
    },
    details: {
      hideUnder: 0
    }
  }
};

export const availableItemFieldsForBookingDetails = [
  {
    name: "postingDate",
    labelName: "BOOKKEEPING.POSTING_DATE",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "serviceDate",
    labelName: "BOOKKEEPING.SERVICE_DATE",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "user",
    labelName: "BOOKKEEPING.USER",
    type: "text",
    width: 4,
    edit_disabled: true,
    isRequired: true
  },
  {
    name: "categorie",
    labelName: "BOOKKEEPING.CATEGORIE",
    type: "text",
    width: 3,
    edit_disabled: true,
    isRequired: true
  },
  {
    name: "viewDocument",
    labelName: "BOOKKEEPING.VIEW_DOCUMENT",
    type: "icon",
    width: 3,
    edit_disabled: true,
    isRequired: true
  }
];

export const availableItemFieldsForDetailsBookingAccounts = [
  {
    name: "debit",
    labelName: "BOOKKEEPING.DEBIT_ACCOUNT",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "credit",
    labelName: "BOOKKEEPING.CREDIT_ACCOUNT",
    type: "text",
    width: 3,
    isRequired: true
  }
];

export const account_leftPanelConfig = [
  {
    type: "sectionHeader",
    headerText: "APPNAVBAR.ACCOUNT_OVERVIEW",
    itemList: [
      {
        type: "filter",
        name: "allAccounts",
        itemText: "BOOKKEEPING.SIDEBAR_ALL_ACCOUNTS",
        iconName: "file outline"
      },
      {
        type: "filter",
        name: "glAccounts",
        itemText: "BOOKKEEPING.SIBEBAR_G/L",
        iconName: "file outline"
      },
      {
        type: "filter",
        name: "personalAccounts",
        itemText: "BOOKKEEPING.SIDEBAR_REVENUE",
        iconName: "file outline"
      }
    ]
  }
];

export const availableItemFieldsForListAccounts = [
  {
    name: "account",
    hederName: "BOOKKEEPING.HEADER_ACCOUNT",
    type: "text",
    width: 3,
    visible: true
    // column_customized_css: styles.first_header_only
  },
  {
    name: "description",
    hederName: "BOOKKEEPING.HEADER_DESCRIPTION",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "category",
    hederName: "BOOKKEEPING.CATEGORIE",
    type: "text",
    width: 4,
    visible: true
  },
  {
    name: "amount",
    hederName: "BOOKKEEPING.HEADER_SALES_TARGET",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "tax",
    hederName: "BOOKKEEPING.HEADER_SALES_CREDIT",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "taxAmount",
    hederName: "BOOKKEEPING.HEADER_BALANCE",
    type: "text",
    width: 2,
    visible: true
  }
];

export const availableItemFieldsForAccountDetails = [
  {
    name: "accountType",
    labelName: "BOOKKEEPING.ACCOUNT_TYPE",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "tax",
    labelName: "BOOKKEEPING.TAX",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "lastPosting",
    labelName: "BOOKKEEPING.LAST_POSTING",
    type: "text",
    width: 4,
    edit_disabled: true,
    isRequired: true
  },
  {
    name: "datevAccount",
    labelName: "BOOKKEEPING.DATEV_AUTO",
    type: "text",
    width: 3,
    edit_disabled: true,
    isRequired: true
  },
  {
    name: "viewAccount",
    labelName: "BOOKKEEPING.VIEW_STATEMENT",
    type: "icon",
    width: 3,
    edit_disabled: true,
    isRequired: true
  }
];

export const myConfigForAccounts = {
  leftPanel: {
    form: {
      hideUnder: 30000,
      hideMenuIconUnder: 30000
    },
    details: {
      hideUnder: 1281,
      hideMenuIconUnder: 1281
    }
  },
  middlePanel: {
    form: {
      hideUnder: 30000
    },
    details: {
      hideUnder: 0
    }
  },
  rightPanel: {
    form: {
      hideUnder: 0
    },
    details: {
      hideUnder: 0
    }
  }
};
