import { useState } from "react";
import { fetchDunning } from "./DunningServices";
import useFetch from "./useFetch";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";

const useDunningServices = (selectedItem, currentView) => {
  const [forbidenResponse, setForbidenResponse] = useState(false);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const getPath = () => {
    let path =
      selectedItem && selectedItem.name && selectedItem.name.id ? selectedItem.name.id : "12";
    return `/settings/subcategories/${path}/properties`;
  };

  const callbackFetchSuccess = (response, setData, setPaging) => {
    setForbidenResponse(false);
  };

  const callbackFetchError = err => {
    setSelectedDunning([]);
    showNotificationMessage({
      type: "warning",
      operation: ""
    });
  };

  const [selectedDunning, paging, loading, fetchDunningCall, setSelectedDunning] = useFetch(
    fetchDunning,
    [],
    getPath(),
    {},
    callbackFetchSuccess,
    callbackFetchError
  );

  return {
    selectedDunning,
    paging,
    loading,
    fetchDunningCall,
    setSelectedDunning,
    forbidenResponse
  };
};

export default useDunningServices;
