import Localize from "react-intl-universal";

export const formatDateToString = date => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateDisplay = date => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(".");
};

export const concatErrorMessagesFromService = message => {
  // TODO This function is used to split strings - error messages that are received from backend side
  // example {"STRING_ONE"}; INTEGER_OR_OTHER; {"STRING_TWO"}
  let errorMessage = "";
  let stringArr = message.split(";");
  let msg1 = stringArr[0];
  let msg2 = stringArr[1];
  let msg3 = stringArr[2];
  let checkMsg1 = Localize.get(msg1.replace(/[{}]/g, ""));
  let checkMsg2 = msg2;
  let checkMsg3 = Localize.get(msg3.replace(/[{}]/g, ""));
  errorMessage = checkMsg1.concat(checkMsg2, checkMsg3);

  return errorMessage;
};
