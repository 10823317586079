import React, { useState, useEffect } from "react";
import { Dimmer, Loader, Dropdown, Input, Form, Segment } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DocumentLinkThumbnailList from "./DocumentLinkThumbnailList";
import DocumentLinkThumbnailDetails from "./DocumentLinkThumbnailDetails";
import * as RestService from "../../RestService";
import {
  convert2Documents,
  directionOptions,
  verificationOptions,
  documentTypeOptions
} from "../Util";
import * as constant from "../Constants";
import styles from "./style/DocumentLinkThumbnails.module.css";
import union from "lodash.union";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";

const DocumentLinkThumbnails = props => {
  const {
    currentDocument,
    setThumbsToSend,
    thumbsToSend,
    setNewlyCreatedDocument,
    handler,
    isLoadingForm
  } = props;

  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [thumb, setThumb] = useState([]);
  const [currentPanetState, setCurrentPanelState] = useState(constant.thumbnailsPanelState.form);
  const [direction, setDirection] = useState();
  const [type, setType] = useState();
  const [verification, setVerification] = useState();

  const [editedFields, setEditedFields] = useState([]);

  const initialState = {
    activeDocumentDirectionFilter: constant.documentDirection.all,
    activeDocumentTypeFilter: constant.documentType.all,
    activeVerificationStatusFilter: constant.verificationStatus.all,
    activeInputNumberSearch: ""
  };

  const [thumbState, setThumbState] = useState({
    ...initialState
  });

  const getAllService = filterResolveObject => {
    const serviceGetAll = new RestService.Document.GetAll.Service();
    serviceGetAll.setCallbackSuccess(callbackGetAllSuccess);
    serviceGetAll.setCallbackError(callbackGetAllError);
    serviceGetAll.setCallbackFinally(callbackGetAllFinally);
    serviceGetAll.query.clearPageSize();

    if (filterResolveObject) {
      resolveFilters(serviceGetAll, filterResolveObject);
    }

    return serviceGetAll;
  };

  const canBeLinked = (docId, documentsArray) => {
    return documentsArray.some(doc => {
      if (doc.id === docId) {
        return true;
      } else {
        if (doc.documents.length > 0) {
          return canBeLinked(docId, doc.documents);
        } else {
          return false;
        }
      }
    });
  };

  const callbackGetAllSuccess = response => {
    let docs = convert2Documents(response.data.data);
    if (currentDocument.partner) {
      setThumb(
        docs !== undefined
          ? docs.filter(
              doc =>
                (currentDocument.parentDocument
                  ? currentDocument.parentDocument.id !== doc.id
                  : true) &&
                !canBeLinked(currentDocument.id, doc.documents) &&
                doc.partner &&
                doc.partner.id === currentDocument.partner.id &&
                doc.id !== currentDocument.id &&
                !thumbsToSend.find(th => th.id === doc.id)
            )
          : []
      );
    } else {
      setThumb(
        docs !== undefined
          ? docs.filter(
              doc =>
                (currentDocument.parentDocument
                  ? currentDocument.parentDocument.id !== doc.id
                  : true) &&
                !canBeLinked(currentDocument.id, doc.documents) &&
                doc.id !== currentDocument.id &&
                !thumbsToSend.find(th => th.id === doc.id)
            )
          : []
      );
    }
    if (currentDocument.parentDocument) {
      let tempDoc = docs.filter(document => document.id === currentDocument.parentDocument.id);
      if (tempDoc.length > 0) {
        setThumbsToSend([...thumbsToSend, { ...tempDoc[0], isParent: true }]);
      } else {
        setThumbsToSend([...thumbsToSend, { ...currentDocument.parentDocument, isParent: true }]);
      }
    }
  };

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {};

  useEffect(() => {
    const filterObject = {
      activeDocumentDirectionFilter: thumbState.activeDocumentDirectionFilter,
      activeDocumentTypeFilter: thumbState.activeDocumentTypeFilter,
      activeVerificationStatusFilter: thumbState.activeVerificationStatusFilter,
      activeInputNumberSearch: thumbState.activeInputNumberSearch,
      partnerFilter: currentDocument.partner ? currentDocument.partner.id : null
    };
    let fetchAllData = getAllService(filterObject);
    const fetchData = async () => {
      setIsLoading(true);
      await fetchAllData.call();
      setIsLoading(false);
    };

    fetchData();
  }, [
    thumbState.activeDocumentDirectionFilter,
    thumbState.activeDocumentTypeFilter,
    thumbState.activeVerificationStatusFilter
  ]);

  useEffect(() => {
    const filterObject = {
      activeDocumentDirectionFilter: thumbState.activeDocumentDirectionFilter,
      activeDocumentTypeFilter: thumbState.activeDocumentTypeFilter,
      activeVerificationStatusFilter: thumbState.activeVerificationStatusFilter,
      activeInputNumberSearch: thumbState.activeInputNumberSearch,
      partnerFilter: currentDocument.partner ? currentDocument.partner.id : null
    };
    let fetchAllData = getAllService(filterObject);
    const fetchData = async () => {
      setIsLoading(true);
      await fetchAllData.call();
      setIsLoading(false);
    };

    let isSubscribed = true;
    delayTimer = setTimeout(
      function() {
        if (isSubscribed && !firstBuild) {
          fetchData();
        }
      },

      thumbState.activeDocumentDirectionFilter === "" &&
        thumbState.activeDocumentTypeFilter === "" &&
        thumbState.activeVerificationStatusFilter === "" &&
        thumbState.activeInputNumberSearch === ""
        ? 0
        : 800
    );
    setFirstBild(false);
    return () => (isSubscribed = false);
  }, [thumbState.activeInputNumberSearch]);

  const resolveFilters = (service, filterObject) => {
    if (
      filterObject.activeInputNumberSearch !== "" &&
      filterObject.activeInputNumberSearch !== undefined
    ) {
      service.query.addSearchParam(filterObject.activeInputNumberSearch);
      service.query.addSearchMatchParam("containing");
    }

    service.query.documentWithoutParent();
    service.query.documentWithPartner(filterObject.partnerFilter);

    service.query.clearDocumentDirection();
    switch (filterObject.activeDocumentDirectionFilter) {
      case constant.documentDirection.incoming:
        service.query.addDocumentDirectionIncoming();
        break;
      case constant.documentDirection.outgoing:
        service.query.addDocumentDirectionOutgoing();
        break;
      case "":
        service.query.clearDocumentDirection();
        break;
      default:
        break;
    }

    service.query.clearDocumentType();
    switch (filterObject.activeDocumentTypeFilter) {
      case constant.documentType.offer:
        service.query.addDocumentTypeOffer();
        break;
      case constant.documentType.invoice:
        service.query.addDocumentTypeInvoice();
        break;
      case constant.documentType.correction:
        service.query.addDocumentTypeInvoiceCorrection();
        break;
      case constant.documentType.delivery:
        service.query.addDocumentTypeDelivery();
        break;
      case constant.documentType.receipt:
        service.query.addDocumentTypeReceipt();
        break;
      case constant.documentType.order:
        service.query.addDocumentTypeOrder();
        break;
      case constant.documentType.inquiry:
        service.query.addDocumentTypeInquiry();
        break;
      case constant.documentType.other:
        service.query.addDocumentTypeOther();
        break;
      case "":
        service.query.clearDocumentType();
        break;
      default:
        break;
    }

    service.query.clearApprovalStatus();
    switch (filterObject.activeVerificationStatusFilter) {
      case constant.verificationStatus.verified:
        service.query.addDocumentVerificationStatusVerified();
        break;
      case constant.verificationStatus.disapproved:
        service.query.addDocumentVerificationStatusDisapproved();
        break;
      case constant.verificationStatus.unverified:
        service.query.addDocumentVerificationUnverified();
        break;
      case "":
        service.query.clearApprovalStatus();
        break;
      default:
        break;
    }
  };

  const [selectedLinkedDocumentsPreview, setSelectedLinkedDocumentsPreview] = useState();
  const [selectedDocSeparately, setSelectedDocSeparately] = useState(null);
  const [directionOfDocumentPreview, setDirectionOfDocumentPreview] = useState();

  const documentLinkThumbnailDetails = {
    onSelectButtonClick: (data, operation) => {
      handleChange(data, operation);
      setCurrentPanelState(constant.thumbnailsPanelState.form);
      setSelectedDocSeparately(null);
      setSelectedLinkedDocumentsPreview();
    },

    onBackButtonClick: () => {
      setCurrentPanelState(constant.thumbnailsPanelState.form);
      setSelectedDocSeparately(null);
      setSelectedLinkedDocumentsPreview();
    },

    onThumbClick: (data, doc, listVIew) => {
      setDirectionOfDocumentPreview(listVIew);
      setSelectedLinkedDocumentsPreview(data);
      setSelectedDocSeparately(doc);
      setCurrentPanelState(constant.thumbnailsPanelState.documentPreview);
    }
  };

  const handleParentChange = data => {
    let exParent = thumbsToSend.filter(thumbItem => thumbItem.id === Number(data));
    setThumb([...thumb, { ...exParent[0], isParent: false }]);
    setNewlyCreatedDocument({ ...currentDocument, parentDocument: null });
    setThumbsToSend(thumbsToSend.filter(thimbItem => thimbItem.id !== Number(data)));
    if (!editedFields.includes("parentDocument")) {
      const newArr = [...editedFields];
      newArr.push("parentDocument");
      setEditedFields(newArr);
    }
  };

  const handleChange = (data, operation) => {
    let newThumbs = [];
    let thumbsToLink = [];
    let newArr = [];
    if (operation === "add") {
      // eslint-disable-next-line array-callback-return
      thumb.map(doc => {
        if (doc.id === Number(data)) {
          thumbsToLink.push(doc);
        } else {
          newThumbs.push(doc);
        }
      });
      newArr = union(thumbsToLink, thumbsToSend);
    } else {
      // eslint-disable-next-line array-callback-return
      thumbsToSend.map(doc => {
        if (doc.id === Number(data)) {
          newThumbs = union([doc], thumb);
        } else {
          newArr.push(doc);
        }
      });
    }

    setThumb(newThumbs);
    setNewlyCreatedDocument({ ...currentDocument, documents: newArr });
    setThumbsToSend(newArr);
    if (!editedFields.includes("documents")) {
      const newArr = [...editedFields];
      newArr.push("documents");
      setEditedFields(newArr);
    }
  };

  const onSaveClicked = () => {
    props.handler.documentForm.onUpdateDocumentSave(
      { ...currentDocument, editedFields: editedFields },
      thumbsToSend.filter(thumbItem => thumbItem.isParent !== true)
    );
  };

  const handleDropdownOnChange = (event, data) => {
    let filterObject;
    if (data.name === "direction") {
      const activeDocumentDirectionFilter =
        thumbState.activeDocumentDirectionFilter === data.value
          ? constant.documentDirection.all
          : data.value;
      setThumbState({
        ...thumbState,
        activeDocumentDirectionFilter: activeDocumentDirectionFilter
      });

      filterObject = {
        ...filterObject,
        activeDocumentDirectionFilter: data.value
      };
      setDirection(data.value);
    }
    if (data.name === "type") {
      const activeDocumentTypeFilter =
        thumbState.activeDocumentTypeFilter === data.value ? constant.documentType.all : data.value;
      setThumbState({
        ...thumbState,
        activeDocumentTypeFilter: activeDocumentTypeFilter
      });

      filterObject = {
        ...filterObject,
        activeDocumentTypeFilter: data.value
      };
      setType(data.value);
    }
    if (data.name === "verification") {
      const activeVerificationStatusFilter =
        thumbState.activeVerificationStatusFilter === data.value
          ? constant.verificationStatus.all
          : data.value;
      setThumbState({
        ...thumbState,
        activeVerificationStatusFilter: activeVerificationStatusFilter
      });

      filterObject = {
        ...filterObject,
        activeVerificationStatusFilter: data.value
      };
      setVerification(data.value);
    }
    if (data.name === "search") {
      clearTimeout(delayTimer);
      setThumbState({
        ...thumbState,
        activeInputNumberSearch: data.value ? data.value : ""
      });
    }

    getAllService(filterObject);
  };

  return (
    <>
      {currentPanetState === constant.thumbnailsPanelState.form ? (
        <>
          <div className={styles.bms_header}>
            {" "}
            {Localize.get("DOCUMENTS.THUMBNAILS.LINK_DOCUMENTS")}
          </div>
          <Dimmer active={isLoadingForm} inverted>
            <Loader size="large" disabled={!isLoadingForm}>
              {Localize.get("DOCUMENTS.FORM.DOCUMENT_LOADER")}
            </Loader>
          </Dimmer>
          <>
            <Form
              className={styles.linkForm}
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              <div className={styles.searchBtn}>
                <Input
                  autoComplete={constant.autoComplete.off}
                  className={styles.marginBottom}
                  name="search"
                  icon="search"
                  placeholder={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_UNLINKED_PLACEHOLDER")}
                  onChange={handleDropdownOnChange}
                />
                <Dropdown
                  name="direction"
                  className={styles.marginBottom}
                  onChange={handleDropdownOnChange}
                  clearable
                  placeholder={Localize.get("TRANSACTIONS.DIRECTION.HEADER_DIRECTION")}
                  options={directionOptions()}
                  value={direction}
                  selection
                  size="mini"
                />
                <Dropdown
                  className={styles.marginBottom}
                  search
                  selection
                  name="type"
                  onChange={handleDropdownOnChange}
                  clearable
                  placeholder={Localize.get("DOCUMENTS.THUMBNAILS.TYPE")}
                  options={documentTypeOptions()}
                  value={type}
                />
                <Dropdown
                  placeholder={Localize.get("DOCUMENTS.THUMBNAILS.VERIFICATION_STATUS")}
                  className={styles.marginBottom}
                  search
                  selection
                  name="verification"
                  onChange={handleDropdownOnChange}
                  clearable
                  options={verificationOptions()}
                  value={verification}
                />
              </div>

              <Segment>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted>{Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : null}
                <>
                  <DocumentLinkThumbnailList
                    listName={"DOCUMENTS.THUMBNAILS.DOCUMENT_UNLINKED"}
                    documentLinkThumbnailDetails={documentLinkThumbnailDetails}
                    thumb={thumb}
                    handleChange={handleChange}
                    handler={props.handler}
                    currentDocumentId={currentDocument.id}
                    emptyListMessage={"DOCUMENTS.THUMBNAILS.LIST_EMPTY_UNLINKED"}
                  />
                  <DocumentLinkThumbnailList
                    listName={"DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED"}
                    documentLinkThumbnailDetails={documentLinkThumbnailDetails}
                    currentDocumentType={currentDocument.documentType}
                    thumb={thumbsToSend}
                    handleChange={handleChange}
                    handleParentChange={handleParentChange}
                    handler={props.handler}
                    emptyListMessage={"DOCUMENTS.THUMBNAILS.LIST_EMPTY_LINKED"}
                  />
                </>
              </Segment>
            </Form>

            <GlobalSaveNewCancelBtn
              onSave={onSaveClicked}
              onCancel={handler.documentForm.onCancelButtonClick}
            />
          </>
        </>
      ) : (
        <DocumentLinkThumbnailDetails
          state={props.state}
          handler={documentLinkThumbnailDetails}
          selectedLinkedDocumentsPreview={selectedLinkedDocumentsPreview}
          document={currentDocument}
          directionOfDocumentPreview={directionOfDocumentPreview}
          selectedDocSeparately={selectedDocSeparately}
        />
      )}
    </>
  );
};

export default DocumentLinkThumbnails;
