import React from "react";
import GlobalList from "../Components/Lists/GlobalList";
import {
  availableItemFieldsForListProperties,
  availableItemFieldsForListUsers,
  availableItemFieldsForListRoles,
  availableItemFieldsForListAccounting,
  availableItemFieldsForListDunning,
  availableItemFieldsForListDefaultTemplates
} from "./SettingsConfig";
import * as constants from "./Constants";
import styles from "./style/SettingsMiddlePanel.module.css";

const RolesMiddlePanel = props => {
  const {
    currentView,
    listState = [],
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    searchFilter,
    filterObject,
    currentModule
  } = props;

  const getFieldsForList = () => {
    switch (currentModule) {
      case constants.panelModule.properties:
        return availableItemFieldsForListProperties;
      case constants.panelModule.users:
        return availableItemFieldsForListUsers;
      case constants.panelModule.roles:
        return availableItemFieldsForListRoles;
      case constants.panelModule.accounting:
        return availableItemFieldsForListAccounting;
      case constants.panelModule.dunning:
        return availableItemFieldsForListDunning;
      case constants.panelModule.default_templates:
        return availableItemFieldsForListDefaultTemplates;
      case constants.panelModule.accounts:
        return [];
      default:
        return [];
    }
  };

  return (
    <>
      <GlobalList
        currentView={currentView}
        listState={listState}
        searchFilter={searchFilter}
        filterObject={filterObject}
        forbidenResponse={forbidenResponse}
        availableItemFieldsForList={getFieldsForList()}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        paginationStatus={paginationStatus}
        handlePageChange={handlePageChange}
        activePaginationPage={activePaginationPage}
        listPaginationCustomCSS={
          paginationStatus.pageCount > 1 ? styles.middle_panel_list : styles.middle_panel_list_0
        }
        showAtBottom={true}
        currentModule={currentModule}
      />
    </>
  );
};

export default RolesMiddlePanel;
