import { useRef, useEffect } from "react";

function useIsMounted() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [isMounted.current];
}

export default useIsMounted;
