import { useContext } from "react";
import styles from "./style/UsersDetails.module.css";
import { AuthContext } from "../Auth/AuthContext";

export const availableItemFieldsForListProperties = [
  {
    name: "subcategoryName",
    hederName: "SETTINGS.HEADERS.PROPERTY_TYPE",
    type: "prop_translateKey",
    width: 8,
    visible: true,
    column_customized_css: styles.first_header_only
  }
];
export const availableItemFieldsForListUsers = [
  {
    name: "firstName",
    hederName: "SETTINGS.HEADERS.FIRST_NAME",
    type: "text",
    width: 3,
    visible: true,
    column_customized_css: styles.first_header_only
  },
  {
    name: "lastName",
    hederName: "SETTINGS.HEADERS.LAST_NAME",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "username",
    hederName: "SETTINGS.HEADERS.USER_NAME",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "email",
    hederName: "SETTINGS.HEADERS.EMAIL",
    type: "text",
    width: 5,
    visible: true
  }
];
export const availableItemFieldsForListRoles = [
  {
    name: "name",
    hederName: "SETTINGS.HEADERS.ROLE_NAME",
    type: "text",
    width: 5,
    visible: true,
    column_customized_css: styles.first_header_only
  }
];

export const availableItemFieldsForListAccounting = [
  {
    name: "subcategoryName",
    hederName: "SETTINGS.HEADERS.ACCOUNTING",
    type: "prop_translateKey",
    width: 5,
    visible: true,
    column_customized_css: styles.first_header_only
  }
];

export const availableItemFieldsForListDunning = [
  {
    name: "subcategoryName",
    hederName: "SETTINGS.HEADERS.ACCOUNTING",
    type: "prop_translateKey",
    visible: true,
    width: 5,
    column_customized_css: styles.first_header_only
  }
];

export const availableItemFieldsForListDefaultTemplates = [
  {
    name: "name",
    hederName: "SETTINGS.HEADERS.DEFAULT_TEMPLATE_CATEGORIES",
    type: "translateKey",
    visible: true,
    width: 8,
    column_customized_css: styles.first_header_only
  }
];

const checkSubModule = myModule => {
  switch (myModule) {
    case "settings/categories/1/subcategories":
      return {
        type: "createNew",
        buttonText: "SETTINGS.BUTTONS.CREATE_PROPERTY",
        disabled: true
      };
    case "users":
      return { type: "createNew", buttonText: "SETTINGS.BUTTONS.CREATE_USER" };
    case "roles":
      return { type: "createNew", buttonText: "SETTINGS.BUTTONS.CREATE_ROLE" };
    case "settings/categories/2/subcategories":
      return {
        type: "createNew",
        buttonText: "SETTINGS.BUTTONS.CREATE_SYSTEM",
        disabled: true
      };
    case "settings/templates":
      return {
        type: "createNew",
        buttonText: "SETTINGS.BUTTONS.CREATE_SYSTEM",
        disabled: true
      };

    case "settings/dunning":
      return {
        type: "createNew",
        buttonText: "SETTINGS.BUTTONS.CREATE_DUNNING",
        disabled: true
      };

    default:
      return {
        type: "createNew",
        buttonText: "SETTINGS.BUTTONS.CREATE_USER",
        disabled: true
      };
  }
};
export const leftPanelConfig = currentModule => {
  const myCreateNewButton = checkSubModule(currentModule);
  const { customConfig } = useContext(AuthContext);

  return [
    {
      ...myCreateNewButton
    },
    {
      type: "sectionHeader",
      headerText: "SETTINGS.SIDEBAR_HEADERS.GENERAL_SETTINGS",
      itemList: customConfig.settings.generalItemList
    },
    {
      type: "sectionHeader",
      headerText: "SETTINGS.SIDEBAR_OPTIONS.TEMPLATES_SETTINGS",
      itemList: [
        {
          type: "submodule",
          name: "settings/templates",
          itemText: "SETTINGS.SIDEBAR_OPTIONS.TEMPLATE"
        },
        {
          type: "submodule",
          name: "categories",
          itemText: "SETTINGS.SIDEBAR_OPTIONS.DEFAULT_TEMPLATES"
        }
      ]
    }
  ];
};
