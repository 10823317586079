export const BASE = {
  method: {
    get: "GET",
    put: "PUT",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE"
  },
  protocol: {
    http: "http",
    https: "https"
  },
  host: "localhost",
  port: "8082",
  apiPath: "/api/v1",
  resource: {
    partners: "/partners",
    partnersCount: "/partners/count",
    employees: "/partners/employees",
    employeesCount: "/partners/employees/count",
    persons: "/partners/persons",
    personsCount: "/partners/persons/count",
    companies: "/partners/companies",
    companiesCount: "/partners/companies/count",
    documents: "/documents",
    documentsCount: "/documents/count",
    documentsComment: "/documentsHistory/document",
    documentsCommentCreate: "/documentsHistory",
    documentExport: "/documents/zip",
    documentPackage: "/documents/packages",
    documentGeneratePDF: "/documents/pdfs",
    documentDownload: "/documents/download",
    documentSendEmails: "/documents/template-categories/mail-inv/mails",
    documentPackageContent: "/documents/packagecontent",
    assignProject: "/documents/assignProject",
    accounts: "/accounts",
    paypalAccounts: "/accounts/paypal",
    accountsCount: "/accounts/count",
    accountsRefresh: "/accounts/update",
    accountsGetRefresh: "/accounts/bankConnection",
    accountsBankName: "/accounts/bank",
    transactions: "/transactions",
    transactionsCount: "/transactions/count",
    payments: "/payments",
    paymentsManual: "/payments/manual",
    projects: "/projects",
    projectsCount: "/projects/count",
    projectRoles: "/projects/roles",
    projectTypes: "/projects/types",
    projectTemplates: "/projects/templates",
    projectStatuses: "/projects/externalstatuses",
    properties: "/settings/subcategories/1/properties",
    token: "/token",
    users: "/users",
    files: "/files",
    ocrRead: "/files/read",
    logout: "/logout",
    scanedFiles: "/files/getSubmittedFiles",
    syncWithFFO: "/invoices/imports/FFO",
    syncPartnerFFO: "/partners/imports/FFO",
    generateInvoices: "/invoices/generate",
    verifyAll: "partners/verifyAll"
  },
  pathParameter: null,
  pathParameters: [],
  query: ""
};

export const SORT = {
  asc: "asc",
  desc: "desc"
};

export const DEFAULTCONFIGURATION = {
  method: BASE.method.get,
  // protocol: BASE.protocol.https,
  protocol: BASE.protocol.http,
  host: BASE.host,
  port: BASE.port,
  headers: [],
  apiPath: BASE.apiPath,
  resource: "",
  query: null
};

export const PARTNER = {
  type: {
    employee: "employee",
    company: "company",
    person: "person"
  },
  role: {
    customer: "customer",
    collective: "collective",
    supplier: "supplier",
    customer_supplier: "customer_supplier"
  },
  archived: {
    yes: true,
    no: false
  },
  query: {
    page: "page",
    size: "size",
    sort: "sort",
    partnerType: "partnerType",
    partnerRole: "partnerRole",
    archived: "archived",
    search: "search",
    match: "match",
    name: "name"
  }
};

export const DOCUMENT = {
  type: {
    inquiry: "inquiry",
    offer: "offer",
    order: "order",
    receipt: "receipt",
    delivery: "delivery",
    invoice: "invoice",
    correction: "correction",
    other: "other"
  },
  direction: {
    incoming: "incoming",
    outgoing: "outgoing"
  },
  verification: {
    verified: "verified",
    disapproved: "disapproved",
    unverified: "unverified"
  },
  payment: {
    approved: "approved",
    declined: "declined",
    unresolved: "unresolved",
    paid: "paid"
  },
  paymentStatus: {
    open: "open",
    pending: "pending",
    notPaid: "not_paid",
    successful: "successful",
    notSuccessful: "not_successful",
    discarded: "discarded",
    paid: "paid",
    notRelevant: "not_relevant",
    partialPaid: "partially_paid"
  },
  query: {
    page: "page",
    size: "size",
    sort: "sort",
    documentType: "type",
    documentDirection: "direction",
    approved: "approved",
    verification: "verification",
    documentNumber: "documentNumber"
  },
  archived: {
    active: false,
    archived: true
  }
};

export const ACCOUNT = {
  type: {
    bank: "bank",
    paypal: "paypal"
  },
  query: {
    page: "page",
    size: "size",
    sort: "sort",
    accountType: "accountType"
  }
};

export const TRANSACTION = {
  assignment: {
    linked: "linked",
    unlinked: "unlinked",
    linkedWithoutDocument: "linked_without_document"
  },
  direction: {
    revenue: "revenue",
    expense: "expense"
  },
  query: {
    page: "page",
    size: "size",
    sort: "sort",
    transactionDirection: "transactionDirection",
    transactionAssignment: "transactionAssignment",
    minDate: "minDate",
    maxDate: "maxDate"
  }
};

export const PROJECT = {
  status: {
    new: "new",
    closed: "closed",
    process: "in_process"
  },
  archived: {
    archived: true,
    active: false
  }
};
