import React, { useEffect } from "react";
import { createGetAllService } from "../../Components/Utils/GlobalUtil";
import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import Localize from "react-intl-universal";
import { Message, Segment, Icon } from "semantic-ui-react";
import styles from "../style/PropertiesDetails.module.css";

const PropertiesDetails = props => {
  const { selectedItem, customProps, availableItemFieldsForDetails } = props;

  const { selectedProperties, setSelectedProperties, setCurrentView } = customProps;

  const callBackGetAllSuccess = response => {
    let myData = response.data.data.content;
    let myNewData;
    if (selectedItem.name.subcategoryName === "external_settings") {
      myNewData = myData.reduce(
        (acc, currentObject) => {
          if (acc.arrType.includes(currentObject.subType)) {
            let newArr = [...acc.objOfArrays[currentObject.subType]];
            newArr.push(currentObject);
            acc.objOfArrays[currentObject.subType] = newArr;
          } else {
            let newArr = [];
            let newArrOfTypes = [...acc.arrType];
            newArr.push(currentObject);
            newArrOfTypes.push(currentObject.subType);
            acc.objOfArrays[currentObject.subType] = newArr;
            acc.arrType = newArrOfTypes;
          }
          return acc;
        },
        {
          objOfArrays: {},
          arrType: []
        }
      );
    } else {
      myNewData = myData;
    }

    setSelectedProperties(myNewData);
  };
  const callBackGetAllError = response => {};
  const callBackGetAllFinally = response => {};

  let newService = createGetAllService(
    callBackGetAllSuccess,
    callBackGetAllError,
    callBackGetAllFinally,
    `settings/subcategories/${selectedItem.name.id}/properties`
  );

  useEffect(() => {
    newService.call();
  }, [selectedItem]);

  const findProperty = (propertyName, mySelectedProperties) => {
    let tempItem = mySelectedProperties.find(item => item.name === propertyName);
    return tempItem ? tempItem.value : "";
  };

  const getPropertiesDetails = (listOfProperties, mySelectedProperties) => {
    if (listOfProperties.length === 0) {
      return <Message>{Localize.get("PROPERTIES.NO_PROPERTIES")}</Message>;
    }
    return listOfProperties.map((field, index) => {
      return (
        <GlobalDetailsItem
          key={index}
          itemName={field.hederName}
          type={field.type}
          itemValue={findProperty(field.name, mySelectedProperties)}
        />
      );
    });
  };

  const getSegment = (listOfProperties, myArray, index) => {
    return (
      <Segment.Group key={index}>
        <Segment className={styles.title}>
          <strong>{findProperty("integration.name", myArray)}</strong>
          <Icon
            name="edit"
            className={styles.edit_icon}
            size={"large"}
            onClick={() => {
              setCurrentView("edit");
              setSelectedProperties(myArray);
            }}
          />
        </Segment>
        <Segment className={styles.form_lines}>
          {getPropertiesDetails(listOfProperties, myArray)}
        </Segment>
      </Segment.Group>
    );
  };

  const getDetailsForExternalSystems = (listOfProperties, myProperties) => {
    let mySubTypes = myProperties && myProperties.arrType ? myProperties.arrType : [];
    return mySubTypes.map((subType, index) =>
      getSegment(listOfProperties, myProperties.objOfArrays[subType], index)
    );
  };

  return (
    <>
      {selectedItem.name.subcategoryName === "external_settings"
        ? selectedProperties &&
          getDetailsForExternalSystems(availableItemFieldsForDetails, selectedProperties)
        : selectedProperties &&
          Array.isArray(selectedProperties) &&
          getPropertiesDetails(availableItemFieldsForDetails, selectedProperties)}
    </>
  );
};
export default PropertiesDetails;
