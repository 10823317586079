import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ConfirmCancelDialog.module.css";
import GlobalOkCancelBtn from "../Components/GlobalOkCancelBtn";

const ConfirmCancelDialog = props => {
  const {
    openModal,
    modalHeader,
    modalContent,
    onModalCancelClick,
    onModalConfirmClick,
    modalColor
  } = props;

  return (
    <>
      <Modal
        size="tiny"
        dimmer="inverted"
        open={openModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header
          className={styles.modal_header}
          style={{ backgroundColor: modalColor ? modalColor : "#2185d0" }}
        >
          {modalHeader}
        </Modal.Header>
        <Modal.Content scrolling={true}>{modalContent}</Modal.Content>
        <Modal.Actions>
          <GlobalOkCancelBtn
            onConfirmClicked={onModalConfirmClick}
            onCancel={onModalCancelClick}
            btnText={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ConfirmCancelDialog;
