import CountryList from "./countriesNameAndCodes.json";

export const countriesList = () => {
  let coutryOptions = CountryList[0].list;

  if (navigator.language.toLowerCase().includes("de")) {
    coutryOptions = CountryList.find(countryItem => countryItem.country.includes("de")).list;
  }

  return coutryOptions;
};
