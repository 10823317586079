import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Sidebar, Segment, Dimmer, Loader } from "semantic-ui-react";

import ProjectTemplateSideBar from "./LeftPanel/ProjectTemplateSideBar.js";
import ProjectTemplateItemList from "./MiddlePanel/ProjectTemplateItemList";
import ProjectTemplatePanel from "./RightPanel/ProjectTemplatePanel";
import { ProjectTemplate } from "../../RestService";
import { GetUpdateObject, convertToJson, convertToRoles } from "./Util.js";
import { AuthContext } from "../../Auth/AuthContext";
import Localize from "react-intl-universal";
import showNotification from "../../Notifications/NotificationFunction";
import * as constant from "./Constants";
import * as componentsConstants from "../../Components/Constants";
import styles from "./style/ProjectTemplates.module.css";
import useArchiveFilter from "../../CustomHooks/useArchiveFilter";
import ConfirmArchiveDialog from "../../Dialogs/ConfirmArchiveDialog";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog";
import {
  compareListWithListOfIds,
  togleOne,
  togleAll
} from "../../Components/Utils/GlobalListsUtil";
import ProjectTemplateItem from "./MiddlePanel/ProjectTemplateItem";

const ProjectTemplates = ({ setHeaderDimmed }) => {
  // eslint-disable-next-line no-unused-vars
  const { authState, rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["projectTemplate"];
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [panelState, setPanelState] = useState({});
  const [deleteItem, setDeleteItem] = useState();
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [templateListState, setTemplateListState] = useState({
    ...constant.templateListInitState(templates)
  });
  const [paginationStatus, setPaginationStatus] = useState(
    componentsConstants.paginationInitStatus
  );
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [archiveBtnEnable, setArchiveBtnEnable] = useState(true);
  const [archiveFilter, bindArchive, resetArchive] = useArchiveFilter(
    false,
    setActivePaginationPage
  );
  const [showArchiveModal, setShowArchiveModal] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [cannotBeDeleted, setCannotBeDeleted] = useState([]);
  const [scrollToBottom, setScrollToButtom] = useState(false);

  let selectedIndex = null;
  let selectedTemplate = null;
  let isSaveAndNew = null;
  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const [sideBarVisibility, setSideBarVisibility] = useState(false);

  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const showNotificationMessage = param => {
    // NOTE: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.TEMPLATE"),
      ...param
    });
  };

  const callbackGetAllSuccess = response => {
    const templatesList = convertToRoles(response.data.data);
    const templates = response.data;

    setTemplates(templatesList);

    if (selectedIndex !== null) {
      selectedTemplate = templates.data[selectedIndex].id;
    }

    if (!selectedTemplate && templates.data.length > 0) {
      selectedTemplate = templates.data[0].id;
    }
    setTemplateListState({
      ...templateListState,
      selectAll: false,
      activeListItem: selectedTemplate
    });

    selectedIndex === 19 ? setScrollToButtom(true) : setScrollToButtom(false);

    setPaginationStatus({
      perPage: templates.paging.perPage,
      pageCount: templates.paging.pageCount,
      totalCount: templates.paging.totalCount
    });

    setPanelState({
      ...panelState,
      activeMode: constant.panelMode.details,
      template: templates.data.find(template => template.id === selectedTemplate)
    });

    if (isSaveAndNew) {
      setPanelState({
        ...panelState,
        activeMode: constant.panelMode.create,
        template: undefined
      });
      isSaveAndNew = null;
    }
  };

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {
    setIsLoading(false);
  };

  const getAllProjectTemplatesService = new ProjectTemplate.GetAll.Service();
  getAllProjectTemplatesService.query.setPage(activePaginationPage - 1);

  getAllProjectTemplatesService.setCallbackSuccess(callbackGetAllSuccess);
  getAllProjectTemplatesService.setCallbackError(callbackGetAllError);
  getAllProjectTemplatesService.setCallbackFinally(callbackGetAllFinally);

  const callbackCreateSuccess = async response => {
    selectedTemplate = response.data.id;
    setActivePaginationPage(1);
    showNotificationMessage({
      type: "info",
      operation: "create"
    });
  };

  const callbackCreateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "createFailed"
    });
  };

  const callbackCreateFinally = (response, saveAndNew) => {
    if (!saveAndNew) setEditMode(false);

    if (activePaginationPage === 1) {
      getAllProjectTemplatesService.call();
    } else {
      setActivePaginationPage(1);
    }
  };

  const createNewProjectTemplateServiceCall = (template, saveAndNew) => {
    const projectTemplateService = new ProjectTemplate.Create.Service();

    projectTemplateService.setPayload(template);
    projectTemplateService.setCallbackSuccess(callbackCreateSuccess);
    projectTemplateService.setCallbackError(callbackCreateError);
    projectTemplateService.setCallbackFinally(res => {
      callbackCreateFinally(res, saveAndNew);
    });
    projectTemplateService.call();
  };

  const updateProjectTemplateServiceCall = async (updatedObject, isSingleUpdate) => {
    const templateId = templateListState.activeListItem;
    const projectTemplateService = new ProjectTemplate.Update.Service(isSingleUpdate && templateId);
    if (updatedObject) {
      selectedTemplate = templateId;
      projectTemplateService.setPayload(updatedObject);
      projectTemplateService.setCallbackSuccess(callbackUpdateSuccess);
      projectTemplateService.setCallbackError(callbackUpdateError);
      projectTemplateService.setCallbackFinally(callbackUpdateFinally);
      projectTemplateService.call();
    }
  };
  const callbackUpdateSuccess = response => {
    showNotificationMessage({
      type: "info",
      operation: "update"
    });
  };

  const callbackUpdateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "update"
    });
  };

  const callbackUpdateFinally = response => {
    setCannotBeDeleted([]);
    setEditMode(false);
    resolveAdditionalFilters(getAllProjectTemplatesService, templateListState, archiveFilter);
    selectedTemplate = null;

    getAllProjectTemplatesService.call();
  };

  const deleteProjectTemplateServiceCall = id => {
    const projectTemplateService = new ProjectTemplate.Delete.Service(id);

    projectTemplateService.setPayload();
    projectTemplateService.setCallbackSuccess(res => callbackDeleteSuccess(id, res));
    projectTemplateService.setCallbackError(callbackDeleteError);
    projectTemplateService.setCallbackFinally(callbackDeleteFinally);
    projectTemplateService.call();
  };

  const callbackDeleteSuccess = (firstSelectedId, response) => {
    let firstIdNumber = firstSelectedId.match(/(\d+)/)[0];
    let firstDeletedIndex = templates.findIndex(element => element.id === parseInt(firstIdNumber));
    if (firstDeletedIndex !== 0) {
      selectedIndex = firstDeletedIndex - 1;
    } else {
      if (activePaginationPage > 1) {
        selectedIndex = 19;
        getAllProjectTemplatesService.query.setPage(activePaginationPage - 2);
        setActivePaginationPage(activePaginationPage - 1);
      } else {
        selectedIndex = firstDeletedIndex;
      }
    }

    if (response && response.status === 226) {
      if (!bindArchive.active) {
        let myArr = JSON.parse(response.data.message);
        let myList = compareListWithListOfIds(myArr, templates);
        setCannotBeDeleted(myList);
        setShowArchiveModal("cannotBeDeleted");
      }
    } else {
      showNotificationMessage({
        type: "info",
        operation: "delete"
      });
      setShowArchiveModal("");
    }
    getAllProjectTemplatesService.call();
  };

  const callbackDeleteError = error => {
    const res = error.response.data;
    const { message } = res;
    let errorMessage;
    message
      ? (errorMessage = Localize.get(message.replace(/[{}]/g, "")))
      : (errorMessage = Localize.get("PROJECTS.TEMPLATES.ERROR"));
    setErrorMessage(errorMessage);
    setShowModalError(true);
  };

  const callbackDeleteFinally = response => {};

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });

    resolveAdditionalFilters(getAllProjectTemplatesService, templateListState, archiveFilter);

    let isSubscribed = true;

    delayTimer = setTimeout(function() {
      if (isSubscribed && !firstBuild) {
        setIsLoading(true);
        getAllProjectTemplatesService.call();
      }
    }, 800);
    setFirstBild(false);
    return () => (isSubscribed = false);
  }, [templateListState.name, templateListState.search, templateListState.description]);

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
    clearTimeout(delayTimer);
    resolveAdditionalFilters(getAllProjectTemplatesService, templateListState, archiveFilter);

    setIsLoading(true);
    getAllProjectTemplatesService.call();
  }, [
    templateListState.sortColumn,
    templateListState.sortDirection,
    activePaginationPage,
    archiveFilter
  ]);

  setHeaderDimmed(
    panelState.activeMode === "create" || panelState.activeMode === "edit" || isLoading
  );

  const handle = {
    templatePanel: {
      details: {
        onEditClick: template => {
          setPanelState({
            ...panelState,
            activeMode: constant.panelMode.edit,
            template: template
          });
          setEditMode(true);
        },
        onDeleteItemClick: item => {
          setDeleteItem(item);
          setShowDeleteModal("singledelete");
        },
        onArchiveClick: item => {
          setDeleteItem(item);
          setShowArchiveModal("singlearchive");
          setArchiveBtnEnable(false);
        }
      },
      templateFormPanel: {
        onSaveButtonClick: newTemplate => {
          const newTemplateObject = convertToJson(newTemplate);
          setIsLoading(true);
          if (panelState.activeMode === "create") {
            createNewProjectTemplateServiceCall(newTemplateObject);
          }
          if (panelState.activeMode === "edit") {
            if (newTemplateObject.editedFields && newTemplateObject.editedFields.length > 0) {
              let updatedObject = GetUpdateObject(newTemplateObject);
              updateProjectTemplateServiceCall(updatedObject, true);
            } else {
              onCancelBtnClick();
            }
          }
        },
        onSaveAndNewButtonClick: newTemplate => {
          const newTemplateObject = convertToJson(newTemplate);
          isSaveAndNew = true;
          createNewProjectTemplateServiceCall(newTemplateObject, true);
        },
        onCloseButtonClick: () => {
          onCancelBtnClick();
        }
      }
    },
    templateSideBar: {
      createNewTemplateButtonClick: (event, data) => {
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.create,
          template: undefined
        });
        setEditMode(true);
        setSideBarVisibility(false);
      }
    },
    templateList: {
      onListItemClick: itemId => {
        setTemplateListState({ ...templateListState, activeListItem: itemId });
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.details,
          template: templates.find(template => template.id === itemId)
        });
      }
    }
  };

  const handlerToolbar = {
    onDeleteClick: () => {
      let m = templates.filter(item => item.checked === true);
      if (m.length > 0) {
        setShowDeleteModal("multipledelete");
      }
    },
    onArchiveClick: () => {
      let m = templates.filter(item => item.checked === true);
      if (m.length > 0) {
        setShowArchiveModal("multiplearchive");
        setArchiveBtnEnable(false);
      }
    }
  };

  const handlerHeader = {
    onSearchChange: async (e, data, reset = false) => {
      clearTimeout(delayTimer);
      reset
        ? setTemplateListState({ ...templateListState, search: "" })
        : setTemplateListState({
            ...templateListState,
            [e.target.name]: data.value
          });
      setActivePaginationPage(1);
    },
    onSortButtonClick: async (field, direction) => {
      setTemplateListState({
        ...templateListState,
        sortColumn: field,
        sortDirection: direction
      });
      setActivePaginationPage(1);
    },
    resetFiltersSearchsAndSorts: async () => {
      setTemplateListState(constant.templateListInitState(templates));
      setPanelState({
        ...panelState,
        template: templates.find(
          template => template.id === (templates[0] ? templates[0].id : undefined)
        )
      });
      setActivePaginationPage(1);
      resetArchive();
    }
  };

  const resolveAdditionalFilters = (service, filters, archiveFilter) => {
    const { name, description, search, sortColumn, sortDirection } = filters;
    // filter
    const filterArray = {
      search: search,
      name: name,
      description: description
    };

    // eslint-disable-next-line array-callback-return
    Object.keys(filterArray).map(column => {
      if (filterArray[column]) service.query.addCustomColumnSearch(column, filterArray[column]);
    });
    //archive
    if (archiveFilter) {
      getAllProjectTemplatesService.query.addCustomColumnSearch("archived", true);
    }
    // sort
    if (sortColumn) {
      service.query.addSortColumnParam(sortColumn);
      service.query.addSortDirectionParam(sortDirection);
    }

    return service;
  };

  const onCancelBtnClick = () => {
    setTemplateListState({
      ...templateListState,
      activeListItem: templateListState.activeListItem
    });
    setPanelState({
      ...panelState,
      activeMode: constant.panelMode.details,
      template: templates.find(template => template.id === templateListState.activeListItem)
    });
    setEditMode(false);
    setIsLoading(false);
  };

  const getDialogError = () => {
    let dialog = null;
    // eslint-disable-next-line no-unused-vars
    let modalColor = "#c00";

    let cancelHandler = () => setShowModalError(false);
    let modalHeader = Localize.get("PROJECTS.TEMPLATES.ERROR");
    let modalContent = errorMessage;
    if (showModalError) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={true}
          closeOnDimmerClick={false}
        >
          <Modal.Header className={styles.modals_header}>{modalHeader}</Modal.Header>
          <Modal.Content>{modalContent}</Modal.Content>

          <Modal.Actions>
            <Button onClick={cancelHandler} secondary>
              {Localize.get("GLOBAL.FORM.CANCEL")}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const handlePageChange = async pageInfo => {
    setTemplateListState({ ...templateListState, activeListItem: null });
    setActivePaginationPage(pageInfo.activePage);
  };

  const handleConfirmButtonClick = deleteOrArchiveOption => {
    switch (deleteOrArchiveOption) {
      case "singledelete":
        deleteProjectTemplateServiceCall("ids=" + deleteItem.id);
        selectedIndex = templates.findIndex(templete => templete.id === deleteItem.id);
        break;
      case "multipledelete":
        let selectedItems = templates.filter(item => item.checked).map(item => item.id);

        deleteProjectTemplateServiceCall("ids=" + selectedItems.join());
        break;
      case "singlearchive":
        let updatedTemplte = GetUpdateObject({
          ...deleteItem,
          archived: !deleteItem.archived,
          editedFields: ["archived"]
        });

        updateProjectTemplateServiceCall(updatedTemplte, true);
        break;
      case "multiplearchive":
        let updatedObjects = templates
          .filter(template => template.checked)
          .map(template => {
            template.archived = !template.archived;
            template.editedFields = ["archived"];
            let updatedObject = GetUpdateObject(template);
            return { id: template.id, operation: updatedObject };
          });
        updateProjectTemplateServiceCall(updatedObjects);
        break;
      case "cannotBeDeleted":
        let updatedObjects2 = cannotBeDeleted
          .filter(template => template.checked)
          .map(template => {
            template.archived = !template.archived;
            template.editedFields = ["archived"];
            let updatedObject = GetUpdateObject(template);
            return { id: template.id, operation: updatedObject };
          });
        updateProjectTemplateServiceCall(updatedObjects2);
        break;
      default:
        break;
    }
    setArchiveBtnEnable(false);
    setShowArchiveModal("");
    setShowDeleteModal("");
  };

  const handleDeclineButtonClicked = () => {
    setArchiveBtnEnable(false);
    setShowArchiveModal("");
    setShowDeleteModal("");
    setCannotBeDeleted([]);
    setIsLoading(false);
  };

  let mainClass = styles.full_width;
  // eslint-disable-next-line no-unused-vars
  let class2 = `ui three column stackable grid ${styles.three_column_grid}`;

  const mytogleItem = data => {
    if (data.checked) {
      togleOne(data.id, setCannotBeDeleted);
      setArchiveBtnEnable(true);
    } else {
      setArchiveBtnEnable(false);
      togleOne(data.id, setCannotBeDeleted);
    }
  };

  const onReset = () => {
    togleAll(false, setTemplates);
    setTemplateListState({ ...templateListState, selectAll: false });
  };

  const togleItem = data => {
    togleOne(data.id, setTemplates);
  };

  const togleAllFunc = (e, data) => {
    let checked = data.checked;
    togleAll(checked, setTemplates);
    setTemplateListState({ ...templateListState, selectAll: checked });
  };

  let myListOfCannotBeDelited = cannotBeDeleted.map(item => (
    <ProjectTemplateItem
      key={item.id}
      privileges={privileges}
      template={item}
      togleItem={mytogleItem}
    />
  ));

  return (
    <>
      <div className={mainClass}>
        <div>
          <Dimmer active={isLoading || editMode} inverted>
            <Loader size="large" disabled={!isLoading}>
              {Localize.get("PROJECTS.TEMPLATES.PROJECT_TEMPLATE_LOADER")}
            </Loader>
          </Dimmer>
          <div
            className={`ui right attached button ${styles.bms_fixed} ${
              sideBarVisibility || editMode ? styles.bms_zindex : null
            }`}
            onClick={settingVisibleSidebar}
          >
            <i className="ellipsis vertical icon"></i>
          </div>

          <Sidebar.Pushable
            as={Segment}
            className={editMode ? styles.bms_segment_zi : styles.bms_segment}
          >
            <ProjectTemplateSideBar
              state={templateListState}
              privileges={privileges}
              handle={handle.templateSideBar}
              sideBarVisibility={sideBarVisibility}
              setSideBarVisibility={setSideBarVisibility}
              bindArchive={bindArchive}
            />
            <Sidebar.Pusher dimmed={sideBarVisibility} className={editMode ? styles.bms_zi : null}>
              <div className={styles.bms_partners_content}>
                <div className={`ui two column stackable grid padded ${styles.grid_middle_panel}`}>
                  <ProjectTemplateItemList
                    templates={templates}
                    setTemplates={setTemplates}
                    privileges={privileges}
                    onListItemClick={handle.templateList.onListItemClick}
                    handlerHeader={handlerHeader}
                    handlerToolbar={handlerToolbar}
                    state={templateListState}
                    editMode={editMode}
                    listDimmed={editMode}
                    handlePageChange={handlePageChange}
                    paginationStatus={paginationStatus}
                    activePaginationPage={activePaginationPage}
                    archiveFilter={archiveFilter}
                    togleAllFunc={togleAllFunc}
                    togleItem={togleItem}
                    onReset={onReset}
                    scrollToBottom={scrollToBottom}
                  />
                  <ProjectTemplatePanel
                    state={panelState}
                    editMode={editMode}
                    privileges={privileges}
                    handle={handle.templatePanel}
                  />
                </div>
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
        <div>
          <ConfirmArchiveDialog
            showModal={
              showArchiveModal === "singlearchive" ||
              showArchiveModal === "multiplearchive" ||
              showArchiveModal === "cannotBeDeleted"
            }
            confirmClicked={() => handleConfirmButtonClick(showArchiveModal)}
            declineClicked={() => handleDeclineButtonClicked()}
            numberOfCheckedItems={templates.filter(templete => templete.checked === true).length}
            cannotBeDeleted={myListOfCannotBeDelited}
            activeArchiveFilter={!archiveFilter && ""}
            archiveBtnEnable={archiveBtnEnable}
          />
          <ConfirmDeleteDialog
            showModal={showDeleteModal === "singledelete" || showDeleteModal === "multipledelete"}
            confirmClicked={() => handleConfirmButtonClick(showDeleteModal)}
            declineClicked={() => handleDeclineButtonClicked()}
            numberOfCheckedItems={templates.filter(templete => templete.checked === true).length}
          />
          {getDialogError()}
        </div>
      </div>
    </>
  );
};

export default ProjectTemplates;
