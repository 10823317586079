import React, { useEffect } from "react";
import { Icon, Message, Dimmer, Dropdown, Input } from "semantic-ui-react";
import ProjectTemplateItem from "./ProjectTemplateItem";
import Localize from "react-intl-universal";
import ListWithPagination from "../../../Components/ListWithPagination";
import styles from "./style/ProjectTemplateItemList.module.css";
import ToolBar from "../../../Components/ToolBar";

const ProjectTemplateItemList = props => {
  const {
    privileges,
    editMode,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    archiveFilter,
    templates,
    handlerToolbar,
    onListItemClick,
    handlerHeader,
    togleAllFunc,
    togleItem,
    onReset,
    scrollToBottom
  } = props;

  const { name, description, sortColumn, sortDirection, selectAll, search } = props.state;

  const { onSearchChange, resetFiltersSearchsAndSorts } = handlerHeader;

  const templateList = templates.map(r => (
    <ProjectTemplateItem
      key={r.id}
      template={r}
      onListItemClick={onListItemClick}
      activeListItem={props.state.activeListItem}
      togleItem={togleItem}
    />
  ));

  let list = document.getElementById("listToScroll");

  useEffect(() => {
    if (scrollToBottom && list) {
      list.scrollTo(0, 1000);
    }
  }, [scrollToBottom]);

  const resetTolbarSearch = (e, data) => {
    onSearchChange(e, data, true);
  };

  const resetAllSearches = () => {
    onReset();
    resetFiltersSearchsAndSorts();
    // setSearchedValue("");
  };

  return (
    <div className={`column items_list ${styles.items_list}`}>
      <Dimmer active={editMode} inverted></Dimmer>
      <div className={styles.header}>
        <ToolBar
          emptyList={!(templateList.length > 0)}
          privileges={privileges}
          searchName={onSearchChange}
          selectAll={selectAll}
          enteredValue={search}
          resetNameSearch={resetTolbarSearch}
          resetSearches={resetAllSearches}
          archiveOrUnarchive={archiveFilter ? "archived" : "active"}
          onToggleAll={togleAllFunc}
          onDeleteClick={handlerToolbar.onDeleteClick}
          onArchiveClick={handlerToolbar.onArchiveClick}
          // deleteEnable is prepared for backend rules integration, see example on documents module
          deleteEnable={true}
          popupContentText={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PROJECT_ROLES_POPUP")}
          disableSearch={name !== "" || description !== ""}
        />
      </div>{" "}
      <div
        className={`ui six column stackable middle aligned grid ${styles.table_headerTemplateList}`}
      >
        <div className="row">
          <div className="two wide column" />
          <div className="three wide column">
            <h5>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NUMBER")}</h5>
          </div>
          <div className="four wide column">
            <Dropdown
              icon={null}
              trigger={
                <div className={styles.dropdown_trigger}>
                  <h5>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME")}</h5>
                  <div>
                    {sortDirection === "desc" && sortColumn === "name" ? (
                      <Icon name="angle down" />
                    ) : (
                      ""
                    )}
                    {sortDirection === "asc" && sortColumn === "name" ? (
                      <Icon name="angle up" />
                    ) : (
                      ""
                    )}
                    {name !== "" ? <Icon name="filter" /> : ""}
                  </div>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="sort amount up"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                  onClick={() => handlerHeader.onSortButtonClick("name", "asc")}
                />
                <Dropdown.Item
                  icon="sort amount down"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                  onClick={() => handlerHeader.onSortButtonClick("name", "desc")}
                />
                <Dropdown.Divider />
                <Input
                  name="name"
                  icon="search"
                  disabled={search !== "" && search !== null}
                  iconPosition="left"
                  className="search"
                  placeholder={Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME_ONLY")}
                  value={name}
                  onClick={e => e.stopPropagation()}
                  onChange={onSearchChange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="four wide column">
            <Dropdown
              icon={null}
              trigger={
                <div className={styles.dropdown_trigger}>
                  <h5> {Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DESCRIPTION")}</h5>
                  <div>
                    {sortDirection === "desc" && sortColumn === "description" ? (
                      <Icon name="angle down" />
                    ) : (
                      ""
                    )}
                    {sortDirection === "asc" && sortColumn === "description" ? (
                      <Icon name="angle up" />
                    ) : (
                      ""
                    )}
                    {description !== "" ? <Icon name="filter" /> : ""}
                  </div>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="sort amount up"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                  onClick={() => handlerHeader.onSortButtonClick("description", "asc")}
                />
                <Dropdown.Item
                  icon="sort amount down"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                  onClick={() => handlerHeader.onSortButtonClick("description", "desc")}
                />
                <Dropdown.Divider />
                <Input
                  name="description"
                  icon="search"
                  disabled={search !== "" && search !== null}
                  iconPosition="left"
                  className="search"
                  placeholder={Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME_ONLY")}
                  value={description}
                  onClick={e => e.stopPropagation()}
                  onChange={onSearchChange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {templateList.length > 0 ? (
        <ListWithPagination
          list={templateList}
          state={props.state}
          defaultActivePage={paginationStatus.pageCount}
          totalPage={paginationStatus.totalCount}
          handlePageChange={handlePageChange}
          activePaginationPage={activePaginationPage}
          perPage={paginationStatus.perPage}
        />
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("PROJECTS.TEMPLATES.EMPTY_LIST")}
        </Message>
      )}
    </div>
  );
};

export default ProjectTemplateItemList;
