import React from "react";
import { Dropdown, Popup, Label } from "semantic-ui-react";
import propTypes from "prop-types";
import styles from "./style/TableCellDropdownOut.module.css";

const TableCellDropdownOut = props => {
  const {
    upward,
    editedIndex,
    inputValue = "",
    inputName,
    dropdownOptions = {},
    placeholder,
    index,
    icon,
    customLabelClassName = "",
    customInputClassName = "",
    onCellChange = function(arg) {},
    validator,
    showPopup = false,
    validationUpload,
    disableDropdown,
    hasError
  } = props;
  const errorBorder = { border: "1px red solid", borderRadius: "5px" };

  return (
    <>
      {editedIndex === index ? (
        <>
          {showPopup ? (
            <Popup
              wide
              content={inputValue}
              trigger={
                <Dropdown
                  closeOnChange
                  fluid
                  labeled
                  upward={upward}
                  placeholder={placeholder}
                  name={inputName}
                  disabled={disableDropdown}
                  index={index}
                  icon={icon}
                  className={`${styles.input_style} ${customInputClassName} ${"icon"}`}
                  floating
                  options={dropdownOptions}
                  value={inputValue}
                  onChange={onCellChange}
                  style={
                    (editedIndex === index && validator && validator.parameter[inputName]) ||
                    validationUpload ||
                    hasError
                      ? errorBorder
                      : {}
                  }
                />
              }
            />
          ) : (
            <Dropdown
              closeOnChange
              fluid
              labeled
              upward={upward}
              placeholder={placeholder}
              disabled={disableDropdown}
              name={inputName}
              index={index}
              icon={icon}
              className={`${styles.input_style} ${customInputClassName}`}
              floating
              options={dropdownOptions}
              value={inputValue}
              onChange={onCellChange}
              style={
                (editedIndex === index && validator && validator.parameter[inputName]) ||
                validationUpload ||
                hasError
                  ? errorBorder
                  : {}
              }
            />
          )}
        </>
      ) : (
        <>
          {showPopup ? (
            <Popup
              content={inputValue}
              size="tiny"
              trigger={
                <Label
                  name={inputName}
                  index={index}
                  className={`${styles.label_style} ${customLabelClassName}`}
                  fluid="true"
                  transparent="true"
                  content={inputValue}
                  style={
                    (editedIndex === index && validator && validator.parameter[inputName]) ||
                    validationUpload ||
                    hasError
                      ? errorBorder
                      : {}
                  }
                />
              }
            />
          ) : (
            <Label
              name={inputName}
              index={index}
              className={`${styles.label_style} ${customLabelClassName}`}
              fluid="true"
              transparent="true"
              content={inputValue}
              style={
                (editedIndex === index && validator && validator.parameter[inputName]) ||
                validationUpload ||
                hasError
                  ? errorBorder
                  : {}
              }
            />
          )}
        </>
      )}
    </>
  );
};

TableCellDropdownOut.propTypes = {
  editedIndex: propTypes.number,
  inputValue: propTypes.oneOfType([propTypes.number, propTypes.string, propTypes.bool]),
  inputName: propTypes.string,
  index: propTypes.number,
  customLabelClassName: propTypes.string,
  customInputClassName: propTypes.string,
  customInputStyle: propTypes.object,
  customLabelStyle: propTypes.object,
  fluid: propTypes.bool,
  popupText: propTypes.oneOfType([propTypes.number, propTypes.string])
};
export default TableCellDropdownOut;
