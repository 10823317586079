import React from "react";
import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import GlobalHederRightPanel from "../../Components/Details/GlobalHederRightPanel";
import styles from "../style/AccountRightPanel.module.css";
import Localize from "react-intl-universal";

import { availableItemFieldsForAccountDetails } from "../BookkeepingConfig";

const AccountRightPanel = props => {
  const { selectedItem, setCurrentView, currentModule, currentView } = props;

  const getFieldsWithData = fields => {
    return fields.map((field, index) => {
      let iconHeandler = () => setCurrentView("preview");
      return (
        <GlobalDetailsItem
          key={index}
          type={field.type}
          itemName={field.labelName}
          // itemValue={selectedItem[field.name]}
          iconName={"eye"}
          iconHeandler={iconHeandler}
        />
      );
    });
  };

  const getPreviewDoc = () => {
    return <div>{Localize.get("TRANSACTIONS.INVOICETHUMB.PREVIEW")}</div>;
  };

  const getRightPanelBody = () => {
    switch (currentView) {
      case "details":
        return <>{getFieldsWithData(availableItemFieldsForAccountDetails)}</>;
      case "preview":
        return getPreviewDoc();
      default:
        break;
    }
  };

  return (
    <>
      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        detailsHeaderText={"BOOKKEEPING.ACCOUNT_DETAILS_HEADER"}
        hideIcon={true}
        customCss={styles.details_header}
      />
      {getRightPanelBody()}
    </>
  );
};

export default AccountRightPanel;
