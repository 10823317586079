import React from "react";
import ProjectStatusesFormPanel from "./ProjectStatusesFormPanel";
import { panelMode } from "../Constants";

const RightPanel = props => {
  const { editMode, state, handle, selectedStatus, updateSelectedStatus } = props;

  const content = (() => {
    let content;

    switch (state.activeMode) {
      case panelMode.edit:
        content = (
          <ProjectStatusesFormPanel
            state={state}
            editMode={editMode}
            mode={panelMode.edit}
            handle={handle.statusesFormPanel}
            selectedStatus={selectedStatus}
            updateSelectedStatus={updateSelectedStatus}
          />
        );
        break;
      case panelMode.create:
        content = (
          <ProjectStatusesFormPanel
            state={state}
            editMode={editMode}
            mode={panelMode.create}
            handle={handle.statusesFormPanel}
            updateSelectedStatus={updateSelectedStatus}
          />
        );
        break;
      default:
        break;
    }
    return content;
  })();

  return <>{content}</>;
};

export default RightPanel;
