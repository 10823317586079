import React from "react";
import { Label } from "semantic-ui-react";
import * as constant from "./Constants.js";
import Localize from "react-intl-universal";

const LinkedInvoice = props => {
  const handleOnLabelClick = () => {
    props.handleLinkedInvoiceDetails(props.invoice);
  };

  return (
    <Label
      as="a"
      style={{ margin: "0px 7px 7px 0px", backgroundColor: "#eee" }}
      onClick={handleOnLabelClick}
    >
      <span style={{ color: "#07b" }}>
        {Localize.get("TRANSACTIONS.INVOICETHUMB.NO")} {props.invoice.documentNumber}
      </span>{" "}
      <span
        style={{
          color: props.invoice.direction === constant.invoiceDirection.outgoing ? "#090" : "#a00"
        }}
      >
        {Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR"
        }).format(props.invoice.amount)}
      </span>
    </Label>
  );
};

export default LinkedInvoice;
