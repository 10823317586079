import React, { useState } from "react";
import { Form, Input, Button, Accordion, Dropdown, Label, TextArea } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import styles from "./style/DocNewProjectForm.module.css";
import * as constant from "../../Constants.js";
import { formatAmount } from "../../Util";

const DocNewProjectForm = props => {
  const {
    projectPrivileges,
    setEditedDropdown,
    projectAmountText,
    selectedProject,
    setSelectedProject,
    projectsOptions,
    restAmount,
    addProject
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [validator, setValidator] = useState(
    props.validator === undefined
      ? new Validator(validatorConstants.formFieldsNewProject)
      : props.validator
  );

  const [refreshValidation, setRefreshValidation] = useState(false);
  const errorBorder = "3px red solid";

  const [projektNameDefault, setProjektNameDefault] = useState("");
  const [charCounter, setCharCounter] = useState({
    charsLeft:
      255 -
      (selectedProject && selectedProject.description ? selectedProject.description.length : 0)
  });

  const handleOnDropdownChange = (event, data) => {
    setEditedDropdown(true);
    setProjektNameDefault(data.value);

    let selectedProjectFromDropdown = projectsOptions.find(project => project.key === data.value);

    setSelectedProject({
      ...selectedProjectFromDropdown,
      amount: selectedProject.amount,
      description: selectedProject.description
    });

    if (data.name in validatorConstants.formFieldsNewProject) {
      validator.validateField(data.value, data.name);
    }
  };

  const handleOnChange = (event, data) => {
    if (!data.value && data.name === "amount") {
      // clear validation messages after field is cleared out
      validator.validateField(data.value, data.name);
    } else {
      const validationOnField = validator.parameter[data.name];

      if (validationOnField) {
        validator.validateField(data.value, data.name);
      }
    }

    if (data.name === "amount") {
      let amount = data.value;

      if (!validator.isValidNumber(amount)) {
        return;
      }

      if (amount.indexOf(".") >= 0) {
        amount = amount.substr(0, amount.indexOf(".")) + amount.substr(amount.indexOf("."), 3);
      } else if (amount.indexOf(",") >= 0) {
        amount = amount.substr(0, amount.indexOf(",")) + amount.substr(amount.indexOf(","), 3);
      }

      setSelectedProject({
        ...selectedProject,
        amount: amount
      });
    }

    if (data.name === "description") {
      let inputChar = data.value;

      setSelectedProject({
        ...selectedProject,
        description: inputChar
      });

      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }
  };

  const handleOnBlur = (event, data) => {
    if (event.target.name in validatorConstants.formFieldsNewProject) {
      validator.validateField(event.target.value, event.target.name);
      if (validator.isThereValidation()) {
        setRefreshValidation(!refreshValidation);
      }
    }
  };

  const onAddClicked = event => {
    event.preventDefault();
    if (
      !validator.validateOnSave(validatorConstants.documentProjectRequiredFields, selectedProject)
    ) {
      setValidator({ ...validator });
      return false;
    }

    if (
      validator.compareNumbers(
        "amount",
        Number(formatAmount(selectedProject.amount)),
        Number(formatAmount(restAmount))
      )
    ) {
      setValidator({ ...validator });
      return false;
    }

    addProject(selectedProject);

    setSelectedProject({
      ...selectedProject,
      projectname: "",
      description: "",
      id: ""
    });

    setProjektNameDefault("");
    setEditedDropdown(false);
  };

  return (
    <>
      <Accordion>
        <Accordion.Content>
          <Form>
            <Form.Field required>
              <label>{props.projectNameText}</label>
              <Dropdown
                icon="dropdown"
                floating
                labeled
                fluid
                direction="left"
                placeholder={props.projectNameText}
                name="projectname"
                className={styles.choose_project_dropdown}
                selection
                search
                closeOnChange
                value={projektNameDefault}
                options={projectsOptions}
                onChange={handleOnDropdownChange}
                onSearchChange={props.handleProjectSearch}
                disabled={!projectPrivileges.get}
                style={{
                  border: validator.parameter.projectname ? errorBorder : ""
                }}
              />
              {validator.parameter.projectname && (
                <Label basic color="red" pointing content={validator.parameter.projectname} />
              )}
            </Form.Field>
            <Form.Field>
              <label>{props.projectNumberText}</label>
              <Input
                autoComplete={constant.autoComplete.none}
                disabled
                name="projectNumber"
                placeholder={props.projectNumberText}
                defaultValue={selectedProject.id}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
            </Form.Field>
            {selectedProject.projectexternalnumber && (
              <Form.Field>
                <label>{props.projectExternalNumberText}</label>
                <Input
                  autoComplete={constant.autoComplete.none}
                  disabled
                  name="projectExternalNumber"
                  placeholder={props.projectNumberText}
                  defaultValue={
                    selectedProject.projectexternalnumber
                      ? selectedProject.projectexternalnumber
                      : ""
                  }
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                />
              </Form.Field>
            )}
            <Form.Field>
              <label>{projectAmountText}</label>
              <Input
                autoComplete={constant.autoComplete.none}
                name="amount"
                placeholder={projectAmountText}
                value={selectedProject.amount}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                style={{
                  border: validator.parameter.amount ? errorBorder : ""
                }}
              />
              {validator.parameter.amount && (
                <Label basic color="red" pointing content={validator.parameter.amount} />
              )}
            </Form.Field>
            <Form.Field className={styles.input_fields_padding_right}>
              <label className={styles.required_label}>
                {Localize.get("DOCUMENTS.FORM.DESCRIPTION")}
              </label>
              <TextArea
                placeholder={Localize.get("DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER")}
                value={selectedProject.description}
                onChange={handleOnChange}
                name="description"
                maxLength={255}
                onBlur={handleOnBlur}
                style={{
                  border: validator.parameter.description ? errorBorder : ""
                }}
              />
              <p className="right floated content">
                {charCounter.charsLeft}
                {"/255"}
              </p>
            </Form.Field>
            <div className={styles.buttons_container}>
              <Button
                className={styles.positive_button}
                onClick={onAddClicked}
                disabled={!projectPrivileges.get}
              >
                {Localize.get("DOCUMENTS.NEWDOCUMENT.ADD")}
              </Button>
            </div>
          </Form>
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default DocNewProjectForm;
