import { useState } from "react";
import { paginationInitStatus } from "../Components/Constants";
import { convert2Documents, dateRange } from "./Util.js";

import * as RestService from "../RestService";
import { getByPathAndParams } from "../RestServicesNew/DocumentsServices";
import useFetch from "../RestServicesNew/useFetch";

const useFetchDocuments = (
  state,
  selectedDocument,
  setSelectedDocument,
  setState,
  documentsPanelState
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [paginationStatus, setPaginationStatus] = useState(paginationInitStatus);

  const [activePaginationPage, setActivePaginationPage] = useState(1);

  const [unverifiedPartners, , loadingUnverified, checkPartnersCall] = useFetch(
    getByPathAndParams,
    { count: 0 },
    "/partners/count?verified=false",
    {}
  );

  const callbackGetAllSuccess = (response, selectedIndex) => {
    const tempDocs = convert2Documents(response.data.data);

    setDocuments(tempDocs);
    setPaginationStatus({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });

    if (selectedDocument && !selectedIndex) {
      let isDocInList = tempDocs.find(doc => doc.id === selectedDocument.id);
      setSelectedDocument(isDocInList);
    }

    if (selectedIndex >= 0) {
      setSelectedDocument(tempDocs[selectedIndex]);
    }

    if ((!selectedDocument && tempDocs.length > 0) || state.selectFirst) {
      setSelectedDocument(tempDocs[0]);
    }
    setState(oldState => {
      return { ...oldState, selectFirst: false };
    });
  };

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {};

  const createService = (filterObject, selectedIndex) => {
    const serviceGetAll = new RestService.Document.GetAll.Service();
    serviceGetAll.setCallbackSuccess(res => callbackGetAllSuccess(res, selectedIndex));
    serviceGetAll.setCallbackError(callbackGetAllError);
    serviceGetAll.setCallbackFinally(callbackGetAllFinally);
    serviceGetAll.query.setPageSize(20);

    resolveFilters(serviceGetAll, filterObject);

    return serviceGetAll;
  };

  const resolveFilters = (service, filterObject) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filterObject).map(column => {
      if (filterObject[column] && filterObject[column] !== "all")
        service.query.addCustomColumnSearch(column, filterObject[column]);
    });
  };

  const getDueDate = myRange => {
    return myRange === "all" ? myRange : dateRange[myRange];
  };

  const fetchData = async selectedIndex => {
    const filterObject = {
      match: state.match,
      direction: documentsPanelState.activeDocumentDirectionFilter,
      type: documentsPanelState.activeDocumentTypeFilter,
      verification: documentsPanelState.activeVerificationStatusFilter,
      due: documentsPanelState.due
        ? documentsPanelState.due
        : getDueDate(documentsPanelState.activeDueStatusFilter),
      activeEarlyDueStatusFilter: documentsPanelState.activeEarlyDueStatusFilter,
      approval: documentsPanelState.activePaymentStatusFilter,
      archived: documentsPanelState.activeArchiveFilter === "archived" ? true : "",
      search: documentsPanelState.activeInputNumberSearch,
      sortBy: documentsPanelState.sortColumn,
      sortDirection: documentsPanelState.sortDirection,
      number: documentsPanelState.number,
      partnerName: documentsPanelState.partnerName,
      partnerOriginalId: state.partnerOriginalId,
      documentDate: documentsPanelState.documentDate,
      fromDate: documentsPanelState.minDate
        ? documentsPanelState.minDate
        : documentsPanelState.fromDate
        ? documentsPanelState.fromDate
        : "",
      toDate: documentsPanelState.maxDate
        ? documentsPanelState.maxDate
        : documentsPanelState.toDate
        ? documentsPanelState.toDate
        : "",
      amount: documentsPanelState.amount,
      savings: documentsPanelState.savings,
      createdAt: documentsPanelState.createdAt,
      progress: documentsPanelState.activeProgressStatusFilter,
      payment: documentsPanelState.activeRevenuePaymentStatusFilter,
      partnerSearch: documentsPanelState.partnerSearch
    };

    let getAllService = createService(filterObject);
    getAllService.query.setPage(activePaginationPage - 1);

    setIsLoading(true);
    getAllService.setCallbackSuccess(res => {
      callbackGetAllSuccess(res, selectedIndex);
      checkPartnersCall();
    });
    await getAllService.call();
    setIsLoading(false);
  };

  return {
    documents,
    setDocuments,
    fetchData,
    activePaginationPage,
    setActivePaginationPage,
    paginationStatus,
    isLoading,
    setIsLoading,
    unverifiedPartners,
    loadingUnverified
  };
};

export default useFetchDocuments;
