import React from "react";

const ProjectTimeTracker = props => {
  return (
    <>
      <h1>TimeTracker</h1>
    </>
  );
};

export default ProjectTimeTracker;
