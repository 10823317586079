import React, { useImperativeHandle, forwardRef, useState } from "react";
import ProjectResourcesPartners from "./ProjectResourcesPartners";
import { Segment } from "semantic-ui-react";
import styles from "./style/ProjectResources.module.css";

const ProjectResources = forwardRef((props, ref) => {
  const {
    roles,
    activeMode,
    setPartners,
    project,
    partners,
    handlePartnerSelection,
    handlePartnerDelete,
    partnerCounter,
    setPartnerCounter,
    setEditedFields,
    editedFields,
    newDetailsFields
  } = props;

  const [incorectRoleIds, setIncorectRoleIds] = useState([]);

  const rolePersonsHandeler = role => {
    let roleContacts = [];
    partners.forEach(partner => {
      if (partner.id.resourceRole.id === role.id.resourceRole.id) {
        roleContacts.push(partner);
      }
    });
    return roleContacts;
  };

  useImperativeHandle(ref, () => ({
    validateResourcesOnSave() {
      // eslint-disable-next-line
      let incorectRoles = roles.filter(role => {
        let listOfPartnersInRole;
        // eslint-disable-next-line
        listOfPartnersInRole = partners.filter(
          partner => partner.id.resourceRole.id === role.id.resourceRole.id
        );
        if (role.min > listOfPartnersInRole.length) {
          let roleID = role.id.resourceRole.id;
          return roleID;
        }
      });

      if (incorectRoles.length > 0) {
        setIncorectRoleIds(incorectRoles.map(role => role.id.resourceRole.id));
        return false;
      } else {
        setIncorectRoleIds([]);
        return true;
      }
    }
  }));

  const projetResources =
    project.projectTemplate &&
    roles &&
    roles.map((role, index) => (
      <Segment key={index} className={styles.project_resources_height} id="content">
        <ProjectResourcesPartners
          key={index}
          role={role}
          project={project}
          correctRoleMin={incorectRoleIds}
          setIncorectRoleIds={setIncorectRoleIds}
          activeMode={activeMode}
          setPartners={setPartners}
          partners={rolePersonsHandeler(role)}
          handlePartnerSelection={handlePartnerSelection}
          handlePartnerDelete={handlePartnerDelete}
          partnerCounter={partnerCounter}
          setPartnerCounter={setPartnerCounter}
          setEditedFields={setEditedFields}
          editedFields={editedFields}
          newDetailsFields={newDetailsFields}
        />
      </Segment>
    ));
  return <>{projetResources}</>;
});

export default ProjectResources;
