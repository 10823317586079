import React, { useState } from "react";

import { Loader, Dimmer } from "semantic-ui-react";
import styles from "./style/GlobalForm.module.css";
import GlobalHederRightPanel from "../Details/GlobalHederRightPanel";
import * as constants from "../Constants";
import useCreateItem from "../../CustomHooks/useCreateItem";
import useEditItem from "../../CustomHooks/useEditItem";
import propTypes from "prop-types";
import showNotification from "../../Notifications/NotificationFunction";
import GlobalSaveNewCancelBtn from "../GlobalSaveNewCancelBtn";
import Localize from "react-intl-universal";

const GlobalForm = props => {
  const {
    seletedAccounting = [],
    setSelectedAccounting = function() {},
    selectedItem,
    currentView,
    setCurrentView,
    availableItemFieldsForDetails,
    CustomForm,
    currentModule,
    formHeaderText,
    detailsHeaderText,
    emptyObject,
    customErrorHandler,
    customOnSaveValidation,
    customOnChangeValidation,
    callGetAll = function() {},
    parameters,
    okButton,
    putDefaultTemplate,
    putLoading
  } = props;

  const [currentItem, setCurrentItem] = useState(selectedItem);
  const [editedFields, setEditedFields] = useState([]);
  const [errorFields, setErrorFields] = useState({});

  const callbackSucess = (response, save) => {
    if (save === constants.saveOption.saveAndNew) {
      setCurrentItem(emptyObject);
      callGetAll();
    } else {
      callGetAll(true);
    }
    showNotificationMessage({
      type: constants.notificationType.info,
      serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.SUCESS_SAVED")
    });
  };

  const callbackError = error => {
    if (customErrorHandler && error && error.response && error.response.status !== 401) {
      customErrorHandler(error, showNotificationMessage);
    } else if (error && error.response && error.response.status !== 401) {
      showNotificationMessage({
        type: constants.notificationType.warning,
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };

  const callbackFinaly = response => {};

  const showNotificationMessage = param => {
    showNotification({
      entity: currentModule,
      ...param
    });
  };

  const [createServiceCall, createIsLoading, disableSaveBtn] = useCreateItem(
    currentItem,
    currentModule,
    customOnSaveValidation,
    callbackSucess,
    callbackError,
    callbackFinaly
  );

  const [updateItemServiceCall, editIsLoading] = useEditItem(
    editedFields,
    setCurrentView,
    currentItem,
    customErrorHandler,
    customOnSaveValidation,
    currentModule
  );

  const CustomFormElement = React.createElement(
    CustomForm,
    {
      selectedItem,
      currentItem,
      setCurrentItem,
      errorFields,
      parameters,
      setErrorFields,
      currentView,
      setCurrentView,
      availableItemFieldsForDetails,
      setEditedFields,
      editedFields,
      customOnChangeValidation,
      seletedAccounting,
      setSelectedAccounting,
      updateItemServiceCall,
      putDefaultTemplate,
      putLoading
    },
    null
  );

  return (
    <>
      <Dimmer active={createIsLoading || editIsLoading} inverted>
        <Loader disabled={!editIsLoading && !createIsLoading} />
      </Dimmer>

      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        formHeaderText={formHeaderText}
        detailsHeaderText={detailsHeaderText}
      />
      <div className={styles.form_container}>{CustomFormElement}</div>

      <GlobalSaveNewCancelBtn
        okButton={okButton}
        editMode={currentView === constants.panelView.edit}
        onUpdate={updateItemServiceCall}
        setCurrentView={setCurrentView}
        onSave={() => {
          createServiceCall(constants.saveOption.save);
        }}
        onSaveNew={() => {
          createServiceCall(constants.saveOption.saveAndNew);
        }}
        onCancel={() => {
          setCurrentView(constants.panelView.details);
        }}
        disabledBtn={disableSaveBtn}
      />
    </>
  );
};

GlobalForm.propTypes = {
  selectedItem: propTypes.oneOfType([propTypes.object, propTypes.array]),
  currentView: propTypes.string.isRequired,
  setCurrentView: propTypes.func,
  availableItemFieldsForDetails: propTypes.array,
  CustomForm: propTypes.func,
  currentModule: propTypes.string.isRequired,
  formHeaderText: propTypes.string,
  detailsHeaderText: propTypes.string,
  emptyObject: propTypes.object
};

export default GlobalForm;
