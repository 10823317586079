import React, { useState, useEffect } from "react";
import { Icon, Form, Radio, Dimmer, Loader } from "semantic-ui-react";
import styles from "./AccountingRightPanel.module.css";
import useAccountingServices from "../../RestServicesNew/useAccountingServices";
import GlobalHederRightPanel from "../../Components/Details/GlobalHederRightPanel";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";

const AccountingSystemRP = props => {
  const { currentView, setCurrentView, currentModule, selectedItem } = props;

  const [chnagesMade, setChangesMade] = useState(false);

  const {
    listOfTemplates,
    setListOfTemplates,
    fetchTemplatesCall,
    fetchTemplatesLoading,
    patchOrPostCall,
    patchOrPostLoading
  } = useAccountingServices(setCurrentView);

  useEffect(() => {
    currentView === "details" && fetchTemplatesCall();
  }, [currentView]);

  const handleRadio = (e, data) => {
    setListOfTemplates(prevState => {
      return prevState.map(item => {
        item.activeTemplate = data.id === item.id;
        return item;
      });
    });
    setChangesMade(true);
  };

  const onSaveisClicked = () => {
    if (chnagesMade) {
      let myActiveTemplate = listOfTemplates.find(item => item.activeTemplate);
      patchOrPostCall(
        {},
        `/accountings/charts/3/templates/${myActiveTemplate.id}`,
        undefined,
        "PUT"
      );
    } else {
      setCurrentView("details");
    }
  };

  return (
    <>
      <Dimmer active={fetchTemplatesLoading || patchOrPostLoading} inverted>
        <Loader disabled={!fetchTemplatesLoading || !patchOrPostLoading} />
      </Dimmer>
      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        disabledIcons={["delete"]}
        formHeaderText={"SETTINGS.SIDEBAR_OPTIONS.EDIT_ACCOUNT"}
        detailsHeaderText={"SETTINGS.HEADERS.ACCOUNTING_DETAILS"}
      />
      <div className={styles.dropdown_system}>
        {currentView === "details" ? (
          <Form>
            {listOfTemplates.map(el => (
              <Form.Field key={el.id}>
                <Radio label={el.description} checked={el.activeTemplate} disabled />
                <Icon name="eye" className={styles.standardEye} />
              </Form.Field>
            ))}
          </Form>
        ) : (
          <Form>
            <div className={styles.list_of_accounts}>
              {listOfTemplates.map(el => (
                <Form.Field key={el.id}>
                  <Radio
                    id={el.id}
                    label={el.description}
                    name={el.name}
                    checked={el.activeTemplate}
                    onChange={handleRadio}
                  />
                  <Icon name="eye" className={styles.standardEye} />
                </Form.Field>
              ))}
            </div>
            <GlobalSaveNewCancelBtn
              editMode={true}
              onUpdate={onSaveisClicked}
              onCancel={() => setCurrentView("details")}
            />
          </Form>
        )}
      </div>
    </>
  );
};

export default AccountingSystemRP;
