import React, { useState } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import GlobalTextInputField from "../Components/Inputs/GlobalTextInputField";
import { createPostService } from "../Components/Utils/GlobalUtil";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";

const Registration = ({ match }) => {
  const uuid = match.params.uuid;

  const [userObject, setUserObject] = useState({
    password: "",
    activationUUID: uuid
  });
  const [confirmPass, setConfirmPass] = useState("");
  const [errorFields, setErrorFields] = useState({});

  const callBackGetCreateSuccess = (response, save) => {
    window.location.href = "/#/";
    showNotification({
      entity: "register",
      type: "info",
      serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ACTIVATION_SUCCESS")
    });
  };
  const callBackGetCreateError = () => {
    showNotification({
      entity: "register",
      type: "warning",
      serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ACTIVATION_FAILED")
    });
  };
  const callBackGetCreateFinally = () => {};

  const checkFields = (myCredentials, confirmation) => {
    if (myCredentials.password.length < 6 || confirmation.length < 6) {
      setErrorFields({
        ...errorFields,

        password:
          myCredentials.password.length < 6 &&
          Localize.get("SETTINGS.ACTIVATE_ACCOUNT.EMPTY_PW_FIELD"),
        confirm_password:
          confirmation.length < 6 && Localize.get("SETTINGS.ACTIVATE_ACCOUNT.EMPTY_PW_FIELD")
      });
      return false;
    } else {
      if (myCredentials.password === confirmation) {
        return true;
      } else {
        setErrorFields({
          ...errorFields,
          confirm_password: Localize.get("SETTINGS.ACTIVATE_ACCOUNT.CONFIRMATION_PW_INCORRECT")
        });
        return false;
      }
    }
  };

  const onConfirmClick = () => {
    if (checkFields(userObject, confirmPass)) {
      let service = createPostService(
        userObject,
        callBackGetCreateSuccess,
        callBackGetCreateError,
        callBackGetCreateFinally,
        "users/activateUser",
        "save"
      );
      service.call();
    }
  };

  const onInputChange = (e, data) => {
    setErrorFields({});
    setUserObject({ ...userObject, [data.name]: data.value });
  };

  const onInputChangeConfirm = (e, data) => {
    setErrorFields({});
    setConfirmPass(data.value);
  };

  return (
    <>
      <Modal open={true}>
        <Modal.Header>{Localize.get("SETTINGS.ACTIVATE_ACCOUNT.INSTRUCTION_MESSAGE")}</Modal.Header>
        <Modal.Content>
          <Form>
            <GlobalTextInputField
              inputName={"password"}
              labelName={"SETTINGS.HEADERS.PASSWORD_PLACEHOLDER"}
              isRequired={true}
              itemValue={userObject.password}
              onInputChange={onInputChange}
              errorFields={errorFields}
              isPassword={true}
            />
            <GlobalTextInputField
              labelName={"SETTINGS.HEADERS.CONFIRM_PW"}
              isRequired={true}
              inputName={"confirm_password"}
              itemValue={confirmPass}
              onInputChange={onInputChangeConfirm}
              errorFields={errorFields}
              isPassword={true}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={onConfirmClick}>
            {Localize.get("SETTINGS.BUTTONS.CONFIRM")}
          </Button>
          <Button
            color="black"
            onClick={() => {
              window.location.href = "/#/";
            }}
          >
            {Localize.get("GLOBAL.FORM.CANCEL")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Registration;
