import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ConfirmArchiveDialog.module.css";
import GlobalOkCancelBtn from "../Components/GlobalOkCancelBtn";

const ConfirmArchiveDialog = props => {
  const {
    numberOfArchivedItems,
    confirmClicked,
    declineClicked,
    activeArchiveFilter,
    singularHeaderArchive,
    pluralHeaderArchive,
    singularHeaderUnarchive,
    pluralHeaderUnarchive,
    showModal,
    cannotBeDeleted = [],
    archiveBtnEnable,
    selectedItems,
    module
  } = props;

  const modalColor = cannotBeDeleted.length > 0 ? "#CC0001" : "#2185D0";

  if (!showModal) return null;

  const getItemName = el => {
    switch (module) {
      case "document":
        return el.documentNumber;
      case "partner":
        return el.name;
      case "project":
        return el.projectName;
      default:
        break;
    }
  };

  const getHeader = () => {
    if (cannotBeDeleted.length > 0) {
      return Localize.get("MODAL.CANNOT_BE_DELETED");
    } else {
      if (activeArchiveFilter === "") {
        return numberOfArchivedItems > 1
          ? pluralHeaderArchive
            ? Localize.get(pluralHeaderArchive)
            : Localize.get("MODAL.ARCHIVE_ITEMS")
          : singularHeaderArchive
          ? Localize.get(singularHeaderArchive)
          : Localize.get("MODAL.ARCHIVE_ITEM");
      } else {
        return numberOfArchivedItems > 1
          ? pluralHeaderUnarchive
            ? Localize.get(pluralHeaderUnarchive)
            : Localize.get("MODAL.UNARCHIVE_ITEMS")
          : singularHeaderUnarchive
          ? Localize.get(singularHeaderUnarchive)
          : Localize.get("MODAL.UNARCHIVE_ITEM");
      }
    }
  };

  return (
    <>
      <Modal
        size="tiny"
        dimmer="inverted"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
          {getHeader()}
        </Modal.Header>
        {selectedItems &&
          selectedItems.length > 0 &&
          selectedItems[0].actions &&
          selectedItems[0].actions.ArchiveRules && (
            <Modal.Content>
              <Modal.Description>
                {selectedItems
                  .filter(el => el.actions.ArchiveRules)
                  .map((el, index) => (
                    <ul key={index}>
                      <li>{getItemName(el) + " " + Localize.get(el.actions.ArchiveRules)}</li>
                    </ul>
                  ))}
              </Modal.Description>
            </Modal.Content>
          )}
        {cannotBeDeleted.length > 0 && (
          <Modal.Content className={styles.content_padding}>
            <div className={styles.rows}>{cannotBeDeleted}</div>
          </Modal.Content>
        )}
        <Modal.Actions>
          <GlobalOkCancelBtn
            disabledBtn={archiveBtnEnable}
            onConfirmClicked={confirmClicked}
            onCancel={declineClicked}
            btnText={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ConfirmArchiveDialog;
