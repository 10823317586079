import React, { useState } from "react";
import { Label, FormField, Input } from "semantic-ui-react";
import Localize from "react-intl-universal";
import propTypes from "prop-types";

const GlobalTextInputField = props => {
  const {
    labelName,
    itemValue,
    onInputChange = function(par) {},
    inputName,
    inputPlaceholder,
    inputDisabled = false,
    errorFields = {},
    isRequired = false,
    isPassword = false
  } = props;

  const [changePassword, setChangePassword] = useState(isPassword);

  const handleEyeClick = () => {
    setChangePassword(prev => !prev);
  };

  return (
    <>
      <FormField required={isRequired} autoComplete="off">
        {labelName && <label>{Localize.get(labelName)}</label>}

        <Input
          icon={
            isPassword
              ? {
                  name: changePassword ? "eye slash" : "eye",
                  circular: true,
                  link: true,
                  onClick: handleEyeClick
                }
              : null
          }
          type={changePassword ? "password" : "text"}
          disabled={inputDisabled}
          placeholder={inputPlaceholder ? Localize.get(inputPlaceholder) : ""}
          name={inputName}
          autoComplete="off"
          onChange={(e, data) => onInputChange(e, data)}
          value={itemValue}
          style={
            errorFields && errorFields[inputName]
              ? { border: "2px solid red", borderRadius: "5px" }
              : null
          }
        />
        {errorFields && errorFields[inputName] && (
          <Label pointing basic color="red">
            {errorFields[inputName]}
          </Label>
        )}
      </FormField>
    </>
  );
};

GlobalTextInputField.propTypes = {
  labelName: propTypes.string,
  itemValue: propTypes.string,
  onInputChange: propTypes.func,
  inputName: propTypes.string,
  inputDisabled: propTypes.bool,
  errorFields: propTypes.object,
  isRequired: propTypes.bool
};

export default GlobalTextInputField;
