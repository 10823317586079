import { useState, useEffect } from "react";
import useViewportSize from "./useViewportSize";
import { panelView } from "./Constants";
import { panelDefaultConf } from "./GlobalPanel.config";
import propTypes from "prop-types";

const usePanelConfig = (configuration = panelDefaultConf, currentView = "details") => {
  const { width = 1920 } = useViewportSize();
  const checkLeftPanelConfig = (myConfig = panelDefaultConf.leftPanel, widthArg, view) => {
    const {
      form = {
        hideUnder: 1281,
        hideMenuIconUnder: 1281
      },
      details = {
        hideUnder: 1281,
        hideMenuIconUnder: 1281
      }
    } = myConfig;

    let myPath = view === panelView.edit || view === panelView.create ? form : details;

    return {
      showPanel: myPath.hideUnder < widthArg,
      hideIcon: myPath.hideMenuIconUnder < widthArg
    };
  };

  const checkMiddlePanelConfig = (myConfig = panelDefaultConf.middlePanel, widthArg, view) => {
    const {
      form = {
        hideUnder: 0
      },
      details = {
        hideUnder: 0
      }
    } = myConfig;

    let myPath = view === panelView.edit || view === panelView.create ? form : details;

    return {
      showPanel: myPath.hideUnder < widthArg
    };
  };

  const [leftPanelGridConfig, setLeftPanelConfig] = useState(
    checkLeftPanelConfig(configuration.leftPanel, width, currentView)
  );
  const [middlePanelGridConfig, setMiddlePanelConfig] = useState(
    checkMiddlePanelConfig(configuration.rightPanel, width, currentView)
  );
  const [rightPanelGridConfig, setRightPanelConfig] = useState(
    checkMiddlePanelConfig(configuration.rightPanel, width, currentView)
  );

  useEffect(() => {
    if (configuration && configuration.leftPanel) {
      setLeftPanelConfig(checkLeftPanelConfig(configuration.leftPanel, width, currentView));
    }
    if (configuration && configuration.middlePanel) {
      setMiddlePanelConfig(checkMiddlePanelConfig(configuration.middlePanel, width, currentView));
    }
    if (configuration && configuration.rightPanel) {
      setRightPanelConfig(checkMiddlePanelConfig(configuration.rightPanel, width, currentView));
    }
  }, [width, configuration, currentView]);

  return {
    leftPanelGridConfig,
    middlePanelGridConfig,
    rightPanelGridConfig,
    screenWidth: width
  };
};

usePanelConfig.propTypes = {
  configuration: propTypes.object,
  currentView: propTypes.string
};

export default usePanelConfig;
