export const projectElements = {
  management: "management",
  role: "role",
  status: "status",
  template: "template",
  type: "type"
};

export const paginationInitStatus = {
  perPage: 0,
  pageCount: 0,
  totalCount: 0
};

export const panelInitialState = {
  panelView: "details",
  panelModule: "properties"
};

export const panelModule = {
  accounts: "accounts",
  permisions: "permisions",
  roles: "roles",
  users: "users",
  properties: "properties/types"
};

export const panelView = {
  create: "create",
  edit: "edit",
  details: "details",
  display: "display",
  list: "list"
};

export const saveOption = {
  save: "save",
  saveAndNew: "saveAndNew"
};

export const notificationType = {
  info: "info",
  warning: "warning"
};
