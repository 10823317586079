import React from "react";
import { Menu, Grid, Checkbox, Button, Icon, Popup } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/GlobalItem.module.css";
import propTypes from "prop-types";
import { get } from "lodash";

const GlobalItem = props => {
  const {
    item,
    setSelectedItem = function(par) {},
    itemHandler = function(par) {},
    selectedItem,
    availableItemFieldsForList = [],
    customItemCss
  } = props;

  const getIconGroup = (icons = []) => {
    return icons.map((iconType, index) => {
      return (
        <Popup
          key={index}
          content={Localize.get(iconType.popupText)}
          size="mini"
          basic
          trigger={
            <Icon
              myindex={index}
              name={iconType.name}
              onClick={(e, data) => itemHandler(item, data)}
            />
          }
        />
      );
    });
  };

  const itemFieldType = item_field => {
    switch (item_field.type) {
      case "text":
        return <div>{get(item, `${item_field.name}`)}</div>;
      case "acc_text":
        return <div>{item.name[item_field.name]}</div>;
      case "translateKey":
        return <div>{Localize.get(`PROPERTIES.TYPES.${item[item_field.name]}`)}</div>;
      case "prop_translateKey":
        return <div>{Localize.get(`PROPERTIES.TYPES.${item.name[item_field.name]}`)}</div>;
      case "message":
        return <p>{Localize.get(item[item_field.name].replace(/[{}]/g, ""))}</p>;
      case "amount":
        return (
          <div>
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR"
            }).format(item[item_field.name])}
          </div>
        );
      case "checkbox":
        return (
          <Checkbox
            name={item_field.name}
            value={item.id}
            onChange={(e, data) => itemHandler(item, data)}
            checked={item.checked}
          />
        );
      case "iconGroup":
        return getIconGroup(item_field.icons);
      case "button":
        return (
          <Button
            onClick={(e, data) => itemHandler(item, data)}
            {...item_field.element_semantic_properties}
            name={item_field.operation}
            className={
              item_field.element_customizes_css
                ? item_field.element_customizes_css
                : styles.add_or_remove_button
            }
          >
            {Localize.get(item_field.buttonText)}
          </Button>
        );
      default:
        return <div>{"default error message"}</div>;
    }
  };
  const itemFieldsList = () => {
    return (
      <>
        <Grid padded verticalAlign="middle">
          {availableItemFieldsForList
            .filter(item => item.visible)
            .map((item_field, index) => {
              return (
                <Grid.Column
                  key={index}
                  width={item_field.width ? item_field.width : 3}
                  className={
                    item_field && item_field.column_customized_css
                      ? item_field.column_customized_css
                      : styles.column_field
                  }
                >
                  {itemFieldType(item_field)}
                </Grid.Column>
              );
            })}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Menu.Item
        item_id={item.id}
        onClick={() => setSelectedItem(item)}
        active={selectedItem && selectedItem.id === item.id ? true : null}
        className={customItemCss ? customItemCss : styles.bms_item}
      >
        {itemFieldsList()}
      </Menu.Item>
    </>
  );
};

GlobalItem.propTypes = {
  item: propTypes.object.isRequired,
  setSelectedItem: propTypes.func,
  selectedItem: propTypes.object,
  availableItemFieldsForList: propTypes.arrayOf(
    propTypes.oneOfType([
      propTypes.shape({
        type: propTypes.oneOf(["text", "checkbox"]).isRequired,
        name: propTypes.string.isRequired,
        hederName: propTypes.string,
        width: propTypes.number,
        searchable: propTypes.bool
      }),
      propTypes.shape({
        type: propTypes.oneOf(["button"]).isRequired,
        hederName: propTypes.string,
        operation: propTypes.string,
        buttonText: propTypes.string,
        width: propTypes.number,
        element_semantic_properties: propTypes.object,
        column_customized_css: propTypes.string,
        element_customizes_css: propTypes.string
      }),
      propTypes.shape({
        hederName: propTypes.string,
        type: propTypes.string,
        icons: propTypes.arrayOf(
          propTypes.shape({
            name: propTypes.string,
            type: propTypes.string,
            operation: propTypes.string,
            width: propTypes.number,
            popupText: propTypes.string
          })
        )
      })
    ])
  ),
  customItemCss: propTypes.string
};

export default GlobalItem;
