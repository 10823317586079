import React, { useEffect, useState } from "react";
import { Container, Statistic, Segment, Divider, Grid, Dimmer, Loader } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars
import LineChart from "./LineChart";
import Doughnut from "./Doughnut";
import BarChart from "./BarChart";
import HorizontalBar from "./HorizontalBar";
import Localize from "react-intl-universal";
import * as RestService from "../RestService";
import useFetch from "../RestServicesNew/useFetch";
import { fetchCount } from "../RestServicesNew/CountServices";
import styles from "./style/Dashboard.module.css";

const Dashboard = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const [person, setPerson] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [countDocuments, setCountDocuments] = useState(0);
  const [countProjects, setCountProjects] = useState(0);
  const [countPartners, setCountPartners] = useState(0);
  const [numberOfUnlinkedTransactions, setNumberOfUnlinkedTransactions] = useState();

  const getDocMax = (inq, off, rec, del, inv, invCor, oth, ord) => {
    return Math.max(inq, off, rec, del, inv, invCor, oth, ord);
  };

  const getDocMin = (inq, off, rec, del, inv, invCor, oth, ord) => {
    let min;

    if (inq > 0 && off > 0 && rec > 0 && del > 0 && inv > 0 && invCor > 0 && oth > 0 && ord) {
      min = Math.min(inq, off, rec, del, inv, invCor, oth, ord) - 1;
    } else {
      min = 0;
    }

    return min;
  };

  const getTransactionsMax = (a = 0, b = 0, c = 0) => {
    return Math.max(a, b, c);
  };

  const callbackCountDocumentsSuccess = response => {
    setCountDocuments(response.data.count);
  };

  const callbackCountProjectsSuccess = response => {
    setCountProjects(response.data.count);
  };

  const callbackCountPartnersSuccess = response => {
    setCountPartners(response.data.count);
  };

  const callbackAccountsSuccess = response => {
    const accountsArr = response.data.map(account => ({
      id: account.id,
      numberOfLinkedTransactions: account.numberOfLinkedTransactions,
      numberOfLinkedWithoutDocTransactions: account.numberOfLinkedWithoutDocTransactions,
      numberOfUnlinkedTransactions: account.numberOfUnlinkedTransactions
    }));

    setNumberOfUnlinkedTransactions(
      accountsArr.reduce((a, b) => a + b.numberOfUnlinkedTransactions, 0)
    );
  };

  const callbackCountCompaniesSuccess = response => {
    setCompany(response.data.count);
  };

  const callbackCountPersonsSuccess = response => {
    setPerson(response.data.count);
  };

  const callbackCountEmployeeSuccess = response => {
    setEmployee(response.data.count);
  };

  const callbackCountError = error => {};

  const callbacCountFinally = response => {};

  const countDocumentsService = () => {
    const countDocuments = new RestService.Document.CountDocuments.Service();
    countDocuments.setCallbackSuccess(callbackCountDocumentsSuccess);
    countDocuments.setCallbackError(callbackCountError);
    countDocuments.setCallbackFinally(callbacCountFinally);
    countDocuments.call();
  };

  const countProjectsService = () => {
    const countProjects = new RestService.Project.CountProjects.Service();
    countProjects.setCallbackSuccess(callbackCountProjectsSuccess);
    countProjects.setCallbackError(callbackCountError);
    countProjects.setCallbackFinally(callbacCountFinally);
    countProjects.call();
  };

  const countPartnersService = () => {
    const countPartners = new RestService.Partner.CountPartners.Service();
    countPartners.setCallbackSuccess(callbackCountPartnersSuccess);
    countPartners.setCallbackError(callbackCountError);
    countPartners.setCallbackFinally(callbacCountFinally);
    countPartners.call();
  };

  const accountsService = () => {
    const countAccounts = new RestService.Accounts.GetAll.Service();
    countAccounts.setCallbackSuccess(callbackAccountsSuccess);
    countAccounts.setCallbackError(callbackCountError);
    countAccounts.setCallbackFinally(callbacCountFinally);
    countAccounts.call();
  };

  const countCompaniesService = () => {
    const countCompanies = new RestService.Company.CountCompanies.Service();
    countCompanies.setCallbackSuccess(callbackCountCompaniesSuccess);
    countCompanies.setCallbackError(callbackCountError);
    countCompanies.setCallbackFinally(callbacCountFinally);
    countCompanies.call();
  };

  const countPersonsService = () => {
    const countPersons = new RestService.Person.CountPersons.Service();
    countPersons.setCallbackSuccess(callbackCountPersonsSuccess);
    countPersons.setCallbackError(callbackCountError);
    countPersons.setCallbackFinally(callbacCountFinally);
    countPersons.call();
  };

  const countEmployeeService = () => {
    const countEmployee = new RestService.Employee.CountEmployee.Service();
    countEmployee.setCallbackSuccess(callbackCountEmployeeSuccess);
    countEmployee.setCallbackError(callbackCountError);
    countEmployee.setCallbackFinally(callbacCountFinally);
    countEmployee.call();
  };

  const [customerData, , , fetchCustomerCount] = useFetch(
    fetchCount,
    0,
    "/partners/count?partnerRole=customer"
  );

  const [supplierData, , , fetchSupplierCount] = useFetch(
    fetchCount,
    0,
    "/partners/count?partnerRole=supplier"
  );

  const [archivedrData, , , fetchArchivedCount] = useFetch(
    fetchCount,
    0,
    "/partners/count?archived=true"
  );

  const [activeData, , , fetchActivedCount] = useFetch(
    fetchCount,
    0,
    "/partners/count?archived=false"
  );

  const [invoiceData, , , fetchInvoiceCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?type=invoice"
  );

  const [correctionData, , , fetchCorrectionCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?type=correction"
  );

  const [orderData, , , fetchOrderCount] = useFetch(fetchCount, 0, "/documents/count?type=order");

  const [offerData, , , fetchOfferCount] = useFetch(fetchCount, 0, "/documents/count?type=offer");

  const [receiptData, , , fetchReceiptCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?type=receipt"
  );

  const [deliveryData, , , fetchDeliveryCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?type=delivery"
  );

  const [inquiryData, , , fetchInquiryCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?type=inquiry"
  );

  const [otherData, , , fetchOtherCount] = useFetch(fetchCount, 0, "/documents/count?type=other");

  const [incomingData, , , fetchIncomingCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?direction=incoming"
  );

  const [outgoingData, , , fetchOutgoingCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?direction=outgoing"
  );

  const [approvedgData, , , fetchApprovedCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?approval=approved"
  );

  const [declinedData, , , fetchDeclinedCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?approval=declined"
  );

  const [unresolvedData, , , fetchUnresolvedCount] = useFetch(
    fetchCount,
    0,
    "/documents/count?approval=unresolved"
  );

  const [accountsData, , , fetchAccountsCount] = useFetch(fetchCount, 0, "/accounts/count");

  const [linkedTransData, , , fetchLinkedTransCount] = useFetch(
    fetchCount,
    0,
    "/transactions/count?assignment=LINKED"
  );

  const [linkedWithoutTransData, , , fetchLinkedWithoutTransCount] = useFetch(
    fetchCount,
    0,
    "/transactions/count?assignment=LINKED_WITHOUT_DOCUMENT"
  );

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });

    const fetchData = async () => {
      setIsLoading(true);
      fetchCustomerCount();
      fetchSupplierCount();
      fetchArchivedCount();
      fetchActivedCount();
      fetchInvoiceCount();
      fetchCorrectionCount();
      fetchOrderCount();
      fetchOfferCount();
      fetchReceiptCount();
      fetchDeliveryCount();
      fetchInquiryCount();
      fetchOtherCount();
      fetchIncomingCount();
      fetchOutgoingCount();
      fetchApprovedCount();
      fetchDeclinedCount();
      fetchUnresolvedCount();
      fetchAccountsCount();
      fetchLinkedTransCount();
      fetchLinkedWithoutTransCount();
      await countDocumentsService();
      await countProjectsService();
      await countPartnersService();
      await countCompaniesService();
      await countPersonsService();
      await countEmployeeService();
      await accountsService();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  let typeDocuments = {
    labels: [
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_INQUIRY")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_OFFER")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_ORDER")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_RECEIPT")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_DELIVERY_NOTE")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_INVOICE")}`,
      `${Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.INVOICE_CORRECTION")}`,
      `${Localize.get("DASHBOARD.DOCUMENT_TYPE_OTHER")}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.DOCUMENTATIONS")}`,
        backgroundColor: [
          "rgba(219,40,40,0.6)",
          "rgba(242,113,28,0.6)",
          "rgba(251,189,8,0.6)",
          "rgba(33,186,69,0.6)",
          "rgba(33,133,208,0.6)",
          "rgba(118,118,118,0.6)",
          "rgba(0,181,173,0.6)",
          "rgba(0,140,173,0.6)"
        ],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: [
          "rgba(219,40,40,1)",
          "rgba(242,113,28,1)",
          "rgba(251,189,8,1)",
          "rgba(33,186,69,1)",
          "rgba(33,133,208,1)",
          "rgba(118,118,118,1)",
          "rgba(0,181,173,1)",
          "rgba(0,140,173,1)"
        ],
        hoverBorderColor: "#FFFFFF",
        data: [
          inquiryData,
          offerData,
          orderData,
          receiptData,
          deliveryData,
          invoiceData,
          correctionData,
          otherData
        ]
      }
    ]
  };

  let directionsDoc = {
    labels: [
      `${Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")}: ${incomingData}`,
      `${Localize.get("TRANSACTIONS.DIRECTION.REVENUE")}: ${outgoingData}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.DOCUMENTATIONS")}`,
        backgroundColor: ["rgba(33,186,69,0.7)", "rgba(242,113,28,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(219,40,40,1)", "rgba(242,113,28,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [incomingData, outgoingData]
      }
    ]
  };

  let approvalDoc = {
    labels: [
      `${Localize.get("DASHBOARD.APPROVAL_STATUS_APPROVED")}: ${approvedgData}`,
      `${Localize.get("DASHBOARD.APPROVAL_STATUS_DECLINED")}: ${declinedData}`,
      `${Localize.get("DASHBOARD.APPROVAL_STATUS_UNRESOLVED")}: ${unresolvedData}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.DOCUMENTATIONS")}`,
        backgroundColor: ["rgba(33,186,69,0.7)", "rgba(33,133,208,0.7)", "rgba(118,118,118,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(33,186,69,1)", "rgba(33,133,208,1)", "rgba(118,118,118,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [approvedgData, declinedData, unresolvedData]
      }
    ]
  };

  let partnersTypes = {
    labels: [
      `${Localize.get("DASHBOARD.PERSON")}: ${person}`,
      `${Localize.get("DASHBOARD.COMPANY")}: ${company}`,
      `${Localize.get("DASHBOARD.EMPLOYEE")}: ${employee}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.PARTNER")}`,
        backgroundColor: ["rgba(33,186,69,0.7)", "rgba(33,133,208,0.7)", "rgba(118,118,118,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(33,186,69,1)", "rgba(33,133,208,1)", "rgba(118,118,118,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [person, company, employee]
      }
    ]
  };

  let partnersRole = {
    labels: [
      `${Localize.get("DASHBOARD.CONTACT_ROLE_CUSTOMER")}: ${customerData}`,
      `${Localize.get("DASHBOARD.CONTACT_TYPE_SUPPLIER")}: ${supplierData}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.PARTNER")}`,
        backgroundColor: ["rgba(33,186,69,0.7)", "rgba(118,118,118,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(33,186,69,1)", "rgba(118,118,118,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [customerData, supplierData]
      }
    ]
  };

  let arhiveStatus = {
    labels: [
      `${Localize.get("DASHBOARD.ARCHIVE_STATUS_ACTIVE")}: ${activeData}`,
      `${Localize.get("DASHBOARD.ARCHIVE_STATUS_ARCHIVED")}: ${archivedrData}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.PARTNER")}`,
        backgroundColor: ["rgba(33,133,208,0.7)", "rgba(118,118,118,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(33,133,208,1)", "rgba(118,118,118,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [activeData, archivedrData]
      }
    ]
  };

  let linkedStatus = {
    labels: [
      `${Localize.get("DASHBOARD.ASSIGNMENT_LINKED")}`,
      `${Localize.get("DASHBOARD.ASSIGNMENT_WITHOUT_DOC")}`,
      `${Localize.get("DASHBOARD.ASSIGNMENT_UNLINKED")}`
    ],
    datasets: [
      {
        label: `${Localize.get("DASHBOARD.LINKED_DOCUMENT")}`,
        backgroundColor: ["rgba(33,186,69,0.7)", "rgba(33,133,208,0.7)", "rgba(118,118,118,0.7)"],
        borderColor: "#FFFFFF",
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(33,186,69,1)", "rgba(33,133,208,1)", "rgba(118,118,118,1)"],
        hoverBorderColor: "#FFFFFF",
        data: [linkedTransData, linkedWithoutTransData, numberOfUnlinkedTransactions]
      }
    ]
  };

  // TODO This part of code is left here in case of change requests it is connected with LineChart
  // let transaction = {
  //   labels: [
  //     `${Localize.get("DASHBOARD.JANUARY")}`,
  //     `${Localize.get("DASHBOARD.FEBRUARY")}`,
  //     `${Localize.get("DASHBOARD.MARCH")}`,
  //     `${Localize.get("DASHBOARD.APRIL")}`,
  //     `${Localize.get("DASHBOARD.MAY")}`,
  //     `${Localize.get("DASHBOARD.JUNE")}`,
  //     `${Localize.get("DASHBOARD.JULY")}`
  //   ],
  //   datasets: [
  //     {
  //       label: `${Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")}`,
  //       fill: false,
  //       backgroundColor: "rgba(255,99,132,0.2)",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.9)",
  //       hoverBorderColor: "rgba(255,99,132,0.9)",
  //       data: [6500, 5900, 8000, 8100, 5600, 5500, 6000]
  //     },
  //     {
  //       label: `${Localize.get("TRANSACTIONS.DIRECTION.REVENUE")}`,
  //       fill: false,
  //       backgroundColor: "rgba(33,186,69,0.6)",
  //       borderColor: "rgba(33,186,69,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(33,186,69,1)",
  //       hoverBorderColor: "rgba(33,186,69,1)",
  //       data: [5000, 5300, 7000, 7900, 6000, 6500, 8000]
  //     }
  //   ]
  // };

  const sumTransactions = (a = 0, b = 0, c = 0) => {
    return a + b + c;
  };

  return (
    <div className={styles.bms_dashboard}>
      <Container>
        <Divider hidden />
        <Segment textAlign="center" id="statistic">
          <Statistic.Group className={styles.bms_statistic_info}>
            <Statistic color="blue">
              <Statistic.Value>{countProjects}</Statistic.Value>
              <Statistic.Label>{Localize.get("DASHBOARD.PROJECTS")}</Statistic.Label>
            </Statistic>
            <Statistic color="blue">
              <Statistic.Value>{countDocuments}</Statistic.Value>
              <Statistic.Label> {Localize.get("DASHBOARD.DOCUMENTS")}</Statistic.Label>
            </Statistic>
            <Statistic color="blue">
              <Statistic.Value>
                {sumTransactions(
                  linkedTransData,
                  linkedWithoutTransData,
                  numberOfUnlinkedTransactions
                )}
              </Statistic.Value>
              <Statistic.Label> {Localize.get("DASHBOARD.TRANSACTIONS")}</Statistic.Label>
            </Statistic>
            <Statistic color="blue">
              <Statistic.Value>{countPartners}</Statistic.Value>
              <Statistic.Label> {Localize.get("DASHBOARD.PARTNERS")}</Statistic.Label>
            </Statistic>
            <Statistic color="blue">
              <Statistic.Value>{accountsData}</Statistic.Value>
              <Statistic.Label> {Localize.get("DASHBOARD.ACCOUNTS")}</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <Divider hidden />

        <Segment textAlign="center" className={styles.dashboard_header}>
          <h3> {Localize.get("DASHBOARD.DOCUMENTS")}</h3>
        </Segment>
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column width={10}>
              <Segment className={styles.documents_type}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <HorizontalBar
                    data={typeDocuments}
                    minDoc={getDocMin(
                      inquiryData,
                      offerData,
                      receiptData,
                      deliveryData,
                      invoiceData,
                      correctionData,
                      otherData,
                      orderData
                    )}
                    maxDoc={getDocMax(
                      inquiryData,
                      offerData,
                      receiptData,
                      deliveryData,
                      invoiceData,
                      correctionData,
                      otherData,
                      orderData
                    )}
                    title={Localize.get("DASHBOARD.DOCUMENTS_TYPE")}
                  />
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment className={styles.documents_type_details}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <Doughnut
                    data={directionsDoc}
                    title={Localize.get("TRANSACTIONS.DIRECTION.HEADER_DIRECTION")}
                  />
                )}
              </Segment>
              <Segment className={styles.documents_type_details}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <Doughnut
                    data={approvalDoc}
                    title={Localize.get("DASHBOARD.HEADER_APPROVAL_STATUS")}
                  />
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Divider hidden />
        </Grid>
        <Divider hidden />
        <Segment textAlign="center" className={styles.dashboard_header}>
          <h3>{Localize.get("DASHBOARD.TRANSACTIONS")}</h3>
        </Segment>
        <Grid columns={2}>
          <Grid.Row columns="equal">
            {/* TODO Currently, there is not an adequate time tracker for expenses and incomings. 
            This part of code is left here in case of change requests
             <Grid.Column>
              <Segment style={{ backgroundColor: "#f5f5f5" }}>
                <LineChart
                  data={transaction}
                  title={Localize.get("DASHBOARD.TRANSACTION")}
                  axesX={"2019"}
                  axesY={"EUR"}
                />
              </Segment>
            </Grid.Column> */}

            <Grid.Column>
              <Segment className={styles.documents_transactions_linked}>
                <BarChart
                  data={linkedStatus}
                  title={Localize.get("DASHBOARD.LINKED_DOCUMENT")}
                  minDoc={0}
                  maxDoc={getTransactionsMax(
                    linkedTransData,
                    linkedWithoutTransData,
                    numberOfUnlinkedTransactions
                  )}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Segment textAlign="center" className={styles.dashboard_header}>
          <h3>{Localize.get("DASHBOARD.PARTNERS")}</h3>
        </Segment>
        <Grid columns={3}>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Segment className={styles.partners_details}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <Doughnut data={partnersTypes} title={Localize.get("DASHBOARD.PARTNER")} />
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className={styles.partners_details}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <Doughnut data={partnersRole} title={Localize.get("DASHBOARD.PARTNER")} />
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment className={styles.partners_details}>
                {isLoading ? (
                  <Dimmer active inverted>
                    <Loader inverted> {Localize.get("DASHBOARD.LOADING")}</Loader>
                  </Dimmer>
                ) : (
                  <Doughnut data={arhiveStatus} title={Localize.get("DASHBOARD.PARTNER")} />
                )}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
