import React from "react";
import ProjectTemplateDetails from "./ProjectTemplateDetails";
import ProjectTemplateFormPanel from "./ProjectTemplateFormPanel";
import { panelMode } from "../Constants.js";

const ProjectTemplatePanel = props => {
  const { privileges, editMode } = props;
  const content = (() => {
    let content;
    switch (props.state.activeMode) {
      case panelMode.details:
        content = (
          <ProjectTemplateDetails
            state={props.state}
            editMode={editMode}
            privileges={privileges}
            handle={props.handle.details}
          />
        );
        break;
      case panelMode.edit:
        content = (
          <ProjectTemplateFormPanel
            state={props.state}
            editMode={editMode}
            mode={panelMode.edit}
            handle={props.handle.templateFormPanel}
            formErrorMessage={props.formErrorMessage}
          />
        );
        break;
      case panelMode.create:
        content = (
          <ProjectTemplateFormPanel
            state={props.state}
            editMode={editMode}
            mode={panelMode.create}
            handle={props.handle.templateFormPanel}
            formErrorMessage={props.formErrorMessage}
          />
        );
        break;
      default:
        break;
    }
    return content;
  })();

  return <>{content}</>;
};

export default ProjectTemplatePanel;
