import React from "react";
import { Modal } from "semantic-ui-react";
import styles from "./style/WarningOkDialog.module.css";
import GlobalOkBtn from "../Components/GlobalOkBtn";

const WarningOkDialog = props => {
  const { open, modalContent, onOkFunction, modalHeader } = props;
  return (
    <Modal open={open} size="small" closeOnDocumentClick dimmer="blurring">
      <Modal.Header className={styles.dialogHeader}>{modalHeader}</Modal.Header>

      <Modal.Content>
        <Modal.Content>{modalContent}</Modal.Content>
      </Modal.Content>
      <Modal.Actions>
        <GlobalOkBtn onOkClicked={onOkFunction} />
      </Modal.Actions>
    </Modal>
  );
};

export default WarningOkDialog;
