import { useState, useEffect } from "react";
import usePreviousState from "./usePreviousState";
import { createGetAllService, resolveFilters } from "../Components/Utils/GlobalUtil";
import { checkCurrentSelectedItem } from "../Components/Utils/GlobalListsUtil";
import * as constants from "./Constants";
import propTypes from "prop-types";

const useGetAll = (
  currentModule,
  initialFilterObject = {},
  initialSearchObject = {},
  setSideBarVisibility = function(arg) {},
  formatSuccess
) => {
  const [filterObject, setFilterObject] = useState(initialFilterObject);
  const [searchObject, setSearchObject] = useState(initialSearchObject);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listState, setListState] = useState([]);
  const [forbidenResponse, setForbidenResponse] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(constants.paginationInitStatus);
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState("details");
  const previousView = usePreviousState(currentView);
  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const callBackGetAllSuccess = (response, selectFirst, formatResponse) => {
    let myData;

    if (response && response.data) {
      if (formatResponse) {
        myData = formatResponse(response.data);
      } else {
        if (response.data.data) {
          myData = response.data.data;
          myData.map(item => {
            item.checked = false;
            return item;
          });
        } else {
          myData = response.data.map((item, index) => {
            return { id: index, name: item };
          });
          if (myData[0].name.settingCategory.id === 3) {
            myData.sort(function(a, b) {
              return a.name.id - b.name.id;
            });
          }
        }
      }

      setForbidenResponse(false);
      if (selectFirst) {
        setSelectedItem(myData.length > 0 ? myData[0] : null);
      } else {
        setSelectedItem(checkCurrentSelectedItem(myData, currentView, selectedItem));
      }

      setListState(myData);
      setPaginationStatus({
        perPage: response.data.paging.perPage,
        pageCount: response.data.paging.pageCount,
        totalCount: response.data.paging.totalCount
      });
    }
  };

  const callBackGetAllError = response => {
    if (response && response.response && response.response.data) {
      let myRes = response.response.data;
      if (myRes && myRes.status && myRes.status === 403) {
        setForbidenResponse(myRes.message);
      }
    }
  };
  const callBackGetAllFinally = response => {
    setSideBarVisibility(false);
    setIsLoading(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (currentView === constants.panelView.details && currentModule) {
      const newService = createGetAllService(
        callBackGetAllSuccess,
        callBackGetAllError,
        callBackGetAllFinally,
        currentModule,
        previousView && previousView === "create",
        formatSuccess
      );

      resolveFilters(newService, searchObject);
      resolveFilters(newService, filterObject);
      newService.query.setPage(activePaginationPage - 1);

      delayTimer = setTimeout(function() {
        if (isSubscribed && !firstBuild) {
          setIsLoading(true);
          newService.call();
        }
      }, 600);
      setFirstBild(false);
    }
    return () => (isSubscribed = false);
  }, [searchObject]);

  useEffect(() => {
    if (currentView === constants.panelView.details && currentModule) {
      clearTimeout(delayTimer);
      const newService = createGetAllService(
        callBackGetAllSuccess,
        callBackGetAllError,
        callBackGetAllFinally,
        currentModule,
        previousView && previousView === "create",
        formatSuccess
      );

      resolveFilters(newService, filterObject);
      resolveFilters(newService, searchObject);
      newService.query.setPage(activePaginationPage - 1);
      setIsLoading(true);
      newService.call();
    }
  }, [currentModule, currentView, activePaginationPage, filterObject]);

  const callGetAll = page => {
    if (page) {
      //TODO - these setters triggers effect 2x
      setCurrentView(constants.panelView.details);
      setActivePaginationPage(1);
    } else {
      let newService = createGetAllService(
        callBackGetAllSuccess,
        callBackGetAllError,
        callBackGetAllFinally,
        currentModule,
        undefined,
        formatSuccess
      );

      resolveFilters(newService, filterObject);
      resolveFilters(newService, searchObject);
      newService.query.setPage(activePaginationPage - 1);
      newService.call();
    }
  };

  //function used for filter in side bar
  const filterHandler = (fieldName, fieldValue, currentFilters) => {
    if (!currentFilters.hasOwnProperty(fieldName)) {
      setFilterObject({ ...currentFilters, [fieldName]: fieldValue });
    } else {
      delete currentFilters[fieldName];
      setFilterObject({ ...currentFilters });
    }
  };

  //function used for search
  const searchFilter = (e, data, currentFilters, reset = false) => {
    if (reset) {
      setFilterObject(initialFilterObject);
      setSearchObject(initialSearchObject);
    } else {
      setActivePaginationPage(1);
      clearTimeout(delayTimer);
      setSearchObject({ ...currentFilters, [data.name]: data.value });
    }
  };

  //function used for sort
  const sortHandler = (fieldName, fieldValue, currentFilters) => {
    if (currentFilters.sortBy === fieldName) {
      if (currentFilters.sortDirection === fieldValue) {
        setFilterObject({ ...currentFilters, sortBy: "", sortDirection: "" });
      } else {
        setFilterObject({
          ...currentFilters,
          sortBy: fieldName,
          sortDirection: fieldValue
        });
      }
    } else {
      setFilterObject({
        ...currentFilters,
        sortBy: fieldName,
        sortDirection: fieldValue
      });
    }
  };

  return [
    selectedItem,
    listState,
    currentView,
    isLoading,
    activePaginationPage,
    filterObject,
    searchObject,
    forbidenResponse,
    paginationStatus,
    callGetAll,
    setSelectedItem,
    setListState,
    filterHandler,
    searchFilter,
    sortHandler,
    setActivePaginationPage,
    setCurrentView,
    setIsLoading
  ];
};

useGetAll.propTypes = {
  currentModule: propTypes.string.isRequired,
  currentView: propTypes.string,
  activePaginationPage: propTypes.number.isRequired,
  filterObject: propTypes.object,
  setSideBarVisibility: propTypes.func,
  setIsLoading: propTypes.func
};
export default useGetAll;
