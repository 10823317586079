export const role = {
  // ACCOUNTS
  account: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  accounts: {
    get: true,
    count: true
  },
  accountBank: {
    get: true
  },
  accountPaypal: {
    create: true
  },
  // BIZS
  bizsIntegration: {
    get: true
  },
  // companies
  // employees
  // enterpreneurs
  // persons
  // same as PARTNERS
  partner: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  partners: {
    get: true,
    count: true
  },
  // DOCUMENTS
  document: {
    get: true,
    create: true,
    update: true,
    delete: true,
    approve: true,
    verify: true
  },
  documents: {
    get: true,
    count: true
  },
  documentContent: {
    get: true
  },
  documentHistory: {
    get: true
  },
  datevExport: {
    create: true
  },
  exportedFiles: {
    get: true
  },
  // FILE
  file: {
    get: true,
    create: true
  },
  // PAYMENT
  payment: {
    create: true
  },
  payments: {
    get: true
  },
  //MANUAL PAYMENT
  manualPayment: {
    create: true,
    delete: true
  },
  // TRANSACTIONS
  transaction: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  transactions: {
    get: true,
    count: true
  },
  // PROJECTS
  // menagement
  project: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  projects: {
    delete: true,
    update: true
  },
  resourceRole: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  projectType: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  projectTemplate: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  projectStatus: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  projectExternalStatus: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  // ROLE
  appRole: {
    get: true
  },
  // USERS
  user: {
    get: true,
    create: true,
    update: true,
    delete: true
  },
  //SETTINGS
  properties: {
    get: true,
    update: true
  }
};
