import React from "react";
import Localize from "react-intl-universal";
import { Segment } from "semantic-ui-react";
import styles from "./style/InvoiceSettingsDetails.module.css";
import NotifierDetails from "./NotifierDetails";
import GlobalDetailsTextItem from "../../Components/Details/GlobalDetailsTextItem";
import InvoiceHierarchy from "./InvoiceHierarchy";
import { get } from "lodash";
import useTypes from "../../CustomHooks/useTypes";

const InvoiceSettingsDetails = ({ company }) => {
  const { documentTypes, emailTypes } = useTypes();

  const getExportMessage = (mail, post) => {
    if (mail || post) {
      return (
        <div>
          {mail && <div>{Localize.get("CONTACTS.DETAILS.INVOICE_SENT_VIA_EMAIL")}</div>}
          {post && <div>{Localize.get("CONTACTS.DETAILS.INVOICE_SENT_VIA_POST")}</div>}
        </div>
      );
    } else {
      return <div>{Localize.get("CONTACTS.DETAILS.INVOICE_WONT_BE_SENT")}</div>;
    }
  };

  const isVisible = types => {
    let defaultTemplateKeys = Object.keys(get(company, "defaultTemplates"));
    let isKeyInDef = types.some(r => defaultTemplateKeys.includes(r.categoryKey));
    return isKeyInDef;
  };

  return (
    <div>
      <Segment.Group>
        <Segment className={styles.title}>
          <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICING_SETTINGS")}</strong>
        </Segment>
        <Segment>
          {company.recipients && company.recipients.length > 0 && (
            <Segment.Group>
              <Segment className={styles.title}>
                <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICE_NOTIFIERS")}</strong>
              </Segment>
              <Segment>
                {company.recipients.map((notifier, index) => {
                  return <NotifierDetails key={index} notifier={notifier} />;
                })}
              </Segment>
            </Segment.Group>
          )}

          {company.sendInvoiceToParent ? (
            <>
              <div>{Localize.get("CONTACTS.DETAILS.ARE_DIRECTED_TO_PARENT")}</div>
              <InvoiceHierarchy contact={company} isDetails={true} />
            </>
          ) : (
            <>
              {isVisible(documentTypes) && (
                <Segment.Group>
                  <Segment className={styles.title}>
                    <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICE_TEMPLATES")}</strong>
                  </Segment>
                  <Segment>
                    {documentTypes.map(
                      (item, index) =>
                        get(company, `defaultTemplates.${item.categoryKey}.name`) && (
                          <GlobalDetailsTextItem
                            key={index}
                            itemName={`PROPERTIES.FIELDS.${item.categoryKey}`}
                            itemValue={get(company, `defaultTemplates.${item.categoryKey}.name`)}
                            hideDivider={true}
                          />
                        )
                    )}
                  </Segment>
                </Segment.Group>
              )}
              {isVisible(emailTypes) && (
                <Segment.Group>
                  <Segment className={styles.title}>
                    <strong>{Localize.get("PROPERTIES.TYPES.Email templates")}</strong>
                  </Segment>
                  <Segment>
                    {emailTypes.map(
                      (item, index) =>
                        get(company, `defaultTemplates.${item.categoryKey}.name`) && (
                          <GlobalDetailsTextItem
                            key={index}
                            itemName={`PROPERTIES.FIELDS.${item.categoryKey}`}
                            itemValue={get(company, `defaultTemplates.${item.categoryKey}.name`)}
                            hideDivider={true}
                          />
                        )
                    )}
                  </Segment>
                </Segment.Group>
              )}
              <Segment.Group>
                <Segment className={styles.title}>
                  <strong>{Localize.get("CONTACTS.DETAILS.EXPORT_INVOICE_OPTIONS")}</strong>
                </Segment>
                <Segment>
                  <div>{getExportMessage(company.sendToMail, company.sendToPost)}</div>
                </Segment>
              </Segment.Group>
              <InvoiceHierarchy contact={company} isDetails={true} />
            </>
          )}
        </Segment>
      </Segment.Group>
    </div>
  );
};

export default InvoiceSettingsDetails;
