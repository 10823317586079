import React from "react";
import { Pagination, Icon } from "semantic-ui-react";
import * as constant from "../../Documents/Constants.js";
import styles from "./style/ListWithPagination2.module.css";
import propTypes from "prop-types";

const ListWithPagination = props => {
  const {
    list,
    currentView,
    handlePageChange,
    defaultActivePage,
    totalPage,
    activePaginationPage,
    perPage,
    listPaginationCustomCSS
  } = props;

  return (
    <>
      <div
        className={
          currentView === constant.middlePanelMode.documentForPay
            ? `ui fluid vertical menu ${styles.payListHeight}`
            : `ui vertical menu ${styles.listOfItems} ${listPaginationCustomCSS} `
        }
      >
        {list}
      </div>
      {defaultActivePage > 1 && currentView !== constant.middlePanelMode.documentForPay ? (
        <Pagination
          pointing
          secondary
          className={styles.pagination_position}
          activePage={activePaginationPage}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={Math.ceil(totalPage / perPage)}
          onPageChange={(e, pageInfo) => handlePageChange(pageInfo.activePage)}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

ListWithPagination.propTypes = {
  list: propTypes.array,
  currentView: propTypes.string,
  handlePageChange: propTypes.func,
  defaultActivePage: propTypes.number,
  totalPage: propTypes.number,
  activePaginationPage: propTypes.number,
  perPage: propTypes.number,
  listPaginationCustomCSS: propTypes.string
};

export default ListWithPagination;
