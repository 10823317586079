import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = props => {
  const m = {
    title: {
      display: false,
      text: props.title,
      fontSize: 20
    },
    legend: {
      display: false,
      position: "bottom"
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: props.minDoc,
            suggestedMax: props.docMax,
            stepSize: 1,
            precision: 1
          }
        }
      ]
    },
    maintainAspectRatio: false
  };
  return (
    <Bar
      data={props.data}
      // width={100}
      height={350}
      options={m}
    />
  );
};

export default BarChart;
