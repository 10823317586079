import React from "react";
import { Menu, Grid, Checkbox } from "semantic-ui-react";
import styles from "./style/ProjectManagementItem.module.css";

const ProjectManagementItem = props => {
  const onListItemToggle = (event, data) => {
    event.stopPropagation();
    props.onListToggleClick(data);
  };

  return (
    <Menu.Item
      id={props.project.id}
      del={props.project.id}
      active={props.state.activeListItem === props.project.id ? true : null}
      onClick={props.onListItemClick}
    >
      <Grid columns="5" stackable container verticalAlign="middle">
        <Grid.Column width={1} className={styles.project_list_checkbox_column}>
          <Checkbox
            item_id={props.project.id}
            checked={props.project.checked}
            onClick={onListItemToggle}
          />
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={3}>
          <div className="content">
            <div className="list_name">{props.project.id}</div>
          </div>
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={5}>
          <div className="content">
            <div className="list_name">{props.project.projectName}</div>
          </div>
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={3}>
          <div>
            {props.project && props.project.projectTemplate && props.project.projectTemplate.name
              ? props.project.projectTemplate.name
              : null}
          </div>
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={3}>
          <div>
            {props.project &&
            props.project.projectTemplate &&
            props.project.projectTemplate.projectType &&
            props.project.projectTemplate.name
              ? props.project.projectTemplate.projectType.name
              : null}
          </div>
        </Grid.Column>
      </Grid>
    </Menu.Item>
  );
};

export default ProjectManagementItem;
