import React, { useEffect, useState } from "react";
import { Divider, Form } from "semantic-ui-react";
import GlobalList from "../../Components/Lists/GlobalList";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";
import { itemsGlobalListHandler, createGetAllService } from "../../Components/Utils/GlobalUtil";
import { nameFieldConfig, removeButtonFieldConfig, addButtonFieldConfig } from "./RolesForm.config";
import styles from "../style/RolesForm.module.css";

const RolesForm = props => {
  const {
    currentView,
    editedFields,
    setEditedFields,
    setErrorFields,
    currentItem,
    setCurrentItem,
    parameters,
    customOnChangeValidation
  } = props;
  const [permissionList, setPermissionList] = useState([]);
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [paginationStatus, setPaginationStatus] = useState({
    perPage: 0,
    pageCount: 0,
    totalCount: 0
  });
  const [loadingLocaly, setLoadingLocaly] = useState(false);

  const callBackGetAllSuccess = response => {
    // eslint-disable-next-line array-callback-return
    let tempArr = response.data.data.filter(item => {
      let compare = currentItem.permissions.filter(permission => permission.id === item.id);

      if (compare.length === 0) {
        return item;
      }
    });
    setPermissionList(tempArr);
    setPaginationStatus({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });
  };
  const callBackGetAllError = response => {};
  const callBackGetAllFinally = response => {
    setLoadingLocaly(false);
  };

  useEffect(() => {
    if (currentView === "edit" || currentView === "create") {
      let newService = createGetAllService(
        callBackGetAllSuccess,
        callBackGetAllError,
        callBackGetAllFinally,
        currentView === "edit" ? `permissions/assignableForRole/${currentItem.id}` : "permissions"
      );
      newService.query.setPage(activePaginationPage - 1);
      setLoadingLocaly(true);
      newService.call();
    }
  }, [currentView, activePaginationPage]);

  const handleAddRemoveClick = (clickedItem, data) => {
    itemsGlobalListHandler(
      clickedItem,
      data,
      setErrorFields,
      editedFields,
      setEditedFields,
      currentItem,
      setCurrentItem,
      permissionList,
      setPermissionList,
      "permissions"
    );
  };

  const handleNameChange = (e, data) => {
    setCurrentItem({ ...currentItem, name: data.value });
    setErrorFields({});
    if (!editedFields.includes("name")) {
      let tempArray = editedFields;
      tempArray.push("name");
      setEditedFields(tempArray);
    }
    customOnChangeValidation("name", data.value);
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo);
  };

  return (
    <>
      <Form>
        <GlobalTextInputField
          labelName={"SETTINGS.HEADERS.ROLE_NAME"}
          isRequired={true}
          inputName={"name"}
          itemValue={currentItem.name}
          onInputChange={handleNameChange}
          errorFields={parameters}
        />
      </Form>

      <Divider className={styles.item_divider} />
      <GlobalList
        listState={currentItem.permissions}
        listPaginationCustomCSS={styles.permission_item}
        itemHandler={handleAddRemoveClick}
        availableItemFieldsForList={[
          nameFieldConfig,
          {
            ...removeButtonFieldConfig,
            column_customized_css: styles.custom_css_for_button,
            element_customizes_css: styles.custom_button
          }
        ]}
      />
      <Divider />
      <GlobalList
        listState={permissionList}
        listPaginationCustomCSS={styles.permission_item}
        paginationStatus={paginationStatus}
        itemHandler={handleAddRemoveClick}
        handlePageChange={handlePageChange}
        activePaginationPage={activePaginationPage}
        showAtBottom
        loadingLocaly={loadingLocaly}
        availableItemFieldsForList={[
          {
            ...nameFieldConfig,
            hederName: "SETTINGS.HEADERS.UNASSIGNED_PERMISSIONS"
          },
          {
            ...addButtonFieldConfig,
            column_customized_css: styles.custom_css_for_button,
            element_customizes_css: styles.custom_button
          }
        ]}
      />
    </>
  );
};
export default RolesForm;
