import React, { useState, useEffect } from "react";
import { Label } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../Utils/Validator";
import * as constant from "../../Utils/Constant";
import styles from "./style/BankAccountsPayPalPanel.module.css";
import * as constants from "../Constants.js";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";

const BankAccountsItemPanel = props => {
  const { panelState, paypalAccount, formErrorMessage, handlerButton } = props;

  const panelLabel =
    panelState.activeMode === "edit"
      ? Localize.get("ACCOUNTS.EDITACCOUNT.EDIT_PAYPAL_ACCOUNT")
      : Localize.get("ACCOUNTS.NEWACCOUNT.NEW_PAYPAL_ACCOUNT");

  const [paypalLocal, setPaypalLocal] = useState(
    panelState.activeMode === "edit"
      ? paypalAccount
      : {
          id: "",
          accountOwner: "",
          accountNumber: ""
        }
  );

  const [validator, setValidator] = useState(
    props.validator === undefined
      ? new Validator(constant.formFieldsPaypalAccount)
      : props.validator
  );

  const [refreshValidation, setRefreshValidation] = useState(false);
  let disabledButton =
    paypalLocal.accountOwner === "" ||
    paypalLocal.accountNumber === "" ||
    validator.parameter.accountOwner ||
    validator.parameter.accountNumber;

  const onItemChange = event => {
    const data = event.target;
    if (!data.value) {
      // clear validation messages after field is cleared out
      validator.validateField(data.value, data.name);
    } else {
      const validationOnField = validator.parameter[data.name];

      if (validationOnField) {
        validator.validateField(data.value, data.name);
      }
    }
    setPaypalLocal({
      ...paypalLocal,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (event, data) => {
    if (event.target.name in constant.formFieldsPaypalAccount) {
      validator.validateField(event.target.value, event.target.name);
      if (validator.isThereValidation()) {
        setRefreshValidation(!refreshValidation);
      }
    }
  };

  useEffect(() => {
    if (formErrorMessage) {
      validator.addErrorMsgParam(formErrorMessage);
    }

    return function cleanup() {
      validator.clearMessages();

      setValidator({ ...validator });
    };
  }, [formErrorMessage]);

  const onSaveClick = () => {
    if (validator.validateForm(constant.formFieldsPaypalAccount, paypalLocal)) {
      handlerButton.saveButtonPaypalFormHandler(paypalLocal);
    }
    setValidator({ ...validator });
  };

  const errorBorder = "3px red solid";

  return (
    <div className="six wide column items_details">
      <div className={styles.item_details_container}>
        <div className={`"header" ${styles.bms_header}`}>{panelLabel}</div>
        <div className={styles.company_details_container_account}>
          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content">
                <div className={styles.str_lable}>
                  <strong>{Localize.get("ACCOUNTS.FORM.ACCOUNT_OWNER")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    name="accountOwner"
                    type="text"
                    value={paypalLocal.accountOwner}
                    placeholder={Localize.get("ACCOUNTS.FORM.ACCOUNT_OWNER_PLACEHOLDER")}
                    onChange={onItemChange}
                    onBlur={handleOnBlur}
                    style={{
                      border: validator.parameter.accountOwner ? errorBorder : ""
                    }}
                  />
                </div>
                {validator.parameter.accountOwner && (
                  <Label basic color="red" pointing content={validator.parameter.accountOwner} />
                )}
                <div className={styles.str_lable_mail}>
                  <strong>{Localize.get("ACCOUNTS.FORM.PAYPAL_NAME")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    name="accountNumber"
                    type="text"
                    disabled={panelState.activeMode === "edit"}
                    value={paypalLocal.accountNumber}
                    placeholder={Localize.get("ACCOUNTS.FORM.ACCOUNT_OWNER_PLACEHOLDER")}
                    onChange={onItemChange}
                    onBlur={handleOnBlur}
                    style={{
                      border: validator.parameter.accountNumber ? errorBorder : ""
                    }}
                  />
                </div>
                {validator.parameter.accountNumber && (
                  <Label basic color="red" pointing content={validator.parameter.accountNumber} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.btn_group}>
          <GlobalSaveNewCancelBtn
            disabledBtn={disabledButton ? true : false}
            onSave={onSaveClick}
            onCancel={handlerButton.closePaypalButtonFormHandler}
          />
        </div>
      </div>

      <div style={{ clear: "both", display: "block" }} />
    </div>
  );
};

export default BankAccountsItemPanel;
