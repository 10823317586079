import React from "react";
import AccountingSystemRP from "./AccountingSystemRP";
import CustomAccountingSystem from "./CustomAccountingSystem";
import AccountingNumbering from "./AccountingNumbering";

const AccountingRightPanel = props => {
  const { selectedItem } = props;

  return (
    <>
      {selectedItem &&
        selectedItem.name &&
        selectedItem.name.subcategoryName &&
        selectedItem.name.subcategoryName === "accounting_system" && (
          <AccountingSystemRP {...props} />
        )}
      {selectedItem &&
        selectedItem.name &&
        selectedItem.name.subcategoryName &&
        selectedItem.name.subcategoryName === "custom_chart_of_accounts" && (
          <CustomAccountingSystem {...props} />
        )}
      {selectedItem &&
        selectedItem.name &&
        selectedItem.name.subcategoryName &&
        selectedItem.name.subcategoryName === "invoice_Email" && <div>invoice_Email</div>}
      {selectedItem &&
        selectedItem.name &&
        selectedItem.name.subcategoryName &&
        selectedItem.name.subcategoryName === "number_range" && <AccountingNumbering {...props} />}
    </>
  );
};

export default AccountingRightPanel;
