import React, { useState, useEffect, useRef } from "react";
import { Label, TextArea, Form, Divider, Dropdown, Segment } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../../Utils/Validator";
import * as constant from "../../../Utils/Constant";
import * as elements from "../../../Components/Constants";
import * as constants from "../Constants";
import EditRoles from "./EditRoles";
import { ProjectType, ProjectRole } from "../../../RestService";
import ProjectSaveButtonsGroup from "../../../Components/ProjectSaveButtonsGroup";
import styles from "./style/ProjectTemplateFormPanel.module.css";

const ProjectTemplateFormPanel = props => {
  // eslint-disable-next-line no-unused-vars
  const { editMode } = props;
  let generateTypes = useRef();
  const refRoles = useRef(null);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [templateNameErr, setTemplateNameErr] = useState(false);
  const [templateAvailableRoles, setTemplateAvailableRoles] = useState([]);
  const [template, setTemplate] = useState(
    props.state.activeMode === "edit"
      ? props.state.template
      : {
          id: "",
          name: "",
          description: "",
          projectType: {
            id: null,
            name: "",
            description: ""
          },
          roles: []
        }
  );
  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (template && template.description ? template.description.length : 0)
  });

  const [validator, setValidator] = useState(
    props.validator === undefined ? new Validator(constant.formFieldsNewTemplate) : props.validator
  );
  const [refreshValidation, setRefreshValidation] = useState(false);
  const [selectedType, setSelectedType] = useState(template.projectType.description);
  const [editedFields, setEditedFields] = useState([]);

  const callbackGetAllTypesSuccess = response => {
    generateTypes.current = response.data.data;
  };
  const callbackGetAllTypesError = error => {};

  const callbackGetAllTypesFinally = response => {
    setProjectTypeOptions(
      generateTypes.current.map((t, i) => ({
        key: i,
        text: t.name,
        value: t.name
      }))
    );
  };

  const getAllProjectTypesService = new ProjectType.GetAll.Service();

  getAllProjectTypesService.setCallbackSuccess(callbackGetAllTypesSuccess);
  getAllProjectTypesService.setCallbackError(callbackGetAllTypesError);
  getAllProjectTypesService.setCallbackFinally(callbackGetAllTypesFinally);

  const callbackGetAllRolesSuccess = response => {
    if (template.roles.length > 0) {
      let availableRolesList = response.data.data.filter(role => {
        let newArray = template.roles.filter(temRole => {
          return temRole.resourceRole.id === role.id;
        });
        return newArray.length > 0 ? false : true;
      });

      setTemplateAvailableRoles(availableRolesList);
    } else {
      setTemplateAvailableRoles(response.data.data);
    }
  };
  const callbackGetAllRolesError = error => {};

  const callbackGetAllRolesFinally = response => {};

  useEffect(() => {
    const getAllProjectRolesService = new ProjectRole.GetAll.Service();
    getAllProjectRolesService.setCallbackSuccess(callbackGetAllRolesSuccess);
    getAllProjectRolesService.setCallbackError(callbackGetAllRolesError);
    getAllProjectRolesService.setCallbackFinally(callbackGetAllRolesFinally);
    getAllProjectRolesService.call();
    getAllProjectTypesService.call();
  }, []);
  const panelLabel =
    props.state.activeMode === "edit"
      ? Localize.get("PROJECTS.TEMPLATES.EDIT_TEMPLATE")
      : Localize.get("PROJECTS.TEMPLATES.CREATE_TEMPLATE");

  const onItemChange = event => {
    if (!event.target.value) {
      // clear validation messages after field is cleared out

      validator.validateField(event.target.value, event.target.name);
    } else {
      const validationOnField = validator.parameter[event.target.name];

      if (validationOnField) {
        validator.validateField(event.target.value, event.target.name);
      }
    }

    setTemplate({ ...template, [event.target.name]: event.target.value });
    if (event.target.name && !editedFields.includes(event.target.name)) {
      const newArr = [...editedFields];
      newArr.push(event.target.name);
      setEditedFields(newArr);
    }
    if (event && event.target && event.target.name === "description") {
      let inputChar = event.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }
  };

  const handleOnBlur = (e, data) => {
    validator.validateField(e.target.value, e.target.name);
    setRefreshValidation(!refreshValidation);
  };

  const handleDeleteRole = (roles, deletedRole) => {
    const newTemplate = { ...template, roles: roles };

    setTemplateAvailableRoles([...templateAvailableRoles, deletedRole]);
    setTemplate(newTemplate);
    if (!editedFields.includes("roles")) {
      const newArr = [...editedFields];
      newArr.push("roles");
      setEditedFields(newArr);
    }
  };

  const handleOnPlusClick = () => {
    const newRoles = [{ max: 1, min: 0, resourceRole: {} }, ...template.roles];
    setTemplate({ ...template, roles: newRoles });
  };

  const handleRoleChange = (index, changedRole) => {
    const newRoles = template.roles.map((a, i) => {
      if (i === index) {
        a = changedRole;
      }
      return a;
    });
    let newAwailableRoles = templateAvailableRoles.filter(
      role => role.id !== changedRole.resourceRole.id
    );

    setTemplateAvailableRoles(newAwailableRoles);
    setTemplate({ ...template, roles: newRoles });

    if (!editedFields.includes("roles")) {
      const newArr = [...editedFields];
      newArr.push("roles");
      setEditedFields(newArr);
    }
  };

  useEffect(() => {
    if (props.formErrorMessage) {
      validator.addErrorMsgParam(props.formErrorMessage);
    }
    if (props.state.activeMode === "edit") {
      setTemplate(props.state.template);
    }
  }, [props.state.template, props.formErrorMessage]);

  // eslint-disable-next-line no-unused-vars
  const errorBorder = "3px red solid";
  const errorBorderNoRadius = { border: "3px red solid", borderRadius: "0px" };

  const handleSelectType = (event, data) => {
    let selectedTypeObj = generateTypes.current.find(t => t.name === data.value);
    validator.validateField(data.value, "projectTypeValidation");
    setSelectedType(selectedTypeObj.description);
    setTemplate({ ...template, projectType: selectedTypeObj });

    if (!editedFields.includes("projectType")) {
      const newArr = [...editedFields];
      newArr.push("projectType");
      setEditedFields(newArr);
    }
  };

  return (
    <div className={styles.right_panel}>
      <div className={styles.template_panel}>
        <div className={styles.header}>{panelLabel}</div>

        <div className={styles.company_details_container}>
          <Form>
            <div className="ui middle aligned divided list">
              <div className="item">
                <div className="content">
                  <Form.Field required>
                    <label>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME")}</label>
                    <div className="ui fluid input">
                      <input
                        autoComplete={constants.autoComplete.off}
                        name="name"
                        type="text"
                        value={template.name}
                        placeholder={Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME_PLACEHOLDER")}
                        onChange={onItemChange}
                        onBlur={handleOnBlur}
                        style={
                          validator.parameter.name || templateNameErr
                            ? {
                                ...errorBorderNoRadius
                              }
                            : null
                        }
                      />
                    </div>
                    {validator.parameter.name && (
                      <Label basic color="red" pointing content={validator.parameter.name} />
                    )}
                    {templateNameErr && (
                      <Label basic color="red" pointing>
                        {Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME_ERROR")}
                      </Label>
                    )}
                  </Form.Field>
                </div>
              </div>
            </div>
          </Form>
          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content ">
                <Form className={styles.some_form}>
                  <Form.Field>
                    <label>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DESCRIPTION")} </label>
                    <TextArea
                      name="description"
                      rows={10}
                      maxLength={255}
                      value={template.description}
                      placeholder={Localize.get(
                        "PROJECTS.TEMPLATES.TEMPLATE_DESCRIPTION_PLACEHOLDER"
                      )}
                      onChange={onItemChange}
                      onBlur={handleOnBlur}
                      style={
                        validator.parameter.description
                          ? {
                              ...errorBorderNoRadius
                            }
                          : null
                      }
                    />
                    <p className="right floated content">
                      {charCounter.charsLeft}
                      {"/255"}
                    </p>
                  </Form.Field>
                </Form>
                {validator.parameter.description && (
                  <Label basic color="red" pointing content={validator.parameter.description} />
                )}
              </div>
            </div>
          </div>
          <Form size="tiny">
            <Segment.Group>
              <Segment color="teal" clearing inverted>
                <div className={styles.type_name_header}>
                  {" "}
                  <strong>{Localize.get("PROJECTS.TEMPLATES.PROJECT_TYPE_NAME")}</strong>
                </div>
              </Segment>
              <Segment>
                <Form.Field required>
                  <label>{Localize.get("PROJECTS.TEMPLATES.PROJECT_NAME")}</label>
                  <Dropdown
                    placeholder={Localize.get("PROJECTS.TEMPLATES.PROJECT_NAME")}
                    selection
                    search
                    className={styles.choose_type_dropdown}
                    options={projectTypeOptions}
                    onChange={handleSelectType}
                    defaultValue={template.projectType.name}
                    style={
                      validator.parameter.projectTypeValidation
                        ? {
                            ...errorBorderNoRadius
                          }
                        : null
                    }
                  />
                  {validator.parameter.projectTypeValidation && (
                    <Label
                      basic
                      color="red"
                      pointing
                      content={validator.parameter.projectTypeValidation}
                    />
                  )}
                  <br />
                  {selectedType && selectedType.length > 0 && (
                    <>
                      <Divider />
                      <strong>{Localize.get("PROJECTS.TEMPLATES.TYPE_DESCRIPTION_ONLY")}</strong>
                      <div className={styles.choosen_type}>{selectedType}</div>
                    </>
                  )}
                </Form.Field>
              </Segment>
            </Segment.Group>
          </Form>
          <EditRoles
            roles={template.roles}
            handleDeleteRole={handleDeleteRole}
            ref={refRoles}
            onChange={handleRoleChange}
            handleOnPlusClicked={handleOnPlusClick}
            generatedRoles={templateAvailableRoles}
          />
        </div>

        <ProjectSaveButtonsGroup
          onSaveButtonClick={props.handle.onSaveButtonClick}
          onSaveAndNewButtonClick={props.handle.onSaveAndNewButtonClick}
          activeMode={props.state.activeMode}
          onCancelButtonGroup={props.handle.onCloseButtonClick}
          setValidator={setValidator}
          refRoles={refRoles}
          requiredFields={constant.requiredFieldsNewTemplate}
          validator={validator}
          objectThatIWantToSave={template}
          editedFields={editedFields}
          setError={setTemplateNameErr}
          myParentElement={elements.projectElements.template}
        />
      </div>

      <div className={styles.clear_both} />
    </div>
  );
};

export default ProjectTemplateFormPanel;
