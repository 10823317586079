import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Accordion, Icon, Form, Input, Label } from "semantic-ui-react";
import styles from "./style/ContactFormContactPerson.module.css";
import * as constant from "../Constants.js";

const ContactFormContactPerson = props => {
  const { handler, onChange, index, validator, contact, title, validateContactsListField } = props;

  const [activeAccordion, setActiveAccordion] = useState(props.activeAccordion);

  const handleAccordionClick = (e, titleProps) => {
    handler.accordionHandler(index);
  };

  const handleOnChange = (event, data) => {
    let partner = { ...contact, [data.name]: data.value };
    if (validator && validator[data.name]) {
      validateContactsListField(data.name, data.value, index);
    }

    onChange(index, partner);
  };

  const handleOnBlur = (e, data) => {
    let propName = data ? data.name : e.target.name;
    let propValue = data ? data.value : e.target.value;
    if (propValue) {
      validateContactsListField(propName, propValue, index);
    }
  };

  const setTitle = () => {
    let newTitle =
      contact && contact.first
        ? contact.first + " " + (contact && contact.last ? contact.last : "")
        : contact && contact.last
        ? contact.last
        : title;
    return newTitle;
  };

  const handleOnDeleteClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.handleDelete(props.index);
  };

  useEffect(() => {
    setActiveAccordion(props.activeAccordion);
  }, [props.activeAccordion]);

  const errorBorder = "3px red solid";

  return (
    <Accordion className={styles.accordition_container} styled>
      <Accordion.Title active={activeAccordion} onClick={handleAccordionClick}>
        <Icon className={styles.dropdown_icon} name="dropdown" />
        {setTitle()}
        <Icon
          name="minus circle"
          className={styles.minus_circle_icon}
          onClick={handleOnDeleteClick}
        ></Icon>
      </Accordion.Title>
      <Accordion.Content className={styles.ac_content} active={activeAccordion}>
        <Form.Group className={styles.address_col} widths="equal">
          <Form.Field required>
            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}</label>
            <Input
              autoComplete={constant.autoComplete.off}
              name="first"
              style={{
                border: validator && validator.first ? errorBorder : ""
              }}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}
              value={!contact.first ? "" : contact.first}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {validator && validator.first && (
              <Label basic color="red" pointing content={validator.first} />
            )}
          </Form.Field>
          <Form.Field required>
            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}</label>
            <Input
              autoComplete={constant.autoComplete.off}
              name="last"
              style={{ border: validator && validator.last ? errorBorder : "" }}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}
              value={!contact.last ? "" : contact.last}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {validator && validator.last && (
              <Label basic color="red" pointing content={validator.last} />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.JOB_TITLE")}</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="jobTitle"
            style={{
              border: validator && validator.jobTitle ? errorBorder : ""
            }}
            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.JOB_TITLE")}
            value={!contact.jobTitle ? "" : contact.jobTitle}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.jobTitle && (
            <Label basic color="red" pointing content={validator.jobTitle} />
          )}
        </Form.Field>
        <Form.Group className={styles.address_col} widths="equal">
          <Form.Field>
            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE")}</label>
            <Input
              autoComplete={constant.autoComplete.off}
              name="phone"
              style={{
                border: validator && validator.phone ? errorBorder : ""
              }}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE")}
              value={!contact.phone ? "" : contact.phone}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {validator && validator.phone && (
              <Label basic color="red" pointing content={validator.phone} />
            )}
          </Form.Field>
          <Form.Field>
            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.MOBILE")}</label>
            <Input
              autoComplete={constant.autoComplete.off}
              name="mobile"
              style={{
                border: validator && validator.mobile ? errorBorder : ""
              }}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.MOBILE")}
              value={!contact.mobile ? "" : contact.mobile}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {validator && validator.mobile && (
              <Label basic color="red" pointing content={validator.mobile} />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL")}</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="email"
            style={{ border: validator && validator.email ? errorBorder : "" }}
            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL")}
            value={!contact.email ? "" : contact.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.email && (
            <Label basic color="red" pointing content={validator.email} />
          )}
        </Form.Field>
      </Accordion.Content>
    </Accordion>
  );
};

export default ContactFormContactPerson;
