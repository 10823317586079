import React, { useContext } from "react";
import { Grid, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import { AuthContext } from "../Auth/AuthContext";
import styles from "./Unauthorised.module.css";

const Unauthorised = props => {
  const { authState } = useContext(AuthContext);
  const { user } = authState;

  return (
    <>
      <Grid columns="three" textAlign="center" className={styles.unauthorised}>
        <Grid.Row>
          <Grid.Column width={3} />
          <Grid.Column width={10} className={styles.header_background}>
            <Icon circular color="red" name="info" size="big" />
            <h2> {Localize.get("UNAUTHORISED.INFORMATIONS")}</h2>
          </Grid.Column>
          <Grid.Column width={3} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} />
          <Grid.Column width={10} className="central_column">
            <h3>
              {Localize.get("UNAUTHORISED.USER") +
                " " +
                user.firstname +
                " " +
                user.lastname +
                " " +
                Localize.get("UNAUTHORISED.NOT_AUTORISED")}
            </h3>
          </Grid.Column>
          <Grid.Column width={3} />
        </Grid.Row>
        <Grid.Column width={3} />
      </Grid>
    </>
  );
};

export default Unauthorised;
