import { getCurrentDate, getBackThreeMonthsFromNow } from "./Util";

export const assignment = {
  assigned: "linked", //'assigned',
  notAssigned: "unlinked", //'notAssigned',
  assignedWithoutDoc: "linked_without_document", //'assignedWithoutDoc',
  all: "all"
};

export const direction = {
  revenue: "revenue",
  expense: "expense",
  all: "all"
};

export const invoiceDirection = {
  outgoing: "outgoing",
  incoming: "incoming",
  all: "all"
};

export const rightPanelState = {
  init: 0,
  thumbDetails: 1,
  linkedInvoiceDetails: 2
};

export const rightPanelButtonsState = {
  init: "init",
  assigned: "linked",
  assignedWithoutDoc: "linked_without_document",
  notAssigned: "unlinked"
};

export const bankAccount = {
  all: "All Accounts"
};

export const transactionListInitState = {
  activeListItem: undefined,
  selectAll: false,
  activeBankAccount: bankAccount.all,
  activeAssignmentFilter: assignment.all,
  activeDirectionFilter: direction.all,
  minDate: getBackThreeMonthsFromNow(),
  maxDate: getCurrentDate(),
  search: "",
  searchField: "",

  // assignment: '',
  // direction: '',
  counterpart: "", //for filter
  purpose: "", //for filter
  // bookingDate: '',
  amount: "", //for filter
  sortColumn: null,
  sortDirection: null
};

export const filterObjectForPagination = {
  assignment: "all",
  direction: "all",
  minDate: getBackThreeMonthsFromNow(),
  maxDate: getCurrentDate()
};

export const invoicesListHeader = [
  {
    name: "checkbox",
    hederName: "TRANSACTIONS.INVOICETHUMB.CHECKBOX",
    type: "checkbox",
    width: 2,
    visible: true
  },
  {
    name: "documentNumber",
    hederName: "TRANSACTIONS.INVOICETHUMB.NO",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "amount",
    hederName: "GLOBAL.DETAILS.AMOUNT",
    type: "amount",
    width: 4,
    visible: true
  },
  {
    name: "direction",
    hederName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION",
    type: "text",
    width: 4,
    visible: true
  },
  {
    name: "preview",
    icons: [{ name: "eye", popupText: "TRANSACTIONS.INVOICETHUMB.PREVIEW" }],
    hederName: "TRANSACTIONS.INVOICETHUMB.PREVIEW",
    type: "iconGroup",
    width: 3,
    visible: true
  }
];
