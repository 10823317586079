import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";

const GlobalInfoDialog = props => {
  const { modalContent, onClose, modalHeader } = props;

  return (
    <Modal size="small" open={true} closeIcon onClose={onClose}>
      <Modal.Header>{Localize.get(modalHeader)}</Modal.Header>
      <Modal.Content>
        <Modal.Content>{Localize.get(modalContent)}</Modal.Content>
      </Modal.Content>
    </Modal>
  );
};

export default GlobalInfoDialog;
