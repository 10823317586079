import React, { useState } from "react";
import { Header, Dropdown, Input, Grid, Menu, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/GlobalHeaderForList.module.css";
import propTypes from "prop-types";
import HeaderSettingsModal from "../Panels/HeaderSettingsModal";

const GlobalHeaderForList = props => {
  const {
    availableItemFieldsForList = [],
    searchFilter = function(arg) {},
    filterObject = {},
    searchObject = {},
    disableInput,
    sortHandler = function(arg) {},
    headerIcon = true,
    setFieldsForList = function(arg) {}
  } = props;

  const [settingModal, setSettingModal] = useState(false);

  const onOkSettingsClicked = () => {
    setSettingModal(false);
  };

  return (
    <>
      {settingModal && (
        <HeaderSettingsModal
          settingModal={settingModal}
          setSettingModal={setSettingModal}
          availableItemFieldsForList={availableItemFieldsForList}
          setFieldsForList={setFieldsForList}
          onOkSettingsClicked={onOkSettingsClicked}
        />
      )}
      <Menu.Item secondary="true" className={styles.header_above_list}>
        <Grid padded verticalAlign="middle">
          {availableItemFieldsForList
            .filter(item => item.visible)
            .map((item_field, index) => {
              return (
                <Grid.Column
                  key={index}
                  width={item_field.width}
                  className={
                    item_field && item_field.column_customized_css
                      ? item_field.column_customized_css
                      : styles.column_field
                  }
                >
                  {item_field.searchable ? (
                    <Menu.Item className={styles.header_item}>
                      <Dropdown
                        icon={null}
                        trigger={
                          <div className={styles.dropdown_trigger}>
                            <Header className={styles.header_list} as="h5">
                              {item_field.hederName && Localize.get(item_field.hederName)}
                            </Header>
                            {filterObject.sortBy === item_field.name &&
                              filterObject.sortDirection === "desc" && <Icon name="angle down" />}
                            {filterObject.sortBy === item_field.name &&
                              filterObject.sortDirection === "asc" && <Icon name="angle up" />}
                            {searchObject[item_field.name] ? <Icon name="filter" /> : null}
                          </div>
                        }
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            text={Localize.get("SETTINGS.HEADERS.SORT_ASC")}
                            icon="sort amount up"
                            name={item_field.name}
                            onClick={() => {
                              sortHandler(item_field.name, "asc", filterObject);
                            }}
                          />
                          <Dropdown.Item
                            text={Localize.get("SETTINGS.HEADERS.SORT_DESC")}
                            icon="sort amount down"
                            name={item_field.name}
                            onClick={() => {
                              sortHandler(item_field.name, "desc", filterObject);
                            }}
                          />
                          <Dropdown.Divider />
                          <Input
                            autoComplete="off"
                            onClick={e => e.stopPropagation()}
                            disabled={item_field.disabled ? disableInput : false}
                            onChange={(e, data) => searchFilter(e, data, searchObject)}
                            value={
                              searchObject[item_field.name] ? searchObject[item_field.name] : ""
                            }
                            name={item_field.name}
                            icon="search"
                            iconPosition="left"
                            className="search"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>
                  ) : (
                    <Menu.Item className={styles.header_item}>
                      <Header className={styles.heder_list} as="h5">
                        {item_field.hederName && Localize.get(item_field.hederName)}
                      </Header>
                    </Menu.Item>
                  )}
                </Grid.Column>
              );
            })}
        </Grid>
        {headerIcon && (
          <Icon name="setting" className={styles.set_icon} onClick={() => setSettingModal(true)} />
        )}
      </Menu.Item>
    </>
  );
};

GlobalHeaderForList.propTypes = {
  availableItemFieldsForList: propTypes.array,
  searchFilter: propTypes.func,
  filterObject: propTypes.object,
  searchObject: propTypes.object,
  sortHandler: propTypes.func,
  disableInput: propTypes.bool
};
export default GlobalHeaderForList;
