import React, { useState, forwardRef, useImperativeHandle } from "react";
import Localize from "react-intl-universal";
import { Dropdown, Input, Form, Segment, Label, TextArea, Tab, Menu } from "semantic-ui-react";
import ContactFormAddress from "./ContactFormAddress";
import * as constant from "../Constants.js";
import * as RestService from "../../RestService";
import * as validatorConstants from "../../Utils/Constant";
import styles from "./style/ContactFormEmployee.module.css";
import useValidator from "../../Utils/useValidator";
import {
  partnerEmployeeSchema,
  addressSchema,
  companyEmpleyeeSchema
} from "../../Utils/PartnersSchema";

const ContactFormEmployee = forwardRef((props, ref) => {
  const { setEditedFields, editedFields, contact, setContact } = props;

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyOptionsLoader, setCompanyOptionsLoader] = useState(false);
  const companyService = new RestService.Company.GetAll.Service();
  companyService.query.clearPageSize();

  const [parameters, , validateOnSave, validateField] = useValidator(
    partnerEmployeeSchema,
    "object",
    "always"
  );

  const [parametersCompany, , validateOnSaveCompany, validateFieldCompany] = useValidator(
    companyEmpleyeeSchema,
    "object",
    "always"
  );

  const [parametersAddress, , validateOnSaveAddress, validateFieldAddress] = useValidator(
    addressSchema,
    "object",
    "always"
  );

  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (contact && contact.note ? contact.note.length : 0)
  });

  const callbackGetCompaniesSuccess = response => {
    const companyLst = response.data.data.map(c => ({
      text: c.name,
      value: c.name,
      key: c.id
    }));
    setCompanyOptions(companyLst);
  };

  const callbackGetCompaniesError = error => {};

  const callbackGetCompaniesFinally = response => {
    setCompanyOptionsLoader(false);
  };

  companyService.setCallbackSuccess(callbackGetCompaniesSuccess);
  companyService.setCallbackError(callbackGetCompaniesError);
  companyService.setCallbackFinally(callbackGetCompaniesFinally);

  const handleFieldChange = (e, data) => {
    let newContact;
    switch (data.name) {
      case "company":
        const cmpId = companyOptions.find(company => company.value === data.value.toString());
        newContact = {
          ...contact,
          company: {
            name: data.value,
            id: cmpId.key
          }
        };
        validateFieldCompany("name", data.value);
        break;
      default:
        newContact = { ...contact, [data.name]: data.value };
    }

    if (data.value) {
      const validationOnField = parameters[data.name];
      if (validationOnField) {
        validateField(data.name, data.value);
      }
    }

    if (e && e.target && e.target.name === "note") {
      let inputChar = e.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }
    setContact(newContact);

    if (data.name && !editedFields.includes(data.name)) {
      const newArr = [...editedFields];
      newArr.push(data.name);
      setEditedFields(newArr);
    }
  };

  const handleOnBlur = (e, data) => {
    if (
      e &&
      e.target &&
      e.target.name in validatorConstants.employeeFormNewFields &&
      e.target.value
    ) {
      validateField(e.target.name, e.target.value);
    }
  };

  const handleCompaniesSearch = (e, data) => {
    if (data.searchQuery.length >= 3) {
      setCompanyOptionsLoader(true);
      companyService.query.addSearchParam(data.searchQuery);
      companyService.query.addSearchMatchParam("containing");
      setCompanyOptions([]);
      companyService.call();
    } else if (data.searchQuery.length === 0 && companyOptions.length === 0) {
      companyService.call();
    }
  };

  const resetCompanyOptions = () => {
    companyService.call();
  };

  useImperativeHandle(ref, () => ({
    validateFormOnSave(employee) {
      let validGeneralPart = validateOnSave(employee);
      let validCompanyOfEmployee = validateOnSaveCompany(employee.company);

      let validAddress = validateOnSaveAddress(employee.address);
      validGeneralPart = validGeneralPart && validAddress && validCompanyOfEmployee;

      return validGeneralPart;
    }
  }));

  const errorBorder = "3px red solid";

  return (
    <>
      <div className={styles.divided_list}>
        <div className="item add_company_name">
          <div className="content">
            <Tab
              className={styles.tab_panel}
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: (
                    <Menu.Item key={0} className={styles.tab_title}>
                      {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL")}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Form size="tiny" className={styles.form}>
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>
                              {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL_INFORMATION")}
                            </strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Group widths="equal">
                            <Form.Field required>
                              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}</label>
                              <Input
                                autoComplete={constant.autoComplete.none}
                                name="first"
                                className={styles.input_width}
                                style={{
                                  border: parameters && parameters.first ? errorBorder : ""
                                }}
                                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}
                                value={contact.first === undefined ? "" : contact.first}
                                onChange={handleFieldChange}
                                onBlur={handleOnBlur}
                              />
                              {parameters && parameters.first && (
                                <Label basic color="red" pointing content={parameters.first} />
                              )}
                            </Form.Field>
                            <Form.Field required>
                              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}</label>
                              <Input
                                autoComplete={constant.autoComplete.none}
                                name="last"
                                className={styles.input_width}
                                style={{
                                  border: parameters && parameters.last ? errorBorder : ""
                                }}
                                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}
                                value={contact.last === undefined ? "" : contact.last}
                                onChange={handleFieldChange}
                                onBlur={handleOnBlur}
                              />
                              {parameters && parameters.last && (
                                <Label basic color="red" pointing content={parameters.last} />
                              )}
                            </Form.Field>
                          </Form.Group>
                          <Form.Group>
                            <Form.Field className={styles.job_title_input}>
                              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.JOB_TITLE")}</label>
                              <Input
                                autoComplete={constant.autoComplete.none}
                                name="jobTitle"
                                style={{
                                  border: parameters && parameters.jobTitle ? errorBorder : ""
                                }}
                                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.JOB_TITLE")}
                                value={contact.jobTitle === undefined ? "" : contact.jobTitle}
                                onChange={handleFieldChange}
                                onBlur={handleOnBlur}
                              />
                              {parameters && parameters.jobTitle && (
                                <Label basic color="red" pointing content={parameters.jobTitle} />
                              )}
                            </Form.Field>
                          </Form.Group>
                          <Form.Field className={styles.note_field}>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.NOTE")}</label>
                            <TextArea
                              name="note"
                              style={{
                                border: parameters && parameters.note ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.NOTE_PLACEHOLDER"
                              )}
                              value={contact.note === undefined ? "" : contact.note}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                              maxLength={255}
                            />
                            <p className={styles.counter_note}>
                              {charCounter.charsLeft}
                              {"/255"}
                            </p>
                          </Form.Field>
                          {parameters && parameters.note && (
                            <Label basic color="red" pointing content={parameters.note} />
                          )}
                        </Segment>
                      </Segment.Group>

                      {/* Company */}
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>{Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.COMPANY")}</strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Field required>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.COMPANY")}</label>
                            <Dropdown
                              autoComplete={constant.autoComplete.none}
                              placeholder={
                                contact && contact.company && contact.company.name
                                  ? contact.company.name
                                  : Localize.get(
                                      "CONTACTS.NEWCONTACT.FORM.COMPANY_SELECT_PLACEHOLDER"
                                    )
                              }
                              name="company"
                              selection
                              search
                              closeOnChange
                              loading={companyOptionsLoader}
                              value={""}
                              options={companyOptions}
                              onChange={handleFieldChange}
                              onSearchChange={handleCompaniesSearch}
                              onOpen={resetCompanyOptions}
                              style={{
                                border:
                                  parametersCompany && parametersCompany.name ? errorBorder : ""
                              }}
                              className={styles.wordWrapClass}
                            />
                            {parametersCompany && parametersCompany.name && (
                              <Label basic color="red" pointing content={parametersCompany.name} />
                            )}
                          </Form.Field>
                        </Segment>
                      </Segment.Group>

                      {/* Communication */}
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>
                              {Localize.get("CONTACTS.NEWCONTACT.FORM.COMMUNICATION")}
                            </strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE")}</label>
                            <Input
                              autoComplete={constant.autoComplete.none}
                              name="phone"
                              style={{
                                border: parameters && parameters.phone ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.PHONE_PLACEHOLDER"
                              )}
                              value={contact.phone === undefined ? "" : contact.phone}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.phone && (
                              <Label basic color="red" pointing content={parameters.phone} />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.MOBILE")}</label>
                            <Input
                              autoComplete={constant.autoComplete.none}
                              name="mobile"
                              style={{
                                border: parameters && parameters.mobile ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.MOBILE_PLACEHOLDER"
                              )}
                              value={contact.mobile === undefined ? "" : contact.mobile}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.mobile && (
                              <Label basic color="red" pointing content={parameters.mobile} />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL")}</label>
                            <Input
                              autoComplete={constant.autoComplete.none}
                              name="email"
                              style={{
                                border: parameters && parameters.email ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.EMAIL_PLACEHOLDER"
                              )}
                              value={contact.email === undefined ? "" : contact.email}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.email && (
                              <Label basic color="red" pointing content={parameters.email} />
                            )}
                          </Form.Field>
                        </Segment>
                      </Segment.Group>

                      {/* Addresses */}
                      <Segment className={styles.title}>
                        <div>
                          <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESSES")}</strong>
                        </div>
                      </Segment>
                      <ContactFormAddress
                        key={1}
                        name="address"
                        needsBilling="false"
                        title={Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESS")}
                        address={contact.address}
                        onChange={handleFieldChange}
                        open={true}
                        parameters={parametersAddress}
                        validateField={validateFieldAddress}
                      />
                    </Form>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ContactFormEmployee;
