import React from "react";
import DocumentScanFilesItem from "./DocumentScanFilesItem";
import Localize from "react-intl-universal";
import styles from "./style/DocumentScanFilesList.module.css";
import { Grid, Message, Icon, Button, Dimmer, Loader } from "semantic-ui-react";
import ListWithPagination from "../../Components/ListWithPagination";

const DocumentScanFilesList = props => {
  const {
    state,
    handler,
    handleScanedFilesPageChange,
    paginationScanedFiles,
    activePaginationScandedFilesPage,
    scanedFiles,
    isLoadingScansList
  } = props;

  const onListItemClick = (event, data) => {
    handler.documentScansPanel.onScanedListItemClick(data);
  };

  const documentScansList = scanedFiles.map(docFile => (
    <DocumentScanFilesItem
      key={docFile.id}
      state={state}
      documentType={docFile.documentType}
      fileName={docFile.binaryContent.fileName}
      onListItemClick={onListItemClick}
      documentRecognitionStatus={docFile.documentRecognitionStatus}
      selectedfilebinaryid={docFile.binaryContent.id}
      binaryContent={docFile.binaryContent}
      id={docFile.id}
      scannedContent={docFile.scannedContent}
      handler={handler}
    />
  ));

  return (
    <>
      <Button
        animated
        basic
        className={styles.scaned_list_toolbar_button}
        onClick={handler.documentScansPanel.onBackButtonClick}
      >
        <Button.Content visible>{Localize.get("DOCUMENTS.SCAN.BACK")}</Button.Content>
        <Button.Content hidden>
          <Icon name="arrow left" />
        </Button.Content>
      </Button>
      <Button
        icon="refresh"
        className={styles.scaned_list_toolbar_button}
        primary
        onClick={handler.documentScansPanel.onRefreshBtnClick}
      />
      <Grid className={styles.header_scan_documents} columns="equal" verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={6}>{Localize.get("DOCUMENTS.SCAN.FILE_NAME")}</Grid.Column>
          <Grid.Column width={5}>{Localize.get("DOCUMENTS.SCAN.DOC_TYPE")}</Grid.Column>
          <Grid.Column width={4}>{Localize.get("DOCUMENTS.SCAN.SELECT_FOR_PROCESING")}</Grid.Column>
        </Grid.Row>
      </Grid>

      <Dimmer active={isLoadingScansList} inverted>
        <Loader size="large" disabled={!isLoadingScansList}>
          {Localize.get("DOCUMENTS.SCAN.DOCUMENT_LOADER")}
        </Loader>
      </Dimmer>

      {documentScansList.length > 0 ? (
        <>
          <ListWithPagination
            list={documentScansList}
            state={state}
            defaultActivePage={paginationScanedFiles.pageCount}
            totalPage={paginationScanedFiles.totalCount}
            handlePageChange={handleScanedFilesPageChange}
            activePaginationPage={activePaginationScandedFilesPage}
            perPage={paginationScanedFiles.perPage}
          />
        </>
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("DOCUMENTS.SCAN.EMPTY")}
        </Message>
      )}
    </>
  );
};

export default DocumentScanFilesList;
