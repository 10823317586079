import { useState } from "react";
import { fetchDefaultTemplate, patchOrPostService } from "./DefaultTemplateServices";
import useFetch from "./useFetch";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";
import usePostOrPatch from "./usePostOrPatch";
import { get } from "lodash";

const useDefaultTemplateServices = (selectedItem, currentView) => {
  const [forbidenResponse, setForbidenResponse] = useState(false);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const getPath = () => {
    let path = get(selectedItem, "categoryKey") ? get(selectedItem, "categoryKey") : "doc";
    return `/categories/${path}`;
  };

  const callbackFetchSuccess = (response, setData, setPaging) => {
    setForbidenResponse(false);
    setSelectedTemplate(response);
  };

  const callbackFetchError = err => {
    console.err(err);
    setSelectedTemplate([]);
    showNotificationMessage({
      type: "warning",
      operation: ""
    });
  };

  const [
    selectedTemplate,
    paging,
    loading,
    fetchDefaultTemplateCall,
    setSelectedTemplate
  ] = useFetch(fetchDefaultTemplate, [], getPath(), {}, callbackFetchSuccess, callbackFetchError);

  const callbackPostSuccess = () => {};

  const callbackPostError = () => {
    console.err("error");
    showNotificationMessage({
      type: "warning",
      operation: ""
    });
  };

  const [putDefaultTemplate, putLoading] = usePostOrPatch(
    patchOrPostService,
    callbackPostSuccess,
    callbackPostError
  );

  return {
    selectedTemplate,
    paging,
    loading,
    fetchDefaultTemplateCall,
    setSelectedTemplate,
    forbidenResponse,
    putDefaultTemplate,
    putLoading
  };
};

export default useDefaultTemplateServices;
