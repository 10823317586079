import React from "react";
import { Input, Popup, Icon, Dropdown, Checkbox } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/BookingToolbar.module.css";
import ToolbarDatepicker from "../Components/Inputs/ToolbarDatepicker";

const BookkeepingToolBar = props => {
  const { searchName, enteredValue, resetNameSearch, checkboxLabel = "", actionOptions } = props;

  const dateChangeHandler = newDate => {
    console.log(newDate);
  };

  return (
    <>
      {checkboxLabel !== "" && (
        <Checkbox
          className={styles.check_box}
          label={Localize.get("BOOKKEEPING.TOOLBAR_CHEKBOX")}
          // onChange={}
          // checked={}
        />
      )}

      <Popup
        content={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PLACEHOLDER")}
        on={["hover", "click"]}
        trigger={
          <Input
            icon={
              enteredValue ? (
                <Icon name="close" onClick={resetNameSearch} circular link size="small" />
              ) : (
                <Icon name="search" />
              )
            }
            className={styles.header_search}
            name="search"
            onChange={searchName}
            autoComplete={"off"}
            value={enteredValue ? enteredValue : ""}
            type="text"
            placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
          />
        }
      />
      <ToolbarDatepicker disabledDropdown={false} dateChangeHandler={dateChangeHandler} />

      <Dropdown
        text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.ACTIONS")}
        button
        className={styles.headerActions}
      >
        <Dropdown.Menu className={styles.dropdowmMenu}>
          {actionOptions.map((item, index) => (
            <Dropdown.Item
              key={index}
              icon={item.icon}
              name={item.name}
              // active={}
              text={item.text}
              // onClick={}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default BookkeepingToolBar;
