import React from "react";
import { Menu, Grid, Checkbox } from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as constant from "../Constants.js";
import styles from "./style/ReferedInvoiceItem.module.css";

const ReferedInvoiceItem = props => {
  const { document, onToggleItem } = props;
  let classPaymentStatuses;
  let classApprovalStatuses;
  let classVerificationStatuses;

  let verificationStatus;
  let paymentStatus;
  let approvalStatus;

  switch (document.approvalStatus) {
    case constant.paymentApprovalStatus.unresolved:
      classApprovalStatuses = `ui label ${styles.unresolved}`;
      approvalStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_UNRESOLVED");
      break;
    case constant.paymentApprovalStatus.approved:
      classApprovalStatuses = `ui label ${styles.approved}`;
      approvalStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_APPROVED");
      break;
    case constant.paymentApprovalStatus.declined:
      classApprovalStatuses = `ui label ${styles.declined}`;
      approvalStatus = approvalStatus = Localize.get(
        "DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_DECLINED"
      );
      break;
    default:
  }

  switch (document.paymentStatus) {
    case constant.paymentStatus.paid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PAID");
      break;
    case constant.paymentStatus.notPaid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.not_paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTPAID");
      break;
    case constant.paymentStatus.partialPaid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.partial_paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PARTIAL_PAID");
      break;
    case constant.paymentStatus.notRelevant:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.not_relevant}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_NOT_RELEVANT");
      break;
    case constant.paymentStatus.notSuccessful:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.notsucc}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTSUCC");
      break;
    case constant.paymentStatus.pending:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.pending}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PENDING");
      break;
    default:
  }

  switch (document.verificationStatus) {
    case constant.verificationStatus.approved:
      classVerificationStatuses = `ui label ${styles.verified}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATIONAPPROVED");
      break;
    case constant.verificationStatus.declined:
      classVerificationStatuses = `ui label ${styles.declined}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATIONDECLINED");
      break;
    case constant.verificationStatus.unresolved:
      classVerificationStatuses = `ui label ${styles.unverified}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATIONUNRESOLVED");
      break;

    default:
  }

  return (
    <Menu.Item className={styles.bms_documents_item}>
      <Grid stackable container verticalAlign="middle">
        <Grid.Column width={1}>
          <Checkbox item_id={document.id} checked={document.checked} onClick={onToggleItem} />
        </Grid.Column>
        <Grid.Column width={2}>
          {" "}
          <span className={classVerificationStatuses}>{verificationStatus}</span>
          {(document.documentType === constant.documentType.invoice ||
            document.documentType === constant.documentType.correction) &&
            document.direction === constant.documentDirection.incoming && (
              <span className={classApprovalStatuses}>{approvalStatus}</span>
            )}
        </Grid.Column>
        <Grid.Column width={3}>
          <div className={`${styles.list_desc_doc_number} ${styles.list_desc}`}>
            {document.documentNumber}
          </div>
        </Grid.Column>
        <Grid.Column className={styles.partner_name_column} width={4}>
          {document.partner ? document.partner.name : null}
        </Grid.Column>
        <Grid.Column width={3}>
          {new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR"
          }).format(document.amount)}
        </Grid.Column>
        <Grid.Column width={2} className={styles.colFixedHeight}>
          {(document.documentType === constant.documentType.invoice ||
            document.documentType === constant.documentType.correction) &&
            document.direction === constant.documentDirection.incoming && (
              <span size="mini" className={classPaymentStatuses}>
                {paymentStatus}
              </span>
            )}
        </Grid.Column>
      </Grid>
    </Menu.Item>
  );
};

export default ReferedInvoiceItem;
