export const dunning_phases = [
  {
    name: "phase.reminder",
    hederName: "PROPERTIES.FIELDS.payment_remainder",
    type: "boolean",
    isRequired: true
  },
  {
    name: "phase.dunning1",
    hederName: "PROPERTIES.FIELDS.dunning_1",
    type: "boolean",
    isRequired: true
  },
  {
    name: "phase.dunning2",
    hederName: "PROPERTIES.FIELDS.dunning_2",
    type: "boolean",
    isRequired: true
  },
  {
    name: "phase.dunning3",
    hederName: "PROPERTIES.FIELDS.dunning_3",
    type: "boolean",
    isRequired: true
  }
];

export const dunning_deadlines = [
  {
    name: "deadline.reminder",
    hederName: "PROPERTIES.FIELDS.payment_remainder",
    type: "input",
    isRequired: true
  },
  {
    name: "deadline.dunning1",
    hederName: "PROPERTIES.FIELDS.dunning_1",
    type: "input",
    isRequired: true
  },
  {
    name: "deadline.dunning2",
    hederName: "PROPERTIES.FIELDS.dunning_2",
    type: "input",
    isRequired: true
  },
  {
    name: "deadline.dunning3",
    hederName: "PROPERTIES.FIELDS.dunning_3",
    type: "input",
    isRequired: true
  }
];

export const dunning_email = [
  {
    name: "mail.invoiceSettings",
    hederName: "PROPERTIES.FIELDS.use_invoice_email",
    type: "boolean",
    isRequired: true
  },
  {
    name: "mail.smtp.host",
    hederName: "PROPERTIES.FIELDS.mail_smtp_host",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.port",
    hederName: "PROPERTIES.FIELDS.mail_smtp_port",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.ssl.trust",
    hederName: "PROPERTIES.FIELDS.mail_smtp_ssl_trust",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.user",
    hederName: "PROPERTIES.FIELDS.mail_smtp_user",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.pass",
    hederName: "PROPERTIES.FIELDS.mail_smtp_pass",
    type: "password",
    isRequired: true
  },
  {
    name: "mail.smtp.starttls.enable",
    hederName: "PROPERTIES.FIELDS.mail_smtp_starttls_enable",
    type: "boolean",
    isRequired: true
  },
  {
    name: "mail.smtp.auth",
    hederName: "PROPERTIES.FIELDS.mail_smtp_auth",
    type: "boolean",
    isRequired: true
  }
];
