import React from "react";
import { Button } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DocumentScansPanel.module.css";

const DocumentScansPanel = props => {
  const { handler, isLoadingScansList, state } = props;

  const onPreviewClick = (event, data) => {
    handler.documentScansPanel.onScanedItemPreviewClick(data.selectedfilebinaryid);
  };

  return (
    <>
      <div className={`header ${styles.bms_header}`}>
        {Localize.get("DOCUMENTS.SCAN.HEADER_SCANS")}
      </div>
      <div className={styles.panel_area}>
        <div>
          <div> {Localize.get("DOCUMENTS.SCAN.CLICK_PREVIEW")}</div>
          <Button
            disabled={isLoadingScansList}
            basic
            size="massive"
            icon="eye"
            onClick={onPreviewClick}
            selectedfilebinaryid={state.selectedfilebinaryid}
          />
        </div>
      </div>
    </>
  );
};

export default DocumentScansPanel;
