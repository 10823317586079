import React, { useState, useEffect, useRef } from "react";
import { Label, Segment, Dropdown, Form, TextArea, FormField, Input } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../../Utils/Validator";
import * as constant from "../../../Utils/Constant";
import * as constants from "../Constants";
import * as elements from "../../../Components/Constants";
import styles from "./style/ProjectManagementPanel.module.css";
import ProjectSaveButtonsGroup from "../../../Components/ProjectSaveButtonsGroup";
import ProjectResources from "./ProjectResources";
import ConfirmCancelDialog from "../../../Dialogs/ConfirmCancelDialog";

import { ProjectTemplate, Project } from "../../../RestService";

const ProjectManagementPanel = props => {
  const { panelState, formErrorMessage, clearProjectForm, editMode, handlerButton } = props;
  let generateTemplates = useRef();
  const refProjectResources = useRef();
  const refProjectPanelContacts = useRef();
  const refProjectPanelAddress = useRef();
  const refProjectPanelDeliveryAddress = useRef();
  const scrollContainer = useRef();

  const [projectTemplateOptions, setProjectTemplateOptions] = useState();

  const [dialogErr, setDialogErr] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [projectsNames, setProjectsNames] = useState();

  const panelLabel =
    panelState.activeMode === constants.panelMode.edit
      ? Localize.get("PROJECTS.MANAGEMENT.EDIT_PROJECT")
      : Localize.get("PROJECTS.MANAGEMENT.CREATE_PROJECT");

  const emptyProject = {
    projectName: "",
    contacts: [{}],
    officeAddress: {
      street: "",
      zip: "",
      city: "",
      state: "",
      country: ""
    },
    deliveryAddress: {},
    projectTemplate: {
      name: "",
      description: "",
      id: "",
      projectType: {
        id: "",
        name: "",
        description: ""
      },
      roles: []
    },
    documents: [],
    resources: [],
    projectStatus: {
      id: "01_NEW",
      name: "NEW"
    }
  };

  const [project, setProject] = useState(
    panelState.activeMode === constants.panelMode.edit ? props.project : emptyProject
  );

  const newResources = project.resources.map(r => {
    return r;
  });

  const [partners, setPartners] = useState(
    panelState.activeMode === constants.panelMode.edit ? project.resources : []
  );

  const [partnerCounter, setPartnerCounter] = useState(
    panelState.activeMode === constants.panelMode.edit
      ? [{ partnersLeft: newResources.length }]
      : [{ partnersLeft: project.resources.length }]
  );

  const [temporaryData, setTemporaryData] = useState();

  const [validator, setValidator] = useState(
    props.validator === undefined
      ? new Validator(constant.formFieldsProjectManagement)
      : props.validator
  );

  const [refreshValidation, setRefreshValidation] = useState(false);

  const [projectNameErr, setProjectNameErr] = useState(false);
  const [editedFields, setEditedFields] = useState([]);
  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (project && project.projectDescription ? project.projectDescription.length : 0)
  });

  const statusOptions = [
    {
      key: 0,
      text: Localize.get("PROJECTS.MANAGEMENT.STATUS_NEW"),
      value: "01_NEW"
    },
    {
      key: 1,
      text: Localize.get("PROJECTS.MANAGEMENT.STATUS_PROCESS"),
      value: "02_IN_PROCESS"
    },
    {
      key: 2,
      text: Localize.get("PROJECTS.MANAGEMENT.STATUS_CLOSED"),
      value: "03_CLOSED"
    }
  ];

  // GetTemplates
  const callbackGetAllTemplatesSuccess = response => {
    generateTemplates.current = response.data.data;

    const templateOptions = generateTemplates.current.map((t, i) => ({
      key: t.id,
      text: t.name,
      value: t.id
    }));
    setProjectTemplateOptions(templateOptions);
  };
  const callbackGetAllTemplatesError = error => {};
  const callbackGetAllTemplatesFinally = response => {};

  const getAllProjectTemplatesService = new ProjectTemplate.GetAll.Service();
  getAllProjectTemplatesService.setCallbackSuccess(callbackGetAllTemplatesSuccess);
  getAllProjectTemplatesService.setCallbackError(callbackGetAllTemplatesError);
  getAllProjectTemplatesService.setCallbackFinally(callbackGetAllTemplatesFinally);

  const callbackGetAllSuccess = response => {
    setProjectsNames(response.data.data.map(pro => pro.projectName));
  };
  const callbackGetAllError = error => {};
  const callbackGetAllFinally = response => {};

  const projectsGetAllService = projectName => {
    const getAllProjectService = new Project.GetAll.Service();
    getAllProjectService.setCallbackSuccess(callbackGetAllSuccess);
    getAllProjectService.setCallbackError(callbackGetAllError);
    getAllProjectService.setCallbackFinally(callbackGetAllFinally);
    getAllProjectService.query.clearPageSize();
    getAllProjectService.query.addSearchProjectParam(projectName);
    getAllProjectService.call();
  };

  const onItemChange = (event, data) => {
    setProjectNameErr(false);
    if (!data.value) {
      // clear validation messages after field is cleared out

      validator.validateField(event.target.value, event.target.name);
    } else {
      const validationOnField = validator.parameter[data.name];

      if (validationOnField) {
        validator.validateField(data.value, data.name);
      }
    }

    setProject({ ...project, [event.target.name]: event.target.value });

    if (!editedFields.includes("projectName")) {
      const newArr = [...editedFields];
      newArr.push("projectName");
      setEditedFields(newArr);
    }
  };

  const handleFieldChange = (e, data) => {
    setProject({ ...project, [data.name]: data.value });

    if (data.name && !editedFields.includes(data.name)) {
      const newArr = [...editedFields];
      newArr.push(data.name);
      setEditedFields(newArr);
    }
    if (e && e.target && e.target.name === "projectDescription") {
      let inputChar = e.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }
  };

  const handleOnBlur = (event, data) => {
    validator.validateField(event.target.value, event.target.name);
    setRefreshValidation(!refreshValidation);
  };

  const handleStatusChange = (e, data) => {
    setProject({
      ...project,
      projectStatus: {
        id: data.value,
        name: getStatusName(data.value)
      }
    });

    if (!editedFields.includes("projectStatus")) {
      const newArr = [...editedFields];
      newArr.push("projectStatus");
      setEditedFields(newArr);
    }
  };

  const getStatusName = data => {
    return data.substring(3);
  };

  useEffect(() => {
    getAllProjectTemplatesService.call();
    projectsGetAllService(project.projectName);
  }, []);

  useEffect(() => {
    if (formErrorMessage) {
      validator.addErrorMsgParam(formErrorMessage);
    }
    if (panelState.activeMode === constants.panelMode.edit) {
      setProject(props.project);
    }
  }, [props.project, formErrorMessage]);

  useEffect(() => {
    if (panelState.activeMode === constants.panelMode.create) {
      setProject(emptyProject);
      setPartners([]);
    } else {
      setProject(project);
    }
  }, [panelState.activeMode, clearProjectForm]);

  const errorBorderNoRadius = { border: "3px red solid", borderRadius: "0px" };

  const handleSelectTemplate = (event, data) => {
    if (panelState.activeMode === constants.panelMode.create) {
      validator.validateField(data.value, data.name);
      const selectedTempalteObj = generateTemplates.current.find(t => t.id === data.value);

      delete selectedTempalteObj.projectType.id;
      setProject({ ...project, projectTemplate: selectedTempalteObj });
    } else {
      setDialogErr(true);
      setTemporaryData(data);
    }
  };

  const handleTemplateChange = () => {
    setDialogErr(false);
    validator.validateField(temporaryData.value, temporaryData.name);
    const selectedTempalteObj = generateTemplates.current.find(t => t.id === temporaryData.value);

    setProject({
      ...project,
      projectTemplate: selectedTempalteObj,
      resources: []
    });

    if (!editedFields.includes("projectTemplate")) {
      const newArr = [...editedFields];
      newArr.push("projectTemplate");
      newArr.push("resources");
      setEditedFields(newArr);
    }
  };

  const handlePartnerSelection = (newSelectedPartner, curentRole) => {
    setPartnerCounter([
      {
        partnersLeft: partnerCounter[0].partnersLeft + 1
      }
    ]);

    setPartners([
      {
        id: {
          ...curentRole.id,
          partner: newSelectedPartner,
          phone: true,
          mobile: true,
          email: true
        }
      },
      ...partners
    ]);

    if (!editedFields.includes("resources")) {
      const newArr = [...editedFields];
      newArr.push("resources");
      setEditedFields(newArr);
    }
  };

  const newDetailsFields = (newObj, partnerId, rolaId) => {
    let partnerForChange = partners
      .filter(el => el.id.resourceRole.id === rolaId)
      .find(el => el.id.partner.id === partnerId);
    let newPartners = [...partners];
    let index = partners.indexOf(partnerForChange);
    if (index !== -1) {
      newPartners[index] = { id: newObj };
    }
    setPartners(newPartners);
    if (!editedFields.includes("resources")) {
      const newArr = [...editedFields];
      newArr.push("resources");
      setEditedFields(newArr);
    }
  };

  const handlePartnerDelete = (id, partnersRoleId) => {
    const delPersons = partners.filter(p => {
      return String(p.id.partner.id) !== String(id)
        ? p
        : String(p.id.resourceRole.id) !== String(partnersRoleId)
        ? p
        : false;
    });

    setPartnerCounter([
      {
        partnersLeft: partnerCounter[0].partnersLeft - 1
      }
    ]);
    setPartners(delPersons);
  };

  const getDialogError = () => {
    let dialog = null;
    dialog = (
      <ConfirmCancelDialog
        modalColor={"red"}
        openModal={true}
        modalHeader={Localize.get("PROJECTS.MANAGEMENT.BE_CAREFUL")}
        modalContent={Localize.get("PROJECTS.MANAGEMENT.WARNING_MESSAGE")}
        onModalCancelClick={() => {
          setDialogErr(false);
        }}
        onModalConfirmClick={handleTemplateChange}
      />
    );
    return dialog;
  };

  return (
    <div
      className={
        editMode
          ? `six wide column items_details ${styles.edit_projects_zindex1}`
          : `six wide column items_details ${styles.edit_projects_zindex2}`
      }
      id="editProject"
    >
      <div id="template_panel">
        <div className={styles.project_edit_header}>{panelLabel}</div>

        <div className={styles.company_details_container} ref={scrollContainer}>
          <div className="ui middle aligned list">
            <div className="item">
              <div className="content">
                <Form>
                  <FormField required>
                    <label>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME_ONLY")}</label>
                    <div className="ui fluid input">
                      <Input
                        autoComplete={constants.autoComplete.off}
                        name="projectName"
                        type="text"
                        value={project.projectName}
                        placeholder={Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME_PLACEHOLDER")}
                        onChange={onItemChange}
                        onBlur={handleOnBlur}
                        style={
                          validator.parameter.projectName || projectNameErr
                            ? {
                                ...errorBorderNoRadius
                              }
                            : null
                        }
                      />
                    </div>
                    {validator.parameter.projectName && (
                      <Label basic color="red" pointing content={validator.parameter.projectName} />
                    )}
                    {projectNameErr && (
                      <Label basic color="red" pointing>
                        {Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME_ERROR")}
                      </Label>
                    )}
                  </FormField>
                </Form>
              </div>
            </div>

            <div className="item">
              <div className="content">
                <strong>{Localize.get("PROJECTS.MANAGEMENT.STATUS")}</strong>
                <div className={`ui fluid input ${styles.item_content_top_bottom}`}>
                  <Dropdown
                    placeholder={Localize.get("PROJECTS.MANAGEMENT.STATUS")}
                    fluid
                    selection
                    options={statusOptions}
                    onChange={handleStatusChange}
                    value={
                      panelState.activeMode !== constants.panelMode.edit
                        ? statusOptions[0].value
                        : project.projectStatus.id
                    }
                    disabled={panelState.activeMode !== constants.panelMode.edit}
                  />
                </div>
              </div>
            </div>
            <div className="item">
              <div className="content">
                <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}</strong>
                <Form className={styles.item_content_top_bottom}>
                  <TextArea
                    name="projectDescription"
                    rows={5}
                    maxLength={255}
                    value={project.projectDescription ? project.projectDescription : ""}
                    placeholder={Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}
                    style={
                      validator.parameter.projectDescription
                        ? {
                            ...errorBorderNoRadius
                          }
                        : null
                    }
                    onChange={handleFieldChange}
                    onBlur={handleOnBlur}
                  />
                  <p className="right floated content">
                    {charCounter.charsLeft}
                    {"/255"}
                  </p>
                </Form>
                {validator.parameter.projectDescription && (
                  <Label
                    basic
                    color="red"
                    pointing
                    content={validator.parameter.projectDescription}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Project Template*/}

          <Segment color="teal" clearing inverted>
            <div className={styles.segment_titles}>
              <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE")}</strong>
            </div>
          </Segment>

          <div />
          <div className={styles.project_template_margin}>
            <Form.Field>
              {projectTemplateOptions && (
                <Dropdown
                  placeholder={Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE")}
                  name="projectTemplateValidate"
                  fluid
                  className={styles.project_template}
                  selection
                  disabled={project.projectStatus === constants.projectStatus.process}
                  options={projectTemplateOptions}
                  onChange={handleSelectTemplate}
                  value={project.projectTemplate.id}
                  style={
                    validator.parameter.projectTemplateValidate
                      ? {
                          ...errorBorderNoRadius
                        }
                      : null
                  }
                />
              )}
              {validator.parameter.projectTemplateValidate && (
                <Label
                  basic
                  color="red"
                  pointing
                  content={validator.parameter.projectTemplateValidate}
                />
              )}
            </Form.Field>
          </div>

          <Form size="tiny">
            {project.projectTemplate.roles.length > 0 && (
              <Segment color="teal" clearing inverted>
                <div className={styles.segment_titles}>
                  <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_RESOURCES")}</strong>

                  {partnerCounter ? (
                    <div className={styles.partner_counter}>
                      {Localize.get("PROJECTS.MANAGEMENT.SELECTED_PARTNER")}
                      <Label circular color="green" className={styles.partner_counter_label}>
                        {partnerCounter ? partnerCounter[0].partnersLeft : 0}
                      </Label>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Segment>
            )}
            <ProjectResources
              roles={project.projectTemplate.roles}
              activeMode={panelState.activeMode}
              setPartners={setPartners}
              partners={partners}
              ref={refProjectResources}
              project={project}
              handlePartnerSelection={handlePartnerSelection}
              handlePartnerDelete={handlePartnerDelete}
              partnerCounter={partnerCounter}
              setPartnerCounter={setPartnerCounter}
              setEditedFields={setEditedFields}
              editedFields={editedFields}
              newDetailsFields={newDetailsFields}
            />
          </Form>
        </div>

        <ProjectSaveButtonsGroup
          onSaveButtonClick={handlerButton.saveButtonFormHandler}
          onSaveAndNewButtonClick={handlerButton.saveNewButtonHandler}
          activeMode={panelState.activeMode}
          onCancelButtonGroup={handlerButton.closeNewButtonFormHandler}
          setValidator={setValidator}
          requiredFields={constant.requiredFieldsProjectManagement}
          validator={validator}
          refProjectResources={refProjectResources}
          refProjectPanelContacts={refProjectPanelContacts}
          refProjectPanelAddress={refProjectPanelAddress}
          refProjectPanelDeliveryAddress={refProjectPanelDeliveryAddress}
          objectThatIWantToSave={project}
          editedFields={editedFields}
          setError={setProjectNameErr}
          rolesPersons={partners}
          myParentElement={elements.projectElements.management}
        />
      </div>
      <div className={styles.dialog_error} />
      {dialogErr && panelState.activeMode !== constants.panelMode.create ? (
        getDialogError()
      ) : (
        <div />
      )}
    </div>
  );
};

export default ProjectManagementPanel;
