import React from "react";
import Localize from "react-intl-universal";
import styles from "./style/FooterComponent.module.css";
import { format } from "date-fns";

const FooterComponent = props => {
  const currentYear = format(new Date(), "yyyy");
  return (
    <>
      <div className={`ui divider ${styles.wpfooter_margin}`}></div>
      <footer className={`${styles.wpfooter} ${styles.wpfooter_margin}`}>
        <div className="">
          {` © ${currentYear} · ${Localize.get("FOOTER.DBR")} · `}
          <a href=" "> {Localize.get("FOOTER.PRIVACY")}</a> ·
          <a href=" "> {Localize.get("FOOTER.TERMS")}</a>
        </div>
        <div className=" ">
          <button className="ui primary icon right labeled button" onClick={props.backToTop}>
            <i aria-hidden="true" className="angle double up icon"></i>
            {Localize.get("FOOTER.BTN_BACK")}
          </button>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
