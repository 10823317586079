import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = props => {
  const m = {
    title: {
      display: false,
      text: props.title,
      fontSize: 20
    },
    legend: {
      display: true,
      position: "top"
    },
    maintainAspectRatio: false
  };
  return (
    <Doughnut
      data={props.data}
      // width={100}
      height={170}
      options={m}
    />
  );
};

export default DoughnutChart;
