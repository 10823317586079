export const autoComplete = {
  none: "none",
  off: "off"
};

export const availableLanguages = [
  {
    value: 2,
    key: "de",
    text: "Deutsch"
  },
  {
    value: 1,
    key: "en",
    text: "English"
  }
];
