import { useState } from "react";
import useIsMounted from "./useIsMounted";

function usePostOrPatch(postOrPatchFn, customCBSuccess, customCBError) {
  const [loading, setLoading] = useState(false);
  const [isMounted] = useIsMounted();

  async function makeCall(savedObject, url, saveAndNew, postOrPatchMethod, responseType, params) {
    try {
      setLoading(true);
      const response = await postOrPatchFn(
        savedObject,
        url,
        postOrPatchMethod,
        responseType,
        params
      );
      if (isMounted && response && customCBSuccess) {
        customCBSuccess(response, saveAndNew, postOrPatchMethod);
      }
    } catch (err) {
      if (isMounted && customCBError) {
        customCBError(err, postOrPatchMethod);
      }
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  }

  return [makeCall, loading];
}

export default usePostOrPatch;
