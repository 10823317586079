import React from "react";
import styles from "./style/DocumentDisplay.module.css";

const DocumentDisplay = props => {
  return (
    <>
      {props.state.panelMode !== "edit" ? (
        <div
          className={
            props.addNewPartnerDisplayActive === true
              ? styles.addingPartnerNewDocument
              : `ui vertical menu ${styles.new_document_display}`
          }
        >
          <div
            className={
              props.addNewPartnerDisplayActive === true
                ? props.isPDFPreview !== true
                  ? "newDocNonPdf"
                  : "documentPreviewContainer"
                : "documentPreviewContainer"
            }
          >
            {!props.isPDFPreview && (
              <div>
                <img src={props.documentPreview} alt="" />
              </div>
            )}
            {props.isPDFPreview && (
              <object
                className={styles.documentPreview}
                data={props.documentPreview}
                type="application/pdf"
              >
                alt : ''
              </object>
            )}
          </div>
        </div>
      ) : (
        props.linkedDocumentsPreview && (
          <div
            className={
              props.addNewPartnerDisplayActive === true
                ? styles.addingPartnerEditDocument
                : styles.documentEditPreviewContainer
            }
          >
            {!props.linkedDocumentsPreview.isPDF && (
              <div>
                <img
                  className={styles.documentEditPreviewDetails}
                  src={props.linkedDocumentsPreview.data}
                  alt=""
                />
              </div>
            )}
            {props.linkedDocumentsPreview.isPDF && (
              <object
                className={styles.documentPreview}
                data={props.linkedDocumentsPreview.data}
                type="application/pdf"
              >
                alt : ''
              </object>
            )}
          </div>
        )
      )}
    </>
  );
};

export default DocumentDisplay;
