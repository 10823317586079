import React, { useState, useEffect } from "react";
import ListWithPagination from "../../Components/ListWithPagination";
import ReferedInvoiceItem from "./ReferedInvoiceItem";
import * as componentsConstants from "../../Components/Constants";
import * as RestService from "../../RestService";
import Localize from "react-intl-universal";
import * as constants from "../Constants";
import styles from "./style/ReferedInvoiceList.module.css";
import { Button, Message, Icon, Input } from "semantic-ui-react";
import { convert2Documents } from ".././Util.js";

const ReferedInvoiceList = props => {
  const {
    handleOnChange,
    setRelatedDocumentState,
    relatedDocument,
    setShowInvoicesModal,
    partner,
    direction
  } = props;
  const [listOfItems, setListOfItems] = useState([]);
  const [listOfRawItems, setListOfRawItems] = useState([]);
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [paginationStatus, setPaginationStatus] = useState(
    componentsConstants.paginationInitStatus
  );
  const [searchedNumberValue, setSearcheNumberValue] = useState(constants.initialState.number);
  //this is fake state in order to fulfil pagination list component requirements
  const [state] = useState({
    activeMiddlePanelMode: "all",
    activeListItem: ""
  });
  const [checkedDocs, setCheckedDocs] = useState(relatedDocument);
  const [confirmEnabled, setConfirmEnabled] = useState(true);

  const callbackGetAllSuccess = response => {
    const documents = convert2Documents(response.data.data);
    let documentList = documents.map(documentItem => {
      documentItem.checked = documentItem.id === checkedDocs;
      return (
        <ReferedInvoiceItem
          key={documentItem.id}
          document={documentItem}
          checkedDocs={[]}
          onToggleItem={onToggleItemHandler}
        />
      );
    });

    setListOfRawItems(documents);
    setListOfItems(documentList);
    setPaginationStatus({
      perPage: response.data.paging.perPage / 2,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });
  };
  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {};

  const createService = filterResolveObject => {
    const serviceGetAll = new RestService.Document.GetAll.Service();
    serviceGetAll.setCallbackSuccess(callbackGetAllSuccess);
    serviceGetAll.setCallbackError(callbackGetAllError);
    serviceGetAll.setCallbackFinally(callbackGetAllFinally);
    serviceGetAll.query.setPageSize(10);
    serviceGetAll.query.addDocumentVerificationStatusVerified();
    serviceGetAll.query.addCustomColumnSearch("partnerId", partner.id);
    serviceGetAll.query.addCustomColumnSearch("direction", direction);
    return serviceGetAll;
  };

  const service = createService();
  service.query.addDocumentTypeInvoice(constants.documentType.invoice);

  const handlePageChange = async pageInfo => {
    setCheckedDocs(relatedDocument);
    service.query.setPage(pageInfo.activePage - 1);
    setActivePaginationPage(pageInfo.activePage);
    await service.call();
  };

  const onToggleItemHandler = (e, data) => {
    if (data.item_id !== checkedDocs) {
      setCheckedDocs(data.item_id);
      setConfirmEnabled(false);
    } else {
      setCheckedDocs(constants.initialState.number);
      setConfirmEnabled(true);
    }
  };

  const onSaveClick = (e, data) => {
    if (checkedDocs) {
      let selectedDocument = listOfRawItems.find(
        document => Number(document.id) === Number(checkedDocs)
      );
      setRelatedDocumentState(selectedDocument.documentNumber);
    } else {
      setRelatedDocumentState(constants.initialState.activeInputNumberSearch);
    }

    handleOnChange(e, { name: "parentDocument", value: checkedDocs });
    setShowInvoicesModal(false);
  };

  const searchByDocNumber = async (e, data) => {
    service.query.addCustomColumnSearch("number", e.target.value);
    service.query.setPage(0);
    await service.call();
    setSearcheNumberValue(data.value);
  };

  useEffect(() => {
    let documentList = listOfRawItems.map(d => {
      d.checked = d.id === checkedDocs;
      return (
        <ReferedInvoiceItem
          key={d.id}
          document={d}
          state={state}
          checkedDocs={[]}
          onToggleItem={onToggleItemHandler}
        />
      );
    });

    setListOfItems(documentList);
  }, [checkedDocs]);

  useEffect(() => {
    service.query.setPage(0);
    service.call();
  }, []);

  return (
    <>
      <Input
        type="text"
        icon="search"
        onChange={searchByDocNumber}
        value={searchedNumberValue}
        placeholder={Localize.get("DOCUMENTS.ITEMLIST.HEADER.DOCUMENT_NUMBER")}
      />

      {listOfItems.length > 0 ? (
        <div className={styles.list_of_items}>
          <ListWithPagination
            list={listOfItems}
            state={state}
            defaultActivePage={paginationStatus.pageCount}
            totalPage={paginationStatus.totalCount}
            handlePageChange={handlePageChange}
            activePaginationPage={activePaginationPage}
            perPage={paginationStatus.perPage}
          />
        </div>
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY")}
        </Message>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={() => setShowInvoicesModal(false)}
          className={styles.button}
          floated="right"
          secondary
        >
          {Localize.get("GLOBAL.FORM.CANCEL")}
        </Button>
        <Button
          onClick={onSaveClick}
          // disabled={listOfItems.length === 0}
          disabled={confirmEnabled}
          className={styles.button}
          floated="right"
          primary
        >
          {Localize.get("GLOBAL.FORM.SAVE")}
        </Button>
      </div>
    </>
  );
};

export default ReferedInvoiceList;
