import * as constant from "./Constants.js";
import Localize from "react-intl-universal";
import { startOfWeek, startOfMonth, endOfMonth, addDays, subMonths } from "date-fns";

// eslint-disable-next-line no-unused-vars
function randomDate(start, end) {
  const date = new Date(+start + Math.random() * (end - start));
  return date;
}

export const formatProjectsEditDoc = projects => {
  const arr = projects;
  const result = arr.map(element => {
    const [obj, ...rest] = element;
    return rest.reduce(
      (total, currentValue, index) => ({
        ...total,
        [projects[index]]: currentValue
      }),
      obj
    );
  });

  const projectsArr = result.map(pro => ({
    text: pro.project.projectName,
    projectname: pro.project.projectName,
    amount: pro.amount,
    description: pro.description,
    projectdesignation: pro.project.projectDesignation,
    value: pro.project.id,
    key: pro.project.id,
    id: pro.project.id
  }));

  return projectsArr;
};

export const formatDate = date => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateDisplay = date => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const translateStatus = data => {
  let comment;
  switch (data) {
    case "VERIFIED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_APPROVED");
      break;
    case "DISAPPROVED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_DECLINED");
      break;
    case "UNVERIFIED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_UNRESOLVED");
      break;
    case "PAID":
      comment = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PAID");
      break;
    case "PARTIALLY_PAID":
      comment = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PARTIAL_PAID");
      break;
    case "NOT_PAID":
      comment = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTPAID");
      break;
    case "APPROVED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.APPROVAL_STATUS_APPROVED");
      break;
    case "UNRESOLVED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.APPROVAL_STATUS_UNRESOLVED");
      break;
    case "DECLINED":
      comment = Localize.get("DOCUMENTS.SIDEBAR.APPROVAL_STATUS_DECLINED");
      break;
    case constant.actionType.addManualPayment:
      comment = Localize.get("DOCUMENTS.DETAILS.COMMENTS_PAYMENT_ADD");
      break;
    default:
      comment = Localize.get("DOCUMENTS.DETAILS.COMMENTS_PAYMENT_ERROR_MESSAGE");
      break;
  }
  return comment;
};

export const translateField = field => {
  switch (field) {
    case "verification status":
      return Localize.get("DOCUMENTS.SIDEBAR.HEADER_VERIFIATION_STATUS");
    case "approval status":
      return Localize.get("DOCUMENTS.SIDEBAR.HEADER_APPROVAL_STATUS");
    case "payment status":
      return Localize.get("DOCUMENTS.SIDEBAR.HEADER_PAID_STATUS");
    default:
      return Localize.get("DOCUMENTS.DETAILS.COMMENTS_PAYMENT_ERROR_MESSAGE");
  }
};

export const formatDateToString = date => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(".");
};

// today
let today = formatDate(new Date());
// this week
let startOfWeekThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
let endOfWeekThisWeek = addDays(startOfWeekThisWeek, 4);
// next week
let startOfWeekWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
let startOfWeekNextWeek = addDays(startOfWeekWeek, 7);
let endOfWeekNextWeek = addDays(startOfWeekWeek, 11);
// this month
let startOfThisMonth = startOfMonth(new Date());
let endOfThisMonth = endOfMonth(new Date());
//overdue date
let startOfOverdue = formatDate(new Date("1970-01-01"));
let endDate = new Date();
let endOfOverdue = formatDate(endDate.setDate(endDate.getDate() - 1));

export const dateRange = {
  overdue: [formatDate(startOfOverdue), formatDate(endOfOverdue)].join(":"),
  today: [today, today].join(":"),
  thisWeek: [formatDate(startOfWeekThisWeek), formatDate(endOfWeekThisWeek)].join(":"),
  nextWeek: [formatDate(startOfWeekNextWeek), formatDate(endOfWeekNextWeek)].join(":"),
  thisMonth: [formatDate(startOfThisMonth), formatDate(endOfThisMonth)].join(":")
};

//datePicker locales

export const WEEKDAYS_SHORT = {
  de: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
};

export const WEEKDAYS_LONG = {
  de: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};

export const MONTHS = {
  de: [
    " Jan.",
    "Feb.",
    "Mrz.",
    "Apr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Okt.",
    "Nov.",
    "Dez."
  ]
};

export const LABELS = {
  de: { nextMonth: "nächsten Monat", previousMonth: "vorheriger Monat" }
};

export const FIRST_DAY_OF_WEEK = {
  de: 1
};

// ---- datePicker Locales END
export const formatAmount = amount => {
  if (amount && typeof amount !== "number" && amount.includes(",")) {
    amount = Number(parseFloat(amount.split(",").join(".")));
  } else if (amount === null || amount === undefined) {
    amount = 0;
  }
  return amount;
};

export const formatAmountOrderItems = amount => {
  if (amount && typeof amount !== "number" && amount.includes(",")) {
    amount = Number(parseFloat(amount.split(",").join(".")));
  } else if (amount === null) {
    amount = 0;
  } else {
    amount = parseFloat(amount);
  }
  return amount;
};

export const repackItems = orderItems => {
  let itemsArr = orderItems;

  const repackOrderItems = itemsArr.map(item => ({
    id: item.id,
    itemLineId: item.itemLineId,
    sellerItemId: item.sellerItemId,
    netPrice: formatAmount(item.netPrice),
    quantity: formatAmount(item.quantity),
    amount: formatAmount(item.amount),
    surchargeDiscount: formatAmount(item.surchargeDiscount),
    vat: item.vat === null || item.vat === "" ? null : Number(item.vat),
    name: item.name,
    unit: item.unit
  }));
  return repackOrderItems;
};

export const repackSubtotals = subtotals => {
  let itemsArr = subtotals;

  const repackSub = itemsArr.map(subtotal => ({
    id: subtotal.id,
    tax: subtotal.tax,
    surcharge: formatAmount(subtotal.surcharge),
    discount: formatAmount(subtotal.discount),
    netAmount: formatAmount(subtotal.netAmount)
  }));
  return repackSub;
};

// Repacking tax subtotals Read from OCR
export const repackSubtotalsOCRRead = taxSubtotals => {
  let subtotalsArr = taxSubtotals;

  const repackTaxSubtotals = subtotalsArr.map(subtotal => ({
    tax: Number(subtotal.tax),
    netAmount: formatAmount(subtotal.netAmount),
    calculatedTax: formatAmount(subtotal.calculatedTax),
    grossAmount: formatAmount(subtotal.grossAmount)
  }));
  return repackTaxSubtotals;
};

// Repacking document discount Read from OCR
export const repackDocumentDiscountOCRRead = documentDiscount => {
  let discountsArr = documentDiscount;

  const repackTaxDiscounts = discountsArr.map(discount => ({
    earlyPayAmount: formatAmount(discount.earlyPayAmount),
    earlyPayDate: discount.earlyPayDate,
    description: discount.description
  }));
  return repackTaxDiscounts;
};

// Repacking order Items Read from OCR
export const repackItemsOCRRead = orderItems => {
  let itemsArr = orderItems;

  const repackOrderItems = itemsArr.map(item => ({
    id: item.id,
    itemLineId: item.itemLineId,
    sellerItemId: item.sellerItemId,
    netPrice: formatAmount(item.netPrice),
    quantity: formatAmount(item.quantity),
    amount: formatAmount(item.amount),
    surchargeDiscount: formatAmount(item.surchargeDiscount),
    vat: item.vat,
    name: item.name,
    unit: mapOrderItemUnit(item.unit)
  }));
  return repackOrderItems;
};

// Maping and matching order Items unit field
export const mapOrderItemUnit = unit => {
  let orderItem = {
    commonCodeUN: "",
    unit: unit
  };
  switch (unit) {
    case "Stk":
      orderItem.commonCodeUN = "C62";
      break;
    case "km":
      orderItem.commonCodeUN = "KMT";
      break;
    case "m3":
      orderItem.commonCodeUN = "MTQ";
      break;
    case "m2":
      orderItem.commonCodeUN = "MTK";
      break;
    case "m":
      orderItem.commonCodeUN = "MTR";
      break;
    case "cm":
      orderItem.commonCodeUN = "CMT";
      break;
    case "mm":
      orderItem.commonCodeUN = "MMT";
      break;
    case "inch":
      orderItem.commonCodeUN = "INH";
      break;
    case "t":
      orderItem.commonCodeUN = "TNE";
      break;
    case "kg":
      orderItem.commonCodeUN = "KGM";
      break;
    case "g":
      orderItem.commonCodeUN = "GRM";
      break;
    case "mg":
      orderItem.commonCodeUN = "MGM";
      break;
    case "h":
      orderItem.commonCodeUN = "HUR";
      break;
    case "l":
      orderItem.commonCodeUN = "LTR";
      break;
    case "cl":
      orderItem.commonCodeUN = "CLT";
      break;
    case "hl":
      orderItem.commonCodeUN = "HLT";
      break;
    case "kW":
      orderItem.commonCodeUN = "KWT";
      break;
    case "kWh":
      orderItem.commonCodeUN = "KWH";
      break;
    case "piece":
      orderItem.commonCodeUN = "H87";
      break;
    case "set":
      orderItem.commonCodeUN = "SET";
      break;
    case "portion":
      orderItem.commonCodeUN = "PTN";
      break;
    case "day":
      orderItem.commonCodeUN = "DAY";
      break;
    case "-":
      orderItem.commonCodeUN = "LS";
      break;
    default:
      orderItem = null;
      break;
  }
  return orderItem;
};

export const repackProjects = projects => {
  const projectsArr = projects.map(project => ({
    amount: parseFloat(formatAmount(project.amount)),
    description: project.description,
    id: { project: { id: project.id } }
  }));

  return projectsArr;
};

export const convert2Documents = data => {
  const documents = [];

  for (let i = 0; i < data.length; i++) {
    const document = {};
    document.amount = data[i].amount;
    document.binaryContent = data[i].binaryContent;
    document.currency = data[i].currency;
    document.deliveryAddress = data[i].deliveryAddress;
    document.deliveryNoteReference = data[i].deliveryNoteReference;
    document.direction = data[i].direction;
    document.isGross = data[i].isGross;
    document.documentDate = data[i].documentDate !== null ? formatDate(data[i].documentDate) : "";

    document.documentNumber = data[i].documentNumber ? data[i].documentNumber.trim() : null;
    document.documentType = data[i].documentType;
    document.id = data[i].id;

    document.orderItems = data[i].orderItems;

    document.partner = data[i].partner;
    document.paymentDue = data[i].paymentDue ? formatDate(data[i].paymentDue) : "";
    document.deliveryDate = data[i].deliveryDate ? formatDate(data[i].deliveryDate) : "";
    document.approvalStatus = data[i].approvalStatus;
    document.approvalStatus === "due"
      ? (document.documentDue = true)
      : (document.documentDue = false);
    document.projects =
      data[i].projects !== undefined
        ? data[i].projects.map(pro => [
            {
              project: pro.id.project,
              amount: pro.amount,
              description: pro.description
            }
          ])
        : [];
    document.sentToDatev = data[i].sentToDatev;
    document.verificationStatus = data[i].verificationStatus;
    document.zugferdBased = data[i].zugferdBased;
    document.description = data[i].description;

    document.createdAt = formatDate(data[i].createdAt);
    document.changedAt = null;
    document.checked = false;

    document.archived = data[i].archived;
    document.actions = data[i].actions;

    document.documentDiscount = data[i].documentDiscount;

    document.divededAmounts = [];
    document.documents = data[i].documents;
    document.parentDocument = data[i].parentDocument;
    document.payments = data[i].payments;
    document.paymentStatus = data[i].paymentStatus;
    document.progressStatus = data[i].progressStatus;
    document.overpayment = data[i].overpayment;
    document.taxSubtotals = data[i].taxSubtotals;

    document.totalNetAmount = data[i].totalNetAmount;
    document.totalDiscount = data[i].totalDiscount;
    document.totalSurcharge = data[i].totalSurcharge;
    document.totalBaseAmount = data[i].totalBaseAmount;
    document.totalTax = data[i].totalTax;
    document.totalGrossAmount = data[i].totalGrossAmount;
    document.totalDiscount = data[i].totalDiscount;

    document.documentDiscount = data[i].documentDiscount ? data[i].documentDiscount : {};

    documents.push(document);
  }
  return documents;
  //mockUP data
  // let d = new Date();
  // let mockUpData = documents.map((doc, index) =>
  //   doc.direction === "outgoing"
  //     ? {
  //         ...doc,
  //         progressStatus: constant.progressStatus.pdfCreated,
  //         // paymentStatus: "",
  //         binaryContent: { ...doc.binaryContent, id: 8 },
  //         importedDate: formatDate(d),
  //         output_mail: "mijoandjic@gmail.com",
  //         output_address: "Mülhausener Str. 70 Groß Chantalbrunn",
  //         sentOn: formatDate(d),
  //         dueDate: formatDate(d.setDate(d.getDate() + 15)),
  //         personWhoSent: "ADMIN_01",
  //         paidDate: formatDate(d.setDate(d.getDate() - 2)),
  //         orderItems: doc.orderItems.map((item, i) => ({
  //           ...item,
  //           orderId: i + 1000,
  //           chargeName: "Frutful item",
  //           quantity: 2 + i,
  //           currency: "eur",
  //           grossAmount: 324.22 * (i + 1),
  //           deliveryDate: "12-08-2021",
  //           deliveredTo: "Siemens"
  //         }))
  //       }
  //     : doc
  // );

  // return mockUpData;
};

export const convertDocumentComments = data => {
  const documentsComments = data.map(com => [
    {
      appUser: com.appUser,
      comment: com.comment,
      documentField: com.documentField,
      id: com.id,
      newValue: com.newValue,
      oldValue: com.oldValue,
      changeDate: com.changeDate
    }
  ]);
  return documentsComments;
};

export const createNewDeliveryDocument = (data, binaryContent, linkedDocuments) => {
  if (data.orderItems && data.orderItems.length > 0) {
    data.orderItems.forEach(function(element) {
      delete element.document;
      delete element.id;
    });
  }
  const lnkDocObj = getLinkedDocumentObject(linkedDocuments);
  document.documents = lnkDocObj;
  return {
    documentDate: data.documentDate ? formatDate(data.documentDate) : null,
    verificationStatus: constant.verificationStatus.unverified,
    directionType: data.directionType,
    parentDocument: data.parentDocument,
    archived: false,
    binaryContent: binaryContent,
    changedAt: null,
    checked: false,
    direction: data.direction,
    description: data.description,
    documentNumber: data.documentNumber.trim(),
    documentType: data.documentType,
    documents: document.documents ? document.documents : [],
    deliveryDate: data.deliveryDate ? formatDate(data.deliveryDate) : null,
    approvalStatus:
      data.documentType === constant.documentType.invoice
        ? constant.approvalStatus.unresolved
        : constant.approvalStatus.notRelevant,
    orderItems: data.orderItems ? repackItems(data.orderItems) : null,
    projects: data.projects ? repackProjects(data.projects) : null,
    partner:
      data.partner && data.partner.id
        ? {
            id: data.partner.id,
            phone: data.partner.phone
          }
        : null
  };
};

// POST method for creating new document PDF/JPG
export const createNewDocument = (data, binaryContent, linkedDocuments) => {
  if (data.orderItems && data.orderItems.length > 0) {
    data.orderItems.forEach(function(element) {
      delete element.document;
      delete element.id;
    });
  }
  const lnkDocObj = getLinkedDocumentObject(linkedDocuments);
  document.documents = lnkDocObj;
  let returnObj = {
    taxSubtotals: data.taxSubtotals ? data.taxSubtotals : [],

    totalNetAmount: data.totalNetAmount,
    totalDiscount: data.totalDiscount,
    totalSurcharge: data.totalSurcharge,
    totalBaseAmount: data.totalBaseAmount,
    totalTax: data.totalTax,
    totalGrossAmount: data.totalGrossAmount,

    documentDiscount: {
      earlyPayAmount: data.documentDiscount.earlyPayAmount,
      savings: data.documentDiscount.savings,
      earlyPayDate: data.documentDiscount.earlyPayDate
        ? formatDate(data.documentDiscount.earlyPayDate)
        : null
    },
    documentDate: data.documentDate ? formatDate(data.documentDate) : null,
    verificationStatus: constant.verificationStatus.unverified,
    directionType: data.directionType,
    parentDocument: data.parentDocument,
    archived: false,
    binaryContent: binaryContent,
    changedAt: null,
    checked: false,
    direction: data.direction,
    description: data.description,
    documentDue: false,
    documentNumber: data.documentNumber.trim(),
    documentType: data.documentType,
    documents: document.documents ? document.documents : [],
    paymentDue: data.paymentDue ? formatDate(data.paymentDue) : null,
    deliveryDate: data.deliveryDate ? formatDate(data.deliveryDate) : null,
    approvalStatus:
      data.documentType === constant.documentType.invoice
        ? constant.approvalStatus.unresolved
        : constant.approvalStatus.notRelevant,
    orderItems: data.orderItems ? repackItems(data.orderItems) : null,
    projects: data.projects ? repackProjects(data.projects) : null,
    paymentStatus: data.paymentStatus,
    amount: formatAmount(data.amount),
    overpayment: data.overpayment,
    isGross: data.isGross,

    currency: {
      id: data.currency.id
    },
    partner:
      data.partner && data.partner.id
        ? {
            id: data.partner.id,
            phone: data.partner.phone
          }
        : null
  };
  if (data.documentDiscount.earlyPayAmount === null) {
    delete returnObj.documentDiscount;
  }

  return returnObj;
};

// PATCH method for editing PDF or creating Zugfred document
export const getEditedDocumentObject = (document, linkedDocuments) => {
  const ms = new Date().getTime() + 14 * 86400000;
  const due = new Date(ms);

  document.documents = getLinkedDocumentObject(linkedDocuments);
  if (!document.editedFields) {
    //zugfert just save without any changes
    const fields = Reflect.ownKeys(document);
    if (fields.includes("id")) {
      fields.splice(fields.indexOf("id"), 1);
    }
    if (fields.includes("orderItems")) {
      fields.splice(fields.indexOf("orderItems"), 1);
    }
    if (fields.includes("projects")) {
      fields.splice(fields.indexOf("projects"), 1);
    }
    if (fields.includes("partner")) {
      fields.splice(fields.indexOf("partner"), 1);
    }
    if (fields.includes("deliveryAddress") && document.deliveryAddress) {
      delete document.deliveryAddress.id;
    }
    if (fields.includes("binaryContent")) {
      fields.splice(fields.indexOf("binaryContent"), 1);
    }
    if (fields.includes("sentToDatev")) {
      fields.splice(fields.indexOf("sentToDatev"), 1);
    }
    if (fields.includes("documentDue")) {
      fields.splice(fields.indexOf("documentDue"), 1);
    }
    if (fields.includes("deliveryDate")) {
      fields.splice(fields.indexOf("deliveryDate"), 1);
    }
    if (fields.includes("createdAt")) {
      fields.splice(fields.indexOf("createdAt"), 1);
    }
    if (fields.includes("changedAt")) {
      fields.splice(fields.indexOf("changedAt"), 1);
    }
    if (fields.includes("checked")) {
      fields.splice(fields.indexOf("checked"), 1);
    }

    return fields.map(function(field) {
      let fieldNameForDB = field;
      let val = document[field];

      switch (field) {
        case "documentDate":
        case "paymentDue":
        case "deliveryDate":
          document[field] !== null ? (val = formatDate(document[field])) : (val = formatDate(due));
          break;
        case "currency":
          val = {
            id: document.currency ? document.currency.id : "EUR"
          };
          break;
        case "partner":
          val = {
            id: document.partner.id,
            phone: document.partner.phone
          };
          break;
        case "taxSubtotals":
          val = [
            {
              discountCharge: document.taxSubtotals[0].discountCharge,
              tax: 19,
              subTotal: document.taxSubtotals[0].subTotal
            },
            {
              discountCharge: document.taxSubtotals[1].discountCharge,
              tax: 7,
              subTotal: document.taxSubtotals[1].subTotal
            },
            {
              discountCharge: document.taxSubtotals[2].discountCharge,
              tax: 0,
              subTotal: document.taxSubtotals[2].subTotal
            },
            {
              discountCharge: document.taxSubtotals[3].discountCharge,
              tax: 5,
              subTotal: document.taxSubtotals[3].subTotal
            },
            {
              discountCharge: document.taxSubtotals[4].discountCharge,
              tax: 16,
              subTotal: document.taxSubtotals[4].subTotal
            }
          ];
          break;
        default:
          break;
      }
      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  } else {
    if (
      document.editedFields.includes("documentType") &&
      document.paymentDue &&
      !document.editedFields.includes("paymentDue")
    ) {
      if (document.documentType === constant.documentType.delivery) {
        document.editedFields.push(
          "paymentDue",
          "overpayment",
          "taxSubtotals",
          "totalBaseAmount",
          "totalDiscount",
          "totalGrossAmount",
          "totalNetAmount",
          "totalSurcharge",
          "totalTax"
        );
        document.paymentDue = null;
        document.overpayment = null;
        document.taxSubtotals = [];
        document.totalBaseAmount = null;
        document.totalDiscount = null;
        document.totalGrossAmount = null;
        document.totalNetAmount = null;
        document.totalSurcharge = null;
        document.totalTax = null;
      }
    }

    return document.editedFields.map(function(field) {
      let fieldNameForDB = field;
      let val = document[field];
      switch (field) {
        case "documentDate":
        case "paymentDue":
        case "deliveryDate":
          document[field] !== null && document[field] !== ""
            ? (val = formatDate(document[field]))
            : (val = null);
          break;
        case "partner":
          document[field]
            ? (val = {
                id: document.partner.id,
                phone: document.partner.phone
              })
            : (val = null);
          break;
        case "amount":
          val = formatAmount(document.amount);
          break;
        case "overpayment":
          val = document.overpayment;
          break;
        case "orderItems":
          val = repackItems(document.orderItems);
          break;
        case "projects":
          val = repackProjects(document.projects);
          break;
        case "taxSubtotals":
          val = document && document.taxSubtotals ? repackSubtotals(document.taxSubtotals) : [];

          break;
        case "documentDiscount":
          val = document.documentDiscount
            ? {
                earlyPayAmount: parseFloat(document.documentDiscount.earlyPayAmount),
                earlyPayDate:
                  document && document.documentDiscount && document.documentDiscount.earlyPayDate
                    ? formatDate(document.documentDiscount.earlyPayDate)
                    : null,
                savings: parseFloat(document.documentDiscount.savings),
                id: document.documentDiscount.id,
                description: document.documentDiscount.description
              }
            : {
                earlyPayDate: null
              };
          break;
        case "documentNumber":
          val = document.documentNumber.trim();
          break;
        case "currency":
          fieldNameForDB = "currency/id";
          val = val.id;
          break;
        default:
          break;
      }
      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  }
};

export const getEmptyDocumentObject = () => {
  // eslint-disable-next-line no-unused-vars
  const currentLanguage = navigator.language;

  // document
  return {
    binaryContent: null,
    id: "",
    documentNumber: "",
    documentType: "",
    partner: null,
    verificationStatus: constant.verificationStatus.unverified,

    createdAt: new Date(),
    changedAt: null,
    checked: false,
    archived: false,
    isGross: false,

    amount: "",

    directionType: "",
    direction: constant.documentDirection.incoming,

    approvalStatus: constant.approvalStatus.notRelevant,

    documentDue: false,

    paymentDue: new Date(),
    documentDate: new Date(),
    documents: [],
    projects: []
  };
};

export const getOCRDocumentReadObject = documentOCR => {
  // document
  return {
    id: documentOCR.id,
    documentNumber: documentOCR.documentNumber.trim(),
    documentType: documentOCR.documentType,
    direction: constant.documentDirection.incoming,
    partner: documentOCR.partner,
    documentDate: documentOCR.documentDate ? documentOCR.documentDate : "",
    deliveryDate: documentOCR.deliveryDate ? documentOCR.deliveryDate : "",
    paymentDue: documentOCR.dueDate ? documentOCR.dueDate : "",
    isGross: false,

    binaryContent: null,
    verificationStatus: constant.verificationStatus.unresolved,
    approvalStatus:
      documentOCR.documentType === constant.documentType.invoice
        ? constant.approvalStatus.unresolved
        : constant.approvalStatus.notRelevant,

    archived: false,

    orderItems: repackItemsOCRRead(documentOCR.orderItems),
    taxSubtotals: repackSubtotalsOCRRead(documentOCR.taxSubtotals),
    documentDiscount: repackDocumentDiscountOCRRead(documentOCR.documentDiscount),
    totalNetAmount: formatAmount(documentOCR.totalNetAmount),
    totalTax: formatAmount(documentOCR.totalTax),
    totalGrossAmount: formatAmount(documentOCR.totalGrossAmount),
    amount: formatAmount(documentOCR.amount)
  };
};

const getLinkedDocumentObject = linkedDocuments => {
  let linkedObject = [];
  if (linkedDocuments && linkedDocuments.length > 0) {
    linkedObject = linkedDocuments.map(function(doc) {
      return {
        id: doc.id,
        direction: doc.direction,
        verificationStatus: doc.verificationStatus,
        paymentStatus: doc.paymentStatus,
        documentType: doc.documentType
      };
    });
  }
  return linkedObject;
};

export const formatDocumentStatus = docStatus => {
  let documentStatus;
  if (docStatus !== undefined && docStatus !== null) {
    switch (docStatus) {
      case constant.verificationStatus.verified:
        documentStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_VERIFIED_TABLE");

        break;
      case constant.verificationStatus.disapproved:
        documentStatus = Localize.get(
          "DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_DISAPPROVED_TABLE"
        );

        break;
      case constant.verificationStatus.unverified:
        documentStatus = Localize.get(
          "DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_UNVERIFIED_TABLE"
        );

        break;

      default:
        break;
    }
  }
  return documentStatus;
};

export const formatDocumentPaymentStatus = docPaymentStatus => {
  let documentStatus;
  if (docPaymentStatus !== undefined && docPaymentStatus !== null) {
    switch (docPaymentStatus) {
      case constant.paymentStatus.open:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_OPEN");
        break;
      case constant.paymentStatus.pending:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_PENDING");
        break;
      case constant.paymentStatus.notPaid:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_NOTPAID");
        break;
      case constant.paymentStatus.successful:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_SUCC");
        break;
      case constant.paymentStatus.notSuccessful:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_NOTSUCC");
        break;
      case constant.paymentStatus.discarded:
        documentStatus = Localize.get("UTIL.DOCUMENT_PAYMENT_DISCARDED");
        break;
      default:
        break;
    }
  }
  return documentStatus;
};

export const formatDocumentPaymentLabelStatus = payments => {
  let docPaymentStatus = payments;
  let documentStatus;
  let classPaymentApprovalStatuses;

  if (docPaymentStatus !== undefined && docPaymentStatus !== null) {
    switch (docPaymentStatus) {
      case constant.paymentLabelStatus.pending:
        documentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PENDING");
        classPaymentApprovalStatuses = "ui label green pay pending";
        break;
      case constant.paymentLabelStatus.notPaid:
        documentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTPAID");
        classPaymentApprovalStatuses = "ui label green pay not_paid";
        break;
      case constant.paymentLabelStatus.paid:
        documentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PAID");
        classPaymentApprovalStatuses = "ui label green pay paid";
        break;
      case constant.paymentLabelStatus.notSuccessful:
        documentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTSUCC");
        classPaymentApprovalStatuses = "ui label green pay notsucc";
        break;
      default:
        break;
    }
  }
  return {
    documentStatus: documentStatus,
    classPaymentApprovalStatuses: classPaymentApprovalStatuses
  };
};

export const directionOptions = () => [
  {
    key: 1,
    text: Localize.get("TRANSACTIONS.DIRECTION.EXPENSE"),
    value: "incoming"
  },
  {
    key: 2,
    text: Localize.get("TRANSACTIONS.DIRECTION.REVENUE"),
    value: "outgoing"
  }
];

export const verificationOptions = () => [
  {
    key: 1,
    text: Localize.get("UTIL.APPROVAL_STATUS_VERIFIED"),
    value: "verified"
  },
  {
    key: 2,
    text: Localize.get("UTIL.APPROVAL_STATUS_DISAPPROVED"),
    value: "disapproved"
  },
  {
    key: 3,
    text: Localize.get("UTIL.APPROVAL_STATUS_UNVERIFIED"),
    value: "unverified"
  }
];

export const documentTypeOptions = () => [
  {
    key: 0,
    value: constant.documentType.inquiry,
    text: Localize.get("UTIL.DOCUMENT_TYPE_INQUIRY")
  },
  {
    key: 1,
    value: constant.documentType.offer,
    text: Localize.get("UTIL.DOCUMENT_TYPE_OFFER")
  },
  {
    key: 2,
    value: constant.documentType.order,
    text: Localize.get("UTIL.DOCUMENT_TYPE_ORDER")
  },
  {
    key: 3,
    value: constant.documentType.receipt,
    text: Localize.get("UTIL.DOCUMENT_TYPE_RECEIPT")
  },
  {
    key: 4,
    value: constant.documentType.delivery,
    text: Localize.get("UTIL.DOCUMENT_TYPE_DELIVERY_NOTE")
  },
  {
    key: 5,
    value: constant.documentType.invoice,
    text: Localize.get("UTIL.DOCUMENT_TYPE_INVOICE")
  },
  {
    key: 6,
    value: constant.documentType.correction,
    text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.CORRECTION")
  },
  {
    key: 7,
    value: constant.documentType.annex,
    text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.ANNEX")
  },
  {
    key: 8,
    value: constant.documentType.imported_invoice,
    text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.IMPORTED_INVOICE")
  },
  {
    key: 9,
    value: constant.documentType.other,
    text: Localize.get("UTIL.DOCUMENT_TYPE_OTHER")
  }
];

// today
export const getCurrentDate = () => {
  return formatDate(new Date());
};

// three months from now
export const getBackThreeMonthsFromNow = () => {
  return formatDate(subMonths(new Date(), 3));
};

export const getDocumentDataType = type => {
  let localeType;
  if (type !== undefined && type !== null) {
    switch (type) {
      case constant.documentType.inquiry:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_INQUIRY");
        break;
      case constant.documentType.offer:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_OFFER");
        break;
      case constant.documentType.order:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_ORDER");
        break;
      case constant.documentType.receipt:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_RECEIPT");
        break;
      case constant.documentType.delivery:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_DELIVERY_NOTE");
        break;
      case constant.documentType.invoice:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_INVOICE");
        break;
      case constant.documentType.imported_invoice:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_IMPORTED_INVOICE");
        break;
      case constant.documentType.annex:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_ANNEX");
        break;
      case constant.documentType.other:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_OTHER");
        break;
      case constant.documentType.deliveryNote:
        localeType = Localize.get("UTIL.DOCUMENT_TYPE_DELIVERY_NOTE");
        break;
      default:
        break;
    }
  }
  return localeType;
};
