import React, { useState } from "react";
import { Icon, Divider, Accordion, Grid, List, Progress, Popup, Message } from "semantic-ui-react";
import Localize from "react-intl-universal";
import ProjectResourcesPartners from "./ProjectResourcesPartners";
import DocumentFlow from "../../../Documents/DocumentFLow/DocumentFlow";
import { formatDateDisplay } from "../../../Documents/Util";
import { localizeStatus } from "../Util";
import styles from "./style/ProjectManagementDetails.module.css";
import * as RestService from "../../../RestService";

const ProjectManagementDetails = props => {
  const {
    privileges,
    setIsLoading,
    onDeleteItemClick,
    handler,
    onEditItemClick,
    onArchiveClick,
    project = {}
  } = props;
  const { projectTemplate } = project;
  const [activeIndex, setActiveIndex] = useState(0);
  const [showNote, setShowNote] = useState(false);
  const [selectedPreviewDoc, setSelectedPreviewDoc] = useState(null);
  const [showDiagramFlowState, setShowDiagramFlowState] = useState(false);
  const [documentForDiagram, setDocumentForDiagram] = useState(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const rolePersonsHandeler = role => {
    let roleContacts = [];
    project.resources.forEach(resource => {
      if (resource.id.resourceRole.id === role.id.resourceRole.id) {
        roleContacts.push(resource);
      }
    });
    return roleContacts;
  };

  const projetResources =
    projectTemplate && projectTemplate.roles
      ? projectTemplate.roles.map((role, index) => (
          <ProjectResourcesPartners
            key={index}
            role={role}
            activeMode={"details"}
            partners={rolePersonsHandeler(role)}
          />
        ))
      : null;
  const parseProgress = per => {
    let persent;
    if (per.includes(",")) {
      persent = parseFloat(per.split(",").join("."));
    } else {
      persent = parseFloat(per);
    }
    if (isNaN(persent)) {
      persent = 0;
    }
    return persent;
  };

  const handleOnArchiveClick = (event, data) => {
    if (privileges.update) {
      if (props.project) onArchiveClick(project.id, project.archived);
    }
  };

  const showResources = () => {
    let m;
    m = projectTemplate.roles.map((role, index) => rolePersonsHandeler(role).length !== 0);
    const equalToTrue = currentValue => currentValue === true;

    return m.some(equalToTrue);
  };

  const displayNote = () => {
    setShowNote(!showNote);
  };

  const callbackDocSuccess = response => {
    setDocumentForDiagram(response.data);
    setShowDiagramFlowState(true);
  };

  const callbackDocError = response => {};

  const callbackDocFinaly = response => {
    setIsLoading(false);
  };

  const getDocumentById = parentId => {
    if (parentId) {
      const serviceGetDocById = new RestService.Document.GetById.Service(parentId);
      serviceGetDocById.setCallbackSuccess(callbackDocSuccess);
      serviceGetDocById.setCallbackError(callbackDocError);
      serviceGetDocById.setCallbackFinally(callbackDocFinaly);
      serviceGetDocById.call();
    }
  };

  const viewFlow = myId => {
    setIsLoading(true);
    setShowDiagramFlowState(false);
    getDocumentById(myId);
  };

  const checkNan = amount => (!isNaN(amount) ? amount : null);
  const parseAndFixDecimal = (amount, decimalSize = 2) => {
    return parseFloat(parseFloat(amount).toFixed(2));
  };

  const sumAndCheckForNaN = (accAmount, amount) => {
    if (!isNaN(accAmount) || !isNaN(amount))
      return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
    else return null;
  };

  const totalNetAmount = project.documents.reduce(
    (a, document) => sumAndCheckForNaN(a, document.amount),
    0
  );

  const displayNetAmount = amount => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR"
    }).format(amount);
  };
  return (
    <>
      {project.projectName !== undefined ? (
        <div className="six wide column items_details">
          <div className={styles.project_details_header}>
            <span>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_DETAILS")}</span>

            <Popup
              size="mini"
              content={Localize.get("PROJECTS.MANAGEMENT.DELETE_PROJECT")}
              trigger={
                <Icon
                  disabled={!privileges.delete}
                  name="trash alternate"
                  className={`${styles.project_details_header_icon} ${styles.delete_icon}`}
                  onClick={onDeleteItemClick}
                />
              }
            />

            <Popup
              size="mini"
              content={
                project.archived === false
                  ? Localize.get("CONTACTS.DETAILS.TOOLBAR.ARCHIVE")
                  : Localize.get("CONTACTS.DETAILS.TOOLBAR.UNARCHIVE")
              }
              trigger={
                <Icon.Group
                  className={styles.project_details_header_icon}
                  onClick={handleOnArchiveClick}
                  disabled={!privileges.update ? true : props.project ? false : true}
                >
                  <Icon name="archive" />
                  <Icon corner name={project.archived === true ? "minus circle" : "plus circle"} />
                </Icon.Group>
              }
            />

            <Popup
              size="mini"
              content={Localize.get("PROJECTS.MANAGEMENT.EDIT_PROJECT")}
              trigger={
                <Icon
                  disabled={!privileges.update}
                  name="edit"
                  className={styles.project_details_header_icon}
                  onClick={onEditItemClick}
                />
              }
            />
          </div>
          <div className={styles.project_details_container}>
            <Grid container>
              <Grid.Row className={styles.details_border}>
                <Grid.Column width={6}>
                  <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME")}</strong>
                </Grid.Column>
                <Grid.Column className={styles.wrap_column} width={10}>
                  {props.project ? props.project.projectName : undefined}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={styles.details_border}>
                <Grid.Column width={6}>
                  <strong>{Localize.get("PROJECTS.MANAGEMENT.STATUS")}</strong>
                </Grid.Column>
                <Grid.Column width={10}>
                  {props.project.projectStatus
                    ? localizeStatus(props.project.projectStatus.name)
                    : undefined}
                </Grid.Column>
              </Grid.Row>
              {props.project.projectStatusExternal ? (
                <Grid.Row className={styles.details_border}>
                  <Grid.Column width={6}>
                    <strong>{Localize.get("PROJECTS.MANAGEMENT.EXTERNAL_STATUS")}</strong>
                  </Grid.Column>
                  <Grid.Column width={10}>{props.project.projectStatusExternal.name}</Grid.Column>
                </Grid.Row>
              ) : (
                <div></div>
              )}
              {props.project.id ? (
                <Grid.Row className={styles.details_border}>
                  <Grid.Column width={6}>
                    <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_NUMBER")}</strong>
                  </Grid.Column>
                  <Grid.Column width={10}>{props.project.id}</Grid.Column>
                </Grid.Row>
              ) : (
                <div></div>
              )}
              {props.project.projectExternalNumber ? (
                <Grid.Row className={styles.details_border}>
                  <Grid.Column width={6}>
                    <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_EXTERNAL")}</strong>
                  </Grid.Column>
                  <Grid.Column width={10}>{props.project.projectExternalNumber}</Grid.Column>
                </Grid.Row>
              ) : (
                <div></div>
              )}
              {props.project.documents.length > 0 && (
                <Grid.Row className={styles.details_border}>
                  <Grid.Column width={6}>
                    <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_TOTAL_NET_AMOUNT")}</strong>
                  </Grid.Column>
                  <Grid.Column width={10}>{displayNetAmount(totalNetAmount)}</Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row className={styles.details_border}>
                <Grid.Column width={6}>
                  <strong>{Localize.get("PROJECTS.MANAGEMENT.PROGRESS")}</strong>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Progress
                    className={styles.progress_bar_margin}
                    percent={
                      props.project.progress ? parseProgress(props.project.progress) : undefined
                    }
                    progress
                    color="blue"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {props.project && props.project.projectDescription ? (
              <>
                <div className={styles.note_details}>
                  <div className={styles.header_notes}>
                    <span className={styles.header_title}>
                      {Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}
                    </span>
                    <div className={styles.header_icon}>
                      {!showNote ? (
                        <Icon name="sticky note outline" size="large" onClick={displayNote} />
                      ) : (
                        <Icon name="sticky note" size="large" onClick={displayNote} />
                      )}
                    </div>
                  </div>
                  <div>
                    {showNote ? (
                      <div className="item">
                        <div className="content">
                          <div className={styles.description_of_issue}>
                            {props.project.projectDescription}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div></div>
            )}

            <Divider />

            <h5 className={styles.accourdion_title}>
              {" "}
              {Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE")}
            </h5>

            <Accordion styled className={styles.projectDetailsTemplate}>
              <Accordion.Title
                active={activeIndex === 3}
                index={3}
                onClick={handleClick}
                className={styles.title}
              >
                <Icon name="dropdown" />
                {Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE")}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <List divided verticalAlign="middle">
                  <List.Item className="half_space">
                    <List.Content>
                      <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE_NAME")}</strong>
                    </List.Content>
                    <List.Content className={styles.wrap_text}>
                      {projectTemplate ? projectTemplate.name : ""}
                    </List.Content>
                    {projectTemplate && projectTemplate.description && (
                      <>
                        <List.Content>
                          <strong>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}</strong>
                        </List.Content>
                        <List.Content className={styles.wrap_text}>
                          {projectTemplate.description}
                        </List.Content>
                      </>
                    )}
                  </List.Item>
                  <List.Item className="half_space">
                    <List.Content>
                      <strong> {Localize.get("PROJECTS.MANAGEMENT.PROJECT_TYPE")}</strong>
                    </List.Content>
                    <List.Content>
                      {projectTemplate ? projectTemplate.projectType.name : ""}
                    </List.Content>

                    {projectTemplate &&
                      projectTemplate.projectType &&
                      projectTemplate.projectType.description && (
                        <>
                          <List.Content>
                            <strong>
                              {" "}
                              {Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}
                            </strong>
                          </List.Content>
                          <List.Content className={styles.wrap_text}>
                            {projectTemplate.projectType.description}
                          </List.Content>
                        </>
                      )}
                  </List.Item>
                </List>
              </Accordion.Content>
            </Accordion>

            {project.documents && project.documents.length > 0 ? (
              <>
                <Divider></Divider>
                <h5 className={styles.accourdion_title}>
                  {" "}
                  {Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS")}
                </h5>
                <Accordion className={styles.projectDetailsTemplate} styled>
                  <Accordion.Title
                    active={activeIndex === 4}
                    index={4}
                    onClick={handleClick}
                    className={styles.title}
                  >
                    <Icon name="dropdown" />
                    {Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS")}
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 4}
                    className={styles.preview_table_container}
                  >
                    <table className={`ui celled table ${styles.preview_table_font}`}>
                      <thead>
                        <tr>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_NUMBER")}</th>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_TYPE")}</th>
                          <th>{Localize.get("DOCUMENTS.ITEMLIST.HEADER.PARTNER")}</th>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_DATE")}</th>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_NET_AMOUNT")}</th>
                          <th>{Localize.get("PROJECTS.STATUSES.STATUS_DESCRIPTION")}</th>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_PREVIEW")}</th>
                          <th>{Localize.get("PROJECTS.MANAGEMENT.LINK_DOCUMENTS_FLOW")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {props.project.documents.map(doc => (
                          <tr key={doc.id.document.id}>
                            <td>{doc.id.document.documentNumber}</td>
                            <td>
                              {Localize.get(
                                `PROJECTS.MANAGEMENT.DOCUMENT_TYPE_${doc.id.document.documentType
                                  .toString()
                                  .toUpperCase()}`
                              )}
                            </td>
                            <td>{doc.id.document.partner.name}</td>
                            <td>{formatDateDisplay(doc.id.document.documentDate)}</td>

                            <td>{displayNetAmount(doc.amount)}</td>
                            <td>{doc.description}</td>
                            <td className={styles.icon_center}>
                              {" "}
                              {selectedPreviewDoc === doc.id.document ? (
                                <Icon
                                  onClick={() => {
                                    handler.showLinkedDocumentHandler(null);
                                    setSelectedPreviewDoc(null);
                                  }}
                                  name="eye slash"
                                  size="large"
                                />
                              ) : (
                                <Icon
                                  onClick={() => {
                                    handler.showLinkedDocumentHandler(doc.id.document);
                                    setSelectedPreviewDoc(doc.id.document);
                                  }}
                                  name="eye"
                                  size="large"
                                />
                              )}
                            </td>
                            <td className={styles.sitemap_cell}>
                              <Icon
                                className={styles.edit_link_docs_icon}
                                name="sitemap"
                                onClick={() => viewFlow(doc.id.document.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Accordion.Content>
                </Accordion>
              </>
            ) : (
              <div />
            )}
            <Divider />
            {showResources() && (
              <>
                <h5 className={styles.accourdion_title}>
                  {" "}
                  {Localize.get("PROJECTS.MANAGEMENT.PROJECT_RESOURCES")}
                </h5>

                {projetResources}

                <Divider />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="six wide column items_details">
          <div className={styles.project_details_header}>
            <span>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_DETAILS")}</span>
          </div>
          <Message icon>
            <Icon name="info circle" />
            {Localize.get("PROJECTS.MANAGEMENT.EMPTY_DETAILS")}
          </Message>
        </div>
      )}
      <div>
        {showDiagramFlowState ? (
          <DocumentFlow
            viewFlow={viewFlow}
            showDiagramFlowState={showDiagramFlowState}
            setDocumentForDiagram={setDocumentForDiagram}
            setShowDiagramFlowState={setShowDiagramFlowState}
            document={documentForDiagram}
          />
        ) : null}
      </div>
    </>
  );
};

export default ProjectManagementDetails;
