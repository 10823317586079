import axios from "axios";
import { getLocalStorage, getSession } from "../Auth/AuthContext";

const getDefaultConfig = ({ path = "", params = {}, responseType = "", data = {}, url = "" }) => {
  const auth = getLocalStorage("auth") || {};
  const session = getSession();
  const { token } = auth;
  const myBaseUrl = `${process.env.REACT_APP_API_LINK}${path}`;

  return {
    baseURL: myBaseUrl,
    responseType: responseType,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Session: session
    },
    params,
    data,
    url
  };
};

export const getByPathAndParams = (path, params, responseType) => {
  const instance = axios.create(getDefaultConfig({ path, params, responseType }));
  return instance({ method: "GET" });
};

export const downloadDocById = (path, params, responseType) => {
  const instance = axios.create(getDefaultConfig({ path, params, responseType }));
  return instance({ method: "GET" });
};

export const postDocumentByPathAndParams = (
  data,
  path,
  postOrPatchMethod,
  responseType,
  params
) => {
  const instance = axios.create(getDefaultConfig({ data, path, params }));
  return instance({ method: "POST" });
};

export const postDocumentByPath = path => {
  const instance = axios.create(getDefaultConfig({ path }));
  return instance({ method: "POST" });
};

export const downloadImportedById = (data, path) => {
  const instance = axios.create(getDefaultConfig({ data, path, responseType: "arraybuffer" }));
  return instance({ method: "POST" });
};

export const sendMail = (data, path) => {
  const instance = axios.create(
    getDefaultConfig({
      data,
      path,
      responseType: "text"
    })
  );
  return instance({ method: "POST" });
};
