import * as constant from "../Constants";
import { BaseService } from "../Base";

export class Service extends BaseService {
  constructor(id) {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.delete,
      resource: constant.BASE.resource.projects,
      pathParameter: id
    };
    this.query = null;
  }
}
