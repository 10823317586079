import React, { useState, useContext } from "react";
import { Menu, Dropdown, Dimmer, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import Localize from "react-intl-universal";
import imgLogo from "../Assets/Images/new_logo.png";
import LoginForm from "../Login/LoginForm";
import styles from "./AppNavBar.module.css";
// context
import { AuthContext } from "../Auth/AuthContext";

const AppNavBar = props => {
  const { authState, dispatch, customConfig } = useContext(AuthContext);

  const menuItemBookKeeping = Localize.get("APPNAVBAR.BOOKKEEPING");
  const menuItemBookingOverview = Localize.get("APPNAVBAR.BOOKING_OVERVIEW");
  const menuItemAccountOverview = Localize.get("APPNAVBAR.ACCOUNT_OVERVIEW");
  const menuItemFinances = Localize.get("APPNAVBAR.FINANCES");
  const menuItemAccounts = Localize.get("APPNAVBAR.ACCOUNTS");
  const menuItemTransactions = Localize.get("APPNAVBAR.TRANSACTIONS");
  const menuItemProjects = Localize.get("APPNAVBAR.PROJECTS");
  const menuItemManagement = Localize.get("APPNAVBAR.MANAGEMENT");
  const menuItemStatuses = Localize.get("APPNAVBAR.STATUSES");
  const menuItemRoles = Localize.get("APPNAVBAR.ROLES");
  const menuItemTypes = Localize.get("APPNAVBAR.TYPES");
  const menuItemTemplates = Localize.get("APPNAVBAR.TEMPLATES");
  const menuItemExports = Localize.get("APPNAVBAR.EXPORTS");
  const menuItemExported = Localize.get("APPNAVBAR.EXPORTED");

  let hrefLocation = window.location.href;
  const [boldedHeader, setBoldedHeader] = useState(
    hrefLocation.includes("roles") ||
      hrefLocation.includes("management") ||
      hrefLocation.includes("types") ||
      hrefLocation.includes("templates") ||
      hrefLocation.includes("statuses")
      ? menuItemProjects
      : hrefLocation.includes("export")
      ? menuItemExports
      : hrefLocation.includes("accounts") || hrefLocation.includes("transactions")
      ? menuItemFinances
      : hrefLocation.includes("booking") || hrefLocation.includes("account")
      ? menuItemBookKeeping
      : hrefLocation.includes("settings")
      ? "settings"
      : null
  );
  const { setVerticalBarVisible, verticalBarVisible, activeItem, setActiveItem } = props;

  const handleItemClick = (e, { to, name }) => {
    if (hrefLocation.includes("export")) {
      let hashLocation = window.location.hash;
      let locationArray = hashLocation.split("/");
      let pageArray = to.split("/");

      if (locationArray[locationArray.length - 1] === pageArray[pageArray.length - 1]) {
        window.location.reload();
      }
    } else if (to === "/") {
      if (window.location.hash === "#/") {
        window.location.reload();
      }
    } else if (hrefLocation.includes(to.toLowerCase())) {
      window.location.reload();
    }

    if (name === menuItemAccounts || name === menuItemTransactions) {
      setBoldedHeader(menuItemFinances);
    } else if (
      name === menuItemRoles ||
      name === menuItemTypes ||
      name === menuItemTemplates ||
      name === menuItemStatuses ||
      name === menuItemManagement
    ) {
      setBoldedHeader(menuItemProjects);
    } else if (name === menuItemExports || name === menuItemExported) {
      setBoldedHeader(menuItemExports);
    } else if (name === menuItemBookingOverview || name === menuItemAccountOverview) {
      setBoldedHeader(menuItemBookKeeping);
    } else {
      setBoldedHeader();
    }

    setActiveItem(name);
  };

  const boldedHeaderStyle = {
    fontWeight: "900",
    color: "black"
  };

  const getMenuItem = (item, index) => {
    switch (item.type) {
      case "item":
        return (
          !item.hidden && (
            <Menu.Item
              key={item.name}
              className={styles.bms_tablet_hide}
              name={Localize.get(item.name)}
              active={activeItem === Localize.get(item.name)}
              onClick={handleItemClick}
              as={NavLink}
              to={item.to}
              exact
            />
          )
        );
      case "dropdown":
        return (
          !item.hidden && (
            <Menu.Item
              key={item.name}
              className={styles.bms_tablet_hide}
              name={Localize.get(item.name)}
              as={Dropdown}
              item
              text={Localize.get(item.name)}
              style={
                boldedHeader === Localize.get(item.name)
                  ? boldedHeaderStyle
                  : { fontWeight: "normal" }
              }
            >
              <Dropdown.Menu>
                {item.dropdownItems.map(dropdownItem => (
                  <Dropdown.Item
                    key={dropdownItem.value}
                    name={Localize.get(dropdownItem.name)}
                    as={NavLink}
                    onClick={handleItemClick}
                    text={Localize.get(dropdownItem.name)}
                    value={dropdownItem.value}
                    to={dropdownItem.to}
                    exact
                  />
                ))}
              </Dropdown.Menu>
            </Menu.Item>
          )
        );
      default:
        return <div key={index} />;
    }
  };

  return (
    <div className="ui stackable menu header_menu ">
      <Dimmer active={props.headerDimmed} inverted></Dimmer>
      <Menu.Item>
        <img className={`${styles.dms_logo} logo`} src={imgLogo} alt="Digital Billing" />
      </Menu.Item>

      {authState.token &&
        customConfig.header.menuItems.map((item, index) => getMenuItem(item, index))}
      <div className={"right menu"}>
        {authState.token && (
          <>
            <Menu.Item className={styles.bms_tablet_hide}>
              <Icon
                icon="settings"
                className={`${styles.setting_icon} ${
                  boldedHeader === "settings" || activeItem === "settings"
                    ? styles.setting_icon_active
                    : null
                }`}
                size="large"
                name={"settings"}
                onClick={() => {
                  setBoldedHeader("settings");
                  setActiveItem();
                  window.location.href = "/#/settings";
                }}
              />
            </Menu.Item>
            <div
              className={`item ${styles.bms_tablet_show} ${styles.bms_icon}`}
              onClick={() => {
                setVerticalBarVisible(!verticalBarVisible);
              }}
            >
              <i className="bars icon" />
            </div>
          </>
        )}

        <div className="item user">
          <LoginForm signedIn={authState.token} dispatch={dispatch} />
        </div>
      </div>
    </div>
  );
};

export default AppNavBar;
