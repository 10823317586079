import React from "react";
import { Button, Sidebar, Icon, Menu, Container } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ProjectTemplateSideBar.module.css";

const ProjectTemplateSideBar = props => {
  const { privileges, sideBarVisibility, setSideBarVisibility, bindArchive } = props;

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Container>
          <Button
            disabled={!privileges.create}
            className={`ui primary button new_document ${styles.bms_button}`}
            onClick={props.handle.createNewTemplateButtonClick}
          >
            {Localize.get("PROJECTS.TEMPLATES.NEW_TEMPLATE")}
            <Icon name="plus" />
          </Button>
        </Container>
        <Menu.Item>
          <Menu.Menu className={styles.active_menu_item}>
            <Menu.Item name={"archived"} {...bindArchive}>
              <Icon name="archive" className={styles.archive_filter} />{" "}
              {Localize.get("CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default ProjectTemplateSideBar;
