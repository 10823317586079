import React, { useState, useEffect } from "react";

import { List, Grid, Dropdown, Modal, Input, Label, Checkbox } from "semantic-ui-react";
import Localize from "react-intl-universal";

import * as RestService from "../../RestService";
import { formatDate, formatDateToString, formatAmount } from "../../Documents/Util";
import Validator from "../../Utils/Validator";
import PaymentGrid from "./PaymentGrid";
import * as validatorConstants from "../../Utils/Constant";
import styles from "./style/PaymentForm.module.css";
import GlobalOkCancelBtn from "../../Components/GlobalOkCancelBtn";
import GlobalOkBtn from "../../Components/GlobalOkBtn";
import GlobalDatedown from "../RightPanel/GlobalDatedown";

const PaymentForm = props => {
  const { checkedDocs, accounts, companyName, selectedDocument } = props;

  const checkedDoc = checkedDocs.find(doc => doc.id === selectedDocument.id);
  const lastValidAmount = checkedDoc.amount;
  const partner = checkedDoc.partner ? checkedDoc.partner : { accounts: [] };
  const [incompletePayments, setIncompletePayments] = useState([]);
  const [executionDate, setExecutionDate] = useState(new Date());
  const [prvExecutionDate, setPrvExecutionDate] = useState(new Date());
  const [paymentAccountId, setPaymentAccountId] = useState(null);

  const [dateChangeDialog, setDateChangeDialog] = useState(false);
  const [companyAccountChangeDialog, setCompanyAccountChangeDialog] = useState(false);
  const [openUrlDialog, setOpenUrlDialog] = useState(false);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [openMsgPayDialog, setOpenMsgPayDialog] = useState(false);
  const [openNoDocsDialog, setOpenNoDocsDialog] = useState(false);
  const [openUncheckDialog, setOpenUncheckDialog] = useState(false);
  const [creationDateMenuOpen, setCreationDateMenuOpen] = useState(false);
  const [uncheckedId, setUncheckedId] = useState(0);

  const [bankLink, setBankLink] = useState("");
  const [refreshValidation, setRefreshValidation] = useState(false);

  const partnerAcountOptions = partner.accounts.map(acc => {
    return { key: acc.id, text: acc.accountOwner, value: acc.id };
  });

  const companyAcountOptions = accounts.map(acc => {
    return { key: acc.id, text: acc.accountOwner, value: acc.id };
  });

  const [selectedCompanyAccount, setSelectedCompanyAccount] = useState(
    accounts.length >= 1
      ? {
          id: accounts[0].id,
          finApiId: accounts[0].finApiId,
          bankName: accounts[0].bankName,
          accountOwner: accounts[0].accountOwner,
          iban: accounts[0].iban,
          bic: accounts[0].bic
        }
      : {
          id: null,
          accountOwner: null,
          finApiId: null,
          bankName: null,
          iban: "",
          bic: ""
        }
  );

  const [prvSelectedCompanyAccount, setPrvSelectedCompanyAccount] = useState(
    selectedCompanyAccount
  );

  const allPartners = checkedDocs.map(doc => {
    return {
      docId: doc.id,
      partner: doc.partner
    };
  });

  const initPayments = docs => {
    return docs.map(doc => {
      let docPartner = allPartners.find(ip => ip.docId === doc.id).partner;

      return {
        docId: doc.id,
        docNo: doc.documentNumber,
        fullAmount: doc.amount,
        amount: (
          doc.amount -
          (doc.payments && doc.payments.length > 0
            ? doc.payments.reduce(
                (prvAmt, pay) => (pay.amount ? prvAmt + pay.amount : prvAmt + 0),
                0
              )
            : 0)
        ).toFixed(2),
        initialAmount: (
          doc.amount -
          (doc.payments && doc.payments.length > 0
            ? doc.payments.reduce(
                (prvAmt, pay) => (pay.amount ? prvAmt + pay.amount : prvAmt + 0),
                0
              )
            : 0)
        ).toFixed(2),
        discount: doc.documentDiscount,
        amountChanged: false,
        purpose: doc.documentDiscount.valid
          ? (companyName.value !== "" ? companyName.value + ", " : "") +
            doc.documentNumber +
            " " +
            Localize.get("DOCUMENTS.PAYMENT.INCL_1") +
            doc.documentDiscount.savings +
            Localize.get("DOCUMENTS.PAYMENT.INCL_2")
          : (companyName.value !== "" ? companyName.value + ", " : "") + doc.documentNumber,
        recipientName: docPartner.accounts[0].accountOwner,
        recipientIban: docPartner.accounts[0].iban,
        recipientBic: docPartner.accounts[0].bic,
        partnerName: docPartner.name,
        partnerAccountId: docPartner.accounts[0].id,
        executionDate: formatDate(new Date()),
        checked: doc.checked,
        isValidPayment: false,
        checkAmount: false,
        zeroAmount: false,
        actions: doc.actions,
        paymentValidator: new Validator(validatorConstants.checkFieldsPaymentForm),
        paymentsPaid:
          doc.payments && doc.payments.length > 0
            ? doc.payments.reduce(
                (prvAmt, pay) => (pay.amount ? prvAmt + pay.amount : prvAmt + 0),
                0
              )
            : 0
      };
    });
  };

  const payments = initPayments(checkedDocs).map(doc => ({
    ...doc,
    displayAmount: doc.discount.valid
      ? doc.actions.PaymentRules.maxPaymentWithDiscount
      : doc.actions.PaymentRules.maxPayment,
    amount: doc.discount.valid
      ? doc.actions.PaymentRules.maxPaymentWithDiscount
      : doc.actions.PaymentRules.maxPayment
  }));

  const [paymentObject, setPaymentObject] = useState({
    accountId: selectedCompanyAccount.finApiId,
    companyAccountId: selectedCompanyAccount.id,
    payments: payments,
    singleBooking: true,
    executionDate: formatDate(new Date())
  });

  let paymentObjectInstance = paymentObject.payments.find(payObj => payObj.docId === checkedDoc.id);

  const onCheckboxChange = () => {
    let newValue = !paymentObjectInstance.discount.valid;
    let paymentObjectInstanceIndex = paymentObject.payments.indexOf(paymentObjectInstance);
    let newPayments = paymentObject.payments;
    newPayments[paymentObjectInstanceIndex] = {
      ...paymentObjectInstance,
      discount: { ...paymentObjectInstance.discount, valid: newValue },
      amount: newValue
        ? paymentObjectInstance.actions.PaymentRules.maxPaymentWithDiscount
        : paymentObjectInstance.actions.PaymentRules.maxPayment,
      displayAmount: newValue
        ? paymentObjectInstance.actions.PaymentRules.maxPaymentWithDiscount
        : paymentObjectInstance.actions.PaymentRules.maxPayment,
      purpose: newValue
        ? paymentObjectInstance.docNo +
          " " +
          Localize.get("DOCUMENTS.PAYMENT.INCL_1") +
          paymentObjectInstance.discount.savings +
          Localize.get("DOCUMENTS.PAYMENT.INCL_2")
        : paymentObjectInstance.docNo
    };

    setPaymentObject({ ...paymentObject, payments: newPayments });
  };

  let selectedPartnerAccount = partner.accounts.find(
    a => a.id === paymentObjectInstance.partnerAccountId
  );

  const validator =
    props.validator === undefined
      ? new Validator(validatorConstants.formFieldsPaymentForm)
      : props.validator;

  let formPurpose = paymentObjectInstance.purpose;

  let formAmount = paymentObjectInstance.displayAmount;

  const errorBorder = "3px red solid";

  useEffect(() => {
    paymentObject.payments.map(pay => {
      if (pay.checked !== checkedDocs.find(doc => doc.id === pay.docId).checked) {
        pay.checked = checkedDocs.find(doc => doc.id === pay.docId).checked;

        if (!pay.checked) {
          setOpenUncheckDialog(true);
          setUncheckedId(pay.docId);
        }
      }
      return pay;
    });
  });

  useEffect(() => {
    if (props.accounts && props.accounts.length > 0) {
      setSelectedCompanyAccount({
        id: accounts[0].id,
        finApiId: accounts[0].finApiId,
        bankName: accounts[0].bankName,
        accountOwner: accounts[0].accountOwner,
        iban: accounts[0].iban,
        bic: accounts[0].bic
      });
      setPrvSelectedCompanyAccount(selectedCompanyAccount);

      setPaymentObject({
        ...paymentObject,
        accountId: selectedCompanyAccount.finApiId,
        companyAccountId: selectedCompanyAccount.id
      });
    }
  }, [props.accounts]);

  useEffect(() => {
    setPaymentObject({
      ...paymentObject,
      accountId: selectedCompanyAccount.finApiId,
      companyAccountId: selectedCompanyAccount.id
    });
  }, [selectedCompanyAccount]);

  // methods
  const getCurrency = () => {
    let currency;
    if (checkedDocs.length > 0 && checkedDocs[0].currency && checkedDocs[0].currency.id) {
      currency = checkedDocs[0].currency.id;
    } else {
      currency = null;
    }

    return currency;
  };

  // event handlers & callbacks
  const onPaymentAccountChange = (e, data) => {
    let selected = partner.accounts.find(acc => acc.id === data.value);

    paymentObject.payments.map(pay => {
      if (pay.docId === selectedDocument.id) {
        pay.recipientName = selected.accountOwner;
        pay.recipientIban = selected.iban;
        pay.recipientBic = selected.bic;
        pay.partnerName = partner.name;
        pay.partnerAccountId = selected.id;
      }
      return pay;
    });

    setPaymentObject({ ...paymentObject, payments: paymentObject.payments });
  };

  const onCompanyAccountChange = (e, data) => {
    const selected = accounts.find(acc => acc.id === data.value);
    setPrvSelectedCompanyAccount(selectedCompanyAccount);
    setSelectedCompanyAccount(selected);
    setCompanyAccountChangeDialog(true);
  };

  const cancelCompanyAccountChange = () => {
    setCompanyAccountChangeDialog(false);
    setSelectedCompanyAccount(prvSelectedCompanyAccount);
  };

  const acceptCompanyAccountChange = () => {
    setCompanyAccountChangeDialog(false);
    paymentObject.payments.map(
      pay =>
        (pay.isValidPayment = validator.validateForm(validatorConstants.checkFieldsPaymentForm, {
          ...pay,
          accountId: selectedCompanyAccount.finApiId
        }))
    );

    setPaymentObject({
      ...paymentObject,
      accountId: selectedCompanyAccount.finApiId,
      companyAccountId: selectedCompanyAccount.id
    });

    props.handler.paymentForm.onPaymentChange({}, { payments: paymentObject.payments });
  };

  const handleOnChange = (e, data) => {
    if (e.target.name === "amount") {
      if (!validator.isValidNumber(e.target.value)) {
        e.target.value = lastValidAmount;
        return;
      }

      let t = e.target.value;
      if (t.indexOf(".") >= 0) {
        e.target.value = t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3);
      } else if (t.indexOf(",") >= 0) {
        e.target.value = t.substr(0, t.indexOf(",")) + t.substr(t.indexOf(","), 3);
      }

      let newAmount = formatAmount(e.target.value);
      paymentObjectInstance.zeroAmount = false;
      if (parseFloat(newAmount) > parseFloat(checkedDoc.actions.PaymentRules.maxPayment)) {
        paymentObjectInstance.checkAmount = true;
      } else {
        paymentObjectInstance.checkAmount = false;
      }

      paymentObject.payments.map(pay => {
        if (pay.docId === checkedDoc.id) {
          pay.amount = newAmount;
          pay.amountChanged = true;
          pay.displayAmount = e.target.value;
          if (pay.discount.valid) {
            pay.purpose = pay.docNo + Localize.get("DOCUMENTS.PAYMENT.INCL_3");
          }
        }

        return pay;
      });

      setPaymentObject({ ...paymentObject, payments: paymentObject.payments });
    } else {
      paymentObject.payments.map(pay => {
        if (pay.docId === checkedDoc.id) {
          pay[e.target.name] = e.target.value;
        }
        return pay;
      });

      if (e.target.name === "purpose" && !validator.isObjectEmpty({ purpose: e.target.value }))
        paymentObjectInstance.paymentValidator.parameter.purpose = null;

      setPaymentObject({ ...paymentObject, payments: paymentObject.payments });
    }

    paymentObjectInstance.isValidPayment =
      paymentObjectInstance.paymentValidator.validateForm(
        validatorConstants.checkFieldsPaymentForm,
        paymentObjectInstance
      ) && !paymentObjectInstance.checkAmount;

    paymentObjectInstance.paymentValidator.parameter = [];

    props.handler.paymentForm.onPaymentChange(e, {
      ...data,
      payments: [].concat(paymentObjectInstance)
    });
  };

  const handleOnBlur = e => {
    if (e && e.target && e.target.name === "amount") {
      if (!validator.isValidNumber(e.target.value)) {
        e.target.value = lastValidAmount;
        return;
      }

      let newAmount = formatAmount(e.target.value);
      paymentObjectInstance.zeroAmount =
        newAmount === "0" ||
        newAmount === "0." ||
        newAmount === "0.0" ||
        newAmount === "0.00" ||
        newAmount === 0 ||
        newAmount === null ||
        isNaN(newAmount);
    }
    setRefreshValidation(!refreshValidation);
  };

  const handleCreationDateClick = (day, modifiers = {}, event) => {
    if (modifiers.disabled) return true;

    event.stopPropagation();
    setCreationDateMenuOpen(false);
    setPrvExecutionDate(executionDate);
    setExecutionDate(day);
    setDateChangeDialog(true);
  };

  const onPayButtonClick = (e, data) => {
    paymentObject.payments.map(pay => {
      pay.amount = parseFloat(pay.amount).toString();
      pay.zeroAmount = pay.amount === "0" || pay.amount === null || isNaN(pay.amount);
      return pay;
    });
    let validationArray = [];
    validationArray = paymentObject.payments
      .filter(p => p.checked)
      .map(pay => ({
        isValidPayment:
          pay.paymentValidator.validateForm(validatorConstants.checkFieldsPaymentForm, {
            ...pay,
            accountId: selectedCompanyAccount.finApiId
          }) &&
          !pay.checkAmount &&
          !pay.zeroAmount,
        docId: pay.docId,
        docNo: checkedDocs.find(doc => doc.id === pay.docId).documentNumber
      }));
    let noDocsSelected = validationArray.length > 0 ? false : true;
    let allValid = validationArray.find(a => a.isValidPayment === false) ? false : true;

    if (noDocsSelected) {
      setOpenNoDocsDialog(true);
    } else if (allValid) {
      paymentObject.payments.map(doc => {
        doc.executionDate = paymentObject.executionDate;
        doc.accountId = paymentObject.accountId;
        doc.singleBooking = paymentObject.payments.filter(p => p.checked).length > 1 ? false : true;
        doc.isValidPayment = validator.validateForm(validatorConstants.checkFieldsPaymentForm, doc);
        doc.checked = checkedDocs.find(d => d.id === doc.docId).checked;
        doc.amount = parseFloat(doc.amount);
        return doc;
      });

      setPaymentObject({ ...paymentObject });
      setOpenPayDialog(true);
    } else {
      setOpenMsgPayDialog(true);
      setIncompletePayments(
        validationArray.filter(arr => !arr.isValidPayment).map(arr => arr.docNo)
      ); // --> build info message about what doc.number is invalid
    }
  };

  const cancelPayMode = () => {
    setOpenPayDialog(false);
  };

  const validEarlyPayDate = earlyPayDate => {
    return new Date(earlyPayDate).setHours(0, 0, 0, 0) >= executionDate.setHours(0, 0, 0, 0);
  };

  const onPayConfirmationButtonClick = (e, data) => {
    const servicePayment = new RestService.Payment.Payment.Service();
    servicePayment.setCallbackSuccess(callbackPaymentSuccess);
    servicePayment.setCallbackError(callbackPaymentError);
    servicePayment.setCallbackFinally(callbackPaymentFinaly);
    servicePayment.setPayload(paymentObject.payments.filter(p => p.checked));
    servicePayment.call();
  };

  const callbackPaymentSuccess = response => {
    if (response.data.formUrl) {
      setOpenUrlDialog(true);
      setBankLink(response.data.formUrl);
      setPaymentAccountId(response.data.webFormId);
    } else {
      setOpenPayDialog(false);
    }
  };

  const callbackPaymentError = response => {
    props.handler.setPaymentErrorNotification(
      response.response && response.response.data && response.response.data.message
        ? response.response.data.message
        : response.message
    );
    setOpenPayDialog(false);
  };

  const callbackPaymentFinaly = response => {};

  const closeUrlModal = () => {
    setOpenUrlDialog(false);
    props.setEditMode(false);
  };

  const closeMsgPayModal = () => {
    setOpenMsgPayDialog(false);
    setIncompletePayments([]);
  };

  const closeNoDocsModal = () => {
    setOpenNoDocsDialog(false);
  };

  const onLinkCLicked = () => {
    props.setEditMode(false);
    props.handler.onPayButtonClick(
      paymentAccountId,
      paymentObject.payments.filter(p => p.checked)
    );
  };

  const cancelUncheck = (e, data) => {
    props.handler.documentList.revertCheckItem(e, {
      ...data,
      item_id: uncheckedId
    });
    setOpenUncheckDialog(false);
  };

  const acceptUncheck = () => {
    setOpenUncheckDialog(false);
    setUncheckedId(0);
  };

  const cancelDateChange = () => {
    setDateChangeDialog(false);
    setExecutionDate(prvExecutionDate);
    setPaymentObject({
      ...paymentObject,
      executionDate: formatDate(prvExecutionDate)
    });
  };

  const acceptDateChange = () => {
    setDateChangeDialog(false);
    let newPayments = paymentObject.payments.map(pay =>
      validEarlyPayDate(pay.discount.earlyPayDate)
        ? pay
        : {
            ...pay,
            discount: { ...pay.discount, valid: false },
            displayAmount: pay.actions.PaymentRules.maxPayment,
            amount: pay.actions.PaymentRules.maxPayment,
            purpose: pay.docNo
          }
    );
    setPaymentObject({
      ...paymentObject,
      executionDate: formatDate(executionDate),
      payments: newPayments
    });
  };

  let documentGrid = paymentObject.payments
    .filter(p => p.checked)
    .map(pay => (
      <PaymentGrid
        key={pay.docId}
        accounts={accounts}
        paymentObject={paymentObject}
        payment={pay}
        currencyid={checkedDocs[0].currency.id}
      />
    ));

  return (
    <>
      <div className={styles.paymentFormStyle} style={props.editMode ? { zIndex: 1001 } : {}}>
        <Grid columns="equal">
          <Grid.Row divided className={styles.row_bottom}>
            <Grid.Column>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4> {Localize.get("DOCUMENTS.PAYMENT.FROM")}</h4>
                </List.Item>
                <List.Item className={styles.listItemMargin}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.OUR_COMPANY")}</List.Header>
                    <List.Description>{companyName.value}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.ACCOUNT")}</List.Header>
                    <List.Description>
                      <Dropdown
                        onChange={onCompanyAccountChange}
                        placeholder={Localize.get("DOCUMENTS.PAYMENT.SELECT_ACC")}
                        value={selectedCompanyAccount.id}
                        selection
                        fluid
                        options={companyAcountOptions}
                        className={styles.dropdownMargin}
                        style={{
                          border: paymentObjectInstance.paymentValidator.parameter.accountId
                            ? errorBorder
                            : ""
                        }}
                      />
                      {paymentObjectInstance.paymentValidator.parameter.accountId && (
                        <Label
                          className={styles.payment_labele}
                          basic
                          color="red"
                          pointing
                          content={paymentObjectInstance.paymentValidator.parameter.accountId}
                        />
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>IBAN</List.Header>
                    <List.Description>
                      <Input
                        fluid
                        disabled
                        placeholder="IBAN"
                        value={selectedCompanyAccount.iban}
                      />
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>BIC</List.Header>
                    <List.Description>
                      <Input fluid disabled placeholder="BIC" value={selectedCompanyAccount.bic} />
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4>{Localize.get("DOCUMENTS.PAYMENT.TO")}</h4>
                </List.Item>
                <List.Item className={styles.listItemMargin}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.PARTNER")}</List.Header>
                    <List.Description>{partner.name}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.ACCOUNT")}</List.Header>
                    <List.Description>
                      <Dropdown
                        onChange={onPaymentAccountChange}
                        placeholder={Localize.get("DOCUMENTS.PAYMENT.SELECT_ACC")}
                        selection
                        search
                        fluid
                        options={partnerAcountOptions}
                        className={styles.dropdownMargin}
                        value={selectedPartnerAccount.id}
                      />
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>IBAN</List.Header>
                    <List.Description>
                      <Input fluid disabled value={selectedPartnerAccount.iban} />
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>BIC</List.Header>
                    <List.Description>
                      <Input fluid disabled value={selectedPartnerAccount.bic} />
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4>{Localize.get("DOCUMENTS.PAYMENT.PAYMENT")}</h4>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>
                      {" "}
                      {Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT")}
                    </List.Header>
                    <List.Description>
                      <Input
                        name="purpose"
                        fluid
                        value={formPurpose}
                        placeholder={Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT")}
                        onChange={handleOnChange}
                        style={{
                          border: paymentObjectInstance.paymentValidator.parameter.purpose
                            ? errorBorder
                            : ""
                        }}
                      />
                      {paymentObjectInstance.paymentValidator.parameter.purpose && (
                        <Label
                          className={styles.payment_labele}
                          basic
                          color="red"
                          pointing
                          content={paymentObjectInstance.paymentValidator.parameter.purpose}
                        />
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</List.Header>
                    <List.Description>
                      <Input
                        name="amount"
                        fluid
                        value={formAmount}
                        placeholder={Localize.get("GLOBAL.DETAILS.AMOUNT")}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                      />
                      {paymentObjectInstance.checkAmount && (
                        <Label
                          className={styles.payment_labele}
                          basic
                          color="red"
                          pointing
                          content={Localize.get("DOCUMENTS.PAYMENT.AMMOUNT_MSG")}
                        />
                      )}
                      {paymentObjectInstance.zeroAmount && (
                        <Label
                          className={styles.payment_labele}
                          basic
                          color="red"
                          pointing
                          content={Localize.get("DOCUMENTS.PAYMENT.AMOUNT_ZERO_MESSAGE")}
                        />
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>
                      {Localize.get("DOCUMENTS.PAYMENT.CURRENCY") + " : " + getCurrency()}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Checkbox
                    checked={paymentObjectInstance.discount.valid}
                    label={Localize.get("DOCUMENTS.PAYMENT.USE_EARLY_DISCOUNT")}
                    // disabled={!paymentObjectInstance.discount.earlyPayAmount}
                    disabled={!validEarlyPayDate(paymentObjectInstance.discount.earlyPayDate)}
                    onChange={onCheckboxChange}
                  />
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.DATE")}</List.Header>
                    <List.Description className={styles.globaldatedown_field}>
                      <GlobalDatedown
                        name="documentDate"
                        icon="calendar alternate outline"
                        isRequired={false}
                        dropdownName={"documentDate"}
                        handleOnClick={() => setCreationDateMenuOpen(true)}
                        handleOnBlur={() => setCreationDateMenuOpen(false)}
                        dropdownOpen={creationDateMenuOpen}
                        dropdownText={formatDateToString(executionDate)}
                        handleDateClick={handleCreationDateClick}
                        showLabel={false}
                        dropdownStyleError={"1px red solid"}
                        selectedDays={executionDate}
                        disableDropdown={paymentObjectInstance.discount.valid}
                        disabledDays={[
                          {
                            before: new Date()
                          }
                        ]}
                      />

                      <div className="ui calendar" id="calendar"></div>
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.rowPadding} textAlign="right">
            <Grid.Column>
              <GlobalOkCancelBtn
                onConfirmClicked={onPayButtonClick}
                disabledBtn={checkedDocs.length === 0}
                onCancel={props.handler.cancelPayMode}
                btnText={Localize.get("DOCUMENTS.PAYMENT.PROCEED")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal open={openUrlDialog} onClose={closeUrlModal} closeIcon>
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MESSAGE")}</Modal.Header>
          <Modal.Content>
            <p>
              <a onClick={onLinkCLicked} href={bankLink} target="_blank" rel="noopener noreferrer">
                {Localize.get("DOCUMENTS.PAYMENT.MESSAGE")}
              </a>
            </p>
          </Modal.Content>
        </Modal>

        <Modal open={openPayDialog} onClose={cancelPayMode} closeIcon>
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.REVIEW_DATA")}</Modal.Header>
          <Modal.Content scrolling className={styles.scrollingBar}>
            {documentGrid}
          </Modal.Content>
          <Modal.Actions>
            <GlobalOkCancelBtn
              onConfirmClicked={onPayConfirmationButtonClick}
              disabledBtn={checkedDocs.length === 0 || paymentObjectInstance.checkAmount === true}
              onCancel={cancelPayMode}
              btnText={Localize.get("DOCUMENTS.PAYMENT.PAY")}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={companyAccountChangeDialog}>
          <Modal.Header>
            {Localize.get("DOCUMENTS.PAYMENT.MODAL.COMPANY_CHANGE_HEADER")}
          </Modal.Header>
          <Modal.Content>
            {Localize.get("DOCUMENTS.PAYMENT.MODAL.COMPANY_CHANGE_BODY")}
          </Modal.Content>
          <Modal.Actions>
            <GlobalOkCancelBtn
              onConfirmClicked={acceptCompanyAccountChange}
              onCancel={cancelCompanyAccountChange}
              btnText={Localize.get("GLOBAL.CONFIRMATION.BUTTON_YES")}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={dateChangeDialog}>
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.DATE_CHANGE_HEADER")}</Modal.Header>
          <Modal.Content>{Localize.get("DOCUMENTS.PAYMENT.MODAL.DATE_CHANGE_BODY")}</Modal.Content>
          <Modal.Actions>
            <GlobalOkCancelBtn
              onConfirmClicked={acceptDateChange}
              onCancel={cancelDateChange}
              btnText={Localize.get("GLOBAL.CONFIRMATION.BUTTON_YES")}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={openUncheckDialog}>
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.EXCLUSION_HEADER")}</Modal.Header>
          <Modal.Content>{Localize.get("DOCUMENTS.PAYMENT.MODAL.EXCLUSION_BODY")}</Modal.Content>
          <Modal.Actions>
            <GlobalOkCancelBtn
              onConfirmClicked={acceptUncheck}
              onCancel={cancelUncheck}
              btnText={Localize.get("GLOBAL.CONFIRMATION.BUTTON_YES")}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={openMsgPayDialog} onClose={closeMsgPayModal} closeIcon size="small">
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.INCOMPLETE_HEADER")}</Modal.Header>
          <Modal.Content>
            <p>
              {Localize.get("DOCUMENTS.PAYMENT.MODAL.INCOMPLETE_BODY")}:<br />
              {"[" + incompletePayments.toString() + "]"}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <GlobalOkBtn onOkClicked={closeMsgPayModal} />
          </Modal.Actions>
        </Modal>

        <Modal open={openNoDocsDialog} onClose={closeNoDocsModal} closeIcon size="small">
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.NO_PAYMENTS_HEADER")}</Modal.Header>
          <Modal.Content>
            <p>{Localize.get("DOCUMENTS.PAYMENT.MODAL.NO_PAYMENTS_BODY")}</p>
          </Modal.Content>
          <Modal.Actions>
            <GlobalOkBtn onOkClicked={closeNoDocsModal} />
          </Modal.Actions>
        </Modal>
      </div>
    </>
  );
};
export default PaymentForm;
