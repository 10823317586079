import React, { useState } from "react";
import Localize from "react-intl-universal";
import { Accordion, Icon, Form, Input, Label } from "semantic-ui-react";
import * as validatorConstants from "../../Utils/Constant";
import { countriesList } from "../../Utils/Countries";
import * as constant from "../Constants.js";
import styles from "./style/ContactFormAddress.module.css";
import DropdownSelectMe from "./DropdownSelectMe";

const ContactFormAddress = props => {
  const { parameters, validateField } = props;
  const [activeAccordion, setActiveAccordion] = useState(props.open);

  const handleAccordionClick = (e, titleProps) => {
    return setActiveAccordion(!activeAccordion);
  };

  const [address, setAddress] = useState(props.address ? props.address : {});

  const handleOnChange = (e, data) => {
    let newAddr = { ...address };
    if (!data.value) {
      delete newAddr[data.name];
    } else {
      newAddr = { ...address, [data.name]: data.value };

      const validationOnField = parameters[data.name];
      if (validationOnField) {
        validateField(data.name, data.value);
      }
    }
    setAddress(newAddr);
    props.onChange(e, {
      name: props.name,
      value: newAddr
    });
  };

  const handleOnBlur = (e, data) => {
    let propName = data ? data.name : e.target.name;
    let propValue = data ? data.value : e.target.value;

    if (propName && propValue && propName in validatorConstants.formFieldsNewAddress) {
      validateField(propName, propValue);
    }
  };

  const handleFieldChange = (e, data) => {
    setAddress({ ...address, [data.name]: data.checked });
    props.onChange(e, { name: data.name, value: data.checked });
  };

  const errorBorder = "3px red solid";

  const handleCountryChange = value => {
    let newAddr = { ...address };
    newAddr = { ...address, country: value.key };

    const validationOnField = parameters["country"];
    if (validationOnField) {
      validateField("country", value.label);
    }

    setAddress(newAddr);
    props.onChange(undefined, {
      name: props.name,
      value: newAddr
    });
  };

  return (
    <>
      <Accordion className={styles.accordition_container} styled>
        <Accordion.Title active={activeAccordion} onClick={handleAccordionClick}>
          <Icon name="dropdown" />
          {props.title}
        </Accordion.Title>
        <Accordion.Content className={styles.content_lines} active={activeAccordion}>
          <Form.Field required={true}>
            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.STREET")}</label>
            <Input
              autoComplete={constant.autoComplete.none}
              name="street"
              style={{
                border: parameters && parameters.street ? errorBorder : ""
              }}
              value={address.street === undefined ? "" : address.street}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.STREET")}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
            {parameters && parameters.street && (
              <Label basic color="red" pointing content={parameters.street} />
            )}
          </Form.Field>
          <Form.Group className={styles.address_col} widths="equal">
            <Form.Field required={true}>
              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.CITY")}</label>
              <Input
                autoComplete={constant.autoComplete.off}
                name="city"
                style={{
                  border: parameters && parameters.city ? errorBorder : ""
                }}
                value={address.city === undefined ? "" : address.city}
                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.CITY")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
              {parameters && parameters.city && (
                <Label basic color="red" pointing content={parameters.city} />
              )}
            </Form.Field>
            <Form.Field required={true}>
              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.POSTCODE")}</label>
              <Input
                autoComplete={constant.autoComplete.none}
                name="zip"
                style={{
                  border: parameters && parameters.zip ? errorBorder : ""
                }}
                value={address.zip === undefined ? "" : address.zip}
                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.POSTCODE")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
              {parameters && parameters.zip && (
                <Label basic color="red" pointing content={parameters.zip} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group className={styles.address_col} widths="equal">
            <Form.Field>
              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.STATE")}</label>
              <Input
                autoComplete={constant.autoComplete.none}
                name="state"
                style={{
                  border: parameters && parameters.state ? errorBorder : ""
                }}
                value={address.state ? address.state : ""}
                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.STATE")}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
              />
              {parameters && parameters.state && (
                <Label basic color="red" pointing content={parameters.state} />
              )}
            </Form.Field>
            <Form.Field className={styles.dropdown_field} required={true}>
              <DropdownSelectMe
                labelText={Localize.get("CONTACTS.NEWCONTACT.FORM.COUNTRY")}
                fieldName="country"
                options={countriesList()}
                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.COUNTRY")}
                listMaxHeight={200}
                parameters={parameters}
                value={props.address && props.address.country ? props.address.country : ""}
                onChange={handleCountryChange}
              />
            </Form.Field>
          </Form.Group>
          {props.name === "address" && !props.needsBilling && (
            <>
              <Form.Group className={styles.is_billing_is_shipping} widths="equal">
                <Form.Field>
                  <Form.Checkbox
                    className={styles.checkbox_resize}
                    name="asBillingAddress"
                    checked={address.asBillingAddress}
                    label={Localize.get("CONTACTS.NEWCONTACT.FORM.IS_BILLINGADDRESS_LABEL")}
                    onChange={handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group className={styles.is_billing_is_shipping}>
                <Form.Field>
                  <Form.Checkbox
                    className={styles.checkbox_resize}
                    name="asShippingAddress"
                    checked={address.asShippingAddress}
                    label={Localize.get("CONTACTS.NEWCONTACT.FORM.IS_SHIPTOADDRESS_LABEL")}
                    onChange={handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
            </>
          )}
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default ContactFormAddress;
