import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";
import GlobalOkBtn from "../Components/GlobalOkBtn";

const ErrorDeleteDialog = props => {
  const getDialogError = () => {
    let dialog = null;
    let confirmHandler = props.handle.handleOnConfirmButtonClick;
    let modalColor = "#c00";
    let modalHeader = Localize.get("MODAL.UNSUCCESSFUL_DELETE");
    if (props.state) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
          </Modal.Header>
          <Modal.Actions>
            <GlobalOkBtn onOkClicked={confirmHandler} />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  return <>{getDialogError()}</>;
};

export default ErrorDeleteDialog;
