import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  addSearchParam(value) {
    this.parameter.search = value;
    return this;
  }
  clearSearchParam() {
    delete this.parameter.search;
    return this;
  }
  addSearchMatchParam(value) {
    this.parameter.match = value;
    return this;
  }
  addSearchProjectParam(value) {
    this.parameter.search = value;
    return this;
  }
  clearSearProjectParam() {
    delete this.parameter.search;
    return this;
  }
  clearSearchMatchParam() {
    delete this.parameter.match;
    return this;
  }

  addProjectArchiveStatusArchived() {
    this.parameter.archived = constant.PROJECT.archived.archived;
    return this;
  }

  addProjectStatusNew() {
    this.parameter.status = constant.PROJECT.status.new;
    return this;
  }

  addProjectStatusClosed() {
    this.parameter.status = constant.PROJECT.status.closed;
    return this;
  }
  addProjectStatusProcess() {
    this.parameter.status = constant.PROJECT.status.process;
    return this;
  }

  addCustomColumnSearch(column, value) {
    this.parameter[column] = value;
    return this;
  }
  clearCustomColumnSearch(value) {
    delete this.parameter[value];
    return this;
  }
  addSortColumnParam(value) {
    this.parameter.sortBy = value;
    return this;
  }
  clearSortColumnParam(value) {
    delete this.parameter.sortBy;
    return this;
  }
  addSortDirectionParam(value) {
    this.parameter.sortDirection = value;
    return this;
  }
  clearSortDirectionParam(value) {
    delete this.parameter.sortDirection;
    return this;
  }
  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.projects
    };
    this.query = new CustomQuery.Builder();
  }
}
