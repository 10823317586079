import React from "react";
import { Divider, Item, Grid } from "semantic-ui-react";
import Localize from "react-intl-universal";
import propTypes from "prop-types";

const GlobalDetailsTextItem = props => {
  const { itemName, itemValue, hideDivider = false } = props;
  return (
    <>
      <Item>
        <Grid container columns={2}>
          <Grid.Column width={6}>{Localize.get(itemName)}</Grid.Column>
          <Grid.Column floated="right" width={9} textAlign="right">
            {itemValue}
          </Grid.Column>
        </Grid>
      </Item>
      {!hideDivider ? <Divider /> : null}
    </>
  );
};

GlobalDetailsTextItem.prototype = {
  itemName: propTypes.string,
  itemValue: propTypes.string
};

export default GlobalDetailsTextItem;
