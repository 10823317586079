export const bookingOverviewModule = {
  allBookings: "allBookings",
  expense: "expense",
  revenue: "revenue",
  fixed: "fixed",
  corrections: "corecctions"
};

export const initialFilterObject = { allBookings: true };
export const initialFilterAccountObject = { allAccounts: true };

export const accountOverviewModule = {
  allAccounts: "allAccounts",
  glAccounts: "glAccounts",
  personalAccounts: "personalAccounts"
};

export const panelView = {
  create: "create",
  edit: "edit",
  details: "details",
  display: "display",
  list: "list"
};

export const paginationInitStatus = {
  perPage: 0,
  pageCount: 0,
  totalCount: 0
};
