import { useState } from "react";
import useFetch from "./useFetch";
import usePostOrPatch from "./usePostOrPatch";
import showNotification from "../Notifications/NotificationFunction";
import Localize from "react-intl-universal";
import { fetchSettingsAccount, patchOrPostService, fetchTemplates } from "./AccountingServices";

const useAccountingServices = (setCurrentView = function() {}, currentView) => {
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [editedAccounts, setEditedAccounts] = useState([]);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const callbackFetchError = err => {
    showNotificationMessage({
      type: "warning",
      operation: Localize.get("CONTACTS.LIST.TOOLBAR.GET_PARTNERS_FAILED")
    });
  };

  const getPageAndSize = (myView, myPage) => {
    if (myView === "details") {
      return {};
    } else {
      return { page: myPage - 1, size: 54 };
    }
  };

  const cbFetchAccountsSuccess = (response, setData) => {
    let newAccountsList = response.data.map(item => {
      let myEditedItem = editedAccounts.find(
        editedItem => editedItem.accountKey === item.accountKey
      );
      if (myEditedItem) {
        item.enabled = myEditedItem.operation[0].value;
      }
      return item;
    });
    setData(newAccountsList);
  };

  const [
    customChartAccountsList,
    paging,
    fetchCustomChartLoading,
    fetchCustomChartCall,
    setCustomChart
  ] = useFetch(
    fetchSettingsAccount,
    [],
    "/accountings/charts/3/accounts/",
    getPageAndSize(currentView, activePaginationPage),
    cbFetchAccountsSuccess,
    callbackFetchError
  );

  const [
    listOfTemplates,
    ,
    fetchTemplatesLoading,
    fetchTemplatesCall,
    setListOfTemplates
  ] = useFetch(
    fetchTemplates,
    [],
    "/accountings/charts/?type=TEMPLATE",
    {},
    undefined,
    callbackFetchError
  );

  const [docNumberingList, , fetchDocNumberingLoading, fetchDocNumberingCall] = useFetch(
    fetchTemplates,
    [],
    "/documents/numbers",
    {},
    undefined,
    callbackFetchError
  );

  const [
    partnerNumberingList,
    ,
    fetchPartnerNumberingLoading,
    fetchPartnerNumberingCall,
    setPartnerNumberingList
  ] = useFetch(fetchTemplates, [], "/partners/numbers", {}, undefined, callbackFetchError);

  const cbPatchOrPostSuccess = response => {
    setCurrentView("details");
  };

  const cbPatchOrPostError = err => {
    showNotificationMessage({
      type: "warning",
      operation: Localize.get("CONTACTS.NEWCONTACT.FORM.EDIT_FAILED")
    });
  };

  const [patchOrPostCall, patchOrPostLoading] = usePostOrPatch(
    patchOrPostService,
    cbPatchOrPostSuccess,
    cbPatchOrPostError
  );

  return {
    customChartAccountsList,
    setCustomChart,
    listOfTemplates,
    setEditedAccounts,
    editedAccounts,
    paging,
    fetchCustomChartLoading,
    fetchCustomChartCall,
    fetchTemplatesCall,
    setActivePaginationPage,
    patchOrPostCall,
    patchOrPostLoading,
    fetchTemplatesLoading,
    activePaginationPage,
    setListOfTemplates,
    docNumberingList,
    fetchDocNumberingLoading,
    fetchDocNumberingCall,
    partnerNumberingList,
    fetchPartnerNumberingLoading,
    fetchPartnerNumberingCall,
    setPartnerNumberingList
  };
};

export default useAccountingServices;
