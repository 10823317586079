import React, { useState, useEffect, useRef } from "react";
import Localize from "react-intl-universal";
import { Segment, Icon, Message } from "semantic-ui-react";
import ContactFormAccount from "./ContactFormAccount";
import styles from "./style/ContactFormAccounts.module.css";
import usePreviousState from "../../CustomHooks/usePreviousState";

const ContactFormAccounts = props => {
  const {
    accounts,
    validateAccountsListField,
    parametersAccounts,
    handleOnChangeList,
    deleteItemFromList,
    addItemToList,
    checkEditedFields,
    addAccountInValidator,
    removeAccountFromValidator
  } = props;

  const previousAccounts = usePreviousState(accounts);

  const accountEl = useRef(null);

  const [activeAccordionArray, setActiveAccordionArray] = useState(accounts.map(() => false));

  const handleOnPlusClick = () => {
    addAccountInValidator({});
    addItemToList("accounts", {});
    checkEditedFields("accounts");
  };

  const handler = {
    accordionHandler: index => {
      let newElement = !activeAccordionArray[index];
      let newArray = [...activeAccordionArray];
      newArray.splice(index, 1, newElement);
      setActiveAccordionArray(newArray);
    }
  };

  const handleDelete = index => {
    removeAccountFromValidator(index);
    deleteItemFromList("accounts", index);
    checkEditedFields("accounts");
  };

  const handleOnChange = (index, account) => {
    handleOnChangeList("accounts", account, index);
    checkEditedFields("accounts");
  };

  useEffect(() => {
    if (
      accountEl.current !== null &&
      previousAccounts &&
      previousAccounts.length < accounts.length
    ) {
      accountEl.current.scrollIntoView();
    }
  }, [accounts]);

  const accountForms = () =>
    accounts.map((a, index) => (
      <ContactFormAccount
        ref={accountEl}
        validator={parametersAccounts[index]}
        key={index}
        index={index}
        account={a}
        onChange={handleOnChange}
        handleDelete={handleDelete}
        handler={handler}
        activeAccordion={activeAccordionArray[index]}
        title={Localize.get("CONTACTS.NEWCONTACT.FORM.ACCOUNTS_TITLE")}
        validateAccountsListField={validateAccountsListField}
      />
    ));
  return (
    <Segment.Group>
      <Segment className={styles.title}>
        <div>
          <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.BANK_ACCOUNTS")}</strong>
        </div>
        <div className={styles.formAddButton}>
          <Icon name="plus" onClick={handleOnPlusClick} />
        </div>
      </Segment>
      <Segment className={styles.form_lines}>
        {accounts && accounts.length > 0 ? (
          accountForms()
        ) : (
          <Message
            className={styles.message_block}
            attached="bottom"
            header={Localize.get("CONTACTS.NEWCONTACT.FORM.NO_ACCOUNTS")}
            content={Localize.get("CONTACTS.NEWCONTACT.FORM.CLICK_PLUS_ACCOUNT")}
          />
        )}
      </Segment>
    </Segment.Group>
  );
};

export default ContactFormAccounts;
