import React, { useState } from "react";
import { Accordion, Icon, Transition } from "semantic-ui-react";
import styles from "./style/InvoiceSettingsDetails.module.css";
import GlobalDetailsTextItem from "../../Components/Details/GlobalDetailsTextItem";

const NotifierDetails = ({ notifier }) => {
  const [activeAccordion, setActiveAccordion] = useState(false);
  return (
    <Accordion className={styles.accordition_container} styled>
      <Accordion.Title
        active={activeAccordion}
        onClick={() => setActiveAccordion(!activeAccordion)}
      >
        <Icon className={styles.dropdown_icon} name="dropdown" />
        {notifier.email}
      </Accordion.Title>
      <Transition visible={activeAccordion} animation="fade down" duration={300}>
        <Accordion.Content className={styles.ac_content} active={activeAccordion}>
          <GlobalDetailsTextItem
            itemName={"CONTACTS.NEWCONTACT.FORM.NOTIFIER_EMAIL"}
            itemValue={notifier.email}
          />
          <GlobalDetailsTextItem
            itemName={"CONTACTS.NEWCONTACT.FORM.NOTIFIER_OWNER"}
            itemValue={notifier.first}
          />
          <GlobalDetailsTextItem
            itemName={"CONTACTS.NEWCONTACT.FORM.JOB_TITLE"}
            itemValue={notifier.jobTitle}
          />
          <GlobalDetailsTextItem
            itemName={"CONTACTS.NEWCONTACT.FORM.NOTIFIER_PHONE"}
            itemValue={notifier.phone}
          />
        </Accordion.Content>
      </Transition>
    </Accordion>
  );
};

export default NotifierDetails;
