import React from "react";
import styles from "./style/DocumentShowScan.module.css";

const ShowScan = props => {
  return (
    <div id="showScan">
      <div className={`ui middle aligned divided list ${styles.documentPreviewDetails}`}>
        <div className="item" id={styles.document_preview_center}>
          <div>
            {props.linkedDocumentsPreview && !props.linkedDocumentsPreview.isPDF && (
              <div>
                <img
                  className={styles.documentImagePreviewContainer}
                  src={props.linkedDocumentsPreview.data}
                  alt=""
                />
              </div>
            )}
            {props.linkedDocumentsPreview && props.linkedDocumentsPreview.isPDF && (
              <object
                className={styles.documentPreviewDetails}
                data={props.linkedDocumentsPreview.data}
                type="application/pdf"
              >
                alt : ''
              </object>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowScan;
