import React from "react";
import { Divider, Item, Grid, Checkbox, Icon, Input, Form } from "semantic-ui-react";
import styles from "./style/GlobalDetailsItem.module.css";
import Localize from "react-intl-universal";

const GlobalDetailsItem = props => {
  const {
    itemName = "",
    itemValue = "",
    type = "empty",
    iconName = "",
    iconHeandler = function() {},
    totalDays = ""
  } = props;

  const getType = myType => {
    switch (myType) {
      case "text":
        return (
          <>
            <Grid.Column width={6} verticalAlign="middle">
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column
              className={styles.text_three_dots}
              floated="right"
              width={9}
              textAlign="right"
            >
              {itemValue}
            </Grid.Column>
          </>
        );
      case "template_search":
        return (
          <>
            <Grid.Column width={6} verticalAlign="middle">
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column
              className={styles.text_three_dots}
              floated="right"
              width={9}
              textAlign="right"
            >
              {itemValue}
            </Grid.Column>
          </>
        );
      case "boolean":
        return (
          <>
            <Grid.Column width={6}>
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column floated="right" width={5} align="right">
              <Checkbox value={itemValue} checked={itemValue === "true"} toggle disabled />
            </Grid.Column>
          </>
        );
      case "input":
        return (
          <>
            <Grid.Column width={6} verticalAlign="middle">
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column floated="right" width={4} align="right">
              <Form>
                <div className={styles.above_input}>
                  {Localize.get("PROPERTIES.FIELDS.overdue_for_days")}
                </div>
                <Input value={itemValue} disabled className={styles.item_input} />
                <div className={styles.below_input}>
                  <span>{totalDays} </span>
                  {Localize.get("PROPERTIES.FIELDS.days_total")}
                </div>
              </Form>
            </Grid.Column>
          </>
        );
      case "password":
        return (
          <>
            <Grid.Column width={6}>
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column floated="right" width={9} textAlign="right">
              <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226; </div>
            </Grid.Column>
          </>
        );
      case "icon":
        return (
          <>
            <Grid.Column width={6}>
              {Localize.get(itemName !== "" ? itemName : "PROPERTIES.EMPTY_FIELD")}
            </Grid.Column>
            <Grid.Column floated="right" width={9} textAlign="right">
              <Icon name={iconName} size="large" onClick={iconHeandler} />
            </Grid.Column>
          </>
        );
      case "info":
        return (
          <>
            <Grid.Column textAlign="center" className={styles.info_column}>
              <Grid>
                <Grid.Column width={3} />
                <Grid.Column width={1} textAlign="left">
                  <Icon name="info" size="large" />
                </Grid.Column>
                <Grid.Column width={9} textAlign="left">
                  <h5>{Localize.get("PROPERTIES.FIELDS.overdue_info_header")}</h5>
                  <div className={styles.info_content}>
                    {Localize.get("PROPERTIES.FIELDS.overdue_info_content")}
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Item>
        <Grid className={styles.grid_style} container>
          {getType(type)}
        </Grid>
      </Item>
      <Divider />
    </>
  );
};

export default GlobalDetailsItem;
