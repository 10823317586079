import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  addPartnerTypeEmployee() {
    this.parameter.partnerType = constant.PARTNER.type.employee;
    return this;
  }
  addPartnerTypePerson() {
    this.parameter.partnerType = constant.PARTNER.type.person;
    return this;
  }
  addPartnerTypeCompany() {
    this.parameter.partnerType = constant.PARTNER.type.company;
    return this;
  }
  clearPartnerType() {
    delete this.parameter.partnerType;
    return this;
  }
  addPartnerRoleCustomer() {
    this.parameter.partnerRole = constant.PARTNER.role.customer;
    return this;
  }
  addPartnerRoleSupplier() {
    this.parameter.partnerRole = constant.PARTNER.role.supplier;
    return this;
  }
  addPartnerRoleCustomerSupplier() {
    this.parameter.partnerRole = constant.PARTNER.role.customer_supplier;
    return this;
  }
  clearPartnerRole() {
    delete this.parameter.partnerRole;
    return this;
  }
  addArchivedYes() {
    this.parameter.archived = constant.PARTNER.archived.yes;
    return this;
  }
  clearArchived() {
    delete this.parameter.archived;
    return this;
  }
  addSearchParam(value) {
    this.parameter.search = value;
    return this;
  }
  clearSearchParam() {
    delete this.parameter.search;
    return this;
  }
  addSearchColumnParam(value) {
    this.parameter.searchColumn = value;
    return this;
  }
  clearSearchColumnParam() {
    delete this.parameter.searchColumn;
    return this;
  }
  addSearchNameParam(value) {
    this.parameter.name = value;
    return this;
  }
  clearSearchNameParam() {
    delete this.parameter.name;
    return this;
  }
  addSearchMatchParam(value) {
    this.parameter.match = value;
    return this;
  }
  clearSearchMatchParam() {
    delete this.parameter.match;
    return this;
  }
  addSortColumnParam(value) {
    this.parameter.sortBy = value;
    return this;
  }
  clearSortColumnParam(value) {
    delete this.parameter.sortBy;
    return this;
  }
  addCustomColumnSearch(column, value) {
    this.parameter[column] = value;
    return this;
  }
  clearCustomColumnSearch(value) {
    delete this.parameter[value];
    return this;
  }
  addSortDirectionParam(value) {
    this.parameter.sortDirection = value;
    return this;
  }
  clearSortDirectionParam() {
    delete this.parameter.sortDirection;
    return this;
  }
  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.partners
    };
    this.query = new CustomQuery.Builder();
  }
}
