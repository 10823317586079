import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./style/welcomepage-styles.css";
import financeImg from "../Assets/Images/financeCarousel3.jpg";
import documentsImg from "../Assets/Images/documentsCarousel.jpg";
import contactsImg from "../Assets/Images/bpCarousel.jpg";
import Localize from "react-intl-universal";

const content = [
  {
    title: "WELCOME_PAGE.SLIDER_TEXT1",
    description: "WELCOME_PAGE.SLIDER_DESCRIPTION1",
    image: financeImg
  },
  {
    title: "WELCOME_PAGE.SLIDER_TEXT2",
    description: "WELCOME_PAGE.SLIDER_DESCRIPTION2",
    image: documentsImg
  },
  {
    title: "WELCOME_PAGE.SLIDER_TEXT3",
    description: "WELCOME_PAGE.SLIDER_DESCRIPTION3",
    image: contactsImg
  }
];
const SliderComponenet = () => (
  <div>
    <Slider autoplay={5000} className="slider-wrapper">
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url('${item.image}') no-repeat center center` }}
        >
          <div className="inner">
            <h1>{Localize.get(item.title)}</h1>
            <p>{Localize.get(item.description)}</p>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default SliderComponenet;
