import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";

const OpenFilterModule = props => {
  const { filter, onFilterClick, activeFilter } = props;

  return (
    <>
      <Menu.Item>
        <Menu.Header>{Localize.get(filter.headerName)}</Menu.Header>
        <Menu.Menu>
          {filter.filterItems.map((item, index) => (
            <Menu.Item
              key={index}
              name={item.name}
              onClick={onFilterClick}
              active={activeFilter === item.name}
              color={item.color ? item.color : null}
            >
              <Icon name={item.iconName} className={item.iconStyle ? item.iconStyle : ""} />
              {Localize.get(item.itemName)}
            </Menu.Item>
          ))}
        </Menu.Menu>
      </Menu.Item>
    </>
  );
};

export default OpenFilterModule;
