import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Accordion, List, Icon, Transition } from "semantic-ui-react";
import styles from "./style/DetailsAccount.module.css";

const DetailsAccount = props => {
  const [activeAccordion, setActiveAccordion] = useState(props.open);

  const handleAccordionClick = (e, titleProps) => {
    return setActiveAccordion(!activeAccordion);
  };

  useEffect(() => {
    setActiveAccordion(props.open);
  }, [props.account]);
  return props.account !== undefined ? (
    <Accordion styled className={`${styles.account_details} ${styles.details_align_long_name}`}>
      <Accordion.Title
        className={styles.accounts_title}
        active={activeAccordion}
        onClick={handleAccordionClick}
      >
        <Icon name="dropdown" />
        {Localize.get("CONTACTS.DETAILS.FORM.BANK")}{" "}
        {props.account.bankName ? "- " + props.account.bankName : ""}
      </Accordion.Title>
      <Transition visible={activeAccordion} animation="fade down" duration={300}>
        <Accordion.Content active={activeAccordion}>
          <List divided verticalAlign="middle" className={styles.details_devided}>
            <List.Item>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.OWNER")}</strong>
              </List.Content>
              <List.Content>{props.account.accountOwner}</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <strong>IBAN</strong>
              </List.Content>
              <List.Content>{props.account.iban}</List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <strong>BIC</strong>
              </List.Content>
              <List.Content>{props.account.bic}</List.Content>
            </List.Item>
          </List>
        </Accordion.Content>
      </Transition>
    </Accordion>
  ) : null;
};

export default DetailsAccount;
