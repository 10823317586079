export const doc = [
  {
    name: "inv",
    hederName: "PROPERTIES.FIELDS.invoice_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "ann",
    hederName: "PROPERTIES.FIELDS.ann",
    type: "template_search",
    isRequired: true
  },
  {
    name: "off",
    hederName: "PROPERTIES.FIELDS.offer_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "ord",
    hederName: "PROPERTIES.FIELDS.order_template",
    type: "template_search"
  },
  {
    name: "del",
    hederName: "PROPERTIES.FIELDS.delivery_note_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "inv-cor",
    hederName: "PROPERTIES.FIELDS.correction_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "rec",
    hederName: "PROPERTIES.FIELDS.receipt_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "inq",
    hederName: "PROPERTIES.FIELDS.inquiry_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "oth",
    hederName: "PROPERTIES.FIELDS.other_template",
    type: "template_search",
    isRequired: true
  }
];

export const dun = [
  {
    name: "dun0",
    hederName: "PROPERTIES.FIELDS.payment_reminder_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "dun1",
    hederName: "PROPERTIES.FIELDS.dunning_1_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "dun2",
    hederName: "PROPERTIES.FIELDS.dunning_2_template",
    type: "template_search"
  },
  {
    name: "dun3",
    hederName: "PROPERTIES.FIELDS.dunning_3_template",
    type: "template_search",
    isRequired: true
  }
];

export const email = [
  {
    name: "mail-inv",
    hederName: "PROPERTIES.FIELDS.invoice_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "mail-inv-cor",
    hederName: "PROPERTIES.FIELDS.correction_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "mail-dun0",
    hederName: "PROPERTIES.FIELDS.payment_reminder_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "mail-dun1",
    hederName: "PROPERTIES.FIELDS.dunning_1_template",
    type: "template_search",
    isRequired: true
  },
  {
    name: "mail-dun2",
    hederName: "PROPERTIES.FIELDS.dunning_2_template",
    type: "template_search"
  },
  {
    name: "mail-dun3",
    hederName: "PROPERTIES.FIELDS.dunning_3_template",
    type: "template_search",
    isRequired: true
  }
];
