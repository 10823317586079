import React from "react";
import { Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DocNewProjectTable.module.css";

const DocNewProjectTable = props => {
  const {
    projects,
    projectNameText,
    projectNumberText,
    projectExternalNumberText,
    projectAmountText,
    projectDescription
  } = props;

  return (
    <>
      <table className={`ui celled table conpact ${styles.new_project_table_header}`}>
        <thead>
          <tr>
            <th>{projectNameText}</th>
            <th>{projectNumberText}</th>
            <th>{projectExternalNumberText}</th>
            <th>{projectAmountText}</th>
            <th>{projectDescription}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {projects.length > 0 ? (
            projects.map(project => (
              <tr key={project.id}>
                <td>{project.projectname}</td>
                <td>{project.id}</td>
                <td>{project.projectexternalnumber}</td>
                <td>{project.amount}</td>
                <td>{project.description}</td>
                <td className={styles.icons}>
                  <Icon
                    className={styles.edit_icon}
                    name="edit"
                    onClick={() => {
                      props.editRow(project);
                    }}
                  />
                  <Icon
                    className={styles.minus_circle_icon}
                    name="minus circle"
                    disabled={props.editing}
                    style={{ marginLeft: "5px" }}
                    onClick={() => props.deleteProject(project.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{Localize.get("DOCUMENTS.NEWDOCUMENT.NO_DATA")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default DocNewProjectTable;
