import { useState } from "react";
import propTypes from "prop-types";

const useArchiveFilter = (state = false, setActivePaginationPage = function(arg) {}) => {
  const [active, setActive] = useState(state);
  const resetArchive = () => {
    setActive(false);
  };

  const bind = {
    active,
    onClick: e => {
      e.preventDefault();
      setActive(!active);
      setActivePaginationPage(1);
    }
  };
  return [active, bind, resetArchive];
};

useArchiveFilter.propTypes = {
  state: propTypes.bool,
  setActivePaginationPage: propTypes.func
};
export default useArchiveFilter;
