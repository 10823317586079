import React, { useEffect, useState } from "react";
import styles from "./style/DocumentDiagram.module.css";
import Localize from "react-intl-universal";
import { Grid, Checkbox, Container, Icon, Button, Label } from "semantic-ui-react";
import { formatDateDisplay, getDocumentDataType } from "../Util.js";
import * as constant from "../Constants.js";

const NodeInnerCustom = ({ node }) => {
  const [selectedDoc, setSelectedDoc] = useState(node.checked);
  const selectDocument = (e, data) => {
    node.handler(node.documentData.binaryContent.id);
  };
  useEffect(() => {
    setSelectedDoc(node.checked);
  }, [node.checked]);

  useEffect(() => {
    if (node.type === "output-only") {
      let mainDocument = document.getElementById("masterDocument");
      mainDocument.scrollIntoView({ inline: "center" });
    }
  }, []);

  return (
    <div className={styles.outer} id="masterDocument">
      <Grid columns="2" stackable container>
        <Grid.Row columns={1} centered className={styles.nodeContentRow}>
          <Grid.Column>
            {node.documentData.docLevel === "children" ? (
              <Button
                disabled
                icon
                labelPosition="right"
                className={styles.preview_button}
                onClick={() => node.viewFlow(node.documentData.id)}
              >
                {Localize.get("DOCUMENTFLOW.VIEW_FLOW")}
                <Icon name="arrow down" />
              </Button>
            ) : null}
            {node.documentData.docLevel === "parent" ? (
              <Button
                disabled
                icon
                labelPosition="right"
                className={styles.preview_button_2}
                onClick={() => node.viewFlow(node.documentData.id)}
              >
                {Localize.get("DOCUMENTFLOW.GO_UP")}
                <Icon name="arrow up" />
              </Button>
            ) : null}
            {node.documentData.docLevel === "current" ? (
              <Label
                className={styles.selected_lable}
                basic
                content={Localize.get("DOCUMENTFLOW.SELECTED_DOCUMENT")}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} centered className={`${styles.nodeContentRow} ${styles.pdf_icon}`}>
          <Grid.Column>
            <Icon name="file pdf" size="big" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={styles.nodeContentRow}>
          <Grid.Column>
            <Container>
              <p>{Localize.get("DOCUMENTFLOW.NUMBER")}</p>
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Container className={styles.doc_number}>
              <p>{node.documentData.docNo}</p>
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={styles.nodeContentRow}>
          <Grid.Column>
            <Container>
              <p>{Localize.get("DOCUMENTFLOW.DATE")}</p>
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Container>
              <p>{formatDateDisplay(node.documentData.date)}</p>
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={styles.nodeContentRow}>
          <Grid.Column>
            <Container>
              <p>{Localize.get("DOCUMENTFLOW.TYPE")}</p>
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Container>
              <p>{getDocumentDataType(node.documentData.type)}</p>
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={styles.nodeContentRow}>
          <Grid.Column>
            <Container>
              <p>{Localize.get("DOCUMENTFLOW.TRANSACTION")}</p>
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Container>
              <p>
                {node.documentData.direction === constant.documentDirection.incoming
                  ? Localize.get("TRANSACTIONS.DIRECTION.REVENUE")
                  : Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")}
              </p>
            </Container>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1} centered className={styles.nodeContentRow}>
          <Grid.Column>
            <Checkbox
              className={styles.checkbox_padding}
              onChange={selectDocument}
              label={Localize.get("DOCUMENTFLOW.SELECT_FOR_COMPARE")}
              checked={selectedDoc}
              disabled
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default NodeInnerCustom;
