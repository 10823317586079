import React, { useState, useEffect, useContext } from "react";
import BankAccountsSideBar from "./BankAccountsSideBar";
import BankAccountsItemPanel from "./BankAccountsItemPanel";
import BankAccountsPaypalPanel from "./BankAccountsPaypalPanel";
import BankAccountsItemList from "./BankAccountsItemList";
import { AuthContext } from "../../Auth/AuthContext";
import { Accounts } from "../../RestService";
import Localize from "react-intl-universal";
import { GetUpdateObject, ReactivateAccount } from "./Util.js";
import showNotification from "../../Notifications/NotificationFunction";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog.js";
import ErrorDeleteDialog from "../../Dialogs/ErrorDeleteDialog.js";
import { Modal, Loader, Dimmer, Sidebar, Segment } from "semantic-ui-react";
import styles from "./style/BankAccounts.module.css";
import { refreshLinkInit } from "../Constants";

const BankAccounts = props => {
  const { setHeaderDimmed } = props;

  // eslint-disable-next-line no-unused-vars
  const { authState, rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["account"];
  const [isLoading, setIsLoading] = useState(false);
  const [linkedTransactionsToAccount, setLinkedTransactionsToAccount] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const panelMode = {
    edit: "edit",
    create: "create"
  };

  const panelInitState = {
    activeMode: panelMode.create
  };

  const [isNewAccountClicked, setisNewAccountClicked] = useState(false);
  const [isPayPalAccClicked, setIsPayPalAccClicked] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const [panelState, setPanelState] = useState(panelInitState);
  const [deleteItemid, setDeleteItemId] = useState();

  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [showInfoMessage, setShowInfoMessage] = useState();
  // eslint-disable-next-line no-unused-vars
  const [showErrorMessage, setShowErrorMessage] = useState();

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isNewAccountCreated, setIsNewAccountCreated] = useState(false);

  const [stopFetching, setStopFetching] = useState(false);
  const [fetchCount, setFetchCount] = useState(false);
  const [openUrlDialog, setOpenUrlDialog] = useState(false);
  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);
  const [bankLink, setBankLink] = useState("");
  const [paypalLink, setPaypalLink] = useState("");
  const [refreshLink, setRefreshLink] = useState(refreshLinkInit);
  const [newAccountData, setNewAccountData] = useState(null);
  const [newPaypalAccountData, setPaypalNewAccountData] = useState(null);

  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const accountListInitState = {
    activeListItem: accounts.length > 0 ? "ItemList_" + accounts[0].id : ""
  };

  const heandleLink = () => {
    setOpenRefreshDialog(false);
    getRefreshService(refreshLink.webFormId, refreshLink.accountId);
  };

  const callbackGetAllSuccess = response => {
    const accountList = response.data;
    const sortedList = accountList.sort(function(a, b) {
      return b.id - a.id;
    });
    setAccounts(sortedList);

    if (fetchCount === accountList.length) {
      setStopFetching(true);
    }
  };

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {
    setIsLoading(false);
  };

  const callbackDeleteSuccess = response => {
    const indexOfDeletedItem = accounts.findIndex(function(element, index, array) {
      return element.id === deleteItemid;
    });

    if (accounts.length - 1 !== indexOfDeletedItem) {
      setAccountListState({
        ...accountListState,
        activeListItem: accounts.length > 0 ? "ItemList_" + accounts[indexOfDeletedItem + 1].id : ""
      });
    } else {
      setAccountListState({
        ...accountListState,
        activeListItem:
          accounts.length > 0 && indexOfDeletedItem > 0
            ? "ItemList_" + accounts[indexOfDeletedItem - 1].id
            : ""
      });
    }

    if (response.data.includes("DEACTIVATED")) {
      showNotification({
        type: "info",
        operation: "deactivate",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    } else {
      showNotification({
        type: "info",
        operation: "delete",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    }
  };

  const callbackDeleteError = error => {
    showNotification({
      type: "warning",
      operation: "delete",
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
    });
  };

  const callbackDeleteFinally = response => {
    setShowModal(false);
    setDeleteItemId(null);
    setLinkedTransactionsToAccount(false);
    getAllAccountsService();
  };

  const deleteAccountService = () => {
    const accountDeleteService = new Accounts.Delete.Service(deleteItemid);
    accountDeleteService.setCallbackSuccess(callbackDeleteSuccess);
    accountDeleteService.setCallbackError(callbackDeleteError);
    accountDeleteService.setCallbackFinally(callbackDeleteFinally);
    accountDeleteService.setPayload();
    accountDeleteService.call();
  };

  const callbackUpdateSuccess = response => {
    setisNewAccountClicked(false);
    if (response) {
      showNotification({
        type: "info",
        operation: "activate",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    } else {
      showNotification({
        type: "info",
        operation: "update",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    }
  };
  const callbackUpdateError = error => {
    showNotification({
      type: "warning",
      operation: "update",
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
    });
  };
  const callbackUpdateFinally = response => {
    getAllAccountsService();
    setLinkedTransactionsToAccount(false);
    setIsLoading(false);
  };

  const getAllAccountsService = () => {
    const getAllAccounts = new Accounts.GetAll.Service();
    getAllAccounts.setCallbackSuccess(callbackGetAllSuccess);
    getAllAccounts.setCallbackError(callbackGetAllError);
    getAllAccounts.setCallbackFinally(callbackGetAllFinally);
    getAllAccounts.call();
  };

  const updateAccountService = (updatedAccount, reactivatedAccount) => {
    const accountUpdateService = new Accounts.Update.Service(updatedAccount.id);

    accountUpdateService.setCallbackSuccess(res => {
      callbackUpdateSuccess(reactivatedAccount);
    });
    accountUpdateService.setCallbackError(callbackUpdateError);
    accountUpdateService.setCallbackFinally(callbackUpdateFinally);
    if (reactivatedAccount) {
      accountUpdateService.setPayload(ReactivateAccount(updatedAccount));
    } else {
      accountUpdateService.setPayload(GetUpdateObject(updatedAccount));
    }
    accountUpdateService.call();
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getAllAccountsService();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const [accountListState, setAccountListState] = useState(accountListInitState);

  const fetchListItems = async () => {
    getAllAccountsService.query.setPageSize(accounts.length + 20);
    await getAllAccountsService();
    setFetchCount(accounts.length);
  };

  const getRefreshService = (webFormId, accountId) => {
    const callbackRefreshSuccess = response => {
      getAllAccountsService();
      showNotification({
        type: "info",
        operation: "update",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    };
    const callbackRefreshError = error => {
      const res = error.response.data;
      const { message } = res;

      showNotification({
        type: "warning",
        operation: "update",
        serverMessage: Localize.get(message.replace(/[{}]/g, ""))
      });
    };
    const callbackRefreshFinaly = response => {};
    const accountRefreshService = new Accounts.GetRefresh.Service(webFormId);

    accountRefreshService.query.clearPageSize();
    accountRefreshService.query.clearPage();
    accountRefreshService.query.addAccountId(accountId);

    accountRefreshService.setCallbackSuccess(callbackRefreshSuccess);
    accountRefreshService.setCallbackError(callbackRefreshError);
    accountRefreshService.setCallbackFinally(callbackRefreshFinaly);
    accountRefreshService.call();
  };

  const handlerItem = {
    onListItemClick: (event, data) => {
      event.stopPropagation();
      setAccountListState({ ...accountListState, activeListItem: data.id });
      props.history.push({
        pathname: "/finances/transactions",
        search: "",
        state: { id: data.finapiid }
      });
    },
    onEditItemClick: (itemId, isPaypal) => {
      setAccountListState({ ...accountListState, activeListItem: itemId });
      isPaypal ? setIsPayPalAccClicked(true) : setisNewAccountClicked(true);

      setPanelState({ activeMode: panelMode.edit });
      setEditMode(true);
      setHeaderDimmed(true);
    },
    onReactivateClick: reactivatedAccount => {
      setIsLoading(true);
      updateAccountService(reactivatedAccount, true);
    },
    onDeleteItemClick: account => {
      setDeleteItemId(account.id);
      if (
        account &&
        (account.numberOfLinkedTransactions > 0 || account.numberOfLinkedWithoutDocTransactions > 0)
      ) {
        setLinkedTransactionsToAccount(true);
      }
      setShowModal(true);
    },
    onShowInfoMessage: () => {
      setShowInfoMessage(false);
    },
    onShowErrorMessage: () => {
      setShowErrorMessage(false);
    },
    errorDeleteDialog: {
      handleOnConfirmButtonClick: () => {
        setShowModalError(false);
      }
    },
    resetIsNewAccountCreated: () => {
      setIsNewAccountCreated(false);
    },
    onRefreshAccountsClick: account => {
      setIsLoading(true);
      const callbackUpdateSuccess = response => {
        let accountsArr = accounts;
        if (response.data.accountNumber) {
          let accountResponse = response.data;
          let foundAccount = accountsArr.findIndex(accnt => accnt.id === accountResponse.id);
          accountsArr.splice(foundAccount, 1, accountResponse);
          setAccounts(accountsArr);
          showNotification({
            type: "info",
            operation: "update",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
          });
          getAllAccountsService();
        }
        if (response.data.webFormId) {
          setRefreshLink({
            ...refreshLink,
            formUrl: response.data.formUrl,
            webFormId: response.data.webFormId,
            accountId: account.id
          });
          setOpenRefreshDialog(true);
        }
      };
      const callbackUpdateError = error => {
        const res = error.response.data;
        const { message } = res;
        showNotification({
          type: "warning",
          entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT"),
          serverMessage: Localize.get(message.replace(/[{}]/g, ""))
        });
      };
      const callbackUpdateFinally = response => {
        setIsLoading(false);
      };

      const accountUpdateService = new Accounts.GetUpdate.Service(account.id);

      accountUpdateService.setCallbackSuccess(callbackUpdateSuccess);
      accountUpdateService.setCallbackError(callbackUpdateError);
      accountUpdateService.setCallbackFinally(callbackUpdateFinally);
      accountUpdateService.call();
    },

    confirmDeleteDialog: {
      handleOnDeclineCancelButtonClick: () => {
        setShowModal(false);
        setLinkedTransactionsToAccount(false);
      },
      handleDeleteConfirmButtonClick: () => {
        deleteAccountService();
      }
    }
  };

  const handlerButton = {
    closeNewButtonFormHandler: event => {
      event.preventDefault();
      setisNewAccountClicked(false);
      setFormErrorMessage("");
      setEditMode(false);
      setHeaderDimmed(false);
    },

    closePaypalButtonFormHandler: event => {
      event.preventDefault();
      setIsPayPalAccClicked(false);
      // setFormErrorMessage("");
      setEditMode(false);
      setHeaderDimmed(false);
    },

    saveNewButtonHandler: newAccount => {
      if (panelState.activeMode === "create") {
        const callbackCreateSuccess = response => {
          setIsNewAccountCreated(true);
          setAccountListState({
            ...accountListState,
            activeListItem: accounts.length > 0 ? "ItemList_" + response.data.id : ""
          });
          setFormErrorMessage("");
          showNotification({
            type: "info",
            operation: "create",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
          });
        };
        const callbackCreateError = error => {
          setFormErrorMessage(error);
          showNotification({
            type: "warning",
            operation: "create",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
          });
        };
        const callbackCreateFinally = response => {
          getAllAccountsService();
        };

        const accountCreateService = new Accounts.Create.Service();

        accountCreateService.setCallbackSuccess(callbackCreateSuccess);
        accountCreateService.setCallbackError(callbackCreateError);
        accountCreateService.setCallbackFinally(callbackCreateFinally);
        accountCreateService.setPayload(newAccount);
        accountCreateService.call();
      }
    },
    createNewBankAccountButtonHandler: event => {
      event.preventDefault();
      setPanelState({ activeMode: panelMode.create });
      setisNewAccountClicked(true);
      setEditMode(true);
      setSideBarVisibility(false);
    },
    createNewPaypalAccountButtonHandler: event => {
      event.preventDefault();
      setPanelState({ activeMode: panelMode.create });
      setIsPayPalAccClicked(true);
      setEditMode(true);
      setSideBarVisibility(false);
    },
    saveButtonFormHandler: newAccount => {
      if (panelState.activeMode === "create") {
        const callbackCreateSuccess = response => {
          if (response.data.formUrl) {
            setOpenUrlDialog(true);
            setBankLink(response.data.formUrl);

            setNewAccountData({
              newAccData: newAccount,
              webFormId: response.data.webFormId
            });
          }
        };
        const callbackCreateError = error => {
          setFormErrorMessage(error);

          const res = error.response.data;
          const { message } = res;

          showNotification({
            type: "warning",
            operation: "create",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT"),
            custom: Localize.get(`NOTIFICATIONS.NOTIFICATIONCUSTOM.${message}`)
              ? Localize.get(`NOTIFICATIONS.NOTIFICATIONCUSTOM.${message}`)
              : null
          });
        };
        const callbackCreateFinally = response => {
          getAllAccountsService();
        };

        if (newAccount.id) {
          delete newAccount.id;
        }

        const accountCreateService = new Accounts.Create.Service();

        accountCreateService.setCallbackSuccess(callbackCreateSuccess);
        accountCreateService.setCallbackError(callbackCreateError);
        accountCreateService.setCallbackFinally(callbackCreateFinally);
        accountCreateService.setPayload(newAccount);
        accountCreateService.call();
      }
      if (panelState.activeMode === "edit") {
        setEditMode(false);
        setHeaderDimmed(false);
        setisNewAccountClicked(false);
        newAccount.balance = 0;
        newAccount.checked = false;
        newAccount.numberOfSales = 0;
        updateAccountService(newAccount, false);
      }
    },
    saveButtonPaypalFormHandler: newAccount => {
      if (panelState.activeMode === "create") {
        const callbackCreateSuccess = response => {
          if (response.data.formUrl) {
            setOpenUrlDialog(true);
            setPaypalLink(response.data.formUrl);

            setPaypalNewAccountData({
              newAccData: newAccount,
              webFormId: response.data.webFormId
            });
          }
        };
        const callbackCreateError = error => {
          setFormErrorMessage(error);

          const res = error.response.data;
          const { message } = res;

          showNotification({
            type: "warning",
            operation: "create",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PAYPAL"),
            custom: Localize.get(`NOTIFICATIONS.NOTIFICATIONCUSTOM.${message}`)
              ? Localize.get(`NOTIFICATIONS.NOTIFICATIONCUSTOM.${message}`)
              : null
          });
        };
        const callbackCreateFinally = response => {
          getAllAccountsService();
        };

        const paypalCreateService = new Accounts.PaypalCreate.Service();

        paypalCreateService.setCallbackSuccess(callbackCreateSuccess);
        paypalCreateService.setCallbackError(callbackCreateError);
        paypalCreateService.setCallbackFinally(callbackCreateFinally);
        paypalCreateService.setPayload({
          accountOwner: newAccount.accountOwner
        });
        paypalCreateService.call();
      }
      if (panelState.activeMode === "edit") {
        setEditMode(false);
        setHeaderDimmed(false);
        setIsPayPalAccClicked(false);

        const callbackUpdateSuccess = response => {
          setisNewAccountClicked(false);
          if (response) {
            showNotification({
              type: "info",
              operation: "activate",
              entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
            });
          } else {
            showNotification({
              type: "info",
              operation: "update",
              entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
            });
          }
        };
        const callbackUpdateError = error => {
          showNotification({
            type: "warning",
            operation: "update",
            entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PAYPAL")
          });
        };
        const callbackUpdateFinally = async response => {
          setIsLoading(true);
          await getAllAccountsService();
          setIsLoading(false);
        };

        newAccount.balance = 0;
        newAccount.checked = false;
        newAccount.numberOfSales = 0;

        const paypalUpdateService = new Accounts.PaypalUpdate.Service(newAccount.id);

        paypalUpdateService.setCallbackSuccess(callbackUpdateSuccess);
        paypalUpdateService.setCallbackError(callbackUpdateError);
        paypalUpdateService.setCallbackFinally(callbackUpdateFinally);
        paypalUpdateService.setPayload(GetUpdateObject(newAccount));
        paypalUpdateService.call();
      }
    }
  };

  const closeUrlModal = () => {
    setOpenUrlDialog(false);
    setPaypalLink("");
  };

  const closeRefreshModal = event => {
    setOpenRefreshDialog(false);
    setIsLoading(false);
  };

  const onLinkClicked = () => {
    setEditMode(false);
    setHeaderDimmed(false);
    setisNewAccountClicked(false);

    // one step account creation

    const callbackAccountVerifySuccess = response => {
      getAllAccountsService();
      setisNewAccountClicked(false);
      setAccountListState({
        ...accountListState,
        activeListItem: accounts.length > 0 ? "ItemList_" + response.data.id : ""
      });
      setFormErrorMessage("");
      showNotification({
        type: "info",
        operation: "create",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    };
    const callbackAccountVerifyError = error => {
      setFormErrorMessage(error);

      const res = error.response.data;
      const { message } = res;

      const notification = {
        type: "warning",
        operation: "create",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      };

      if (Localize.get(message.replace(/[{}]/g, ""))) {
        notification.serverMessage = Localize.get(message.replace(/[{}]/g, ""));
      } else if (message) {
        notification.custom = message;
      }

      showNotification(notification);
      setisNewAccountClicked(false);
      setAccountListState({
        ...accountListState,
        activeListItem: accounts.length > 0 ? "ItemList_" + accounts[0].id : ""
      });
    };
    const callbackAccountVerifyFinally = resp => {
      setNewAccountData(null);
      closeUrlModal();
    };

    const accountVerify = new Accounts.GetRefresh.Service(
      newAccountData ? newAccountData.webFormId : ""
    );

    accountVerify.query.clearPageSize();
    accountVerify.query.clearPage();
    accountVerify.setCallbackSuccess(callbackAccountVerifySuccess);
    accountVerify.setCallbackError(callbackAccountVerifyError);
    accountVerify.setCallbackFinally(callbackAccountVerifyFinally);
    accountVerify.setPayload({
      accountOwner:
        newAccountData && newAccountData.newAccData ? newAccountData.newAccData.accountOwner : "",
      bankName:
        newAccountData && newAccountData.newAccData ? newAccountData.newAccData.bankName : "",
      iban: newAccountData && newAccountData.newAccData ? newAccountData.newAccData.iban : "",
      bic: newAccountData && newAccountData.newAccData ? newAccountData.newAccData.bic : ""
    });
    accountVerify.call();

    // one step account creation
  };

  const onPaypalLinkClicked = () => {
    setEditMode(false);
    setHeaderDimmed(false);
    setIsPayPalAccClicked(false);
    // one step account creation

    const callbackAccountVerifySuccess = async response => {
      setIsLoading(true);
      await getAllAccountsService();
      setIsLoading(false);
      setisNewAccountClicked(false);
      setAccountListState({
        ...accountListState,
        activeListItem: accounts.length > 0 ? "ItemList_" + response.data.id : ""
      });
      setFormErrorMessage("");
      showNotification({
        type: "info",
        operation: "create",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      });
    };
    const callbackAccountVerifyError = error => {
      setFormErrorMessage(error);

      const res = error.response.data;
      const { message } = res;

      const notification = {
        type: "warning",
        operation: "create",
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ACCOUNT")
      };

      if (message.includes("{")) {
        let messageReplace = message.replace(/[{}]/g, "");
        let localizedMessage = Localize.get(messageReplace);
        notification.serverMessage = localizedMessage;
      }

      showNotification(notification);
      setIsPayPalAccClicked(false);
      setAccountListState({
        ...accountListState,
        activeListItem: accounts.length > 0 ? "ItemList_" + accounts[0].id : ""
      });
    };
    const callbackAccountVerifyFinally = resp => {
      setPaypalNewAccountData(null);
      closeUrlModal();
    };

    const accountVerify = new Accounts.GetRefresh.Service(
      newPaypalAccountData ? newPaypalAccountData.webFormId : ""
    );

    accountVerify.query.clearPageSize();
    accountVerify.query.clearPage();
    accountVerify.setCallbackSuccess(callbackAccountVerifySuccess);
    accountVerify.setCallbackError(callbackAccountVerifyError);
    accountVerify.setCallbackFinally(callbackAccountVerifyFinally);
    accountVerify.setPayload({
      accountOwner:
        newPaypalAccountData && newPaypalAccountData.newAccData
          ? newPaypalAccountData.newAccData.accountOwner
          : "",
      paypalAccount: true,
      accountNumber:
        newPaypalAccountData && newPaypalAccountData.newAccData
          ? newPaypalAccountData.newAccData.accountNumber
          : ""
    });
    accountVerify.call();

    // one step account creation
  };

  let class1;
  let class2;

  if (isNewAccountClicked || isPayPalAccClicked) {
    class1 = "ui container fluid finances_bank_transactions new_bank_account";
    class2 = `ui two column stackable grid ${styles.bms_column_height}`;
  } else {
    class1 = `ui container fluid finances_bank_account_overview ${styles.accounts}`;
    class2 = "ui one column stackable grid";
  }

  const selectedAccount =
    accounts.length > 0
      ? accounts.find(
          account =>
            account.id.toString() === accountListState.activeListItem.replace("ItemList_", "")
        )
      : null;

  return (
    <div className={class1}>
      <div>
        <Dimmer active={isLoading || editMode} inverted>
          <Loader size="large" disabled={!isLoading}>
            {Localize.get("ACCOUNTS.ITEMLIST.ACCOUNT_LOADER")}
          </Loader>
        </Dimmer>

        <div
          className={`ui right attached button ${styles.bms_fixed} ${
            sideBarVisibility || editMode ? styles.bms_zindex : null
          }`}
          onClick={settingVisibleSidebar}
        >
          <i className="ellipsis vertical icon"></i>
        </div>
        <Sidebar.Pushable
          as={Segment}
          className={editMode ? styles.bms_segment_zi : styles.bms_segment}
        >
          <BankAccountsSideBar
            privileges={privileges}
            handlerButton={handlerButton}
            sideBarVisibility={sideBarVisibility}
            setSideBarVisibility={setSideBarVisibility}
          />

          <Sidebar.Pusher dimmed={sideBarVisibility} className={editMode ? styles.bms_zi : null}>
            <div className={styles.bms_accounts_content}>
              <div className={class2}>
                <BankAccountsItemList
                  privileges={privileges}
                  accounts={accounts}
                  handlerItem={handlerItem}
                  state={accountListState}
                  handlerButton={handlerButton}
                  fetchListItems={fetchListItems}
                  stopFetching={stopFetching}
                  editMode={editMode}
                />

                {isNewAccountClicked && (
                  <BankAccountsItemPanel
                    privileges={privileges}
                    editMode={editMode}
                    handlerButton={handlerButton}
                    handlerItem={handlerItem}
                    panelState={panelState}
                    account={selectedAccount}
                    formErrorMessage={formErrorMessage}
                    isNewAccountCreated={isNewAccountCreated}
                  />
                )}
                {isPayPalAccClicked && (
                  <BankAccountsPaypalPanel
                    privileges={privileges}
                    editMode={editMode}
                    handlerButton={handlerButton}
                    handlerItem={handlerItem}
                    panelState={panelState}
                    // account={selectedAccount}
                    paypalAccount={selectedAccount}
                    formErrorMessage={formErrorMessage}
                    isPayPalAccClicked={isPayPalAccClicked}
                  />
                )}
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
      <ConfirmDeleteDialog
        numberOfCheckedItems={1}
        showModal={showModal}
        confirmClicked={handlerItem.confirmDeleteDialog.handleDeleteConfirmButtonClick}
        declineClicked={handlerItem.confirmDeleteDialog.handleOnDeclineCancelButtonClick}
        linkedTransactionsToAccount={linkedTransactionsToAccount}
      />
      <ErrorDeleteDialog state={showModalError} handle={handlerItem.errorDeleteDialog} />
      <Modal open={openUrlDialog} onClose={closeUrlModal} closeIcon>
        <Modal.Header>
          {paypalLink !== ""
            ? Localize.get("ACCOUNTS.NEWACCOUNT.NEW_PAYPAL_ACCOUNT")
            : Localize.get("ACCOUNTS.NEWACCOUNT.NEW_ACCOUNT")}
        </Modal.Header>
        <Modal.Content>
          <p>
            {Localize.get("ACCOUNTS.NEWACCOUNT.NEW_ACCOUNT_MSG")}

            {paypalLink ? (
              <a
                href={paypalLink}
                onClick={onPaypalLinkClicked}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.bms_link}
              >
                Link
              </a>
            ) : (
              <a
                href={bankLink}
                onClick={onLinkClicked}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.bms_link}
              >
                Link
              </a>
            )}
          </p>
        </Modal.Content>
      </Modal>

      <Modal open={openRefreshDialog} onClose={closeRefreshModal} closeIcon>
        <Modal.Header> {Localize.get("ACCOUNTS.UPDATEACCOUNT.REFRESH_ACCOUNT")}</Modal.Header>
        <Modal.Content>
          <p>
            {Localize.get("ACCOUNTS.UPDATEACCOUNT.REFRESH_ACCOUNT_MSG")}
            <a
              href={refreshLink.formUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={heandleLink}
            >
              Link
            </a>
          </p>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default BankAccounts;
