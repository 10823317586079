import { useState } from "react";
import { fetchAccountOverview } from "./BookingServices";
import useFetch from "./useFetch";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";
import * as constants from "../Bookkeeping/Constants";
import { checkCurrentSelectedItem } from "../Components/Utils/GlobalListsUtil";

const useBookingOverviewServices = currentView => {
  const [forbidenResponse, setForbidenResponse] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterObject, setFilterObject] = useState(constants.initialFilterAccountObject);

  const [activePaginationPage, setActivePaginationPage] = useState(1);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("BOOKKEEPING.GET_DATA_FAILED"),
      ...param
    });
  };

  const resolveFilters = filterObject => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filterObject).map(column => {
      if (!filterObject[column] || filterObject[column] === "all" || filterObject[column] === "")
        delete filterObject[column];
    });
    return filterObject;
  };

  const callbackFetchSuccess = (response, setData, setPaging) => {
    setForbidenResponse(false);
    setSelectedItem(checkCurrentSelectedItem(response.data, currentView, selectedItem));
  };

  const callbackFetchError = err => {
    setBookingAccountList([]);
    showNotificationMessage({
      type: "warning",
      operation: ""
    });
  };

  const getPath = () => {
    let activefilter = Object.keys(filterObject)[0];
    switch (activefilter) {
      case constants.accountOverviewModule.allAccounts:
        return "/bookings/accounts";
      default:
        return `/bookings/accounts/${activefilter}`;
    }
  };

  const [
    bookingAccountList,
    paginationStatus,
    bookingAccountLoading,
    bookingAccountCall,
    setBookingAccountList
  ] = useFetch(
    fetchAccountOverview,
    [],
    getPath(),
    resolveFilters({
      page: `${activePaginationPage - 1}`,
      size: 20
    }),
    callbackFetchSuccess,
    callbackFetchError
  );

  return {
    bookingAccountList,
    bookingAccountLoading,
    bookingAccountCall,
    setBookingAccountList,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    filterObject,
    setFilterObject,
    paginationStatus,
    activePaginationPage,
    setActivePaginationPage
  };
};

export default useBookingOverviewServices;
