import React, { useContext } from "react";
import GlobalPanel from "../../Components/Panels/GlobalPanel";
import TypesMiddlePanel from "./TypesMiddlePanel";
import TypesRightPanel from "./TypesRightPanel";
import { leftPanelConfig } from "./ProjectTypes.config";
import { AuthContext } from "../../Auth/AuthContext";
import { panelDefaultConf, panelView } from "../../CustomHooks/GlobalPanel.config";
import useGetAll from "../../CustomHooks/useGetAll";

const ProjectTypes = ({ setHeaderDimmed }) => {
  const { rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["projectType"];

  const [
    selectedItem,
    listState,
    currentView,
    isLoading,
    activePaginationPage,
    filterObject,
    searchObject,
    forbidenResponse,
    paginationStatus,
    callGetAll,
    setSelectedItem,
    setListState,
    filterHandler,
    searchFilter,
    sortHandler,
    setActivePaginationPage,
    setCurrentView,
    setIsLoading
  ] = useGetAll("projects/types", {}, { search: "", sortBy: "", sortDirection: "" });

  setHeaderDimmed(currentView === "create" || currentView === "edit" || isLoading);

  const leftPanelProps = {
    leftPanelConfig: leftPanelConfig,
    hideDetailsPanel: true,
    setCurrentView: setCurrentView,
    filterObject: filterObject,
    filterHandler: filterHandler,
    setSelectedItem: setSelectedItem,
    forbidenResponse: forbidenResponse,
    setListState: setListState,
    setActivePaginationPage: setActivePaginationPage,
    privileges: privileges
  };

  const middlePanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    listState: listState,
    setListState: setListState,
    searchFilter: searchFilter,
    filterObject: filterObject,
    searchObject: searchObject,
    forbidenResponse: forbidenResponse,
    listDimmed: currentView === panelView.edit || currentView === panelView.create,
    activePaginationPage: activePaginationPage,
    handlePageChange: setActivePaginationPage,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    paginationStatus: paginationStatus,
    callGetAll: callGetAll,
    sortHandler: sortHandler,
    privileges: privileges,
    setIsLoading: setIsLoading
  };

  const rightPanelProps = {
    currentModule: "projects/types",
    currentView: currentView,
    setCurrentView: setCurrentView,
    selectedItem: selectedItem,
    callGetAll: callGetAll,
    activePaginationPage: activePaginationPage,
    setActivePaginationPage: setActivePaginationPage,
    lenghtOfList: listState.length,
    privileges: privileges
  };

  return (
    <GlobalPanel
      leftPanelProps={leftPanelProps}
      middlePanelProps={middlePanelProps}
      rightPanelProps={rightPanelProps}
      currentView={currentView}
      isLoading={isLoading}
      customizedMiddlePanelComponent={TypesMiddlePanel}
      customizedRightPanelComponent={TypesRightPanel}
      globalPanelConf={{
        ...panelDefaultConf,
        rightPanel: {
          form: {
            hideUnder: 0
          },
          details: {
            hideUnder: 30000
          }
        }
      }}
    />
  );
};

export default ProjectTypes;
