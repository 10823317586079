import React from "react";
import { Button, Divider } from "semantic-ui-react";
import propTypes from "prop-types";
import Localize from "react-intl-universal";
import styles from "./style/GlobalSaveNewCancelBtn.module.css";
import GlobalCancelBtn from "./GlobalCancelBtn";

const GlobalSaveNewCancelBtn = props => {
  const {
    editMode,
    onUpdate = function(arg) {},
    onSave = function(arg) {},
    onSaveNew = function(arg) {},
    onCancel = function(arg) {},
    setCurrentView = function(arg) {},
    disabledBtn,
    okButton = false
  } = props;

  return (
    <>
      <Divider className={styles.margin_bottom} />
      <div className={styles.button_container}>
        {editMode === undefined || editMode ? (
          okButton ? (
            <Button
              primary
              disabled={disabledBtn ? disabledBtn : false}
              className={styles.save_btn}
              onClick={() => setCurrentView("details")}
            >
              {Localize.get("GLOBAL.FORM.OK")}
            </Button>
          ) : (
            <Button
              primary
              disabled={disabledBtn ? disabledBtn : false}
              className={styles.save_btn}
              onClick={(editMode ? editMode : false) ? onUpdate : onSave}
            >
              {Localize.get("GLOBAL.FORM.SAVE")}
            </Button>
          )
        ) : (
          <>
            <Button
              primary
              className={styles.save_btn}
              disabled={disabledBtn ? disabledBtn : false}
              onClick={onSave}
            >
              {Localize.get("GLOBAL.FORM.SAVE")}
            </Button>
            <Button
              primary
              className={styles.save_new_btn}
              disabled={disabledBtn ? disabledBtn : false}
              onClick={onSaveNew}
            >
              {Localize.get("GLOBAL.FORM.SAVE_AND_NEW")}
            </Button>
          </>
        )}

        {!okButton && <GlobalCancelBtn onCancel={onCancel} />}
      </div>
    </>
  );
};

GlobalSaveNewCancelBtn.prototype = {
  editMode: propTypes.bool,
  onUpdate: propTypes.func,
  onSave: propTypes.func,
  onSaveNew: propTypes.func,
  onCancel: propTypes.func,
  disabledBtn: propTypes.bool
};
export default GlobalSaveNewCancelBtn;
