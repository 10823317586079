import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { Dropdown, Input } from "semantic-ui-react";
import styles from "./style/ToolbarDatepicker.module.css";

import {
  MONTHS,
  WEEKDAYS_SHORT,
  WEEKDAYS_LONG,
  LABELS,
  FIRST_DAY_OF_WEEK
} from "../Utils/GlobalUtil";

const ToolbarDatepicker = props => {
  const {
    setDisableReset = function() {},
    disabledDropdown = false,
    dateChangeHandler = function() {}
  } = props;

  //DATEPICKER in ToolBar
  const currentLanguage = navigator.language.toLowerCase().includes("de") ? "de" : "en";
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [dateRange, setDateRange] = useState({
    from: DateUtils.addMonths(new Date(), -1),
    to: new Date()
  });
  const modifiers = { start: dateRange.from, end: dateRange.to };

  //End DATEPICKER in ToolBar

  const handleDateRangeClick = day => {
    const newDateRange = DateUtils.addDayToRange(day, dateRange);
    if (newDateRange.from !== null) {
      setDateRange(newDateRange);
      dateChangeHandler(newDateRange);
    }
  };

  const handleDropdownClick = () => {
    setDateMenuOpen(true);
    setDisableReset(true);
  };

  const handleDropdownBlure = () => {
    setDateMenuOpen(false);
    setDisableReset(false);
  };

  const triggerDateInput = (
    <span>
      <Input
        className={styles.datepicker_input}
        icon="calendar alternate outline"
        readOnly
        id="fromDate"
        name="fromDate"
        type="text"
        placeholder="Date"
        value={
          dateRange.from.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }) +
          " - " +
          dateRange.to.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
        }
      />
    </span>
  );

  return (
    <Dropdown
      trigger={triggerDateInput}
      className={styles.date_range}
      floating
      labeled
      button
      onClick={handleDropdownClick}
      onBlur={handleDropdownBlure}
      closeOnBlur
      icon={null}
      disabled={disabledDropdown}
    >
      <Dropdown.Menu open={dateMenuOpen}>
        <DayPicker
          id="myCal"
          locale={currentLanguage}
          months={MONTHS[currentLanguage]}
          weekdaysLong={WEEKDAYS_LONG[currentLanguage]}
          weekdaysShort={WEEKDAYS_SHORT[currentLanguage]}
          firstDayOfWeek={FIRST_DAY_OF_WEEK[currentLanguage]}
          labels={LABELS[currentLanguage]}
          className={styles.selectable}
          numberOfMonths={numberOfMonths}
          modifiers={modifiers}
          month={dateRange.from}
          selectedDays={[dateRange.from, { from: dateRange.from, to: dateRange.to }]}
          onDayClick={handleDateRangeClick}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ToolbarDatepicker;
