import React, { useRef, useState } from "react";
// import * as constant from '../Constants.js';
import Localize from "react-intl-universal";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import styles from "./style/DocumentUpload.module.css";

const DocumentUpload = props => {
  const { handler, isLoadingForm } = props;

  const inputFile = useRef(null);
  const [dragMode, setDragMode] = useState(false);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onDragAndDropClick = e => {
    setDragMode(!dragMode);
    handler.documentUpload.onDragMode(e);
  };

  return (
    <>
      <Dimmer active={isLoadingForm} inverted>
        <Loader size="large" disabled={!isLoadingForm}>
          {Localize.get("DOCUMENTS.FORM.DOCUMENT_LOADER")}
        </Loader>
      </Dimmer>
      <>
        <div className={styles.bms_header}>
          {Localize.get("DOCUMENTS.NEWDOCUMENT.HEADER_UPLOAD")}
        </div>

        <p>{Localize.get("DOCUMENTS.NEWDOCUMENT.LABEL_UPLOAD_NEW_DOCUMENT")}</p>

        <div className={`ui buttons ${styles.upload_new_document}`}>
          <button className="ui button" onClick={onButtonClick}>
            <i className="cloud upload icon" />
            {Localize.get("DOCUMENTS.NEWDOCUMENT.ICON_UPLOAD")}
          </button>
          <input
            type="file"
            id="file"
            ref={inputFile}
            // style={{ display: "none" }}
            className={styles.upload_input}
            onChange={handler.documentUpload.onUploadFileClick}
          />
          <Button.Or text={Localize.get("DOCUMENTS.NEWDOCUMENT.UPLOAD_OR")} />
          <Button
            className={!dragMode ? null : "dragNdropButton"}
            active={dragMode}
            onClick={onDragAndDropClick}
          >
            <i
              className={
                !dragMode ? "external alternate icon" : "external alternate icon dragNdropButton"
              }
            />
            {Localize.get("DOCUMENTS.NEWDOCUMENT.ICON_DRAG_DROP")}
          </Button>
          <Button.Or text={Localize.get("DOCUMENTS.NEWDOCUMENT.UPLOAD_OR")} />
          <Button className="ui button" onClick={handler.documentUpload.onSelectExistingFile}>
            <i className="list ul icon" />
            {Localize.get("DOCUMENTS.NEWDOCUMENT.ICON_CHOOSE_FROM_LIST")}
          </Button>
        </div>
        <GlobalCancelBtn
          onCancel={handler.documentUpload.onCancelButtonClick}
          floatedDirection={"right"}
          customStyle={{ marginTop: "10px" }}
        />
      </>
    </>
  );
};

export default DocumentUpload;
