import React from "react";
import { Button } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ThumbDetails.module.css";

const ThumbDetails = props => {
  const { invoices, selectedDocumentsPreview, isLinkedInvoices } = props;

  const invoice = invoices.find(invoice => invoice.id === selectedDocumentsPreview.invoiceId);

  const handleInvoiceDetailsToggle = () => {
    props.handler.handleInvoiceDetailsToggle(selectedDocumentsPreview.invoiceId);
  };
  return (
    <>
      <div className="six wide column">
        <div
          className={
            !selectedDocumentsPreview.isPDF ? styles.invoice_full : styles.invoice_full_no_overflow
          }
        >
          <div>
            {selectedDocumentsPreview && !selectedDocumentsPreview.isPDF && (
              <div>
                <img
                  className={styles.documentImagePreviewContainer}
                  src={props.selectedDocumentsPreview.data}
                  alt=""
                />
              </div>
            )}
            {selectedDocumentsPreview && selectedDocumentsPreview.isPDF && (
              <object
                className={styles.documentPreviewDetails}
                data={props.selectedDocumentsPreview.data}
                type="application/pdf"
              >
                alt : ''
              </object>
            )}
          </div>
        </div>
        <div>
          <Button onClick={props.handler.handleInvoiceDetailsBack}>
            <i className="arrow alternate circle left outline icon"></i>
            {Localize.get("TRANSACTIONS.INVOICEDETAILS.BACK")}
          </Button>
          {isLinkedInvoices ? null : (
            <Button onClick={handleInvoiceDetailsToggle} className="ui positive button">
              {invoice && invoice.checked
                ? Localize.get("TRANSACTIONS.INVOICEDETAILS.DESELECT")
                : Localize.get("TRANSACTIONS.INVOICEDETAILS.SELECT")}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.clear_div} />
    </>
  );
};

export default ThumbDetails;
