import styles from "./LeftPanel/style/SideBar.module.css";

export const documentDirection = {
  incoming: "incoming",
  outgoing: "outgoing",
  all: "all"
};

export const typeFilter = { imported: "imported invoice" };

export const documentType = {
  inquiry: "inquiry",
  offer: "offer",
  order: "order",
  receipt: "receipt",
  delivery: "delivery",
  invoice: "invoice",
  imported_invoice: "imported_invoice",
  other: "other",
  correction: "correction",
  deliveryNote: "delivery",
  all: "all",
  annex: "annex"
};

// TODO: call service for available taxes
export const taxOptions = [
  { key: 1, text: "0%", value: 0 },
  { key: 2, text: "5%", value: 5 },
  { key: 3, text: "7%", value: 7 },
  { key: 4, text: "16%", value: 16 },
  { key: 5, text: "19%", value: 19 }
];

export const unitOptions = [
  { key: "C62", value: "Stk", text: "Stk" },
  { key: "KMT", value: "km", text: "km" },
  { key: "MTQ", value: "m3", text: "m3" },
  { key: "MTK", value: "m2", text: "m2" },
  { key: "MTR", value: "m", text: "m" },
  { key: "CMT", value: "cm", text: "cm" },
  { key: "MMT", value: "mm", text: "mm" },
  { key: "TNE", value: "t", text: "t" },
  { key: "KGM", value: "kg", text: "kg" },
  { key: "MGM", value: "mg", text: "mg" },
  { key: "HUR", value: "h", text: "h" },
  { key: "GRM", value: "g", text: "g" },
  { key: "LTR", value: "l", text: "l" },
  { key: "CLT", value: "cl", text: "cl" },
  { key: "KWT", value: "kW", text: "kW" },
  { key: "KWH", value: "kWh", text: "kWh" },
  { key: "H87", value: "piece", text: "piece" },
  { key: "SET", value: "set", text: "set" },
  { key: "PTN", value: "portion", text: "portion" },
  { key: "DAY", value: "day", text: "day" },
  { key: "HLT", value: "hl", text: "hl" },
  { key: "INH", value: "inch", text: "inch" },
  { key: "LS", value: "-", text: "-" }
];

export const vatOptions = [
  { key: 1, value: "0", text: "0 %" },
  { key: 2, value: "5", text: "5 %" },
  { key: 3, value: "7", text: "7 %" },
  { key: 4, value: "16", text: "16 %" },
  { key: 5, value: "19", text: "19 %" }
];

export const documentItem = {
  itemLineId: "itemLineId",
  sellerItemId: "sellerItemId",
  name: "name",
  netPrice: "netPrice",
  quantity: "quantity",
  amount: "amount"
};

export const contactType = {
  person: "person",
  company: "company"
};

export const documentDue = {
  overdue: "overdue",
  today: "today",
  thisWeek: "thisWeek",
  nextWeek: "nextWeek",
  thisMonth: "thisMonth",
  all: "all"
};

export const paymentApprovalStatus = {
  approved: "approved",
  declined: "declined",
  unresolved: "unresolved",
  not_paid: "not_paid",
  all: "all"
};

export const progressStatus = {
  imported: "imported",
  pdfCreated: "pdf_created",
  sent: "sent",
  dunning: "dunning",
  all: "all"
};

export const verificationStatus = {
  verified: "verified",
  disapproved: "disapproved",
  unverified: "unverified",
  all: "all"
};

export const paymentStatus = {
  open: "open",
  pending: "pending",
  notPaid: "not_paid",
  successful: "successful",
  notSuccessful: "not_successful",
  discarded: "discarded",
  paid: "paid",
  notRelevant: "not_relevant",
  partialPaid: "partially_paid"
};

export const paymentLabelStatus = {
  pending: "pending",
  notPaid: "not_paid",
  paid: "paid",
  notSuccessful: "not_successful"
};
export const revenuePaymentStatus = {
  paid: "paid",
  partialPaid: "partially_paid",
  notPaid: "not_paid",
  all: "all"
};
export const approvalStatus = {
  paid: "paid",
  complete: "complete",
  pending: "pending",
  approved: "approved",
  declined: "declined",
  unresolved: "unresolved",
  notRelevant: "not_relevant",
  due: "due",
  all: "all"
};

export const filterOptions = {
  documentType: "documentType",
  direction: "direction",
  partner: "partner",
  parentDocument: "parentDocument"
};

export const archive = {
  archived: "archived"
};

export const leftPanelMode = {
  sidebar: "sidebar"
};

export const middlePanelMode = {
  documentNotSelected: "documentNotSelected",
  documentScanList: "documentScanList",
  documentList: "documentList",
  documentDisplay: "documentDisplay",
  documentDragnDrop: "documentDragnDrop",
  documentForPay: "documentForPay"
};

export const rightPanelMode = {
  documentDetails: "documentDetails",
  documentUpload: "documentUpload",
  documentThumbnails: "documentThumbnails",
  documentScansPanel: "documentScansPanel",
  documentForm: "documentForm",
  documentLinkThumbnails: "documentLinkThumbnails",
  documentLinkThumbnailDetails: "documentLinkThumbnailDetails",
  documentShowScan: "documentShowScan",
  documentShowScanFromFolder: "documentShowScanFromFolder",
  linkDocumentPreview: "linkDocumentPreview",
  projectPanel: "projectPanel"
};

export const initialState = {
  activeListItem: undefined,
  selectAll: false,
  activeSideBarDimmer: false,
  activeLeftPanelMode: leftPanelMode.sidebar,
  activeMiddlePanelMode: middlePanelMode.documentList,
  activeRightPanelMode: rightPanelMode.documentDetails,
  activeInputNumberSearch: null,
  selectFirst: false,
  match: "",
  fromDate: "all",
  toDate: "all",
  approval: "",
  number: "",
  savings: "",
  partnerName: "",
  partnerOriginalId: "",
  documentDate: "",
  minDate: "",
  maxDate: "",
  amount: "",
  allDates: undefined,
  sortColumn: null,
  sortDirection: null,
  pickerTouched: false
};

export const panelMode = {
  details: "details",
  edit: "edit",
  create: "create"
};

export const panelInitState = {
  activeMode: panelMode.details,
  contact: {}
};

export const actionType = {
  verificationConfirm: "Verification approved",
  verificationDecline: "Verification decline",
  resetVerificationStatus: "Reset verification status",
  paymentConfirm: "Payment approved",
  paymentDecline: "Payment decline",
  resetPaymentStatus: "Reset payment status",
  addManualPayment: "Add manual payment",
  deleteManualPayment: "Delete manual payment"
};

export const docInitialState = {
  achived: false
};

export const sortColumn = {
  documentDate: "documentDate"
};

export const toolbarDayPicker = {
  documentDate: "documentDate",
  paymentDate: "paymentDate",
  createdAt: "createdAt",
  reset: "resetDates"
};

export const autoComplete = {
  none: "none",
  off: "off"
};

export const thumbnailsPanelState = {
  form: "form",
  documentPreview: "documentPreview"
};

export const initDocumentItem = {
  itemLineId: "",
  sellerItemId: "",
  name: "",
  vat: null,
  unit: null,
  quantity: null,
  netPrice: null,
  amount: null,
  surchargeDiscount: null
};

export const initSubtotals = {
  tax: "",
  netAmount: "",
  discount: "",
  surcharge: "",
  baseAmount: "",
  taxValue: "",
  grossAmount: ""
};

export const initCheckState = {
  documentItems: false,
  documentSubtotals: false,
  documentTotal: false,
  editMode: false
};

export const partnerListConfig = [
  {
    name: "checked",
    type: "checkbox",
    visibleName: "CONTACTS.LIST.HEADER.CHECKBOX",
    width: 1,
    visibleSetDisabled: true,
    visible: true
  },
  {
    name: "number",
    hederName: "CONTACTS.LIST.HEADER.NUMBER",
    type: "text",
    width: 2,
    visibleSetDisabled: true,
    searchable: true,
    visible: true
  },
  {
    name: "drole",
    hederName: "CONTACTS.LIST.HEADER.ROLE",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "dtype",
    hederName: "CONTACTS.LIST.HEADER.TYPE",
    type: "text",
    width: 2,
    visible: true
  },
  {
    name: "name",
    hederName: "CONTACTS.LIST.HEADER.NAME",
    type: "text",
    width: 4,
    searchable: true,
    visible: true
  },
  {
    name: "city",
    hederName: "CONTACTS.LIST.HEADER.ADDRESS",
    type: "text",
    width: 3,
    searchable: true,
    visible: true
  }
];

export const sideBarDirectionConfig = {
  headerName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION",
  type: "direction",
  filterItems: [
    {
      name: documentDirection.incoming,
      iconName: "sign-out",
      itemName: "TRANSACTIONS.DIRECTION.EXPENSE",
      color: "red"
    },
    {
      name: documentDirection.outgoing,
      iconName: "sign-in",
      itemName: "TRANSACTIONS.DIRECTION.REVENUE",
      color: "green"
    }
  ]
};

export const sideBarVerificationConfig = {
  headerName: "DOCUMENTS.SIDEBAR.HEADER_VERIFIATION_STATUS",
  type: "verificationStatus",
  filterItems: [
    {
      name: verificationStatus.verified,
      iconName: "check circle outline",
      iconStyle: styles.green_icon,
      itemName: "DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_APPROVED"
    },
    {
      name: verificationStatus.disapproved,
      iconName: "times circle outline",
      iconStyle: styles.gray_icon,
      itemName: "DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_DECLINED"
    },
    {
      name: verificationStatus.unverified,
      iconName: "question circle outline",
      iconStyle: styles.blue_icon,
      itemName: "DOCUMENTS.SIDEBAR.VERIFICATION_STATUS_UNRESOLVED"
    }
  ]
};

export const sideBarApprovalConfig = {
  headerName: "DOCUMENTS.SIDEBAR.HEADER_APPROVAL_STATUS",
  type: "approvalStatus",
  filterItems: [
    {
      name: paymentApprovalStatus.approved,
      iconName: "check circle outline",
      iconStyle: styles.green_icon,
      itemName: "DOCUMENTS.SIDEBAR.APPROVAL_STATUS_APPROVED"
    },
    {
      name: paymentApprovalStatus.declined,
      iconName: "times circle outline",
      iconStyle: styles.gray_icon,
      itemName: "DOCUMENTS.SIDEBAR.APPROVAL_STATUS_DECLINED"
    },
    {
      name: paymentApprovalStatus.unresolved,
      iconName: "question circle outline",
      iconStyle: styles.blue_icon,
      itemName: "DOCUMENTS.SIDEBAR.APPROVAL_STATUS_UNRESOLVED"
    }
  ]
};

export const sideBarPayStatusConfig = {
  headerName: "DOCUMENTS.SIDEBAR.HEADER_PAID_STATUS",
  type: "status",
  filterItems: [
    {
      name: revenuePaymentStatus.paid,
      iconName: "check square",
      iconStyle: styles.paid_icon,
      itemName: "DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PAID"
    },
    {
      name: revenuePaymentStatus.partialPaid,
      iconName: "check square outline",
      iconStyle: styles.blue_icon,
      itemName: "DOCUMENTS.DETAILS.DOCUMENT_PARTIAL_PAID"
    },
    {
      name: revenuePaymentStatus.notPaid,
      iconName: "square outline",
      iconStyle: styles.red_icon,
      itemName: "DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTPAID"
    }
  ]
};

export const sideBarStatus = {
  headerName: "DOCUMENTS.SIDEBAR.HEADER_STATUS",
  type: "status",
  filterItems: [
    {
      name: progressStatus.imported,
      iconName: "arrow alternate circle right outline",
      iconStyle: styles.green_icon,
      itemName: "DOCUMENTS.SIDEBAR.STATUS_IMPORTED"
    },
    {
      name: progressStatus.pdfCreated,
      iconName: "file outline",
      iconStyle: styles.pdf_icon,
      itemName: "DOCUMENTS.SIDEBAR.STATUS_PDF_CREATED"
    },
    {
      name: progressStatus.sent,
      iconName: "mail outline",
      iconStyle: styles.blue_icon,
      itemName: "DOCUMENTS.SIDEBAR.STATUS_SENT"
    },
    {
      name: progressStatus.dunning,
      iconName: "exclamation",
      iconStyle: styles.red_icon,
      itemName: "DOCUMENTS.SIDEBAR.STATUS_DUNNING"
    }
  ]
};

export const initialAccordion = {
  details: true,
  items: false,
  projects: false,
  linked: false,
  payments: false,
  comments: false,
  notes: false
};
export const availableItemFieldsForListInvoiceIncoming = [
  {
    name: "status",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.STATUS",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "number",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_NUMBER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "partner",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.PARTNER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "earlyPaymentDate",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.EARLY_PAYMENT_DATE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "paymentDate",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.PAYMENT_DATE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "amount",
    hederName: "GLOBAL.DETAILS.AMOUNT",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "savings",
    hederName: "DOCUMENTS.ITEMLIST.HEADER.SAVINGS",
    visibleSetDisabled: false,
    visible: true
  }
];

export const availableItemFieldsForListInvoiceOutgoing = [
  {
    name: "status",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.STATUS",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "number",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_NUMBER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "partner",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.PARTNER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "documentType",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_TYPE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "documentDate",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_DATE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "amount",
    hederName: "GLOBAL.DETAILS.AMOUNT",
    visibleSetDisabled: false,
    visible: true
  }
];

const headerForNonInvoice = [
  {
    name: "status",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.STATUS",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "number",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_NUMBER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "partner",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.PARTNER",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "documentType",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_TYPE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "documentDate",
    hederName: "DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_DATE",
    visibleSetDisabled: false,
    visible: true
  },
  {
    name: "amount",
    hederName: "GLOBAL.DETAILS.AMOUNT",
    visibleSetDisabled: false,
    visible: true
  }
];

export const availableItemFieldsForListOther = {
  inquiry: headerForNonInvoice,
  offer: headerForNonInvoice,
  order: headerForNonInvoice,
  receipt: headerForNonInvoice,
  other: headerForNonInvoice,
  correction: headerForNonInvoice,
  delivery: headerForNonInvoice,
  all: headerForNonInvoice,
  imported_invoice: headerForNonInvoice,
  annex: headerForNonInvoice
};
