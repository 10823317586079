import React from "react";
import { toast } from "react-semantic-toasts";
// import * as constant from "./Constants.js";
import Localize from "react-intl-universal";
import "./style/Notification.css";

const localize = param => Localize.get(`NOTIFICATIONS.${param}`);

const operationMessage = operation => {
  let message;
  switch (operation) {
    case "create":
      message = localize("NOTIFICATIONOPTIONS.CREATE");
      break;
    case "createFailed":
      message = localize("NOTIFICATIONOPTIONS.CREATE_FAILED");
      break;
    case "update":
      message = localize("NOTIFICATIONOPTIONS.UPDATE");
      break;
    case "verification":
      message =
        localize("NOTIFICATIONOPTIONS.VERIFICATION") + " " + localize("NOTIFICATIONOPTIONS.UPDATE");
      break;
    case "payment":
      message =
        localize("NOTIFICATIONOPTIONS.PAYMENT") + " " + localize("NOTIFICATIONOPTIONS.UPDATE");
      break;
    case "archive":
      message = localize("NOTIFICATIONOPTIONS.ARCHIVE");
      break;
    case "unarchive":
      message = localize("NOTIFICATIONOPTIONS.UNARCHIVE");
      break;
    case "delete":
      message = localize("NOTIFICATIONOPTIONS.DELETE");
      break;
    case "upload":
      message = localize("NOTIFICATIONOPTIONS.UPLOAD");
      break;
    case "added":
      message = localize("NOTIFICATIONOPTIONS.ADDED");
      break;
    case "activate":
      message = localize("NOTIFICATIONOPTIONS.ACTIVATED");
      break;
    case "deactivate":
      message = localize("NOTIFICATIONOPTIONS.DEACTIVATED");
      break;
    default:
      break;
  }
  return message;
};

const titleMessage = type => {
  return localize(`NOTIFICATIONTYPE.${type.toUpperCase()}`);
};

const notificationTime = (type, time) => {
  return time ? time : type === "info" ? 3000 : 3500;
};

const descriptionMessage = (type, operation, entity, serverMessage, custom) => {
  if (serverMessage) return <p>{serverMessage}</p>;
  if (custom) return <p>{localize(`NOTIFICATIONCUSTOM.${custom}`)}</p>;

  // message based on operation type
  const message = operationMessage(operation);

  return type === "info" ? (
    <>
      {entity} {message} {localize("NOTIFICATIONRESULT.SUCCESSFULLY")}
    </>
  ) : (
    <>
      {entity} {message} {localize("NOTIFICATIONRESULT.UNSUCCESSFULLY")}
    </>
  );
};

const showNotification = ({ type, operation, entity, serverMessage, custom, time }) => {
  // title for notification
  const title = titleMessage(type);

  // description text for notification
  const description = descriptionMessage(type, operation, entity, serverMessage, custom);

  // notification duration time
  const notificationDurationTime = notificationTime(type, time);

  const toastObject = {
    type: type,
    title: title,
    description: description,
    time: notificationDurationTime
  };

  toast({
    ...toastObject,
    onClose: () => {},
    onClick: () => {},
    onDismiss: () => {}
  });
};

export default showNotification;
