import React, { useState } from "react";
import { Button, Icon, Menu, Sidebar } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ExportSideBar.module.css";
import * as constants from "./Constants";

const ExportSideBar = props => {
  const { sideBarVisibility } = props;
  const { setSideBarVisibility } = props;
  const [activeItem, setActiveItem] = useState();
  const handleItemClick = (e, { name }) => setActiveItem({ name });
  const handleBtn = () => {
    props.history.push("/exports/export");
  };

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <div className="ui center aligned container">
          <Button
            primary
            className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
            onClick={handleBtn}
          >
            {Localize.get("EXPORT.DATEV.NEW_TRANSVER")}
            <Icon name="plus" />
          </Button>
        </div>

        <h4 className={styles.bms_sideBar_h4}> {Localize.get("EXPORT.DATEV.EXPORT_SETTINGS")}</h4>
        <Menu className={styles.vertical_menu} vertical>
          <Menu.Item
            className={styles.settings_item}
            name="settings"
            onClick={handleItemClick}
            disabled
            active={activeItem === constants.sideBarConstants.settings}
          >
            <Icon className={styles.settings_button} name="settings" disabled />
            {Localize.get("EXPORT.DATEV.EXPORT_SETTINGS")}
          </Menu.Item>
        </Menu>
      </Sidebar>
    </>
  );
};

export default ExportSideBar;
