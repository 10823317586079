import React from "react";
import { Button, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DocumentLinkThumbnailDetails.module.css";

const DocumentLinkThumbnailDetails = props => {
  const {
    selectedLinkedDocumentsPreview,
    handler,
    selectedDocSeparately,
    directionOfDocumentPreview
  } = props;

  return (
    <>
      <div className={styles.bms_header}>
        {Localize.get("DOCUMENTS.THUMBNAILS.LINK_DOCUMENTS")} &gt;{" "}
        {directionOfDocumentPreview === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED"
          ? Localize.get("DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED")
          : Localize.get("DOCUMENTS.THUMBNAILS.DOCUMENT_UNLINKED")}{" "}
        &gt; {Localize.get("DOCUMENTS.THUMBNAILS.LINK_DOCUMENTS_PREVIEW")}
      </div>

      <div className={`${styles.invoice_thumbnails} ${styles.single_invoice}`}>
        <div>
          {selectedLinkedDocumentsPreview && !selectedLinkedDocumentsPreview.isPDF && (
            <div>
              <img
                className={styles.documentImagePreviewContainer}
                src={selectedLinkedDocumentsPreview.data}
                alt=""
              />
            </div>
          )}
          {selectedLinkedDocumentsPreview && selectedLinkedDocumentsPreview.isPDF && (
            <div>
              <object
                className={styles.selectedLinkedPreview}
                data={selectedLinkedDocumentsPreview.data}
                type="application/pdf"
              >
                alt : ''
              </object>
            </div>
          )}
        </div>
      </div>
      <div>
        <Button
          color={
            directionOfDocumentPreview === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED" ? "grey" : "blue"
          }
          disabled={
            selectedDocSeparately &&
            selectedDocSeparately.documentType &&
            selectedDocSeparately.documentType === "correction"
          }
          onClick={() =>
            handler.onSelectButtonClick(
              selectedDocSeparately.id,
              directionOfDocumentPreview === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED"
                ? "remove"
                : "add"
            )
          }
        >
          {directionOfDocumentPreview === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED"
            ? Localize.get("DOCUMENTS.THUMBNAILS.REMOVE").toUpperCase()
            : Localize.get("DOCUMENTS.THUMBNAILS.ADD").toUpperCase()}
        </Button>
        <Button icon labelPosition="left" onClick={handler.onBackButtonClick}>
          <Icon name="reply" />
          {Localize.get("DOCUMENTS.THUMBNAILS.BACK")}
        </Button>
      </div>
    </>
  );
};

export default DocumentLinkThumbnailDetails;
