import React, { useState } from "react";
import { Accordion, Icon, Label, Grid, Segment } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DetailsAccordition.module.css";

const DetailsAccordion = props => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Accordion.Title
        active={activeIndex === props.index}
        index={props.index}
        onClick={handleClick}
      >
        <Grid className={styles.my_grid}>
          <Grid.Column className={styles.roles_details_title} floated="left" width={8}>
            <Icon name="dropdown" />
            {props.role.resourceRole.name ? props.role.resourceRole.name : undefined}
          </Grid.Column>
          <Grid.Column className={styles.right_panel} floated="right" width={7}>
            <Segment basic floated="right" className={styles.min_max_segment}>
              <div className={styles.min_block}>
                min
                <Label circular color="grey" key="grey" className={styles.min_label}>
                  {props.role.min}
                </Label>
              </div>
              <div className={styles.max_block}>
                max
                <Label circular color="teal" key="red" className={styles.max_label}>
                  {props.role.max}
                </Label>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === props.index}>
        <div className={styles.wrap_text}>
          <strong>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DESCRIPTION_PLACEHOLDER")}</strong>
          <br />
          {props.role.resourceRole.description ? props.role.resourceRole.description : undefined}
        </div>
      </Accordion.Content>
    </>
  );
};

export default DetailsAccordion;
