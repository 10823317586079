import React, { useState } from "react";
import { Form, Input, Dropdown, Label } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import * as elements from "../../../Components/Constants";
import { bmsStatusOptions, initialStatusFormState, autoComplete } from "../Constants";
import ProjectSaveButtonsGroup from "../../../Components/ProjectSaveButtonsGroup";

import styles from "./style/ProjectStatusesFormPanel.module.css";

const ProjectStatusesFormPanel = props => {
  const { editMode, handle, state, selectedStatus, updateSelectedStatus } = props;

  const [externalStatus, setExternalStatus] = useState(
    selectedStatus ? selectedStatus : initialStatusFormState
  );
  const [statusNameDefault, setStatusNameDefault] = useState("");
  const [editedFields, setEditedFields] = useState([]);
  const [validator, setValidator] = useState(new Validator(validatorConstants.formFieldsNewStatus));

  const [refreshValidation, setRefreshValidation] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [typeStatusErr, setStatusNameErr] = useState(false);

  const handleOnChange = (event, data) => {
    if (!event.target.value) {
      // clear validation messages after field is cleared out

      validator.validateField(event.target.value, event.target.name);
    } else {
      const validationOnField = validator.parameter[event.target.name];

      if (validationOnField) {
        validator.validateField(event.target.value, event.target.name);
      }
    }

    if (data.name && !editedFields.includes(data.name)) {
      const newArr = [...editedFields];
      newArr.push(data.name);
      setEditedFields(newArr);
    }

    if (state.activeMode === "edit") {
      updateSelectedStatus({ ...selectedStatus, [data.name]: data.value });
    } else {
      setExternalStatus({ ...externalStatus, [data.name]: data.value });
    }
  };

  const handleOnBlur = (e, data) => {
    if (e.target.name in validatorConstants.formFieldsNewStatus) {
      validator.validateField(e.target.value, e.target.name);
      setRefreshValidation(!refreshValidation);
    }
  };

  const handleOnDropdownChange = (event, data) => {
    if (data.name in validatorConstants.formFieldsNewStatus) {
      validator.validateField(data.value, data.name);
    }
    if (data.name && !editedFields.includes(data.name)) {
      const newArr = [...editedFields];
      newArr.push(data.name);
      setEditedFields(newArr);
    }
    setStatusNameDefault(data.value);
    let statusArr = bmsStatusOptions();

    let selectedStatus = statusArr.find(status => status.value === data.value);

    setExternalStatus({ ...externalStatus, [data.name]: selectedStatus.value });
  };

  const saveAndNewClicked = searchedName => {
    handle.onSaveAndNewButtonClick(searchedName);
    setStatusNameDefault("");
    setExternalStatus(initialStatusFormState);
  };

  const panelLabel =
    state.activeMode === "edit"
      ? Localize.get("PROJECTS.STATUSES.EDIT_STATUS")
      : Localize.get("PROJECTS.STATUSES.CREATE_NEW");

  const errorBorder = "3px red solid";
  const errorBorderNoRadius = { border: "3px red solid", borderRadius: "0px" };

  return (
    <div className="six wide column" style={editMode ? { zIndex: 1000 } : {}}>
      <div className={styles.padding_left}>
        <div className={`header ${styles.bms_header}`}>{panelLabel}</div>
        <div className={styles.status_form_container}>
          <Form>
            {state.activeMode === "edit" ? (
              <Form.Field required>
                <label>{Localize.get("PROJECTS.STATUSES.STATUS_KEY")}</label>
                <Input
                  name="id"
                  placeholder={Localize.get("PROJECTS.STATUSES.STATUS_KEY")}
                  value={selectedStatus ? selectedStatus.id : externalStatus.id}
                  disabled
                />
              </Form.Field>
            ) : (
              <Form.Field required>
                <label>{Localize.get("PROJECTS.STATUSES.STATUS_KEY")}</label>
                <Input
                  autoComplete={autoComplete.off}
                  name="id"
                  placeholder={Localize.get("PROJECTS.STATUSES.STATUS_KEY")}
                  value={selectedStatus ? selectedStatus.id : externalStatus.id}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  style={{
                    border: validator.parameter.id ? errorBorder : ""
                  }}
                />
                {validator.parameter.id && (
                  <Label basic color="red" pointing="above" content={validator.parameter.id} />
                )}
              </Form.Field>
            )}
            <Form.Field required>
              <label>{Localize.get("PROJECTS.STATUSES.STATUS_NAME")}</label>
              <Input
                autoComplete={autoComplete.off}
                name="name"
                placeholder={Localize.get("PROJECTS.STATUSES.STATUS_NAME")}
                value={selectedStatus ? selectedStatus.name : externalStatus.name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                style={{
                  border: validator.parameter.name ? errorBorder : ""
                }}
              />
              {validator.parameter.name && (
                <Label basic color="red" pointing="above" content={validator.parameter.name} />
              )}
            </Form.Field>
            <Form.Field required>
              <label>{Localize.get("PROJECTS.STATUSES.CHOOSE_STATUS_BMS")}</label>
              <Dropdown
                name="status"
                placeholder={
                  selectedStatus
                    ? selectedStatus.status.name
                    : Localize.get("PROJECTS.STATUSES.CHOOSE_STATUS_BMS")
                }
                icon="dropdown"
                floating
                labeled
                fluid
                direction="left"
                selection
                search
                closeOnChange
                value={statusNameDefault}
                options={bmsStatusOptions()}
                onChange={handleOnDropdownChange}
                style={
                  validator.parameter.status
                    ? {
                        ...errorBorderNoRadius
                      }
                    : null
                }
              />
              {validator.parameter.status && (
                <Label basic color="red" pointing content={validator.parameter.status} />
              )}
            </Form.Field>
          </Form>
        </div>
        <ProjectSaveButtonsGroup
          onSaveButtonClick={handle.onSaveButtonClick}
          onSaveAndNewButtonClick={saveAndNewClicked}
          activeMode={state.activeMode}
          onCancelButtonGroup={handle.onCancelButtonClick}
          setValidator={setValidator}
          objectForValidation={state.activeMode === "edit" ? selectedStatus : externalStatus}
          requiredFields={validatorConstants.formFieldsNewStatus}
          validator={validator}
          objectThatIWantToSave={state.activeMode === "edit" ? selectedStatus : externalStatus}
          editedFields={editedFields}
          myParentElement={elements.projectElements.status}
          setError={setStatusNameErr}
        />
      </div>
    </div>
  );
};

export default ProjectStatusesFormPanel;
