import * as constant from "../Constants";
import { BaseService } from "../Base";

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.documentsCount
    };
    this.query = null;
  }
}
