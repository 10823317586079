import React, { useContext } from "react";
import { AuthContext } from "../../Auth/AuthContext";
import { List, Grid, GridColumn } from "semantic-ui-react";
import Localize from "react-intl-universal";

import { formatDateToString } from "../../Documents/Util";
import styles from "./style/PaymentForm.module.css";

const PaymentGrid = props => {
  const { authState } = useContext(AuthContext);

  const { accounts, paymentObject } = props;

  const getCurrency = () => {
    let currency;
    if (props.currencyid) {
      currency = props.currencyid;
    } else {
      currency = null;
    }

    return currency;
  };

  const displayUser = () => {
    if (authState.user) {
      if (authState.user.lastname) {
        return authState.user.lastname;
      } else if (authState.user.firstname) {
        return authState.user.firstname;
      } else {
        return authState.user.username;
      }
    }
    // hardcoded value - should be changed in the future
    return "CONTRIMO";
  };

  return (
    <>
      <div style={{ paddingBottom: "2px" }}>
        {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_NUMBER")}: {props.payment.docNo}
      </div>

      <div
        style={{
          border: "1px solid #dde1ee",
          color: "#575d62",
          marginBottom: "2px"
        }}
      >
        <Grid
          celled="internally"
          columns="equal"
          style={{ border: "1px solid #dde1ee !important" }}
        >
          <Grid.Row divided className={styles.row_bottom}>
            <GridColumn>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4> {Localize.get("DOCUMENTS.PAYMENT.FROM")}</h4>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.OUR_COMPANY")}</List.Header>
                    <List.Description>{displayUser()}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.ACCOUNT")}</List.Header>
                    <List.Description>
                      {accounts && accounts.length > 0 && paymentObject.companyAccountId
                        ? accounts.find(a => a.id === paymentObject.companyAccountId).accountOwner
                        : ""}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>IBAN</List.Header>
                  <List.Description>
                    {accounts && accounts.length > 0 && paymentObject.companyAccountId
                      ? accounts.find(a => a.id === paymentObject.companyAccountId).iban
                      : ""}
                  </List.Description>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>BIC</List.Header>
                  <List.Description>
                    {accounts && accounts.length > 0 && paymentObject.companyAccountId
                      ? accounts.find(a => a.id === paymentObject.companyAccountId).bic
                      : ""}
                  </List.Description>
                </List.Item>
              </List>
            </GridColumn>
            <GridColumn>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4>{Localize.get("DOCUMENTS.PAYMENT.TO")}</h4>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.PARTNER")}</List.Header>
                    <List.Description>{props.payment.partnerName}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Content>
                    <List.Header>{Localize.get("DOCUMENTS.PAYMENT.ACCOUNT")}</List.Header>
                    <List.Description>{props.payment.recipientName}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>IBAN</List.Header>
                  <List.Description>{props.payment.recipientIban}</List.Description>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>BIC</List.Header>
                  <List.Description>{props.payment.recipientBic}</List.Description>
                </List.Item>
              </List>
            </GridColumn>
            <GridColumn>
              <List>
                <List.Item className={styles.headerMargine}>
                  <h4>{Localize.get("DOCUMENTS.PAYMENT.PAYMENT")}</h4>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Content>
                    <List.Header>
                      {Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT")}
                    </List.Header>
                    <List.Description>{props.payment.purpose}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</List.Header>
                  <List.Description>{props.payment.amount.toString()}</List.Description>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>{Localize.get("DOCUMENTS.PAYMENT.DATE")}</List.Header>
                  <List.Description>
                    {formatDateToString(props.payment.executionDate)}
                  </List.Description>
                </List.Item>
                <List.Item className={styles.listItemMarginSmall}>
                  <List.Header>{Localize.get("DOCUMENTS.PAYMENT.CURRENCY")}</List.Header>
                  <List.Description>{getCurrency()}</List.Description>
                </List.Item>
              </List>
            </GridColumn>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};
export default PaymentGrid;
