import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Localize from "react-intl-universal";
import { Segment, Icon } from "semantic-ui-react";
import EditFormRole from "./EditFormRole";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import styles from "./style/EditRoles.module.css";

const EditRoles = forwardRef((props, ref) => {
  const { roles, handleOnPlusClicked, generatedRoles } = props;

  const [validator, setValidator] = useState(
    new Validator(validatorConstants.requiredFieldsTemplateRoles)
  );

  const handleOnPlusClick = () => {
    handleOnPlusClicked();
  };
  const handleDelete = (index, role) => {
    const newRoles = roles.filter((role, i) => i !== index);
    props.handleDeleteRole(newRoles, role);
  };

  useEffect(() => {
    if (props.formErrorMessage && props.roles && props.roles.length > 0) {
      props.roles.every(function(filter, index, array) {
        return validator.addNestedErrorMsgParam(props.formErrorMessage, "roles[" + index + "]");
      });
      setValidator({ ...validator });
    }
  }, [props.formErrorMessage]);

  useImperativeHandle(ref, () => ({
    validateFormOnSave(refRoles) {
      if (refRoles === undefined) {
        refRoles = "";
      }

      let valid = false;
      if (refRoles.length > 0) {
        valid = refRoles.every(function(element) {
          return validator.validateOnSave(
            validatorConstants.requiredFieldsTemplateRoles,
            element.resourceRole
          );
        });
      } else {
        return true;
      }
      setValidator({ ...validator });
      return valid;
    }
  }));

  const roleForms = () =>
    roles.map((role, index) => (
      <EditFormRole
        validator={validator}
        key={index}
        index={index}
        role={role}
        generatedRoles={generatedRoles}
        onChange={props.onChange}
        handleDelete={handleDelete}
        title={Localize.get("PROJECTS.TEMPLATES.ROLES_TITLE")}
      />
    ));

  return (
    <>
      <Segment color="teal" clearing inverted>
        <div className={styles.roles_segment}>
          <strong>{Localize.get("PROJECTS.TEMPLATES.EDIT_ROLE")}</strong>
        </div>
        <div className={`formAddButton ${styles.plus_icon}`}>
          <Icon name="plus" onClick={handleOnPlusClick} />
        </div>
      </Segment>
      <div className={styles.role_list_of_items}>{roleForms()}</div>
    </>
  );
});

export default EditRoles;
