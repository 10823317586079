import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Accordion, List, Icon, Divider, Transition } from "semantic-ui-react";
import CountryList from "../../Utils/countriesNameAndCodes.json";
import styles from "./style/DetailsAddress.module.css";

const DetailsAddress = props => {
  const [activeAccordion, setActiveAccordion] = useState(props.open);

  const handleAccordionClick = (e, titleProps) => {
    return setActiveAccordion(!activeAccordion);
  };

  const findCountry = () => {
    let userCountryListOfCountries = CountryList[0].list;

    if (navigator.language.toLowerCase().includes("de")) {
      userCountryListOfCountries = CountryList.find(countryItem =>
        countryItem.country.includes("de")
      ).list;
    }

    let myCountry = userCountryListOfCountries.find(
      country => country.value === props.address.country
    );

    return myCountry && myCountry.label ? myCountry.label : "";
  };

  useEffect(() => {
    setActiveAccordion(props.open);
  }, [props.address]);

  return props.address ? (
    <Accordion styled className={styles.address_details}>
      <Accordion.Title
        className={styles.address_header}
        active={activeAccordion}
        onClick={handleAccordionClick}
      >
        <Icon name="dropdown" />
        {props.title}
      </Accordion.Title>
      <Transition visible={activeAccordion} animation="fade down" duration={300}>
        <Accordion.Content active={activeAccordion}>
          <List verticalAlign="middle" className={styles.address_list}>
            <List.Item className={styles.address_line}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.STREET")}</strong>
              </List.Content>
              <List.Content>{props.address.street}</List.Content>
            </List.Item>

            <Divider className={styles.divider_line} />

            <List.Item className={styles.address_line}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.CITY")}</strong>
              </List.Content>
              <List.Content>{props.address.city}</List.Content>
            </List.Item>

            <Divider className={styles.divider_line} />

            <List.Item className={styles.address_line}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.POSTCODE")}</strong>
              </List.Content>
              <List.Content>{props.address.zip}</List.Content>
            </List.Item>

            <Divider className={styles.divider_line} />

            <List.Item className={styles.address_line}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.STATE")}</strong>
              </List.Content>
              <List.Content>{props.address.state}</List.Content>
            </List.Item>
            <Divider className={styles.divider_line} />
            <List.Item className={styles.address_line}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.COUNTRY")}</strong>
              </List.Content>
              <List.Content>{findCountry()}</List.Content>
            </List.Item>
          </List>
        </Accordion.Content>
      </Transition>
    </Accordion>
  ) : null;
};

export default DetailsAddress;
