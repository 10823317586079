import React, { useState, useRef, useEffect } from "react";
import Localize from "react-intl-universal";
import { Button, Icon, Modal, Header, Grid, Popup } from "semantic-ui-react";
import CompanyForm from "../../../Contacts/RightPanel/ContactFormCompany";
import PersonForm from "../../../Contacts/RightPanel/ContactFormPerson";
import { getNewEmptyContact } from "../../../Contacts/Util";
import styles from "./style/AddNewPartner.module.css";
import DocumentDisplay from "../../MiddlePanel/DocumentDisplay";
import * as constant from "../../Constants";

const CompanyModal = props => {
  const {
    showModal,
    privileges,
    state,
    handler,
    handle,
    documentPreview,
    isPDFPreview,
    linkedDocumentsPreview,
    addNewPartnerDisplayActive,
    documentDirection,
    partnerOCRRead
  } = props;
  const [contactFromDoc, setContactFromDoc] = useState({
    ...getNewEmptyContact(),
    ...partnerOCRRead
  });
  const [editedFields, setEditedFields] = useState([]);
  const refAddress = useRef(null);

  useEffect(() => {
    setContactFromDoc({ ...getNewEmptyContact(), ...partnerOCRRead });
  }, []);

  const handleOnClick = (event, data) => {
    const newContact = contactFromDoc;

    if (newContact !== undefined) {
      if (refAddress.current.validateFormOnSave(newContact)) {
        handle.contactFormPanel.onSaveButtonClick(newContact, "company");
      }
    } else {
      refAddress.current.validateFormOnSave({});
    }
  };

  return (
    <Modal
      open={showModal}
      closeOnEscape={true}
      closeOnDimmerClick={false}
      dimmer="blurring"
      centered={true}
      className="modalScreenForm"
      closeIcon={true}
      trigger={
        <Button primary icon>
          {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.COMPANY")} <Icon name="right chevron" />
        </Button>
      }
    >
      <Modal.Content className={styles.companyFormHeight}>
        <Grid columns={2}>
          <Grid.Column width={8} className={styles.document_display_column}>
            <Header className={styles.headerDocument}>
              {Localize.get("DOCUMENTS.NEWDOCUMENT.DOCUMENT_LINKED_TABLE_HEADER")}
            </Header>
            <DocumentDisplay
              privileges={privileges}
              state={state}
              handler={handler}
              documentPreview={documentPreview}
              isPDFPreview={isPDFPreview}
              linkedDocumentsPreview={linkedDocumentsPreview}
              addNewPartnerDisplayActive={addNewPartnerDisplayActive}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Header className={styles.headerCreateNew}>
              {" "}
              {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.NEW_COMPANY")}
            </Header>
            <div className={styles.form_part}>
              <CompanyForm
                ref={refAddress}
                mode="create"
                documentDirection={documentDirection}
                contact={contactFromDoc}
                setContact={setContactFromDoc}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon
          contact={contactFromDoc}
          onClick={handleOnClick}
          className={styles.button_action}
        >
          <Icon name="check" />
          {Localize.get("GLOBAL.FORM.SAVE")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const PersonModal = props => {
  const {
    partnerOCRRead,
    handle,
    showModal,
    documentDirection,
    privileges,
    state,
    handler,
    documentPreview,
    isPDFPreview,
    linkedDocumentsPreview,
    addNewPartnerDisplayActive
  } = props;
  const [contactFromDoc, setContactFromDoc] = useState({
    ...getNewEmptyContact(),
    ...partnerOCRRead
  });
  const [editedFields, setEditedFields] = useState([]);
  const refAddress = useRef(null);

  useEffect(() => {
    setContactFromDoc({
      ...getNewEmptyContact(),
      ...partnerOCRRead
    });
  }, []);

  const handleOnClick = (event, data) => {
    const newContact = contactFromDoc;
    if (newContact !== undefined) {
      if (refAddress.current.validateFormOnSave(newContact)) {
        handle.contactFormPanel.onSaveButtonClick(newContact, "person");
      }
    } else {
      refAddress.current.validateFormOnSave({});
    }
  };

  return (
    <Modal
      open={showModal}
      closeOnEscape={true}
      closeOnDimmerClick={false}
      dimmer="blurring"
      centered={true}
      className="modalScreenForm"
      closeIcon={true}
      trigger={
        <Button
          color="orange"
          icon
          disabled={documentDirection === constant.documentDirection.incoming ? true : false}
        >
          {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.PERSON")} <Icon name="right chevron" />
        </Button>
      }
    >
      <Modal.Content className={styles.companyFormHeight}>
        <Grid columns={2}>
          <Grid.Column width={8} className={styles.document_display_column}>
            <Header className={styles.headerDocument}>
              {Localize.get("DOCUMENTS.NEWDOCUMENT.DOCUMENT_LINKED_TABLE_HEADER")}
            </Header>
            <DocumentDisplay
              privileges={privileges}
              state={state}
              handler={handler}
              documentPreview={documentPreview}
              isPDFPreview={isPDFPreview}
              linkedDocumentsPreview={linkedDocumentsPreview}
              addNewPartnerDisplayActive={addNewPartnerDisplayActive}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Header className={styles.headerCreateNew}>
              {" "}
              {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.NEW_PERSON")}
            </Header>
            <div className={styles.form_part}>
              <PersonForm
                contact={contactFromDoc}
                setContact={setContactFromDoc}
                ref={refAddress}
                mode="create"
                editedFields={editedFields}
                setEditedFields={setEditedFields}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon
          contact={contactFromDoc}
          onClick={handleOnClick}
          className={styles.button_action}
        >
          <Icon name="check" /> {Localize.get("GLOBAL.FORM.SAVE")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const AddNewPartner = props => {
  const {
    partnerPrivileges,
    handle,
    showModal,
    state,
    documentPreview,
    isPDFPreview,
    linkedDocumentsPreview,
    validator,
    formErrorMessage,
    documentDirection,
    partnerOCRRead,
    disabled = false
  } = props;
  const handleOpenModal = (event, data) => {
    handle.contactFormPanel.handleOpenModal(event, data);
  };
  const handleCloseModal = (event, data) => {
    handle.contactFormPanel.onCancelButtonClick(event, data);
  };
  return (
    <Modal
      open={showModal}
      size="small"
      closeOnDocumentClick
      dimmer="blurring"
      trigger={
        <Popup
          content={Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.NEW_CONTACT")}
          size="small"
          trigger={
            <Button
              icon
              className={styles.button_height}
              primary
              onClick={handleOpenModal}
              disabled={!partnerPrivileges.create || disabled}
            >
              <Icon name="plus" />
            </Button>
          }
        />
      }
    >
      <Modal.Header> {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.SELECT_PARTNER")}</Modal.Header>

      <Modal.Content>
        <Modal.Description>
          {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.OPERATION_PARTNER")}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <CompanyModal
          isPDFPreview={isPDFPreview}
          linkedDocumentsPreview={linkedDocumentsPreview}
          documentPreview={documentPreview}
          state={state}
          handle={handle}
          validator={validator}
          formErrorMessage={formErrorMessage}
          addNewPartnerDisplayActive={showModal}
          documentDirection={documentDirection}
          partnerOCRRead={partnerOCRRead}
        />
        <PersonModal
          handle={handle}
          isPDFPreview={isPDFPreview}
          linkedDocumentsPreview={linkedDocumentsPreview}
          documentPreview={documentPreview}
          state={state}
          validator={validator}
          formErrorMessage={formErrorMessage}
          addNewPartnerDisplayActive={showModal}
          documentDirection={documentDirection}
          partnerOCRRead={partnerOCRRead}
        />
        <Button icon className={styles.button_action_cancel} onClick={handleCloseModal}>
          {Localize.get("GLOBAL.FORM.CANCEL")} <Icon name="cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddNewPartner;
