import React from "react";
import { Icon, Dimmer, Popup, Button, Message, Dropdown } from "semantic-ui-react";
import DocumentUpload from "./DocumentUpload";
import DocumentScansPanel from "./DocumentScansPanel";
import DocumentForm from "./DocumentForm";
import DocumentLinkThumbnails from "./DocumentLinkThumbnails";
import DocumentDetails from "./DocumentDetails";
import DocumentShowScan from "./DocumentShowScan";
import ProjectsPanel from "./ProjectsPanel";
import * as constant from "../Constants.js";
import Localize from "react-intl-universal";
import styles from "./style/RightPanel.module.css";
import { formatProjectsEditDoc } from "../Util";

const RightPanel = props => {
  const {
    privileges,
    projectPrivileges,
    partnerPrivileges,
    paymentPrivileges,
    manualPaymentPrivileges,
    editMode,
    state,
    document,
    documents,
    setThumbsToSend,
    setNewlyCreatedDocument,
    createCommentSave,
    parentDocumentForDiagram,
    parentDocumentForTable,
    scanedFiles,
    isLoadingScansList,
    handler,
    linkedDocumentsPreview,
    verificationLoading,
    documentsCommentHistory,
    thumbsToSend,
    isLoadingForm,
    showNotificationMessage,
    isPDFPreview,
    documentPreview,
    setIsLoading,
    setIsLoadingLoader
  } = props;

  const showScanHandler = () => {
    handler.showScanHandler();
  };

  const handleOnEditClick = (event, data) => {
    if (privileges.update) handler.documentForm.onEditDocument();
  };

  const handleOnArchiveClick = (event, data) => {
    if (privileges.update) {
      if (document) handler.onArchiveClick(document.id, document.archived);
    }
  };

  const handleOnDeleteClick = (event, data) => {
    if (privileges.delete && documents.length !== 0) handler.documentList.onRightPanelDeleteClick();
  };

  let deleteAction =
    privileges.delete &&
    (props.actions && props.actions.ToolbarRules ? props.actions.ToolbarRules.delete : false);

  // ---- TO DO----
  // let editAction =
  //   privileges.update &&
  //   (props.actions && props.actions.ToolbarRules
  //     ? props.actions.ToolbarRules.edit
  //     : false);

  const exportOptions = [
    {
      key: "download",
      text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.DOWNLOAD_PDF")
    },
    { key: "send", text: Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.SEND_PDF") }
  ];

  const editDocComponent = () => {
    return (
      <Popup
        size="mini"
        content={Localize.get("DOCUMENTS.DETAILS.EDIT_DOCUMENT")}
        trigger={
          <Icon
            onClick={handleOnEditClick}
            name="edit"
            className={styles.header_icon}
            disabled={
              document
                ? document.progressStatus &&
                  document.progressStatus !== constant.progressStatus.imported
                : true
            }
            // disabled={   ----- TO DO ----
            //   document && document.direction === "incoming"
            //     ? !editAction
            //     : !editAction ||
            //       (document &&
            //         document.progressStatus &&
            //         document.progressStatus !==
            //           constant.progressStatus.imported)
            // }
          />
        }
      />
    );
  };

  const archiveDocComponent = () => {
    return (
      <Popup
        size="mini"
        content={
          document && document.archived
            ? Localize.get("CONTACTS.DETAILS.TOOLBAR.UNARCHIVE")
            : Localize.get("CONTACTS.DETAILS.TOOLBAR.ARCHIVE")
        }
        trigger={
          <Icon.Group
            onClick={handleOnArchiveClick}
            disabled={!privileges.update ? true : document ? false : true}
          >
            <Icon name="archive" disabled={!privileges.update ? true : document ? false : true} />
            <Icon
              corner
              name={document && document.archived === true ? "minus circle" : "plus circle"}
              disabled={!privileges.update ? true : document ? false : true}
            />
          </Icon.Group>
        }
      />
    );
  };

  const deleteDocComponent = () => {
    return (
      <Popup
        size="mini"
        content={Localize.get("CONTACTS.DETAILS.TOOLBAR.DELETE")}
        trigger={
          <Icon
            link={deleteAction}
            disabled={
              document
                ? document.progressStatus &&
                  document.progressStatus !== constant.progressStatus.imported
                : true
            }
            name="trash alternate"
            className={styles.icon_trash}
            onClick={handleOnDeleteClick}
          />
        }
      />
    );
  };

  const createPdfComponent = () => {
    return (
      <>
        {state.activeDocumentDirectionFilter === constant.documentDirection.outgoing &&
          document &&
          document.progressStatus === constant.progressStatus.imported &&
          document.verificationStatus === constant.verificationStatus.verified && (
            <Popup
              size="mini"
              content={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.MAKE_PDF")}
              trigger={<Icon name="file pdf outline" className={styles.pdf_icon} />}
            />
          )}
      </>
    );
  };

  const sentComponent = () => {
    return (
      <Popup
        size="mini"
        content={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EXPORT_PDF")}
        trigger={
          <Dropdown
            className={styles.export_dropdown}
            trigger={<Icon name="caret square right outline" />}
            options={exportOptions}
            pointing="top right"
            icon={null}
          />
        }
      />
    );
  };

  const headerRightPanel =
    state.activeRightPanelMode === constant.rightPanelMode.documentDetails ||
    state.activeRightPanelMode === constant.rightPanelMode.documentShowScan ||
    state.activeRightPanelMode === constant.rightPanelMode.linkDocumentPreview ? (
      <div id={styles.document_right_header} className={`item ${styles.right_panel_item}`}>
        <div
          className={
            editMode
              ? `top aligned content ${styles.headerOnPay}`
              : `top aligned content ${styles.headerOnNotPay}`
          }
          style={editMode === true ? { marginTop: "1px" } : { marginTop: "2.5rem" }}
        >
          <div>{Localize.get("DOCUMENTS.DETAILS.DOCUMENTS_STATUS")}</div>
          {state.activeRightPanelMode === constant.rightPanelMode.documentShowScan ||
          state.activeRightPanelMode === constant.rightPanelMode.linkDocumentPreview ? (
            <div>
              <Icon name="eye slash" size="large" onClick={showScanHandler} />
              {editDocComponent()}
              {archiveDocComponent()}
            </div>
          ) : documentsCommentHistory !== undefined &&
            documentsCommentHistory.length > 0 &&
            state.activeRightPanelMode !== constant.rightPanelMode.documentShowScan ? (
            <div>
              {editDocComponent()}
              {createPdfComponent()}
              {archiveDocComponent()}
              {deleteDocComponent()}
            </div>
          ) : (
            <div>
              {editDocComponent()}
              {createPdfComponent()}
              {state.activeDocumentDirectionFilter === constant.documentDirection.outgoing &&
                document &&
                document.progressStatus === constant.progressStatus.pdfCreated &&
                sentComponent()}
              {archiveDocComponent()}
              {deleteDocComponent()}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div />
    );

  let activeRightPanelComponent;
  switch (state.activeRightPanelMode) {
    case constant.rightPanelMode.documentUpload:
      activeRightPanelComponent = (
        <DocumentUpload state={state} handler={handler} isLoadingForm={isLoadingForm} />
      );
      break;

    case constant.rightPanelMode.documentScansPanel:
      activeRightPanelComponent =
        scanedFiles.length > 0 ? (
          <DocumentScansPanel
            state={state}
            handler={handler}
            isLoadingScansList={isLoadingScansList}
          />
        ) : (
          <Message icon className={styles.right_panel_msg}>
            <Icon name="info circle" />
            {Localize.get("DOCUMENTS.SCAN.EMPTY")}
          </Message>
        );
      break;

    case constant.rightPanelMode.documentShowScanFromFolder:
      activeRightPanelComponent = (
        <>
          <div className={`header ${styles.bms_header}`}>
            {Localize.get("DOCUMENTS.THUMBNAILS.HEADER_THUMBNAILS")}
          </div>
          <div className={styles.header_pop_up}>
            <Popup
              content={Localize.get("DOCUMENTS.SCAN.CANCEL_PREVIEW")}
              trigger={
                <Button
                  basic
                  icon="eye slash outline"
                  onClick={handler.documentScansPanel.onCancelScanedItemPreview}
                />
              }
            />
          </div>
          <DocumentShowScan linkedDocumentsPreview={linkedDocumentsPreview} />
        </>
      );
      break;

    case constant.rightPanelMode.documentForm:
      activeRightPanelComponent = (
        <DocumentForm
          isPDFPreview={isPDFPreview}
          documentPreview={documentPreview}
          linkedDocumentsPreview={linkedDocumentsPreview}
          state={state}
          handler={handler}
          document={document}
          documents={documents}
          thumbsToSend={thumbsToSend}
          showNotificationMessage={showNotificationMessage}
          projectPrivileges={projectPrivileges}
          partnerPrivileges={partnerPrivileges}
          isLoadingForm={isLoadingForm}
          invalidInvoiceAmount={props.invalidInvoiceAmount}
          invalidCorrectionAmount={props.invalidCorrectionAmount}
        />
      );
      break;

    case constant.rightPanelMode.documentLinkThumbnails:
      activeRightPanelComponent = (
        <DocumentLinkThumbnails
          state={state}
          handler={handler}
          documents={documents}
          currentDocument={document}
          setNewlyCreatedDocument={setNewlyCreatedDocument}
          setThumbsToSend={setThumbsToSend}
          thumbsToSend={thumbsToSend}
          isLoadingForm={isLoadingForm}
        />
      );
      break;

    case constant.rightPanelMode.projectsPanel:
      activeRightPanelComponent = (
        <ProjectsPanel
          state={state}
          handler={handler}
          document={document}
          projects={formatProjectsEditDoc(document.projects)}
          projectPrivileges={projectPrivileges}
        />
      );
      break;

    case constant.rightPanelMode.documentDetails:
      activeRightPanelComponent = (
        <>
          {document && document.id && (
            <DocumentDetails
              state={state}
              privileges={privileges}
              paymentPrivileges={paymentPrivileges}
              manualPaymentPrivileges={manualPaymentPrivileges}
              handler={handler}
              document={document}
              documentsCommentHistory={documentsCommentHistory}
              parentDocumentForDiagram={parentDocumentForDiagram}
              parentDocumentForTable={parentDocumentForTable}
              createCommentSave={createCommentSave}
              verificationLoading={verificationLoading}
              setIsLoading={setIsLoading}
              setIsLoadingLoader={setIsLoadingLoader}
            />
          )}
        </>
      );
      break;

    case constant.rightPanelMode.documentShowScan:
      activeRightPanelComponent = (
        <DocumentShowScan linkedDocumentsPreview={linkedDocumentsPreview} />
      );
      break;

    case constant.rightPanelMode.linkDocumentPreview:
      activeRightPanelComponent = (
        <DocumentShowScan linkedDocumentsPreview={linkedDocumentsPreview} />
      );
      break;

    default:
      break;
  }

  return (
    <>
      <div
        className={`${
          editMode
            ? state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay
              ? "six wide column"
              : "eight wide column"
            : "six wide column"
        }  ${styles.column_height}`}
      >
        <Dimmer
          active={state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay}
          inverted
        ></Dimmer>
        {headerRightPanel}

        {activeRightPanelComponent}
      </div>
    </>
  );
};

export default RightPanel;
