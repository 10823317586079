import React from "react";
import { Menu, Grid, Dimmer, Message, Icon } from "semantic-ui-react";
import BankAccountItem from "./BankAccountItem";
import Localize from "react-intl-universal";
import ScrollableList from "../../Components/ScrollableList";
import styles from "./style/BankAccountsItemList.module.css";

const BankAccountsItemList = props => {
  const { privileges, editMode, accounts, handlerItem, state, fetchListItems, isFetching } = props;

  const accountList = accounts.map(a => (
    <BankAccountItem
      handlerItem={handlerItem}
      onListItemClick={handlerItem.onListItemClick}
      onEditItemClick={handlerItem.onEditItemClick}
      onReactivateClick={handlerItem.onReactivateClick}
      key={a.id}
      privileges={privileges}
      account={a}
      state={state}
      onDeleteItemClick={handlerItem.onDeleteItemClick}
    />
  ));

  let sumOfBalances = accounts.reduce((sum, account) => sum + account.balance, 0);
  const sumOfNotAssignedSales = accounts.reduce(
    (sum, account) => sum + account.numberOfUnlinkedTransactions,
    0
  );

  const countOfAccounts = accounts.length;
  const colorBalanceLabel = sumOfBalances > 0 ? `${styles.positive}` : `${styles.negative}`;

  sumOfBalances = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  }).format(sumOfBalances);

  return (
    <div
      className={
        !editMode
          ? `sixteen wide column ${styles.bms_vertical}`
          : `ten wide column ${styles.bms_vertical}`
      }
    >
      <Dimmer active={editMode} inverted></Dimmer>
      <div className={`ui vertical menu ${styles.bms_header}`}>
        <Menu.Item>
          <Grid columns="6" stackable container verticalAlign="middle">
            <Grid.Column width={2}>Status</Grid.Column>
            <Grid.Column width={2}>
              <span className={colorBalanceLabel}>
                {sumOfBalances > 0 ? "+" + sumOfBalances : sumOfBalances}
              </span>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="content">
                <div className={`${styles.list_name} ${styles.bms_bold_header}`}>
                  {Localize.get("ACCOUNTS.ITEMLIST.ALL_ACCOUNTS")}
                </div>
                <div className={styles.list_desc}>
                  {Localize.get("ACCOUNTS.ITEMLIST.SUM_OF_ALL_ACCOUNTS", {
                    countOfAccounts: countOfAccounts
                  })}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4} />
            <Grid.Column width={2}>
              <div className={styles.sales_assignment}>
                {Localize.get("ACCOUNTS.ITEMLIST.TRANSACTIONS_NOT_LINKED_HEADER", {
                  sumOfNotAssignedSales: sumOfNotAssignedSales
                })}
              </div>
            </Grid.Column>
          </Grid>
        </Menu.Item>
      </div>

      {accountList.length !== 0 ? (
        <ScrollableList
          list={accountList}
          state={state}
          fetchListItems={fetchListItems}
          isFetching={isFetching}
        />
      ) : (
        <Message icon className={styles.empty_list_message}>
          <Icon name="info circle" />
          {Localize.get("ACCOUNTS.ITEMLIST.EMPTY_LIST")}
        </Message>
      )}
    </div>
  );
};

export default BankAccountsItemList;
