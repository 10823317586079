import React, { createContext, useReducer } from "react";
// eslint-disable-next-line no-unused-vars
import { roles, role } from "./RolePrivileges";
import Localize from "react-intl-universal";
import showNotification from "../Notifications/NotificationFunction";
import { docsInitFilterState, partnersInitStateFilters } from "./Constants";

const getLocalStorage = key => {
  let data = window.localStorage.getItem("store") || null;
  data = JSON.parse(data);
  if (!data) return null;
  return key in data ? data[key] : null;
};

const setLocalStorage = (key, value) => {
  let data = window.localStorage.getItem("store") || null;
  data = JSON.parse(data) || {};
  data[key] = value;
  window.localStorage.setItem("store", JSON.stringify(data));
};

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const serverAuthoritiesToList = serverAuthorities => {
  return serverAuthorities.map(data => data["authority"].toLowerCase().split("_"));
};

const getCategoryFromData = data => {
  // category
  return data.reduce((acc, value, i) => {
    // eslint-disable-next-line eqeqeq
    if (i == 0) return value;
    return `${acc}${capitalize(value)}`;
  }, "");
};

const transformAuthoritiesData = serverAuthorities => {
  const authoritiesList = serverAuthoritiesToList(serverAuthorities);
  // from list to object
  const authorities = authoritiesList.reduce(
    (acc, value) => {
      let res = { ...acc };
      const method = value[0]; // method => get, create, update, delete, count
      const category = getCategoryFromData(value.slice(1)); // without first element

      res[category] = {
        ...acc[category],
        [method]: true
      };

      return res;
    },
    { ...role }
  );
  return authorities;
};

/* AUTH CONTEXT */
const AuthContext = createContext();
const auth = getLocalStorage("auth");
const initialState = {
  user: auth ? auth.user : null,
  token: auth ? auth.token : null,
  session: auth ? auth.session : null,
  userAuthorites: auth ? auth.userAuthorites : null,
  sessionExpired: auth ? auth.sessionExpired : false
};

const localStorageFilterState = getLocalStorage("filtersState");
const filtersInitState = localStorageFilterState
  ? localStorageFilterState
  : {
      documents: docsInitFilterState,
      partners: partnersInitStateFilters
    };

const authReducer = (authState, action) => {
  switch (action.type) {
    case "set":
      // here call function to transform
      const userAuthorites = transformAuthoritiesData(action.payload.user.authorities);
      setSession(action.payload.session);
      setLocalStorage("auth", {
        ...action.payload,
        userAuthorites: userAuthorites
      });
      return {
        ...authState,
        ...action.payload,
        userAuthorites: userAuthorites
      };
    case "setLanguage":
      let myNewAuthState = {
        ...authState,
        user: { ...authState.user, language: action.payload }
      };
      setLocalStorage("auth", myNewAuthState);
      return myNewAuthState;
    case "get":
      return { ...authState };
    case "del":
      localStorage.clear();
      setSession(null);
      return {};
    case "expired":
      let tempStore = JSON.parse(localStorage.getItem("store"));
      localStorage.clear();
      if (tempStore) {
        setLocalStorage("auth", {
          ...authState,
          user: {
            firstname: tempStore.auth.user.firstname,
            lastname: tempStore.auth.user.lastname,
            username: tempStore.auth.user.username
          },
          sessionExpired: true
        });
      }

      setSession(null);
      window.localStorage.setItem("expired_message", true);
      window.location.href = "/";
      return {
        authState
      };
    case "logout":
      localStorage.clear();
      setSession(null);
      window.location.href = "/";
      return {};
    case "error":
      showNotification({
        type: "warning",
        serverMessage: Localize.get("AUTHORIZATION.UNHANDLED_ERROR")
      });
      break;
    default:
      break;
  }
};

const logOutOffUnauthorizedCall = operation => {
  authReducer(null, { type: operation });
};

let session = auth ? auth.session : null;

const setSession = sessionId => {
  session = sessionId;
  return session;
};

const getSession = () => {
  return session;
};

const panelReducer = (filtersState, action) => {
  switch (action.type) {
    case "set":
      setLocalStorage("filtersState", action.payload);
      return action.payload;
    case "resetDocuments":
      let myNewFilterState = {
        ...filtersState,
        documents: docsInitFilterState
      };
      setLocalStorage("filtersState", myNewFilterState);
      return myNewFilterState;
    case "resetPartners":
      let myNewPartnersFilterState = {
        ...filtersState,
        partners: partnersInitStateFilters
      };
      setLocalStorage("filtersState", myNewPartnersFilterState);
      return myNewPartnersFilterState;
    default:
      break;
  }
};

const AuthContextProvider = props => {
  const { toastElement, loadLocales, customConfig } = props;
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const [filtersState, setFiltersState] = useReducer(panelReducer, filtersInitState);
  const rolePrivileges = authState.userAuthorites;
  const value = {
    authState,
    dispatch,
    rolePrivileges,
    toastElement,
    filtersState,
    setFiltersState,
    loadLocales,
    customConfig
  };
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider, getLocalStorage, logOutOffUnauthorizedCall, getSession };
