export const GetAccounts = n => {
  const bics = [
    "UIQIGGS1",
    "IYSECVC1021",
    "GQLIQAO1358",
    "NLPEKRA1048",
    "GYQIFJT1",
    "MGFUZMG1MAU",
    "TGJEVGF1",
    "UREODDG1159",
    "EWAOCLH1",
    "TIYOITO1",
    "DNVOSVP1",
    "LEAAGMO1",
    "FUEOMWA1105",
    "EWHAUGQ1",
    "GIXUCPI1341",
    "FVQIIOV1502",
    "ZQKEFRT1",
    "KYBOLAI1",
    "KJBOFRQ1754",
    "KJPOMKY1",
    "NSOOVAW1",
    "RWZUJML1",
    "QROEHRL1",
    "DAHUETU1",
    "REGUSEG1",
    "NQCIISY1",
    "HZHUSYK1",
    "BQIULCQ1",
    "FHWOUMU1",
    "OXZEBGD1",
    "HSUITGP1",
    "UPDEBTG1",
    "POKIBOS1",
    "IJLAHMO1",
    "VCROTHJ1",
    "GTBABVF1156",
    "QQJUPYS1566",
    "KQLIMOQ1305",
    "MBDOZWK1109",
    "OFZOFOC1",
    "VMKUBMG1",
    "ECTUDGC1",
    "AOKIEEY1",
    "JZVOMGS1823",
    "YGGUGPC1",
    "CTXENCQ1596",
    "ZWUOUGG1720",
    "JAHOVID1117",
    "NZVAFKU1214",
    "NQKAWFL1111"
  ];
  const firstNames = [
    "Liz",
    "Kerem",
    "Zeynep",
    "Elijah",
    "Darleen",
    "Lena",
    "Elaine",
    "Mayra",
    "Claire",
    "Ecrin",
    "Sönke",
    "Antonia",
    "Liliana",
    "Jonas",
    "Cindy",
    "Anouk",
    "Celine",
    "Mika",
    "Logan",
    "Colin",
    "Finnley",
    "Mikail",
    "Nila",
    "Patricia",
    "Davide",
    "Leni",
    "Georg",
    "Valentino",
    "Konstantin",
    "Mailin",
    "Kaya",
    "Franziska",
    "Azra",
    "Leila",
    "Ayse",
    "Antonio",
    "Adam",
    "Murat",
    "Mathis",
    "Ron",
    "Xaver",
    "Semih",
    "Len",
    "Leander",
    "Gerrit",
    "Irem",
    "Kim",
    "Marla",
    "Jared",
    "Danilo"
  ];
  const lastNames = [
    "Köhler",
    "Kreb",
    "Schäfer",
    "Steinert",
    "Soller",
    "Neupert",
    "Hechler",
    "Huber",
    "Gombert",
    "Gehrke",
    "Schwennen",
    "Freisen",
    "Muckenthaler",
    "Wanner",
    "Hartwig",
    "Hudak",
    "Schwab",
    "Brinkmann",
    "Köpernick",
    "Huls",
    "Güldemeister",
    "Möhsner",
    "Milde",
    "Knies",
    "Reichel",
    "Jucken",
    "Kerl",
    "Jungbluth",
    "Kohle",
    "Camara",
    "Aryee",
    "Keiner",
    "Lukoschek",
    "Salzer",
    "Lewke",
    "Rietscher",
    "Rossler",
    "Hechler",
    "Merkel",
    "Schlechtweg",
    "Seigel",
    "Filipowski",
    "Jürgens",
    "Marxen",
    "Klauder",
    "Feld",
    "Böhm",
    "Timmermann",
    "Seidel",
    "Jucken"
  ];
  const bankNames = [
    "Deutsche Bundesbank",
    "Bordesholmer Sparkasse",
    "Bordesholmer Sparkasse",
    "Spar- und Leihkasse zu Bredstedt",
    "Sparkasse Bremen",
    "Hamburger Sparkasse",
    "Sparkasse zu Lübeck",
    "Sparkasse Mittelholstein",
    "HSH Nordbank",
    "Landesbank Baden-Württemberg",
    "Landesbank Berlin",
    "Landesbank Hessen-Thuringen",
    "Bremer Landesbank Kreditanstalt Oldenburg",
    "Landesbank Baden-Württemberg",
    "BayernLB",
    "Commerzbank",
    "Bremer Bank",
    "DAB BNP Paribas",
    "DekaBank Deutsche Girozentrale",
    "Deutsche Pfandbriefbank",
    "Landesbank Baden-Württemberg",
    "Landesbank Hessen-Thüringen",
    "Landwirtschaftliche Rentenbank",
    "NRW.Bank",
    "KfW",
    "Südwestbank AG",
    "Baader Bank AG",
    "Varengold Bank",
    "GLS Bank",
    "N26",
    "Consorsbank"
  ];
  const ibans = [
    "AZ96N7265725170520",
    "DO54KPTE00925468055",
    "AT98006750140250",
    "NL62DEWS0491900481",
    "AL6303905766J8Y8AJ2",
    "IT31G00908006650P6J",
    "NO6407410040057",
    "GB34BJBB80021105013915",
    "HU55500252323713087768",
    "BG75ZFRX0785325X9703JO",
    "RS20770686000605002480",
    "LT860048927005520982",
    "NL53IRBU1009547498",
    "MR0989013003971007202",
    "MT04OVKC10017472W445VA759",
    "XK800441005008051002",
    "AL84500400840496689787H53950",
    "SK5467861620680019848084",
    "BG45ZPAY0080093CCN",
    "SI24008871022397448",
    "GB14LQWY0060201980",
    "JO26XZPQ0062850086460",
    "FI4105000834310282",
    "BE84031390382084",
    "GL6990043285007001",
    "LB3359698035U0835432",
    "PK0563880820700014062311",
    "TL4313200300060432435",
    "BE73008681700157",
    "XK440864894107014091",
    "DK8908080166960538",
    "IL66650789807446798",
    "VG518A16655766786",
    "TL26043900800902598",
    "6567000126013067029",
    "SM19K0406880041K12499836",
    "LB45689548249924DB1I1103",
    "RS45692552607269001547",
    "CR5711705910030783829",
    "DE05402143486900608838",
    "GR77007024500559N7M65N4VI75",
    "CH15510050T5128923197",
    "MR0801424005141870930664892",
    "FO7130076567002005",
    "FI8614252148787001",
    "KW73OEUY48443455WD1Z30PW60",
    "NL14GZYM8003080302",
    "TR53906018669095R19798",
    "BG16SRDO0277786X809E48",
    "NO3700210070140"
  ];
  const accounts = [];

  for (let i = 1; i <= n; i++) {
    const account = {};
    account.id = i;
    account.balance = Math.round(Math.floor((Math.random() - 0.5) * 2 * 1000000) * 100) / 100;
    account.bankName = bankNames[Math.floor(Math.random() * bankNames.length)];
    account.accountOwner =
      firstNames[Math.floor(Math.random() * firstNames.length)] +
      " " +
      lastNames[Math.floor(Math.random() * lastNames.length)];
    account.iban = ibans[Math.floor(Math.random() * ibans.length)];
    account.bic = bics[Math.floor(Math.random() * bics.length)];
    account.checked = false;
    account.numberOfSales = Math.round(Math.random() * 5);
    accounts.push(account);
  }

  return accounts;
};

export const GetUpdateObject = newAccount => {
  if (newAccount) {
    const updObj = [
      {
        op: "replace",
        path: "/accountOwner",
        value: newAccount.accountOwner
      }
    ];

    return updObj;
  }

  return [];
};

export const ReactivateAccount = newAccount => {
  if (newAccount) {
    const updObj = [
      {
        op: "replace",
        path: "/active",
        value: true
      }
    ];

    return updObj;
  }

  return [];
};
