import React, { useEffect } from "react";
import GlobalDetails from "../../Components/Panels/GlobalDetails";
import GlobalForm from "../../Components/Panels/GlobalForm";
import RolesDetails from "./RolesDetails";
import RolesForm from "./RolesForm";
import useValidator from "../../Utils/useValidator";
import Localize from "react-intl-universal";
import { settingsRoleSchema } from "../../Utils/SettingsSchema";

const RolesRightPanel = props => {
  const {
    currentView,
    setCurrentView,
    currentModule,
    selectedItem,
    deleteItem,
    callGetAll
  } = props;

  const [parameters, , validateOnSave, validateField, setParameters] = useValidator(
    settingsRoleSchema
  );

  const customProperties = {
    selectedItem,
    setCurrentView,
    currentView,
    currentModule
  };

  const customPropertiesCreate = {
    selectedItem: { name: "", permissions: [] },
    setCurrentView,
    currentView,
    currentModule
  };

  const customOnSaveValidation = currentItem => {
    let validateRole = validateOnSave(currentItem);

    if (validateRole) {
      return true;
    }
    return false;
  };

  const customOnChangeValidation = (field, value) => {
    if (parameters && parameters[field] && parameters[field] !== "") {
      validateField(field, value);
    }
  };

  const myErrorHander = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorMessages = {};
      if (error.response && error.response.data && error.response.data.errors) {
        let errorList = error.response.data.errors;
        // eslint-disable-next-line array-callback-return
        errorList.map(item => {
          errorMessages[item.field] = Localize.get(item.defaultMessage);
        });
        setParameters(prevPara => {
          return { ...prevPara, ...errorMessages };
        });
        showNotificationMessage({
          type: "warning",
          serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
        });
      } else {
        showNotificationMessage({
          type: "warning",
          serverMessage: error.response.data.message
        });
      }
    }
  };

  const myErrorHandlerEdit = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorResponse = error.response.data;
      let errorObj = {};
      // eslint-disable-next-line array-callback-return
      errorResponse.map(item => {
        errorObj[item.validationSubject] = Localize.get(item.validationMessage);
      });
      setParameters(prevPara => {
        return { ...prevPara, ...errorObj };
      });
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };

  useEffect(() => {
    setParameters(prevPara => {
      return {
        ...Object.keys(prevPara).reduce((reduced, key) => ({ ...reduced, [key]: "" }), {})
      };
    });
  }, [currentView]);

  return (
    <>
      {selectedItem && currentView === "details" && (
        <GlobalDetails
          detailsHeaderText={"SETTINGS.HEADERS.ROLE_DETAILS"}
          {...customProperties}
          CustomDetails={RolesDetails}
          deleteItem={deleteItem}
        />
      )}
      {selectedItem && currentView === "edit" && (
        <GlobalForm
          formHeaderText={"SETTINGS.HEADERS.EDIT_ROLE"}
          {...customProperties}
          CustomForm={RolesForm}
          emptyObject={{ name: "", permissions: [] }}
          customOnSaveValidation={customOnSaveValidation}
          customOnChangeValidation={customOnChangeValidation}
          parameters={parameters}
          customErrorHandler={myErrorHandlerEdit}
        />
      )}

      {currentView === "create" && (
        <GlobalForm
          formHeaderText={"SETTINGS.HEADERS.CREATE_ROLE"}
          {...customPropertiesCreate}
          CustomForm={RolesForm}
          emptyObject={{ name: "", permissions: [] }}
          callGetAll={callGetAll}
          customOnSaveValidation={customOnSaveValidation}
          customOnChangeValidation={customOnChangeValidation}
          parameters={parameters}
          customErrorHandler={myErrorHander}
        />
      )}
    </>
  );
};
export default RolesRightPanel;
