export const DEFAULTCONFIGURATION = {
  fields: null
};

export const formFieldsNewAccount = {
  accountOwner: ["whitespaceBeginFilter", "longerThan255"],
  iban: ["characterFilter", "whitespaceBeginFilter", "longerThan34"],
  bic: ["characterFilter", "whitespaceBeginFilter", "longerThan11"]
};

export const formFieldsPaypalAccount = {
  accountOwner: ["whitespaceBeginFilter", "longerThan255"],
  accountNumber: ["emailFormat", "longerThan224"]
};

export const requiredFieldsTemplateRoles = {
  name: ["whitespaceBeginFilter"]
};

export const formFieldsNewAccountInternal = {
  bankName: ["whitespaceBeginFilter", "longerThan255"],
  accountOwner: ["whitespaceBeginFilter", "longerThan255"],
  iban: ["characterFilter", "whitespaceBeginFilter", "longerThan34"],
  bic: ["characterFilter", "whitespaceBeginFilter", "longerThan11"]
};

export const formFieldsNewDocument = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["whitespaceBeginFilter"],
  paymentDue: ["whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  partner: ["whitespaceBeginFilter"],
  currency: ["whitespaceBeginFilter"]
};

export const formFieldsNewDocumentDelivery = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  partner: ["whitespaceBeginFilter"]
};

export const formFieldsNewDocumentCorrectionExp = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  paymentDue: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  parentDocument: ["min1Character", "whitespaceBeginFilter"],
  amount: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  partner: ["min1Character", "whitespaceBeginFilter"]
};

export const formFieldsNewDocumentCorrectionRev = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  parentDocument: ["min1Character", "whitespaceBeginFilter"],
  amount: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  partner: ["min1Character", "whitespaceBeginFilter"]
};

export const formFieldsNewDocumentInvoiceExp = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  paymentDue: ["min1Character", "whitespaceBeginFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  amount: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  partner: ["whitespaceBeginFilter"]
};

export const formFieldsNewDocumentInvoiceRev = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  amount: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: [
    "min1Character",
    "whitespaceBeginFilter",
    "numberFilterAndMinus",
    "longerThan15"
  ],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  partner: ["whitespaceBeginFilter"]
};

export const formFieldsSubtotal = {
  tax: ["min1Character", "vatAllowed", "longerThan15"],
  netAmount: ["min1Character", "numberFilterAndMinus", "longerThan15", "zeroForbiden"],
  surcharge: ["numberFilterAndMinus", "longerThan15"],
  discount: ["numberFilterAndMinus", "longerThan15"],
  baseAmount: ["numberFilterAndMinus", "longerThan15"],
  calculatedTax: ["numberFilterAndMinus", "longerThan15"],
  grossAmount: ["numberFilterAndMinus", "longerThan15"]
};

export const formFieldsTotal = {
  totalNetAmount: ["numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["numberFilterAndMinus", "longerThan15"],
  totalTax: ["numberFilterAndMinus", "longerThan15"],
  overpayment: ["numberFilterAndMinus", "longerThan15"],
  amount: ["numberFilterAndMinus", "longerThan15", "min1Character"],
  earlyPayAmount: ["numberFilterAndMinus", "longerThan15"],
  paymentDue: ["min1Character"]
};

export const formFieldsTotalSave = {
  totalNetAmount: ["numberFilterAndMinus", "longerThan15"],
  paymentDue: ["numberFilterAndMinus", "longerThan15"],
  amount: ["numberFilterAndMinus", "longerThan15"]
};

export const formFieldsNewCompany = {
  name: ["whitespaceBeginFilter", "longerThan255"],
  vatin: ["whitespaceBeginFilter", "longerThan19"],
  taxNumber: ["whitespaceBeginFilter", "longerThan19"],
  salesIdNumber: ["onlyNumberFilter", "whitespaceBeginFilter"],
  customerOwnNumber: ["onlyNumberFilter", "whitespaceBeginFilter"],
  supplierOwnNumber: ["onlyNumberFilter", "whitespaceBeginFilter"],
  phone: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  fax: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  email: ["emailFilter", "whitespaceBeginFilter", "longerThan224"],
  url: ["whitespaceBeginFilter", "longerThan255"],
  note: ["whitespaceBeginFilter"]
};

export const notifierFields = {
  email: ["min1Character", "emailFilter", "whitespaceBeginFilter", "longerThan255"],
  notifierName: ["min1Character", "whitespaceBeginFilter", "longerThan255"],
  notifierAddress: ["whitespaceBeginFilter", "longerThan255"],
  notifierPhone: ["whitespaceBeginFilter", "phoneFormat", "longerThan255"]
};

export const personFormNewFields = {
  firstName: ["whitespaceBeginFilter", "longerThan100"],
  lastName: ["whitespaceBeginFilter", "longerThan100"],
  salesIdNumber: ["onlyNumberFilter", "whitespaceBeginFilter"],
  phone: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  mobile: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  url: ["whitespaceBeginFilter", "longerThan255"],
  email: ["whitespaceBeginFilter", "emailFilter", "longerThan224"],
  note: ["whitespaceBeginFilter"]
};

export const employeeFormNewFields = {
  first: ["whitespaceBeginFilter", "longerThan100"],
  last: ["whitespaceBeginFilter", "longerThan100"],
  company: ["whitespaceBeginFilter"],
  jobTitle: ["whitespaceBeginFilter", "longerThan255"],
  phone: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  mobile: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  url: ["whitespaceBeginFilter", "longerThan255"],
  email: ["whitespaceBeginFilter", "emailFilter", "longerThan224"],
  note: ["whitespaceBeginFilter"]
};

export const personFormRequiredFields = {
  firstName: ["whitespaceBeginFilter"],
  lastName: ["whitespaceBeginFilter"]
};

export const companyFormRequiredFields = {
  name: ["whitespaceBeginFilter"]
};

export const addressFormRequiredFields = {
  street: ["whitespaceBeginFilter", "longerThan64"],
  city: ["whitespaceBeginFilter", "longerThan64"],
  zip: ["onlyNumberFilter", "whitespaceBeginFilter", "longerThan12"],
  country: ["whitespaceBeginFilter", "longerThan64"]
};

export const contactPersonFormRequiredFields = {
  first: ["whitespaceBeginFilter"],
  last: ["whitespaceBeginFilter"]
};

export const projectContactPersonFormRequiredFields = {
  first: ["whitespaceBeginFilter"],
  last: ["whitespaceBeginFilter"],
  phone: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  mobile: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ]
};

export const employeeFormRequiredFields = {
  first: ["whitespaceBeginFilter"],
  last: ["whitespaceBeginFilter"],
  company: ["whitespaceBeginFilter"]
};

export const formFieldsNewAddress = {
  street: ["whitespaceBeginFilter", "longerThan64"],
  city: ["whitespaceBeginFilter", "longerThan64"],
  zip: ["onlyNumberFilter", "whitespaceBeginFilter", "longerThan12"],
  state: ["whitespaceBeginFilter", "longerThan64"],
  country: ["whitespaceBeginFilter", "longerThan64"]
};

export const formFieldsNewContactPerson = {
  first: ["whitespaceBeginFilter", "longerThan255"],
  last: ["whitespaceBeginFilter", "longerThan255"],
  jobTitle: ["whitespaceBeginFilter", "longerThan255"],
  phone: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  mobile: [
    "charactersOnThePhoneEnd",
    "whitespaceBeginFilter",
    "textFilter",
    "specialCharactersPhoneFormat",
    "longerThan255"
  ],
  email: ["emailFilter", "whitespaceBeginFilter", "longerThan224"],
  url: ["whitespaceBeginFilter", "longerThan255"]
};

export const formFieldsNewTemplate = {
  name: ["whitespaceBeginFilter", "longerThan255"],
  description: ["whitespaceBeginFilter"],
  projectTypeValidation: ["whitespaceBeginFilter"]
};

export const requiredFieldsNewTemplate = {
  name: ["whitespaceBeginFilter", "longerThan255"],
  projectTypeValidation: ["whitespaceBeginFilter"]
};

export const formFieldsNewType = {
  name: ["whitespaceBeginFilter", "longerThan255"],
  description: ["whitespaceBeginFilter"]
};

export const requiredFieldsNewType = {
  name: ["whitespaceBeginFilter", "longerThan255"]
};

export const formFieldsNewStatus = {
  name: ["whitespaceBeginFilter", "longerThan255"],
  id: ["whitespaceBeginFilter", "longerThan255"],
  status: ["whitespaceBeginFilter"]
};

export const formFieldsNewProject = {
  projectname: ["whitespaceBeginFilter", "longerThan255"],
  projectName: ["whitespaceBeginFilter", "longerThan255"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"]
};

export const formFieldsProjectManagement = {
  projectName: ["whitespaceBeginFilter", "longerThan255"],
  projectTemplateValidate: ["onlyNumberFilter"],
  projectDescription: ["whitespaceBeginFilter"]
};

export const requiredFieldsProjectManagement = {
  projectName: ["whitespaceBeginFilter", "longerThan255"],
  projectTemplateValidate: ["onlyNumberFilter"]
};

export const formFieldsNewItemInvoice = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  netPrice: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  quantity: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255", "min1Character"],
  unit: ["whitespaceBeginFilter", "longerThan50"],
  vat: ["whitespaceBeginFilter", "min1Character"],
  surchargeDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"]
};

export const formFieldsNewItem = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  netPrice: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  quantity: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255"],
  unit: ["whitespaceBeginFilter", "longerThan50"],
  vat: ["whitespaceBeginFilter"],
  surchargeDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"]
};

export const formFieldsNewItemDelivery = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  quantity: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255"],
  unit: ["whitespaceBeginFilter", "longerThan50"]
};

export const documentProjectRequiredFields = {
  projectname: ["whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter", "amountFilter"]
};

export const formFieldsPaymentForm = {
  purpose: ["whitespaceBeginFilter"]
};

export const checkFieldsPaymentForm = {
  purpose: ["whitespaceBeginFilter"],
  recipientName: ["whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter"],
  accountId: ["whitespaceBeginFilter"]
};

export const checkFieldsManualPaymentForm = {
  partnerName: ["whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter"]
};

export const filterStrings = {
  // eslint-disable-next-line no-useless-escape
  emailFilter: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"])$/i,
  emailFormat: /^(|null|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i,
  characterFilter: /^[0-9 A-Z a-z _ - äöüÄÖÜß]*$/,
  numberFilter: /^[0-9]*[,.]?[0-9]*$/,
  numberFilterAndChar: /^[0-9.,-]+$/,
  // eslint-disable-next-line no-useless-escape
  numberFilterAndMinus: /^[-+]?(|[0-9]+((([\.\,]){1}([0-9]){1,2})+)?)$/,
  whitespaceBeginFilter: /^\s/,
  zeroForbiden: /[^0|^0.0|^0,0|^0.00|^0,00]/,
  onlyNumberFilter: /^\d+$/,
  textFilter: /^[^A-Za-z]+$/,
  // eslint-disable-next-line no-useless-escape
  specialCharactersPhoneFormat: /^[^\!\@\#\$\%\^\&\*\_\=\|\]\}\[\{\;\:\'\"\?\.\>\,\<\`\~]+$/,
  longerThan11: /^(?=.{0,11}$).*/,
  // eslint-disable-next-line no-useless-escape
  charactersOnThePhoneEnd: /[^\+\-\/\(\)\\\s]$/,
  longerThan12: /^(?=.{0,12}$).*/,
  longerThan15: /^(?=.{0,15}$).*/,
  longerThan16: /^(?=.{0,16}$).*/,
  longerThan19: /^(?=.{0,19}$).*/,
  longerThan20: /^(?=.{0,20}$).*/,
  longerThan24: /^(?=.{0,24}$).*/,
  longerThan30: /^(?=.{0,30}$).*/,
  longerThan34: /^(?=.{0,34}$).*/,
  longerThan36: /^(?=.{0,36}$).*/,
  longerThan50: /^(?=.{0,50}$).*/,
  longerThan64: /^(?=.{0,64}$).*/,
  longerThan70: /^(?=.{0,70}$).*/,
  longerThan100: /^(?=.{0,100}$).*/,
  longerThan224: /^(?=.{0,224}$).*/,
  longerThan255: /^(?=.{0,255}$).*/,
  // eslint-disable-next-line no-useless-escape
  datevFilter: /^[a-zA-Z0-9amp\*\+\-\/]+$/,
  min1Character: /^(?=.{1,255}$).*/,
  min6Character: /^(?=.{6,10}$).*/,
  vatAllowed: /^(5|7|0|16|19|0%|5%|7%|16%|19%)+$/,
  phoneFormat: /^(|null|[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9])*$/,
  faxFormat: /^(|null|[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9])*$/
};

export const filterMessages = {
  emailFilter: "VALIDATIONMESSAGES.EMAILFORMAT",
  characterFilter: "VALIDATIONMESSAGES.ALLOWEDCHARACTERS",
  whitespaceBeginFilter: "VALIDATIONMESSAGES.NOWHITESPACE",
  onlyNumberFilter: "VALIDATIONMESSAGES.ONLYNUMBERS",
  numberFilterAndChar: "VALIDATIONMESSAGES.NUMBERSCHARS",
  numberFilterAndMinus: "VALIDATIONMESSAGES.NUMBERSMINUS",
  requiredFilter: "VALIDATIONMESSAGES.REQUIREDDATA",
  textFilter: "VALIDATIONMESSAGES.TEXTFILTERPHONEFILTER",
  amountFilter: "VALIDATIONMESSAGES.AMOUNTALLOWED",
  specialCharactersPhoneFormat: "VALIDATIONMESSAGES.SPECIALCHARACTERSPHONEFORMAT",
  charactersOnThePhoneEnd: "VALIDATIONMESSAGES.CHARACTERSONTHEPHONEEND",
  longerThan11: "VALIDATIONMESSAGES.LONGERTHAN11",
  longerThan12: "VALIDATIONMESSAGES.LONGERTHAN12",
  longerThan15: "VALIDATIONMESSAGES.LONGERTHAN15",
  longerThan16: "VALIDATIONMESSAGES.LONGERTHAN16",
  longerThan19: "VALIDATIONMESSAGES.LONGERTHAN19",
  longerThan20: "VALIDATIONMESSAGES.LONGERTHAN20",
  longerThan24: "VALIDATIONMESSAGES.LONGERTHAN24",
  longerThan30: "VALIDATIONMESSAGES.LONGERTHAN30",
  longerThan34: "VALIDATIONMESSAGES.LONGERTHAN34",
  longerThan36: "VALIDATIONMESSAGES.LONGERTHAN36",
  longerThan50: "VALIDATIONMESSAGES.LONGERTHAN50",
  longerThan64: "VALIDATIONMESSAGES.LONGERTHAN64",
  longerThan70: "VALIDATIONMESSAGES.LONGERTHAN70",
  longerThan100: "VALIDATIONMESSAGES.LONGERTHAN100",
  longerThan224: "VALIDATIONMESSAGES.LONGERTHAN224",
  longerThan255: "VALIDATIONMESSAGES.LONGERTHAN255",
  datevFilter: "VALIDATIONMESSAGES.DATEVFILTER",
  min1Character: "VALIDATIONMESSAGES.MIN1CHARACTER",
  min6Character: "VALIDATIONMESSAGES.MIN6CHARACTER",
  zeroForbiden: "VALIDATIONMESSAGES.ZEROFORBIDEN",
  vatAllowed: "VALIDATIONMESSAGES.VATALLOWED",
  phoneFormat: "VALIDATIONMESSAGES.PHONEFORMAT",
  faxFormat: "VALIDATIONMESSAGES.FAXFORMAT",
  emailFormat: "VALIDATIONMESSAGES.MAILFORMAT"
};
