import "../semantic.css";
import React, { useEffect } from "react";
import { toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import Localize from "react-intl-universal";

const FillMandatoryDataWarningNotification = props => {
  const toastObject = {
    type: "warning",
    title: Localize.get("NOTIFICATIONS.NOTIFICATIONTYPE.WARNING"),
    description: Localize.get("MODAL.CHECK_FILLED_DATA"),
    time: 3000
  };

  useEffect(() => {
    if (props.isAlive) {
      toast({
        ...toastObject,
        onClose: () => {},
        onClick: () => {},
        onDismiss: () => {}
      });
    }
  }, [props.isAlive]);

  return <></>;
};

export default FillMandatoryDataWarningNotification;
