import React from "react";
import styles from "../style/UsersDetails.module.css";
import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import GlobalList from "../../Components/Lists/GlobalList";

const UsersDetails = props => {
  const { selectedItem, availableItemFieldsForDetails } = props;
  const getFieldsWithData = () => {
    return availableItemFieldsForDetails.map((field, index) => {
      return (
        <GlobalDetailsItem
          key={index}
          type="text"
          itemName={field.labelName}
          itemValue={selectedItem[field.name]}
        />
      );
    });
  };
  return (
    <>
      {getFieldsWithData()}

      <GlobalList
        listState={selectedItem.appRoles}
        listPaginationCustomCSS={styles.role_item_details}
        availableItemFieldsForList={[
          {
            name: "name",
            hederName: "SETTINGS.HEADERS.ASSIGNED_ROLES",
            type: "text",
            width: 12,
            visible: true
          }
        ]}
      />
    </>
  );
};
export default UsersDetails;
