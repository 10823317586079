export const projectRoleSchemma = {
  name: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  description: { type: "string", filters: ["whitespaceBeginFilter"] }
};

export const projectTypeSchemma = {
  name: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  description: { type: "string", filters: ["whitespaceBeginFilter"] }
};
