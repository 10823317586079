import React, { useEffect, useRef, forwardRef, useState } from "react";
import { Menu, Loader } from "semantic-ui-react";
import * as constant from "../Documents/Constants.js";
import styles from "./style/ScrollableList.module.css";

const ScrollableList = forwardRef((props, ref) => {
  const listRef = useRef(null);
  const [isFetchingItems, setIsFetchingItems] = useState(false);

  useEffect(() => {
    setIsFetchingItems(false);
    if (props.stopFetching) {
      return;
    }
    if (
      listRef.current &&
      // eslint-disable-next-line eqeqeq
      props.state.activeMiddlePanelMode != constant.middlePanelMode.documentForPay
    ) {
      listRef.current.addEventListener("scroll", handleScroll);
    }

    return () => listRef.current.removeEventListener("scroll", handleScroll);
  }, [props.list]);

  useEffect(() => {
    if (!isFetchingItems) return;
    props.fetchListItems();
  }, [isFetchingItems]);

  function handleScroll() {
    if (listRef.current.offsetHeight + listRef.current.scrollTop >= listRef.current.scrollHeight) {
      if (!isFetchingItems) {
        setIsFetchingItems(true);
      }
    }
  }

  return (
    <>
      <div
        ref={listRef}
        id={props.id}
        className={
          props.state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay
            ? `ui fluid vertical menu ${styles.payListHeight}`
            : `ui vertical menu ${styles.listOfItems}`
        }
      >
        {props.list}
        {isFetchingItems && (
          <Menu.Item item_id="{c.id}">
            <Loader size="medium" style={{ display: "inline-block" }} />
          </Menu.Item>
        )}
      </div>
    </>
  );
});

export default ScrollableList;
