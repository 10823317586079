import React from "react";
import { Menu, Grid, Icon } from "semantic-ui-react";
import styles from "./style/DatevListItem.module.css";
import Localize from "react-intl-universal";
import * as RestService from "../RestService";

const DatevListItem = props => {
  const callbackGetByIdError = () => {};

  const callbackGetByIdFinally = () => {};

  const callbackGetByIdSuccess = response => {
    let blob = new Blob([response.data], {
      type: "application/octet-stream"
    });
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = props.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const resolveServiceByIdCall = () => {
    const exportFileByIdService = new RestService.Document.GetPackageContentById.Service(props.id);
    exportFileByIdService.setCallbackSuccess(callbackGetByIdSuccess);
    exportFileByIdService.setCallbackError(callbackGetByIdError);
    exportFileByIdService.setCallbackFinally(callbackGetByIdFinally);
    exportFileByIdService.query.addResponseType("arraybuffer");
    exportFileByIdService.call();
  };

  return (
    <Menu.Item className={styles.menu_item} item_id={props.id}>
      <Grid className={styles.my_grid} columns="6" verticalAlign="middle">
        <Grid.Column width={1} className={styles.id_item}>
          <div>{props.id}</div>
        </Grid.Column>
        <Grid.Column width={4} className={styles.file_name}>
          <div>{props.fileName}</div>
        </Grid.Column>
        <Grid.Column width={3} className={styles.start_export_date}>
          <div>{props.fromDate}</div>
        </Grid.Column>
        <Grid.Column width={3} className={styles.end_export_date}>
          <div>{props.toDate}</div>
        </Grid.Column>
        <Grid.Column width={3} className={styles.creation_date}>
          <div>{props.packageCreationDate}</div>
        </Grid.Column>
        <Grid.Column width={2} className={styles.download}>
          <Icon
            className={styles.download_icon}
            name="download"
            title={Localize.get("EXPORT.DATEV.DOWNLOAD")}
            onClick={resolveServiceByIdCall}
          />
        </Grid.Column>
      </Grid>
    </Menu.Item>
  );
};

export default DatevListItem;
