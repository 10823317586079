import React from "react";
import { Button } from "semantic-ui-react";
import propTypes from "prop-types";
import Localize from "react-intl-universal";

const GlobalOkBtn = props => {
  const {
    onOkClicked = function(arg) {},
    positiveText = "DOCUMENTS.MODAL.MODAL_OK",
    disabled
  } = props;

  return (
    <>
      <Button color="teal" onClick={onOkClicked} disabled={disabled}>
        {Localize.get(positiveText)}
      </Button>
    </>
  );
};

GlobalOkBtn.prototype = {
  onOkClicked: propTypes.func,
  positiveText: propTypes.string
};
export default GlobalOkBtn;
