export const availableItemFieldsForDetails = [
  {
    name: "firstName",
    labelName: "SETTINGS.HEADERS.FIRST_NAME",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "lastName",
    labelName: "SETTINGS.HEADERS.LAST_NAME",
    type: "text",
    width: 3,
    isRequired: true
  },
  {
    name: "username",
    labelName: "SETTINGS.HEADERS.USER_NAME",
    type: "text",
    width: 4,
    edit_disabled: true,
    isRequired: true
  },
  {
    name: "email",
    labelName: "SETTINGS.HEADERS.EMAIL",
    type: "text",
    width: 3,
    edit_disabled: true,
    isRequired: true
  }
];

export const emptyUserObject = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  appRoles: []
};

export const removeButtonFieldConfig = {
  type: "button",
  name: "remove_button",
  width: 4,
  visible: true,
  operation: "remove",
  buttonText: "SETTINGS.BUTTONS.REMOVE",
  hederName: "SETTINGS.HEADERS.OPERATION",
  element_semantic_properties: { basic: true }
};

export const addButtonFieldConfig = {
  type: "button",
  name: "add_button",
  width: 4,
  operation: "add",
  buttonText: "SETTINGS.BUTTONS.ADD",
  hederName: "SETTINGS.HEADERS.OPERATION",
  element_semantic_properties: { basic: true }
};

export const assignedRolesName = {
  name: "name",
  hederName: "SETTINGS.HEADERS.ASSIGNED_ROLES",
  type: "text",
  width: 12,
  visible: true
};

export const unassingedRolesName = {
  name: "name",
  hederName: "SETTINGS.HEADERS.UNASSIGNED_ROLES",
  type: "text",
  width: 12,
  visible: true
};
