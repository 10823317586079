export const panelModule = {
  accounts: "accounts",
  permisions: "permisions",
  roles: "roles",
  users: "users",
  dunning: "settings/categories/3/subcategories",
  properties: "settings/categories/1/subcategories",
  accounting: "settings/categories/2/subcategories",
  default_templates: "categories"
};

export const panelView = {
  create: "create",
  edit: "edit",
  details: "details",
  display: "display",
  list: "list"
};

export const type = {
  invoice: "INVOICE",
  offer: "OFFER",
  order: "ORDER",
  deliveryNote: "DELIVERY",
  invoiceCorrection: "CORRECTION",
  receipt: "RECEIPT",
  other: "OTHER",
  inquiiry: "INQUIRY",
  customer: "Customer",
  employee: "Employee",
  supplier: "Supplier"
};
