import React, { useState, useEffect, forwardRef } from "react";
import Localize from "react-intl-universal";
import { Accordion, Icon, Form, Input, Label } from "semantic-ui-react";
import styles from "./style/ContactFormAccount.module.css";
import * as constant from "../Constants.js";

const ContactFormAccount = forwardRef((props, ref) => {
  const { handler, onChange, index, validateAccountsListField, account, title, validator } = props;
  const [activeAccordion, setActiveAccordion] = useState(props.activeAccordion);

  const handleAccordionClick = (e, titleProps) => {
    handler.accordionHandler(index);
  };

  const handleOnChange = (event, data) => {
    let acc = { ...account, [data.name]: data.value };

    if (validator && validator[data.name]) {
      validateAccountsListField(data.name, data.value, index);
    }
    onChange(index, acc);
  };

  const handleOnBlur = (e, data) => {
    let propName = data ? data.name : e.target.name;
    let propValue = data ? data.value : e.target.value;
    if (propValue) {
      validateAccountsListField(propName, propValue, index);
    }
  };

  const handleOnDeleteClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.handleDelete(index);
  };

  useEffect(() => {
    setActiveAccordion(props.activeAccordion);
  }, [props.activeAccordion]);

  const errorBorder = "3px red solid";

  const setTitle = () => {
    let newTitle = account && account.accountOwner ? account.accountOwner : title;
    return newTitle;
  };

  return (
    <Accordion className={styles.accordition_container} styled>
      <Accordion.Title active={activeAccordion} onClick={handleAccordionClick}>
        <Icon className={styles.dropdown_icon} name="dropdown" />
        {setTitle()}
        <Icon
          name="minus circle"
          className={styles.minus_circle_icon}
          onClick={handleOnDeleteClick}
        ></Icon>
      </Accordion.Title>
      <Accordion.Content className={styles.ac_content} active={activeAccordion}>
        <Form.Field>
          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.BANK_NAME")}</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="bankName"
            style={{
              border: validator && validator.bankName ? errorBorder : ""
            }}
            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.BANK_NAME")}
            value={account && account.bankName ? account.bankName : ""}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.bankName && (
            <Label basic color="red" pointing content={validator.bankName} />
          )}
        </Form.Field>
        <Form.Field required>
          <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.OWNER_NAME")}</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="accountOwner"
            style={{
              border: validator && validator.accountOwner ? errorBorder : ""
            }}
            placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.OWNER_NAME")}
            value={account.accountOwner === undefined ? "" : account.accountOwner}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.accountOwner && (
            <Label basic color="red" pointing content={validator.accountOwner} />
          )}
        </Form.Field>
        <Form.Field required>
          <label>IBAN</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="iban"
            style={{ border: validator && validator.iban ? errorBorder : "" }}
            placeholder="IBAN"
            value={account.iban === undefined ? "" : account.iban}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.iban && (
            <Label basic color="red" pointing content={validator.iban} />
          )}
        </Form.Field>
        <Form.Field required>
          <label>BIC</label>
          <Input
            autoComplete={constant.autoComplete.off}
            name="bic"
            style={{ border: validator && validator.bic ? errorBorder : "" }}
            placeholder="BIC"
            value={account.bic === undefined ? "" : account.bic}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {validator && validator.bic && (
            <Label basic color="red" pointing content={validator.bic} />
          )}
        </Form.Field>
      </Accordion.Content>
    </Accordion>
  );
});

export default ContactFormAccount;
