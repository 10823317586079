import React, { useState, useEffect } from "react";
import { Icon, Table, Grid, Input, Header, Button, Popup, Divider, Form } from "semantic-ui-react";
import styles from "./style/DocumentTotal.module.css";
import Localize from "react-intl-universal";
import GlobalDatedown from "./GlobalDatedown";
import TableCellInputOut from "../../Components/Lists/TableCellInputOut";
import {
  calculateTotalTableChanged,
  calculateTotalFormChanged,
  checkIsValid
} from "./DocumentTotalUtil";

const DocumentTotal = props => {
  const {
    document,
    documentTotalHandler,
    recalculateClicked,
    equalsItemsWithDocumentHeader,
    equalsTaxSubtotalsWithDocumentHeader,
    editedFields,
    setEditedFields,
    checkEdit,
    setCheckEdit,
    amountType,
    recalculatePerSubtotal,
    totalValidator,
    setTotalIsValid,
    validateTotalField,
    sectionConfig
  } = props;

  const init = document => {
    return {
      totalNetAmount: document.totalNetAmount,
      totalDiscount: document.totalDiscount,
      totalSurcharge: document.totalSurcharge,
      totalBaseAmount: document.totalBaseAmount,
      totalTax: document.totalTax,
      totalGrossAmount: document.totalGrossAmount,
      overpayment: document.overpayment,
      paymentDue: document.paymentDue,
      amount: document.amount,

      documentDiscount: {
        earlyPayAmount: document.documentDiscount.earlyPayAmount,
        savings: document.documentDiscount.savings,
        earlyPayDate: document.documentDiscount.earlyPayDate,
        id: document.documentDiscount.id,
        description: document.documentDiscount.description
      }
    };
  };

  const [headerValues, setHeaderValues] = useState(init(document));
  const [editDocTotal, setEditDocTotal] = useState(false);
  const [dueDateMenuOpen, setDueDateMenuOpen] = useState(false);
  const [earlyDateMenuOpen, setEarlyDateMenuOpen] = useState(false);

  const getIcon = () => {
    if (document && document.currency && document.currency.id) {
      switch (document.currency.id) {
        case "EUR":
          return <Icon name="euro sign" />;
        case "GBP":
          return <Icon name="pound" />;
        case "USD":
          return <Icon name="dollar sign" />;
        default:
          break;
      }
    }
  };

  const handleDueDateClick = (day, selected, event) => {
    event.stopPropagation();
    setDueDateMenuOpen(false);
    let initiaYear = new Date("1900-01-01");
    let dayYear = day.getFullYear();

    if (initiaYear.getFullYear() === dayYear) {
      day.setFullYear(2010);
    }

    setHeaderValues({
      ...headerValues,
      paymentDue: day,
      documentDiscount: {
        ...headerValues.documentDiscount,
        earlyPayDate:
          headerValues.documentDiscount.earlyPayDate === null
            ? null
            : day < new Date(headerValues.documentDiscount.earlyPayDate)
            ? day
            : headerValues.documentDiscount.earlyPayDate
      }
    });

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount"
    ];
    setEditedFields(newArr);
  };

  const handleValidationOnBlur = (event, data) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    validateTotalField(fieldValue ? fieldValue : "", fieldName);
  };

  const handleEarlyDateClick = (day, selected, event) => {
    event.stopPropagation();
    setEarlyDateMenuOpen(false);
    let initiaYear = new Date("1900-01-01");
    let dayYear = day.getFullYear();

    if (initiaYear.getFullYear() === dayYear) {
      day.setFullYear(2010);
    }

    setHeaderValues(
      calculateTotalTableChanged({
        ...headerValues,
        paymentDue: new Date(headerValues.paymentDue) < day ? day : headerValues.paymentDue,
        documentDiscount: {
          ...headerValues.documentDiscount,
          earlyPayDate: day
        }
      })
    );

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount"
    ];
    setEditedFields(newArr);
  };

  const onHeaderChange = (event, data) => {
    let tempHeaderValues = {
      ...headerValues,
      [event.target.name]: event.target.value
    };

    if (
      totalValidator.parameter[event.target.name] !== "" ||
      event.target.name === "totalNetAmount"
    ) {
      if (event.target.name === "totalNetAmount") {
        validateTotalField(headerValues.amount, "amount");
      } else {
        validateTotalField(event.target.value, event.target.name);
      }
    }

    if (event.target.name === "amount") {
      setHeaderValues(calculateTotalFormChanged(tempHeaderValues));
    } else {
      setHeaderValues(calculateTotalTableChanged(tempHeaderValues));
    }

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount"
    ];
    setEditedFields(newArr);
  };

  const onHeaderDiscountChange = (event, data) => {
    let tempHeaderValues = {
      ...headerValues,
      documentDiscount: {
        ...headerValues.documentDiscount,
        [event.target.name]: event.target.value
      }
    };

    setHeaderValues(calculateTotalFormChanged(tempHeaderValues));

    const newArr = [
      ...editedFields,
      "totalNetAmount",
      "totalDiscount",
      "totalSurcharge",
      "totalTax",
      "overpayment",
      "amount",
      "paymentDue",
      "documentDiscount"
    ];
    setEditedFields(newArr);
  };

  const clearEarlyDate = () => {
    let tempHeaderValues = {
      ...headerValues,
      documentDiscount: {
        ...headerValues.documentDiscount,
        earlyPayDate: ""
      }
    };

    setHeaderValues(calculateTotalFormChanged(tempHeaderValues));

    const newArr = [...editedFields, "documentDiscount"];
    setEditedFields(newArr);
  };

  const onUpdateClick = () => {
    if (checkIsValid(totalValidator)) {
      setTotalIsValid(true);
      setEditDocTotal(false);
      documentTotalHandler(headerValues);
    }
  };

  const onEditClick = () => {
    setEditDocTotal(true);
    setCheckEdit({ type: "editTotals" });
  };

  const onCancelClick = () => {
    let myInit = init(document);
    setHeaderValues({ ...myInit });
    setEditDocTotal(false);
    setCheckEdit({ type: "nonEditState" });
  };

  const onRecalculateClick = () => {
    recalculateClicked();
  };

  const getTextDate = date => {
    let paymentDate = "";
    if (date) {
      paymentDate = new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
    return paymentDate;
  };

  useEffect(() => {
    setHeaderValues(init(document));
  }, [document]);

  return (
    <>
      <Form className={styles.form_container}>
        {" "}
        <Header
          className={`${styles.total_header} ${
            !equalsItemsWithDocumentHeader || !equalsTaxSubtotalsWithDocumentHeader
              ? styles.total_header_2
              : ""
          }`}
          as="h4"
          dividing
        >
          <Popup
            size="mini"
            content={Localize.get("DOCUMENTS.DETAILS.EDIT_DOCUMENT")}
            trigger={<Icon name="edit" onClick={onEditClick} disabled={checkEdit.editMode} />}
          />

          <Header.Content>{Localize.get("DOCUMENTS.NEWDOCUMENT.DOC_TOTAL_HEADER")}</Header.Content>
        </Header>
        {!equalsItemsWithDocumentHeader && (
          <div className={styles.validation_message_red}>
            {Localize.get("DOCUMENTS.FORM.TOTAL_MSG_1")}
          </div>
        )}
        {!equalsTaxSubtotalsWithDocumentHeader && (
          <div className={styles.validation_message_red}>
            {Localize.get("DOCUMENTS.FORM.TOTAL_MSG_2")}
          </div>
        )}
        <div className={styles.total_container}>
          <Table compact className={styles.subtotal_table}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {" "}
                  {Localize.get("DOCUMENTS.FORM.BASE_NETTO")}
                  {getIcon()}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {Localize.get("DOCUMENTS.FORM.TOTAL_DISCOUNT")}
                  {getIcon()}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {Localize.get("DOCUMENTS.FORM.TOTAL_SURCHAGE")}
                  {getIcon()}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {Localize.get("DOCUMENTS.FORM.TOTAL_BASE_AMOUNT")}
                  {getIcon()}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {Localize.get("DOCUMENTS.FORM.TOTAL_TAX")}
                  {getIcon()}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {Localize.get("DOCUMENTS.FORM.TOTAL_GROSS_AMOUNT")}
                  {getIcon()}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={editDocTotal ? 1 : 2}
                    index={1}
                    inputValue={headerValues.totalNetAmount}
                    placeholder={"Amount"}
                    inputName={"totalNetAmount"}
                    onCellChange={onHeaderChange}
                    onCellBlur={handleValidationOnBlur}
                    hasError={totalValidator.parameter["totalNetAmount"]}
                  />
                </Table.Cell>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={editDocTotal ? 1 : 2}
                    index={1}
                    inputValue={headerValues.totalDiscount}
                    placeholder={"Amount"}
                    inputName={"totalDiscount"}
                    onCellChange={onHeaderChange}
                    onCellBlur={handleValidationOnBlur}
                    hasError={totalValidator.parameter["totalDiscount"]}
                  />
                </Table.Cell>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={editDocTotal ? 1 : 2}
                    index={1}
                    inputValue={headerValues.totalSurcharge}
                    placeholder={"Amount"}
                    inputName={"totalSurcharge"}
                    onCellChange={onHeaderChange}
                    onCellBlur={handleValidationOnBlur}
                    hasError={totalValidator.parameter["totalSurcharge"]}
                  />
                </Table.Cell>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={2}
                    index={1}
                    inputValue={headerValues.totalBaseAmount}
                    placeholder={"Amount"}
                    inputName={"totalBaseAmount"}
                    onCellChange={onHeaderChange}
                  />
                </Table.Cell>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={editDocTotal ? 1 : 2}
                    index={1}
                    inputValue={headerValues.totalTax}
                    placeholder={"Amount"}
                    inputName={"totalTax"}
                    onCellChange={onHeaderChange}
                    onCellBlur={handleValidationOnBlur}
                    hasError={totalValidator.parameter["totalTax"]}
                  />
                </Table.Cell>
                <Table.Cell className={styles.default_cell}>
                  <TableCellInputOut
                    editedIndex={2}
                    index={1}
                    inputValue={headerValues.totalGrossAmount}
                    placeholder={"Amount"}
                    inputName={"totalGrossAmount"}
                    onCellChange={onHeaderChange}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <Grid columns="2">
          <Grid.Column verticalAlign="bottom" style={{ paddingLeft: "0px" }}>
            <Button primary onClick={() => recalculatePerSubtotal()} disabled={checkEdit.editMode}>
              {Localize.get("DOCUMENTS.FORM.RECALCULATE_PER_SUBTOTALS")}
            </Button>

            <Divider hidden style={{ marginBottom: "10px", marginTop: "10px" }} />

            <Popup
              size="tiny"
              content={amountType ? "Gross amount" : "Net amount"}
              trigger={
                <Button primary onClick={onRecalculateClick} disabled={checkEdit.editMode}>
                  {Localize.get("DOCUMENTS.FORM.RECALCULATE")}
                </Button>
              }
            />
          </Grid.Column>
          <Grid.Column className={styles.right_column}>
            {" "}
            <Grid columns={2}>
              {sectionConfig.overpayment && (
                <Grid.Row
                  className={editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten}
                >
                  <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                    <Header as="h5">
                      {Localize.get("DOCUMENTS.ORDERITEMS.OVERPAYMENT")}
                      {getIcon()}
                    </Header>
                  </Grid.Column>
                  <Grid.Column className={styles.padding_right_null} width={7}>
                    <Input
                      name="overpayment"
                      className={styles.disabled_color_align}
                      autoComplete="off"
                      fluid
                      transparent={!editDocTotal}
                      disabled={!editDocTotal}
                      value={headerValues.overpayment ? headerValues.overpayment : ""}
                      onChange={onHeaderChange}
                      onBlur={handleValidationOnBlur}
                      style={
                        totalValidator.parameter["overpayment"]
                          ? { border: "1px red solid", borderRadius: "5px" }
                          : {}
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row
                className={editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten}
              >
                <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                  <Header as="h5">
                    {Localize.get("DOCUMENTS.ORDERITEMS.TOTAL_AMOUNT")}
                    {getIcon()}
                  </Header>
                </Grid.Column>
                <Grid.Column width={7} className={styles.padding_right_null}>
                  <Input
                    name="amount"
                    className={styles.disabled_color_align}
                    autoComplete="off"
                    fluid
                    transparent={!editDocTotal}
                    disabled={!editDocTotal}
                    value={headerValues.amount ? headerValues.amount : ""}
                    onChange={onHeaderChange}
                    onBlur={handleValidationOnBlur}
                    style={
                      totalValidator.parameter["amount"]
                        ? {
                            border: "1px red solid",
                            borderRadius: "5px"
                          }
                        : {}
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              {sectionConfig.paymentDue && (
                <>
                  <Grid.Row
                    className={
                      editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten
                    }
                  >
                    <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                      <Header as="h5">{Localize.get("DOCUMENTS.FORM.DUE_DATE")}</Header>
                    </Grid.Column>
                    <Grid.Column width={7} className={styles.padding_right_null}>
                      <GlobalDatedown
                        name="paymentDue"
                        icon="calendar alternate outline"
                        disable={!editDocTotal}
                        isRequired={false}
                        dropdownName={"paymentDue"}
                        handleOnClick={() => (editDocTotal ? setDueDateMenuOpen(true) : null)}
                        handleOnBlur={() => setDueDateMenuOpen(false)}
                        dropdownOpen={dueDateMenuOpen}
                        dropdownText={getTextDate(headerValues.paymentDue)}
                        handleDateClick={handleDueDateClick}
                        showLabel={false}
                        dropdownStyleError={"1px red solid"}
                        hasError={totalValidator.parameter["paymentDue"]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider className={styles.divider_margin} />
                </>
              )}
              {sectionConfig.earlyPayDate && (
                <Grid.Row
                  className={editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten}
                >
                  <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                    <Header as="h5">{Localize.get("DOCUMENTS.FORM.EARLY_PAMENT_DATE")}</Header>
                  </Grid.Column>
                  <Grid.Column width={7} className={styles.padding_right_null}>
                    <GlobalDatedown
                      name="documentDiscount.earlyPayDate"
                      icon={
                        headerValues &&
                        headerValues.documentDiscount &&
                        headerValues.documentDiscount.earlyPayDate ? (
                          <Icon
                            name="close"
                            onClick={clearEarlyDate}
                            className={styles.clear_earlydate}
                          />
                        ) : (
                          "calendar alternate outline"
                        )
                      }
                      disable={!editDocTotal}
                      isRequired={false}
                      dropdownName={"earlyPayDate"}
                      handleOnClick={() => (editDocTotal ? setEarlyDateMenuOpen(true) : null)}
                      handleOnBlur={() => setEarlyDateMenuOpen(false)}
                      dropdownOpen={earlyDateMenuOpen}
                      dropdownText={getTextDate(headerValues.documentDiscount.earlyPayDate)}
                      handleDateClick={handleEarlyDateClick}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {sectionConfig.earlyPayAmount && (
                <Grid.Row
                  className={editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten}
                >
                  <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                    <Header as="h5">{Localize.get("DOCUMENTS.FORM.EARLY_PAMENT_GROSS")}</Header>
                  </Grid.Column>
                  <Grid.Column width={7} className={styles.padding_right_null}>
                    <Input
                      name="earlyPayAmount"
                      className={styles.disabled_color_align}
                      autoComplete="off"
                      fluid
                      transparent={!editDocTotal}
                      disabled={!editDocTotal}
                      value={
                        headerValues.documentDiscount.earlyPayAmount
                          ? headerValues.documentDiscount.earlyPayAmount
                          : ""
                      }
                      onChange={onHeaderDiscountChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {sectionConfig.savings && (
                <Grid.Row
                  className={editDocTotal ? styles.padding_bottom_null : styles.padding_bottom_ten}
                >
                  <Grid.Column width={9} verticalAlign={editDocTotal ? "middle" : "bottom"}>
                    <Header as="h5">{Localize.get("DOCUMENTS.FORM.SAVINGS")}</Header>
                  </Grid.Column>
                  <Grid.Column width={7} className={styles.padding_right_null}>
                    <Input
                      name="savings"
                      className={styles.disabled_color_align}
                      fluid
                      transparent={true}
                      disabled={true}
                      value={
                        headerValues.documentDiscount.savings
                          ? headerValues.documentDiscount.savings
                          : ""
                      }
                      onChange={onHeaderDiscountChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                {editDocTotal && (
                  <Grid.Column floated="right" width={12} className={styles.padding_right_null}>
                    <Button.Group floated="right">
                      <Button primary onClick={onUpdateClick}>
                        {Localize.get("DOCUMENTS.FORM.UPDATE")}
                      </Button>
                      <Button onClick={() => onCancelClick()}>
                        {Localize.get("GLOBAL.FORM.CANCEL")}
                      </Button>
                    </Button.Group>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Form>
    </>
  );
};

export default DocumentTotal;
