export const panelMode = {
  edit: "edit",
  create: "create",
  details: "details"
};

export const templateListInitState = templates => {
  return {
    activeListItem: templates[0] ? templates[0].id : undefined,
    activeMiddlePanelMode: undefined,
    name: "",
    description: "",
    search: "",
    sortColumn: null,
    sortDirection: null,
    selectAll: false
  };
};

export const panelInitState = {
  activeMode: panelMode.details,
  template: {}
};

export const autoComplete = {
  none: "none",
  off: "off"
};
