import { useState, useEffect } from "react";
import Validator from "../../Utils/Validator";
import * as validatorConstants from "../../Utils/ConstantsDocuments";

const useDocumentValidator = document => {
  const [documentFormValidator, setDocumentFormValidator] = useState(
    new Validator(validatorConstants.documentFormDefaultTypes)
  );
  const [totalValidator, setTotalValidator] = useState(
    new Validator(validatorConstants.totalTableDefaultTypes)
  );
  const [documentItemsValidator, setDocumentItemsValidator] = useState(new Validator({}));
  const [documentSubtotalsValidator, setDocumentSubtotalsValidator] = useState(new Validator({}));

  const [itemsAreValid, setItemsAreValid] = useState(false);
  const [subtotalsAreValid, setSubtotalsAreValid] = useState(true);
  const [totalIsValid, setTotalIsValid] = useState(true);
  const [docIsValid, setDocIsValid] = useState(true);

  const createDocumentValidator = currentDocument => {
    return new Validator(getDocumentFormConstants(currentDocument));
  };
  const createTotalValidator = currentDocument => {
    return new Validator(getTotalConstants(currentDocument));
  };
  const createItemsValidator = currentDocument => {
    return new Validator(getItemsConstants(currentDocument));
  };
  const createSubtotalValidator = currentDocument => {
    return new Validator(getSubtotalConstants(currentDocument));
  };

  const getDocumentFormConstants = currentDocument => {
    switch (currentDocument.documentType) {
      case "invoice":
        return currentDocument.direction === "incoming"
          ? validatorConstants.invoiceFormExpence
          : validatorConstants.invoiceFormRevenue;
      case "correction":
        return currentDocument.direction === "incoming"
          ? validatorConstants.correctionFormExpence
          : validatorConstants.correctionFormRevenue;
      case "delivery":
        return validatorConstants.deliveryFormBothDirections;
      default:
        return validatorConstants.documentFormDefaultTypes;
    }
  };

  const getTotalConstants = currentDocument => {
    switch (currentDocument.documentType) {
      case "invoice":
        return validatorConstants.totalTableInvoices;
      case "correction":
        return validatorConstants.totalTableInvoices;
      case "delivery":
        return {};
      default:
        return validatorConstants.totalTableDefaultTypes;
    }
  };

  const getItemsConstants = currentDocument => {
    switch (currentDocument.documentType) {
      case "invoice":
        return validatorConstants.orderItemForInvoices;
      case "correction":
        return validatorConstants.orderItemForInvoices;
      case "delivery":
        return validatorConstants.orderItemForDelivery;
      default:
        return validatorConstants.orderItemForDefault;
    }
  };

  const getSubtotalConstants = currentDocument => {
    switch (currentDocument.documentType) {
      case "delivery":
        return {};
      default:
        return validatorConstants.subtotalsTableAll;
    }
  };

  const validateDocumentFunc = (currentDocument, tabValidate) => {
    let newDocumentFormValidator = createDocumentValidator(currentDocument);
    let newItemsValidator = createItemsValidator(currentDocument);
    let newSubtotalsValidator = createSubtotalValidator(currentDocument);
    let newTotalValidator = createTotalValidator(currentDocument);

    let isDocumentValid = newDocumentFormValidator.validateDocumentForm(
      getDocumentFormConstants(currentDocument),
      currentDocument
    );
    let isTotalValid = newTotalValidator.validateDocumentForm(
      getTotalConstants(currentDocument),
      currentDocument
    );
    let areItemsValid = newItemsValidator.validateOnUpload(
      getItemsConstants(currentDocument),
      currentDocument.orderItems
    );
    let areSubtotalsValid = newSubtotalsValidator.validateOnUpload(
      getSubtotalConstants(currentDocument),
      currentDocument.taxSubtotals
    );

    setDocumentItemsValidator({ ...newItemsValidator });
    setDocumentFormValidator({ ...newDocumentFormValidator });
    setDocumentSubtotalsValidator({ ...newSubtotalsValidator });
    setTotalValidator({ ...newTotalValidator });
    setItemsAreValid(areItemsValid);

    if (tabValidate) {
      setDocIsValid(isDocumentValid);
      setSubtotalsAreValid(areSubtotalsValid);
      setTotalIsValid(isTotalValid);
    }

    return isDocumentValid && areSubtotalsValid && isTotalValid;
  };

  const validateSubtotalsFunc = currentDocument => {
    let newSubtotalsValidator = createSubtotalValidator(currentDocument);
    let areSubtotalsValid = newSubtotalsValidator.validateOnUpload(
      getSubtotalConstants(currentDocument),
      currentDocument.taxSubtotals
    );

    setDocumentSubtotalsValidator({ ...newSubtotalsValidator });

    return areSubtotalsValid;
  };

  const validateItemsFunc = currentDocument => {
    let newItemsValidator = createItemsValidator(currentDocument);
    let areItemsValid = newItemsValidator.validateOnUpload(
      getItemsConstants(currentDocument),
      currentDocument.orderItems
    );

    setDocumentItemsValidator({ ...newItemsValidator });
    setItemsAreValid(areItemsValid);

    return areItemsValid;
  };

  const validateSubtotalField = (field, value, index) => {
    if (
      documentSubtotalsValidator.parameterList &&
      documentSubtotalsValidator.parameterList[index]
    ) {
      documentSubtotalsValidator.validateFieldOfItem(field, value, index);
      setDocumentSubtotalsValidator({ ...documentSubtotalsValidator });
    }
  };

  const validateOrderItemField = (field, value, index) => {
    if (documentItemsValidator.parameterList && documentItemsValidator.parameterList[index]) {
      documentItemsValidator.validateFieldOfItem(field, value, index);
      setDocumentItemsValidator({ ...documentItemsValidator });
    }
  };

  const validateDocumentFormField = (value, field) => {
    let isDocumentValid = documentFormValidator.validateField(value, field);
    setDocumentFormValidator({ ...documentFormValidator });
    setDocIsValid(isDocumentValid);
  };

  const validateTotalField = (value, field) => {
    totalValidator.validateField(value, field);
    setTotalValidator({ ...totalValidator });
  };

  useEffect(() => {
    validateDocumentFunc(document);
  }, [document.documentType]);

  return {
    documentFormValidator,
    totalValidator,
    documentItemsValidator,
    documentSubtotalsValidator,
    subtotalsAreValid,
    validateDocumentFunc,
    validateDocumentFormField,
    validateTotalField,
    validateSubtotalField,
    validateOrderItemField,
    itemsAreValid,
    validateSubtotalsFunc,
    validateItemsFunc,
    totalIsValid,
    docIsValid,
    setTotalIsValid,
    setSubtotalsAreValid
  };
};
export default useDocumentValidator;
