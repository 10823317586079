import React, { useState } from "react";
import Localize from "react-intl-universal";
import { Loader, Dimmer, Sidebar, Segment, Grid } from "semantic-ui-react";
import GlobalLeftPanel from "./GlobalLeftPanel";
import GlobalMiddlePanel from "./GlobalMiddlePanel";
import GlobalRightPanel from "./GlobalRightPanel";
import styles from "./style/GlobalPanel.module.css";
import { panelView } from "../Constants";
import propTypes from "prop-types";
import usePanelConfig from "../../CustomHooks/usePanelConfig";

const GlobalPanel = props => {
  const {
    customizedRightPanelComponent,
    customizedMiddlePanelComponent,
    globalPanelConf,
    leftPanelProps = {},
    rightPanelProps = {},
    middlePanelProps = {},
    currentView = "details",
    isLoading = false
  } = props;

  const [sideBarVisibility, setSideBarVisibility] = useState(false);

  const { leftPanelGridConfig, middlePanelGridConfig, rightPanelGridConfig } = usePanelConfig(
    globalPanelConf,
    currentView
  );

  const checkMenuIconVisability = myConfig => {
    if (sideBarVisibility || myConfig.hideIcon) {
      return styles.bms_zindex;
    }
  };

  const checkZIndexOfPushable = () => {
    if (currentView === panelView.edit || currentView === panelView.create) {
      return styles.bms_segment_zi;
    } else {
      return styles.bms_segment;
    }
  };

  const checkZiIndexOfPusher = () => {
    if (currentView === panelView.edit || currentView === panelView.create) {
      return styles.bms_zi;
    } else {
      return null;
    }
  };

  const checkMidAndRigPanVis = (showPanel = true) => {
    if (!showPanel) {
      return styles.change_middAndRig_margin;
    } else {
      return null;
    }
  };

  return (
    <>
      <Dimmer
        active={isLoading || currentView === panelView.edit || currentView === panelView.create}
        inverted
      >
        <Loader size="large" disabled={!isLoading}>
          {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
        </Loader>
      </Dimmer>
      <div
        className={`ui right attached button ${styles.bms_fixed} ${checkMenuIconVisability(
          leftPanelGridConfig
        )}`}
        onClick={() => setSideBarVisibility(true)}
      >
        <i className="ellipsis vertical icon"></i>
      </div>
      <Sidebar.Pushable as={Segment} className={checkZIndexOfPushable()}>
        <GlobalLeftPanel
          {...leftPanelProps}
          sideBarVisibility={sideBarVisibility}
          setSideBarVisibility={setSideBarVisibility}
        />
        <Sidebar.Pusher dimmed={sideBarVisibility} className={checkZiIndexOfPusher()}>
          <div
            className={`${styles.bms_document_content} ${checkMidAndRigPanVis(
              leftPanelGridConfig.showPanel
            )}`}
          >
            <Grid
              padded
              columns={middlePanelGridConfig.showPanel && rightPanelGridConfig.showPanel ? 2 : 1}
            >
              {middlePanelGridConfig.showPanel && (
                <Grid.Column
                  width={
                    middlePanelGridConfig.showPanel && rightPanelGridConfig.showPanel ? 10 : 16
                  }
                >
                  <GlobalMiddlePanel
                    CustomComponent={customizedMiddlePanelComponent}
                    {...middlePanelProps}
                  />
                </Grid.Column>
              )}

              {rightPanelGridConfig.showPanel && (
                <Grid.Column
                  className={styles.right_panel_padding}
                  width={
                    !leftPanelGridConfig.showPanel && !middlePanelGridConfig.showPanel ? 16 : 6
                  }
                >
                  <GlobalRightPanel
                    CustomComponent={customizedRightPanelComponent}
                    {...rightPanelProps}
                  />
                </Grid.Column>
              )}
            </Grid>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

GlobalPanel.propTypes = {
  customizedRightPanelComponent: propTypes.func,
  customizedMiddlePanelComponent: propTypes.func,
  globalPanelConf: propTypes.object,
  leftPanelProps: propTypes.object,
  rightPanelProps: propTypes.object,
  middlePanelProps: propTypes.object,
  currentView: propTypes.string,
  isLoading: propTypes.bool
};

export default GlobalPanel;
