import React from "react";
import DocumentNotSelected from "./DocumentNotSelected";
import DocumentScanFilesList from "./DocumentScanFilesList";
import DocumentDisplay from "./DocumentDisplay";
import DocumentList from "./DocumentList";
import DragAndDropPanel from "./DragAndDropPanel";
import * as constant from "../Constants.js";
import styles from "./style/MiddlePanel.module.css";

const MiddlePanel = props => {
  const {
    privileges,
    paymentPrivileges,
    state,
    handler,
    documentPreview,
    isPDFPreview,
    linkedDocumentsPreview,
    editMode,
    documents,
    handlePageChange,
    paginationStatus,
    activePaginationPage,
    checkedDocs,
    dateTypeValue,
    setEditMode,
    accounts,
    scanedFiles,
    paginationScanedFiles,
    activePaginationScandedFilesPage,
    handleScanedFilesPageChange,
    activeScanesListItemId,
    isLoadingScansList,
    selectedDocument,
    documentsPanelState,
    respDownAndSendMessage,
    setActiveItem,
    unverifiedPartners
  } = props;
  let activeMiddlePanelComponent;
  switch (state.activeMiddlePanelMode) {
    case constant.middlePanelMode.documentNotSelected:
      activeMiddlePanelComponent = <DocumentNotSelected state={state} handler={handler} />;
      break;

    case constant.middlePanelMode.documentDisplay:
      activeMiddlePanelComponent = (
        <DocumentDisplay
          privileges={privileges}
          state={state}
          handler={handler}
          documentPreview={documentPreview}
          isPDFPreview={isPDFPreview}
          linkedDocumentsPreview={linkedDocumentsPreview}
        />
      );
      break;

    case constant.middlePanelMode.documentList:
      activeMiddlePanelComponent = (
        <DocumentList
          privileges={privileges}
          paymentPrivileges={paymentPrivileges}
          state={state}
          documentsPanelState={documentsPanelState}
          editMode={editMode}
          setEditMode={setEditMode}
          handler={handler}
          documents={documents}
          handlePageChange={handlePageChange}
          paginationStatus={paginationStatus}
          activePaginationPage={activePaginationPage}
          checkedDocs={checkedDocs}
          dateTypeValue={dateTypeValue}
          selectedDocument={selectedDocument}
          respDownAndSendMessage={respDownAndSendMessage}
          setActiveItem={setActiveItem}
          unverifiedPartners={unverifiedPartners}
        />
      );
      break;

    case constant.middlePanelMode.documentForPay:
      activeMiddlePanelComponent = (
        <DocumentList
          privileges={privileges}
          paymentPrivileges={paymentPrivileges}
          state={state}
          editMode={editMode}
          setEditMode={setEditMode}
          handler={handler}
          documents={documents}
          handlePageChange={handlePageChange}
          paginationStatus={paginationStatus}
          checkedDocs={checkedDocs}
          accounts={accounts}
          selectedDocument={selectedDocument}
          documentsPanelState={documentsPanelState}
        />
      );
      break;

    case constant.middlePanelMode.documentDragnDrop:
      activeMiddlePanelComponent = <DragAndDropPanel state={state} handler={handler} />;
      break;

    case constant.middlePanelMode.documentScanList:
      activeMiddlePanelComponent = (
        <DocumentScanFilesList
          scanedFiles={scanedFiles}
          privileges={privileges}
          state={state}
          handler={handler}
          documentPreview={documentPreview}
          paginationScanedFiles={paginationScanedFiles}
          activePaginationScandedFilesPage={activePaginationScandedFilesPage}
          handleScanedFilesPageChange={handleScanedFilesPageChange}
          isPDFPreview={isPDFPreview}
          linkedDocumentsPreview={linkedDocumentsPreview}
          activeScanesListItemId={activeScanesListItemId}
          isLoadingScansList={isLoadingScansList}
        />
      );
      break;

    default:
      break;
  }

  return (
    <>
      <div
        className={`${
          editMode
            ? state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay
              ? "ten wide column"
              : "eight wide column"
            : "ten wide column"
        }  ${styles.items_list} ${styles.bms_gutter_reset}`}
      >
        {activeMiddlePanelComponent}
      </div>
    </>
  );
};

export default MiddlePanel;
