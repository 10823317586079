import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import financeContent from "../Assets/Images/financeContent.png";
import documentsContent from "../Assets/Images/documentsContent.png";
import contactsContent from "../Assets/Images/contactsContent.png";
import Localize from "react-intl-universal";
import styles from "./style/HeadingDetails.module.css";

const HeadingSegment = props => {
  const [heading1] = useState([
    {
      heading: "WELCOME_PAGE.HEADING_DETAILS_HEADING1",
      details: "WELCOME_PAGE.HEADING_DETAILS_DETAILS1",
      img: financeContent
    },
    {
      heading: "WELCOME_PAGE.HEADING_DETAILS_HEADING2",
      details: "WELCOME_PAGE.HEADING_DETAILS_DETAILS2",
      img: documentsContent
    }
  ]);
  const [heading2] = useState([
    {
      heading: "WELCOME_PAGE.HEADING_DETAILS_HEADING3",
      details: "WELCOME_PAGE.HEADING_DETAILS_DETAILS3",
      img: contactsContent
    }
  ]);

  return (
    <>
      {props.textFirst ? (
        <Grid columns={"2"}>
          <Grid.Column width={6}>
            <img
              src={heading1[props.i].img}
              alt={heading1[props.i].heading}
              className="ui medium image"
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <h1 className="ui header">{Localize.get(heading1[props.i].heading)}</h1>
            <p className={styles.wptext}>{Localize.get(heading1[props.i].details)}</p>
          </Grid.Column>
        </Grid>
      ) : (
        <Grid columns={"2"}>
          <Grid.Column width={10}>
            <h1 className="ui header">{Localize.get(heading2[0].heading)}</h1>
            <p className={styles.wptext}>
              {Localize.get(heading2[0].details)}
              {}
            </p>
          </Grid.Column>

          <Grid.Column width={6}>
            <img src={heading2[0].img} alt={heading2[0].heading} className="ui medium image" />
          </Grid.Column>
        </Grid>
      )}
    </>
  );
};

export default HeadingSegment;
