import React, { useState } from "react";
import GlobalPanel from "../Components/Panels/GlobalPanel";
import * as constants from "./Constants";
import PropertiesRightPanel from "./Properties/PropertiesRightPanel";
import RolesRightPanel from "./Roles/RolesRightPanel";
import SettingsMiddlePanel from "./SettingsMiddlePanel";
import UsersRightPanel from "./Users/UsersRightPanel";
import { leftPanelConfig } from "./SettingsConfig";
import AccountingRightPanel from "./Accounting/AccountingRightPanel";
import useGetAll from "../CustomHooks/useGetAll";
import { panelDefaultConf } from "../CustomHooks/GlobalPanel.config";
import TemplateMiddlePanel from "./Templates/TemplateMiddlePanel";
import DunningRightPanel from "./Dunning/DunningRightPanel";
import DefaulTemplateRightPanel from "./Templates/DefaultTemplateRightPanel";
import { useLocation } from "react-router-dom";

const Settings = ({ setHeaderDimmed }) => {
  const location = useLocation();
  const routeModule = location && location.state && location.state.routeModule;

  const [currentModule, setCurrentModule] = useState(
    routeModule ? routeModule : constants.panelModule.users
  );

  const [
    selectedItem,
    listState,
    currentView,
    isLoading,
    activePaginationPage,
    filterObject,
    searchObject,
    forbidenResponse,
    paginationStatus,
    callGetAll,
    setSelectedItem,
    setListState,
    filterHandler,
    searchFilter,
    sortHandler,
    setActivePaginationPage,
    setCurrentView
  ] = useGetAll(
    currentModule === "settings/templates" ? undefined : currentModule,
    { sort: "createdAt,desc", topLevel: "true" },
    {},
    () => {},
    currentModule === "categories"
      ? resp => {
          return resp;
        }
      : null
  );

  setHeaderDimmed(currentView === "create" || currentView === "edit" || isLoading);

  const getCustomRightPanel = () => {
    switch (currentModule) {
      case constants.panelModule.properties:
        return PropertiesRightPanel;
      case constants.panelModule.users:
        return UsersRightPanel;
      case constants.panelModule.roles:
        return RolesRightPanel;
      case constants.panelModule.accounting:
        return AccountingRightPanel;
      case constants.panelModule.dunning:
        return DunningRightPanel;
      case constants.panelModule.default_templates:
        return DefaulTemplateRightPanel;
      case constants.panelModule.accounts:
        return () => <div />;
      default:
        return () => <div />;
    }
  };

  const myConfigForAccounting = {
    leftPanel: {
      form: {
        hideUnder: 30000,
        hideMenuIconUnder: 30000
      },
      details: {
        hideUnder: 1281,
        hideMenuIconUnder: 1281
      }
    },
    middlePanel: {
      form: {
        hideUnder: 30000
      },
      details: {
        hideUnder: 0
      }
    },
    rightPanel: {
      form: {
        hideUnder: 0
      },
      details: {
        hideUnder: 0
      }
    }
  };

  const leftPanelProps = {
    leftPanelConfig: leftPanelConfig(currentModule),
    setCurrentModule: setCurrentModule,
    currentModule: currentModule,
    setCurrentView: setCurrentView,
    filterObject: filterObject,
    filterHandler: filterHandler,
    setSelectedItem: setSelectedItem,
    forbidenResponse: forbidenResponse,
    setListState: setListState,
    setActivePaginationPage: setActivePaginationPage
  };

  const middlePanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    listState: listState,
    setListState: setListState,
    searchFilter: searchFilter,
    filterObject: filterObject,
    searchObject: searchObject,
    forbidenResponse: forbidenResponse,
    listDimmed:
      currentView === constants.panelView.edit || currentView === constants.panelView.create,
    activePaginationPage: activePaginationPage,
    handlePageChange: setActivePaginationPage,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    paginationStatus: paginationStatus,
    callGetAll: callGetAll,
    sortHandler: sortHandler
  };

  const rightPanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    selectedItem: selectedItem,
    callGetAll: callGetAll,
    activePaginationPage: activePaginationPage,
    setActivePaginationPage: setActivePaginationPage,
    lenghtOfList: listState.length
  };

  return (
    <>
      {currentModule === "settings/templates" ? (
        <GlobalPanel
          currentView={currentView}
          setCurrentModule={setCurrentModule}
          customizedMiddlePanelComponent={TemplateMiddlePanel}
          leftPanelProps={leftPanelProps}
          middlePanelProps={middlePanelProps}
          rightPanelProps={rightPanelProps}
          isLoading={isLoading}
          globalPanelConf={{
            ...panelDefaultConf,
            rightPanel: {
              form: {
                hideUnder: 0
              },
              details: {
                hideUnder: 30000
              }
            }
          }}
        />
      ) : (
        <GlobalPanel
          currentView={currentView}
          setCurrentModule={setCurrentModule}
          customizedMiddlePanelComponent={SettingsMiddlePanel}
          customizedRightPanelComponent={getCustomRightPanel()}
          leftPanelProps={leftPanelProps}
          middlePanelProps={middlePanelProps}
          rightPanelProps={rightPanelProps}
          isLoading={isLoading}
          globalPanelConf={
            currentView === "edit" &&
            selectedItem &&
            selectedItem.name &&
            selectedItem.name.subcategoryName === "custom_chart_of_accounts"
              ? myConfigForAccounting
              : undefined
          }
        />
      )}
    </>
  );
};

export default Settings;
