import React, { useState } from "react";
import { Container, Dropdown, Input, Form, Radio } from "semantic-ui-react";

import DatepickerWithDropdown from "../../Documents/RightPanel/DatepickerWithDropdown";
import styles from "./style/QuickModalComponent.module.css";
import Localize from "react-intl-universal";

const QuickModalComponent = props => {
  const { handleOnChange, activeModalRadio, exportDateRange, setExportDateRange } = props;

  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const currentLanguage = navigator.language.toLowerCase().includes("de") ? "de" : "en";

  const trigger = (
    <span>
      <Input
        className={styles.datepicker_input}
        icon="calendar alternate outline"
        readOnly
        id="fromDate"
        name="fromDate"
        type="text"
        placeholder="Date"
        value={
          exportDateRange.from.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }) +
          " - " +
          exportDateRange.to.toLocaleDateString(currentLanguage, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
        }
      />
    </span>
  );

  const closeDateMenu = () => {
    setDateMenuOpen(false);
  };

  return (
    <>
      <Container>
        <Form>
          <Form.Field>
            <Radio
              toggle
              label={Localize.get("DOCUMENTS.MODAL.MODAL_LAST_IMPORTED")}
              name="radioGroup"
              value="last"
              checked={activeModalRadio === "last"}
              onChange={handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={Localize.get("DOCUMENTS.MODAL.MODAL_ALL_IMPORTED")}
              name="radioGroup"
              value="all"
              checked={activeModalRadio === "all"}
              onChange={handleOnChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              toggle
              label={Localize.get("DOCUMENTS.MODAL.MODAL_SELECT")}
              name="radioGroup"
              value="select"
              checked={activeModalRadio === "select"}
              onChange={handleOnChange}
            />
          </Form.Field>
          <Container className={styles.calendar_dropdown}>
            <Dropdown
              trigger={trigger}
              floating
              labeled
              button
              disabled={activeModalRadio !== "select"}
              onClick={() => setDateMenuOpen(true)}
              onBlur={closeDateMenu}
              closeOnBlur
              icon={null}
            >
              <Dropdown.Menu open={dateMenuOpen}>
                <DatepickerWithDropdown
                  numberOfMonths={2}
                  setExportDateRange={setExportDateRange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </Form>
      </Container>
    </>
  );
};

export default QuickModalComponent;
