export const availableItemFieldsForList = [
  {
    name: "checked",
    type: "checkbox",
    width: 1,
    visible: true
  },
  {
    name: "id",
    hederName: "PROJECTS.ROLES.ROLE_NUMBER",
    type: "text",
    width: 3,
    visible: true
  },
  {
    name: "name",
    hederName: "PROJECTS.ROLES.ROLE_NAME_PLACEHOLDER",
    type: "text",
    width: 3,
    searchable: true,
    disabled: true,
    visible: true
  },
  {
    name: "description",
    hederName: "PROJECTS.ROLES.ROLE_DESCRIPTION_ONLY",
    type: "text",
    width: 5,
    searchable: true,
    disabled: true,
    visible: true
  },
  {
    hederName: "SETTINGS.HEADERS.OPERATION",
    name: "operations",
    type: "iconGroup",
    visible: true,
    icons: [
      {
        name: "edit",
        type: "icon",
        operation: "edit",
        width: 1,
        popupText: "PROJECTS.ROLES.EDIT_ROLE"
      },
      {
        name: "archive",
        type: "icon",
        operation: "archive",
        width: 1,
        popupText: "SETTINGS.ICONS.ARCHIVE"
      },
      {
        name: "trash alternate",
        type: "icon",
        operation: "delete",
        width: 1,
        popupText: "PROJECTS.ROLES.DELETE_ROLE"
      }
    ]
  }
];

export const availableItemFieldsForArchive = [
  {
    name: "checked",
    type: "checkbox",
    width: 1
  },
  {
    name: "id",
    hederName: "PROJECTS.ROLES.ROLE_NUMBER",
    type: "text",
    width: 3
  },
  {
    name: "name",
    hederName: "PROJECTS.ROLES.ROLE_NAME_PLACEHOLDER",
    type: "text",
    width: 3
  },
  {
    name: "description",
    hederName: "PROJECTS.ROLES.ROLE_DESCRIPTION_ONLY",
    type: "text",
    width: 5
  }
];

export const leftPanelConfig = [
  { type: "createNew", buttonText: "PROJECTS.ROLES.NEW_ROLE" },
  {
    type: "sectionHeader",
    itemList: [
      {
        type: "filter",
        name: "archived",
        itemText: "CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED",
        iconName: "archive"
      }
    ]
  }
];

export const myEmptyRole = { name: "", description: "" };

export const initialFilterObject = {
  search: "",
  sortBy: "",
  sortDirection: ""
};
