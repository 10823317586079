import React, { useState, useRef, useEffect } from "react";
import Localize from "react-intl-universal";
import { Icon } from "semantic-ui-react";

const DragAndDropPanel = props => {
  const [dragging, setDragging] = useState(false);
  let dragCounter = 0;
  const dropRef = useRef(null);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setDragging(false);
    }
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      props.handler.documentUpload.onFileDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  useEffect(() => {
    let div = dropRef.current;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);

    return function cleanup() {
      let div = dropRef.current;
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  return (
    <div
      style={{
        height: "100%",
        textAlign: "center",
        marginTop: "50%",
        fontSize: 36
      }}
      ref={dropRef}
    >
      {dragging && (
        <div
          style={{
            border: "dashed grey 4px",
            backgroundColor: "rgba(255,255,255,.8)",
            position: "absolute",
            top: "2%",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              left: 0,
              textAlign: "center",
              color: "grey",
              fontSize: 36
            }}
          >
            <div>{Localize.get("DOCUMENTS.NEWDOCUMENT.DRAG_N_DROP_MSG")}</div>
          </div>
        </div>
      )}
      <div> {Localize.get("DOCUMENTS.NEWDOCUMENT.DRAG_N_DROP_AREA")}</div>
      <br />
      <div>
        {" "}
        <Icon name="dropbox"></Icon>
      </div>
    </div>
  );
};
export default DragAndDropPanel;
