import React, { useEffect, useState } from "react";
import Localize from "react-intl-universal";
import { List, Icon, Divider, Message, Popup, Dimmer, Loader } from "semantic-ui-react";
import DetailsAddress from "../RightPanel/DetailsAddress";
import DetailsContactPerson from "../RightPanel/DetailsContactPerson";
import DetailsAccount from "./DetailsAccount";
import * as constant from "../Constants.js";
import styles from "./style/ContactDetailsPanel.module.css";
import GlobalDetailsTextItem from "../../Components/Details/GlobalDetailsTextItem";
import InvoiceSettingsDetails from "./InvoiceSettingsDetails";
import { get } from "lodash";

const ContactDetailsPanel = props => {
  const [showContactNote, setShowContactNote] = useState(false);
  const { privileges, selectedContact, partnerFetchLoading } = props;

  useEffect(() => {
    if (document.getElementById("detailsPanel"))
      document.getElementById("detailsPanel").scrollTop = 0;
  }, [selectedContact]);

  const getContactTypeText = type => {
    let text;
    switch (type) {
      case constant.contactType.company:
        text = Localize.get("CONTACTS.DETAILS.TOOLBAR.COMPANY");
        break;
      case constant.contactType.person:
        text = Localize.get("CONTACTS.DETAILS.TOOLBAR.PERSON");
        break;
      case constant.contactType.employee:
        text = Localize.get("CONTACTS.DETAILS.TOOLBAR.EMPLOYEE");
        break;

      default:
        break;
    }
    return text;
  };

  // Handlers
  const handleOnEditClick = (event, data) => {
    if (privileges.update) props.handle.onEditClick(selectedContact);
  };

  const handleOnArchiveClick = (event, data) => {
    if (privileges.update) props.handle.onArchiveClick(selectedContact);
  };

  const handleOnDeleteClick = (event, data) => {
    if (privileges.update) props.handle.onDeleteClick(selectedContact);
  };

  const displayContactNote = () => {
    setShowContactNote(!showContactNote);
  };

  const formatUrl = to => {
    if (to) {
      if (!to.includes("http")) {
        return "http://" + to;
      } else {
        return to;
      }
    }
    return to;
  };

  let render;

  if (!selectedContact) {
    render = (
      <>
        <div className={`header ${styles.contact_details}`}>
          <span className={styles.header_left}>
            {Localize.get("CONTACTS.DETAILS.TOOLBAR.EMPTY_DETAILS")}
          </span>
        </div>
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("CONTACTS.LIST.TOOLBAR.EMPTY_DETAILS")}
        </Message>
      </>
    );
  } else {
    render = (
      <>
        <Dimmer active={partnerFetchLoading} inverted>
          <Loader className={styles.loader_center} size="large" disabled={!partnerFetchLoading}>
            {Localize.get("CONTACTS.LIST.TOOLBAR.PARTNERS_LOADING")}
          </Loader>
        </Dimmer>
        <div className={styles.contact_details}>
          <span className={styles.header_left}>{getContactTypeText(selectedContact.type)}</span>

          <Popup
            size="mini"
            content={Localize.get("CONTACTS.DETAILS.TOOLBAR.EDIT")}
            trigger={
              <Icon
                link
                disabled={!privileges.update}
                name="edit"
                size="large"
                onClick={handleOnEditClick}
                className={styles.details_icon}
              />
            }
          />
          <Popup
            size="mini"
            content={
              selectedContact.archived === false
                ? Localize.get("CONTACTS.DETAILS.TOOLBAR.ARCHIVE")
                : Localize.get("CONTACTS.DETAILS.TOOLBAR.UNARCHIVE")
            }
            trigger={
              <Icon.Group size="large" disabled={!privileges.update} onClick={handleOnArchiveClick}>
                <Icon name="archive" disabled={!privileges.update} />
                <Icon
                  corner
                  disabled={!privileges.update}
                  name={selectedContact.archived === true ? "minus circle" : "plus circle"}
                />
              </Icon.Group>
            }
          />
          <Popup
            size="mini"
            content={Localize.get("CONTACTS.DETAILS.TOOLBAR.DELETE")}
            trigger={
              <Icon
                link
                disabled={!privileges.delete}
                name="trash alternate"
                size="large"
                onClick={handleOnDeleteClick}
                className={`${styles.details_icon} ${styles.trash}`}
              />
            }
          />
        </div>
        <div className={`${styles.detailsPanelHeight} ${styles.details_container}`}>
          {/* Name */}
          <GlobalDetailsTextItem
            itemName={"CONTACTS.DETAILS.FORM.NAME"}
            itemValue={selectedContact.name}
          />
          {selectedContact.dtype === "employee" && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.NEWCONTACT.FORM.JOB_TITLE"}
              itemValue={selectedContact.jobTitle}
            />
          )}
          {selectedContact.isCustomer && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.CUSTOMER_NUMBER"}
              itemValue={selectedContact.customer.id}
            />
          )}
          {selectedContact.isCustomer && selectedContact.customerExternalNumber !== null && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.CUSTOMER_NUMBER_EXTERNAL"}
              itemValue={selectedContact.customerExternalNumber}
            />
          )}
          {selectedContact.vatin && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.VATIN"}
              itemValue={selectedContact.vatin}
            />
          )}
          {selectedContact.taxNumber && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.TAX_NUMBER"}
              itemValue={selectedContact.taxNumber}
            />
          )}
          {
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.VERIFIED_PARTNER"}
              itemValue={
                get(selectedContact, "verified")
                  ? Localize.get("CONTACTS.DETAILS.FORM.VERIFIED")
                  : Localize.get("CONTACTS.DETAILS.FORM.NOT_VERIFIED")
              }
            />
          }
          {selectedContact.isSupplier && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.SUPPLIER_NUMBER"}
              itemValue={selectedContact.supplier.id}
            />
          )}
          {selectedContact.isSupplier && selectedContact.supplierExternalNumber !== null && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.SUPPLIER_NUMBER_EXTERNAL"}
              itemValue={selectedContact.supplierExternalNumber}
            />
          )}
          {selectedContact.uncategorized && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.EMPLOYEE_NUMBER"}
              itemValue={selectedContact.uncategorized.id}
            />
          )}
          {selectedContact.uncategorized && selectedContact.employeeExternalNumber !== null && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.EMPLOYEE_NUMBER_EXTERNAL"}
              itemValue={selectedContact.employeeExternalNumber}
            />
          )}

          {selectedContact.phone && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.PHONE"}
              itemValue={selectedContact.phone}
            />
          )}
          {selectedContact.mobile && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.MOBILE"}
              itemValue={selectedContact.mobile}
            />
          )}
          {selectedContact.fax && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.FAX"}
              itemValue={selectedContact.fax}
            />
          )}
          {selectedContact.email && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.EMAIL"}
              itemValue={selectedContact.email}
            />
          )}
          {selectedContact.dtype !== constant.contactType.employee && selectedContact.url && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.WEB"}
              itemValue={
                <a target="_blank" rel="noopener noreferrer" href={formatUrl(selectedContact.url)}>
                  {selectedContact.url}
                </a>
              }
            />
          )}
          {selectedContact.dtype === constant.contactType.employee && (
            <GlobalDetailsTextItem
              itemName={"CONTACTS.DETAILS.FORM.COMPANY_NAME"}
              itemValue={selectedContact.company.name}
            />
          )}

          <List divided verticalAlign="middle" className={styles.details_devided}></List>
          {selectedContact.note && (
            <>
              <div className={styles.note_details}>
                <div className={styles.header_notes}>
                  <span className={styles.header_title}>
                    {Localize.get("CONTACTS.DETAILS.FORM.NOTE")}
                  </span>
                  <div className={styles.header_icon}>
                    {!showContactNote ? (
                      <Icon name="sticky note outline" size="large" onClick={displayContactNote} />
                    ) : (
                      <Icon name="sticky note" size="large" onClick={displayContactNote} />
                    )}
                  </div>
                </div>
                <div>
                  {showContactNote ? (
                    <div className="item">
                      <div className="content">
                        <div className={styles.description_of_issue}>{selectedContact.note}</div>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <Divider />
            </>
          )}
          {/* Address */}
          {selectedContact.address && (
            <>
              <strong className={styles.section_headers}>
                {Localize.get("CONTACTS.DETAILS.FORM.ADDRESSES")}
              </strong>
              <DetailsAddress
                title={Localize.get("CONTACTS.DETAILS.FORM.ADDRESS")}
                address={selectedContact.address}
                open={false}
              />
              <Divider />
            </>
          )}
          {selectedContact.shipToAddress && (
            <>
              <DetailsAddress
                title={Localize.get("CONTACTS.DETAILS.FORM.SHIPTOADDRESS")}
                address={selectedContact.shipToAddress}
                open={false}
              />
              <Divider />
            </>
          )}
          {selectedContact.billingAddress && (
            <>
              <DetailsAddress
                title={Localize.get("CONTACTS.DETAILS.FORM.BILLINGADDRESS")}
                address={selectedContact.billingAddress}
                open={false}
              />
              <Divider />
            </>
          )}

          {selectedContact.contacts && selectedContact.contacts.length > 0 && (
            <>
              <strong className={styles.section_headers}>
                {Localize.get("CONTACTS.DETAILS.FORM.CONTACT_PERSONS")}
              </strong>
              {selectedContact.contacts.map((contactPerson, index) => (
                <DetailsContactPerson
                  key={contactPerson.id}
                  contactPerson={contactPerson}
                  open={false}
                />
              ))}
              <Divider />
            </>
          )}

          {selectedContact.accounts && selectedContact.accounts.length > 0 && (
            <>
              <strong className={styles.section_headers}>
                {Localize.get("CONTACTS.DETAILS.FORM.ACCOUNTS")}
              </strong>
              {selectedContact.accounts.map((account, index) => (
                <DetailsAccount key={account.id} account={account} open={false} />
              ))}
              <Divider />
            </>
          )}

          {selectedContact.dtype === "company" && (
            <>
              <strong className={styles.section_headers}>
                {Localize.get("DOCUMENTS.ITEMLIST.HEADER.INVOICING_AND_DUNNING")}
              </strong>
              <InvoiceSettingsDetails company={selectedContact} />
            </>
          )}
        </div>
      </>
    );
  }
  return <>{render}</>;
};

export default ContactDetailsPanel;
