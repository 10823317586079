import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import Localize from "react-intl-universal";
import GlobalCancelBtn from "../GlobalCancelBtn";
import GlobalOkButton from "../GlobalOkBtn";

const GlobalModal = props => {
  const {
    CustomComponent,
    setShowModal = function(arg) {},
    modalHandler,
    showModal = false,
    size = "small",
    dimmer = "blurring",
    closeIcon = true,
    headerText,
    showActions = true,
    showCancel = true,
    customClassName = null,
    customContentClassName = null,
    okDisabled = false
  } = props;

  const BuildedCustomComponent =
    CustomComponent && React.createElement(CustomComponent, props, null);

  return (
    <Modal
      open={showModal}
      size={size}
      dimmer={dimmer}
      closeIcon={closeIcon}
      onClose={() => setShowModal(false)}
      className={customClassName}
    >
      {headerText && <Modal.Header>{Localize.get(headerText)}</Modal.Header>}

      <Modal.Content className={customContentClassName}>{BuildedCustomComponent}</Modal.Content>

      {showActions && (
        <Modal.Actions>
          {modalHandler && <GlobalOkButton disabled={okDisabled} onOkClicked={modalHandler} />}

          {showCancel && <GlobalCancelBtn onCancel={() => setShowModal(false)} />}
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default GlobalModal;
