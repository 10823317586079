import React, { useState } from "react";
import ContactListItem from "../../Contacts/MiddlePanel/ContactListItem";
import GlobalList from "../../Components/Lists/GlobalList";
import { toggleOnlyOne } from "../../Components/Utils/GlobalListsUtil";
import * as constant from "../Constants";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider/Divider";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader/Loader";
import Container from "semantic-ui-react/dist/commonjs/elements/Container/Container";
import { convert2Contacts } from "../../Contacts/Util";
import useGetAll from "../../CustomHooks/useGetAll";
import { Grid } from "semantic-ui-react";
import styles from "./style/ReferedPartner.module.css";
import Localize from "react-intl-universal";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import GlobalOkButton from "../../Components/GlobalOkBtn";

const ReferedPartner = ({
  documentZugfredObject,
  checkPartner,
  modalHandler,
  setShowModal,
  partnerValue
}) => {
  const { partner } = documentZugfredObject;
  const [finalState, setFinalState] = useState(undefined);

  const formatSuccess = list => {
    let tempList = convert2Contacts(list.data);
    let tempList2 = tempList.map(item => {
      if (partnerValue && partnerValue.id && item.id === partnerValue.id) {
        item.checked = true;
      }
      return item;
    });
    return tempList2;
  };

  const [
    ,
    contacts,
    ,
    isLoading,
    activePaginationPage,
    filterObject,
    searchObject,
    ,
    paginationStatus,
    ,
    ,
    setContacts,
    ,
    searchFilter,
    sortHandler,
    setActivePaginationPage
  ] = useGetAll(
    "partners",
    {
      partnerRole:
        documentZugfredObject.direction === constant.documentDirection.incoming
          ? "supplier"
          : "customer",
      match: "containing"
    },
    { search: "", sortBy: "", sortDirection: "" },
    () => {},
    formatSuccess
  );

  const toggleItem = data => {
    toggleOnlyOne(data.item_id, setContacts);
    setFinalState(previous => {
      if (previous && previous === data.item_id) {
        return undefined;
      } else {
        return data.item_id;
      }
    });
  };

  const confirmModalHandler = (e, data) => {
    let myPartner = contacts.find(item => item.id === finalState);
    modalHandler(e, data, myPartner);
  };

  return (
    <>
      {isLoading && (
        <Dimmer inverted active={isLoading}>
          <Loader disabled={!isLoading} />
        </Dimmer>
      )}
      {checkPartner && (
        <Grid className={styles.grid_cont}>
          <Grid.Column width={4}>
            <Container>
              <b>{Localize.get("DOCUMENTS.ITEMLIST.HEADER.NAME_ADDRESS")}</b>
              <Divider hidden className={styles.my_divider} />
              <Grid.Row>{partner.name}</Grid.Row>
              {partner && partner.address && (
                <>
                  <Grid.Row>{partner.address.street}</Grid.Row>
                  <Grid.Row>{partner.address.city}</Grid.Row>
                  <Grid.Row>{partner.address.state}</Grid.Row>
                  <Grid.Row>{partner.address.country}</Grid.Row>
                </>
              )}
            </Container>
          </Grid.Column>
          <Grid.Column width={4}>
            <Container>
              <b>{Localize.get("DOCUMENTS.ITEMLIST.HEADER.CONTACT_DETAILS")}</b>
              <Divider hidden className={styles.my_divider} />
              <Grid.Row>{partner.phone}</Grid.Row>
              <Grid.Row>{partner.mobile}</Grid.Row>
              <Grid.Row>{partner.email}</Grid.Row>
              <Grid.Row>{partner.web}</Grid.Row>
            </Container>
          </Grid.Column>
        </Grid>
      )}

      <Divider />
      <GlobalList
        CustomItemComponent={ContactListItem}
        listState={contacts}
        itemHandler={toggleItem}
        availableItemFieldsForList={constant.partnerListConfig}
        searchFilter={searchFilter}
        sortHandler={sortHandler}
        filterObject={filterObject}
        searchObject={searchObject}
        showAtBottom={true}
        paginationStatus={paginationStatus}
        handlePageChange={setActivePaginationPage}
        activePaginationPage={activePaginationPage}
        listPaginationCustomCSS={styles.item_in_list}
      />
      <Divider className={styles.divider_actions} />
      <div className={styles.div_actions}>
        <GlobalOkButton onOkClicked={confirmModalHandler} disabled={!finalState} />
        <GlobalCancelBtn onCancel={() => setShowModal(false)} />
      </div>
    </>
  );
};

export default ReferedPartner;
