export const nameFieldConfig = {
  name: "name",
  hederName: "SETTINGS.HEADERS.ASSIGNED_PERMISSIONS",
  type: "text",
  width: 12,
  visible: true
};
export const removeButtonFieldConfig = {
  type: "button",
  name: "remove_button",
  hederName: "SETTINGS.HEADERS.OPERATION",
  operation: "remove",
  buttonText: "SETTINGS.BUTTONS.REMOVE",
  width: 4,
  visible: true,
  element_semantic_properties: { basic: true }
};

export const addButtonFieldConfig = {
  type: "button",
  name: "add_button",
  hederName: "SETTINGS.HEADERS.OPERATION",
  operation: "add",
  buttonText: "SETTINGS.BUTTONS.ADD",
  width: 4,
  visible: true,
  element_semantic_properties: { basic: true }
};
