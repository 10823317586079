import React, { useState } from "react";
import { FormField, Form, Checkbox, Input, Icon, Grid } from "semantic-ui-react";
import styles from "../style/Properties.module.css";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";
import Localize from "react-intl-universal";
import GlobalModal from "../../Components/Panels/GlobalModal";
import TemplateModalList from "./TemplateModalList";

const DefaultTemplateForm = props => {
  const {
    currentItem,
    setCurrentItem,
    setEditedFields,
    editedFields,
    parameters,
    availableItemFieldsForDetails,
    putDefaultTemplate
    // putLoading
  } = props;

  const { subCategories = [] } = currentItem;

  const [showModal, setShowModal] = useState(undefined);
  const [activeInput, setActiveInput] = useState();

  const findProperty = propertyName => {
    let tempItem = subCategories.find(item => item.categoryKey === propertyName);

    return tempItem && tempItem.defaultTemplate && tempItem.defaultTemplate.name
      ? tempItem.defaultTemplate.name
      : "";
  };

  const changeInputField = (e, data) => {
    let editedItemId;
    let tempItem = subCategories.map(item => {
      if (item.name === data.name) {
        editedItemId = item.id;
        return {
          ...item,
          value: data.type === "text" || data.type === "password" ? data.value : data.checked
        };
      }
      return item;
    });

    if (!editedFields.includes(editedItemId)) {
      let tempArray = editedFields;
      tempArray.push(editedItemId);
      setEditedFields(tempArray);
    }
    setCurrentItem({ ...currentItem, subCategories: tempItem });
  };

  const removeTemplate = async (name, key) => {
    let tempItem = subCategories.filter(category => category.categoryKey !== name);

    setCurrentItem({ ...currentItem, subCategories: tempItem });

    let delDefault = subCategories.map(categ => {
      if (categ.categoryKey === name) {
        return { ...categ, defaultTemplate: { name: "" } };
      } else {
        return categ;
      }
    });

    setCurrentItem({
      ...currentItem,
      subCategories: delDefault
    });

    await putDefaultTemplate(`categories/${name}`, {
      templateId: ""
    });
  };

  const handleSearch = (e, data) => {
    setActiveInput(data.id.name);
    setShowModal(true);
  };

  const addTemplate = async (id, templateName, categoryKey) => {
    let addDefault = subCategories.map(categ => {
      if (categ.categoryKey === categoryKey) {
        return { ...categ, defaultTemplate: { name: templateName } };
      } else {
        return categ;
      }
    });
    setCurrentItem({
      ...currentItem,
      subCategories: addDefault
    });
    await putDefaultTemplate(`categories/${categoryKey}`, {
      templateId: id
    });

    setShowModal(false);
  };

  const getProperties = () => {
    return availableItemFieldsForDetails.map((field, index) => {
      let propertyValue = findProperty(field.name);
      let subcategory = subCategories.find(item => item.categoryKey === field.name);

      if (field.type === "boolean") {
        return (
          <FormField key={index} required={false}>
            <label>{Localize.get(field.hederName)}</label>
            <Checkbox
              name={field.name}
              checked={propertyValue === "true" || propertyValue === true}
              toggle
              onChange={changeInputField}
            />
          </FormField>
        );
      } else if (field.type === "template_search") {
        return (
          <Grid className={styles.no_margin} key={index}>
            <Grid.Row key={index}>
              <Grid.Column width={6} verticalAlign="middle">
                {Localize.get(field.hederName)}
              </Grid.Column>
              <Grid.Column width={10}>
                <Input
                  // id={field.id}
                  name={field.name}
                  placeholder={Localize.get("CONTACTS.DETAILS.FORM.DEFAULT_TEMPLATE")}
                  value={propertyValue}
                  fluid
                  icon={
                    propertyValue !== "" ? (
                      <Icon
                        name="close"
                        // documentid={item.id}
                        className={styles.temp_in_icon}
                        onClick={() => removeTemplate(field.name)}
                        link
                      />
                    ) : (
                      <Icon
                        name="search"
                        // id={field.name}
                        id={{
                          id: subcategory ? subcategory.id : null,
                          name: field.name
                        }}
                        // onClick={() => setShowModal(item.id)}
                        onClick={handleSearch}
                        link
                        // disabled={disableCauseParent}
                      />
                    )
                  }
                />{" "}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      } else {
        return (
          <GlobalTextInputField
            key={index}
            labelName={field.hederName}
            inputName={field.name}
            itemValue={propertyValue}
            isRequired={field.isRequired}
            onInputChange={changeInputField}
            errorFields={parameters}
            inputDisabled={field.inputDisabled}
            inputType={field.type}
          />
        );
      }
    });
  };

  return (
    <>
      {showModal && (
        <GlobalModal
          showModal={showModal ? true : false}
          documentTypeId={showModal}
          setShowModal={setShowModal}
          addTemplate={addTemplate}
          activeInput={activeInput}
          // changeInputField={changeInputField}
          CustomComponent={TemplateModalList}
          showActions={false}
          headerText={"SETTINGS.SIDEBAR_OPTIONS.TEMPLATE"}
        />
      )}
      <Form>{getProperties()}</Form>
    </>
  );
};

export default DefaultTemplateForm;
