import React, { useState, useEffect, useRef } from "react";
import Localize from "react-intl-universal";
import { Select, Message, Icon, Dimmer, Loader } from "semantic-ui-react";
import ContactFormCompany from "./ContactFormCompany";
import ContactFormPerson from "./ContactFormPerson";
import ContactFormEmployee from "./ContactFormEmployee";
import * as constant from "../Constants.js";
import styles from "./style/ContactFormPanel.module.css";
import FillMandatoryDataWarningNotification from "../../Notifications/FillMandatoryDataWarningModal";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import {
  createNewCompanyObject,
  createNewPersonObject,
  createNewEmployeeObject,
  createUpdateCompanyObject,
  createUpdatePersonObject,
  createUpdateEmployeeObject
} from "../Util";

const ContactFormPanel = props => {
  const {
    selectedContact,
    mode,
    setPanelState,
    panelState,
    postPartnerCall,
    fetchPartnerCall,
    postLoading
  } = props;
  const [contact, setContact] = useState(selectedContact);

  const contactTypeOptions = [
    {
      key: constant.contactType.company,
      value: constant.contactType.company,
      text: Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.COMPANY")
    },
    {
      key: constant.contactType.person,
      value: constant.contactType.person,
      text: Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.PERSON")
    },
    {
      key: constant.contactType.employee,
      value: constant.contactType.employee,
      text: Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.EMPLOYEE")
    }
  ];
  const [contactType, setContactType] = useState(
    mode === constant.panelMode.edit && selectedContact && selectedContact.type
      ? selectedContact.type
      : undefined
  );

  const [editedFields, setEditedFields] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const refPersonPanel = useRef(null);

  const [isNotificationAlive, setIsNotificationAlive] = useState(false);

  const onCancelButtonClick = () => {
    setPanelState({
      ...panelState,
      activeMode: constant.panelMode.details
    });
  };

  /* === HANDLERS === */
  const handleOnContactChange = (contact, field) => {
    if (field && !editedFields.includes(field)) {
      const newArr = [...editedFields];
      newArr.push(field);
      setEditedFields(newArr);
    }
    setContact(contact);
  };

  const handleContactTypeOnChange = (event, data) => {
    setContactType(data.value);
    if (data.value === "person") {
      setContact({ ...selectedContact, isCustomer: true, isSupplier: false });
    }
  };

  const handleUpdateClick = async () => {
    if (editedFields && editedFields.length > 0) {
      setDisableSaveBtn(true);

      if (validateFormOnSave(contact)) {
        let updatedObject = null;
        let url;

        switch (contact.type) {
          case constant.contactType.company:
            updatedObject = createUpdateCompanyObject({
              ...contact,
              editedFields: editedFields
            });
            url = `/partners/companies/${selectedContact.id}`;
            break;
          case constant.contactType.person:
            updatedObject = createUpdatePersonObject({
              ...contact,
              editedFields: editedFields
            });
            url = `/partners/persons/${selectedContact.id}`;
            break;
          case constant.contactType.employee:
            updatedObject = createUpdateEmployeeObject({
              ...contact,
              editedFields: editedFields
            });
            url = `/partners/employees/${selectedContact.id}`;
            break;
          default:
            break;
        }
        await postPartnerCall(updatedObject, url, false, "PATCH");
      } else {
        setDisableSaveBtn(false);
      }
    } else {
      fetchPartnerCall();
      setPanelState(prevState => {
        return { ...prevState, activeMode: "details" };
      });
    }
  };

  const handleOnSaveAndNewButtonClick = async () => {
    makePostCall(true);
  };

  const handleOnSaveButtonClick = async () => {
    makePostCall();
  };

  const makePostCall = async saveAndNew => {
    setDisableSaveBtn(true);

    if (validateFormOnSave(contact)) {
      let newObject = null;
      let url;

      switch (contact.type) {
        case constant.contactType.company:
          newObject = createNewCompanyObject(contact);
          url = "/partners/companies";
          break;
        case constant.contactType.person:
          newObject = createNewPersonObject(contact);
          url = "/partners/persons";
          break;
        case constant.contactType.employee:
          newObject = createNewEmployeeObject(contact);
          url = "/partners/employees";
          break;
        default:
          break;
      }
      await postPartnerCall(newObject, url, saveAndNew);
      if (saveAndNew) {
        setContactType("");
        setDisableSaveBtn(false);
      }
    } else {
      setDisableSaveBtn(false);
    }
  };

  const validateFormOnSave = contact => {
    let valid = refPersonPanel.current.validateFormOnSave(contact);

    if (!valid && !isNotificationAlive) {
      setIsNotificationAlive(true);
    }
    return valid;
  };

  useEffect(() => {
    setContact(selectedContact);
  }, [contactType]);

  // RENDER
  return (
    <>
      <Dimmer active={postLoading} inverted>
        <Loader size="large" disabled={!postLoading}>
          {Localize.get("CONTACTS.NEWCONTACT.FORM.SAVING_PARTNER")}
        </Loader>
      </Dimmer>
      {isNotificationAlive && (
        <FillMandatoryDataWarningNotification
          isAlive={isNotificationAlive}
          setIsNotificationAlive={setIsNotificationAlive}
        />
      )}

      {/* HEADER WITH CONTACT TYPE */}
      {mode === constant.panelMode.create && (
        <div className={styles.header_create}>
          <div>
            <span className={styles.header_name}>
              {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.NEW_CONTACT")}
            </span>
          </div>
          <div className={styles.dd_section}>
            <Select
              className={styles.partner_type_dropdown}
              placeholder={Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.CONTACT_TYPE")}
              options={contactTypeOptions}
              value={contactType}
              onChange={handleContactTypeOnChange}
            />
          </div>
        </div>
      )}
      {mode === constant.panelMode.edit && (
        <div className={`${styles.header_create} ${styles.header_edit}`}>
          <div>
            <span className={styles.header_name}>
              {contactType === constant.contactType.person &&
                Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.PERSON")}
              {contactType === constant.contactType.company &&
                Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.COMPANY")}
              {contactType === constant.contactType.employee &&
                Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.EMPLOYEE")}
              &nbsp;
              {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.EDIT_CONTACT")}
            </span>
          </div>
          <div />
        </div>
      )}

      {/* CONTACT INPUT FORM */}
      <div className={styles.details_container}>
        {contactType ? (
          <>
            {contactType === constant.contactType.person && (
              <ContactFormPerson
                onContactChange={handleOnContactChange}
                contact={{ ...contact, type: contactType }}
                setContact={setContact}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                ref={refPersonPanel}
                mode={mode}
              />
            )}
            {contactType === constant.contactType.company && (
              <ContactFormCompany
                handleOnContactChange={handleOnContactChange}
                contact={{ ...contact, type: contactType }}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                setContact={setContact}
                ref={refPersonPanel}
                mode={mode}
              />
            )}
            {contactType === constant.contactType.employee && (
              <ContactFormEmployee
                onContactChange={handleOnContactChange}
                contact={{ ...contact, type: contactType }}
                editedFields={editedFields}
                setEditedFields={setEditedFields}
                setContact={setContact}
                ref={refPersonPanel}
                mode={mode}
              />
            )}
          </>
        ) : (
          <>
            <Message icon>
              <Icon name="info circle" />
              {Localize.get("CONTACTS.NEWCONTACT.TOOLBAR.SELECT_TYPE")}
            </Message>

            <GlobalCancelBtn onCancel={onCancelButtonClick} floatedDirection={"right"} />
          </>
        )}
      </div>
      {/* FORM BUTTONS */}
      {contactType && (
        <GlobalSaveNewCancelBtn
          editMode={mode !== constant.panelMode.create}
          onUpdate={handleUpdateClick}
          onSave={handleOnSaveButtonClick}
          onSaveNew={handleOnSaveAndNewButtonClick}
          onCancel={onCancelButtonClick}
          disabledBtn={disableSaveBtn}
        />
      )}
    </>
  );
};

export default ContactFormPanel;
