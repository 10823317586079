import React, { useState, useRef } from "react";
import { Dropdown, Form, Button, Input, Message, Icon, Grid, Popup } from "semantic-ui-react";
import DayPicker, { DateUtils } from "react-day-picker";
import Localize from "react-intl-universal";
import "react-day-picker/lib/style.css";
import TransactionListItem from "./TransactionListItem";
// import * as constant from "./Constants.js";
import ListWithPagination from "../../Components/ListWithPagination";
import styles from "./style/TransactionList.module.css";
import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG, LABELS, FIRST_DAY_OF_WEEK } from "../Constants";

const TransactionList = props => {
  const {
    purpose, //for filter
    counterpart, //for filter
    amount, //for filter
    sortColumn,
    sortDirection,
    search
  } = props.state;

  const currentLanguage = navigator.language.toLowerCase().includes("de") ? "de" : "en";
  const refItemList = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const activeBankAccount = props.bankAccountOptions[1];
  const transactionList = props.transactions.map(t => (
    <TransactionListItem
      key={t.id}
      name={t.id}
      transaction={t}
      activeTransaction={props.state.activeTransaction}
      onListItemToggle={props.handler.onToggleItem}
      onListItemClick={props.handler.onListItemClick}
      state={props.state}
    />
  ));
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const dateRange = {
    from: new Date(props.state.minDate),
    to: new Date(props.state.maxDate)
  };
  const [dateFilterOpen, setDateFilterOpen] = useState(false);

  const handleDateRangeClick = day => {
    const newDateRange = DateUtils.addDayToRange(day, dateRange);

    if (newDateRange.from && newDateRange.to) {
      props.handler.onDateChange({
        from: newDateRange.from,
        to: newDateRange.to
      });
    }
  };

  const restartFiltersAndSearches = () => {
    props.handler.onResetClick();
  };

  const handler = {
    resetSearchInput: () => {
      props.handler.onSearchUpdate("");
    },
    filterAccounts: (e, data) => {
      props.handler.onBankAccountFilterChange(e, data);
    }
  };

  // eslint-disable-next-line no-unused-vars

  const modifiers = { start: dateRange.from, end: dateRange.to };

  // BALANCE
  let totalPrice = props.accountBalanceState;
  const colorBalanceLabel = totalPrice > 0 ? `${styles.positive}` : `${styles.negative}`;
  const signPrice = totalPrice > 0 ? "+" : "";

  return (
    <div
      className={`ten wide column ${styles.items_list} ${styles.bms_transaction_list} ${styles.bms_gutter_reset}`}
    >
      <div className={`header ${styles.sec_header}`}>
        <Form className={styles.dayPickers}>
          <Form.Group inline>
            <Dropdown
              icon="calendar alternate outline"
              floating
              labeled
              button
              onClick={() => setDateMenuOpen(true)}
              onBlur={() => setDateMenuOpen(false)}
              closeOnBlur
            >
              <Dropdown.Menu open={dateMenuOpen}>
                <DayPicker
                  id="myCal"
                  locale={currentLanguage}
                  months={MONTHS[currentLanguage]}
                  weekdaysLong={WEEKDAYS_LONG[currentLanguage]}
                  weekdaysShort={WEEKDAYS_SHORT[currentLanguage]}
                  firstDayOfWeek={FIRST_DAY_OF_WEEK[currentLanguage]}
                  labels={LABELS[currentLanguage]}
                  className={styles.selectableDayPicker}
                  modifiers={modifiers}
                  selectedDays={{
                    from: dateRange.from,
                    to: dateRange.to
                  }}
                  onDayClick={handleDateRangeClick}
                />
                <Button.Group attached="bottom">
                  <Button
                    className={styles.button_day_picker}
                    color="blue"
                    onClick={() => {
                      const weekAgo = new Date();
                      weekAgo.setDate(weekAgo.getDate() - 7);
                      props.handler.onDateChange({
                        from: weekAgo,
                        to: new Date()
                      });
                    }}
                  >
                    {Localize.get("TRANSACTIONS.ITEMLIST.LASTWEEK")}
                  </Button>
                  <Button
                    className={styles.button_day_picker}
                    color="teal"
                    onClick={() => {
                      const monthAgo = DateUtils.addMonths(new Date(), -1);
                      props.handler.onDateChange({
                        from: monthAgo,
                        to: new Date()
                      });
                    }}
                  >
                    {Localize.get("TRANSACTIONS.ITEMLIST.LASTMONTH")}
                  </Button>
                </Button.Group>
                <Button.Group attached="bottom">
                  <Button
                    className={styles.button_day_picker}
                    color="teal"
                    onClick={() => {
                      props.handler.onDateChange({
                        from: new Date(),
                        to: new Date()
                      });
                    }}
                  >
                    {Localize.get("TRANSACTIONS.ITEMLIST.TODAY")}
                  </Button>
                  <Button
                    className={styles.button_day_picker}
                    color="blue"
                    onClick={() => {
                      props.handler.onDateChange({
                        from: new Date("01.01.1970"),
                        to: new Date()
                      });
                    }}
                  >
                    {Localize.get("GLOBAL.ITEM.ALLTIME")}
                  </Button>
                </Button.Group>
              </Dropdown.Menu>
            </Dropdown>
            <div className="ui calendar" id="calendar">
              <div className="ui input right icon">
                <input
                  readOnly
                  type="text"
                  placeholder="Date"
                  value={dateRange.from.toLocaleDateString(currentLanguage, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })}
                />
                <i className="calendar alternate outline icon" />
              </div>
            </div>
            <div className="ui calendar" id="calendar">
              <div className="ui input right icon">
                <input
                  readOnly
                  type="text"
                  placeholder="Date"
                  value={dateRange.to.toLocaleDateString(currentLanguage, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                  })}
                />
                <i className="calendar alternate outline icon" />
              </div>
            </div>
          </Form.Group>
        </Form>
        <Button basic className={styles.button_reset} compact onClick={restartFiltersAndSearches}>
          {Localize.get("CONTACTS.LIST.TOOLBAR.RESET")}
        </Button>
        <Popup
          content={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_TRANSACTION_POPUP")}
          on={["hover", "click"]}
          trigger={
            <Input
              type="text"
              name="search"
              disabled={counterpart !== "" || purpose !== ""}
              className={styles.transaction_search}
              icon={
                search ? (
                  <Icon
                    name="close"
                    onClick={props.handler.resetNameSearch}
                    circular
                    link
                    size="small"
                  />
                ) : (
                  <Icon name="search" />
                )
              }
              placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
              onChange={props.handler.onSearchUpdate}
              value={search ? search : ""}
            />
          }
        />

        <Dropdown
          className={styles.transactions_allaccounts}
          onChange={(e, data) => handler.filterAccounts(e, data)}
          placeholder={Localize.get("TRANSACTIONS.ITEMLIST.ALLACCOUNTS")}
          value={props.state.activeBankAccount}
          selection
          options={props.bankAccountOptions}
        />
        <div className="sales">
          <strong>{Localize.get("TRANSACTIONS.ITEMLIST.BALANCE")}</strong>{" "}
          <span className={colorBalanceLabel}>
            {signPrice}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR"
            }).format(totalPrice)}
          </span>
        </div>
      </div>

      <div className={styles.table_header_projects}>
        <Grid columns="6" stackable container verticalAlign="middle" padded={"vertically"}>
          <Grid.Row>
            <Grid.Column largeScreen={3}>
              <div className={`${styles.dropdown_trigger} ${styles.first}`}>
                <h5>{Localize.get("TRANSACTIONS.HEADER_ASSIGNMENT")}</h5>
              </div>
            </Grid.Column>
            <Grid.Column largeScreen={2}>
              <Dropdown
                icon={null}
                trigger={
                  <div className={styles.dropdown_trigger}>
                    <h5>{Localize.get("TRANSACTIONS.DIRECTION.HEADER_DIRECTION")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "direction" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "direction" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => props.handler.onSortButtonClick("direction", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => props.handler.onSortButtonClick("direction", "desc")}
                  />
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column largeScreen={4}>
              <Dropdown
                icon={null}
                trigger={
                  <div className={styles.dropdown_trigger}>
                    <h5>{Localize.get("TRANSACTIONS.ITEMLIST.HEADER_COUNTERPART")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "counterpart" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "counterpart" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {counterpart !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => props.handler.onSortButtonClick("counterpart", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => props.handler.onSortButtonClick("counterpart", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    name="counterpart"
                    icon="search"
                    disabled={search !== "" && search !== null}
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={counterpart}
                    onClick={e => e.stopPropagation()}
                    onChange={props.handler.onSearchUpdate}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column largeScreen={3}>
              <Dropdown
                icon={null}
                trigger={
                  <div className={styles.dropdown_trigger}>
                    <h5>{Localize.get("TRANSACTIONS.ITEMLIST.HEADER_PURPOSE_OF_BOOKING")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "purpose" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "purpose" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {purpose !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => props.handler.onSortButtonClick("purpose", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => props.handler.onSortButtonClick("purpose", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    icon="search"
                    name="purpose"
                    disabled={search !== "" && search !== null}
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={purpose}
                    onClick={e => e.stopPropagation()}
                    onChange={props.handler.onSearchUpdate}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column largeScreen={2} className={styles.date_column_padding}>
              <Dropdown
                icon={null}
                onClick={() => setDateFilterOpen(true)}
                onBlur={() => setDateFilterOpen(false)}
                trigger={
                  <div className={`${styles.dropdown_trigger} ${styles.purpose_booking}`}>
                    <h5 className={styles.header_for_date}>
                      {Localize.get("TRANSACTIONS.ITEMLIST.HEADER_BOOKING_DATE")}
                    </h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "bookingDate" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "bookingDate" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {/* {bookingDate !== "" ? <Icon name="filter" /> : ""} */}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu open={dateFilterOpen}>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => props.handler.onSortButtonClick("bookingDate", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => props.handler.onSortButtonClick("bookingDate", "desc")}
                  />
                  <Dropdown.Divider />
                  <DayPicker
                    id="myCal"
                    locale={currentLanguage}
                    months={MONTHS[currentLanguage]}
                    weekdaysLong={WEEKDAYS_LONG[currentLanguage]}
                    weekdaysShort={WEEKDAYS_SHORT[currentLanguage]}
                    firstDayOfWeek={FIRST_DAY_OF_WEEK[currentLanguage]}
                    labels={LABELS[currentLanguage]}
                    className={styles.selectableDayPicker}
                    modifiers={modifiers}
                    selectedDays={{
                      from: dateRange.from,
                      to: dateRange.to
                    }}
                    onDayClick={handleDateRangeClick}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column largeScreen={2}>
              <Dropdown
                icon={null}
                trigger={
                  <div className={styles.dropdown_trigger}>
                    <h5>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "amount" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "amount" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {amount !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => props.handler.onSortButtonClick("amount", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => props.handler.onSortButtonClick("amount", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    type="text"
                    name="amount"
                    icon="search"
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={amount}
                    onClick={e => e.stopPropagation()}
                    onChange={props.handler.onSearchUpdate}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {transactionList && transactionList.length > 0 ? (
        <div ref={refItemList} className={styles.vertical_list_height}>
          <ListWithPagination
            list={transactionList}
            state={props.state}
            defaultActivePage={props.paginationStatus.pageCount}
            totalPage={props.paginationStatus.totalCount}
            handlePageChange={props.handlePageChange}
            activePaginationPage={props.activePaginationPage}
            perPage={props.paginationStatus.perPage}
          />
        </div>
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("TRANSACTIONS.ITEMLIST.EMPTY")}
        </Message>
      )}
    </div>
  );
};

export default TransactionList;
