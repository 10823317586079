import React from "react";
import GlobalList from "../../Components/Lists/GlobalList";
import { availableItemFieldsForListAccounts } from "../BookkeepingConfig";
import BookkeepingToolBar from "../BookkeepingToolBar";
import styles from "../style/AccountMiddlePanel.module.css";
import Localize from "react-intl-universal";

const AccountMiddlePanel = props => {
  const {
    currentView,
    listState,
    searchFilter,
    filterObject,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    paginationStatus,
    activePaginationPage,
    searchObject,
    handlePageChange
  } = props;

  const actionOptions = [
    {
      name: "printPreview",
      text: Localize.get("BOOKKEEPING.PRINT_VIEW"),
      icon: "file outline"
    },
    {
      name: "exportAccounts",
      text: Localize.get("BOOKKEEPING.EXPORT_ACCOUNTS"),
      icon: "file outline"
    }
  ];

  const myToolbarSearch = (e, data) => {
    e.preventDefault();
    searchFilter(e, data, searchObject);
  };

  const resetSearchIputField = (event, data) => {
    searchFilter(event, { ...data, name: "search", value: "" }, searchObject);
  };
  return (
    <>
      <BookkeepingToolBar
        searchName={myToolbarSearch}
        enteredValue={searchObject.search}
        resetNameSearch={resetSearchIputField}
        checkboxLabel={Localize.get("BOOKKEEPING.TOOLBAR_CHEKBOX")}
        actionOptions={actionOptions}
      />

      <GlobalList
        currentView={currentView}
        listState={listState}
        searchFilter={searchFilter}
        filterObject={filterObject}
        forbidenResponse={forbidenResponse}
        availableItemFieldsForList={availableItemFieldsForListAccounts}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        paginationStatus={paginationStatus}
        handlePageChange={handlePageChange}
        activePaginationPage={activePaginationPage}
        listPaginationCustomCSS={
          paginationStatus.pageCount > 1 ? styles.middle_panel_list : styles.middle_panel_list_0
        }
        showAtBottom={true}
      />
    </>
  );
};

export default AccountMiddlePanel;
