import React, { useState, useEffect, useContext } from "react";
import ThumbPanel from "./ThumbPanel";
import SideBar from "./SideBar";
import TransactionList from "./TransactionList";
import ThumbDetails from "./ThumbDetails";
import { AuthContext } from "../../Auth/AuthContext";
import Localize from "react-intl-universal";
import {
  convert2Transactions,
  formatDate,
  convert2Invoices,
  getLinkedObject,
  getLinkedWithoutDocObject,
  getEditedTransacionObject,
  getInitBankAccountOption
} from "./Util";
import * as RestService from "../../RestService";
import * as constant from "./Constants.js";
import * as componentsConstants from "../../Components/Constants";
import { Loader, Dimmer, Segment, Sidebar } from "semantic-ui-react";
import styles from "./style/Transactions.module.css";
import documentSample from "../../Assets/Images/invoice_thumbnail.png";

const Transactions = props => {
  const { location } = props;
  const { rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["transaction"];
  const [isLoading, setIsLoading] = useState(false);
  const [rightPanelState, setRightPanelState] = useState(constant.rightPanelState.init);

  const [firstBuild, setFirstBild] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [bankAccountOptions, setBankAccountOptions] = useState([getInitBankAccountOption()]);
  const [invoices, setInvoices] = useState([]);
  const [transactionListState, setTransactionListState] = useState({
    ...constant.transactionListInitState,
    activeListItem: undefined,
    activeListItemId: "",
    invoiceSearchPhrase: "",
    activeBankAccount:
      location.state !== undefined
        ? location && location.state && location.state.id
        : constant.transactionListInitState.activeBankAccount
  });
  const [triggerGetInvoices, setTriggerGetInvoices] = useState(true);
  let selectedTransaction = null;
  const [buttonsVisibleMode, setButtonsVisibleMode] = useState(
    constant.rightPanelButtonsState.init
  );
  const [selectedDocumentsPreview, setSelectedDocumentsPreview] = useState();
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(
    componentsConstants.paginationInitStatus
  );
  const [filterObjectForPagination, setFilterObjectForPagination] = useState(
    constant.filterObjectForPagination
  );
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  const [searchBy, setSearchBy] = useState(false);
  const [isLinkedInvoices, setIsLinkedInvoices] = useState(true);
  let doc = {};

  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const getAllSuccessHandler = response => {
    const transactions = convert2Transactions(response.data.data);
    setTransactions(transactions);

    setPaginationStatus({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });

    let selTr = null;

    if (selectedTransaction) {
      const sel = transactions.filter(tr => {
        return tr.id.toString() === selectedTransaction.id.toString();
      });
      selTr = sel[0];
    }

    if (!selectedTransaction) {
      selectedTransaction = transactions.length > 0 ? transactions[0] : undefined;
      selTr = selectedTransaction;
    }

    if (selTr) {
      setTransactionListState({
        ...transactionListState,
        activeListItem: selTr.id,
        activeListItemId: selTr.id,
        activeTransaction: selTr
      });
    } else {
      setTransactionListState({
        ...transactionListState,
        activeListItem: undefined,
        activeListItemId: undefined,
        activeTransaction: undefined
      });
    }

    selectedTransaction = null;

    if (transactions.length === 0) {
      setInvoices([]);
    }
  };

  const getAllErrorHandler = error => {};

  const getAllFinallyHandler = response => {};

  const getAllInvoicesSuccessHandler = response => {
    const invoices = convert2Invoices(response.data.data);

    const selectedInvoices = invoices.filter(invoice => {
      let linked;
      if (
        activeTransaction &&
        activeTransaction.documents &&
        activeTransaction.documents.length > 0
      ) {
        linked = activeTransaction.documents.find(doc => {
          return doc.id.toString() === invoice.id.toString();
        });
      }
      if (linked) {
        return false;
      } else {
        return true;
      }
    });

    if (transactions.length === 0) {
      setInvoices([]);
    } else {
      setInvoices(selectedInvoices);
    }
  };

  const getAllInvoicesErrorHandler = error => {};

  const getAllInvoicesFinallyHandler = response => {};

  const unlinkedSuccessHandler = async response => {
    const filterObject = {
      assignment: transactionListState.activeAssignmentFilter,
      direction: transactionListState.activeDirectionFilter,
      minDate: transactionListState.minDate,
      maxDate: transactionListState.maxDate
    };

    const service = resolveServiceCall(filterObject);
    await service.call();
  };

  const unlinkedErrorHandler = error => {};

  const unlinkedFinallyHandler = response => {};

  const linkedSuccessHandler = async response => {
    const filterObject = {
      assignment: transactionListState.activeAssignmentFilter,
      direction: transactionListState.activeDirectionFilter,
      minDate: transactionListState.minDate,
      maxDate: transactionListState.maxDate
    };

    const service = resolveServiceCall(filterObject);
    await service.call();
    getAndSetInvoiceService();
  };

  const linkedErrorHandler = error => {};

  const linkedFinallyHandler = response => {};

  const linkedWithOutSuccessHandler = async response => {
    const filterObject = {
      assignment: transactionListState.activeAssignmentFilter,
      direction: transactionListState.activeDirectionFilter,
      minDate: transactionListState.minDate,
      maxDate: transactionListState.maxDate
    };

    const service = resolveServiceCall(filterObject);
    await service.call();
    getAndSetInvoiceService();
  };

  const linkedWithOutErrorHandler = error => {};

  const linkedWithOutFinallyHandler = response => {};
  const [accountBalanceState, setAccountBalanceState] = useState(0);
  const callbackGetAllAccountsSuccess = response => {
    const accountList = response.data;
    let sumOfBalances = 0;

    let convertAccountList = accountList
      ? accountList.map(acount => {
          sumOfBalances = sumOfBalances + parseFloat(acount.balance);
          return {
            key: acount.finApiId,
            text: acount.accountOwner,
            value: acount.finApiId,
            balance: acount.balance,
            icon: acount.active
              ? { name: "check circle outline", color: "green" }
              : { name: "minus circle", color: "red" }
          };
        })
      : null;
    setAccountBalanceState(
      location.state !== undefined
        ? convertAccountList.find(account => account.value === location.state.id).balance
        : sumOfBalances
    );
    let initBankAccountOption = getInitBankAccountOption();
    setBankAccountOptions([
      {
        ...initBankAccountOption,
        balance: sumOfBalances ? sumOfBalances : 0
      },
      ...convertAccountList
    ]);
  };

  const callbackGetAllAccountsError = error => {};

  const callbackGetAllAccountsFinally = res => {};

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
    getAllAccounts();
  }, []);

  let delayTimer;
  let delayDocumentTimer;

  const fetchData = async () => {
    const filterObject = {
      assignment: transactionListState.activeAssignmentFilter,
      direction: transactionListState.activeDirectionFilter,
      minDate: transactionListState.minDate,
      maxDate: transactionListState.maxDate
    };

    let service = resolveServiceCall(filterObject);
    //resolve header fillters
    service = resolveAdditionalFilters(service);
    service.query.setPage(activePaginationPage);
    setIsLoading(true);
    await service.call();
    setIsLoading(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    delayTimer = setTimeout(
      function() {
        if (isSubscribed && !firstBuild) {
          fetchData();
        }
      },
      transactionListState.search === null &&
        transactionListState.counterpart === "" &&
        transactionListState.purpose === "" &&
        transactionListState.amount === ""
        ? 0
        : 600
    );
    setFirstBild(false);
    return () => (isSubscribed = false);
  }, [
    transactionListState.search,
    transactionListState.counterpart,
    transactionListState.purpose,
    transactionListState.amount
  ]);

  useEffect(() => {
    fetchData();
    clearTimeout(delayTimer);
  }, [
    transactionListState.activeDirectionFilter,
    transactionListState.activeAssignmentFilter,
    transactionListState.activeBankAccount,
    transactionListState.sortColumn,
    transactionListState.sortDirection,
    transactionListState.minDate,
    transactionListState.maxDate,
    activePaginationPage
  ]);

  useEffect(() => {
    if (transactionListState.activeTransaction && transactionListState.activeTransaction.realId) {
      getTransactionDetailsAndInvoices(transactionListState.activeTransaction.realId);
    } else {
      getAndSetInvoiceService();
    }
    if (transactionListState.activeTransaction) {
      resolveButtonsvisibility(transactionListState.activeTransaction.assignmentType);
    }
  }, [transactionListState.activeTransaction]);

  useEffect(() => {
    let isSubscribed = true;
    delayDocumentTimer = setTimeout(function() {
      if (isSubscribed && !firstBuild) {
        getAndSetInvoiceService();
      }
    }, 600);
    setFirstBild(false);
    return () => (isSubscribed = false);
  }, [transactionListState.invoiceSearchPhrase]);

  //Service call for doc preview
  const callbackGetBinaryContentSuccess = response => {
    var reader = new window.FileReader();

    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        setSelectedDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: reader.result,
          checked: doc.checked,
          docId: doc.id,
          invoiceId: doc.id
        });
        setRightPanelState(constant.rightPanelState.thumbDetails);
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });

      setSelectedDocumentsPreview({
        isPDF: response.headers["content-type"].includes("pdf"),
        data: urlCreator.createObjectURL(file),
        checked: doc.checked,
        docId: doc.id,
        invoiceId: doc.id
      });
      setRightPanelState(constant.rightPanelState.thumbDetails);
    }
  };

  const callbackGetBinaryContentError = response => {};

  const callbackGetBinaryContentFinally = response => {};

  const getDocumentBinaryContent = document => {
    if (document && document.binaryContent.id) {
      doc = document;

      const serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(
        document.binaryContent.id
      );

      serviceGetContent.query.addResponseType("blob");
      serviceGetContent.setCallbackSuccess(callbackGetBinaryContentSuccess);
      serviceGetContent.setCallbackError(callbackGetBinaryContentError);
      serviceGetContent.setCallbackFinally(callbackGetBinaryContentFinally);
      serviceGetContent.call();
    } else {
      setSelectedDocumentsPreview({
        isPDF: false,
        data: documentSample,
        checked: false,
        docId: undefined,
        invoiceId: doc.id
      });
    }
  };

  const getTransactionDetails = transactionRealId => {
    const serviceDetails = new RestService.Transaction.GetById.Service(transactionRealId);

    serviceDetails.setCallbackSuccess(transactionDetailsSuccessHandler);
    serviceDetails.setCallbackError(transactionDetailsErrorHandler);
    serviceDetails.setCallbackFinally(transactionDetailsFinallyHandler);

    serviceDetails.call();
  };

  const getTransactionDetailsAndInvoices = async transactionRealId => {
    await getAndSetInvoiceService();
    getTransactionDetails(transactionRealId);
  };

  const transactionDetailsSuccessHandler = async response => {
    let newArr = [...transactions];
    const index = transactions.indexOf(transactionListState.activeTransaction);

    newArr[index].documents = response.data.documents;
    setTransactions(newArr);
  };

  const transactionDetailsErrorHandler = error => {};

  const transactionDetailsFinallyHandler = response => {};

  const getAndSetInvoiceService = () => {
    const serviceGetAll = new RestService.Document.GetAll.Service();
    serviceGetAll.setCallbackSuccess(getAllInvoicesSuccessHandler);
    serviceGetAll.setCallbackError(getAllInvoicesErrorHandler);
    serviceGetAll.setCallbackFinally(getAllInvoicesFinallyHandler);
    serviceGetAll.query.addDocumentTypeInvoice();
    serviceGetAll.query.addPaymentStatusPaid();

    if (transactionListState.activeTransaction) {
      switch (transactionListState.activeTransaction.directionType) {
        case constant.direction.expense:
          serviceGetAll.query.addDocumentDirectionIncoming();
          break;
        case constant.direction.revenue:
          serviceGetAll.query.addDocumentDirectionOutgoing();
          break;
        default:
          break;
      }
    }

    serviceGetAll.query.addSearchParam(transactionListState.invoiceSearchPhrase);
    serviceGetAll.query.addSearchMatchParam("containing");

    serviceGetAll.query.clearPageSize();
    serviceGetAll.call();
  };

  const getUnlikedService = transactionRealId => {
    const serviceUnlink = new RestService.Transaction.Delete.Service(transactionRealId);
    serviceUnlink.setCallbackSuccess(unlinkedSuccessHandler);
    serviceUnlink.setCallbackError(unlinkedErrorHandler);
    serviceUnlink.setCallbackFinally(unlinkedFinallyHandler);

    serviceUnlink.call();
  };

  const getLinkedService = data => {
    const serviceLink = new RestService.Transaction.Create.Service();
    serviceLink.setPayload(data);
    serviceLink.setCallbackSuccess(linkedSuccessHandler);
    serviceLink.setCallbackError(linkedErrorHandler);
    serviceLink.setCallbackFinally(linkedFinallyHandler);

    serviceLink.call();
  };

  const getLinkedWithOutService = data => {
    const serviceLinkWithout = new RestService.Transaction.Create.Service();
    serviceLinkWithout.setPayload(data);
    serviceLinkWithout.setCallbackSuccess(linkedWithOutSuccessHandler);
    serviceLinkWithout.setCallbackError(linkedWithOutErrorHandler);
    serviceLinkWithout.setCallbackFinally(linkedWithOutFinallyHandler);

    serviceLinkWithout.call();
  };

  const getUpdateService = (activeTransaction, payloadLinked) => {
    const serviceUpdate = new RestService.Transaction.Update.Service(activeTransaction.realId);
    serviceUpdate.setPayload(payloadLinked);
    serviceUpdate.setCallbackSuccess(linkedWithOutSuccessHandler);
    serviceUpdate.setCallbackError(linkedWithOutErrorHandler);
    serviceUpdate.setCallbackFinally(linkedWithOutFinallyHandler);

    serviceUpdate.call();
  };

  const resolveButtonsvisibility = assignmentType => {
    switch (assignmentType) {
      case constant.assignment.assigned:
        setButtonsVisibleMode(constant.rightPanelButtonsState.assigned);
        break;
      case constant.assignment.assignedWithoutDoc:
        setButtonsVisibleMode(constant.rightPanelButtonsState.assignedWithoutDoc);
        break;
      case constant.assignment.notAssigned:
        setButtonsVisibleMode(constant.rightPanelButtonsState.notAssigned);
        break;
      default:
        setButtonsVisibleMode(constant.rightPanelButtonsState.init);
        break;
    }
  };

  const resolveAdditionalFilters = service => {
    const {
      counterpart,
      purpose,
      amount,
      search,
      activeBankAccount,
      sortColumn,
      sortDirection,
      invoiceSearchPhrase
    } = transactionListState;
    // filter
    const filterArray = {
      counterpart: counterpart,
      purpose: purpose,
      amount: amount,
      search: search,
      accountId: activeBankAccount,
      invoiceSearchPhrase: invoiceSearchPhrase
    };
    // eslint-disable-next-line array-callback-return
    Object.keys(filterArray).map(column => {
      if (column === "accountId" && filterArray[column] === "All Accounts")
        // eslint-disable-next-line array-callback-return
        return;

      if (filterArray[column]) service.query.addCustomColumnSearch(column, filterArray[column]);
    });

    // sort
    if (sortColumn) {
      service.query.addSortColumnParam(sortColumn);
      service.query.addSortDirectionParam(sortDirection);
    }

    return service;
  };

  const resolveServiceCall = filtersObject => {
    let service = new RestService.Transaction.GetAll.Service();
    switch (filtersObject.assignment) {
      case constant.assignment.assigned:
        service.query.addTransactionAssignmentLinked();
        break;
      case constant.assignment.notAssigned:
        service.query.addTransactionAssignmentUnlinked();
        break;
      case constant.assignment.assignedWithoutDoc:
        service.query.addTransactionAssignmentLinkedWithoutDocument();
        break;
      case constant.assignment.all:
        service.query.clearTransactionAssignment();
        break;
      default:
        break;
    }

    switch (filtersObject.direction) {
      case constant.direction.expense:
        service.query.addTransactionDirectionExpense();
        break;
      case constant.direction.revenue:
        service.query.addTransactionDirectionRevenue();
        break;
      case constant.direction.all:
        service.query.clearTransactionDirection();
        break;
      default:
        break;
    }

    service.query.addMinDate(filtersObject.minDate);
    service.query.addMaxDate(filtersObject.maxDate);

    service.setCallbackSuccess(getAllSuccessHandler);
    service.setCallbackError(getAllErrorHandler);
    service.setCallbackFinally(getAllFinallyHandler);

    return service;
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo.activePage);
  };

  const getAllAccounts = () => {
    const getAllAccountsService = new RestService.Accounts.GetAll.Service();
    getAllAccountsService.setCallbackSuccess(callbackGetAllAccountsSuccess);
    getAllAccountsService.setCallbackError(callbackGetAllAccountsError);
    getAllAccountsService.setCallbackFinally(callbackGetAllAccountsFinally);
    getAllAccountsService.query.clearPageSize();
    getAllAccountsService.call();
  };

  const handler = {
    onResetClick: () => {
      if (transactions.length > 0) {
        setTransactionListState({
          ...constant.transactionListInitState,
          activeListItemId: transactions[0].id,
          activeListItem: transactions[0].id,
          activeTransaction: transactions[0],
          invoiceSearchPhrase: "",
          search: ""
        });
      } else {
        setTransactionListState({
          ...constant.transactionListInitState,
          activeListItem: undefined,
          activeListItemId: "",
          invoiceSearchPhrase: ""
        });
      }
      if (
        transactionListState.invoiceSearchPhrase.length !== 0 &&
        transactionListState.activeAssignmentFilter === constant.assignment.all &&
        transactionListState.activeDirectionFilter === constant.direction.all &&
        transactionListState.activeBankAccount === constant.bankAccount.all
      ) {
        setTriggerGetInvoices(!triggerGetInvoices);
      }
      setRightPanelState(constant.rightPanelState.init);
      getAllAccounts();
      setActivePaginationPage(1);
    },
    onSearchChange: (value, field) => {
      if (isLoading) return;

      setTransactionListState({
        ...transactionListState,
        [field]: value
      });
    },
    resetNameSearch: () => {
      clearTimeout(delayTimer);
      setTransactionListState({ ...transactionListState, search: null });
    },

    onSortButtonClick: (field, direction) => {
      if (isLoading) return;

      setTransactionListState({
        ...transactionListState,
        sortColumn: field,
        sortDirection: direction
      });
    },

    filterInvoicesBySearchPhrase: (e, data) => {
      clearTimeout(delayDocumentTimer);
      setTransactionListState({
        ...transactionListState,
        invoiceSearchPhrase: data.value
      });
    },

    handleLinkWithoutInvoice: activeTransaction => {
      if (activeTransaction === undefined) return false;

      let payloadLinkedWithoutDoc = getLinkedWithoutDocObject(activeTransaction);
      selectedTransaction = activeTransaction;
      getLinkedWithOutService(payloadLinkedWithoutDoc);
    },

    handleLinkInvoices: (activeTransaction, selectedInvoices) => {
      if (selectedInvoices.length === 0) return false;
      if (activeTransaction === undefined) return false;

      let payloadLinked;
      selectedTransaction = activeTransaction;
      if (activeTransaction.documents.length === 0) {
        payloadLinked = getLinkedObject(selectedInvoices, activeTransaction);
        getLinkedService(payloadLinked);
      } else {
        payloadLinked = getEditedTransacionObject(selectedInvoices);
        getUpdateService(activeTransaction, payloadLinked);
      }
      return true;
    },
    handleUnlinkAllInvoices: transaction => {
      if (
        activeTransaction !== undefined &&
        (activeTransaction.assignmentType === constant.assignment.assigned ||
          activeTransaction.assignmentType === constant.assignment.assignedWithoutDoc)
      ) {
        selectedTransaction = activeTransaction;
        getUnlikedService(transaction.realId);
        setTransactionListState({
          ...transactionListState,
          invoiceSearchPhrase: ""
        });
      }
    },
    handleInvoiceUnlinkClick: unlinkedInvoice => {},
    handleLinkedInvoiceLabelClicked: document => {
      setIsLinkedInvoices(true);
      getDocumentBinaryContent(document);
      setTransactionListState({
        ...transactionListState,
        invoiceSearchPhrase: ""
      });
    },
    handleInvoiceDetailsBack: () => {
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        invoiceId: undefined
      });
    },
    handleInvoiceDetailsToggle: invoiceId => {
      const newInvoices = invoices.map(invoice => {
        if (invoice.id === invoiceId) invoice.checked = !invoice.checked;
        return invoice;
      });
      setInvoices(newInvoices);
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        invoiceId: undefined
      });
    },
    onListItemClick: itemId => {
      const selTransaction = transactions.filter(transaction => {
        return transaction.id.toString() === itemId.toString();
      });
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        activeListItem: itemId,
        activeListItemId: itemId,
        activeTransaction: selTransaction[0],
        invoiceSearchPhrase: ""
      });

      resolveButtonsvisibility(selTransaction[0].assignmentType);
    },
    setActiveItem: id => {
      setTransactionListState({
        ...transactionListState,
        activeListItem: "ItemList_" + id,
        activeListItemId: id,
        invoiceSearchPhrase: ""
      });
      setInvoices(
        invoices.map(invoice => {
          invoice.checked = false;
          return invoice;
        })
      );
      //TODO -transaction Panel state
    },
    onToggleAll: () => {
      const filteredTransaction = transactions;
      setTransactions(
        transactions.map(transaction => {
          if (
            filteredTransaction.find(
              filteredTransaction => filteredTransaction.id === transaction.id
            )
          ) {
            transaction.checked = !transactionListState.selectAll;
          }
          return transaction;
        })
      );
      setTransactionListState({
        ...transactionListState,
        selectAll: !transactionListState.selectAll,
        invoiceSearchPhrase: ""
      });
    },
    onBankAccountFilterChange: (e, data) => {
      setSearchBy(false);
      if (isLoading) return;
      setAccountBalanceState(data.options.find(account => account.value === data.value).balance);
      setActivePaginationPage(1);
      setTransactionListState({
        ...transactionListState,
        activeBankAccount: data.value,
        invoiceSearchPhrase: ""
      });
    },
    onAssignmentFilterClick: async (e, data) => {
      setSearchBy(false);
      const filterObject = {
        assignment:
          transactionListState.activeAssignmentFilter === data.name
            ? constant.assignment.all
            : data.name,
        direction: transactionListState.activeDirectionFilter,
        minDate: transactionListState.minDate,
        maxDate: transactionListState.maxDate
      };
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        selectAll: false,
        activeAssignmentFilter: filterObject.assignment,
        invoiceSearchPhrase: ""
      });

      setFilterObjectForPagination(filterObject);
      setActivePaginationPage(1);

      resolveButtonsvisibility(filterObject.assignment);
    },
    onDirectionFilterClick: (e, data) => {
      setSearchBy(false);
      const filterObject = {
        assignment: transactionListState.activeAssignmentFilter,
        direction:
          transactionListState.activeDirectionFilter === data.name
            ? constant.direction.all
            : data.name,
        minDate: transactionListState.minDate,
        maxDate: transactionListState.maxDate
      };
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        selectAll: false,
        activeDirectionFilter: filterObject.direction,
        invoiceSearchPhrase: ""
      });
      setFilterObjectForPagination(filterObject);
      setActivePaginationPage(1);
    },
    onDateChange: async day => {
      setRightPanelState(constant.rightPanelState.init);
      setTransactionListState({
        ...transactionListState,
        selectAll: false,
        minDate: formatDate(day.from),
        maxDate: formatDate(day.to),
        invoiceSearchPhrase: ""
      });
      setFilterObjectForPagination({
        ...filterObjectForPagination,
        minDate: formatDate(day.from),
        maxDate: formatDate(day.to)
      });
      setActivePaginationPage(1);
      setInvoices(
        invoices.map(invoice => {
          invoice.checked = false;
          return invoice;
        })
      );
    },
    onSearchUpdate: (e, data) => {
      let searchValue = data.value;
      clearTimeout(delayTimer);
      if (isLoading) return;
      // eslint-disable-next-line no-unused-vars

      setTransactionListState({
        ...transactionListState,
        [e.target.name]: searchValue
      });
    },
    onPreviewIconClick: document => {
      setIsLinkedInvoices(false);
      getDocumentBinaryContent(document);
    },
    onInvoiceCheck: invoiceId => {
      const checkedInvoice = invoices.find(function(invoice) {
        return invoice.id === invoiceId;
      });

      const alreadyChecked = invoices.filter(inv => inv.checked === true && inv.id !== invoiceId);

      if (!checkedInvoice.checked) {
        alreadyChecked.push(checkedInvoice);
      }

      const newInvoices = invoices.map(invoice => {
        if (invoice.id === invoiceId) {
          invoice.checked = !invoice.checked;
        }

        return invoice;
      });
      setInvoices(newInvoices);
    }
  };

  const filteredTransactions = transactions;
  const filteredInvoices = invoices;

  const activeTransaction =
    transactionListState.activeListItemId === undefined
      ? filteredTransactions.length > 0
        ? filteredTransactions[0]
        : undefined
      : filteredTransactions.find(
          transaction =>
            transaction.id.toString() === transactionListState.activeListItemId.toString()
        );

  let rightPanel;
  switch (rightPanelState) {
    case constant.rightPanelState.init:
      rightPanel = (
        <ThumbPanel
          handler={handler}
          privileges={privileges}
          activeTransaction={activeTransaction}
          state={transactionListState}
          invoices={filteredInvoices}
          buttonsVisibleMode={buttonsVisibleMode}
          selectedDocumentsPreview={selectedDocumentsPreview}
          setRightPanelState={setRightPanelState}
          searchBy={searchBy}
        />
      );
      break;
    case constant.rightPanelState.thumbDetails:
      rightPanel = (
        <ThumbDetails
          handler={handler}
          state={transactionListState}
          invoices={filteredInvoices}
          selectedDocumentsPreview={selectedDocumentsPreview}
          isLinkedInvoices={isLinkedInvoices}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="ui container fluid">
      <Dimmer.Dimmable blurring dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader size="large">{Localize.get("TRANSACTIONS.ITEMLIST.TRANSACTIONS_LOADER")}</Loader>
        </Dimmer>

        <div
          className={`ui right attached button ${styles.bms_fixed} ${
            sideBarVisibility ? styles.bms_zindex : null
          }`}
          onClick={settingVisibleSidebar}
        >
          <i className="ellipsis vertical icon"></i>
        </div>

        <Sidebar.Pushable as={Segment} className={styles.bms_segment}>
          <SideBar
            state={transactionListState}
            handler={handler}
            sideBarVisibility={sideBarVisibility}
            setSideBarVisibility={setSideBarVisibility}
          />

          <Sidebar.Pusher dimmed={sideBarVisibility}>
            <div className={styles.bms_transaction_content}>
              <div className="ui two column stackable grid padded">
                <TransactionList
                  transactions={transactions}
                  activeTransaction={activeTransaction}
                  state={transactionListState}
                  accountBalanceState={accountBalanceState}
                  bankAccountOptions={bankAccountOptions}
                  handler={handler}
                  setRightPanelState={setRightPanelState}
                  handlePageChange={handlePageChange}
                  paginationStatus={paginationStatus}
                  activePaginationPage={activePaginationPage}
                />
                {rightPanel}
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Dimmer.Dimmable>
      <div className={styles.bms_transactions_div} />
    </div>
  );
};

export default Transactions;
