import * as RestService from "../../RestService";

//comparing two list of objects where for second list will be returned only those that are not in first list
export const itemsGlobalListHandler = (
  clickedItem,
  data,
  setErrorFields = function() {},
  editedFields,
  setEditedFields,
  currentItem,
  setCurrentItem,
  list,
  setList,
  listField
) => {
  if (data.name === "add") {
    let tempList = list;
    setList(tempList.filter(item => item.id !== clickedItem.id));
    let tempArr = currentItem[listField].slice();
    tempArr.unshift(clickedItem);
    setCurrentItem({ ...currentItem, [listField]: tempArr });
  } else {
    let tempPerList = list;
    tempPerList.unshift(clickedItem);
    setList(tempPerList);
    let tempArr = currentItem[listField].filter(role => role.id !== clickedItem.id);
    setCurrentItem({ ...currentItem, [listField]: tempArr });
  }

  setErrorFields({});
  if (!editedFields.includes(listField)) {
    let tempArray = editedFields;
    tempArray.push(listField);
    setEditedFields(tempArray);
  }
};

//get all service
export const createGetAllService = (
  newCallbackSuccess,
  newCallBackError,
  newCallbackFinally,
  modulePath,
  additionalArgs,
  formatSuccess
) => {
  const service = new RestService.GlobalRest.GetAll.Service(`/${modulePath}`);
  service.setCallbackSuccess(response =>
    newCallbackSuccess(response, additionalArgs, formatSuccess)
  );
  service.setCallbackError(newCallBackError);
  service.setCallbackFinally(newCallbackFinally);
  return service;
};

//create new service
export const createPostService = (
  currentItem,
  callbackCreateSuccess,
  callbackCreateError,
  callbackCreateFinaly,
  modulePath,
  save,
  disablePayload = false
) => {
  const service = new RestService.GlobalRest.Create.Service(`/${modulePath}`);
  if (!disablePayload) {
    service.setPayload(currentItem);
  }
  service.setCallbackSuccess(res => callbackCreateSuccess(res, save));
  service.setCallbackError(callbackCreateError);
  service.setCallbackFinally(callbackCreateFinaly);
  return service;
};

//edit service
export const createPostEditedService = (
  updatedObject,
  callbackEditSuccess,
  callbackEditError,
  callbackEditFinaly,
  modulePath
) => {
  const service = new RestService.GlobalRest.Update.Service(`/${modulePath}`);
  service.setPayload(updatedObject);
  service.setCallbackSuccess(callbackEditSuccess);
  service.setCallbackError(callbackEditError);
  service.setCallbackFinally(callbackEditFinaly);
  return service;
};

// creating queries for all keys for passed object with its values, for example: key=value
export const resolveFilters = (service, filterObject) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(filterObject)
    .filter(column => filterObject[column] !== "")
    // eslint-disable-next-line array-callback-return
    .map(column => {
      service.query.addCustomColumnSearch(column, filterObject[column]);
    });
  return service;
};

export const getDocumentContent = (id, callbackSuccess, callbackError, callbackFinaly) => {
  let serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(id);

  serviceGetContent.query.addResponseType("blob");
  serviceGetContent.setCallbackSuccess(callbackSuccess);
  serviceGetContent.setCallbackError(callbackError);
  serviceGetContent.setCallbackFinally(callbackFinaly);

  return serviceGetContent;
};

//datePicker locales

export const WEEKDAYS_SHORT = {
  de: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
};

export const WEEKDAYS_LONG = {
  de: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};

export const MONTHS = {
  de: [
    " Jan.",
    "Feb.",
    "Mrz.",
    "Apr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Okt.",
    "Nov.",
    "Dez."
  ]
};

export const LABELS = {
  de: { nextMonth: "nächsten Monat", previousMonth: "vorheriger Monat" }
};

export const FIRST_DAY_OF_WEEK = {
  de: 1
};

// ---- datePicker Locales END
