import { useState } from "react";
import useFetch from "./useFetch";
import usePostOrPatch from "./usePostOrPatch";
import showNotification from "../Notifications/NotificationFunction";
import Localize from "react-intl-universal";
import {
  fetchPartners,
  fetchPartner,
  postPartner,
  partnerSynchronizeFFO
} from "../RestServicesNew/PartnersServices";
import * as constants from "../Components/Constants";

const usePartnerServices = (
  contactListState = {},
  setPanelState = function() {},
  selectedId,
  setSelectedId,
  customFetchFormating = undefined
) => {
  const [selectedContact, setSelectedContact] = useState(undefined);
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const resolveFilters = filterObject => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filterObject).map(column => {
      if (!filterObject[column] || filterObject[column] === "all" || filterObject[column] === "")
        delete filterObject[column];
    });
    return filterObject;
  };

  const callbackFetchPartnerSuccess = response => {
    setSelectedContact({ ...response.data[0], id: selectedId });
  };

  const callbackFetchPartnerError = () => {};

  const [selectedPartner, , partnerFetchLoading, fetchPartnerCall] = useFetch(
    fetchPartner,
    [],
    `/partners/${selectedId}`,
    {},
    callbackFetchPartnerSuccess,
    callbackFetchPartnerError
  );

  const callbackFetchSuccess = (response, setData) => {
    let myListOfPartner = [];
    if (customFetchFormating) {
      myListOfPartner = customFetchFormating(response.data);
    } else {
      myListOfPartner = response.data;
    }

    let isSelected = myListOfPartner.find(
      item => selectedContact && selectedContact.id === item.id
    );
    let selectPartner = isSelected
      ? isSelected
      : myListOfPartner.length > 0
      ? myListOfPartner[0]
      : undefined;
    setData(myListOfPartner);
    setSelectedId(selectPartner.id);
    fetchPartnerCall();
  };

  const callbackFetchError = err => {
    setSelectedContact(null);
  };

  const [
    listOfPartners,
    paging,
    partnersFetchLoading,
    fetchPartnersCall,
    setListOfPartners
  ] = useFetch(
    fetchPartners,
    [],
    `/partners${contactListState.activeNotification ? "/newPartners" : ""}`,
    resolveFilters({
      page: activePaginationPage - 1,
      size: 20,
      type: contactListState.activeTypeFilter,
      partnerRole: contactListState.activeRoleFilter,
      archived: contactListState.activeArchiveFilter === "archived" ? true : "",
      name: contactListState.name,
      city: contactListState.city,
      number: contactListState.number,
      search: contactListState.search,
      sortBy: contactListState.sortColumn,
      sortDirection: contactListState.sortDirection,
      noParent: contactListState.noParent,
      noChildren: contactListState.noChildren
    }),
    callbackFetchSuccess,
    callbackFetchError
  );

  const callbackFetchAllSuccess = (response, setData) => {
    setData(response.data);
    if (response.status === 200 || response.status === 204) {
      showNotificationMessage({
        type: "info",
        operation: "update"
      });
    } else {
      showNotificationMessage({
        type: "warning",
        serverMessage: response.response.data.message
      });
    }
  };

  const callbackFetchAllError = err => {
    console.error(err);
  };

  // fetchAllPartners
  const [listOfAllPartners, , , fetchAllPartnersCall] = useFetch(
    fetchPartners,
    [],
    `/partners`,
    [],
    callbackFetchAllSuccess,
    callbackFetchAllError
  );

  const callbackPostSuccess = (response, saveAndNew = false, postOrPatchMethod) => {
    postOrPatchMethod !== "PATCH" && setSelectedContact();

    !saveAndNew &&
      setPanelState(previousState => {
        return { ...previousState, activeMode: "details" };
      });

    fetchPartnersCall();
    if (response.status === 200 || response.status === 204) {
      showNotificationMessage({
        type: "info",
        operation: "update"
      });
    } else {
      showNotificationMessage({
        type: "warning",
        serverMessage: response.response.data.message
      });
    }
  };
  const callbackPostError = (err, postOrPatchMethod) => {
    showNotificationMessage({
      type: "warning",
      operation:
        postOrPatchMethod === "PATCH"
          ? Localize.get("CONTACTS.NEWCONTACT.FORM.EDIT_FAILED")
          : Localize.get("CONTACTS.NEWCONTACT.FORM.CREATE_FAILED")
    });
  };

  const [postPartnerCall, postLoading] = usePostOrPatch(
    postPartner,
    callbackPostSuccess,
    callbackPostError
  );

  const callbackSyncFFOSuccess = async response => {
    await fetchPartnersCall();
    showNotification({
      type: constants.notificationType.info,
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.SYNC")
    });
  };

  const callbackSyncFFOError = err => {
    showNotificationMessage({
      type: "warning",
      serverMessage: err.message
    });
  };

  const [syncPartnerFFO, syncPartnerFFOLoading] = usePostOrPatch(
    partnerSynchronizeFFO,
    callbackSyncFFOSuccess,
    callbackSyncFFOError
  );

  const callbackVerifyAllPartners = response => {
    if (response.status === 200) {
      showNotification({
        type: constants.notificationType.info,
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.VERIFIED")
      });
    } else {
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.NOT_VERIFIED")
      });
    }
  };

  const callbackVerifyAllPartnersError = err => {
    showNotificationMessage({
      type: "warning",
      serverMessage: err.message
    });
  };

  const [verifyAllPartners, verifyAllPartnersLoading] = usePostOrPatch(
    postPartner,
    callbackVerifyAllPartners,
    callbackVerifyAllPartnersError
  );

  return {
    listOfPartners,
    paging,
    partnersFetchLoading,
    fetchPartnersCall,
    selectedContact,
    setSelectedContact,
    activePaginationPage,
    setActivePaginationPage,
    setListOfPartners,
    postPartnerCall,
    postLoading,
    selectedPartner,
    partnerFetchLoading,
    fetchPartnerCall,
    syncPartnerFFO,
    syncPartnerFFOLoading,
    fetchAllPartnersCall,
    listOfAllPartners,
    verifyAllPartners,
    verifyAllPartnersLoading
  };
};

export default usePartnerServices;
