import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Grid, Icon, Loader, Dimmer, Sidebar, Segment } from "semantic-ui-react";
import ProjectManagementSideBar from "./LeftPanel/ProjectManagementSideBar";
import ProjectManagementPanel from "./RightPanel/ProjectManagementPanel";
import ProjectManagementList from "./MiddlePanel/ProjectManamegentList";
import ProjectManagementItem from "./MiddlePanel/ProjectManagementItem";
import ProjectManagementDetails from "./RightPanel/ProjectManagementDetails";
import ConfirmDeleteDialog from "../../Dialogs/ConfirmDeleteDialog";
import ConfirmArchiveDialog from "../../Dialogs/ConfirmArchiveDialog";
import { AuthContext } from "../../Auth/AuthContext";
import Localize from "react-intl-universal";
import { GetUpdateObject, createNewProject } from "./Util.js";
import * as constant from "./Constants";
import * as componentsConstants from "../../Components/Constants";
import * as RestService from "../../RestService";
import DocumentShowScan from "../../Documents/RightPanel/DocumentShowScan";
import showNotification from "../../Notifications/NotificationFunction";
import documentSample from "../../Assets/Images/document_sample.png";
import styles from "./style/ProjectManagement.module.css";

const ProjectManagement = ({ setHeaderDimmed }) => {
  // eslint-disable-next-line no-unused-vars
  const { authState, rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["project"];
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [projects, setProjects] = useState([]);
  const [panelState, setPanelState] = useState(constant.panelInitState);
  const [listState, setListState] = useState(constant.listInitState);
  const [linkedDocumentsPreview, setLinkedDocumentsPreview] = useState();
  // eslint-disable-next-line no-unused-vars
  const [deleteItemid, setDeleteItemId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [formErrorMessage] = useState("");
  const [modalWarningStatus, setModalWarningStatus] = useState(false);
  const [lastProjectId, setLastProjectId] = useState();
  const [projectState] = useState({});
  const [clearProjectForm, setClearProjectForm] = useState(true);
  const [filterStatus, setFilterStatus] = useState(constant.initialFilterStatus);
  const [archInfo, setArchInfo] = useState({ id: null, projectArchived: null });
  const [deleteLinkedProjectsModal, setDeleteLinkedProjectsModal] = useState({
    show: false,
    listOfLinkedProjects: [],
    disableArchiveBtn: true
  });
  const [showArchModal, setShowArchModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let selectedIndex = null;
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const [activePaginationPage, setActivePaginationPage] = useState(1);

  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const projectsListInitState = {
    name: "",
    projectTemplate: "",
    selectAll: false,
    projectType: "",
    sortColumn: null,
    sortDirection: null,
    activeListItem: projects.length > 0 ? projects[0].id : "",
    search: "",
    number: "",
    listOfProjectsNotEmpty: true
  };
  const [projectsListState, setProjectsListState] = useState(projectsListInitState);

  const [paginationStatus, setPaginationStatus] = useState(
    componentsConstants.paginationInitStatus
  );

  const showNotificationMessage = param => {
    // NOTE: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PROJECT"),
      ...param
    });
  };

  const callbackGetAllSuccess = response => {
    const projects = response.data.data;

    setProjects(
      projects.map(project => {
        project.checked = false;
        return project;
      })
    );

    setPaginationStatus({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });

    if (selectedIndex !== null) {
      selectedProject = projects[selectedIndex];
      setProjectsListState({
        ...projectsListState,
        deleteItem: selectedProject.id
      });
    }

    if (!selectedProject && projects.length > 0) {
      selectedProject = projects[0];
    }

    setProjectsListState({
      ...projectsListState,
      selectAll: false,
      activeListItem: selectedProject.id
    });

    selectedProject = null;

    const lastItem = projects.slice(-1);

    if (lastItem.length > 0) setLastProjectId(lastItem[0].id);
  };
  const callbackGetAllError = error => {};
  const callbackGetAllFinally = response => {};

  const createGetAllService = filterObject => {
    const getAllProjectService = new RestService.Project.GetAll.Service();
    getAllProjectService.setCallbackSuccess(callbackGetAllSuccess);
    getAllProjectService.setCallbackError(callbackGetAllError);
    getAllProjectService.setCallbackFinally(callbackGetAllFinally);
    getAllProjectService.query.setPageSize(20);
    if (filterObject) {
      resolveFilters(getAllProjectService, filterObject);
    }
    return getAllProjectService;
  };

  const resolveFilters = (service, filterObject) => {
    switch (filterObject.activeStatusFilter) {
      case constant.projectStatus.new:
        service.query.addProjectStatusNew();
        break;
      case constant.projectStatus.closed:
        service.query.addProjectStatusClosed();
        break;
      case constant.projectStatus.process:
        service.query.addProjectStatusProcess();
        break;

      default:
        break;
    }

    if (filterObject.activeArchiveFilter === "archived") {
      service.query.addProjectArchiveStatusArchived();
    }
  };

  //callServiceWithFilters - refactorization
  const callServiceWithFilters = () => {
    const filterObject = {
      activeStatusFilter: filterStatus.activeStatusFilter,
      activeArchiveFilter: filterStatus.activeArchiveFilter
    };
    let service = resolveAdditionalFilters(createGetAllService(filterObject));
    service.query.setPage(activePaginationPage - 1);

    const fetchData = async service => {
      setIsLoading(true);
      await service.call();
      setIsLoading(false);
    };

    fetchData(service);
  };

  useEffect(() => {
    // NOTE: disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  //callServiceWithFilters - refactorization of code in order to support filters and restart

  useEffect(() => {
    selectedProject = null;
    selectedIndex = null;
    let isSubscribed = true;
    delayTimer = setTimeout(
      function() {
        if (isSubscribed && !firstBuild) {
          callServiceWithFilters();
        }
      },
      projectsListState.search === null &&
        projectsListState.name === "" &&
        projectsListState.number === ""
        ? 0
        : 600
    );
    setFirstBild(false);
    return () => (isSubscribed = false);
  }, [projectsListState.name, projectsListState.number, projectsListState.search]);

  useEffect(() => {
    clearTimeout(delayTimer);
    selectedProject = null;
    selectedIndex = null;
    callServiceWithFilters();
  }, [
    projectsListState.sortColumn,
    projectsListState.sortDirection,
    filterStatus.activeArchiveFilter,
    filterStatus.activeStatusFilter,
    activePaginationPage
  ]);

  setHeaderDimmed(
    panelState.activeMode === "create" || panelState.activeMode === "edit" || isLoading
  );

  const callbackCreateSuccess = async response => {
    selectedProject = null;
    setProjectsListState({ ...projectsListState, activeListItem: "" });

    if (activePaginationPage === 1) {
      callServiceWithFilters();
    } else {
      setActivePaginationPage(1);
    }
    setListState({ ...listState, activeMode: "list" });
    showNotificationMessage({
      type: "info",
      operation: "create"
    });
  };

  const callbackCreateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "createFailed"
    });
  };
  const callbackCreateFinally = (response, saveAndNew) => {
    if (!saveAndNew) {
      setEditMode(false);
      setPanelState({ activeMode: constant.panelMode.details });
    } else {
      setClearProjectForm(!clearProjectForm);
    }
    createGetAllService.call();
  };

  const createNewProjectServiceCall = (project, saveAndNew) => {
    const projectService = new RestService.Project.Create.Service();

    projectService.setPayload(createNewProject(project));
    projectService.setCallbackSuccess(callbackCreateSuccess);
    projectService.setCallbackError(callbackCreateError);
    projectService.setCallbackFinally(res => callbackCreateFinally(res, saveAndNew));
    projectService.call();
  };

  const callbackUpdateSuccess = async response => {
    callServiceWithFilters();
    setListState({ ...listState, activeMode: "list" });
    setPanelState({ activeMode: constant.panelMode.details });
    showNotificationMessage({
      type: "info",
      operation: "update"
    });
  };
  const callbackArchUpdateSuccess = async response => {
    const filterObject = {
      activeStatusFilter: filterStatus.activeStatusFilter,
      activeArchiveFilter: filterStatus.activeArchiveFilter
    };
    let service = resolveAdditionalFilters(createGetAllService(filterObject));

    const fetchData = async service => {
      setIsLoading(true);
      await service.call();
      setIsLoading(false);
    };
    let checkedIdArr = projects.filter(project => project.checked).map(pro => pro.id);
    let firstArchIndex;

    if (checkedIdArr.length !== 0) {
      firstArchIndex = projects.findIndex(element => element.id === checkedIdArr[0]);
    } else {
      firstArchIndex = 0;
    }

    if (firstArchIndex !== 0) {
      selectedIndex = firstArchIndex - 1;
      service.query.setPage(activePaginationPage - 1);
    } else {
      if (activePaginationPage > 1) {
        selectedIndex = 19;
        setActivePaginationPage(activePaginationPage - 1);
        service.query.setPage(activePaginationPage - 2);
      } else {
        selectedIndex = firstArchIndex;
        service.query.setPage(activePaginationPage - 1);
      }
    }
    setPanelState({ activeMode: constant.panelMode.details });
    setListState({ ...listState, activeMode: "list" });
    fetchData(service);
    if (checkedIdArr.length > 1) {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PROJECTS"),
        type: "info",
        operation: "update"
      });
    } else {
      showNotificationMessage({
        type: "info",
        operation: "update"
      });
    }
  };
  const callbackUpdateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "update"
    });
  };
  const callbackUpdateFinally = response => {
    createGetAllService.call();
    setEditMode(false);
  };

  const updateProjectServiceCall = project => {
    const projectId = projectsListState.activeListItem;
    const updatedProject = GetUpdateObject(project);

    const projectService = new RestService.Project.Update.Service(projectId);
    projectService.setPayload(updatedProject);
    projectService.setCallbackSuccess(callbackUpdateSuccess);
    projectService.setCallbackError(callbackUpdateError);
    projectService.setCallbackFinally(callbackUpdateFinally);
    projectService.call();
  };

  const resolveAdditionalFilters = service => {
    const { name, sortColumn, sortDirection, number, search } = projectsListState;
    // filter
    const filterArray = {
      name: name,
      number: number,
      search: search
    };
    // eslint-disable-next-line array-callback-return
    Object.keys(filterArray).map(column => {
      if (filterArray[column]) service.query.addCustomColumnSearch(column, filterArray[column]);
    });

    // sort
    if (sortColumn) {
      service.query.addSortColumnParam(sortColumn);
      service.query.addSortDirectionParam(sortDirection);
    }

    return service;
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo.activePage);
    selectedProject = null;
    setProjectsListState({ ...projectsListState, activeListItem: "" });
  };

  const handleSearches = {
    resetFiltersSearchsAndSorts: () => {
      setProjectsListState(projectsListInitState);
      setFilterStatus(constant.initialFilterStatus);
      if (activePaginationPage !== 1) {
        setActivePaginationPage(1);
        setProjectsListState({
          ...projectState,
          activeListItem: "",
          name: "",
          number: "",
          search: ""
        });
      }
    }
  };

  const handlerHeader = {
    onSearchChange: (e, data, reset = false) => {
      if (isLoading) return;
      clearTimeout(delayTimer);
      reset
        ? setProjectsListState({ ...projectsListState, search: null })
        : setProjectsListState({
            ...projectsListState,
            [e.target.name]: data.value
          });
    },
    onSortButtonClick: (field, direction) => {
      if (isLoading) return;

      setProjectsListState({
        ...projectsListState,
        sortColumn: field,
        sortDirection: direction
      });
    },
    onReset: () => {
      setProjects(
        projects.map(project => {
          project.checked = false;
          return project;
        })
      );
    },
    onToggleAll: () => {
      setProjects(
        projects.map(project => {
          if (!projectsListState.selectAll) {
            project.checked = true;
          } else {
            project.checked = false;
          }
          return project;
        })
      );

      setProjectsListState({
        ...projectsListState,
        selectAll: !projectsListState.selectAll
      });
    },
    onToggleItem: data => {
      setProjects(
        projects.map(project => {
          if (project.id === data.item_id) {
            project.checked = !project.checked;
          }
          return project;
        })
      );
    },
    onDeleteClick: e => {
      if (projects.filter(project => !project.checked).length !== projects.length) {
        setDeleteButtonClicked(constant.deleteForm.toolbar);
        setShowModal(true);
      }
    }
  };

  const handlerItem = {
    onListItemClick: (event, data) => {
      event.preventDefault();
      event.stopPropagation();
      setProjectsListState({ ...projectsListState, activeListItem: data.id });
      setPanelState({ activeMode: constant.panelMode.details });
    },
    onEditItemClick: () => {
      setProjectsListState({
        ...projectsListState,
        activeListItem: selectedProject.id
      });

      setPanelState({ activeMode: constant.panelMode.edit });
      setListState({ ...listState, activeMode: "list" });
      setEditMode(true);
    },
    onDeleteItemClick: () => {
      if (projectsListState.activeListItem) {
        setProjects(
          projects.map(project => {
            project.checked = false;
            return project;
          })
        );
        setDeleteButtonClicked(constant.deleteForm.details);
        setShowModal(true);
      }
    },
    onArchiveClick: (id, projectArchived) => {
      setProjects(
        projects.map(project => {
          project.id === id ? (project.checked = true) : (project.checked = false);
          return project;
        })
      );
      setArchInfo({ id: id, projectArchived: projectArchived });
      setShowArchModal(true);
    },
    confirmDeleteDialog: {
      handleOnDeclineCancelButtonClick: () => {
        setShowModal(false);
      },
      handleDeleteConfirmButtonClick: () => {
        const selectedProjects = projects.filter(project => project.checked === true);

        setProjects(
          projects.map(project => {
            project.checked = false;
            return project;
          })
        );

        if (
          selectedProjects !== null &&
          selectedProjects.length > 0 &&
          deleteButtonClicked === constant.deleteForm.toolbar
        ) {
          const selectedProjectIds = selectedProjects.map(project => project.id);

          if (selectedProjectIds.length === 1) {
            handleDeleteConfirmButtonClick(
              selectedProjectIds[0],
              "ids=" + selectedProjectIds[0],
              selectedProjectIds.length
            );
          } else {
            handleDeleteConfirmButtonClick(
              selectedProjectIds[0],
              "ids=" + selectedProjectIds.join(","),
              selectedProjectIds.length
            );
          }
        } else {
          handleDeleteConfirmButtonClick(
            projectsListState.activeListItem,
            "ids=" + projectsListState.activeListItem,
            selectedProjects.length
          );
        }
      }
    },
    confirmArchiveDialog: {
      handleOnDeclineArchiveButtonClick: () => {
        setShowArchModal(false);
        setArchInfo({ id: null, partnerArchived: null });
      },
      handleArchiveConfirmButtonClick: () => {
        let projectUpdateService = new RestService.Project.Update.Service();
        let projectArchived;
        let updatedObject;
        if (archInfo.id !== null) {
          projectArchived = archInfo.projectArchived;
          updatedObject = [
            {
              id: archInfo.id,
              operation: [
                {
                  op: "replace",
                  path: "/archived",
                  value: !projectArchived
                }
              ]
            }
          ];
        } else {
          let checkedIdArr = projects.filter(project => project.checked).map(pro => pro.id);

          updatedObject = checkedIdArr.map(chPro => ({
            id: chPro,
            operation: [
              {
                op: "replace",
                path: "/archived",
                value: filterStatus.activeArchiveFilter === ""
              }
            ]
          }));
        }
        if (updatedObject.length > 0) {
          projectUpdateService.setPayload(updatedObject);
          projectUpdateService.setCallbackError(callbackUpdateError);
          projectUpdateService.setCallbackSuccess(callbackArchUpdateSuccess);
          projectUpdateService.setCallbackFinally(callbackGetAllFinally);
          projectUpdateService.call();
        }
        setShowArchModal(false);
        setDeleteLinkedProjectsModal({
          show: false,
          listOfLinkedProjects: [],
          disableArchiveBtn: true
        });
        setArchInfo({ id: null, partnerArchived: null });
        setProjectsListState({ ...projectsListState, activeListItem: "" });
      },
      onMultipleArchiveClick: () => {
        if (projects.filter(project => !project.checked).length !== projects.length) {
          setShowArchModal(true);
        }
      }
    }
  };

  const handlerSidebar = {
    onStatusFilterClick: (e, data) => {
      const activeProjectStatusFilter =
        filterStatus.activeStatusFilter === data.name ? constant.projectStatus.all : data.name;
      setPanelState(constant.panelInitState);
      setListState({ ...listState, activeMode: "list" });
      setFilterStatus({
        ...filterStatus,
        activeStatusFilter: activeProjectStatusFilter
      });
      setActivePaginationPage(1);
      setProjectsListState({
        ...projectsListState,
        activeListItem: undefined
      });
    },
    onArchiveFilterClick: (e, data) => {
      setFilterStatus({
        ...filterStatus,
        activeArchiveFilter: filterStatus.activeArchiveFilter !== "archived" ? data.name : ""
      });
      setActivePaginationPage(1);
      setListState({ ...listState, activeMode: "list" });
      setProjectsListState({
        ...projectsListState,
        activeListItem: undefined
      });
    },
    resetStatusFilterClick: () => {
      setPanelState(constant.panelInitState);
      setProjectsListState({
        ...projectsListState,
        activeListItem: projects.length > 0 ? projects[0].id : undefined
      });
      setListState({ ...listState, activeMode: "list" });
      setFilterStatus({
        ...filterStatus,
        activeStatusFilter: constant.projectStatus.all
      });
      setActivePaginationPage(1);
    }
  };

  const getDialogError = () => {
    let dialog = null;
    let modalColor = "#c00";
    let modalHeader = Localize.get("ACCOUNTS.ITEMLIST.UNSUCCESSFUL_DELETE");
    if (showModalError) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
          </Modal.Header>
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowModalError(false);
              }}
              secondary
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const getDialog = () => {
    let dialog = null;
    if (showModal) {
      dialog = (
        <ConfirmDeleteDialog
          numberOfCheckedItems={projects.filter(project => project.checked === true).length}
          showModal={showModal}
          confirmClicked={handlerItem.confirmDeleteDialog.handleDeleteConfirmButtonClick}
          declineClicked={handlerItem.confirmDeleteDialog.handleOnDeclineCancelButtonClick}
        />
      );
    }
    return dialog;
  };

  const getArchDialog = () => {
    let dialog = null;
    if (showArchModal) {
      dialog = (
        <ConfirmArchiveDialog
          showModal={showArchModal}
          activeArchiveFilter={filterStatus.activeArchiveFilter}
          confirmClicked={handlerItem.confirmArchiveDialog.handleArchiveConfirmButtonClick}
          declineClicked={handlerItem.confirmArchiveDialog.handleOnDeclineArchiveButtonClick}
          numberOfArchivedItems={projects.filter(pro => pro.checked === true).length}
          singularHeaderArchive={"PROJECTS.MANAGEMENT.CONFIRM_ARCHIVE_SINGULAR"}
          pluralHeaderArchive={"PROJECTS.MANAGEMENT.CONFIRM_ARCHIVE_PLURAL"}
          singularHeaderUnarchive={"PROJECTS.MANAGEMENT.CONFIRM_UNARCHIVE_SINGULAR"}
          pluralHeaderUnarchive={"PROJECTS.MANAGEMENT.CONFIRM_UNARCHIVE_PLURAL"}
        />
      );
      return dialog;
    }
  };

  const getDialogWarning = () => {
    let dialog = null;
    let modalColor = "#c00";
    let modalHeader = Localize.get("PROJECTS.MANAGEMENT.BE_CAREFUL");
    if (modalWarningStatus) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
          </Modal.Header>

          <Modal.Content>
            <Grid verticalAlign="middle">
              <Grid.Column width={3} textAlign="center">
                <Icon name="question circle outline" size="huge" />
              </Grid.Column>
              <Grid.Column width={13}>
                <Modal.Description>
                  <p>{Localize.get("PROJECTS.MANAGEMENT.WARNING_MESSAGE_EDIT_NEW")}</p>
                </Modal.Description>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                setModalWarningStatus(false);
              }}
            >
              {Localize.get("PROJECTS.MANAGEMENT.MESSAGE_CANCEL")}
            </Button>
            <Button color="green">{Localize.get("PROJECTS.MANAGEMENT.MESSAGE_YES")}</Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const onToggleItemHandler = data => {
    if (data.checked) {
      handlerHeader.onToggleItem(data);
      setDeleteLinkedProjectsModal({
        ...deleteLinkedProjectsModal,
        disableArchiveBtn: false
      });
    } else {
      handlerHeader.onToggleItem(data);
      setDeleteLinkedProjectsModal({
        ...deleteLinkedProjectsModal,
        disableArchiveBtn: true
      });
    }
  };

  const projectList = () => {
    let newArray = projects.filter(project => {
      let machedIds = deleteLinkedProjectsModal.listOfLinkedProjects.filter(linkedProjectId => {
        return linkedProjectId === project.id && project.archived !== true ? true : false;
      });

      return machedIds > 0 ? true : false;
    });

    return newArray.map(r => (
      <div className={styles.project_multiple_delete_item} key={r.id}>
        <ProjectManagementItem
          project={r}
          state={projectsListState}
          onListItemClick={() => {}}
          onListToggleClick={onToggleItemHandler}
        />
      </div>
    ));
  };

  const getDeleteLinkedProjectDialog = () => {
    let dialog = null;
    let modalColor = "#c00";
    let modalHeader = Localize.get("PROJECTS.MANAGEMENT.LINKED_PROJECTS_CANNOT_BE_DELETED");
    let modalHeaderChooseArchive = Localize.get("PROJECTS.MANAGEMENT.LINKED_CAN_BE_ONLY_ARCIVED");
    if (deleteLinkedProjectsModal.show) {
      dialog = (
        <Modal
          size="small"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
            <br />
            {modalHeaderChooseArchive}
          </Modal.Header>
          <Modal.Content scrolling={true}>{projectList()}</Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setDeleteLinkedProjectsModal({
                  show: false,
                  listOfLinkedProjects: [],
                  disableArchiveBtn: true
                });
                setProjects(
                  projects.map(project => {
                    project.checked = false;
                    return project;
                  })
                );
              }}
              secondary
            >
              {Localize.get("GLOBAL.FORM.CANCEL")}
            </Button>
            <Button
              onClick={handlerItem.confirmArchiveDialog.handleArchiveConfirmButtonClick}
              disabled={deleteLinkedProjectsModal.disableArchiveBtn}
              positive
              labelPosition="right"
              icon="checkmark"
              content={Localize.get("CONTACTS.LIST.TOOLBAR.ARCHIVE")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const handleOnDeclineCancelButtonClick = () => {
    setShowModal(false);
  };

  const callbackDeleteSuccess = async (firstSelectedProjectId, response, projectsLenght) => {
    if (response && response.status === 226) {
      if (filterStatus.activeArchiveFilter === "archived") {
        showNotificationMessage({
          serverMessage: Localize.get("PROJECTS.MANAGEMENT.LINKED_PROJECTS_CANNOT_BE_DELETED"),
          type: "warning",
          operation: "delete"
        });
      } else {
        let myArr = JSON.parse(response.data.message);
        setDeleteLinkedProjectsModal({
          show: true,
          listOfLinkedProjects: myArr,
          disableArchiveBtn: true
        });
      }
    } else {
      if (projectsLenght > 1) {
        showNotificationMessage({
          entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PROJECTS"),
          type: "info",
          operation: "delete"
        });
      } else {
        showNotificationMessage({
          type: "info",
          operation: "delete"
        });
      }
    }
    handleOnDeclineCancelButtonClick();
    const filterObject = {
      activeArchiveFilter: filterStatus.activeArchiveFilter,
      activeStatusFilter: filterStatus.activeStatusFilter
    };
    const service = createGetAllService(filterObject);
    const fetchData = async () => {
      setIsLoading(true);
      await service.call();
      setIsLoading(false);
      setListState({ ...listState, activeMode: "list" });
    };

    let firstDeletedIndex = projects.findIndex(element => element.id === firstSelectedProjectId);
    if (firstDeletedIndex !== 0) {
      selectedIndex = firstDeletedIndex - 1;
      service.query.setPage(activePaginationPage - 1);
    } else {
      if (activePaginationPage > 1) {
        selectedIndex = 19;
        setActivePaginationPage(activePaginationPage - 1);
        service.query.setPage(activePaginationPage - 2);
      } else {
        selectedIndex = firstDeletedIndex;
        service.query.setPage(activePaginationPage - 1);
      }
    }
    fetchData();
  };

  const callbackDeleteError = async response => {
    handleOnDeclineCancelButtonClick();
    const filterObject = {
      activeArchiveFilter: filterStatus.activeArchiveFilter,
      activeStatusFilter: filterStatus.activeStatusFilter
    };
    const service = createGetAllService(filterObject);
    await service.call();

    setFilterStatus({
      ...filterStatus,
      activeArchiveFilter: "active"
    });
    showNotificationMessage({
      type: "warning",
      operation: "delete"
    });
  };

  const handleDeleteConfirmButtonClick = async (firstSelectedProjectId, itemId, projectsLenght) => {
    let serviceDelete = new RestService.Project.Delete.Service(itemId);

    serviceDelete.setCallbackSuccess(res => {
      callbackDeleteSuccess(firstSelectedProjectId, res, projectsLenght);
    });
    serviceDelete.setCallbackError(callbackDeleteError);
    serviceDelete.setCallbackFinally(callbackGetAllFinally);
    await serviceDelete.call();
  };

  const callbackGetBinaryContentSuccess = response => {
    var reader = new window.FileReader();

    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        setLinkedDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: reader.result
        });
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });

      setLinkedDocumentsPreview({
        isPDF: response.headers["content-type"].includes("pdf"),
        data: urlCreator.createObjectURL(file)
      });
    }
  };

  const getDocumentBinaryContnt = selDoc => {
    if (selDoc) {
      if (selDoc.binaryContent && selDoc.binaryContent.id) {
        const serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(
          selDoc.binaryContent.id
        );

        serviceGetContent.query.addResponseType("blob");
        serviceGetContent.setCallbackSuccess(callbackGetBinaryContentSuccess);
        serviceGetContent.setCallbackError(callbackGetAllError);
        serviceGetContent.setCallbackFinally(callbackGetAllFinally);
        serviceGetContent.call();
      } else {
        setLinkedDocumentsPreview({
          isPDF: false,
          data: documentSample
        });
      }
    }
  };

  const handlerButton = {
    closeNewButtonFormHandler: event => {
      event.preventDefault();
      setPanelState({ activeMode: constant.panelMode.details });
      setEditMode(false);
    },
    saveNewButtonHandler: newProject => {
      if (panelState.activeMode === constant.panelMode.create) {
        createNewProjectServiceCall(newProject, true);
      }
    },
    createNewProjectButtonHandler: event => {
      event.preventDefault();

      if (panelState.activeMode === constant.panelMode.edit) {
        setModalWarningStatus(true);
      } else {
        setPanelState({ activeMode: constant.panelMode.create });
        setEditMode(true);
        setSideBarVisibility(false);
      }
    },

    saveButtonFormHandler: newProject => {
      if (panelState.activeMode === constant.panelMode.create) {
        createNewProjectServiceCall(newProject);
      }
      if (panelState.activeMode === constant.panelMode.edit) {
        if (newProject.editedFields && newProject.editedFields.length > 0) {
          updateProjectServiceCall(newProject);
        } else {
          setEditMode(false);
          setPanelState({ activeMode: constant.panelMode.details });
        }
      }
    },
    handleWarningYes: () => {
      setPanelState({ activeMode: constant.panelMode.create });

      setModalWarningStatus(false);
    },
    showLinkedDocumentHandler: selDocumentId => {
      if (selDocumentId) {
        getDocumentBinaryContnt(selDocumentId);
      }
      listState.activeMode === constant.listMode.documentPreview && selDocumentId !== null
        ? setListState({ activeMode: constant.listMode.documentPreview })
        : listState.activeMode === constant.listMode.list
        ? setListState({ activeMode: constant.listMode.documentPreview })
        : setListState({ activeMode: constant.listMode.list });
    }
  };

  let class1 = "ui container fluid finances_bank_transactions new_bank_account";
  let class2 = "ui two column stackable grid";

  let selectedProject =
    projects.length > 0
      ? projects.find(project => project.id === projectsListState.activeListItem)
      : null;

  const handleDeleteRole = () => {
    setProjects();
  };

  return (
    <div className={class1}>
      <Dimmer active={isLoading || editMode} inverted>
        <Loader size="large" disabled={!isLoading}>
          {Localize.get("PROJECTS.MANAGEMENT.PROJECT_LOADER")}
        </Loader>
      </Dimmer>

      <div
        className={`ui right attached button ${styles.bms_fixed} ${
          sideBarVisibility || editMode ? styles.bms_zindex : null
        }`}
        onClick={settingVisibleSidebar}
      >
        <i className="ellipsis vertical icon"></i>
      </div>

      <Sidebar.Pushable
        as={Segment}
        className={editMode ? styles.bms_segment_zi : styles.bms_segment}
      >
        <ProjectManagementSideBar
          handlerButton={handlerButton}
          handlerSidebar={handlerSidebar}
          filterStatus={filterStatus}
          archivedFilter={filterStatus.activeArchiveFilter}
          privileges={privileges}
          sideBarVisibility={sideBarVisibility}
          setSideBarVisibility={setSideBarVisibility}
          editMode={editMode}
        />

        <Sidebar.Pusher dimmed={sideBarVisibility} className={editMode ? styles.bms_zi : null}>
          <div className={styles.bms_projects_content}>
            <div className={class2}>
              {listState.activeMode !== constant.listMode.documentPreview ? (
                <ProjectManagementList
                  projects={projects}
                  privileges={privileges}
                  handlerItem={handlerItem}
                  handlerHeader={handlerHeader}
                  handlerSidebar={handlerSidebar}
                  onArchiveButtonCLick={handlerItem.confirmArchiveDialog.onMultipleArchiveClick}
                  archivedFilter={filterStatus.activeArchiveFilter}
                  archivedActiveFilter={filterStatus.activeArchiveFilter}
                  state={projectsListState}
                  selectAll={projectsListState.selectAll}
                  handlerButton={handlerButton}
                  searchFunctions={handleSearches}
                  projectState={projectState}
                  editMode={editMode}
                  handlePageChange={handlePageChange}
                  paginationStatus={paginationStatus}
                  activePaginationPage={activePaginationPage}
                />
              ) : (
                <div className="ten wide column stackable grid">
                  <DocumentShowScan linkedDocumentsPreview={linkedDocumentsPreview} />
                </div>
              )}
              {panelState.activeMode !== constant.panelMode.details ? (
                <ProjectManagementPanel
                  handlerButton={handlerButton}
                  privileges={privileges}
                  editMode={editMode}
                  panelState={panelState}
                  project={selectedProject}
                  formErrorMessage={formErrorMessage}
                  handleDeleteRole={handleDeleteRole}
                  lastProjectId={lastProjectId}
                  clearProjectForm={clearProjectForm}
                />
              ) : (
                <ProjectManagementDetails
                  privileges={privileges}
                  onEditItemClick={handlerItem.onEditItemClick}
                  onDeleteItemClick={handlerItem.onDeleteItemClick}
                  project={selectedProject ? selectedProject : constant.initialProject}
                  onArchiveClick={handlerItem.onArchiveClick}
                  handler={handlerButton}
                  state={listState}
                  filterStatus={filterStatus}
                  setIsLoading={setIsLoading}
                />
              )}
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <div>
        {getDialog()}
        {getDialogError()}
        {getDialogWarning()}
        {getArchDialog()}
        {getDeleteLinkedProjectDialog()}
      </div>
    </div>
  );
};

export default ProjectManagement;
