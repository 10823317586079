import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ConfirmDeleteDialog.module.css";
import GlobalOkCancelBtn from "../Components/GlobalOkCancelBtn";

const ConfirmDeleteDialog = props => {
  const {
    numberOfCheckedItems,
    linkedTransactionsToAccount,
    confirmClicked,
    declineClicked,
    showModal
  } = props;
  const getDialog = () => {
    let dialog = null;
    let modalColor = "#c00";
    const modalHeader = () => {
      let modalMsg;
      if (linkedTransactionsToAccount) {
        modalMsg = Localize.get("MODAL.LINKED_TRANSACTIONS");
      } else {
        modalMsg =
          numberOfCheckedItems > 1
            ? Localize.get("MODAL.CONFIRM_DELETE_PLURAL")
            : Localize.get("MODAL.CONFIRM_DELETE");
      }
      return modalMsg;
    };

    if (showModal) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header
            className={styles.delete_dialog_header}
            style={{
              backgroundColor: modalColor
            }}
          >
            {modalHeader()}
          </Modal.Header>
          <Modal.Actions>
            <GlobalOkCancelBtn
              onConfirmClicked={confirmClicked}
              onCancel={declineClicked}
              btnText={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  return <>{getDialog()}</>;
};

export default ConfirmDeleteDialog;
