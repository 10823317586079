import React from "react";
import GlobalHederRightPanel from "../../Components/Details/GlobalHederRightPanel";
import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import {
  availableItemFieldsForBookingDetails,
  availableItemFieldsForDetailsBookingAccounts
} from "../BookkeepingConfig";
import styles from "../style/BookingRightPanel.module.css";
import Localize from "react-intl-universal";

const BookingRightPanel = props => {
  const { selectedItem, setCurrentView, currentModule, currentView } = props;

  const getFieldsWithData = fields => {
    return fields.map((field, index) => {
      let iconHeandler = () => setCurrentView("preview");
      return (
        <GlobalDetailsItem
          key={index}
          type={field.type}
          itemName={field.labelName}
          itemValue={selectedItem ? selectedItem[field.name] : null}
          iconName={"eye"}
          iconHeandler={iconHeandler}
        />
      );
    });
  };

  const getPreviewDoc = () => {
    return <div>{Localize.get("TRANSACTIONS.INVOICETHUMB.PREVIEW")}</div>;
  };

  const getRightPanelBody = () => {
    switch (currentView) {
      case "details":
        return (
          <>
            {getFieldsWithData(availableItemFieldsForBookingDetails)}
            <div className={styles.header_above_list}>
              {" "}
              {Localize.get("BOOKKEEPING.BOOKING_DETAILS_SUBHEADER_03")}
            </div>
            {getFieldsWithData(availableItemFieldsForDetailsBookingAccounts)}
          </>
        );
      case "preview":
        return getPreviewDoc();
      default:
        break;
    }
  };

  return (
    <>
      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        detailsHeaderText={"BOOKKEEPING.BOOKING_DETAILS_HEADER"}
        hideIcon={true}
        customCss={styles.details_header}
      />
      {getRightPanelBody()}
    </>
  );
};

export default BookingRightPanel;
