import axios from "axios";
import { getLocalStorage, logOutOffUnauthorizedCall, getSession } from "../Auth/AuthContext";

const getDefaultConfig = ({ path = "", params = {}, data = {}, url = "" }) => {
  const auth = getLocalStorage("auth") || {};
  const session = getSession();
  const { token } = auth;
  const myBaseUrl = `${process.env.REACT_APP_API_LINK}${path}`;
  return {
    baseURL: myBaseUrl,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Session: session
    },
    params,
    data,
    url
  };
};

const myErrorFunc = response => {
  if (
    response &&
    response.data &&
    response.data.message &&
    ((response.status === 401 &&
      (response.data.message === "logout" || response.data.message === "expired")) ||
      response.status === 500)
  ) {
    if (response.status === 500) {
      response.data.message = "error";
    }
    logOutOffUnauthorizedCall(response.data.message);
  }
};

export const fetchBookingOverview = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    function(response) {
      return {
        data: response.data.content,
        paging: {
          perPage: response.data.pageable.pageSize,
          pageCount: response.data.totalPages,
          totalCount: response.data.totalElements
        }
      };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );
  return instance();
};

export const fetchAccountOverview = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    function(response) {
      let responseWithId = response.data.content.map(item => ({
        ...item,
        id: item.accountKey
      }));
      return {
        data: responseWithId,
        paging: {
          perPage: response.data.pageable.pageSize,
          pageCount: response.data.totalPages,
          totalCount: response.data.totalElements
        }
      };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );
  return instance();
};
