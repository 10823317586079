import React from "react";
import { Menu, Grid, Checkbox } from "semantic-ui-react";
import styles from "./style/ProjectTemplateItem.module.css";

const ProjectTemplateItem = props => {
  const { template, togleItem, onListItemClick = function(pro) {}, activeListItem } = props;
  const handleItemClick = (event, data) => {
    onListItemClick(data.id);
  };

  return (
    <Menu.Item
      className={styles.template_item}
      id={template.id}
      del={template.id}
      active={activeListItem === template.id ? true : null}
      onClick={handleItemClick}
    >
      <Grid columns="4" stackable container verticalAlign="middle">
        <Grid.Column width={2} className={styles.project_list_checkbox_column}>
          <Checkbox
            checked={template.checked}
            onClick={e => {
              e.stopPropagation();
              togleItem(template);
            }}
            item_id={template.id}
          />
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={3}>
          <div className="content">
            <div className={styles.list_name}>{template.id}</div>
          </div>
        </Grid.Column>
        <Grid.Column width={4} className={styles.column_height}>
          <div className="content">
            <div className={styles.list_name}>{template.name}</div>
          </div>
        </Grid.Column>
        <Grid.Column className={styles.wrap_column} width={4}>
          <div className={styles.list_name}>{template.description}</div>
        </Grid.Column>
      </Grid>
    </Menu.Item>
  );
};

export default ProjectTemplateItem;
