import React from "react";
import { Modal } from "semantic-ui-react";
import Localize from "react-intl-universal";
import GlobalOkBtn from "../Components/GlobalOkBtn";

const SelectWarningDialog = props => {
  const { handleSelectWarningOkButtonClick } = props.handle;

  return (
    <>
      <Modal
        size="tiny"
        dimmer="inverted"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{Localize.get("DOCUMENTS.MODAL.MODAL_WARNING")}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {Localize.get("DOCUMENTS.MODAL.MODAL_PLEASE_SELECT_ITEM")}{" "}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <GlobalOkBtn onOkClicked={handleSelectWarningOkButtonClick} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default SelectWarningDialog;
