import React, { useEffect } from "react";
import useFetch from "../../RestServicesNew/useFetch";
import Localize from "react-intl-universal";
import GlobalList from "../../Components/Lists/GlobalList";
import GlobalOkButton from "../../Components/GlobalOkBtn";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import { Loader, Dimmer, Modal } from "semantic-ui-react";
import { fetchDefaultTemplate } from "../../RestServicesNew/DefaultTemplateServices";
import styles from "../../Contacts/RightPanel/style/InvoiceNotifiers.module.css";

const TemplateModalList = ({ documentTypeId, setShowModal, addTemplate, activeInput }) => {
  const callbackFetchTemplateByCategoyCussess = response => {
    setListOfTemplates(response);
  };

  const callbackFetchTemplateByCategoyError = err => {
    console.log(err);
  };

  const [listOfTemplates, , templatesLoading, fetchTemplatesCall, setListOfTemplates] = useFetch(
    fetchDefaultTemplate,
    [],
    `/templates?categoryKey=${activeInput}`,
    {},
    callbackFetchTemplateByCategoyCussess,
    callbackFetchTemplateByCategoyError
  );

  useEffect(() => {
    fetchTemplatesCall();
  }, []);

  const itemHandler = item => {
    setListOfTemplates(prevState => {
      let newState = prevState.map(loopedItem => {
        if (item.id === loopedItem.id) {
          loopedItem.checked
            ? (loopedItem.checked = !loopedItem.checked)
            : (loopedItem.checked = true);
        } else {
          loopedItem.checked = false;
        }
        return loopedItem;
      });

      return newState;
    });
  };

  const onOkClicked = () => {
    let chosenTemplate;
    if (listOfTemplates) {
      chosenTemplate = listOfTemplates.find(item => item.checked);
    } else {
      chosenTemplate = null;
    }

    if (chosenTemplate) {
      let { id, name, templateCategory } = chosenTemplate;

      addTemplate(id, name, templateCategory.categoryKey);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  return (
    <>
      <Dimmer active={templatesLoading} inverted>
        <Loader size="large" disabled={!templatesLoading}>
          {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
        </Loader>
      </Dimmer>
      <GlobalList
        currentView="details"
        listState={listOfTemplates}
        itemHandler={itemHandler}
        listContainerCustomCSS={styles.template_list}
        availableItemFieldsForList={[
          {
            name: "checked",
            type: "checkbox",
            width: 1,
            visible: true
          },
          {
            name: "id",
            hederName: "MODAL.ID",
            type: "text",
            width: 2,
            visible: true
          },
          {
            name: "name",
            hederName: "CONTACTS.LIST.HEADER.NAME",
            type: "text",
            width: 6,
            visible: true
          },
          {
            name: "description",
            hederName: "BOOKKEEPING.HEADER_DESCRIPTION",
            type: "text",
            width: 6,
            visible: true
          }
        ]}
      />
      <Modal.Actions>
        <GlobalOkButton onOkClicked={onOkClicked} />
        <GlobalCancelBtn onCancel={() => setShowModal(false)} />
      </Modal.Actions>
    </>
  );
};

export default TemplateModalList;
