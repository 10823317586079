import React, { useState, useEffect } from "react";
import GlobalPanel from "../../Components/Panels/GlobalPanel";

import { booking_leftPanelConfig, myConfigForBooking } from "../BookkeepingConfig";
import BookingMiddlePanel from "./BookingMiddlePanel";
import BookingRightPanel from "./BookingRightPanel";
import * as constants from "../Constants";
import useBookingOverviewServices from "../../RestServicesNew/useBookingOverviewServices";

const BookingOverview = props => {
  const [currentModule, setCurrentModule] = useState(constants.bookingOverviewModule.allBookings);
  let delayTimer;

  let initialSearchObject = {};
  const [currentView, setCurrentView] = useState("details");

  const [searchObject, setSearchObject] = useState(initialSearchObject);

  const {
    bookingOverviewList,
    setbookingOverviewList,
    bookingOverviewLoading,
    bookingOverviewCall,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    filterObject,
    setFilterObject,
    paginationStatus,
    activePaginationPage,
    setActivePaginationPage
  } = useBookingOverviewServices(currentView);

  const handlePageChange = async activePage => {
    setActivePaginationPage(activePage);
  };

  //function used for filter in side bar
  const filterHandler = (fieldName, fieldValue, currentFilters) => {
    if (!currentFilters.hasOwnProperty(fieldName)) {
      currentFilters = {};
      setFilterObject({ ...currentFilters, [fieldName]: fieldValue });
    } else {
      delete currentFilters[fieldName];
      setFilterObject({ ...currentFilters });
    }
  };

  //function used for search
  const searchFilter = (e, data, currentFilters, reset = false) => {
    if (reset) {
      setFilterObject(constants.initialFilterObject);
      setSearchObject(initialSearchObject);
    } else {
      setActivePaginationPage(1);
      clearTimeout(delayTimer);
      setSearchObject({ ...currentFilters, [data.name]: data.value });
    }
  };

  //function used for sort
  const sortHandler = (fieldName, fieldValue, currentFilters) => {
    if (currentFilters.sortBy === fieldName) {
      if (currentFilters.sortDirection === fieldValue) {
        setFilterObject({ ...currentFilters, sortBy: "", sortDirection: "" });
      } else {
        setFilterObject({
          ...currentFilters,
          sortBy: fieldName,
          sortDirection: fieldValue
        });
      }
    } else {
      setFilterObject({
        ...currentFilters,
        sortBy: fieldName,
        sortDirection: fieldValue
      });
    }
  };

  useEffect(() => {
    bookingOverviewCall();
  }, [filterObject, activePaginationPage]);

  const leftPanelProps = {
    leftPanelConfig: booking_leftPanelConfig,
    setCurrentModule: setCurrentModule,
    currentModule: currentModule,
    setCurrentView: setCurrentView,
    filterObject: filterObject,
    filterHandler: filterHandler,
    setSelectedItem: setSelectedItem,
    forbidenResponse: forbidenResponse,
    setListState: setbookingOverviewList,
    setActivePaginationPage: setActivePaginationPage
  };

  const middlePanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    listState: bookingOverviewList,
    setListState: setbookingOverviewList,
    searchFilter: searchFilter,
    filterObject: filterObject,
    searchObject: searchObject,
    forbidenResponse: forbidenResponse,
    listDimmed:
      currentView === constants.panelView.edit || currentView === constants.panelView.create,
    activePaginationPage: activePaginationPage,
    handlePageChange: handlePageChange,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    paginationStatus: paginationStatus,
    callGetAll: bookingOverviewCall,
    sortHandler: sortHandler
  };
  const rightPanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    selectedItem: selectedItem,
    callGetAll: bookingOverviewCall,
    activePaginationPage: activePaginationPage,
    setActivePaginationPage: setActivePaginationPage,
    lenghtOfList: bookingOverviewList.length
  };

  return (
    <GlobalPanel
      currentView={currentView}
      setCurrentModule={setCurrentModule}
      customizedMiddlePanelComponent={BookingMiddlePanel}
      customizedRightPanelComponent={BookingRightPanel}
      leftPanelProps={leftPanelProps}
      middlePanelProps={middlePanelProps}
      rightPanelProps={rightPanelProps}
      isLoading={bookingOverviewLoading}
      globalPanelConf={currentView === "edit" ? myConfigForBooking : undefined}
    />
  );
};

export default BookingOverview;
