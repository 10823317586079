import React from "react";
import { Menu, Grid, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import { localizeStatus } from "../../Management/Util";

const ProjectStatusesItem = props => {
  const { externalStatus, handle, externalStatusPrivileges, state } = props;

  const onItemClick = (event, data) => {
    handle.onListItemClick(data.id);
  };

  const onEditItemClick = (event, data) => {
    event.stopPropagation();
    handle.onEditItemClick(data.id);
  };

  const onDeleteItemClick = (event, data) => {
    event.stopPropagation();
    handle.onDeleteItemClick(data.id);
  };

  return (
    <Menu.Item
      id={externalStatus.id}
      active={state.id === externalStatus.id ? true : null}
      onClick={onItemClick}
    >
      <Grid stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <div className="list_name">{externalStatus.id}</div>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <div className="list_name">
                {" "}
                {externalStatus.name ? externalStatus.name : undefined}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div>{localizeStatus(externalStatus.status.name)}</div>
          </Grid.Column>
          <Grid.Column width={3} className="roleEdit">
            <Icon
              disabled={!externalStatusPrivileges.update}
              className="edit outline icon"
              id={externalStatus.id}
              onClick={onEditItemClick}
              title={Localize.get("PROJECTS.STATUSES.EDIT")}
            />
            <Icon
              disabled={!externalStatusPrivileges.delete}
              className="trash alternate outline icon roleDeleteIcon"
              id={externalStatus.id}
              onClick={onDeleteItemClick}
              title={Localize.get("PROJECTS.STATUSES.DELETE")}
            />
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );
};

export default ProjectStatusesItem;
