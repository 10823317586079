import React from "react";
import { Button, Icon, Segment, Container, Header } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./Templates.module.css";

const TemplateMiddlePanel = () => {
  return (
    <Container className={styles.main_container}>
      <Segment placeholder className={styles.seg_placeholder}>
        <Header>
          <Icon name="info circle" size="small" />
          {Localize.get("SETTINGS.HEADERS.TEMPLATES_FE")}
        </Header>
        <p>{Localize.get("SETTINGS.HEADERS.OPEN_FE_TAB")}</p>

        <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TED_LINK}>
          <Button
            icon="window restore"
            primary
            content={Localize.get("SETTINGS.HEADERS.OPEN_FE_BUTTON")}
          ></Button>
        </a>
        <Header>{Localize.get("SETTINGS.HEADERS.TEMPLATE_INFO")}</Header>
        <p>{Localize.get("SETTINGS.HEADERS.TEMPLATE_INFO_SND")}</p>
      </Segment>
    </Container>
  );
};

export default TemplateMiddlePanel;
