import { useState } from "react";
import has from "lodash/has";
import update from "lodash/update";

const useHandleOnChange = (state, setState) => {
  const [editedFields, setEditedFields] = useState([]);
  const handleOnChangeUsingPath = (fieldPath = "", value = "") => {
    if (has(state, fieldPath)) {
      let tempObj = { ...state };
      update(tempObj, fieldPath, myFieldElement => {
        myFieldElement = value;
        return myFieldElement;
      });
      setState(tempObj);
      checkEditedFields(fieldPath);
    } else {
      console.error("This path is not recognized in state you want to update");
    }
  };

  const useMultipleChange = (myObj = {}) => {
    let newObj = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(myObj).map(item => {
      if (has(state, item)) {
        newObj[item] = myObj[item];
      }
    });
    setState({ ...state, ...newObj });
  };

  const handleOnChangeList = (fieldPath = "", value = "", index) => {
    if (has(state, `${fieldPath}[${index}]`)) {
      let tempObj = { ...state };
      update(tempObj, `${fieldPath}[${index}]`, myFieldElement => {
        myFieldElement = value;
        return myFieldElement;
      });
      setState(tempObj);
      checkEditedFields(fieldPath);
    } else {
      console.error("This path is not recognized in state you want to update");
    }
  };

  const deleteItemFromList = (fieldPath = "", index) => {
    if (has(state, fieldPath)) {
      const newState = state[fieldPath].filter((contact, i) => i !== index);
      setState({ ...state, [fieldPath]: newState });
      checkEditedFields(fieldPath);
    } else {
      console.error("This path is not recognized in state you want to update");
    }
  };

  const addItemToList = (fieldPath = "", newItem) => {
    if (has(state, fieldPath)) {
      setState(previousState => {
        return {
          ...previousState,
          [fieldPath]: [newItem, ...previousState[fieldPath]]
        };
      });
      checkEditedFields(fieldPath);
    } else {
      console.error("This path is not recognized in state you want to update");
    }
  };

  const checkEditedFields = field => {
    setEditedFields(previousState => {
      let newArr = [...previousState];
      !newArr.includes(field) && newArr.push(field);
      return newArr;
    });
  };

  return [
    handleOnChangeUsingPath,
    handleOnChangeList,
    deleteItemFromList,
    addItemToList,
    useMultipleChange,
    editedFields
  ];
};

export default useHandleOnChange;
