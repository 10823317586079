import React, { useState, useEffect } from "react";
import { Container, Message, Dimmer, Loader, Segment } from "semantic-ui-react";
import Localize from "react-intl-universal";
import ListWithPagination from "./ListWithPagination2";
import GlobalItem from "./GlobalItem";
import Unauthorised from "../../Router/Unauthorised";
import styles from "./style/GlobalList.module.css";
import propTypes from "prop-types";
import GlobalHeaderForList from "./GlobalHeaderForList";

const GlobalList = props => {
  const {
    currentView = "list",
    activePaginationPage = 1,
    handlePageChange = function(pro) {},
    availableItemFieldsForList = [],
    itemHandler = function(pro) {},
    customItemCss,
    selectedItem = null,
    setSelectedItem = function(pro) {},
    wantHeader = true,
    listState,
    forbidenResponse,
    paginationStatus = { pageCount: 0, totalCount: 0, perPage: 0 },
    showAtBottom = false,
    listContainerCustomCSS = "",
    listPaginationCustomCSS = "",
    loadingLocaly = false,
    searchFilter,
    filterObject,
    searchObject,
    sortHandler,
    disableInput,
    CustomItemComponent,
    headerIcon,
    currentModule
  } = props;

  const [fieldsForList, setFieldsForList] = useState(availableItemFieldsForList);
  const isNoSelectedColumn = () => {
    let isNoSel = fieldsForList.every(item => item.visible === false);
    if (isNoSel) {
      setSelectedItem(null);
    } else if (selectedItem) {
      return;
    } else {
      setSelectedItem(listState[0]);
    }

    return isNoSel;
  };

  useEffect(() => {
    if (currentModule) {
      setFieldsForList(availableItemFieldsForList);
    }
  }, [currentModule]);

  const myList = providedOrGeneratedList => {
    return providedOrGeneratedList.map((item, index) => {
      if (CustomItemComponent) {
        return React.createElement(
          CustomItemComponent,
          {
            itemHandler,
            selectedItem,
            setSelectedItem,
            availableItemFieldsForList,
            item,
            key: index
          },
          null
        );
      } else {
        return (
          <GlobalItem
            key={index}
            item={item}
            currentView={currentView}
            selectedItem={selectedItem}
            itemHandler={itemHandler}
            setSelectedItem={setSelectedItem}
            availableItemFieldsForList={fieldsForList}
            customItemCss={customItemCss}
            headerIcon={headerIcon}
          />
        );
      }
    });
  };

  return (
    <>
      <Container className={`${styles.container_list} ${listContainerCustomCSS}`}>
        <Segment className={styles.segment_padding}>
          <Dimmer active={loadingLocaly} inverted>
            <Loader size="large" disabled={!loadingLocaly}></Loader>
          </Dimmer>
          {wantHeader && !forbidenResponse ? (
            <GlobalHeaderForList
              availableItemFieldsForList={fieldsForList}
              searchFilter={searchFilter}
              filterObject={filterObject}
              searchObject={searchObject}
              sortHandler={sortHandler}
              disableInput={disableInput}
              headerIcon={headerIcon}
              setFieldsForList={setFieldsForList}
            />
          ) : null}
          {listState && listState.length > 0 && !isNoSelectedColumn() ? (
            <ListWithPagination
              list={myList(listState)}
              currentView={currentView}
              defaultActivePage={paginationStatus.pageCount}
              totalPage={paginationStatus.totalCount}
              handlePageChange={handlePageChange}
              activePaginationPage={activePaginationPage}
              perPage={paginationStatus.perPage}
              showAtBottom={showAtBottom}
              listPaginationCustomCSS={listPaginationCustomCSS}
            />
          ) : (
            <>
              {forbidenResponse ? (
                <div className={styles.unauthorised_page}>
                  <Unauthorised />
                </div>
              ) : (
                <div className={listPaginationCustomCSS}>
                  <Message>{Localize.get("GLOBAL.LIST.LIST_EMPTY")}</Message>
                </div>
              )}
            </>
          )}
        </Segment>
      </Container>
    </>
  );
};

GlobalList.propTypes = {
  currentView: propTypes.string,
  activePaginationPage: propTypes.number,
  handlePageChange: propTypes.func,
  customItemCss: propTypes.string,
  availableItemFieldsForList: propTypes.array,
  loadingLocaly: propTypes.bool,
  setSelectedItem: propTypes.func,
  itemHandler: propTypes.func,
  selectedItem: propTypes.object,
  wantHeader: propTypes.bool,
  listState: propTypes.array,
  forbidenResponse: propTypes.bool,
  paginationStatus: propTypes.object,
  showAtBottom: propTypes.bool,
  listContainerCustomCSS: propTypes.string,
  listPaginationCustomCSS: propTypes.string,
  disableInput: propTypes.bool
};

export default GlobalList;
