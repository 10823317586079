import React, { useState, useEffect } from "react";
import GlobalDetails from "../../Components/Panels/GlobalDetails";
import GlobalForm from "../../Components/Panels/GlobalForm";
import * as property from "./DefaultTemplateConfig";
import Localize from "react-intl-universal";
import DefaultTemplateDetails from "./DefaultTemplateDetails";
import DefaultTemplatesForm from "./DefaultTemplatesForm";
import useDefaultTemplateServices from "../../RestServicesNew/useDefaultTemplateServices";

const DefaultTemplateRightPanel = props => {
  const { currentView, setCurrentView, currentModule, selectedItem } = props;

  const [errorFields, setErrorFields] = useState({});

  const {
    selectedTemplate,
    fetchDefaultTemplateCall,
    setSelectedTemplate,
    putDefaultTemplate,
    putLoading
  } = useDefaultTemplateServices(selectedItem, currentView);

  const getDetailsConfig = () => {
    return property[selectedItem.categoryKey];
  };

  const myErrorHander = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorResponse = error.response.data;
      let errorObj = {};
      // eslint-disable-next-line array-callback-return
      errorResponse.map(item => {
        errorObj[item.validationSubject] = Localize.get(item.validationMessage);
      });
      setErrorFields(errorObj);
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };

  useEffect(() => {
    setErrorFields(prevPara => {
      return {
        ...Object.keys(prevPara).reduce((reduced, key) => ({ ...reduced, [key]: "" }), {})
      };
    });
  }, [currentView]);

  useEffect(() => {
    fetchDefaultTemplateCall();
  }, [selectedItem]);

  return (
    <>
      {selectedItem && currentView === "details" ? (
        <GlobalDetails
          selectedItem={selectedItem}
          currentView={currentView}
          currentModule={currentModule}
          setCurrentView={setCurrentView}
          detailsHeaderText={"SETTINGS.HEADERS.PROP_DETAILS"}
          availableItemFieldsForDetails={getDetailsConfig()}
          disabledIcons={selectedTemplate.length > 0 ? ["delete"] : ["delete"]}
          CustomDetails={DefaultTemplateDetails}
          customProps={{
            selectedTemplate,
            setSelectedTemplate,
            setCurrentView
          }}
        />
      ) : currentView === "edit" || currentView === "create" ? (
        <GlobalForm
          okButton={true}
          selectedItem={selectedTemplate}
          setCurrentView={setCurrentView}
          currentView={currentView}
          currentModule={currentModule}
          formHeaderText={"SETTINGS.HEADERS.EDIT_PROP"}
          availableItemFieldsForDetails={getDetailsConfig()}
          CustomForm={DefaultTemplatesForm}
          customErrorHandler={myErrorHander}
          parameters={errorFields}
          putDefaultTemplate={putDefaultTemplate}
          putLoading={putLoading}
        />
      ) : null}
    </>
  );
};

export default DefaultTemplateRightPanel;
