import React from "react";
import styles from "./style/GlobalDetails.module.css";
import GlobalHederRightPanel from "../Details/GlobalHederRightPanel";
import propTypes from "prop-types";

const GlobalDetails = props => {
  const {
    selectedItem,
    currentView = "details",
    setCurrentView = function(pro) {},
    currentModule = "",
    availableItemFieldsForDetails = [],
    CustomDetails,
    formHeaderText = "",
    detailsHeaderText = "",
    deleteItem = function(pro) {},
    disabledIcons,
    customProps
  } = props;

  let CustomDetailsElement = React.createElement(
    CustomDetails,
    { selectedItem, currentView, availableItemFieldsForDetails, customProps },
    null
  );

  return (
    <>
      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        formHeaderText={formHeaderText}
        detailsHeaderText={detailsHeaderText}
        deleteItem={deleteItem}
        disabledIcons={disabledIcons}
      />
      <div className={styles.details_container}>{selectedItem ? CustomDetailsElement : null}</div>
    </>
  );
};

GlobalDetails.propTypes = {
  selectedItem: propTypes.object,
  currentView: propTypes.string.isRequired,
  setCurrentView: propTypes.func,
  currentModule: propTypes.string.isRequired,
  availableItemFieldsForDetails: propTypes.array,
  CustomDetails: propTypes.func.isRequired,
  formHeaderText: propTypes.string,
  detailsHeaderText: propTypes.string,
  deleteItem: propTypes.func
};

export default GlobalDetails;
