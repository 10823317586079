import React, { useEffect, useRef, useState } from "react";
import { Grid, Container, Segment } from "semantic-ui-react";
import HeadingDetails from "./HeadingDetails";
import HeadingComponent from "./HeadingComponent";
import Slider from "./Slider";
import FooterComponent from "./FooterComponent";
import "./style/welcomepage-styles.css";
import styles from "./style/WelcomePage.module.css";

const WelcomePage = props => {
  const [headingSelected, setHeadingSelected] = useState(0);

  const heading1 = useRef(null);
  const heading2 = useRef(null);
  const heading3 = useRef(null);
  const pageTop = useRef(null);

  const backToTop = event => {
    event.preventDefault();
    pageTop.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (heading1 && heading2 && heading3) {
      switch (headingSelected) {
        case 1:
          heading1.current.scrollIntoView({ behavior: "smooth" });
          break;
        case 2:
          heading2.current.scrollIntoView({ behavior: "smooth" });
          break;
        case 3:
          heading3.current.scrollIntoView({ behavior: "smooth" });
          break;
        default:
          break;
      }
    }
  }, [headingSelected]);

  return (
    <div className={styles.wpcontainer}>
      <div ref={pageTop} />
      <Slider />
      <Container>
        <Segment vertical className={styles.wpbox_padding}>
          <HeadingComponent handleHeadingClicked={id => setHeadingSelected(id)} />
        </Segment>
        <Segment vertical className={styles.wpbox_padding}>
          <div ref={heading1}>
            <HeadingDetails textFirst={true} i={0} />
          </div>
        </Segment>
        <Segment vertical className={styles.wpbox_padding}>
          <Grid.Row>
            <Grid.Column width={"10"}>
              <div ref={heading2}>
                <HeadingDetails textFirst={false} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Segment>
        <Segment vertical className={styles.wpbox_padding}>
          <Grid.Row>
            <Grid.Column width={"10"}>
              <div ref={heading3}>
                <HeadingDetails textFirst={true} i={1} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Segment>
      </Container>
      <FooterComponent backToTop={backToTop} />
    </div>
  );
};

export default WelcomePage;
