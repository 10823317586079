import React from "react";
import { Menu, Grid, Icon, Label } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/BankAccountItem.module.css";

const BankAccountItem = props => {
  const {
    privileges,
    account,
    onEditItemClick,
    handlerItem,
    state,
    onListItemClick,
    onDeleteItemClick,
    onReactivateClick
  } = props;

  const { balance } = account;

  const colorBalanceLabel = balance > 0 ? `${styles.positive}` : `${styles.negative}`;
  const balanceLocal = balance > 0 ? "+" + balance : balance;

  const onEditItemClickLocal = event => {
    event.preventDefault();
    event.stopPropagation();
    onEditItemClick(event.currentTarget.attributes.itmid.value, account.paypalAccount);
  };

  const onDeleteItemClickLocal = event => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteItemClick(account);
  };

  const onRefreshItemClick = event => {
    event.preventDefault();
    event.stopPropagation();
    handlerItem.onRefreshAccountsClick(account);
  };

  const onReactivateAccount = event => {
    event.preventDefault();
    event.stopPropagation();
    onReactivateClick(account);
  };

  const activeInactiveLabel = () => {
    if (!account.active) {
      return (
        <Label basic className={styles.negative_active}>
          <Icon name="minus circle" />
          {Localize.get("ACCOUNTS.ITEMLIST.INACTIVE")}
        </Label>
      );
    }
    return (
      <Label basic className={styles.positive_active}>
        <Icon name="check circle outline" />
        {Localize.get("ACCOUNTS.ITEMLIST.ACTIVE")}
      </Label>
    );
  };

  return (
    <Menu.Item
      id={"ItemList_" + account.id}
      finapiid={account.finApiId}
      active={state.activeListItem === "ItemList_" + account.id ? true : null}
      onClick={onListItemClick}
    >
      <Grid columns="6" stackable container verticalAlign="middle">
        <Grid.Column width={2}>{activeInactiveLabel()}</Grid.Column>
        <Grid.Column width={2}>
          <span className={colorBalanceLabel}>
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR"
            }).format(balanceLocal)}
          </span>
        </Grid.Column>
        <Grid.Column width={4}>
          <div className="content">
            <div className={styles.list_name}>{account.accountOwner}</div>
            {!account.paypalAccount ? (
              <div className={styles.list_desc}>{account.bankName}</div>
            ) : null}
          </div>
        </Grid.Column>
        <Grid.Column width={4} className={styles.line_height}>
          {account.paypalAccount ? (
            <Icon name="cc paypal" className={styles.paypal_icon} />
          ) : (
            <>
              <div>IBAN: {account.iban}</div>
              <div>BIC: {account.bic}</div>
            </>
          )}
        </Grid.Column>
        <Grid.Column width={2}>
          <div className={styles.sales_assignment}>{account.numberOfUnlinkedTransactions}</div>
        </Grid.Column>
        <Grid.Column width={2}>
          <div className="content">
            <Icon
              title={
                account.paypalAccount
                  ? Localize.get("ACCOUNTS.EDITACCOUNT.EDIT_PAYPAL_ACCOUNT")
                  : Localize.get("ACCOUNTS.EDITACCOUNT.EDIT_BANK_ACCOUNT")
              }
              disabled={!privileges.update}
              name="edit outline"
              className={styles.edit_icon}
              itmid={"ItemList_" + account.id}
              onClick={onEditItemClickLocal}
            />
            {account.active ? (
              <Icon
                title={Localize.get("ACCOUNTS.EDITACCOUNT.DELETE_ACCOUNT")}
                name="trash alternate outline"
                className={styles.delete_icon}
                onClick={onDeleteItemClickLocal}
              />
            ) : (
              <Icon
                name="redo"
                title={Localize.get("ACCOUNTS.ITEMLIST.REACTIVATE")}
                onClick={onReactivateAccount}
              />
            )}
            <Icon
              title={Localize.get("ACCOUNTS.UPDATEACCOUNT.REFRESH_ACCOUNT")}
              className="sync"
              onClick={onRefreshItemClick}
            />
          </div>
        </Grid.Column>
      </Grid>
    </Menu.Item>
  );
};

export default BankAccountItem;
