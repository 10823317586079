import { useEffect } from "react";
import useFetch from "../RestServicesNew/useFetch";
import { fetchDocTypesService } from "../RestServicesNew/PartnersServices";

const useTypes = () => {
  const [documentTypes, , documentTypesLoading, fetchDocumentTypes] = useFetch(
    fetchDocTypesService,
    [],
    "/categories/doc"
  );

  const [emailTypes, , emailTypesLoading, fetchEmailTypes] = useFetch(
    fetchDocTypesService,
    [],
    "/categories/email"
  );

  useEffect(() => {
    fetchDocumentTypes();
    fetchEmailTypes();
  }, []);

  return {
    documentTypes,
    documentTypesLoading,
    fetchDocumentTypes,
    emailTypes,
    emailTypesLoading,
    fetchEmailTypes
  };
};

export default useTypes;
