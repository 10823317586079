import React from "react";
import { Grid, Segment, Image, Button } from "semantic-ui-react";
import financeImg from "../Assets/Images/finance.png";
import contactsImg from "../Assets/Images/contacts.png";
import documentsImg from "../Assets/Images/documents.png";
import Localize from "react-intl-universal";

const HeadingComponent = props => {
  const headingContentArr = {
    headingContent: [
      {
        id: 1,
        title: "WELCOME_PAGE.HEADING_CONTENT_TITLE1",
        artContent: "WELCOME_PAGE.HEADING_CONTENT_DESCRIPTION1",
        image: financeImg
      },
      {
        id: 2,
        title: "WELCOME_PAGE.HEADING_CONTENT_TITLE2",
        artContent: "WELCOME_PAGE.HEADING_CONTENT_DESCRIPTION2",
        image: contactsImg
      },
      {
        id: 3,
        title: "WELCOME_PAGE.HEADING_CONTENT_TITLE3",
        artContent: "WELCOME_PAGE.HEADING_CONTENT_DESCRIPTION3",
        image: documentsImg
      }
    ]
  };

  const content = (
    <React.Fragment>
      {headingContentArr.headingContent.map(heading => {
        return (
          <Grid.Column key={heading.id} className="container">
            <HeadingContent
              id={heading.id}
              title={heading.title}
              artContent={heading.artContent}
              image={heading.image}
              handleHeadingClicked={() => props.handleHeadingClicked(heading.id)}
            />
          </Grid.Column>
        );
      })}
    </React.Fragment>
  );
  return (
    <>
      <Grid stackable columns={3} padded centered stretched>
        {content}{" "}
      </Grid>
    </>
  );
};

const HeadingContent = props => {
  return (
    <>
      <Segment className="ui center aligned">
        <div>
          <Image className="ui icon header aligned" src={props.image} size="small" circular />
          <h1 className="ui header">{Localize.get(props.title)}</h1>
          <p>{Localize.get(props.artContent)}</p>
          <br />
          <Button className="ui linkedin button" onClick={props.handleHeadingClicked}>
            {Localize.get("WELCOME_PAGE.VIEW_DETAILS")} &raquo;
          </Button>
        </div>
      </Segment>
    </>
  );
};

export default HeadingComponent;
