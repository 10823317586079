import React, { useState, useEffect } from "react";
import { Segment, Label, Dropdown, Accordion, Icon } from "semantic-ui-react";
import ProjectDetailsContact from "./ProjectDetailsContact";
import * as constants from "../Constants";
import Localize from "react-intl-universal";
import styles from "./style/ProjectResourcesPartners.module.css";
import { Partner, Employee, Company, Person } from "../../../RestService";

const ProjectResourcesPartners = props => {
  const {
    activeMode,
    partners,
    role,
    handlePartnerSelection,
    setIncorectRoleIds,
    correctRoleMin,
    handlePartnerDelete,
    setEditedFields,
    editedFields,
    newDetailsFields
  } = props;

  const [allPartners, setAllPartners] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [currentTypes, setCurrentTypes] = useState("");
  const [searchedName, setSearchedName] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(false);
  const [loadingPartnerDropdown, setLoadingPartnerDropdown] = useState(false);

  const partnersTypesOptions = [
    {
      key: 0,
      text: Localize.get("PROJECTS.MANAGEMENT.PERSON"),
      value: constants.partnersTypes.person
    },
    {
      key: 1,
      text: Localize.get("PROJECTS.MANAGEMENT.COMPANY"),
      value: constants.partnersTypes.companies
    },
    {
      key: 2,
      text: Localize.get("PROJECTS.MANAGEMENT.EMPLOYEE"),
      value: constants.partnersTypes.employee
    }
  ];

  const resolvePartnerService = (partnerType, partnersName) => {
    let service = null;
    if (partnerType === undefined) {
      partnerType = constants.partnersTypes.all;
    }
    switch (partnerType) {
      case constants.partnersTypes.person:
        service = new Person.GetAll.Service();
        break;
      case constants.partnersTypes.companies:
        service = new Company.GetAll.Service();
        break;
      case constants.partnersTypes.employee:
        service = new Employee.GetAll.Service();
        break;
      case constants.partnersTypes.all:
        service = new Partner.GetAll.Service();
        break;
      default:
        break;
    }

    service.query.clearPageSize();
    service.query.addSearchNameParam(partnersName);
    service.setCallbackSuccess(callbackGetAllPartnersSuccess);
    service.setCallbackError(callbackGetAllPartnersError);
    service.setCallbackFinally(callbackGetAllPartnersFinally);
    return service;
  };

  // GetPartners
  const callbackGetAllPartnersSuccess = response => {
    setAllPartners(response.data.data);
    let newOption = response.data.data.filter((partnerItem, i) => {
      return partners.find(partner => String(partner.id.partner.id) === String(partnerItem.id))
        ? false
        : partnerItem;
    });
    let sortNewOption = newOption.sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    );
    setPartnerOptions(
      sortNewOption.map((partnerOption, index) => ({
        key: index,
        text: partnerOption.name,
        value: partnerOption.id
      }))
    );
  };

  const callbackGetAllPartnersError = error => {};
  const callbackGetAllPartnersFinally = response => {
    setLoadingPartnerDropdown(false);
  };

  const handleSelectPartner = (event, data) => {
    setIncorectRoleIds(false);
    setPartnerOptions(partnerOptions.filter(option => option.value !== data.value));
    const newSelectedPartner = allPartners.find(partnerItem => partnerItem.id === data.value);
    handlePartnerSelection(newSelectedPartner, role);
  };

  const handleOnDeletePersonClick = (id, partnersRoleId) => {
    let removedPartner = partners.find(partner => String(partner.id.partner.id) === String(id));
    let partnerDType = "";
    if (
      removedPartner &&
      removedPartner.id &&
      removedPartner.id.partner &&
      removedPartner.id.partner.dtype &&
      removedPartner.id.partner.dtype !== constants.partnersTypes.company
    ) {
      partnerDType = removedPartner.id.partner.dtype;
    } else {
      partnerDType = constants.partnersTypes.companies;
    }

    if (currentTypes === constants.partnersTypes.all || partnerDType === currentTypes) {
      setPartnerOptions([
        ...partnerOptions,
        {
          key: removedPartner.id.partner.id,
          text: removedPartner.id.partner.name,
          value: removedPartner.id.partner.id
        }
      ]);
    }

    handlePartnerDelete(id, partnersRoleId);

    if (!editedFields.includes("resources")) {
      const newArr = [...editedFields];
      newArr.push("resources");
      setEditedFields(newArr);
    }
  };

  let delayTimer;
  useEffect(() => {
    if (currentTypes !== "" && searchedName !== "") {
      let isSubscribed = true;
      delayTimer = setTimeout(function() {
        const service = resolvePartnerService(currentTypes, searchedName);
        if (isSubscribed) {
          service.call();
        }
      }, 800);

      return () => (isSubscribed = false);
    }
  }, [searchedName]);

  const handler = {
    handleSearchPartners: (e, data) => {
      clearTimeout(delayTimer);
      setLoadingPartnerDropdown(true);
      setSearchedName(data.searchQuery);
    },
    handleOnResourcesSelectPartnerType: async (e, data) => {
      setLoadingPartnerDropdown(true);
      const service = resolvePartnerService(data.value, searchedName);
      await service.call();
      setCurrentTypes(data.value);
    }
  };

  const partnersDetails = partners.map((partner, index) => (
    <ProjectDetailsContact
      key={index}
      index={index}
      partner={partner}
      partnersRole={partner.id.resourceRole}
      activeMode={activeMode}
      handleOnDeletePersonClick={handleOnDeletePersonClick}
      newDetailsFields={newDetailsFields}
    />
  ));

  return (
    <>
      <Accordion>
        {activeMode === constants.panelMode.details ? (
          <>
            {partnersDetails.length > 0 ? (
              <>
                <Accordion.Title
                  active={activeAccordion}
                  onClick={() => setActiveAccordion(!activeAccordion)}
                >
                  <Segment className={styles.resources_segment_details}>
                    <Icon name="dropdown" /> <strong>{role.id.resourceRole.name}</strong>
                    {role !== undefined && (
                      <>
                        <div className={styles.resources_segment_position}>
                          {Localize.get("PROJECTS.MANAGEMENT.MAX")}
                          <Label
                            circular
                            color="blue"
                            key="blue"
                            className={styles.resources_segment_label_max}
                          >
                            {role.max}
                          </Label>
                        </div>
                        <div className={styles.resources_segment_position}>
                          {Localize.get("PROJECTS.MANAGEMENT.MIN")}
                          <Label
                            circular
                            color="grey"
                            key="grey"
                            className={styles.resources_segment_label_min}
                          >
                            {role.min}
                          </Label>
                        </div>
                        <div className={styles.resources_segment_position}>
                          {Localize.get("PROJECTS.MANAGEMENT.SELECTED")}
                          <Label
                            circular
                            color="orange"
                            key="orange"
                            className={styles.resources_segment_label_min}
                          >
                            {partners.length}
                          </Label>
                        </div>
                      </>
                    )}
                  </Segment>
                </Accordion.Title>
              </>
            ) : (
              <>
                <div />
              </>
            )}
          </>
        ) : (
          <>
            <Accordion.Title>
              <Segment
                className={styles.resources_segment}
                style={
                  correctRoleMin.length > 0 && correctRoleMin.includes(role.id.resourceRole.id)
                    ? { border: "3px solid red", borderRadius: "0px" }
                    : null
                }
              >
                {" "}
                <strong>{role.id.resourceRole.name}</strong>
                {role !== undefined && (
                  <>
                    <div className={styles.resources_segment_position}>
                      {Localize.get("PROJECTS.MANAGEMENT.MAX")}
                      <Label
                        circular
                        color="blue"
                        key="blue"
                        className={styles.resources_segment_label_max}
                      >
                        {role.max}
                      </Label>
                    </div>
                    <div className={styles.resources_segment_position}>
                      {Localize.get("PROJECTS.MANAGEMENT.MIN")}
                      <Label
                        circular
                        color="grey"
                        key="grey"
                        className={styles.resources_segment_label_min}
                      >
                        {role.min}
                      </Label>
                    </div>
                    <div className={styles.resources_segment_position}>
                      {Localize.get("PROJECTS.MANAGEMENT.SELECTED")}
                      <Label
                        circular
                        color="orange"
                        key="orange"
                        className={styles.resources_segment_label_min}
                      >
                        {partners.length}
                      </Label>
                    </div>
                  </>
                )}
              </Segment>
              {correctRoleMin.length > 0 && correctRoleMin.includes(role.id.resourceRole.id) ? (
                <Label
                  basic
                  color="red"
                  pointing="above"
                  content={Localize.get("PROJECTS.MANAGEMENT.MINIMUM_REQUIRED")}
                  className={styles.zindex}
                />
              ) : (
                <div></div>
              )}
            </Accordion.Title>
          </>
        )}

        {activeMode !== constants.panelMode.details && partnersDetails.length < role.max && (
          <>
            <div>
              <Dropdown
                className={styles.partner_dropdown}
                index={role.id.resourceRole.id}
                placeholder={Localize.get("PROJECTS.MANAGEMENT.PARTNER_TYPE")}
                fluid
                selection
                options={partnersTypesOptions}
                value={currentTypes}
                onChange={handler.handleOnResourcesSelectPartnerType}
                selectOnBlur={false}
              />
              {currentTypes !== "" && (
                <>
                  <Dropdown
                    index={role.id.resourceRole.id}
                    placeholder={Localize.get("PROJECTS.MANAGEMENT.PARTNER_PROJECTS")}
                    loading={loadingPartnerDropdown}
                    className={`icon ${styles.search_dropdown}`}
                    icon="search"
                    button
                    floating
                    labeled
                    fluid
                    search
                    selectOnBlur={false}
                    autoComplete={constants.autoComplete.off}
                    onSearchChange={handler.handleSearchPartners}
                    options={partnerOptions}
                    onChange={handleSelectPartner}
                    defaultValue={searchedName}
                  />
                </>
              )}
            </div>
          </>
        )}
        {activeMode === constants.panelMode.details ? (
          <>
            <Accordion.Content className={styles.resources_partners} active={activeAccordion}>
              {partnersDetails}
            </Accordion.Content>
          </>
        ) : (
          <>
            <Accordion.Content className={styles.resources_partners_edit} active={true}>
              {partnersDetails}
            </Accordion.Content>
          </>
        )}
      </Accordion>
    </>
  );
};

export default ProjectResourcesPartners;
