import React, { useState } from "react";
import { Container, Label, Button, Input } from "semantic-ui-react";
import Localize from "react-intl-universal";
import LinkedInvoice from "./LinkedInvoice";
import * as constant from "./Constants.js";
import styles from "./style/ThumbPanel.module.css";
import GlobalList from "../../Components/Lists/GlobalList";

const ThumbPanel = props => {
  const { privileges, searchBy, invoices, state } = props;

  const { invoiceSearchPhrase } = state;

  const [selectedItem, setSelectedItem] = useState(null);
  const listPaginationCustomCSS = styles.documents_for_linking_list;

  // DIFFERENCE
  const selectedInvoices = invoices.filter(invoice => invoice.checked === true);

  // HANDLERS
  const itemHandler = (item, data) => {
    switch (data.name) {
      case "checkbox":
        props.handler.onInvoiceCheck(item.id);
        break;
      case "eye":
        props.handler.onPreviewIconClick(item);
        break;
      default:
        break;
    }
  };

  const handleLinkedInvoiceDetails = invoice => {
    props.handler.handleLinkedInvoiceLabelClicked(invoice);
  };

  const handleLinkWithoutInvoice = () => {
    props.handler.handleLinkWithoutInvoice(props.activeTransaction);
  };

  const handleLinkInvoices = () => {
    props.handler.handleLinkInvoices(props.activeTransaction, selectedInvoices);
  };

  const handleUnlinkAllInvoices = () => {
    props.handler.handleUnlinkAllInvoices(props.activeTransaction);
  };

  // THUMBS FOR LINKED INVOICES
  let linkedInvoicesThumbs;

  const getLinkedInvoicesThumbs = () => {
    let documents = [];

    for (let i = 0; i < props.activeTransaction.documents.length; i++) {
      if (props.activeTransaction.documents[i].id) {
        documents.push(
          <LinkedInvoice
            key={props.activeTransaction.documents[i].id}
            invoice={props.activeTransaction.documents[i]}
            handleInvoiceUnlinkClick={props.handler.handleInvoiceUnlinkClick}
            handleLinkedInvoiceDetails={handleLinkedInvoiceDetails}
          />
        );
      }
    }

    return documents;
  };

  if (
    props.activeTransaction !== undefined &&
    props.activeTransaction.documents != null &&
    (props.activeTransaction.assignmentType === constant.assignment.assigned ||
      props.activeTransaction.assignmentType === constant.assignment.notAssigned) &&
    props.activeTransaction.documents.length > 0
  ) {
    linkedInvoicesThumbs = getLinkedInvoicesThumbs();
  }
  if (
    props.activeTransaction !== undefined &&
    props.activeTransaction.assignmentType === constant.assignment.assignedWithoutDoc
  ) {
    linkedInvoicesThumbs = (
      <Label className={styles.linkedInvoicesThumbsLabel}>
        {Localize.get("TRANSACTIONS.THUMBPANEL.LINKED_WITHOUT_INVOICE")}
      </Label>
    );
  }
  if (
    props.activeTransaction !== undefined &&
    props.activeTransaction.assignmentType === constant.assignment.notAssigned &&
    props.activeTransaction.documents.length === 0
  ) {
    linkedInvoicesThumbs = (
      <Label className={styles.without_invoice}>
        {Localize.get("TRANSACTIONS.THUMBPANEL.NO_INVOICE_LINKED")}
      </Label>
    );
  }

  const invoiceList = (
    <GlobalList
      listState={invoices}
      availableItemFieldsForList={constant.invoicesListHeader}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      itemHandler={itemHandler}
      listPaginationCustomCSS={listPaginationCustomCSS}
    />
  );

  return (
    <>
      <div className="six wide column">
        <div className={styles.secondary_header}>
          <div className={`three ui buttons ${styles.three_btn}`}>
            <Button
              className="ui button blue"
              onClick={handleLinkWithoutInvoice}
              disabled={
                !privileges.update
                  ? true
                  : props.buttonsVisibleMode !== constant.assignment.notAssigned
                  ? true
                  : false
              }
            >
              {Localize.get("TRANSACTIONS.THUMBPANEL.BUTTON_WITHOUT_DOC")}
            </Button>
            <Button
              className="ui button green"
              onClick={handleLinkInvoices}
              disabled={
                !privileges.update
                  ? true
                  : props.buttonsVisibleMode === constant.assignment.assignedWithoutDoc
                  ? true
                  : false
              }
            >
              {Localize.get("TRANSACTIONS.THUMBPANEL.BUTTON_LINK")}
            </Button>
            <Button
              className={`ui button orange ${styles.last_btn}`}
              onClick={handleUnlinkAllInvoices}
              disabled={
                !privileges.update
                  ? true
                  : props.buttonsVisibleMode === constant.assignment.notAssigned
                  ? true
                  : false
              }
            >
              {Localize.get("TRANSACTIONS.THUMBPANEL.BUTTON_UNLINK_ALL")}
            </Button>
          </div>
        </div>
        <div className={styles.thumb_header}>
          <Container className={styles.container_style}>
            {linkedInvoicesThumbs !== undefined
              ? Array.isArray(linkedInvoicesThumbs) && (
                  <Label className={styles.linked_label}>
                    {Localize.get("TRANSACTIONS.THUMBPANEL.LINK_DOCUMENTS")}
                  </Label>
                )
              : null}
            {linkedInvoicesThumbs}
          </Container>
        </div>
        {props.buttonsVisibleMode !== constant.assignment.assignedWithoutDoc &&
          (invoices.length > 0 || searchBy || invoiceSearchPhrase !== "") && (
            <div className={`header ${styles.thumb_panel_search}`}>
              <div className={styles.searchByLabel}>
                {Localize.get("TRANSACTIONS.THUMBPANEL.SEARCH_BY_DOCUMENT")}
              </div>
              <div />
              <div className="ui action input">
                <Input
                  type="text"
                  className={styles.thumb_panel_search_input}
                  placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
                  value={invoiceSearchPhrase}
                  onChange={props.handler.filterInvoicesBySearchPhrase}
                  icon="search"
                />
              </div>
            </div>
          )}
        {invoiceList}
        <div className={styles.clear_div} />
      </div>
    </>
  );
};

export default ThumbPanel;
