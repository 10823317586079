import React, { useState } from "react";
import Localize from "react-intl-universal";
import { Button, Input, Dropdown, Divider, Modal, Grid, Label } from "semantic-ui-react";
import usePostOrPatch from "../RestServicesNew/usePostOrPatch";
import { patchService } from "../RestServicesNew/UserLoginServices";
import showNotification from "../Notifications/NotificationFunction";
import { availableLanguages } from "./Constants";
import styles from "./LoginForm.module.css";

const UserSettingsModal = ({
  showSettings,
  setShowSettings,
  currentUserId,
  currentUsername,
  currentLanguageId,
  dispatch,
  loadLocales
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(currentLanguageId);
  const [passwordObj, setPasswordObj] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const [errorFields, setErrorFields] = useState({});

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      ...param
    });
  };

  const cbPatchPwSuccess = response => {
    setPasswordObj({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    });
    showNotificationMessage({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PASSWORD_UPDATE"),
      type: "info",
      serverMessage: "Password changed successfully"
    });
  };

  const cbPatchPwError = err => {
    setErrorFields({
      ...errorFields,
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PASSWORD_UPDATE"),
      currentPassword: "Old password is incorrect"
    });
    showNotificationMessage({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PASSWORD_UPDATE"),
      type: "warning",
      serverMessage: err.message
    });
  };

  const [patchPasswordCall, patchPasswordLoading] = usePostOrPatch(
    patchService,
    cbPatchPwSuccess,
    cbPatchPwError
  );

  const cbPatchLangSuccess = response => {
    loadLocales(response.data.language);
    dispatch({
      type: "setLanguage",
      payload: response.data.language
    });
    showNotificationMessage({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.LANGUAGE_UPDATE"),
      type: "info",
      serverMessage: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.LANGUAGE_UPDATE_SUCCESS")
    });
  };

  const cbPatchLangError = err => {
    showNotificationMessage({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.LANGUAGE_UPDATE"),
      type: "warning",
      serverMessage: Localize.get("AUTHORIZATION.UNHANDLED_ERROR")
    });
  };

  const [patchLangCall, patchLangLoading] = usePostOrPatch(
    patchService,
    cbPatchLangSuccess,
    cbPatchLangError
  );

  const onSaveLanguageClicked = () => {
    if (currentLanguage !== currentLanguageId) {
      let myChanges = [{ op: "replace", path: "/language", value: { id: currentLanguage } }];
      patchLangCall(myChanges, `/users/${currentUserId}`);
    }
  };

  const onSavePasswordClicked = () => {
    let myChanges = {
      username: currentUsername,
      password: passwordObj.newPassword,
      oldPassword: passwordObj.currentPassword
    };
    if (checkFields(myChanges, passwordObj.confirmNewPassword)) {
      patchPasswordCall(myChanges, `/users/password`);
    }
  };

  const handleDropdownChange = (e, data) => {
    setCurrentLanguage(data.value);
  };
  const handleInputChange = (e, data) => {
    setErrorFields({});
    setPasswordObj({
      ...passwordObj,
      [data.name]: data.value
    });
  };

  const checkFields = (myCredentials, confirmation) => {
    if (myCredentials.password.length < 6 || confirmation.length < 6) {
      setErrorFields({
        ...errorFields,
        newPassword:
          myCredentials.password.length < 6 &&
          Localize.get("SETTINGS.ACTIVATE_ACCOUNT.EMPTY_PW_FIELD"),
        confirmNewPassword:
          confirmation.length < 6 && Localize.get("SETTINGS.ACTIVATE_ACCOUNT.EMPTY_PW_FIELD")
      });
      return false;
    } else {
      if (myCredentials.password === confirmation) {
        return true;
      } else {
        setErrorFields({
          ...errorFields,
          confirmNewPassword: Localize.get("SETTINGS.ACTIVATE_ACCOUNT.CONFIRMATION_PW_INCORRECT")
        });
        return false;
      }
    }
  };

  return (
    <Modal open={showSettings} size="tiny" closeIcon={true} onClose={() => setShowSettings(false)}>
      <Modal.Header>{Localize.get("SETTINGS.SIDEBAR_HEADERS.USER_SETTINGS")}</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <label>{Localize.get("SETTINGS.SIDEBAR_HEADERS.LANGUAGE_ONLY")}</label>
            </Grid.Column>
            <Grid.Column width={6}>
              <Dropdown
                options={availableLanguages}
                selection
                value={currentLanguage}
                placeholder={Localize.get("SETTINGS.SIDEBAR_HEADERS.SELECT_LANGUAGE")}
                onChange={handleDropdownChange}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                content={Localize.get("SETTINGS.SIDEBAR_HEADERS.CHANGE")}
                primary
                className={styles.change_lang_button}
                onClick={onSaveLanguageClicked}
                loading={patchLangLoading || patchPasswordLoading}
                floated="right"
              />
            </Grid.Column>
          </Grid.Row>
          <Divider className={styles.divider_margin} />
          <Grid.Row className={styles.row_padding_bottom}>
            <Grid.Column width={6}>
              <label>{Localize.get("SETTINGS.SIDEBAR_HEADERS.OLD_PASS")}</label>
            </Grid.Column>
            <Grid.Column width={10}>
              <Input
                name="currentPassword"
                className={styles.password_input}
                value={passwordObj.currentPassword}
                onChange={handleInputChange}
                type="password"
                placeholder={Localize.get("SETTINGS.SIDEBAR_HEADERS.ENTER_OLD_PASS")}
                style={
                  errorFields && errorFields.currentPassword
                    ? { border: "2px solid red", borderRadius: "5px" }
                    : null
                }
              />
              {errorFields && errorFields.currentPassword && (
                <Label pointing basic color="red">
                  {errorFields.currentPassword}
                </Label>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.row_padding_bottom}>
            <Grid.Column width={6}>
              <label>{Localize.get("SETTINGS.SIDEBAR_HEADERS.NEW_PASS")}</label>
            </Grid.Column>
            <Grid.Column width={10}>
              <Input
                className={styles.password_input}
                name="newPassword"
                type="password"
                value={passwordObj.newPassword}
                onChange={handleInputChange}
                placeholder={Localize.get("SETTINGS.SIDEBAR_HEADERS.ENTER_NEW_PASS")}
                style={
                  errorFields && errorFields.newPassword
                    ? { border: "2px solid red", borderRadius: "5px" }
                    : null
                }
              />
              {errorFields && errorFields.newPassword && (
                <Label pointing basic color="red">
                  {errorFields.newPassword}
                </Label>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.row_padding_bottom}>
            <Grid.Column width={6}>
              <label>{Localize.get("SETTINGS.SIDEBAR_HEADERS.CONFIRM_NEW_PASS")}</label>
            </Grid.Column>
            <Grid.Column width={10}>
              <Input
                className={styles.password_input}
                name="confirmNewPassword"
                value={passwordObj.confirmNewPassword}
                onChange={handleInputChange}
                type="password"
                placeholder={`${Localize.get("SETTINGS.SIDEBAR_HEADERS.CONFIRM_NEW_PASS")}...`}
                style={
                  errorFields && errorFields.confirmNewPassword
                    ? { border: "2px solid red", borderRadius: "5px" }
                    : null
                }
              />
              {errorFields && errorFields.confirmNewPassword && (
                <Label pointing basic color="red">
                  {errorFields.confirmNewPassword}
                </Label>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}></Grid.Column>
            <Grid.Column width={6}>
              <Button
                primary
                onClick={onSavePasswordClicked}
                loading={patchPasswordLoading || patchLangLoading}
                floated="right"
              >
                {Localize.get("SETTINGS.SIDEBAR_HEADERS.SAVE_NEW_PASS")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setShowSettings(false);
          }}
          loading={patchPasswordLoading || patchLangLoading}
        >
          {"OK"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserSettingsModal;
