import React from "react";
import { Label, FormField, TextArea } from "semantic-ui-react";
import styles from "./style/GlobalTextInputField.module.css";
import Localize from "react-intl-universal";

const GlobalTextArea = props => {
  const {
    labelName,
    inputValue = "",
    onInputChange = function() {},
    inputName,
    inputRows = 4,
    errorFields = {},
    isRequired = false
  } = props;

  return (
    <>
      <FormField required={isRequired}>
        {labelName && <label className={styles.required_label}>{Localize.get(labelName)}</label>}

        <TextArea
          name={inputName}
          value={inputValue}
          placeholder={Localize.get(labelName)}
          maxLength={255}
          autoComplete={"off"}
          onChange={(e, data) => onInputChange(e, data)}
          rows={inputRows}
          style={
            errorFields && errorFields[inputName]
              ? { border: "2px solid red", borderRadius: "5px" }
              : null
          }
        />
        <div>
          <p className={styles.float_charlength_right}>
            {inputValue === null ? 0 : inputValue.length}
            {"/255"}
          </p>

          {errorFields && errorFields[inputName] && (
            <Label pointing basic color="red">
              {errorFields[inputName]}
            </Label>
          )}
        </div>
      </FormField>
    </>
  );
};

export default GlobalTextArea;
