import React from "react";
import { Button, List, Grid, Message, Icon, Label, Segment, Popup } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DocumentThumb from "./DocumentFormComponents/DocumentThumb";
import styles from "./style/DocumentLinkThumbnailList.module.css";

const DocumentLinkThumbnailList = props => {
  const {
    thumb,
    handleChange,
    handleParentChange,
    handler,
    listName,
    documentLinkThumbnailDetails,
    currentDocumentType,
    currentDocumentId,
    emptyListMessage
  } = props;
  const addDocumentToLinked = data => {
    handleChange(data.target.name, "add");
  };
  const removeDocumentToLinked = data => {
    handleChange(data.target.name, "remove");
  };
  const removeParent = data => {
    handleParentChange(data.target.name);
  };

  return (
    <>
      <Segment
        className={styles.segment_list}
        color={listName === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED" ? "blue" : "grey"}
      >
        <Label color={listName === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED" ? "blue" : "grey"}>
          {Localize.get(listName)}
        </Label>

        <List className={styles.listScroll} divided>
          {thumb.length > 0 ? (
            <>
              <Grid columns="4" verticalAlign="middle" className={styles.headerBackground}>
                <Grid.Column width={5} className={styles.wrap_column}>
                  <strong>{Localize.get("DOCUMENTS.THUMBNAILS.DOCUMENT_NUMBER")}</strong>
                </Grid.Column>
                <Grid.Column width={5} className={styles.wrap_column}>
                  <strong> {Localize.get("DOCUMENTS.THUMBNAILS.TYPE")}</strong>
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid>

              {thumb.map(document => (
                <List.Item
                  className={`${styles.padding_l_r_zero} ${styles.border_style}`}
                  key={document.id}
                >
                  <Grid stackable container columns="4" verticalAlign="middle">
                    <Grid.Column className={styles.document_number_column} width={5}>
                      {document.isParent && document.isParent === true && (
                        <Popup
                          size="mini"
                          content={Localize.get("DOCUMENTS.THUMBNAILS.PARENT_DOCUMENT")}
                          trigger={
                            <Icon
                              className={styles.anchor_icon}
                              name="anchor"
                              size="small"
                              circular={true}
                            />
                          }
                        />
                      )}
                      {document.documentNumber}
                    </Grid.Column>
                    <Grid.Column className={styles.document_number_column} width={5}>
                      {Localize.get(
                        `DOCUMENTS.THUMBNAILS.DOCUMENT_TYPE_${document.documentType
                          .toString()
                          .toUpperCase()}`
                      )}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <DocumentThumb
                        document={document}
                        handler={handler}
                        listName={listName}
                        documentLinkThumbnailDetails={documentLinkThumbnailDetails}
                      />
                    </Grid.Column>
                    <Grid.Column
                      className={`${styles.padding_l_r_zero} ${styles.button_column_width}`}
                      width={3}
                    >
                      {listName === "DOCUMENTS.THUMBNAILS.DOCUMENT_LINKED" ? (
                        <>
                          {(currentDocumentType &&
                            currentDocumentType === "correction" &&
                            document.isParent &&
                            document.isParent === true) ||
                          (document.documentType === "correction" && !document.isParent) ? (
                            <Popup
                              size="mini"
                              content={Localize.get(
                                "DOCUMENTS.THUMBNAILS.CORRECTION_CANNOT_BE_UNLINKED"
                              )}
                              trigger={<Icon name="exclamation" size="small" circular={true} />}
                            />
                          ) : (
                            <Button
                              name={document.id}
                              onClick={document.isParent ? removeParent : removeDocumentToLinked}
                              className={styles.add_remove_button}
                              basic
                              content={Localize.get("DOCUMENTS.THUMBNAILS.REMOVE")}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {document.parentDocument &&
                          document.parentDocument.id !== currentDocumentId ? (
                            <Popup
                              size="mini"
                              content={Localize.get("DOCUMENTS.THUMBNAILS.DOCUMENT_HAS_PARENT")}
                              trigger={
                                <Icon fitted name="exclamation" size="small" circular={true} />
                              }
                            />
                          ) : (
                            <Button
                              name={document.id}
                              onClick={addDocumentToLinked}
                              className={styles.add_remove_button}
                              basic
                            >
                              {Localize.get("DOCUMENTS.THUMBNAILS.ADD")}
                            </Button>
                          )}
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </List.Item>
              ))}
            </>
          ) : (
            <Message className={styles.message_style} icon>
              <Icon name="info circle" className={styles.icon_size} />
              {emptyListMessage && Localize.get(emptyListMessage)}
            </Message>
          )}
        </List>
      </Segment>
    </>
  );
};

export default DocumentLinkThumbnailList;
