import * as constant from "./Constant";
import Localize from "react-intl-universal";

export default class Validator {
  constructor(config) {
    this.parameter = {};
    this.parameterList = [];
    this.configuration = config ? config : constant.DEFAULTCONFIGURATION;
    this.setFieldFilters();
  }

  setFieldFilters() {
    Reflect.ownKeys(this.configuration).map(function iterator(field) {
      return (this.parameter[field] = "");
    }, this);
  }

  setConfiguration = config => {
    this.configuration = config;
  };

  addErrorMsgParam = (serverResponse, entity) => {
    if (!serverResponse.response.data.violations) {
      return this;
    }
    const fieldNameForUI = this.checkFieldNameAlligment(
      serverResponse.response.data.violations[0].fieldName,
      entity
    );
    this.parameter[fieldNameForUI] = serverResponse.response.data.violations[0].message;
    return this;
  };

  addNestedErrorMsgParam = (serverResponse, mainObject) => {
    if (!serverResponse.response.data.violations) {
      return this;
    }
    let property = "";
    if (serverResponse.response.data.violations[0].fieldName.includes(mainObject)) {
      const properties = serverResponse.response.data.violations[0].fieldName.split(".");
      property = properties[1];
    }
    this.parameter[property] = serverResponse.response.data.violations[0].message;
    return this;
  };

  checkFieldNameAlligment = (fieldName, entity) => {
    let fieldNameForUI = fieldName;
    switch (entity) {
      case "company":
        if (fieldName === "companyName") {
          fieldNameForUI = "name";
        }
        if (fieldName === "web") {
          fieldNameForUI = "url";
        }
        if (fieldName === "contacts") {
          fieldNameForUI = "contactPersons";
        }
        break;
      case "person":
        if (fieldName === "web") {
          fieldNameForUI = "url";
        }
        if (fieldName === "first") {
          fieldNameForUI = "firstName";
        }
        if (fieldName === "last") {
          fieldNameForUI = "lastName";
        }
        break;

      default:
        break;
    }

    return fieldNameForUI;
  };

  validateField = (value, field) => {
    this.configuration[field].every(function(filter, index, array) {
      let valid = constant.filterStrings[filter].test(value);

      if (filter.includes("whitespace")) {
        valid = !valid;
      }

      if (!valid && value) {
        if (index === 0 && filter.includes("whitespace")) {
          this.parameter[field] = "";
        }
        this.parameter[field] = Localize.get(constant.filterMessages[filter]);
        return false;
      } else {
        this.parameter[field] = "";
        return true;
      }
    }, this);
    return this;
  };

  getValidatedFields = () => {
    const fields = Reflect.ownKeys(this.parameter);
    return fields.every(function(currentFieldValidation) {
      return this.parameter[currentFieldValidation].length === 0;
    }, this);
  };

  validateDocumentForm = (constantFields, item) => {
    const fields = Reflect.ownKeys(constantFields);
    let allThere = true;

    let tempObj = {};
    fields.forEach(constantField => {
      constantFields[constantField].forEach(regexFilter => {
        let valid = constant.filterStrings[regexFilter].test(
          item[constantField] === null || item[constantField] === undefined
            ? ""
            : item[constantField]
        );

        if (regexFilter.includes("whitespace")) {
          valid = !valid;
        }
        if ((valid && !tempObj[constantField]) || (valid && tempObj[constantField] === "")) {
          tempObj[constantField] = "";
        } else {
          if (tempObj[constantField] === "" || !tempObj[constantField]) {
            tempObj[constantField] = Localize.get(constant.filterMessages[regexFilter]);
            allThere = false;
          }
        }
      }, this);
    }, this);

    this.parameter = tempObj;

    return allThere;
  };

  validateForm = (requiredFields, formData) => {
    const fields = Reflect.ownKeys(requiredFields);
    let allThere = true;
    if (formData) {
      fields.forEach(function(filter, index, array) {
        if (!formData[filter]) {
          const reqDataString = Localize.get(constant.filterMessages["requiredFilter"]);
          this.parameter[filter] = "";
          this.parameter[filter] += reqDataString;
        }
        const exist = formData[filter] ? true : false;

        allThere = allThere && (exist ? formData[filter].toString().length > 0 : false);
      }, this);
    } else {
      fields.forEach(function(filter, index, array) {
        this.parameter[filter] = Localize.get(constant.filterMessages["requiredFilter"]);
      }, this);
      allThere = false;
    }

    return allThere;
  };

  validateOnSave = (requiredFields, formData) => {
    return this.validateForm(requiredFields, formData) && this.getValidatedFields();
  };

  validateFieldOfItem = (field, value, index) => {
    let tempValue = "";
    // eslint-disable-next-line array-callback-return
    this.configuration[field].forEach(function(filter) {
      let valid = constant.filterStrings[filter].test(value);

      if (filter.includes("whitespace")) {
        valid = !valid;
      }

      if (!valid) {
        tempValue = Localize.get(constant.filterMessages[filter]);
        this.parameterList[index][field] = tempValue;
      } else {
        if (tempValue === "") {
          this.parameterList[index][field] = "";
        }
      }
    }, this);

    return this;
  };

  validateOnUpload = (constantFields, providedData = [], ignoreWhenAllAreEmptyStrings = false) => {
    const fields = Reflect.ownKeys(constantFields);
    let allThere = true;
    let tempListOfValidation = providedData.map(item => {
      if (ignoreWhenAllAreEmptyStrings) {
        let checkObjValNotEmpty = obj => {
          return Object.keys(obj).every(k => obj[k] === "");
        };
        let areAllEmtyString = checkObjValNotEmpty(item);
        if (areAllEmtyString) {
          return item;
        }
      }
      let tempObj = {};
      fields.forEach(constantField => {
        constantFields[constantField].forEach(regexFilter => {
          let valid = constant.filterStrings[regexFilter].test(
            constantField !== "min1Character" &&
              (item[constantField] === null || item[constantField] === undefined)
              ? ""
              : item[constantField]
          );

          if (regexFilter.includes("whitespace")) {
            valid = !valid;
          }

          if ((valid && !tempObj[constantField]) || (valid && tempObj[constantField] === "")) {
            tempObj[constantField] = "";
          } else {
            if (tempObj[constantField] === "" || !tempObj[constantField]) {
              tempObj[constantField] = Localize.get(constant.filterMessages[regexFilter]);
              allThere = false;
            }
          }
        }, this);
      }, this);

      return tempObj;
    }, this);

    this.parameterList = tempListOfValidation;
    return allThere;
  };

  clearMessages = () => {
    const fields = Reflect.ownKeys(this.configuration);

    fields.forEach(function(filter, index, array) {
      this.parameter[filter] = "";
    }, this);
  };

  clearUploadMessage = () => {
    const itemList = this.parameterList;
    itemList.forEach((item, index) => {
      Object.keys(item).forEach(field => {
        item[field] = "";
        this.parameterList[index] = item;
      }, this);
      return item;
    }, this);
  };

  isThereValidation = () => {
    return !this.isObjectEmpty(this.parameter);
  };

  isObjectEmpty = data => {
    const fields = Reflect.ownKeys(data);

    if (fields.length === 0) {
      return true;
    } else {
      return Object.values(data).every(
        field => field === "" || field === null || field === undefined
      );
    }
  };

  isMandatoryFieldValidation = validatedField => {
    // determines if validation on field is value from required filter
    return (
      this.parameter[validatedField] === Localize.get(constant.filterMessages["requiredFilter"])
    );
  };

  isValidNumber = number => {
    let valid = constant.filterStrings["numberFilter"].test(number);

    return valid;
  };

  compareNumbers = (validatedField, number1, number2) => {
    let valid = number1 > number2;
    if (valid) {
      return (this.parameter[validatedField] = Localize.get(
        constant.filterMessages["amountFilter"]
      ));
    }
  };
}
