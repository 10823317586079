import { useState } from "react";
import Localize from "react-intl-universal";
import usePostOrPatch from "./usePostOrPatch";
import useFetch from "./useFetch.js";
import showNotification from "../Notifications/NotificationFunction";
import {
  postDocumentByPathAndParams,
  downloadImportedById,
  sendMail,
  getByPathAndParams,
  postDocumentByPath
} from "./DocumentsServices";
import { get } from "lodash";
import * as notificationConstants from "../Notifications/Constants.js";
import * as constants from "../Components/Constants";

const useDocumentsServices = (
  fetchData,
  setDocuments,
  documentsPanelState,
  setLinkedDocumentsPreview,
  selectedDocument,
  togglePreview
) => {
  const [respMessage, setRespMessage] = useState(200);
  const [downloadAndSendResponse, setDownloadAndSendResponse] = useState(false);

  const callbackPostSuccess = (response, saveAndNew = false) => {
    setRespMessage(response);
    fetchData(0);
  };
  const callbackPostError = (err, postOrPatchMethod) => {
    setRespMessage(err.response.data);
  };

  const [postDocumentCall, postLoading] = usePostOrPatch(
    postDocumentByPathAndParams,
    callbackPostSuccess,
    callbackPostError
  );

  const callbackGenerateAndCombineSuccess = response => {
    setRespMessage(response);
    fetchData(0);
    showNotification({
      type: notificationConstants.notificationType.info,
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PROCESSED")
    });
  };
  const callbackGenerateAndCombineError = err => {
    setRespMessage(err.response.data);
    showNotification({
      type: notificationConstants.notificationType.warning,
      serverMessage: err.response.data.message
    });
  };

  const [postGenerateAndCombine, postGenerateAndCombineLoading] = usePostOrPatch(
    postDocumentByPath,
    callbackGenerateAndCombineSuccess,
    callbackGenerateAndCombineError
  );

  const callbackSyncFFOSuccess = async response => {
    await fetchData(0);
    showNotification({
      type: constants.notificationType.info,
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.SYNC")
    });
  };

  const callbackSyncFFOError = err => {
    showNotification({
      type: notificationConstants.notificationType.warning,
      entity: get(err, "message")
        ? get(err, "message")
        : Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PREVIEW_ERR")
    });
  };

  const [syncFFO, syncFFOLoading] = usePostOrPatch(
    postDocumentByPathAndParams,
    callbackSyncFFOSuccess,
    callbackSyncFFOError
  );

  const callBackDownloadImportedSuccess = response => {
    const disposition = response.headers["content-disposition"];
    const fileName = disposition.split("filename=")[1];

    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    fetchData(0);
  };

  const callBackDownloadImportedError = err => {
    setDownloadAndSendResponse(get(err, "response"));
  };

  const callBackSendSuccess = response => {
    setDownloadAndSendResponse(response);
    fetchData(0);
  };

  const callBackSendError = err => {
    setDownloadAndSendResponse(err.response);
  };

  const [postDownloadImported, downloadLoading] = usePostOrPatch(
    downloadImportedById,
    callBackDownloadImportedSuccess,
    callBackDownloadImportedError
  );

  const [postSendMail, sendLoading] = usePostOrPatch(
    sendMail,
    callBackSendSuccess,
    callBackSendError
  );

  const callbackPreviewSuccess = response => {
    const urlCreator = window.URL || window.webkitURL;
    const file = new Blob([response.data], {
      type: "application/pdf"
    });

    setLinkedDocumentsPreview({
      isPDF: true,
      data: urlCreator.createObjectURL(file)
    });
  };

  const callbackPreviewError = err => {
    togglePreview();
    showNotification({
      type: notificationConstants.notificationType.warning,
      entity: get(err, "message")
        ? get(err, "message")
        : Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PREVIEW_ERR")
    });
  };

  const [, , isPreviewLoading, getImportedDocPreview] = useFetch(
    getByPathAndParams,
    undefined,
    `/documents/${get(selectedDocument, "id")}/preview`,
    undefined,
    callbackPreviewSuccess,
    callbackPreviewError,
    "blob"
  );

  return {
    postDocumentCall,
    postLoading,
    respMessage,
    postDownloadImported,
    downloadLoading,
    downloadAndSendResponse,
    postSendMail,
    sendLoading,
    isPreviewLoading,
    getImportedDocPreview,
    syncFFO,
    syncFFOLoading,
    postGenerateAndCombine,
    postGenerateAndCombineLoading
  };
};

export default useDocumentsServices;
