import React from "react";
import Localize from "react-intl-universal";
// import * as constant from '../Constants.js';
import styles from "./style/MiddlePanel.module.css";

const DocumentNotSelected = props => {
  return (
    <>
      <div className={styles.bms_header}>
        <div className="links">{Localize.get("DOCUMENTS.BREADCRUMB.PROCESS_NEW_DOCUMENT")}</div>
      </div>

      <div className={styles.bms_doc_not_selected}>
        <p className={styles.document_not_selected_paragraf}>
          {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY")}
          <br />
          {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY_FOR_PROCESSING")}
        </p>
      </div>
    </>
  );
};

export default DocumentNotSelected;
