import React, { useState } from "react";
import { Icon, Divider, Accordion, Segment, Grid, Message } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DetailsAccordion from "./DetailsAccordion";
import styles from "./style/ProjectTemplateDetails.module.css";

const ProjectTemplateDetails = props => {
  const template = props.state.template;
  const { privileges } = props;

  const [showTemplateNote, setShowTemplateNote] = useState(false);
  const [showTemplateTypeNote, setShowTemplateTypeNote] = useState(false);

  const getAccordion = () =>
    props.state.template.roles.map((role, index) => (
      <DetailsAccordion key={role.resourceRole.id} index={index} role={role} />
    ));

  const handleOnDeleteItemClick = () => {
    props.handle.onDeleteItemClick(template);
  };

  const handleOnEditItemClick = () => {
    props.handle.onEditClick(template);
  };

  const handleOnArchiveClick = () => {
    props.handle.onArchiveClick(template);
  };

  const displayTemplateNote = () => {
    setShowTemplateNote(!showTemplateNote);
  };

  const displayTemplateTypeNote = () => {
    setShowTemplateTypeNote(!showTemplateTypeNote);
  };

  let render;

  if (props.state.template !== undefined) {
    render = (
      <>
        <div className={`column items_list ${styles.right_panel}`}>
          <div className={styles.header}>
            <span>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DETAILS")}</span>
            <Icon
              disabled={!privileges.delete}
              name="trash alternate"
              className={`${styles.delete_and_archive_icons} ${styles.trash}`}
              onClick={handleOnDeleteItemClick}
              title={Localize.get("PROJECTS.TEMPLATES.DELETE_TEMPLATE")}
            />
            <Icon.Group
              className={styles.project_details_header_icon}
              onClick={handleOnArchiveClick}
              disabled={!privileges.update}
            >
              <Icon name="archive" />
              <Icon corner name={template.archived === true ? "minus circle" : "plus circle"} />
            </Icon.Group>
            <Icon
              disabled={!privileges.update}
              name="edit"
              className={`${styles.delete_and_archive_icons} ${styles.edit}`}
              onClick={handleOnEditItemClick}
              title={Localize.get("PROJECTS.TEMPLATES.EDIT_TEMPLATE")}
            />
          </div>
          <div className={styles.company_details_container}>
            {/* Name */}

            <Grid container divided="vertically">
              <Grid.Row>
                <Grid.Column width={4}>
                  <strong>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_NAME_PLACEHOLDER")}</strong>
                </Grid.Column>
                <Grid.Column className={styles.wrap_column} width={12}>
                  {template.name}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {template && template.description ? (
              <>
                <div className={styles.note_details}>
                  <div className={styles.header_notes}>
                    <span className={styles.header_title}>
                      {Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DESCRIPTION_PLACEHOLDER")}
                    </span>
                    <div className={styles.header_icon}>
                      {!showTemplateNote ? (
                        <Icon
                          name="sticky note outline"
                          size="large"
                          onClick={displayTemplateNote}
                        />
                      ) : (
                        <Icon name="sticky note" size="large" onClick={displayTemplateNote} />
                      )}
                    </div>
                  </div>
                  <div>
                    {showTemplateNote ? (
                      <div className="item">
                        <div className="content">
                          <div className={styles.description_of_issue}>{template.description}</div>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div></div>
            )}
            <Divider />
            <Segment.Group id="templateDetailsPanel">
              <Segment className={styles.type_segment}>
                <div>
                  {" "}
                  <strong>{Localize.get("PROJECTS.TEMPLATES.PROJECT_TYPE")}</strong>
                </div>
              </Segment>
              <Segment>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column className={styles.type_row} width={4}>
                      <strong>{Localize.get("CONTACTS.DETAILS.FORM.NAME")}</strong>
                    </Grid.Column>
                    <Grid.Column className={styles.wrap_column} width={12}>
                      {template && template.projectType.name
                        ? template.projectType.name
                        : undefined}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    {template.projectType && template.projectType.description ? (
                      <div className={styles.note_details}>
                        <div className={styles.header_notes}>
                          <span className={styles.header_title}>
                            {Localize.get("PROJECTS.MANAGEMENT.PROJECT_DESCRIPTION")}
                          </span>
                          <div className={styles.header_icon}>
                            {!showTemplateTypeNote ? (
                              <Icon
                                name="sticky note outline"
                                size="large"
                                onClick={displayTemplateTypeNote}
                              />
                            ) : (
                              <Icon
                                name="sticky note"
                                size="large"
                                onClick={displayTemplateTypeNote}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          {showTemplateTypeNote ? (
                            <div className="item">
                              <div className="content">
                                <div className={styles.description_of_issue}>
                                  {template.projectType.description}
                                </div>
                              </div>
                              <Divider />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
            {props.state.template &&
            props.state.template.roles &&
            props.state.template.roles.length > 0 ? (
              <Segment.Group>
                <Segment className={styles.role_segment}>
                  <div>
                    {" "}
                    <strong>{Localize.get("PROJECTS.TEMPLATES.PROJECT_ROLES")} </strong>
                  </div>
                </Segment>
                <Segment>
                  <Accordion id="templateDetails" className={styles.full_width} styled>
                    {getAccordion()}
                  </Accordion>
                </Segment>
              </Segment.Group>
            ) : (
              <div />
            )}
          </div>
        </div>
      </>
    );
  } else {
    render = (
      <>
        <div className={`column items_list ${styles.right_panel}`}>
          <div className={styles.header}>
            <span>{Localize.get("PROJECTS.TEMPLATES.TEMPLATE_DETAILS")}</span>
          </div>
          <Message icon>
            <Icon name="info circle" />
            {Localize.get("PROJECTS.TEMPLATES.EMPTY_DETAILS")}
          </Message>
        </div>
      </>
    );
  }
  return <>{render}</>;
};

export default ProjectTemplateDetails;
