import React from "react";
import { Modal, Menu, Checkbox } from "semantic-ui-react";
import styles from "./style/HeaderSettingsModal.module.css";
import Localize from "react-intl-universal";
import GlobalOkButton from "../GlobalOkBtn";

const HeaderSettingsModal = props => {
  const {
    settingModal = false,
    availableItemFieldsForList = [],
    setFieldsForList = function() {},
    onOkSettingsClicked
  } = props;

  const onCheckboxClick = (e, data) => {
    setFieldsForList(prevState => {
      return prevState.map(item => {
        if (item.name === data.name) {
          item.visible = data.checked;
        }
        return item;
      });
    });
  };

  return (
    <Modal
      size="tiny"
      dimmer="inverted"
      open={settingModal}
      closeOnEscape={true}
      closeOnDimmerClick={false}
    >
      <Modal.Header className={styles.modals_header}>
        {Localize.get("GLOBAL.HEADERS.SET_VISIBLE_COLUMNS")}
      </Modal.Header>

      <Modal.Content className={styles.modals_content}>
        {availableItemFieldsForList.map((item, index) => {
          return (
            <Menu.Item key={index} className={styles.modals_item}>
              <Checkbox
                label={
                  item.hederName
                    ? Localize.get(item.hederName)
                    : item.visibleName
                    ? Localize.get(item.visibleName)
                    : Localize.get("GLOBAL.HEADERS.COLUMN_NAME_UNDEFINED")
                }
                name={item.name}
                disabled={item.visibleSetDisabled}
                checked={item.visible}
                onChange={onCheckboxClick}
              />
            </Menu.Item>
          );
        })}
      </Modal.Content>

      <Modal.Actions>
        <GlobalOkButton onOkClicked={onOkSettingsClicked} />
      </Modal.Actions>
    </Modal>
  );
};

export default HeaderSettingsModal;
