import React from "react";

import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";
import Localize from "react-intl-universal";
import { Message } from "semantic-ui-react";

const DunningDetails = props => {
  const { customProps, availableItemFieldsForDetails } = props;

  const { selectedDunning } = customProps;

  const findProperty = (propertyName, mySelectedProperties) => {
    let tempItem = mySelectedProperties.find(item => item.name === propertyName);
    return tempItem ? tempItem.value : "";
  };

  const getPropertiesDetails = (listOfProperties, mySelectedProperties) => {
    if (listOfProperties.length === 0) {
      return <Message>{Localize.get("PROPERTIES.NO_PROPERTIES")}</Message>;
    }
    let days = 0;
    const getTotalDays = day => {
      days = parseInt(day) + days;
      return days;
    };
    return listOfProperties.map((field, index) => {
      const property = findProperty(field.name, mySelectedProperties);

      return (
        <GlobalDetailsItem
          key={index}
          itemName={field.hederName}
          type={field.type}
          itemValue={property}
          totalDays={field.type === "input" ? getTotalDays(property) : ""}
        />
      );
    });
  };

  return (
    selectedDunning &&
    Array.isArray(selectedDunning) &&
    getPropertiesDetails(availableItemFieldsForDetails, selectedDunning)
  );
};

export default DunningDetails;
