import React from "react";
import Localize from "react-intl-universal";
import { Modal } from "semantic-ui-react";

import GlobalOkCancelBtn from "../../../Components/GlobalOkCancelBtn";

const ChangePropertiesWarningModal = props => {
  return (
    <Modal open={props.showModal} size="small" closeOnDocumentClick dimmer="blurring">
      <Modal.Header>{Localize.get("DOCUMENTS.MODAL.MODAL_WARNING")}</Modal.Header>

      <Modal.Content>
        <Modal.Description>{props.warningMessage}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <GlobalOkCancelBtn
          onConfirmClicked={props.confirmChangeHandler}
          onCancel={props.handleCloseModal}
          btnText={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ChangePropertiesWarningModal;
