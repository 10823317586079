import React from "react";
import { Button, Popup } from "semantic-ui-react";
import propTypes from "prop-types";
import Localize from "react-intl-universal";
import styles from "./style/GlobalStatusActionBtn.module.css";

const GlobalStatusActionBtn = props => {
  const {
    approve,
    reset,
    popupApproveHeaderText,
    popupApproveContentText,
    popupResetContentText,
    disabledApprove,
    disabledDecline,
    disabledReset,
    statusLoading,
    onApproveClick = function(arg) {},
    onDeclineClick = function(arg) {},
    onResetClick = function(arg) {}
  } = props;

  return (
    <>
      {approve ? (
        <>
          <Popup
            header={popupApproveHeaderText ? popupApproveHeaderText : null}
            content={popupApproveContentText}
            disabled={disabledApprove}
            trigger={
              <Button
                size="mini"
                positive
                basic
                className={
                  !disabledApprove
                    ? `${styles.custom_disabled} ${styles.allButtons}`
                    : styles.allButtons
                }
                onClick={!disabledApprove ? null : onApproveClick}
                loading={statusLoading}
              >
                {Localize.get("DOCUMENTS.DETAILS.BUTTON_APPROVE")}
              </Button>
            }
          />

          <Button
            disabled={disabledDecline}
            negative
            className={styles.allButtons}
            size="mini"
            basic
            onClick={onDeclineClick}
            loading={statusLoading}
          >
            {Localize.get("DOCUMENTS.DETAILS.BUTTON_DECLINE")}
          </Button>
        </>
      ) : (
        reset && (
          <>
            <Popup
              content={popupResetContentText}
              disabled={disabledReset}
              trigger={
                <Button
                  className={
                    !disabledReset
                      ? `${styles.custom_disabled} ${styles.allButtons}`
                      : styles.allButtons
                  }
                  onClick={!disabledReset ? null : onResetClick}
                  size="mini"
                  basic
                  loading={statusLoading}
                >
                  {Localize.get("DOCUMENTS.DETAILS.BUTTON_RESET")}
                </Button>
              }
            />
          </>
        )
      )}
    </>
  );
};

GlobalStatusActionBtn.prototype = {
  approve: propTypes.bool,
  reset: propTypes.bool,
  popupApproveHeaderText: propTypes.string,
  popupApproveContentText: propTypes.string,
  popupResetContentText: propTypes.string,
  disabledApprove: propTypes.bool,
  disabledDecline: propTypes.bool,
  disabledReset: propTypes.bool,
  statusLoading: propTypes.bool,
  onApproveClick: propTypes.func,
  onDeclineClick: propTypes.func,
  onResetClick: propTypes.func
};
export default GlobalStatusActionBtn;
