import React from "react";
import { FormField, Form, Checkbox } from "semantic-ui-react";
import styles from "../style/Properties.module.css";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";
import Localize from "react-intl-universal";

const PropertiesForm = props => {
  const {
    currentItem,
    setCurrentItem,
    setEditedFields,
    editedFields,
    parameters,
    availableItemFieldsForDetails
  } = props;

  const findProperty = propertyName => {
    let tempItem = currentItem.find(item => item.name === propertyName);
    return tempItem ? tempItem.value : "";
  };

  const changeInputField = (e, data) => {
    let editedItemId;
    let tempItem = currentItem.map(item => {
      if (item.name === data.name) {
        editedItemId = item.id;
        return {
          ...item,
          value: data.type === "text" || data.type === "password" ? data.value : data.checked
        };
      }
      return item;
    });

    if (!editedFields.includes(editedItemId)) {
      let tempArray = editedFields;
      tempArray.push(editedItemId);
      setEditedFields(tempArray);
    }
    setCurrentItem(tempItem);
  };

  const getProperties = () => {
    return availableItemFieldsForDetails.map((field, index) => {
      let propertyValue = findProperty(field.name);
      if (field.type === "boolean") {
        return (
          <FormField key={index} required={false}>
            <label>{Localize.get(field.hederName)}</label>
            <Checkbox
              name={field.name}
              value={propertyValue}
              checked={propertyValue === "true" || propertyValue === true}
              toggle
              onChange={changeInputField}
            />
          </FormField>
        );
      } else {
        return (
          <GlobalTextInputField
            key={index}
            labelName={field.hederName}
            inputName={field.name}
            itemValue={propertyValue}
            isRequired={field.isRequired}
            onInputChange={changeInputField}
            errorFields={parameters}
            inputDisabled={field.inputDisabled}
            inputType={field.type}
          />
        );
      }
    });
  };

  return (
    <>
      <div className={styles.form_container}>
        <Form>
          <FormField required>{getProperties()}</FormField>
        </Form>
      </div>
    </>
  );
};

export default PropertiesForm;
