import React, { useEffect, useState } from "react";
import { FormField, Form, Divider, Button, Icon } from "semantic-ui-react";
import styles from "../style/UsersDetails.module.css";
import GlobalList from "../../Components/Lists/GlobalList";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";
import GlobalAccordition from "../../Components/GlobalAccordition";
import {
  itemsGlobalListHandler,
  createGetAllService,
  createPostService
} from "../../Components/Utils/GlobalUtil";
import {
  removeButtonFieldConfig,
  addButtonFieldConfig,
  assignedRolesName,
  unassingedRolesName
} from "./UserConfig";
import showNotification from "../../Notifications/NotificationFunction";

const UsersForm = props => {
  const {
    currentItem,
    setCurrentItem,
    availableItemFieldsForDetails,
    setEditedFields,
    customOnChangeValidation,
    editedFields,
    currentView,
    parameters
  } = props;

  const [roleList, setRoleList] = useState([]);
  const [activePaginationPage, setActivePaginationPage] = useState(1);
  const [paginationStatus, setPaginationStatus] = useState({
    perPage: 0,
    pageCount: 0,
    totalCount: 0
  });
  const [loadingLocaly, setLoadingLocaly] = useState(false);
  const [dimmerActive, setDimmerActive] = useState(false);

  const onInputChange = data => {
    let currentFieldName = data.target.name;
    setCurrentItem({ ...currentItem, [currentFieldName]: data.target.value });
    if (!editedFields.includes(currentFieldName)) {
      let tempArray = editedFields;
      tempArray.push(currentFieldName);
      setEditedFields(tempArray);
    }
    customOnChangeValidation(currentFieldName, data.target.value);
  };

  const itemHandler = (clickedItem, data) => {
    itemsGlobalListHandler(
      clickedItem,
      data,
      undefined,
      editedFields,
      setEditedFields,
      currentItem,
      setCurrentItem,
      roleList,
      setRoleList,
      "appRoles"
    );
  };

  const callBackGetAllSuccess = response => {
    // eslint-disable-next-line array-callback-return
    let tempArr = response.data.data.filter(item => {
      let compare = currentItem.appRoles.filter(role => role.id === item.id);
      if (compare.length === 0) {
        return item;
      }
    });

    setRoleList(tempArr);
    setPaginationStatus({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });
  };
  const callBackGetAllError = response => {};
  const callBackGetAllFinally = response => {
    setLoadingLocaly(false);
  };

  useEffect(() => {
    if (currentView === "edit" || currentView === "create") {
      let newService = createGetAllService(
        callBackGetAllSuccess,
        callBackGetAllError,
        callBackGetAllFinally,
        "roles"
      );

      newService.query.setPage(activePaginationPage - 1);
      setLoadingLocaly(true);
      newService.call();
    }
  }, [currentView, activePaginationPage]);

  const callBackGetCreateSuccess = () => {
    showNotification({
      entity: "users",
      type: "info",
      custom: "MAIL_SENT"
    });
  };
  const callBackGetCreateError = () => {};
  const callBackGetCreateFinally = () => {
    setDimmerActive(false);
  };

  const sendEmail = async () => {
    let service = createPostService(
      {},
      callBackGetCreateSuccess,
      callBackGetCreateError,
      callBackGetCreateFinally,
      `users/resetPassword/${currentItem.username}`,
      "send",
      true
    );
    setDimmerActive(true);
    await service.call();
  };

  const getCustomContent = () => {
    return (
      <Button
        disabled={dimmerActive}
        loading={dimmerActive}
        icon
        labelPosition="right"
        primary
        onClick={sendEmail}
      >
        Send email
        <Icon name="mail" />
      </Button>
    );
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo);
  };

  const getInputFields = () => {
    return availableItemFieldsForDetails.map((item_field, index) => {
      return (
        <GlobalTextInputField
          key={index}
          labelName={item_field.labelName}
          itemValue={currentItem[item_field.name]}
          onInputChange={onInputChange}
          inputName={item_field.name}
          inputDisabled={
            currentView === "edit" && item_field.edit_disabled
              ? item_field.edit_disabled
              : undefined
          }
          errorFields={parameters}
          isRequired={item_field.isRequired}
        />
      );
    });
  };

  return (
    <>
      <div>
        <Form>
          {getInputFields()}
          <Divider />
          <FormField>
            <GlobalList
              listPaginationCustomCSS={styles.role_item}
              itemHandler={itemHandler}
              listState={currentItem.appRoles}
              currentView={"list"}
              currentModule={"roles"}
              availableItemFieldsForList={[
                assignedRolesName,
                {
                  ...removeButtonFieldConfig,
                  column_customized_css: styles.custom_css_for_button,
                  element_customizes_css: styles.custom_button
                }
              ]}
            />
            <Divider />

            <GlobalList
              listPaginationCustomCSS={styles.role_item}
              itemHandler={itemHandler}
              listState={roleList}
              currentView={"list"}
              currentModule={"roles"}
              paginationStatus={paginationStatus}
              handlePageChange={handlePageChange}
              activePaginationPage={activePaginationPage}
              showAtBottom
              loadingLocaly={loadingLocaly}
              availableItemFieldsForList={[
                unassingedRolesName,
                {
                  ...addButtonFieldConfig,
                  column_customized_css: styles.custom_css_for_button,
                  element_customizes_css: styles.custom_button
                }
              ]}
            />
          </FormField>
        </Form>
        {currentView === "edit" ? (
          <>
            <Divider />
            <GlobalAccordition
              customTitle={"SETTINGS.HEADERS.ACCORDITION_RESET_PW"}
              customContent={getCustomContent()}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default UsersForm;
