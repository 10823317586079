export const paginationInitStatus = {
  perPage: 0,
  pageCount: 0,
  totalCount: 0
};

export const panelView = {
  create: "create",
  edit: "edit",
  details: "details",
  display: "display",
  list: "list"
};

export const saveOption = {
  save: "save",
  saveAndNew: "saveAndNew"
};

export const notificationType = {
  info: "info",
  warning: "warning"
};

export const initialArchive = {
  show: false,
  customPath: "",
  newObjects: [],
  lenghtOfItems: 0,
  activeOrArchived: ""
};

export const panelModule = {
  accounts: "settings/categories/2/subcategories",
  permisions: "permisions",
  roles: "roles",
  users: "users",
  properties: "settings/categories/1/subcategories",
  dunning: "settings/categories/3/subcategories"
};

export const deviceSize = {
  mobile: "Mobile",
  computer: "Computer",
  tablet: "Tablet"
};
