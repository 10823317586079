export const GetUpdateObject = newTemplate => {
  if (newTemplate) {
    return newTemplate.editedFields.map(function(field) {
      let fieldNameForDB = field;
      let val = newTemplate[field];

      switch (field) {
        case "projectType":
          fieldNameForDB = "projectType/id";
          val = newTemplate.projectType.id;
          break;
        default:
          break;
      }

      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  }

  return [];
};

export const convertToRoles = templates => {
  return templates.map(temp => {
    if (temp.roles && temp.roles.length > 0) {
      temp.roles = temp.roles.map(role => {
        let newRole = {
          max: role.max,
          min: role.min,
          resourceRole: {
            id: role.id.resourceRole.id,
            name: role.id.resourceRole.name,
            description: role.id.resourceRole.description
          }
        };
        return newRole;
      });
      temp.checked = false;
      return temp;
    } else {
      temp.checked = false;
      return temp;
    }
  });
};

export const convertToJson = template => {
  const rolesArray = template.roles.map(role => {
    if (role.resourceRole !== undefined) {
      return {
        min: role.min,
        max: role.max,
        id: {
          resourceRole: {
            id: role.resourceRole.id
          }
        }
      };
    } else {
      return role;
    }
  });
  const newTemplateObject = {
    roles: rolesArray,
    name: template.name,
    description: template.description,
    projectType: {
      id: template.projectType.id
    },
    editedFields: template.editedFields
  };
  return newTemplateObject;
};
