import React from "react";
import { Menu, Sidebar, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as constant from "./Constants.js";
import styles from "./style/SideBar.module.css";

const SideBar = props => {
  const { sideBarVisibility } = props;
  const { setSideBarVisibility } = props;

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Menu.Item>
          <Menu.Header>{Localize.get("TRANSACTIONS.HEADER_ASSIGNMENT")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name={constant.assignment.assigned}
              onClick={props.handler.onAssignmentFilterClick}
              active={props.state.activeAssignmentFilter === constant.assignment.assigned}
            >
              <Icon name="check circle outline" className={styles.linked_icon} />
              {Localize.get("GLOBAL.HEADERS.LINKED")}
            </Menu.Item>
            <Menu.Item
              name={constant.assignment.assignedWithoutDoc}
              onClick={props.handler.onAssignmentFilterClick}
              active={props.state.activeAssignmentFilter === constant.assignment.assignedWithoutDoc}
            >
              <Icon className={styles.linked_without_icon} name="circle outline" />{" "}
              {Localize.get("TRANSACTIONS.ASSIGNMENT_WITHOUT_DOC")}
            </Menu.Item>
            <Menu.Item
              name={constant.assignment.notAssigned}
              onClick={props.handler.onAssignmentFilterClick}
              active={props.state.activeAssignmentFilter === constant.assignment.notAssigned}
            >
              <Icon className={styles.unlinked_icon} name="times circle outline" />
              {Localize.get("GLOBAL.HEADERS.UNLINKED")}
            </Menu.Item>
          </Menu.Menu>

          <Menu.Header>{Localize.get("TRANSACTIONS.DIRECTION.HEADER_DIRECTION")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name={constant.direction.revenue}
              onClick={props.handler.onDirectionFilterClick}
              active={props.state.activeDirectionFilter === constant.direction.revenue}
            >
              <i className="olive credit card outline icon"></i>{" "}
              {Localize.get("TRANSACTIONS.DIRECTION.REVENUE")}
            </Menu.Item>
            <Menu.Item
              name={constant.direction.expense}
              onClick={props.handler.onDirectionFilterClick}
              active={props.state.activeDirectionFilter === constant.direction.expense}
            >
              <i className="red credit card outline icon"></i>{" "}
              {Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default SideBar;
