import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import Localize from "react-intl-universal";
import { TextArea, Input, Form, Checkbox, Segment, Label, Tab, Menu } from "semantic-ui-react";
import ContactFormAddress from "./ContactFormAddress";
import ContactFormAccounts from "./ContactFormAccounts";
import * as constant from "../Constants.js";
import styles from "./style/ContactFormPerson.module.css";
import {
  partnerPersonSchema,
  addressSchema,
  contactAccountSchema
} from "../../Utils/PartnersSchema";
import useValidator from "../../Utils/useValidator";
import useHandleOnChange from "../../CustomHooks/useHandleOnChange";

const ContactFormPerson = forwardRef((props, ref) => {
  const { setEditedFields, editedFields, contact, setContact, mode } = props;

  const [
    handleOnChange,
    handleOnChangeList,
    deleteItemFromList,
    addItemToList,
    handleMultipleChange
  ] = useHandleOnChange(contact, setContact);

  const checkEditedFields = field => {
    setEditedFields(previousState => {
      let newArr = [...previousState];
      !newArr.includes(field) && newArr.push(field);
      return newArr;
    });
  };

  const [charCounter, setCharCounter] = useState({
    charsLeft: 255 - (contact && contact.note ? contact.note.length : 0)
  });

  const [parameters, isFormValid, validateOnSave, validateField] = useValidator(
    partnerPersonSchema,
    "object",
    "always"
  );

  const [
    parametersAddress,
    isAddressValid,
    validateOnSaveAddress,
    validateFieldAddress
  ] = useValidator(addressSchema, "object", "always");

  const [
    parametersBillingAddress,
    isBillingAddressValid,
    validateOnSaveBillingAddress,
    validateFieldBillingAddress
  ] = useValidator(addressSchema, "object", "ifAnyFilled");

  const [
    parametersShippingAddress,
    isShippingAddressValid,
    validateOnSaveShippingAddress,
    validateFieldShippingAddress
  ] = useValidator(addressSchema, "object", "ifAnyFilled");

  const [
    parametersAccounts,
    areAccountsValid,
    ,
    ,
    ,
    validateAccountsListOnSave,
    validateAccountsListField,
    addAccountInValidator,
    removeAccountFromValidator
  ] = useValidator(contactAccountSchema, "list", "ifAnyFilled");

  const handleFieldChange = (e, data) => {
    switch (data.name) {
      case "isCustomer":
        handleOnChange(data.name, data.checked);
        break;
      case "isSupplier":
        handleOnChange(data.name, data.checked);
        break;
      case "firstName":
        {
          const firstName = data.value ? data.value : "";
          const lastName = contact.lastName ? contact.lastName : "";
          const name = (firstName + " " + lastName).trim();
          handleMultipleChange({
            name,
            firstName,
            lastName
          });
        }
        break;
      case "lastName":
        {
          const firstName = contact.firstName ? contact.firstName : "";
          const lastName = data.value ? data.value : "";
          const name = (firstName + " " + lastName).trim();
          handleMultipleChange({
            name,
            firstName,
            lastName
          });
        }
        break;
      case "asBillingAddress":
        if (!data.value) {
          handleMultipleChange({
            [data.name]: data.value,
            billingAddress: null
          });
        } else {
          handleOnChange(data.name, data.value);
        }
        break;
      case "asShippingAddress":
        if (!data.value) {
          handleMultipleChange({
            [data.name]: data.value,
            shipToAddress: null
          });
        } else {
          handleOnChange(data.name, data.value);
        }
        break;
      default:
        handleOnChange(data.name, data.value);
    }

    if (data.value) {
      const validationOnField = parameters[data.name];
      if (validationOnField) {
        validateField(data.name, data.value);
      }
    }

    if (e && e.target && e.target.name === "note") {
      let inputChar = e.target.value;
      let maxChars = 255;
      setCharCounter({
        charsLeft: maxChars - inputChar.length
      });
    }

    if (data.name && !editedFields.includes(data.name)) {
      checkEditedFields(data.name);
    }
  };

  const handleOnBlur = (e, data) => {
    if (e && e.target && e.target.value) {
      validateField(e.target.name, e.target.value);
    }
  };

  useImperativeHandle(ref, () => ({
    validateFormOnSave(person) {
      let validGeneralPart = validateOnSave(person);

      let validAddress = false;
      let validBillingAddress = false;
      let validShippingAddress = false;
      let validAccount = false;

      validAddress = validateOnSaveAddress(person.address);
      validGeneralPart = validGeneralPart && validAddress;

      validBillingAddress = validateOnSaveBillingAddress(person.billingAddress);
      validGeneralPart = validGeneralPart && validBillingAddress;

      validShippingAddress = validateOnSaveShippingAddress(person.shipToAddress);

      validGeneralPart = validGeneralPart && validShippingAddress;

      validAccount = validateAccountsListOnSave(person.accounts);
      validGeneralPart = validGeneralPart && validAccount;

      return validGeneralPart;
    }
  }));

  useEffect(() => {
    if (mode === "edit") {
      validateOnSave(contact);
      validateOnSaveAddress(contact.address);
      validateOnSaveBillingAddress(contact.billingAddress);
      validateOnSaveShippingAddress(contact.shipToAddress);
      validateAccountsListOnSave(contact.accounts);
    }
  }, []);

  const errorBorder = "3px red solid";

  return (
    <>
      <div className={styles.divided_list}>
        <div className="item add_company_name">
          <div className="content">
            <Tab
              className={styles.tab_panel}
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: (
                    <Menu.Item
                      key={0}
                      className={`${styles.tab_title} ${
                        !isFormValid ||
                        !isAddressValid ||
                        !isBillingAddressValid ||
                        !isShippingAddressValid
                          ? styles.error_border
                          : ""
                      }`}
                    >
                      {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL")}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Form size="tiny" className={styles.form}>
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>
                              {Localize.get("CONTACTS.NEWCONTACT.FORM.GENERAL_INFORMATION")}
                            </strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Group widths="equal">
                            <Form.Field required>
                              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}</label>
                              <Input
                                autoComplete={constant.autoComplete.off}
                                name="firstName"
                                className={styles.input_width}
                                style={{
                                  border: parameters && parameters.firstName ? errorBorder : ""
                                }}
                                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.FIRST_NAME")}
                                value={contact && contact.firstName ? contact.firstName : ""}
                                onChange={handleFieldChange}
                                onBlur={handleOnBlur}
                              />
                              {parameters && parameters.firstName && (
                                <Label basic color="red" pointing content={parameters.firstName} />
                              )}
                            </Form.Field>
                            <Form.Field required>
                              <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}</label>
                              <Input
                                autoComplete={constant.autoComplete.off}
                                name="lastName"
                                className={styles.input_width}
                                style={{
                                  border: parameters && parameters.lastName ? errorBorder : ""
                                }}
                                placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.LAST_NAME")}
                                value={contact.lastName === undefined ? "" : contact.lastName}
                                onChange={handleFieldChange}
                                onBlur={handleOnBlur}
                              />
                              {parameters && parameters.lastName && (
                                <Label basic color="red" pointing content={parameters.lastName} />
                              )}
                            </Form.Field>
                          </Form.Group>
                          <Form.Field className={styles.form_item_panel}>
                            <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.NOTE")}</strong>

                            <TextArea
                              name="note"
                              value={contact.note === undefined ? "" : contact.note}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                              maxLength={255}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.NOTE_PLACEHOLDER"
                              )}
                              style={{
                                border: parameters && parameters.note ? errorBorder : ""
                              }}
                            />
                            <p className={styles.counter_note}>
                              {charCounter.charsLeft}
                              {"/255"}
                            </p>
                          </Form.Field>
                          {parameters && parameters.note && (
                            <Label basic color="red" pointing content={parameters.note} />
                          )}
                        </Segment>
                      </Segment.Group>
                      {/* Customer */}
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>
                              {Localize.get("CONTACTS.NEWCONTACT.FORM.CUSTOMER_INFORMATION")}
                            </strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Field>
                            <Checkbox
                              className={styles.checkbox_resize}
                              name="isCustomer"
                              disabled
                              checked={(contact.isCustomer = true)}
                              label={Localize.get("CONTACTS.NEWCONTACT.FORM.IS_CUSTOMER_LABEL")}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                          </Form.Field>
                          {contact.isCustomer && (
                            <>
                              <Form.Field>
                                <div>
                                  {Localize.get("CONTACTS.NEWCONTACT.FORM.CUSTOMER_NUMBER_LABEL")}
                                </div>
                              </Form.Field>
                            </>
                          )}
                        </Segment>
                      </Segment.Group>

                      {/* Communication */}
                      <Segment.Group>
                        <Segment className={styles.title}>
                          <div>
                            <strong>
                              {Localize.get("CONTACTS.NEWCONTACT.FORM.COMMUNICATION")}
                            </strong>
                          </div>
                        </Segment>
                        <Segment className={styles.form_lines}>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.PHONE")}</label>
                            <Input
                              autoComplete={constant.autoComplete.off}
                              name="phone"
                              style={{
                                border: parameters && parameters.phone ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.PHONE_PLACEHOLDER"
                              )}
                              value={contact && contact.phone ? contact.phone : ""}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.phone && (
                              <Label basic color="red" pointing content={parameters.phone} />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.MOBILE")}</label>
                            <Input
                              autoComplete={constant.autoComplete.off}
                              name="mobile"
                              style={{
                                border: parameters && parameters.mobile ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.MOBILE_PLACEHOLDER"
                              )}
                              value={contact.mobile === undefined ? "" : contact.mobile}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.mobile && (
                              <Label basic color="red" pointing content={parameters.mobile} />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.EMAIL")}</label>
                            <Input
                              autoComplete={constant.autoComplete.off}
                              name="email"
                              style={{
                                border: parameters && parameters.email ? errorBorder : ""
                              }}
                              placeholder={Localize.get(
                                "CONTACTS.NEWCONTACT.FORM.EMAIL_PLACEHOLDER"
                              )}
                              value={contact && contact.email ? contact.email : ""}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.email && (
                              <Label basic color="red" pointing content={parameters.email} />
                            )}
                          </Form.Field>
                          <Form.Field>
                            <label>{Localize.get("CONTACTS.NEWCONTACT.FORM.WEB")}</label>
                            <Input
                              autoComplete={constant.autoComplete.off}
                              name="url"
                              style={{
                                border: parameters && parameters.url ? errorBorder : ""
                              }}
                              placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.WEB_PLACEHOLDER")}
                              value={contact && contact.url ? contact.url : ""}
                              onChange={handleFieldChange}
                              onBlur={handleOnBlur}
                            />
                            {parameters && parameters.url && (
                              <Label basic color="red" pointing content={parameters.url} />
                            )}
                          </Form.Field>
                        </Segment>
                      </Segment.Group>

                      {/* Addresses */}
                      <Segment className={styles.title}>
                        <div>
                          <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESSES")}</strong>
                        </div>
                      </Segment>

                      <ContactFormAddress
                        key={1}
                        name="address"
                        title={Localize.get("CONTACTS.NEWCONTACT.FORM.ADDRESS")}
                        address={contact.address}
                        onChange={handleFieldChange}
                        open={true}
                        parameters={parametersAddress}
                        validateField={validateFieldAddress}
                      />

                      {!contact.asBillingAddress && (
                        <ContactFormAddress
                          key={2}
                          name="billingAddress"
                          title={Localize.get("CONTACTS.NEWCONTACT.FORM.BILLINGADDRESS")}
                          address={contact.billingAddress}
                          onChange={handleFieldChange}
                          open={false}
                          parameters={parametersBillingAddress}
                          validateField={validateFieldBillingAddress}
                        />
                      )}

                      {!contact.asShippingAddress && (
                        <ContactFormAddress
                          key={3}
                          name="shipToAddress"
                          title={Localize.get("CONTACTS.NEWCONTACT.FORM.SHIPTOADDRESS")}
                          address={contact.shipToAddress}
                          onChange={handleFieldChange}
                          open={false}
                          parameters={parametersShippingAddress}
                          validateField={validateFieldShippingAddress}
                        />
                      )}
                    </Form>
                  )
                },
                {
                  menuItem: (
                    <Menu.Item
                      key={1}
                      className={`${styles.tab_title} ${
                        !areAccountsValid ? styles.error_border : ""
                      }`}
                    >
                      {Localize.get("CONTACTS.NEWCONTACT.FORM.BANK_ACCOUNTS")}
                    </Menu.Item>
                  ),
                  render: () => (
                    <Form size="tiny" className={styles.form}>
                      <ContactFormAccounts
                        title={Localize.get("CONTACTS.NEWCONTACT.FORM.ACCOUNTS_TITLE")}
                        accounts={contact.accounts}
                        validateAccountsListOnSave={validateAccountsListOnSave}
                        validateAccountsListField={validateAccountsListField}
                        parametersAccounts={parametersAccounts}
                        handleOnChangeList={handleOnChangeList}
                        deleteItemFromList={deleteItemFromList}
                        addItemToList={addItemToList}
                        checkEditedFields={checkEditedFields}
                        addAccountInValidator={addAccountInValidator}
                        removeAccountFromValidator={removeAccountFromValidator}
                      />
                    </Form>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ContactFormPerson;
