import React, { useRef, useState } from "react";
import DocumentFlowDiagram from "./DocumentFlowDiagram";
import { Modal, Button, Grid } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DocumentPreview from "../RightPanel/DocumentShowScan";
import * as RestService from "../../RestService";
import styles from "./style/DiagramFlow.module.css";

const CompareDocumentModal = props => {
  const [firstDocumentsPreview, setFirstDocumentsPreview] = useState(null);
  const [secondDocumentsPreview, setSecondDocumentsPreview] = useState(null);

  const callbackGetBinaryContentSuccess = response => {
    var reader = new window.FileReader();
    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        if (!firstDocumentsPreview) {
          setFirstDocumentsPreview({
            isPDF: response.headers["content-type"].includes("pdf"),
            data: reader.result
          });
        } else {
          setSecondDocumentsPreview({
            isPDF: response.headers["content-type"].includes("pdf"),
            data: reader.result
          });
        }
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });
      if (!firstDocumentsPreview) {
        setFirstDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: urlCreator.createObjectURL(file)
        });
      } else {
        setSecondDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: urlCreator.createObjectURL(file)
        });
      }
    }
  };

  const callbackGetBinaryContentSuccessSecond = response => {
    var reader = new window.FileReader();
    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        setSecondDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: reader.result
        });
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });
      setSecondDocumentsPreview({
        isPDF: response.headers["content-type"].includes("pdf"),
        data: urlCreator.createObjectURL(file)
      });
    }
  };

  const callbackGetAllError = () => {};

  const callbackGetAllFinally = () => {};

  const openCompareModal = () => {
    if (props.firstDocumentId) {
      const serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(
        props.firstDocumentId
      );
      serviceGetContent.query.addResponseType("blob");
      serviceGetContent.setCallbackSuccess(callbackGetBinaryContentSuccess);
      serviceGetContent.setCallbackError(callbackGetAllError);
      serviceGetContent.setCallbackFinally(callbackGetAllFinally);
      serviceGetContent.call();
    }
    if (props.secondDocumentId) {
      const serviceGetContentSecond = new RestService.Document.GetDocumentBinaryContent.Service(
        props.secondDocumentId
      );
      serviceGetContentSecond.query.addResponseType("blob");
      serviceGetContentSecond.setCallbackSuccess(callbackGetBinaryContentSuccessSecond);
      serviceGetContentSecond.setCallbackError(callbackGetAllError);
      serviceGetContentSecond.setCallbackFinally(callbackGetAllFinally);
      serviceGetContentSecond.call();
    }
  };

  const closeCompareModal = () => {
    setFirstDocumentsPreview(null);
    setSecondDocumentsPreview(null);
    props.resetSelectedDocuments();
  };

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onOpen={openCompareModal}
      onClose={closeCompareModal}
      closeIcon
      className={styles.compareModal}
      trigger={
        <Button primary icon disabled={!props.enableCompare}>
          {Localize.get("DOCUMENTFLOW.COMPARE_DOCUMENTS")}
        </Button>
      }
    >
      <Modal.Header>{Localize.get("DOCUMENTFLOW.COMPARE_DOCUMENTS")}</Modal.Header>
      <Modal.Content scrolling>
        <Grid>
          <Grid.Column className={styles.compareModalColumn}>
            <DocumentPreview linkedDocumentsPreview={firstDocumentsPreview} />
          </Grid.Column>
          <Grid.Column className={styles.compareModalColumn}>
            <DocumentPreview linkedDocumentsPreview={secondDocumentsPreview} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

const DocumentFlow = props => {
  const dialogContentRef = useRef(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [enableCompare, setEnableCompare] = useState(false);

  const [firstDocumentId, setFirstDocumentId] = useState(null);
  const [secondDocumentId, setSecondDocumentId] = useState(null);

  const { document } = props;
  const { showDiagramFlowState, setShowDiagramFlowState, viewFlow, setDocumentForDiagram } = props;

  const onSelectDocument = checkedDoc => {
    let arr = [...selectedDocuments];

    if (arr.includes(checkedDoc)) {
      arr.splice(arr.indexOf(checkedDoc), 1);
    } else {
      arr.push(checkedDoc);
    }

    setSelectedDocuments(arr);

    if (arr.length === 2) {
      setFirstDocumentId(arr[0]);
      setSecondDocumentId(arr[1]);
      setEnableCompare(true);
    }
  };

  const resetSelectedDocuments = () => {
    setSelectedDocuments([]);
    setEnableCompare(false);
  };

  return (
    <>
      <Modal
        className={styles.bms_diagram_modal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        dimmer="blurring"
        closeIcon
        onClose={() => {
          setDocumentForDiagram(null);
          setShowDiagramFlowState(false);
          resetSelectedDocuments();
        }}
        open={showDiagramFlowState}
      >
        <Modal.Header>{Localize.get("DOCUMENTFLOW.DOCUMENT_FLOW")}</Modal.Header>
        <Modal.Content scrolling>
          <div className={styles.document_flow} ref={dialogContentRef}>
            <DocumentFlowDiagram
              parentContainer={dialogContentRef}
              handler={onSelectDocument}
              viewFlow={viewFlow}
              enableCompare={enableCompare}
              document={
                document.parentDocument !== null
                  ? {
                      ...document.parentDocument,
                      docPropagation: true,
                      docLevel: "parent",
                      documents: [
                        {
                          ...document,
                          docLevel: "current",
                          docPropagation: true
                        }
                      ]
                    }
                  : { ...document, docLevel: "current", docPropagation: true }
              }
              selectedDocuments={selectedDocuments}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <CompareDocumentModal
            handle={props.handle}
            enableCompare={enableCompare}
            resetSelectedDocuments={resetSelectedDocuments}
            firstDocumentId={firstDocumentId}
            secondDocumentId={secondDocumentId}
          />
          <Button onClick={resetSelectedDocuments}>{Localize.get("DOCUMENTFLOW.RESET")}</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DocumentFlow;
