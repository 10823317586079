import React from "react";
import { Menu, Icon, Sidebar, Button, Container } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/GlobalLeftPanel.module.css";
import * as constants from "../Constants";
import propTypes from "prop-types";

const GlobalLeftPanel = props => {
  const {
    sideBarVisibility,
    setSideBarVisibility,
    setCurrentModule,
    currentModule,
    setCurrentView,
    setSelectedItem,
    leftPanelConfig,
    filterHandler,
    filterObject,
    forbidenResponse = false,
    setListState,
    setActivePaginationPage
  } = props;

  const getMenuItems = itemList => {
    return itemList.map((item, index) => {
      switch (item.type) {
        case "submodule":
          return (
            <Menu.Item
              key={index}
              disabled={item.isDisabled}
              onClick={() => {
                if (item.name !== currentModule) {
                  setCurrentModule(item.name);
                  setSelectedItem(null);
                  setListState([]);
                  setActivePaginationPage(1);
                }
              }}
              name={item.name}
              active={currentModule === item.name}
            >
              {item.iconName && <Icon name={item.iconName} />}
              {Localize.get(item.itemText)}
            </Menu.Item>
          );
        case "filter":
          return (
            <Menu.Item
              key={index}
              disabled={item.isDisabled}
              onClick={() => {
                filterHandler(item.name, true, filterObject);
                setActivePaginationPage(1);
              }}
              name={item.name}
              active={filterObject[item.name]}
            >
              {item.iconName && <Icon name={item.iconName} />}
              {Localize.get(item.itemText)}
            </Menu.Item>
          );
        default:
          return <div></div>;
      }
    });
  };

  const getSections = () => {
    return leftPanelConfig.map((section, index) => {
      switch (section.type) {
        case "sectionHeader":
          return (
            <Menu.Item key={index}>
              <Menu.Header>{section.headerText && Localize.get(section.headerText)}</Menu.Header>
              <Menu.Menu>{getMenuItems(section.itemList)}</Menu.Menu>
            </Menu.Item>
          );
        case "createNew":
          return (
            <Container key={index}>
              <Button
                disabled={forbidenResponse || section.disabled}
                primary
                className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
                onClick={() => {
                  setCurrentView(constants.panelView.create);
                  setSideBarVisibility(false);
                }}
              >
                {Localize.get(section.buttonText)}
                <Icon name="plus" />
              </Button>
            </Container>
          );
        default:
          return <div></div>;
      }
    });
  };

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        {getSections()}
      </Sidebar>{" "}
    </>
  );
};

GlobalLeftPanel.propTypes = {
  sideBarVisibility: propTypes.bool,
  setSideBarVisibility: propTypes.func,
  setCurrentModule: propTypes.func,
  currentModule: propTypes.string,
  setCurrentView: propTypes.func,
  setSelectedItem: propTypes.func,
  leftPanelConfig: propTypes.array,
  setListState: propTypes.func
};

export default GlobalLeftPanel;
