import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import styles from "./style/Notifier.module.css";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";

const Notifier = ({
  index,
  handleOnChangeProp,
  handleDelete,
  notifier,
  errorFields,
  title,
  validateRecipientListField
}) => {
  const [activeAccordion, setActiveAccordion] = useState(false);

  const handleOnChange = (event, data) => {
    handleOnChangeProp(index, { ...notifier, [data.name]: data.value });
    if (errorFields && errorFields[data.name]) {
      validateRecipientListField(data.name, data.value, index);
    }
  };

  const handleOnDeleteClick = e => {
    e.preventDefault();
    e.stopPropagation();
    handleDelete(index);
  };

  return (
    <div>
      <Accordion className={styles.accordition_container} styled>
        <Accordion.Title
          active={activeAccordion}
          onClick={() => setActiveAccordion(!activeAccordion)}
        >
          <Icon className={styles.dropdown_icon} name="dropdown" />
          {notifier.email ? notifier.email : title}
          <Icon
            name="minus circle"
            className={styles.minus_circle_icon}
            onClick={handleOnDeleteClick}
          ></Icon>
        </Accordion.Title>
        <Accordion.Content className={styles.ac_content} active={activeAccordion}>
          <GlobalTextInputField
            labelName="CONTACTS.NEWCONTACT.FORM.NOTIFIER_EMAIL"
            itemValue={notifier.email}
            inputName="email"
            isRequired={true}
            onInputChange={handleOnChange}
            errorFields={errorFields}
          />
          <GlobalTextInputField
            labelName="CONTACTS.NEWCONTACT.FORM.NOTIFIER_OWNER"
            itemValue={notifier.first}
            inputName="first"
            isRequired={true}
            onInputChange={handleOnChange}
            errorFields={errorFields}
          />
          <GlobalTextInputField
            labelName="CONTACTS.NEWCONTACT.FORM.JOB_TITLE"
            itemValue={notifier.jobTitle}
            inputName="jobTitle"
            onInputChange={handleOnChange}
            errorFields={errorFields}
          />
          <GlobalTextInputField
            labelName="CONTACTS.NEWCONTACT.FORM.NOTIFIER_PHONE"
            itemValue={notifier.phone}
            inputName="phone"
            onInputChange={handleOnChange}
            errorFields={errorFields}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};
export default Notifier;
