import React from "react";
import { Dropdown, Label, Form, FormField } from "semantic-ui-react";
import Localize from "react-intl-universal";

const GlobalDropdown = props => {
  const {
    isRequired = false,
    labelName,
    myPlaceholder,
    dropdownName,
    dropdownText,
    dropdownValue,
    dropdownOptions,
    handleOnChange = function() {},
    handleOnBlur = function() {},
    hasError,
    clearable,
    noResultsMessage,
    className,
    onOpen,
    onSearchChange,
    pointing,
    loading,
    disabled,
    pointingError = "above"
  } = props;

  return (
    <>
      <Form>
        <FormField required={isRequired}>
          <label style={{ color: "#575d62 !important", fontSize: "14px !important" }}>
            {labelName && Localize.get(labelName)}
          </label>
          <Dropdown
            icon="dropdown"
            fluid
            selection
            search
            clearable={clearable}
            disabled={disabled}
            onOpen={onOpen}
            text={dropdownText}
            closeOnChange
            pointing={pointing}
            loading={loading}
            className={className}
            placeholder={myPlaceholder && Localize.get(myPlaceholder)}
            name={dropdownName}
            value={dropdownValue}
            options={dropdownOptions}
            onChange={handleOnChange}
            onSearchChange={onSearchChange}
            onBlur={handleOnBlur}
            noResultsMessage={noResultsMessage}
            style={{
              border: hasError ? "3px red solid" : ""
            }}
          />
          {hasError && <Label basic color="red" pointing={pointingError} content={hasError} />}
        </FormField>
      </Form>
    </>
  );
};

export default GlobalDropdown;
