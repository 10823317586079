import React, { useState } from "react";
import { Checkbox, Segment, Button, Form, Input, Popup, Label, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/InvoiceNotifiers.module.css";
import GlobalModal from "../../Components/Panels/GlobalModal";
import PartnerListFormated from "../RightPanel/PartnerListFormated";
import InvoiceHierarchy from "./InvoiceHierarchy";

const AddParentAndExportOptions = ({
  contact,
  setContact = function() {},
  setEditedFields = function() {},
  documentTypes = []
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showClearSettingsModal, setShowClearSettingsModal] = useState(false);

  const checkEditedFields = field => {
    setEditedFields(previousState => {
      let newArr = [...previousState];
      !newArr.includes(field) && newArr.push(field);
      return newArr;
    });
  };

  const handleCheckboxClick = (e, data) => {
    if (data.name === "sendInvoiceToParent" && data.checked) {
      setShowClearSettingsModal(true);
    } else {
      setContact(previousState => {
        return { ...previousState, [data.name]: data.checked };
      });
      if (data && data.name) {
        checkEditedFields(data.name);
      }
    }
  };

  const confirmParentPartnerModal = company => {
    setContact(previousState => {
      return {
        ...previousState,
        parent: company,
        sendInvoiceToParent: !company ? false : previousState.sendInvoiceToParent
      };
    });
    checkEditedFields("parent");
    checkEditedFields("sendInvoiceToParent");
    setShowModal(false);
    setShowWarning(false);
  };

  const confirmChildren = lisOfChildren => {
    setContact(previousState => {
      return {
        ...previousState,
        children: lisOfChildren
      };
    });
    checkEditedFields("children");
    setShowModal(false);
  };

  const confirmInvoiceIsDirected = () => {
    let listOfIdsOfInvoices = [];
    documentTypes.forEach(item => {
      if (item.categoryKey === "inv" || item.categoryKey === "inv-cor") {
        listOfIdsOfInvoices.push(item.id);
      }
    });
    setContact(previousState => {
      return {
        ...previousState,
        sendInvoiceToParent: true,
        recipients: [],
        sendToPost: false,
        sendToMail: false,
        documentTemplates:
          previousState &&
          previousState.documentTemplates &&
          previousState.documentTemplates.filter(
            item => !listOfIdsOfInvoices.includes(item.documentTypeNumber.id)
          )
      };
    });
    checkEditedFields("contacts");
    checkEditedFields("sendToPost");
    checkEditedFields("sendToMail");
    checkEditedFields("sendInvoiceToParent");
    // checkEditedFields("documentTemplates");
    setShowClearSettingsModal(false);
  };

  const warningText = () => {
    return <div>{Localize.get("CONTACTS.NEWCONTACT.FORM.REMOVE_PARENT_CONFIRM")}</div>;
  };

  const clearSettingsConfirmationText = () => {
    return <div>{Localize.get("CONTACTS.NEWCONTACT.FORM.CLEAR_SETTINGS")}</div>;
  };

  const handleOnPlusClick = () => {
    setShowModal("children");
  };

  const deleteChildClicked = index => {
    let newArrOfChildren = contact.children;
    newArrOfChildren.splice(index, 1);
    setContact(prevState => {
      return { ...prevState, children: newArrOfChildren };
    });
    checkEditedFields("children");
  };

  return (
    <>
      <GlobalModal
        showModal={showModal ? true : false}
        isParent={showModal === "parent"}
        setShowModal={setShowModal}
        CustomComponent={PartnerListFormated}
        customContentClassName={styles.partners_modal}
        parent={contact.parent}
        childrenArr={contact.children}
        confirmParentPartnerModal={confirmParentPartnerModal}
        confirmChildren={confirmChildren}
        showActions={false}
        setShowWarning={setShowWarning}
        headerText={"DOCUMENTS.MODAL.SELECT_PARENT"}
        selectedCompanyId={contact.id}
      />
      <GlobalModal
        showModal={showWarning}
        CustomComponent={warningText}
        modalHandler={() => confirmParentPartnerModal(null)}
        setShowModal={setShowWarning}
      />
      <GlobalModal
        showModal={showClearSettingsModal}
        CustomComponent={clearSettingsConfirmationText}
        modalHandler={confirmInvoiceIsDirected}
        setShowModal={setShowClearSettingsModal}
      />
      <Segment.Group className={styles.segment_group}>
        <Segment disabled={contact.sendInvoiceToParent} className={styles.title}>
          <div>
            <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.EXPORT_INVOICE")}</strong>
          </div>
        </Segment>
        <Segment disabled={contact.sendInvoiceToParent} className={styles.form_lines}>
          <Form.Field>
            <Checkbox
              label={Localize.get("CONTACTS.NEWCONTACT.FORM.SEND_VIA_EMAIL")}
              checked={contact.sendToMail}
              onChange={handleCheckboxClick}
              name="sendToMail"
              disabled={contact.sendInvoiceToParent}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={Localize.get("CONTACTS.NEWCONTACT.FORM.SEND_VIA_POST")}
              checked={contact.sendToPost}
              onChange={handleCheckboxClick}
              name="sendToPost"
              disabled={contact.sendInvoiceToParent}
            />
          </Form.Field>
        </Segment>
      </Segment.Group>

      <Segment.Group className={styles.segment_group}>
        <Segment disabled={contact.sendInvoiceToParent} className={styles.title}>
          <div>
            <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.ANNEX")}</strong>
          </div>
        </Segment>
        <Segment className={styles.form_lines}>
          <Form.Field>
            <Checkbox
              label={Localize.get("CONTACTS.NEWCONTACT.FORM.GENERATE_ANNEX")}
              checked={contact.annexRequired}
              onChange={handleCheckboxClick}
              name="annexRequired"
              disabled={contact.sendInvoiceToParent}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={Localize.get("CONTACTS.NEWCONTACT.FORM.ANNEX_GROUP")}
              checked={contact.annexGroupBy}
              onChange={handleCheckboxClick}
              name="annexGroupBy"
              disabled
            />
          </Form.Field>
        </Segment>
      </Segment.Group>

      <Segment.Group className={styles.segment_group}>
        <Segment disabled={contact.sendInvoiceToParent} className={styles.title}>
          <div>
            <strong>{Localize.get("CONTACTS.DETAILS.FORM.CHILDREN_COMPANIES")}</strong>
          </div>
          <div className={styles.formAddButton}>
            <Icon
              name="plus"
              onClick={handleOnPlusClick}
              disabled={contact.sendInvoiceToParent || contact.parent}
            />
          </div>
        </Segment>
        <Segment disabled={contact && contact.parent ? true : false} className={styles.form_lines}>
          <InvoiceHierarchy
            contact={contact}
            currentComponent={"children_companies"}
            deleteChildClicked={deleteChildClicked}
          />
        </Segment>
      </Segment.Group>

      <Segment.Group className={styles.segment_group}>
        <Segment className={styles.title}>
          <div>
            <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.PARENT_COMPANY")}</strong>
          </div>
        </Segment>
        <Segment className={styles.form_lines}>
          <Form.Field>
            <Form.Group>
              <Popup
                content={Localize.get("CONTACTS.LIST.TOOLBAR.SEARCH_PARTNERS")}
                size="small"
                trigger={
                  <Button
                    floated="left"
                    disabled={contact && contact.children && contact.children.length > 0}
                    primary
                    icon="search"
                    onClick={() => setShowModal("parent")}
                  />
                }
              />
              <div className={styles.parent_input}>
                <Input
                  name="parent"
                  labelPosition={contact.parent && "right"}
                  value={contact.parent && contact.parent.name ? contact.parent.name : ""}
                  placeholder={Localize.get("CONTACTS.NEWCONTACT.FORM.PARENT_COMPANY_UNDEFINED")}
                  label={
                    contact.parent && (
                      <Popup
                        size="small"
                        content={Localize.get("CONTACTS.NEWCONTACT.FORM.REMOVE_PARENT_COMPANY")}
                        trigger={
                          <Label
                            onClick={() => setShowWarning(true)}
                            className={styles.remove_btn_icon}
                          >
                            <Icon name="delete" basic="true" />
                          </Label>
                        }
                      />
                    )
                  }
                />
              </div>
            </Form.Group>
          </Form.Field>
          <Form.Field>
            <Checkbox
              disabled={!contact.parent}
              label={Localize.get("CONTACTS.NEWCONTACT.FORM.BILL_TO_PARENT")}
              checked={contact.sendInvoiceToParent}
              onChange={handleCheckboxClick}
              name="sendInvoiceToParent"
            />
          </Form.Field>
        </Segment>
      </Segment.Group>
    </>
  );
};

export default AddParentAndExportOptions;
