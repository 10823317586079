export const settingsRoleSchema = {
  name: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  }
};

export const settingsUserSchema = {
  firstName: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  lastName: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  username: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  email: {
    type: "string",
    filters: ["min1Character", "emailFormat", "whitespaceBeginFilter", "longerThan255"]
  }
};

export const settingAccountingSchema = {
  testInput: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  }
};
