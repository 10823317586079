import React from "react";
import { Menu, Grid, Checkbox, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as constant from "../Constants.js";
import { formatDateDisplay, getDocumentDataType } from "../Util";
import styles from "./style/DocumentItem.module.css";
import { get } from "lodash";

const DocumentItem = props => {
  const {
    document,
    onListItemClick,
    onToggleItem,
    state,
    selectedDocumentId,
    fieldsForList
  } = props;

  const { activeDocumentTypeFilter, activeDocumentDirectionFilter } = state;

  let classPaymentStatuses;
  let classApprovalStatuses;
  let classVerificationStatuses;

  let paymentAmount = !document.payments
    ? 0
    : document.payments.reduce((prv, p) => (p.amount ? prv + p.amount : prv + 0), 0);

  let documentAmount = document.amount;
  let displayDocumentAmount = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: document.currency.id
  }).format(documentAmount);

  let displaySavings = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: document.currency.id
  }).format(
    document.documentDiscount && document.documentDiscount.savings
      ? document.documentDiscount.savings
      : null
  );

  let displayPaymentAmount = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: document.currency.id
  }).format(paymentAmount);
  let displayAmount =
    paymentAmount > 0 && paymentAmount < document.amount && props.editMode
      ? displayPaymentAmount + "/" + displayDocumentAmount
      : displayDocumentAmount;

  const payMode = state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay;

  const checkBinaryContent = () => {
    return !(document.binaryContent && document.binaryContent.id);
  };

  let verificationStatus;
  let paymentStatus;
  let approvalStatus;

  switch (document.approvalStatus) {
    case constant.paymentApprovalStatus.unresolved:
      classApprovalStatuses = `ui label ${styles.unresolved}`;
      approvalStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_UNRESOLVED");
      break;
    case constant.paymentApprovalStatus.approved:
      classApprovalStatuses = `ui label ${styles.approved}`;
      approvalStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_APPROVED");
      break;
    case constant.paymentApprovalStatus.declined:
      classApprovalStatuses = `ui label ${styles.declined}`;
      approvalStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.PAYMENT_DECLINED");
      break;
    default:
  }

  switch (document.paymentStatus) {
    case constant.paymentStatus.paid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PAID");
      break;
    case constant.paymentStatus.notPaid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.not_paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTPAID");
      break;
    case constant.paymentStatus.partialPaid:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.partial_paid}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PARTIAL_PAID");
      break;
    case constant.paymentStatus.notRelevant:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.not_relevant}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_NOT_RELEVANT");
      break;
    case constant.paymentStatus.notSuccessful:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.notsucc}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_NOTSUCC");
      break;
    case constant.paymentStatus.pending:
      classPaymentStatuses = `ui vertical menu span ui label ${styles.pending}`;
      paymentStatus = Localize.get("DOCUMENTS.DETAILS.DOCUMENT_PAYMENT_PENDING");
      break;
    default:
  }

  switch (document.verificationStatus) {
    case constant.verificationStatus.verified:
      classVerificationStatuses = `ui label ${styles.verified}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_VERIFIED");
      break;
    case constant.verificationStatus.disapproved:
      classVerificationStatuses = `ui label ${styles.declined}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_DISAPPROVED");
      break;
    case constant.verificationStatus.unverified:
      classVerificationStatuses = `ui label ${styles.unverified}`;
      verificationStatus = Localize.get("DOCUMENTS.ITEMLIST.ITEM_LABEL.VERIFICATION_UNVERIFIED");
      break;

    default:
  }

  const incominInvoiceNotPay =
    activeDocumentTypeFilter === constant.documentType.invoice &&
    !payMode &&
    activeDocumentDirectionFilter === constant.documentDirection.incoming;

  const isVisible = name => {
    let field = fieldsForList.find(item => item.name === name);
    if (field) {
      return field.visible;
    } else {
      return false;
    }
  };

  return (
    <Menu.Item
      id={document.id}
      className={styles.bms_documents_item}
      active={selectedDocumentId === document.id}
      onClick={() => onListItemClick(document)}
    >
      <Grid stackable container verticalAlign="middle" className={styles.item_grid}>
        <Grid.Column width={1}>
          <Checkbox item_id={document.id} checked={document.checked} onClick={onToggleItem} />
          <Icon
            className={styles.dowloadIcon}
            name="download"
            disabled={checkBinaryContent() || get(document, "progressStatus") === "imported"}
          />
        </Grid.Column>
        {isVisible("status") && (
          <Grid.Column
            width={2}
            className={`${styles.colFixedHeight} ${styles.verticalAlignColumCell} ${styles.bms_first_status}`}
          >
            <span className={classVerificationStatuses}>{verificationStatus}</span>

            {document.documentType === constant.documentType.invoice &&
              document.direction === constant.documentDirection.incoming && (
                <span className={classApprovalStatuses}>{approvalStatus}</span>
              )}
          </Grid.Column>
        )}
        {isVisible("number") && (
          <Grid.Column width={2}>
            <div className={`${styles.list_desc_doc_number} ${styles.list_desc}`}>
              {document.documentNumber}
            </div>
          </Grid.Column>
        )}
        {isVisible("partner") && (
          <Grid.Column className={styles.partner_name_column} width={incominInvoiceNotPay ? 3 : 4}>
            <div>
              {document.direction === constant.documentDirection.outgoing
                ? document && document.partner && document.partner.customer
                  ? document.partner.customer.original
                  : null
                : document && document.partner && document.partner.supplier
                ? document.partner.supplier.original
                : null}{" "}
            </div>{" "}
            <div>{document.partner ? document.partner.name : null}</div>
          </Grid.Column>
        )}
        {(isVisible("earlyPaymentDate") || isVisible("documentType")) && (
          <Grid.Column className={styles.doc_type} width={incominInvoiceNotPay ? 2 : 2}>
            {activeDocumentTypeFilter === constant.documentType.invoice &&
            activeDocumentDirectionFilter === constant.documentDirection.incoming
              ? document.documentDiscount &&
                document.documentDiscount.earlyPayDate &&
                !payMode &&
                isVisible("earlyPaymentDate")
                ? formatDateDisplay(document.documentDiscount.earlyPayDate)
                : " "
              : isVisible("documentType") && getDocumentDataType(document.documentType)}
          </Grid.Column>
        )}
        {(isVisible("documentDate") || isVisible("paymentDate")) && (
          <Grid.Column width={2}>
            <div className={styles.list_desc}>
              {activeDocumentTypeFilter === constant.documentType.invoice &&
              !payMode &&
              activeDocumentDirectionFilter === constant.documentDirection.incoming
                ? document.paymentDue &&
                  isVisible("paymentDate") &&
                  formatDateDisplay(document.paymentDue)
                : document.documentDate && formatDateDisplay(document.documentDate)}
            </div>
          </Grid.Column>
        )}
        {isVisible("amount") && (
          <Grid.Column
            width={
              activeDocumentTypeFilter === constant.documentType.invoice &&
              !payMode &&
              activeDocumentDirectionFilter === constant.documentDirection.incoming
                ? 2
                : 3
            }
          >
            {document.documentType !== constant.documentType.delivery ? (
              <fieldset
                className={
                  activeDocumentTypeFilter === constant.documentType.invoice &&
                  !payMode &&
                  activeDocumentDirectionFilter === constant.documentDirection.incoming
                    ? styles.document_payment_status_label_invoice
                    : styles.document_payment_status_label
                }
              >
                <legend>
                  {document.documentType === constant.documentType.invoice &&
                    activeDocumentDirectionFilter === constant.documentDirection.incoming && (
                      <div size="mini" className={classPaymentStatuses}>
                        {paymentStatus}
                      </div>
                    )}
                  {props.paymentIconOk && (
                    <Icon
                      name="check circle"
                      color="green"
                      style={{
                        display: "table-cell",
                        verticalAlign: "50%",
                        position: "absolute",
                        right: "6px"
                      }}
                    />
                  )}
                </legend>
                {displayAmount}
              </fieldset>
            ) : (
              <div />
            )}
          </Grid.Column>
        )}
        {activeDocumentTypeFilter === constant.documentType.invoice &&
          !payMode &&
          activeDocumentDirectionFilter === constant.documentDirection.incoming &&
          isVisible("savings") && (
            <Grid.Column width={2} className={styles.savings}>
              {displaySavings}
            </Grid.Column>
          )}
      </Grid>
    </Menu.Item>
  );
};

export default DocumentItem;
