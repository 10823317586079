import React, { useEffect, useState } from "react";
import * as constants from "../Projects/Management/Constants";
import * as elements from "./Constants";
import GlobalSaveNewCancelBtn from "../Components/GlobalSaveNewCancelBtn";
import { Project, ProjectRole, ProjectTemplate, ProjectType } from "../RestService";
import { clearEmptyEntries } from "../Projects/Management/Util";
import FillMandatoryDataWarningNotification from "../Notifications/FillMandatoryDataWarningModal";

const ProjectSaveButtonsGroup = props => {
  const {
    activeMode,
    onSaveButtonClick,
    onSaveAndNewButtonClick,
    onCancelButtonGroup,
    setValidator,
    validator,
    requiredFields,
    refRoles,
    editedFields,
    objectThatIWantToSave,
    refProjectResources,
    setError,
    rolesPersons,
    myParentElement
  } = props;

  const [initialName, setInitialName] = useState("");
  let currentNameValue = objectThatIWantToSave.projectName
    ? objectThatIWantToSave.projectName
    : objectThatIWantToSave.name;
  let isThereExistingName;

  const [isNotificationAlive, setIsNotificationAlive] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const callbackNameExist = response => {
    isThereExistingName = response.data.data && response.data.data.length > 0 ? true : false;
  };
  const callbackNameNotExist = response => {
    isThereExistingName = false;
  };
  const callbackNameFinally = response => {};

  const checkIsThereName = async searchName => {
    let checkForNameService;
    switch (myParentElement) {
      case elements.projectElements.management:
        checkForNameService = new Project.GetAll.Service();
        break;
      case elements.projectElements.template:
        checkForNameService = new ProjectTemplate.GetAll.Service();
        break;
      case elements.projectElements.role:
        checkForNameService = new ProjectRole.GetAll.Service();
        break;
      case elements.projectElements.type:
        checkForNameService = new ProjectType.GetAll.Service();
        break;
      default:
        break;
    }

    checkForNameService.setCallbackSuccess(callbackNameExist);
    checkForNameService.setCallbackError(callbackNameNotExist);
    checkForNameService.setCallbackFinally(callbackNameFinally);
    checkForNameService.query.addSearchMatchParam("all");
    checkForNameService.query.addCustomColumnSearch("name", searchName);
    checkForNameService.query.clearPage();
    checkForNameService.query.clearPageSize();

    await checkForNameService.call();
  };

  useEffect(() => {
    if (myParentElement === elements.projectElements.management) {
      setInitialName(objectThatIWantToSave.projectName);
    } else {
      setInitialName(objectThatIWantToSave.name);
    }
  }, []);

  const onSaveNewClick = async field => {
    let objectForValidation = objectThatIWantToSave;
    let canBeSaved = false;
    const checkEmptyStringName = currentNameValue ? currentNameValue.trim() : false;

    if (
      initialName !== currentNameValue &&
      checkEmptyStringName &&
      myParentElement !== elements.projectElements.status
    ) {
      await checkIsThereName(currentNameValue);
      setError(isThereExistingName);
    }

    if (myParentElement === elements.projectElements.management) {
      objectForValidation = {
        ...objectForValidation,
        projectTemplateValidate: objectThatIWantToSave.projectTemplate.name
      };
      let validateResources = false;
      if (refProjectResources && refProjectResources.current) {
        validateResources = refProjectResources.current.validateResourcesOnSave();
      }

      let validGeneralPart = validator.validateOnSave(requiredFields, objectForValidation);

      let validContactPerson = true;

      let validOfficeAddress = true;

      clearEmptyEntries(objectForValidation.deliveryAddress);
      if (objectForValidation.deliveryAddress) {
        delete objectForValidation.deliveryAddress.id;
      }

      if (!(validGeneralPart && validContactPerson && validOfficeAddress && validateResources)) {
        setIsNotificationAlive(true);
      } else {
        if (!isThereExistingName) {
          if (editedFields.includes("resources")) {
            const rolesPersonForService = rolesPersons.map(rp => ({
              id: {
                ...rp.id,
                partner: { id: rp.id.partner.id },
                resourceRole: rp.id.resourceRole
              }
            }));

            objectThatIWantToSave.resources = rolesPersonForService;
          }
          objectThatIWantToSave.editedFields = editedFields;
          canBeSaved = true;
        }
      }
    } else if (myParentElement === elements.projectElements.template) {
      objectForValidation = {
        ...objectForValidation,
        projectTypeValidation: objectThatIWantToSave.projectType.name
      };

      let validGeneralPart = validator.validateOnSave(requiredFields, objectForValidation);

      let validTemplateRoles = refRoles.current.validateFormOnSave(objectForValidation.roles);

      if (validGeneralPart && validTemplateRoles) {
        objectThatIWantToSave.editedFields = editedFields;
        canBeSaved = true;
      } else {
        setIsNotificationAlive(true);
      }
    } else if (validator.validateOnSave(requiredFields, objectForValidation)) {
      objectThatIWantToSave.editedFields = editedFields;
      canBeSaved = true;
    } else {
      setIsNotificationAlive(true);
    }

    if (canBeSaved && !isThereExistingName) {
      switch (field) {
        case "saveAndClose":
          setDisableSaveBtn(true);
          onSaveButtonClick(objectThatIWantToSave);
          break;
        case "saveAndNew":
          onSaveAndNewButtonClick(objectThatIWantToSave);
          break;
        default:
          break;
      }
    }

    setValidator({ ...validator });
  };

  const getFilledDataValidationNotification = () => {
    if (isNotificationAlive) {
      return (
        <FillMandatoryDataWarningNotification
          isAlive={isNotificationAlive}
          setIsNotificationAlive={setIsNotificationAlive}
        />
      );
    }
  };

  return (
    <>
      {getFilledDataValidationNotification()}
      <GlobalSaveNewCancelBtn
        editMode={activeMode !== constants.panelMode.create}
        onUpdate={() => onSaveNewClick("saveAndClose")}
        onSave={() => onSaveNewClick("saveAndClose")}
        onSaveNew={() => onSaveNewClick("saveAndNew")}
        onCancel={onCancelButtonGroup}
        disabledBtn={disableSaveBtn}
      />
    </>
  );
};

export default ProjectSaveButtonsGroup;
