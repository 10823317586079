import React, { useState } from "react";
import GlobalList from "../../Components/Lists/GlobalList";
import { availableItemFieldsForList, availableItemFieldsForArchive } from "./ProjectRoles.config";
import useDeleteItem from "../../CustomHooks/useDeleteItem";
import useArchiveItems from "../../CustomHooks/useArchiveItems";
import useCannotBeDeleted from "../../CustomHooks/useCannotBeDeleted";
import ToolBar from "../../Components/ToolBar";
import { GetUpdateObject, togleAll, togleOne } from "../../Components/Utils/GlobalListsUtil";
import styles from "./style/ProjectRoles.module.css";
import Localize from "react-intl-universal";

const RolesMiddlePanel = props => {
  const {
    currentView,
    setCurrentView,
    listState,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    searchFilter,
    filterObject,
    searchObject,
    callGetAll,
    setIsLoading,
    privileges,
    setListState,
    sortHandler
  } = props;

  const [selectAll, setSelectAll] = useState(false);

  const [
    deleteItem,
    myDialog,
    cannotBeDeleted,
    setCannotBeDeleted,
    cannotDeleteInfo
  ] = useDeleteItem(
    callGetAll,
    setIsLoading,
    "projects/roles",
    activePaginationPage,
    handlePageChange,
    listState.length,
    filterObject.archived
  );
  const [archiveItems, archiveDialog] = useArchiveItems(
    callGetAll,
    setIsLoading,
    "projects/roles",
    activePaginationPage,
    handlePageChange,
    listState.length
  );
  const [cannotDeleteModal] = useCannotBeDeleted(
    callGetAll,
    setIsLoading,
    "projects/roles",
    activePaginationPage,
    handlePageChange,
    listState,
    availableItemFieldsForArchive,
    cannotBeDeleted,
    setCannotBeDeleted
  );

  const itemHandler = (item, data) => {
    switch (data.name) {
      case "edit":
        setSelectedItem(item);
        setCurrentView("edit");
        break;
      case "trash alternate":
        deleteItem(`?ids=${item.id}`, 1);
        break;
      case "archive":
        archiveItems(
          `/${item.id}`,
          [{ op: "replace", path: "/archived", value: !item.archived }],
          1,
          filterObject && filterObject.archived ? "archived" : ""
        );
        break;
      case "checked":
        togleOne(item.id, setListState);
        break;
      default:
        console.log("default: " + data.name);
    }
  };

  const myToolbarSearch = (event, data) => {
    event.preventDefault();
    searchFilter(event, data, searchObject);
  };

  const resetFilters = (event, data) => {
    searchFilter(event, data, filterObject, true);
  };

  const resetSearchIputField = (event, data) => {
    searchFilter(event, { ...data, name: "search", value: "" }, searchObject);
  };

  const toggleAll = () => {
    togleAll(!selectAll, setListState);
    setSelectAll(!selectAll);
  };

  const toolbarArchive = () => {
    let updatedObjects = listState
      .filter(role => role.checked)
      .map(role => {
        role.archived = !role.archived;
        role.editedFields = ["archived"];
        let updatedObject = GetUpdateObject(role);
        return { id: role.id, operation: updatedObject };
      });
    if (updatedObjects.length > 0) {
      archiveItems(
        "",
        updatedObjects,
        updatedObjects.length,
        filterObject && filterObject.archived ? "archived" : ""
      );
      setSelectAll(false);
    }
  };

  const toolbarDelete = () => {
    let myIds = listState.filter(role => role.checked).map(role => role.id);
    if (myIds.length > 0) {
      deleteItem(`?ids=${myIds.toString()}`, myIds.length);
      setSelectAll(false);
    }
  };

  return (
    <>
      {cannotDeleteInfo}
      {cannotDeleteModal}
      {archiveDialog}
      {myDialog}
      <ToolBar
        customStyle={styles.toolbar_margin}
        privileges={privileges}
        resetSearches={resetFilters}
        resetNameSearch={resetSearchIputField}
        searchName={myToolbarSearch}
        enteredValue={searchObject.search}
        selectAll={selectAll}
        onToggleAll={toggleAll}
        onArchiveClick={toolbarArchive}
        onDeleteClick={toolbarDelete}
        emptyList={listState.length === 0}
        archiveOrUnarchive={filterObject && filterObject.archived ? "archived" : ""}
        deleteEnable={true}
        popupContentText={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PROJECT_ROLES_POPUP")}
        disableSearch={
          (searchObject.description !== undefined && searchObject.description !== "") ||
          (searchObject.name !== undefined && searchObject.name !== "")
        }
      />
      <div style={{ padding: "5px" }} />
      <GlobalList
        currentView={currentView}
        listState={listState}
        searchFilter={searchFilter}
        sortHandler={sortHandler}
        itemHandler={itemHandler}
        filterObject={filterObject}
        searchObject={searchObject}
        forbidenResponse={forbidenResponse}
        availableItemFieldsForList={availableItemFieldsForList}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        paginationStatus={paginationStatus}
        handlePageChange={handlePageChange}
        activePaginationPage={activePaginationPage}
        listPaginationCustomCSS={
          paginationStatus.pageCount > 1 ? styles.middle_panel_list : styles.middle_panel_list_0
        }
        showAtBottom={true}
        disableInput={searchObject.search !== undefined && searchObject.search !== ""}
      />
    </>
  );
};

export default RolesMiddlePanel;
