import React from "react";
import { Menu, Grid, Icon, Button } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DocumentScanFilesItem.module.css";

const DocumentScanFilesItem = props => {
  const {
    state,
    documentType,
    fileName,
    onListItemClick,
    id,
    selectedfilebinaryid,
    binaryContent,
    handler
  } = props;

  return (
    <Menu.Item
      id={"ItemList_" + id}
      className={styles.bms_scan_documents_item}
      active={state.activeScanesListItem === "ItemList_" + id}
      onClick={onListItemClick}
      selectedfilebinaryid={selectedfilebinaryid}
      binarycontent={binaryContent}
    >
      <Grid className={styles.table_header_documents} verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={6}>{fileName}</Grid.Column>
          <Grid.Column width={5}>
            {documentType ? documentType : Localize.get("DOCUMENTS.SCAN.UNRECOGNIZED")}
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              icon
              labelPosition="right"
              onClick={handler.documentScansPanel.onSelectButtonClick}
              selectedfilebinaryid={selectedfilebinaryid}
              scanedfilebinaryconetent={binaryContent}
              className={styles.scaned_list_select_button}
            >
              {Localize.get("DOCUMENTS.SCAN.SELECT")}
              <Icon name="checkmark" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );
};

export default DocumentScanFilesItem;
