export const autoComplete = {
  none: "none",
  off: "off"
};

//datePicker locales

export const WEEKDAYS_SHORT = {
  de: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
};

export const WEEKDAYS_LONG = {
  de: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};

export const MONTHS = {
  de: [
    " Jan.",
    "Feb.",
    "Mrz.",
    "Apr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Okt.",
    "Nov.",
    "Dez."
  ]
};

export const LABELS = {
  de: { nextMonth: "nächsten Monat", previousMonth: "vorheriger Monat" }
};

export const FIRST_DAY_OF_WEEK = {
  de: 1
};

// ---- datePicker Locales END

export const refreshLinkInit = { formUrl: "", webFormId: "", accountId: "" };
