import { useState } from "react";

import isEqual from "lodash.isequal";

const useRecalculate = document => {
  const [equalsItemsWithTaxSubtotals, setEqualsItemsWithTaxSubtotals] = useState(true);
  const [equalsItemsWithDocumentHeader, setEqualsItemsWithDocumentHeader] = useState(true);
  const [equalsTaxSubtotalsWithDocumentHeader, setEqualsTaxSubtotalsWithDocumentHeader] = useState(
    true
  );

  const calculateItems = document => {
    // const [orderItems] = document; // get orderItems from document object
  };

  const calculateTaxSubtotalsFromItems = (document, responseType = "list") => {
    const { orderItems } = document; // get orderItems from document object

    // helper functions for calculations from items to taxSubtotals
    // check also is there a null value
    // check for NaN value => float + NaN = NaN => int + NaN = NaN
    const checkNan = amount => (!isNaN(amount) ? amount : null);

    // input params => number or NaN
    const sumAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(parseAndFixDecimal(accAmount)) || !isNaN(parseAndFixDecimal(amount)))
        return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
      else return null;
    };

    const calcSurchagre = surchargeDiscount => {
      if (!isNaN(surchargeDiscount)) return surchargeDiscount > 0 ? surchargeDiscount : 0;
      else return null;
    };

    const calcDiscount = surchargeDiscount => {
      if (!isNaN(surchargeDiscount)) return surchargeDiscount < 0 ? Math.abs(surchargeDiscount) : 0;
      else return null;
    };

    // NOTE: must check everything because => null + null = 0
    const calcBaseAmount = (amount, surchargeDiscount) => {
      if (!isNaN(amount) || !isNaN(surchargeDiscount))
        return parseAndFixDecimal(checkNan(amount) + checkNan(surchargeDiscount));
      else return null;
    };

    const calcTaxValue = (amount, surchargeDiscount, tax) => {
      if (!isNaN(amount))
        return parseAndFixDecimal(
          (calcBaseAmount(amount, surchargeDiscount) * checkNan(tax)) / 100
        );
      else return null;
    };

    const calcGrossAmount = (amount, surchargeDiscount, tax) =>
      parseAndFixDecimal(
        checkNan(amount) +
          checkNan(surchargeDiscount) +
          calcTaxValue(amount, surchargeDiscount, tax)
      );
    /////

    // CALCULATE taxSubtotals from document items
    const taxSubtotals = orderItems
      ? orderItems.reduce((acc, orderItem) => {
          // vat => tax
          // amount => netAmount
          // surcharge =>  + surchargeDiscount
          // discount => - surchargeDiscount
          // baseAmount => amount + surchargeDiscount
          // taxValue => (amount + surchargeDiscount) * tax / 100
          acc[orderItem.vat] = {
            tax: orderItem.vat,
            netAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].netAmount),
                  parseAndFixDecimal(orderItem.amount)
                )
              : checkNan(parseAndFixDecimal(orderItem.amount)),
            discount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].discount),
                  calcDiscount(parseAndFixDecimal(orderItem.surchargeDiscount))
                )
              : calcDiscount(parseAndFixDecimal(orderItem.surchargeDiscount)),
            surcharge: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].surcharge),
                  calcSurchagre(parseAndFixDecimal(orderItem.surchargeDiscount))
                )
              : calcSurchagre(parseAndFixDecimal(orderItem.surchargeDiscount)),
            baseAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].baseAmount),
                  calcBaseAmount(
                    parseAndFixDecimal(orderItem.amount),
                    parseAndFixDecimal(orderItem.surchargeDiscount)
                  )
                )
              : calcBaseAmount(
                  parseAndFixDecimal(orderItem.amount),
                  parseAndFixDecimal(orderItem.surchargeDiscount)
                ),
            calculatedTax: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].calculatedTax),
                  calcTaxValue(
                    parseAndFixDecimal(orderItem.amount),
                    parseAndFixDecimal(orderItem.surchargeDiscount),
                    parseAndFixDecimal(orderItem.vat)
                  )
                )
              : calcTaxValue(
                  parseAndFixDecimal(orderItem.amount),
                  parseAndFixDecimal(orderItem.surchargeDiscount),
                  parseAndFixDecimal(orderItem.vat)
                ),
            grossAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].grossAmount),
                  calcGrossAmount(
                    parseAndFixDecimal(orderItem.amount),
                    parseAndFixDecimal(orderItem.surchargeDiscount),
                    parseAndFixDecimal(orderItem.vat)
                  )
                )
              : calcGrossAmount(
                  parseAndFixDecimal(orderItem.amount),
                  parseAndFixDecimal(orderItem.surchargeDiscount),
                  parseAndFixDecimal(orderItem.vat)
                )
          };
          return acc;
        }, {})
      : {};

    if (responseType === "list") return Object.keys(taxSubtotals).map(tax => taxSubtotals[tax]);
    else return taxSubtotals;
  };

  const calculateDocumentTotalFromItems = document => {
    const taxSubtotals = calculateTaxSubtotalsFromItems(document, "list");

    const checkNan = amount => (!isNaN(amount) ? amount : null);

    const sumAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
      else return null;
    };

    const subtractAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) - checkNan(amount));
      else return null;
    };

    const calculateSavings = (amount, earlyPayAmount, earlyPayDate) => {
      if (!isNaN(amount) && !isNaN(earlyPayAmount)) {
        const currentDate = new Date();
        const earlyPaymentDate = new Date(earlyPayDate);

        return currentDate < earlyPaymentDate && earlyPayAmount > 0
          ? parseAndFixDecimal(amount - earlyPayAmount)
          : parseAndFixDecimal(0);
      } else {
        return parseAndFixDecimal(0);
      }
    };

    // CALCULATE documentTotal from document items (items=>subtotals=>documentTotal)
    const documentHeader = taxSubtotals.reduce(
      (acc, subtotal) => {
        acc.totalNetAmount = acc.totalNetAmount
          ? sumAndCheckForNaN(acc.totalNetAmount, subtotal.netAmount)
          : subtotal.netAmount;
        acc.totalDiscount = acc.totalDiscount
          ? sumAndCheckForNaN(acc.totalDiscount, subtotal.discount)
          : subtotal.discount;
        acc.totalSurcharge = acc.totalSurcharge
          ? sumAndCheckForNaN(acc.totalSurcharge, subtotal.surcharge)
          : subtotal.surcharge;
        acc.totalBaseAmount = acc.totalBaseAmount
          ? sumAndCheckForNaN(acc.totalBaseAmount, subtotal.baseAmount)
          : subtotal.baseAmount;
        acc.totalTax = acc.totalTax
          ? sumAndCheckForNaN(acc.totalTax, subtotal.calculatedTax)
          : subtotal.calculatedTax;
        acc.totalGrossAmount = acc.totalGrossAmount
          ? sumAndCheckForNaN(acc.totalGrossAmount, subtotal.grossAmount)
          : subtotal.grossAmount;
        acc.overpayment = checkNan(parseAndFixDecimal(acc.overpayment));

        acc.amount = subtractAndCheckForNaN(
          parseAndFixDecimal(acc.totalGrossAmount),
          parseAndFixDecimal(acc.overpayment)
        );
        acc.documentDiscount = {
          earlyPayAmount: acc.documentDiscount.earlyPayAmount,

          savings: sumAndCheckForNaN(
            parseAndFixDecimal(
              calculateSavings(
                parseAndFixDecimal(acc.amount),
                parseAndFixDecimal(acc.documentDiscount.earlyPayAmount),
                acc.documentDiscount.earlyPayDate
              )
            )
          ),
          earlyPayDate: acc.documentDiscount.earlyPayDate
        };

        return acc;
      },
      {
        totalNetAmount: null,
        totalDiscount: null,
        totalSurcharge: null,
        totalBaseAmount: null,
        totalTax: null,
        totalGrossAmount: null,
        overpayment: document.overpayment,
        paymentDue: document.paymentDue,
        documentDiscount: {
          earlyPayAmount: document.documentDiscount
            ? document.documentDiscount.earlyPayAmount
            : null,
          savings: document.documentDiscount ? document.documentDiscount.savings : null,
          earlyPayDate: document.documentDiscount ? document.documentDiscount.earlyPayDate : null
        }
      }
    );
    return documentHeader;
  };

  const calculateDocumentTotalFromSubtotals = document => {
    const taxSubtotals = document.taxSubtotals;

    const checkNan = amount => (!isNaN(amount) ? amount : null);

    const subtractAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) - checkNan(amount));
      else return null;
    };

    const sumAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
      else return null;
    };

    // CALCULATE documentTotal from document subtotals
    const documentHeader = taxSubtotals
      ? taxSubtotals.reduce(
          (acc, subtotal) => {
            acc.totalNetAmount = acc.totalNetAmount
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalNetAmount),
                  parseAndFixDecimal(subtotal.netAmount)
                )
              : subtotal.netAmount;
            acc.totalDiscount = acc.totalDiscount
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalDiscount),
                  parseAndFixDecimal(subtotal.discount)
                )
              : subtotal.discount;
            acc.totalSurcharge = acc.totalSurcharge
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalSurcharge),
                  parseAndFixDecimal(subtotal.surcharge)
                )
              : subtotal.surcharge;
            acc.totalBaseAmount = acc.totalBaseAmount
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalBaseAmount),
                  parseAndFixDecimal(subtotal.baseAmount)
                )
              : subtotal.baseAmount;
            acc.totalTax = acc.totalTax
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalTax),
                  parseAndFixDecimal(subtotal.calculatedTax)
                )
              : subtotal.calculatedTax;
            acc.totalGrossAmount = acc.totalGrossAmount
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc.totalGrossAmount),
                  parseAndFixDecimal(subtotal.grossAmount)
                )
              : subtotal.grossAmount;

            acc.documentDiscount = {
              earlyPayAmount: acc.documentDiscount.earlyPayAmount,
              savings: acc.documentDiscount.savings,
              earlyPayDate: acc.documentDiscount.earlyPayDate
            };

            acc.amount = subtractAndCheckForNaN(
              parseAndFixDecimal(acc.totalGrossAmount),
              parseAndFixDecimal(acc.overpayment)
            );

            return acc;
          },
          {
            totalNetAmount: null,
            totalDiscount: null,
            totalSurcharge: null,
            totalBaseAmount: null,
            totalTax: null,
            totalGrossAmount: null,
            overpayment: document.overpayment,
            paymentDue: document.paymentDue,
            documentDiscount: {
              earlyPayAmount: document.documentDiscount
                ? document.documentDiscount.earlyPayAmount
                : null,
              savings: document.documentDiscount ? document.documentDiscount.savings : null,
              earlyPayDate: document.documentDiscount
                ? document.documentDiscount.earlyPayDate
                : null
            }
          }
        )
      : {};

    return documentHeader;
  };

  const checkEquality = document => {
    // calculated values
    const subtotalsFromItems = checkAmountType(
      calculateTaxSubtotalsFromItems,
      calculateTaxSubtotalsFromItemsPerGross,
      document
    );
    const totalFromItems = checkAmountType(
      calculateDocumentTotalFromItems,
      calculateDocumentTotalFromItemsPerGross,
      document
    );
    const totalFromHeader = calculateDocumentTotalFromSubtotals(document);

    // original values
    const documentSubtotales = document.taxSubtotals;
    const documentTotal = {
      totalNetAmount: document.totalNetAmount,
      totalDiscount: document.totalDiscount,
      totalSurcharge: document.totalSurcharge,
      totalBaseAmount: document.totalBaseAmount,
      totalTax: document.totalTax,
      totalGrossAmount: document.totalGrossAmount,
      overpayment: document.overpayment,
      paymentDue: document.paymentDue,
      amount: document.amount,
      documentDiscount: {
        earlyPayAmount: document.documentDiscount ? document.documentDiscount.earlyPayAmount : null,
        savings: document.documentDiscount ? document.documentDiscount.savings : null,
        earlyPayDate: document.documentDiscount ? document.documentDiscount.earlyPayDate : null
      }
    };

    setEqualsItemsWithTaxSubtotals(
      helperFuncCheckSubtotals(subtotalsFromItems, documentSubtotales)
    );

    setEqualsItemsWithDocumentHeader(helperFuncCheck(totalFromItems, documentTotal));
    setEqualsTaxSubtotalsWithDocumentHeader(helperFuncCheck(totalFromHeader, documentTotal));
  };

  const calculateTaxSubtotalsFromItemsPerGross = (document, responseType = "list") => {
    const { orderItems } = document;

    //************************************************************ */
    const checkNan = amount => (!isNaN(amount) ? amount : null);

    const sumAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
      else return null;
    };

    const calcSurchagre = surchargeDiscount => {
      if (!isNaN(surchargeDiscount)) return surchargeDiscount > 0 ? surchargeDiscount : 0;
      else return null;
    };

    const calcDiscount = surchargeDiscount => {
      if (!isNaN(surchargeDiscount)) return surchargeDiscount < 0 ? Math.abs(surchargeDiscount) : 0;
      else return null;
    };

    // NOTE: must check everything because => null + null = 0
    const calcBaseAmount = (amount, tax) => {
      if (!isNaN(amount) || !isNaN(tax))
        return parseAndFixDecimal(checkNan(amount) - checkNan(calcTaxValue(amount, tax)));
      else return null;
    };

    const calcTaxValue = (amount, tax) => {
      if (!isNaN(amount) || !isNaN(tax))
        return parseAndFixDecimal((checkNan(amount) / (checkNan(tax) + 100)) * checkNan(tax));
      else return null;
    };

    const calculteNetAmount = (amount, vat, surchargeDiscount) => {
      return (
        calcBaseAmount(checkNan(parseAndFixDecimal(amount)), checkNan(parseAndFixDecimal(vat))) -
        checkNan(parseAndFixDecimal(surchargeDiscount))
      );
    };

    //************************************************************ */

    // CALCULATE taxSubtotals from document items
    const taxSubtotals = orderItems
      ? orderItems.reduce((acc, orderItem) => {
          // vat => tax
          // amount => netAmount -> treba da bude ** netAmount ce da bude baseAmount - surchargeDiscount
          // surcharge =>  + surchargeDiscount
          // discount => - surchargeDiscount
          // baseAmount => amount + surchargeDiscount -> treba da bude ** (amount - tax amount) - surchargeSiscount
          // taxValue => (amount + surchargeDiscount) * tax / 100 -> treba da bude amount / 100 + tax

          acc[orderItem.vat] = {
            grossAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].grossAmount),
                  parseAndFixDecimal(orderItem.amount)
                )
              : checkNan(parseAndFixDecimal(orderItem.amount)),
            tax: orderItem.vat,

            baseAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].baseAmount),
                  calcBaseAmount(
                    parseAndFixDecimal(orderItem.amount),
                    parseAndFixDecimal(orderItem.vat)
                  )
                )
              : calcBaseAmount(
                  parseAndFixDecimal(orderItem.amount),
                  parseAndFixDecimal(orderItem.vat)
                ),

            netAmount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].netAmount),
                  parseAndFixDecimal(
                    calculteNetAmount(orderItem.amount, orderItem.vat, orderItem.surchargeDiscount)
                  )
                )
              : parseAndFixDecimal(
                  calculteNetAmount(orderItem.amount, orderItem.vat, orderItem.surchargeDiscount)
                ),

            discount: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].discount),
                  calcDiscount(parseAndFixDecimal(orderItem.surchargeDiscount))
                )
              : calcDiscount(parseAndFixDecimal(orderItem.surchargeDiscount)),
            surcharge: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].surcharge),
                  calcSurchagre(parseAndFixDecimal(orderItem.surchargeDiscount))
                )
              : calcSurchagre(parseAndFixDecimal(orderItem.surchargeDiscount)),

            calculatedTax: acc[orderItem.vat]
              ? sumAndCheckForNaN(
                  parseAndFixDecimal(acc[orderItem.vat].calculatedTax),
                  calcTaxValue(
                    parseAndFixDecimal(orderItem.amount),
                    parseAndFixDecimal(orderItem.vat)
                  )
                )
              : calcTaxValue(
                  parseAndFixDecimal(orderItem.amount),
                  parseAndFixDecimal(orderItem.vat)
                )
          };
          return acc;
        }, {})
      : {};

    if (responseType === "list") return Object.keys(taxSubtotals).map(tax => taxSubtotals[tax]);
    else return taxSubtotals;
  };

  const calculateDocumentTotalFromItemsPerGross = document => {
    const taxSubtotals = calculateTaxSubtotalsFromItemsPerGross(document, "list");

    const checkNan = amount => (!isNaN(amount) ? amount : null);

    const sumAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) + checkNan(amount));
      else return null;
    };

    const subtractAndCheckForNaN = (accAmount, amount) => {
      if (!isNaN(accAmount) || !isNaN(amount))
        return parseAndFixDecimal(checkNan(accAmount) - checkNan(amount));
      else return null;
    };

    const calculateSavings = (amount, earlyPayAmount, earlyPayDate) => {
      if (!isNaN(amount) && !isNaN(earlyPayAmount)) {
        const currentDate = new Date();
        const earlyPaymentDate = new Date(earlyPayDate);

        return currentDate < earlyPaymentDate && earlyPayAmount > 0
          ? parseAndFixDecimal(amount - earlyPayAmount)
          : parseAndFixDecimal(0);
      } else {
        return parseAndFixDecimal(0);
      }
    };

    // CALCULATE documentTotal from document items (items=>subtotals=>documentTotal)
    const documentHeader = taxSubtotals.reduce(
      (acc, subtotal) => {
        acc.totalNetAmount = acc.totalNetAmount
          ? sumAndCheckForNaN(acc.totalNetAmount, subtotal.netAmount)
          : subtotal.netAmount;
        acc.totalDiscount = acc.totalDiscount
          ? sumAndCheckForNaN(
              parseAndFixDecimal(acc.totalDiscount),
              parseAndFixDecimal(subtotal.discount)
            )
          : subtotal.discount;
        acc.totalSurcharge = acc.totalSurcharge
          ? sumAndCheckForNaN(
              parseAndFixDecimal(acc.totalSurcharge),
              parseAndFixDecimal(subtotal.surcharge)
            )
          : subtotal.surcharge;
        acc.totalBaseAmount = acc.totalBaseAmount
          ? sumAndCheckForNaN(acc.totalBaseAmount, subtotal.baseAmount)
          : subtotal.baseAmount;
        acc.totalTax = acc.totalTax
          ? sumAndCheckForNaN(acc.totalTax, subtotal.calculatedTax)
          : subtotal.calculatedTax;
        acc.totalGrossAmount = acc.totalGrossAmount
          ? sumAndCheckForNaN(acc.totalGrossAmount, subtotal.grossAmount)
          : subtotal.grossAmount;
        acc.overpayment = checkNan(parseAndFixDecimal(acc.overpayment));

        acc.amount = subtractAndCheckForNaN(
          parseAndFixDecimal(acc.totalGrossAmount),
          parseAndFixDecimal(acc.overpayment)
        );
        acc.documentDiscount = {
          earlyPayAmount: acc.documentDiscount.earlyPayAmount,

          savings: sumAndCheckForNaN(
            parseAndFixDecimal(
              calculateSavings(
                parseAndFixDecimal(acc.amount),
                parseAndFixDecimal(acc.documentDiscount.earlyPayAmount),
                acc.documentDiscount.earlyPayDate
              )
            )
          ),
          earlyPayDate: acc.documentDiscount.earlyPayDate
        };

        return acc;
      },
      {
        totalNetAmount: null,
        totalDiscount: null,
        totalSurcharge: null,
        totalBaseAmount: null,
        totalTax: null,
        totalGrossAmount: null,
        overpayment: document.overpayment,
        paymentDue: document.paymentDue,
        documentDiscount: {
          earlyPayAmount: document.documentDiscount
            ? document.documentDiscount.earlyPayAmount
            : null,
          savings: document.documentDiscount ? document.documentDiscount.savings : null,
          earlyPayDate: document.documentDiscount ? document.documentDiscount.earlyPayDate : null
        }
      }
    );
    return documentHeader;
  };

  const checkAmountType = (myFirstFunc, mySecondFunc, document) => {
    return !document.isGross ? myFirstFunc(document) : mySecondFunc(document);
  };

  // recalculate based on Items
  const recalculate = document => {
    const taxSubtotals = checkAmountType(
      calculateTaxSubtotalsFromItems,
      calculateTaxSubtotalsFromItemsPerGross,
      document
    );
    const total = checkAmountType(
      calculateDocumentTotalFromItems,
      calculateDocumentTotalFromItemsPerGross,
      document
    );

    const newDocument = {
      ...document,
      ...total,
      taxSubtotals: taxSubtotals
    };

    return newDocument;
  };

  // recalculate based on Subtotals
  const recalculateBaseOnSubtotals = document => {
    const total = calculateDocumentTotalFromItems(document);

    const newDocument = {
      ...document,
      ...total
    };

    return newDocument;
  };

  return {
    equalsItemsWithTaxSubtotals,
    equalsItemsWithDocumentHeader,
    equalsTaxSubtotalsWithDocumentHeader,
    calculateItems,
    calculateTaxSubtotalsFromItems,
    calculateDocumentTotalFromItems,
    calculateDocumentTotalFromSubtotals,
    checkEquality,
    recalculate,
    recalculateBaseOnSubtotals
  };
};

const parseAndFixDecimal = (amount, decimalSize = 2) => {
  return parseFloat(parseFloat(amount).toFixed(2));
};

const helperFuncCheckSubtotals = (obj1, obj2) => {
  let documentSubtotalesWithoutKey = obj2
    ? obj2.map(ds => {
        return {
          tax: ds.tax,
          netAmount: ds.netAmount,
          discount: ds.discount,
          surcharge: ds.surcharge,
          baseAmount: ds.baseAmount,
          calculatedTax: ds.calculatedTax,
          grossAmount: ds.grossAmount
        };
      })
    : obj2;

  return isEqual(obj1, documentSubtotalesWithoutKey);
};

// check one with second object
const helperFuncCheck = (obj1, obj2) => {
  const formatAmount = amount => {
    if (!isNaN(amount) && amount !== null) {
      return parseAndFixDecimal(amount);
    } else {
      return amount;
    }
  };

  if (
    formatAmount(obj1.totalBaseAmount) === formatAmount(obj2.totalBaseAmount) &&
    formatAmount(obj1.totalDiscount) === formatAmount(obj2.totalDiscount) &&
    formatAmount(obj1.totalGrossAmount) === formatAmount(obj2.totalGrossAmount) &&
    formatAmount(obj1.totalNetAmount) === formatAmount(obj2.totalNetAmount) &&
    formatAmount(obj1.totalSurcharge) === formatAmount(obj2.totalSurcharge) &&
    formatAmount(obj1.totalTax) === formatAmount(obj2.totalTax)
  )
    return true;
  else return false;
};

export default useRecalculate;
