export const docsInitFilterState = {
  activeDocumentDirectionFilter: "incoming",
  activeDocumentTypeFilter: "invoice",
  activeVerificationStatusFilter: "all",
  activeDueStatusFilter: "all",
  activeEarlyDueStatusFilter: "all",
  activePaymentStatusFilter: "all",
  activeRevenuePaymentStatusFilter: "all",
  activeArchiveFilter: "",
  activeProgressStatusFilter: "all",
  sortColumn: null,
  sortDirection: null,
  activeInputNumberSearch: null,
  partnerSearch: "",
  dateTypeValue: "",
  number: "",
  match: "",
  fromDate: "all",
  toDate: "all",
  savings: "",
  partnerName: "",
  partnerOriginalId: "",
  documentDate: "",
  minDate: "",
  maxDate: "",
  amount: ""
};

export const partnersInitStateFilters = {
  activeArchiveFilter: "",
  activeTypeFilter: "all",
  activeRoleFilter: "all",
  search: "",
  name: "",
  number: "",
  city: "",
  sortColumn: null,
  sortDirection: null,
  activeNotification: false
};
