import * as constant from "../Constants";
import { BaseService, BaseBuilder, BaseQuery } from "../Base";

class CustomBuilder extends BaseBuilder {
  uniqueCheck(value) {
    this.parameter.uniqueCheck = value;
    return this;
  }

  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.post,
      resource: constant.BASE.resource.documents
    };
    this.query = new CustomQuery.Builder();
  }
}
