import React, { useState, useEffect } from "react";
import GlobalDetails from "../../Components/Panels/GlobalDetails";
import GlobalForm from "../../Components/Panels/GlobalForm";
import * as property from "./DunningConfig";
import Localize from "react-intl-universal";
import DunningDetails from "./DunningDetails";
import DunningForm from "./DunningForm";
import useDunningServices from "../../RestServicesNew/useDunningServices";

const DunningRightPanel = props => {
  const { currentView, setCurrentView, currentModule, selectedItem } = props;

  const [errorFields, setErrorFields] = useState({});

  const { selectedDunning, fetchDunningCall, setSelectedDunning } = useDunningServices(
    selectedItem,
    currentView
  );

  const getDetailsConfig = () => {
    return property[selectedItem.name.subcategoryName];
  };

  const myErrorHander = (error, showNotificationMessage) => {
    if (error.response.status === 400) {
      let errorResponse = error.response.data;
      let errorObj = {};
      // eslint-disable-next-line array-callback-return
      errorResponse.map(item => {
        errorObj[item.validationSubject] = Localize.get(item.validationMessage);
      });
      setErrorFields(errorObj);
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };

  useEffect(() => {
    setErrorFields(prevPara => {
      return {
        ...Object.keys(prevPara).reduce((reduced, key) => ({ ...reduced, [key]: "" }), {})
      };
    });
  }, [currentView]);

  useEffect(() => {
    fetchDunningCall();
  }, [selectedItem]);

  return (
    <>
      {selectedItem && currentView === "details" ? (
        <GlobalDetails
          selectedItem={selectedItem}
          currentView={currentView}
          currentModule={currentModule}
          setCurrentView={setCurrentView}
          detailsHeaderText={"SETTINGS.HEADERS.PROP_DETAILS"}
          availableItemFieldsForDetails={getDetailsConfig()}
          disabledIcons={selectedDunning.length > 0 ? ["delete"] : ["delete", "edit"]}
          CustomDetails={DunningDetails}
          customProps={{
            selectedDunning,
            setSelectedDunning,
            setCurrentView
          }}
        />
      ) : currentView === "edit" || currentView === "create" ? (
        <GlobalForm
          selectedItem={selectedDunning}
          setCurrentView={setCurrentView}
          currentView={currentView}
          currentModule={currentModule}
          formHeaderText={"SETTINGS.HEADERS.EDIT_PROP"}
          availableItemFieldsForDetails={getDetailsConfig()}
          CustomForm={DunningForm}
          customErrorHandler={myErrorHander}
          parameters={errorFields}
        />
      ) : null}
    </>
  );
};

export default DunningRightPanel;
