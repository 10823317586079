import React, { useState, useEffect, useContext } from "react";
import SideBar from "./LeftPanel/SideBar";
import MiddlePanel from "./MiddlePanel/MiddlePanel";
import RightPanel from "./RightPanel/RightPanel";
import DocumentItem from "./MiddlePanel/DocumentItem";
import { AuthContext } from "../Auth/AuthContext";
import * as constant from "./Constants.js";
import { BASE } from "../RestService/Constants";
import ConfirmDeleteDialog from "../Dialogs/ConfirmDeleteDialog";
import ConfirmCancelDialog from "../Dialogs/ConfirmCancelDialog";
import ConfirmArchiveDialog from "../Dialogs/ConfirmArchiveDialog";
import Localize from "react-intl-universal";
import {
  createNewDocument,
  createNewDeliveryDocument,
  getEmptyDocumentObject,
  // eslint-disable-next-line no-unused-vars
  getOCRDocumentReadObject,
  getEditedDocumentObject,
  convertDocumentComments,
  formatDate
} from "./Util.js";
import * as RestService from "../RestService";
import showNotification from "../Notifications/NotificationFunction";
import * as notificationConstants from "../Notifications/Constants.js";
import * as componentsConstants from "../Components/Constants";
import documentSample from "../Assets/Images/document_sample.png";
import { Loader, Dimmer, Modal, Button, Sidebar, Segment, Icon } from "semantic-ui-react";
import styles from "./Documents.module.css";
import useCreateDocument from "./useCreateDocument";
import useFetchDocuments from "./useFetchDocuments";
import useDocumentsServices from "../RestServicesNew/useDocumentsServices";
import useFetch from "../RestServicesNew/useFetch";
import { downloadDocById } from "../RestServicesNew/DocumentsServices";
import useFilterStates from "../CustomHooks/useFilterStates";
import { docsInitFilterState } from "../Auth/Constants";
import GlobalModal from "../Components/Panels/GlobalModal";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

const Documents = props => {
  const { authState, rolePrivileges } = useContext(AuthContext);
  const privileges = rolePrivileges["document"];
  const projectPrivileges = rolePrivileges["project"];
  const partnerPrivileges = rolePrivileges["partner"];
  const paymentPrivileges = rolePrivileges["payment"];
  const manualPaymentPrivileges = rolePrivileges["manualPayment"];
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingScansList, setIsLoadingScansList] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState({
    ...constant.initialState,
    paymentIncrement: 0
  });
  const [thumbsToSend, setThumbsToSend] = useState([]); // ??
  const { setPathOnlyOneAtTime, documentsPanelState, resetDocumentsFilters } = useFilterStates(
    "documents"
  );

  const [selectedDocument, setSelectedDocument] = useState();
  const history = useHistory();

  const [linkedDocumentsPreview, setLinkedDocumentsPreview] = useState(); // ??
  const [checkedDocs, setCheckedDocs] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [scanedFiles, setScanedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showArchModal, setShowArchModal] = useState(false);
  const [showGeneratePDFModal, setShowGeneratePDFModal] = useState(false);

  const [archInfo, setArchInfo] = useState({ id: null, partnerArchived: null });
  const [documentsCommentHistory, setDocumentsCommentHistory] = useState();
  const [parentDocumentForLinkedDoc, setParentDocumentForLinkedDoc] = useState(null);
  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [downloadIds, setDownloadIds] = useState([]);

  const [paginationScanedFiles, setPaginationScanedFiles] = useState(
    componentsConstants.paginationInitStatus
  );
  const [activePaginationScandedFilesPage, setActivePaginationScandedFilesPage] = useState(1);

  const [deleteLinkedDocumentsModal, setDeleteLinkedDocumentsModal] = useState({
    show: false,
    listOfLinkedDocuments: []
  });

  const { setHeaderDimmed, setActiveItem } = props;

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.DOCUMENT"),
      ...param
    });
  };

  let delayTimer;
  const [firstBuild, setFirstBild] = useState(true);

  const [newDocOnSave, setNewDocOnSave] = useState();
  const [updateDoc, setUpdateDoc] = useState({});

  const {
    fetchData,
    documents,
    setDocuments,
    activePaginationPage,
    setActivePaginationPage,
    paginationStatus,
    isLoading,
    setIsLoading,
    unverifiedPartners
  } = useFetchDocuments(
    state,
    selectedDocument,
    setSelectedDocument,
    setState,
    documentsPanelState
  );

  setHeaderDimmed(editMode || isLoadingUpdate || isLoading);

  // HOOK for create document!!!
  const [
    originalDocument,
    setOriginalDocument,
    newlyCreatedDocument,
    setNewlyCreatedDocument,
    showSameDocNumbModal,
    setShowSameDocNumbModal,
    invalidCorrectionAmount,
    // eslint-disable-next-line no-unused-vars
    setInvalidCorrectionAmount,
    invalidInvoiceAmount,
    // eslint-disable-next-line no-unused-vars
    setInvalidInvoiceAmount,
    documentPreview,
    setDocumentPreview,
    isPDFPreview,
    setIsPDFPreview,
    createDocumentFromUploaded,
    createDocFromFile,
    readOCRDocumentFromUploaded
  ] = useCreateDocument(
    state,
    setState,
    setIsLoadingForm,
    setIsLoadingScansList,
    showNotificationMessage,
    setActivePaginationPage,
    setLinkedDocumentsPreview,
    setThumbsToSend,
    setEditMode,
    documentsPanelState
  );

  const togglePreview = () => {
    handler.showScanHandler();
  };

  const {
    postDocumentCall,
    postGenerateAndCombine,
    postLoading,
    respMessage,
    postDownloadImported,
    isDownloadLoading,
    downloadAndSendResponse,
    postSendMail,
    sendLoading,
    isPreviewLoading,
    getImportedDocPreview
  } = useDocumentsServices(
    fetchData,
    setDocuments,
    documentsPanelState,
    setLinkedDocumentsPreview,
    selectedDocument,
    togglePreview
  );

  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const getSelectedIndex = docId => {
    let selectedIndex;
    let indexNum = documents.findIndex(element => element.id === docId);
    if (indexNum !== 0) {
      selectedIndex = indexNum - 1;
    } else {
      if (activePaginationPage > 1) {
        selectedIndex = 19;
        setActivePaginationPage(activePaginationPage - 1);
      } else {
        selectedIndex = indexNum;
      }
    }
    return selectedIndex;
  };

  const callbackGetAllCommentsSuccess = response => {
    const documentsCommentHistory = convertDocumentComments(response.data);
    setDocumentsCommentHistory(documentsCommentHistory);
  };

  const callbackGetAllError = error => {};

  const callbackEditDocumentSuccess = response => {
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.update
    });
    setState({
      ...state,
      selectAll: false,
      activeMiddlePanelMode: constant.middlePanelMode.documentList,
      activeRightPanelMode: constant.rightPanelMode.documentDetails,
      activeSideBarDimmer: false,
      panelMode: constant.panelMode.details
    });
    setDocumentPreview(null);
    if (paginationStatus.pageCount < activePaginationPage) {
      setActivePaginationPage(1);
    }
    setEditMode(false);
  };

  const callbackEditDocumentFinaly = response => {};

  const callbackUpdateSuccess = (response, selectedIndex = null) => {
    let checkedIdArr = documents.filter(doc => doc.checked).map(d => d.id);
    fetchData();

    if (checkedIdArr.length > 1) {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.DOCUMENTS"),
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.update
      });
    } else {
      showNotificationMessage({
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.update
      });
    }
  };

  const callbackArchUpdateSuccess = response => {
    let checkedIdArr = documents.filter(doc => doc.checked).map(d => d.id);
    let firstArchIndex;

    if (checkedIdArr.length !== 0) {
      firstArchIndex = documents.findIndex(element => element.id === checkedIdArr[0]);
    } else {
      firstArchIndex = 0;
    }

    let selectedIndex;
    if (firstArchIndex !== 0) {
      selectedIndex = firstArchIndex - 1;
    } else {
      if (activePaginationPage > 1) {
        selectedIndex = 19;
        setActivePaginationPage(activePaginationPage - 1);
      } else {
        selectedIndex = firstArchIndex;
      }
    }

    fetchData(selectedIndex);
    setState({ ...state, activeRightPanelMode: "documentDetails" });
    if (checkedIdArr.length > 1) {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.DOCUMENTS"),
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.update
      });
    } else {
      showNotificationMessage({
        type: notificationConstants.notificationType.info,
        operation: notificationConstants.notificationOperation.update
      });
    }
  };

  const callbackUpdateFinaly = response => {
    setIsLoadingUpdate(false);
  };

  const callbackCreateCommentsSuccess = response => {};

  const callbackCreateCommentsFinaly = response => {};

  const callbackUpdateError = response => {
    if (response && response.response && response.response.data && response.response.data.message) {
      showNotificationMessage({
        type: notificationConstants.notificationType.warning,
        custom: response.response.data.message
      });
    } else {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.DOCUMENT"),
        type: notificationConstants.notificationType.warning,
        operation: notificationConstants.notificationOperation.update
      });
    }
  };

  const callbackGetBinaryContentSuccess = response => {
    var reader = new window.FileReader();
    if (!response.headers["content-type"].includes("pdf")) {
      reader.readAsDataURL(response.data);
      reader.onload = function() {
        setLinkedDocumentsPreview({
          isPDF: response.headers["content-type"].includes("pdf"),
          data: reader.result
        });
      };
    } else {
      const urlCreator = window.URL || window.webkitURL;
      const file = new Blob([response.data], { type: "application/pdf" });
      setLinkedDocumentsPreview({
        isPDF: response.headers["content-type"].includes("pdf"),
        data: urlCreator.createObjectURL(file)
      });
    }
  };

  const callbackDeleteSuccess = async (response, docId, documentsLength) => {
    clearCheckedAll();

    if (response && response.status === 226) {
      if (documentsPanelState.activeArchiveFilter === "archived") {
        showNotificationMessage({
          serverMessage: Localize.get("DOCUMENTS.LINKED_DOCUMENTS_CANNOT_BE_DELETED"),
          type: "warning",
          operation: "delete"
        });
      } else {
        let myArr = JSON.parse(response.data.message);
        setDeleteLinkedDocumentsModal({
          show: true,
          listOfLinkedDocuments: myArr
        });
      }
    } else if (documentsLength > 1) {
      showNotificationMessage({
        entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.DOCUMENTS"),
        type: "info",
        operation: "delete"
      });
    } else {
      showNotificationMessage({
        type: "info",
        operation: "delete"
      });
    }
    setState({ ...state, activeRightPanelMode: "documentDetails" });
    let selectedIndex = getSelectedIndex(docId);
    fetchData(selectedIndex);
  };

  const callbackDeleteError = async response => {
    setShowModal(false);

    await fetchData();

    showNotificationMessage({
      type: "warning",
      operation: "delete"
    });
  };

  const callbackDeleteFinaly = response => {};

  const deleteSelectedDocument = async (docId, documentId, documentsLength) => {
    let serviceDelete;
    serviceDelete = new RestService.Document.Delete.Service(documentId);

    serviceDelete.setCallbackSuccess(res => {
      callbackDeleteSuccess(res, docId, documentsLength);
    });
    serviceDelete.setCallbackError(callbackDeleteError);
    serviceDelete.setCallbackFinally(callbackDeleteFinaly);
    await serviceDelete.call();
  };

  let accountService;

  const resolveAccountService = () => {
    accountService = new RestService.Accounts.GetAll.Service();
    accountService.setCallbackSuccess(callbackGetPartnerSuccess);
    accountService.setCallbackError(callbackGetPartnerError);
    accountService.setCallbackFinally(callbackGetPartnerFinally);
    accountService.call();
  };

  const callbackGetPartnerError = error => {};

  const callbackGetPartnerFinally = response => {};

  const callbackGetPartnerSuccess = response => {
    setAccounts(response.data);
  };

  const callbackGetAllCommentsFinally = () => {};

  const getServiceComments = documentId => {
    const serviceGetComments = new RestService.Document.GetCommnetsById.Service(documentId);
    serviceGetComments.setCallbackSuccess(callbackGetAllCommentsSuccess);
    serviceGetComments.setCallbackError(callbackGetAllError);
    serviceGetComments.setCallbackFinally(callbackGetAllCommentsFinally);
    serviceGetComments.call();
  };

  const callbackPaymentStatusSuccess = response => {
    setState({
      ...state,
      activeMiddlePanelMode: constant.middlePanelMode.documentList,
      paymentIncrement: state.paymentIncrement + 1
    });
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      serverMessage: response.data.customMessage
        ? response.data.customMessage
        : response.data.successMessage,
      operation: notificationConstants.notificationOperation.payment
    });
  };

  const callbackPaymentStatusError = response => {
    showPaymentErrorNotification(
      response.response && response.response.data && response.response.data.message
        ? response.response.data.message
        : response.response.data
    );
  };

  const callbackGetPaymentStatusSuccess = response => {
    let documnetsArr = documents;
    if (response.data[0].id) {
      let documentResponse = response.data[0].id;
      let foundIndex = documnetsArr.findIndex(accnt => accnt.id === documentResponse);
      documnetsArr.splice(foundIndex, 1, response.data[0]);
    }
    setDocuments(documnetsArr);
  };

  const callbackGetPaymentStatusError = response => {};

  const callbackPaymentStatusFinaly = response => {};

  const callbackGetScanedFilesSuccess = response => {
    setScanedFiles(response.data.data);

    setPaginationScanedFiles({
      perPage: response.data.paging.perPage,
      pageCount: response.data.paging.pageCount,
      totalCount: response.data.paging.totalCount
    });

    setState({
      ...state,
      selectAll: false,
      activeMiddlePanelMode: constant.middlePanelMode.documentScanList,
      activeRightPanelMode: constant.rightPanelMode.documentScansPanel,
      panelMode: constant.panelMode.create,
      activeScanesListItem: response.data.data[0].id,
      activeScanesListItemId: response.data.data[0].id,
      selectedfilebinaryid: response.data.data[0].binaryContent.id
    });

    getDocumentBinaryContnt(response.data.data[0].binaryContent.id, true);
    setOriginalDocument(response.data.data[0].binaryContent);
  };

  const callbackGetScanedFilesError = response => {};

  const callbackGetGetScanedFilesFinaly = response => {};

  const showPaymentErrorNotification = message => {
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      serverMessage: message,
      operation: notificationConstants.notificationOperation.payment
    });
  };

  const updateDocFromFile = (id, editedDoc, uniqueCheck) => {
    const serviceUpdate = new RestService.Document.Update.Service(id);
    serviceUpdate.setPayload(editedDoc);
    serviceUpdate.setCallbackSuccess(callbackEditDocumentSuccess);
    serviceUpdate.setCallbackError(callbackUpdateError);
    serviceUpdate.setCallbackFinally(callbackEditDocumentFinaly);
    if (uniqueCheck) {
      serviceUpdate.query.uniqueCheck(uniqueCheck);
    }

    return serviceUpdate;
  };

  const createPaymentServiceStatusChecked = (accId, docs) => {
    const servicePayment = new RestService.Payment.PaymentById.Service(accId);
    let ids = [];
    for (let index = 0; index < docs.length; index++) {
      ids.push(docs[index].id ? docs[index].id : docs[index].docId);
    }

    servicePayment.query.clearPageSize();
    servicePayment.query.clearPage();
    servicePayment.query.addDocumentIds(ids.join(","));
    servicePayment.setCallbackSuccess(callbackPaymentStatusSuccess);
    servicePayment.setCallbackError(callbackPaymentStatusError);
    servicePayment.setCallbackFinally(callbackPaymentStatusFinaly);
    return servicePayment;
  };

  const getDocumentBinaryContnt = (id, isBinaryContentId = false) => {
    if (id) {
      let selDoc = null;
      let serviceGetContent = null;
      if (!isBinaryContentId) {
        selDoc = documents.find(doc => doc.id.toString() === id.toString());
        if (selDoc.binaryContent && selDoc.binaryContent.id) {
          serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(
            selDoc.binaryContent.id
          );
        }
      } else {
        serviceGetContent = new RestService.Document.GetDocumentBinaryContent.Service(id);
      }

      if (serviceGetContent) {
        serviceGetContent.query.addResponseType("blob");
        serviceGetContent.setCallbackSuccess(callbackGetBinaryContentSuccess);
        serviceGetContent.setCallbackError(callbackGetAllError);
        serviceGetContent.call();
      } else {
        setLinkedDocumentsPreview({
          isPDF: false,
          data: documentSample
        });
      }
    }
  };

  const createCommentSave = async (newDocument, history, documentField) => {
    const serviceCommentsCreate = new RestService.Document.CreateComment.Service();
    const comment = {
      document: { id: newDocument.id },
      changeDate: formatDate(history[0].timestamp),
      documentField: documentField,
      newValue: history[0].action,
      comment: history[0].note,
      appUser: { id: authState.user.id }
    };

    serviceCommentsCreate.setPayload(comment);
    serviceCommentsCreate.setCallbackSuccess(callbackCreateCommentsSuccess);
    serviceCommentsCreate.setCallbackError(callbackUpdateError);
    serviceCommentsCreate.setCallbackFinally(callbackCreateCommentsFinaly);
    serviceCommentsCreate.call();
  };

  const callbackParentDocSuccess = response => {
    setParentDocumentForLinkedDoc({ ...response.data, isParent: true });
  };

  const callbackParentDocError = response => {};

  const callbackParentDocFinaly = response => {};

  const getParentDocForLinkedChild = async parentId => {
    if (parentId) {
      const serviceGetParent = new RestService.Document.GetById.Service(parentId);
      serviceGetParent.setCallbackSuccess(callbackParentDocSuccess);
      serviceGetParent.setCallbackError(callbackParentDocError);
      serviceGetParent.setCallbackFinally(callbackParentDocFinaly);
      await serviceGetParent.call();
    }
  };

  // Get scand docs service call
  const getScanedFiles = async pageInfo => {
    const getScanedFiles = new RestService.Document.GetAllScanedFiles.Service();
    getScanedFiles.query.processed();
    if (pageInfo) {
      getScanedFiles.query.setPage(pageInfo);
    }
    getScanedFiles.setCallbackSuccess(callbackGetScanedFilesSuccess);
    getScanedFiles.setCallbackError(callbackGetScanedFilesError);
    getScanedFiles.setCallbackFinally(callbackGetGetScanedFilesFinaly);
    return getScanedFiles.call();
  };

  const checkPaymentStatusService = document => {
    if (document.paymentStatus === constant.paymentStatus.pending) {
      const serviceGetPaymentStatus = new RestService.Payment.GetPaymentStatus.Service();
      const paymentIds = document.payments.map(payments =>
        payments.status === constant.paymentStatus.pending ||
        payments.status === constant.paymentStatus.open
          ? payments.id
          : []
      );
      serviceGetPaymentStatus.query.addPaymentId(paymentIds.join(","));
      serviceGetPaymentStatus.query.addDocumentId(document.id);
      serviceGetPaymentStatus.setCallbackSuccess(callbackGetPaymentStatusSuccess);
      serviceGetPaymentStatus.setCallbackError(callbackGetPaymentStatusError);
      serviceGetPaymentStatus.setCallbackFinally(callbackPaymentStatusFinaly);
      serviceGetPaymentStatus.call();
    }
  };

  // This funtion is added for reset button and asynchronous programming
  const setStateToInitaial = () => {
    setState({
      ...state,
      selectAll: false,
      pickerTouched: false
    });
    resetDocumentsFilters();
    if (activePaginationPage !== 1) {
      setActivePaginationPage(1);
      setState({ ...state });
    }
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  const functionForUseEff = () => {
    fetchData();
  };

  useEffect(() => {
    if (state.activeMiddlePanelMode === constant.middlePanelMode.documentList) {
      clearCheckedAll();
      functionForUseEff();
      clearTimeout(delayTimer);
    }
  }, [
    state.activeMiddlePanelMode,
    documentsPanelState.activeDocumentDirectionFilter,
    documentsPanelState.activeDocumentTypeFilter,
    documentsPanelState.activeVerificationStatusFilter,
    documentsPanelState.activeDueStatusFilter,
    documentsPanelState.activeEarlyDueStatusFilter,
    documentsPanelState.activePaymentStatusFilter,
    documentsPanelState.activeRevenuePaymentStatusFilter,
    documentsPanelState.activeArchiveFilter,
    documentsPanelState.activeProgressStatusFilter,
    documentsPanelState.sortColumn,
    documentsPanelState.sortDirection,
    state.approval,
    documentsPanelState.documentDate,
    documentsPanelState.minDate,
    documentsPanelState.maxDate,
    documentsPanelState.fromDate,
    documentsPanelState.toDate,
    documentsPanelState.due,
    documentsPanelState.createdAt,
    state.paymentIncrement,
    activePaginationPage
  ]);

  useEffect(() => {
    if (state.activeMiddlePanelMode === constant.middlePanelMode.documentList) {
      clearCheckedAll();
      let isSubscribed = true;
      delayTimer = setTimeout(
        function() {
          if (isSubscribed && !firstBuild) {
            functionForUseEff();
          }
        },
        documentsPanelState.activeInputNumberSearch === null &&
          documentsPanelState.partnerSearch === "" &&
          documentsPanelState.amount === constant.initialState.amount &&
          documentsPanelState.savings === constant.initialState.savings &&
          documentsPanelState.number === constant.initialState.number
          ? 0
          : 800
      );
      setFirstBild(false);

      return () => (isSubscribed = false);
    }
  }, [
    documentsPanelState.number,
    documentsPanelState.amount,
    documentsPanelState.savings,
    documentsPanelState.activeInputNumberSearch,
    documentsPanelState.partnerSearch
  ]);

  useEffect(() => {
    if (state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay) {
      resolveAccountService();
    }
    if (
      selectedDocument !== undefined &&
      state.activeRightPanelMode === constant.rightPanelMode.documentShowScan
    ) {
      let selDocumentId = selectedDocument.id;
      if (get(selectedDocument, "binaryContent")) {
        getDocumentBinaryContnt(selDocumentId);
        return;
      }
      getImportedDocPreview();
    }
  }, [selectedDocument, state.activeRightPanelMode]);

  useEffect(() => {
    if (selectedDocument !== undefined) {
      if (selectedDocument && selectedDocument.parentDocument) {
        setThumbsToSend(selectedDocument.documents);
        getParentDocForLinkedChild(selectedDocument.parentDocument.id);
      } else {
        setThumbsToSend(selectedDocument.documents);
      }
      checkPaymentStatusService(selectedDocument);
      getServiceComments(selectedDocument.id);
    }
  }, [selectedDocument]);

  const callbackDownloadSuccess = res => {
    const disposition = res.request.getResponseHeader("Content-Disposition");
    let fileName = "";
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
    let blob = new Blob([res.data], { type: "application/zip" });
    const downloadUrl = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  };

  let checkDocString = downloadIds.toString();

  const [, , isDownloadDocLoading] = useFetch(
    downloadDocById,
    undefined,
    "/documents/export",
    { ids: checkDocString },
    callbackDownloadSuccess,
    undefined,
    "arraybuffer"
  );

  const handler = {
    onPayButtonClick: (paymentAccountId, documents) => {
      setState({
        ...state,
        activeMiddlePanelMode: constant.middlePanelMode.documentList
      });

      createPaymentServiceStatusChecked(paymentAccountId, documents).call();

      setCheckedDocs([]);
    },
    cancelPayMode: () => {
      setState({
        ...state,
        activeMiddlePanelMode: constant.middlePanelMode.documentList
      });
      setCheckedDocs([]);
      setEditMode(false);
    },
    setPayMode: () => {
      resolveAccountService();
      let checkedDoc = documents.find(doc => doc.checked);
      setState({
        ...state,
        activeMiddlePanelMode: constant.middlePanelMode.documentForPay
      });
      setSelectedDocument(checkedDoc);
      setEditMode(true);
    },
    setPaymentErrorNotification: message => {
      showPaymentErrorNotification(message);
    },
    handleSearchByDocumentNumber: async (e, data) => {
      clearTimeout(delayTimer);
      setPathOnlyOneAtTime({
        ...documentsPanelState,
        activeInputNumberSearch: data.value
      });
      setSelectedDocument();
    },
    showScanHandler: () => {
      state.activeRightPanelMode === constant.rightPanelMode.documentDetails
        ? setState({
            ...state,
            activeRightPanelMode: constant.rightPanelMode.documentShowScan
          })
        : setState({
            ...state,
            activeRightPanelMode: constant.rightPanelMode.documentDetails
          });
    },
    showLinkedDocumentHandler: selDocumentId => {
      if (selDocumentId && selDocumentId.binaryContent) {
        getDocumentBinaryContnt(selDocumentId.binaryContent.id, true);
      }

      state.activeRightPanelMode === constant.rightPanelMode.documentDetails
        ? setState({
            ...state,
            activeRightPanelMode: constant.rightPanelMode.linkDocumentPreview
          })
        : setState({
            ...state,
            activeRightPanelMode: constant.rightPanelMode.documentDetails
          });
    },
    showProjectsHandler: () => {
      let selDocumentId = selectedDocument.id.toString();
      getDocumentBinaryContnt(selDocumentId);
      setState({
        ...state,
        selectAll: false,
        activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
        activeRightPanelMode: constant.rightPanelMode.projectsPanel,
        panelMode: constant.panelMode.edit
      });
    },
    onArchiveClick: (id, partnerArchived) => {
      setDocuments(
        documents.map(document => {
          document.id === id ? (document.checked = true) : (document.checked = false);
          return document;
        })
      );

      setArchInfo({ id: id, partnerArchived: partnerArchived });
      setShowArchModal(true);
    },
    documentList: {
      onSearchChange: async (e, data) => {
        if (isLoading) return;
        clearTimeout(delayTimer);
        setPathOnlyOneAtTime({
          ...documentsPanelState,
          [data.name]: data.value
        });

        setSelectedDocument();
        setActivePaginationPage(1);
      },
      onSortButtonClick: async (field, direction) => {
        if (isLoading) return;

        setPathOnlyOneAtTime({
          ...documentsPanelState,
          sortColumn: field,
          sortDirection: direction
        });
        setSelectedDocument();
        setActivePaginationPage(1);
      },
      onListItemClick: doc => {
        setSelectedDocument(doc);
      },
      onReset: () => {
        setDocuments(
          documents.map(document => {
            document.checked = false;
            return document;
          })
        );
      },
      resetToolbarSearch: () => {
        setPathOnlyOneAtTime({
          ...documentsPanelState,
          activeInputNumberSearch: null
        });
        setSelectedDocument();
      },
      onToggleAll: () => {
        setDocuments(
          documents.map(document => {
            document.checked = !state.selectAll;
            return document;
          })
        );
        setState({ ...state, selectAll: !state.selectAll });
      },
      onToggleItem: (e, data) => {
        e.stopPropagation();
        setDocuments(
          documents.map(doc => {
            if (doc.id === data.item_id) {
              doc.checked = !doc.checked;
              if (editMode && !doc.checked) {
                doc.uncheckedInPaymentMode = !doc.checked;
              }
            }
            return doc;
          })
        );
      },
      revertCheckItem: (e, data) => {
        e.stopPropagation();
        setDocuments(
          documents.map(doc => ({
            ...doc,
            checked: (doc.checked = doc.id === data.item_id ? !doc.checked : doc.checked)
          }))
        );
      },
      onClearClick: async (e, data) => {
        // this is an init state of filterObject, this whole logic should be repacked and moved to constants
        setStateToInitaial();
      },
      onMiddlePanelDeleteClick: e => {
        if (documents.filter(doc => doc.checked).length > 0) {
          setShowDelModal(true);
        }
      },
      onRightPanelDeleteClick: () => {
        clearCheckedAll();
        setShowDelModal(true);
      },
      onMultipleArchiveClick: () => {
        if (documents.filter(doc => !doc.checked).length !== documents.length) {
          setShowArchModal(true);
        }
      },
      handleDeleteConfirmButtonClick: () => {
        const selectedDocs = documents.filter(document => document.checked === true);
        if (selectedDocs !== null && selectedDocs.length > 0) {
          const selectedDocsIds = selectedDocs.map(document => document.id);
          if (selectedDocsIds.length === 1) {
            deleteSelectedDocument(
              selectedDocsIds[0],
              "ids=" + selectedDocsIds[0],
              selectedDocs.length
            );
          } else {
            deleteSelectedDocument(
              selectedDocsIds[0],
              "ids=" + selectedDocsIds.join(","),
              selectedDocs.length
            );
          }
        } else {
          deleteSelectedDocument(
            selectedDocument.id,
            "ids=" + selectedDocument.id.toString(),
            selectedDocs.length
          );
        }

        setShowDelModal(false);
      },
      handleOnDeclineCancelButtonClick: () => {
        setShowDelModal(false);
      },
      onMultipleMakePDFClick: () => {
        //make call for making PDF
        const documentIds = documents.filter(doc => doc.checked).map(({ id }) => id);
        if (documentIds.length > 0) {
          postDocumentCall(documentIds, BASE.resource.documentGeneratePDF);
        }
        setShowGeneratePDFModal(true);
      },
      onProcessClick: () => {
        postGenerateAndCombine(BASE.resource.generateInvoices);
      },
      onMultipleDownloadPDFClick: ids => {
        setDownloadIds(ids);
        // downloadDocuments(); --- za download ne importovanih dokumenata --- CHECK!
        postDownloadImported(ids, BASE.resource.documentDownload);
      },
      onMultipleSendPDFClick: ids => {
        //make call for multiple Send PDF via email
        postSendMail(ids, BASE.resource.documentSendEmails);
      },
      onMultipleQuickInvoicingClick: (activeModalRadio, exportDateRange) => {
        //make call for multiple Quick Invoicing using activeModalRadio, exportDateRange and setState
      },
      onVerifyPartnersClick: () => {
        setActiveItem("");
        history.push({
          pathname: "/contacts"
        });
      }
    },
    documentLinkThumbnailDetails: {
      onSelectButtonClick: (e, data) => {
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: constant.rightPanelMode.documentForm
        });
      },
      onBackButtonClick: (e, data) => {
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: constant.rightPanelMode.documentLinkThumbnails
        });
      },
      onThumbClick: (e, data) => {
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: constant.rightPanelMode.documentLinkThumbnailDetails
        });
      }
    },
    documentScansPanel: {
      onSelectButtonClick: (e, data) => {
        setIsLoadingForm(true);
        setIsLoadingScansList(true);
        setOriginalDocument(data.scanedfilebinaryconetent);
        readOCRDocumentFromUploaded(data.selectedfilebinaryid);
        setDocumentPreview(linkedDocumentsPreview.data);
        setIsPDFPreview(true);
      },
      onBackButtonClick: (e, data) => {
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentNotSelected,
          activeRightPanelMode: constant.rightPanelMode.documentUpload
        });
      },
      onRefreshBtnClick: (e, data) => {
        const fetchData = async () => {
          setIsLoadingScansList(true);
          await getScanedFiles();
          setIsLoadingScansList(false);
        };
        fetchData();
      },
      onScanedListItemClick: data => {
        setState({
          ...state,
          activeScanesListItem: data.id,
          activeScanesListItemId: parseInt(data.id.toString()),
          selectedfilebinaryid: data.selectedfilebinaryid,
          activeRightPanelMode: constant.rightPanelMode.documentScansPanel,
          scanedfilebinaryconetent: data.binarycontent
        });
      },
      onScanedItemPreviewClick: async data => {
        await getDocumentBinaryContnt(data, true);
        setState({
          ...state,
          activeRightPanelMode: constant.rightPanelMode.documentShowScanFromFolder
        });
      },
      onScanedItemPreview: async data => {
        await getDocumentBinaryContnt(data, true);
      },
      onCancelScanedItemPreview: async data => {
        setState({
          ...state,
          activeRightPanelMode: constant.rightPanelMode.documentScansPanel
        });
      }
    },
    documentForm: {
      onLinkDocumentsButtonClick: documentNumbers => {
        let selDocumentId = selectedDocument.id.toString();
        getDocumentBinaryContnt(selDocumentId);

        setNewlyCreatedDocument(selectedDocument);
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentLinkThumbnails,
          panelMode: constant.panelMode.edit
        });
      },
      updateLinkedDocuments: data => {
        setThumbsToSend(data);
      },
      onSaveButtonClick: async data => {
        setIsLoadingForm(true);

        let newDoc;
        if (data.documentType !== "delivery") {
          newDoc = createNewDocument(
            data,
            originalDocument ? originalDocument : null,
            thumbsToSend
          );
        } else {
          newDoc = createNewDeliveryDocument(
            data,
            originalDocument ? originalDocument : null,
            thumbsToSend
          );
        }

        setNewDocOnSave(newDoc);

        await createDocFromFile(newDoc, true).call();
        setIsLoadingForm(false);
      },
      onUpdateDocumentSave: async (data, thumbsToSend) => {
        if (data.editedFields && data.editedFields.length > 0) {
          setIsLoadingForm(true);
          let editedDoc = getEditedDocumentObject(data, thumbsToSend);
          setUpdateDoc({ id: data.id, editedDoc: editedDoc });
          if (data.editedFields.includes("documentNumber")) {
            await updateDocFromFile(data.id, editedDoc, true).call();
          } else {
            await updateDocFromFile(data.id, editedDoc).call();
          }
        } else {
          setState({
            ...state,
            selectAll: false,
            activeMiddlePanelMode: constant.middlePanelMode.documentList,
            activeRightPanelMode: constant.rightPanelMode.documentDetails,
            activeSideBarDimmer: false,
            panelMode: constant.panelMode.details
          });
          setEditMode(false);
        }
        setDocumentPreview(null);
        setIsLoadingForm(false);
        setOriginalDocument(null);
        setNewlyCreatedDocument(undefined);
      },
      onSaveAndNewButtonClick: data => {
        setIsLoadingForm(true);
        let newDoc;
        if (data.documentType !== "delivery") {
          newDoc = createNewDocument(
            data,
            originalDocument ? originalDocument : null,
            thumbsToSend
          );
        } else {
          newDoc = createNewDeliveryDocument(
            data,
            originalDocument ? originalDocument : null,
            thumbsToSend
          );
        }
        setNewDocOnSave(newDoc);
        createDocFromFile(newDoc, true, true).call();
        setIsLoadingForm(false);
      },
      onCancelButtonClick: async (e, data) => {
        e.preventDefault();

        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentList,
          activeRightPanelMode: constant.rightPanelMode.documentDetails,
          activeSideBarDimmer: false,
          panelMode: constant.panelMode.details
        });
        setLinkedDocumentsPreview(undefined);
        setNewlyCreatedDocument(undefined);
        setDocumentPreview(null);
        if (selectedDocument !== undefined) {
          setThumbsToSend(selectedDocument.documents);
        } else {
          setThumbsToSend([]);
        }
        setEditMode(false);
      },
      updateDocumentForManualPayment: docId => {
        let selectedIndex = getSelectedIndex(docId);
        fetchData(selectedIndex);
      },
      updateDocument: async (newDocument, actionType, history, serviceType) => {
        setVerificationLoading(true);
        const serviceUpdate = new RestService.Document.Update.Service(
          newDocument.id + "/" + serviceType
        );
        let updateProps = [];

        let updateObj = {
          op: "replace",
          path: "",
          value: ""
        };

        const selectedIndex = documents.findIndex(element => element.id === newDocument.id);

        switch (actionType) {
          case constant.actionType.verificationConfirm:
          case constant.actionType.verificationDecline:
            updateObj.path = "/verificationStatus";
            updateObj.value += newDocument.verificationStatus;
            break;
          case constant.actionType.resetVerificationStatus:
            updateObj.path = "/verificationStatus";
            updateObj.value += newDocument.verificationStatus;

            if (newDocument.approvalStatus === constant.approvalStatus.approved) {
              let updatePaymentStatus = {
                op: "replace",
                path: "/approvalStatus",
                value: constant.paymentApprovalStatus.unresolved
              };
              updateProps.push(updatePaymentStatus);
            }
            break;
          case constant.actionType.paymentConfirm:
          case constant.actionType.paymentDecline:
            updateObj.path = "/approvalStatus";
            updateObj.value += newDocument.approvalStatus;
            break;
          case constant.actionType.resetPaymentStatus:
            updateObj.path = "/approvalStatus";
            updateObj.value += newDocument.approvalStatus;
            break;

          default:
            break;
        }

        updateProps.push(updateObj);
        setIsLoadingUpdate(true);
        serviceUpdate.setPayload(updateProps);
        serviceUpdate.setCallbackSuccess(res => callbackUpdateSuccess(res, selectedIndex));
        serviceUpdate.setCallbackError(callbackUpdateError);
        serviceUpdate.setCallbackFinally(callbackUpdateFinaly);
        await serviceUpdate.call();
        setIsLoadingUpdate(false);
        setVerificationLoading(false);
      },
      onEditDocument: () => {
        let selDocumentId = selectedDocument.id.toString();
        getDocumentBinaryContnt(selDocumentId);

        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentForm,
          panelMode: constant.panelMode.edit
        });
        setEditMode(true);
      },
      onTrashCanClick: documentId => {
        deleteSelectedDocument(documentId);
      },
      handleNotification: () => {
        showNotificationMessage({
          entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.ITEM_LINE_ID"),
          operation: notificationConstants.notificationOperation.create,
          type: notificationConstants.notificationType.warning
        });
      },
      clearThumbs: () => {
        setThumbsToSend([]);
      }
    },
    documentUpload: {
      onUploadFileClick: e => {
        setNewlyCreatedDocument(getEmptyDocumentObject());

        if (e && e.target && e.target.files && e.target.files[0]) {
          const fileSize = e.target.files[0].size;
          const maxFileSize = 50 * 1024 * 1024;

          if (fileSize < maxFileSize) {
            createDocumentFromUploaded(e.target.files[0]);
            setIsLoadingForm(true);
          } else {
            setShowModal(true);
          }
        }
      },
      onDragMode: e => {
        const dNdMode =
          state.activeMiddlePanelMode === constant.middlePanelMode.documentDragnDrop
            ? constant.middlePanelMode.documentNotSelected
            : constant.middlePanelMode.documentDragnDrop;
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: dNdMode,
          activeRightPanelMode: constant.rightPanelMode.documentUpload,
          activeSideBarDimmer: true,
          panelMode: constant.panelMode.create
        });
      },
      onFileDrop: files => {
        setNewlyCreatedDocument(getEmptyDocumentObject());
        createDocumentFromUploaded(files[0]);
      },
      onSelectExistingFile: (e, data) => {
        const fetchScanedData = async () => {
          setIsLoadingScansList(true);
          await getScanedFiles();
          setIsLoadingScansList(false);
        };
        fetchScanedData();
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentScanList,
          activeRightPanelMode: constant.rightPanelMode.documentScansPanel
        });
      },
      onCancelButtonClick: async (e, data) => {
        e.preventDefault();
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentList,
          activeRightPanelMode: constant.rightPanelMode.documentDetails,
          activeSideBarDimmer: false,
          panelMode: constant.panelMode.details
        });
        setEditMode(false);
      }
    },
    sidebar: {
      onNewDocumentButtonClick: (e, data) => {
        setThumbsToSend([]);
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentNotSelected,
          activeRightPanelMode: constant.rightPanelMode.documentUpload,
          activeSideBarDimmer: true
        });
        setEditMode(true);
        setSideBarVisibility(false);
      },
      onDocumentDirectionFilterClick: (e, data) => {
        setState({
          ...state,
          activeRightPanelMode: "documentDetails",
          selectAll: false
        });

        if (documentsPanelState.activeDocumentDirectionFilter !== data.name) {
          setSelectedDocument();
        }
        setActivePaginationPage(1);
        let myNewPanelState = {
          ...documentsPanelState,
          activeDocumentDirectionFilter: data.name
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onDocumentTypeFilterClick: async (e, data) => {
        const activeDocumentTypeFilter =
          documentsPanelState.activeDocumentTypeFilter === data.name
            ? constant.documentType.all
            : data.name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        let myNewPanelState = {
          ...documentsPanelState,
          activeDocumentTypeFilter: activeDocumentTypeFilter,
          activeEarlyDueStatusFilter: docsInitFilterState.activeEarlyDueStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onDocumentDueFilterClick: (e, data) => {
        const activeDueStatusFilter =
          documentsPanelState.activeDueStatusFilter === data.name
            ? constant.documentDue.all
            : data.name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activeDueStatusFilter: activeDueStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onDocumentEarlyDueFilterClick: (e, { name }) => {
        const activeEarlyDueStatusFilter =
          documentsPanelState.activeEarlyDueStatusFilter === name ? constant.documentDue.all : name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activeEarlyDueStatusFilter: activeEarlyDueStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onVerificationStatusFilterClick: (e, data) => {
        const activeVerificationStatusFilter =
          documentsPanelState.activeVerificationStatusFilter === data.name
            ? constant.verificationStatus.all
            : data.name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activeVerificationStatusFilter: activeVerificationStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onPaymentStatusFilterClick: (e, data) => {
        const activePaymentStatusFilter =
          documentsPanelState.activePaymentStatusFilter === data.name
            ? constant.paymentApprovalStatus.all
            : data.name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activePaymentStatusFilter: activePaymentStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onPaymentRevenueStatusFilterClick: (e, data) => {
        const activeStatus =
          documentsPanelState.activeRevenuePaymentStatusFilter === data.name
            ? constant.revenuePaymentStatus.all
            : data.name;
        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activeRevenuePaymentStatusFilter: activeStatus
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },
      onProgressStatusFilterClick: (e, data) => {
        const activeProgressStatusFilter =
          documentsPanelState.activeProgressStatusFilter === data.name
            ? constant.progressStatus.all
            : data.name;

        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        clearCheckedAll();
        let myNewPanelState = {
          ...documentsPanelState,
          activeProgressStatusFilter: activeProgressStatusFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      },

      onArchiveFilterClick: (e, data) => {
        let activeDocumentArhivedFilter =
          documentsPanelState.activeArchiveFilter === "archived" ? "" : data.name;

        setState({
          ...state,
          selectAll: false,
          activeRightPanelMode: "documentDetails"
        });
        setSelectedDocument();
        setActivePaginationPage(1);
        let myNewPanelState = {
          ...documentsPanelState,
          activeArchiveFilter: activeDocumentArhivedFilter
        };
        setPathOnlyOneAtTime(myNewPanelState);
      }
    },
    paymentForm: {
      onPaymentChange: (e, data) => {
        setDocuments(
          documents.map(document => {
            if (data.payments) {
              let foundPayment = data.payments.find(p => p.docId === document.id);
              if (foundPayment) document.isValidPayment = foundPayment.isValidPayment;
            }
            return document;
          })
        );
      }
    },
    confirmArchiveDialog: {
      handleOnDeclineArchiveButtonClick: () => {
        setShowArchModal(false);
        setArchInfo({ id: null, partnerArchived: null });
      },
      handleArchiveConfirmButtonClick: () => {
        setIsLoadingUpdate(true);
        let documentUpdateService = new RestService.Document.Update.Service();
        let partnerArchived;
        let updatedObject;
        if (archInfo.id !== null) {
          partnerArchived = archInfo.partnerArchived;
          updatedObject = [
            {
              id: archInfo.id,
              operation: [
                {
                  op: "replace",
                  path: "/archived",
                  value: !partnerArchived
                }
              ]
            }
          ];
        } else {
          partnerArchived = documents[0].archived;
          let checkedIdArr = documents.filter(doc => doc.checked).map(d => d.id);

          updatedObject = checkedIdArr.map(chDoc => ({
            id: chDoc,
            operation: [
              {
                op: "replace",
                path: "/archived",
                value: documentsPanelState.activeArchiveFilter === ""
              }
            ]
          }));
        }

        if (updatedObject.length > 0) {
          documentUpdateService.setPayload(updatedObject);
          documentUpdateService.setCallbackError(callbackGetAllError);
          documentUpdateService.setCallbackSuccess(callbackArchUpdateSuccess);
          documentUpdateService.setCallbackFinally(callbackUpdateFinaly);
          documentUpdateService.call();
        }
        setShowArchModal(false);
        setDeleteLinkedDocumentsModal({
          show: false,
          listOfLinkedDocuments: []
        });
        setArchInfo({ id: null, partnerArchived: null });
        setState({ ...state });
        setSelectedDocument();
      }
    },
    onDateChange: async day => {
      setPathOnlyOneAtTime({
        ...documentsPanelState,
        minDate: formatDate(day.from),
        maxDate: formatDate(day.to)
      });
      setState({
        ...state,
        pickerTouched: true
      });
    },
    onDateTypeChange: (data, day) => {
      if (data === constant.toolbarDayPicker.reset) {
        setPathOnlyOneAtTime({
          ...documentsPanelState,
          fromDate: constant.initialState.allDates,
          toDate: constant.initialState.allDates,
          due: constant.initialState.allDates,
          createdAt: constant.initialState.allDates,
          dateTypeValue: ""
        });
      } else {
        handler.onDateToolbarChange(data, {
          from: day.from,
          to: day.to
        });
      }
    },
    onDateToolbarChange: (dateType, day) => {
      let myNewPanelState = {
        ...documentsPanelState
      };
      if (dateType) {
        myNewPanelState.dateTypeValue = dateType;
      }
      if (myNewPanelState.dateTypeValue === constant.toolbarDayPicker.documentDate) {
        myNewPanelState.due = "";
        myNewPanelState.createdAt = "";
        myNewPanelState.fromDate = formatDate(day.from);
        myNewPanelState.toDate = formatDate(day.to);
      } else if (myNewPanelState.dateTypeValue === constant.toolbarDayPicker.paymentDate) {
        myNewPanelState.due = formatDate(day.from) + ":" + formatDate(day.to);
        myNewPanelState.fromDate = "";
        myNewPanelState.createdAt = "";
        myNewPanelState.toDate = "";
      } else if (myNewPanelState.dateTypeValue === constant.toolbarDayPicker.createdAt) {
        myNewPanelState.createdAt = formatDate(day.from) + ":" + formatDate(day.to);
        myNewPanelState.fromDate = "";
        myNewPanelState.toDate = "";
        myNewPanelState.due = "";
      }
      setPathOnlyOneAtTime(myNewPanelState);
    }
  };

  const onModalSameDocNumberCancel = () => {
    setShowSameDocNumbModal(false);
  };
  const onModalSameDocNumberConfirm = () => {
    setShowSameDocNumbModal(false);
    createDocFromFile(newDocOnSave, false).call();
  };
  const onModalEditSameDocNumberConfirm = () => {
    setShowSameDocNumbModal(false);
    updateDocFromFile(updateDoc.id, updateDoc.editedDoc, false).call();
  };
  const clearCheckedAll = () => {
    setDocuments(
      documents.map(document => {
        document.checked = false;
        return document;
      })
    );
  };

  const confirmHandler = () => {
    setShowModal(false);
  };

  const handlePageChange = async pageInfo => {
    setActivePaginationPage(pageInfo.activePage);
    setState({ ...state, selectFirst: true });
  };

  const handleScanedFilesPageChange = async pageInfo => {
    await getScanedFiles(pageInfo.activePage - 1);
    setActivePaginationScandedFilesPage(pageInfo.activePage);
  };

  const getDialog = () => {
    let dialog = null;
    let modalHeader = Localize.get("DOCUMENTS.MODAL.UPLOAD_FAIL");
    let modalColor = "#c00";
    let textAreaPlaceHolder = Localize.get("DOCUMENTS.MODAL.NOTE_FILE_EXCEEDED_SIZE");

    if (showModal) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
          </Modal.Header>
          <Modal.Content>
            <p>{textAreaPlaceHolder}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={confirmHandler}
              positive
              labelPosition="right"
              icon="checkmark"
              content={Localize.get("DOCUMENTS.MODAL.MODAL_CONFIRM")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const documentsList = () => {
    let newArray = documents.filter(document => {
      let machedIds = deleteLinkedDocumentsModal.listOfLinkedDocuments.filter(linkedDocumentId => {
        return linkedDocumentId === document.id && document.archived !== true ? true : false;
      });

      return machedIds > 0 ? true : false;
    });

    return newArray.map(document => (
      <div className={`${styles.list_of_items} ${styles.status_aligment}`} key={document.id}>
        <DocumentItem
          document={document}
          state={state}
          onListItemClick={() => {}}
          onToggleItem={handler.documentList.onToggleItem}
        />
      </div>
    ));
  };

  const getDeleteLinkedDocumentDialog = () => {
    let dialog = null;
    let modalColor = "#c00";
    let modalHeader = Localize.get("DOCUMENTS.LINKED_DOCUMENTS_CANNOT_BE_DELETED");
    let modalHeaderChooseArchive = Localize.get("DOCUMENTS.LINKED_DOCUMENTS_CAN_BE_ONLY_ARCHIVED");
    if (deleteLinkedDocumentsModal.show) {
      dialog = (
        <Modal
          size="small"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
            <br />
            {modalHeaderChooseArchive}
          </Modal.Header>
          <Modal.Content scrolling={true}>{documentsList()}</Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                setDeleteLinkedDocumentsModal({
                  show: false,
                  listOfLinkedDocuments: []
                });
              }}
              secondary
            >
              {Localize.get("GLOBAL.FORM.CANCEL")}
            </Button>
            <Button
              onClick={handler.confirmArchiveDialog.handleArchiveConfirmButtonClick}
              positive
              labelPosition="right"
              icon="checkmark"
              content={Localize.get("CONTACTS.LIST.TOOLBAR.ARCHIVE")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  const getArchDialog = () => {
    let dialog = null;
    if (showArchModal) {
      dialog = (
        <ConfirmArchiveDialog
          showModal={showArchModal}
          listState={state.activeMiddlePanelMode.activeArchiveFilter}
          confirmClicked={handler.confirmArchiveDialog.handleArchiveConfirmButtonClick}
          declineClicked={handler.confirmArchiveDialog.handleOnDeclineArchiveButtonClick}
          numberOfArchivedItems={documents.filter(doc => doc.checked === true).length}
          selectedItems={documents.filter(doc => doc.checked === true)}
          activeArchiveFilter={documentsPanelState.activeArchiveFilter}
          module="document"
        />
      );
      return dialog;
    }
  };

  const handleGenerateModal = () => {
    setShowGeneratePDFModal(false);

    if (respMessage.status === 200) {
      setDocuments(prevDoc =>
        prevDoc.map(doc => {
          if (doc.checked === true) {
            doc.checked = false;
            doc.progressStatus = get(constant, "progressStatus.pdfCreated");
          }

          return doc;
        })
      );
    }
  };

  const generateCustomModal = () => {
    return (
      <>
        {postLoading && (
          <div style={{ height: "10rem" }}>
            <Loader active style={{ color: "#000" }}>
              {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.GENERATE_PDF")}
            </Loader>
          </div>
        )}

        {get(respMessage, "status") === 404 && (
          <>
            <div>{Localize.get("DOCUMENTS.MODAL.MODAL_GENERATE_ERROR")}</div>
            <Button
              icon
              labelPosition="right"
              style={{ marginTop: "1rem" }}
              onClick={() => {
                setActiveItem("settings");
                history.push({
                  pathname: "/settings",
                  state: {
                    routeModule: "categories"
                  }
                });
              }}
            >
              {Localize.get("DOCUMENTS.MODAL.GENERATE_TEMPLATE")}
              <Icon name="right arrow" />
            </Button>
          </>
        )}

        {get(respMessage, "status") === 500 && (
          <>
            <div>{get(respMessage, "message")}</div>
          </>
        )}

        {respMessage && respMessage.data && (
          <div>
            {/* {Localize.get("DOCUMENTS.MODAL.MODAL_GENERATE_SUCCESS")} */}
            {respMessage.data}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div>{getDialog()}</div>
      <div>{getDeleteLinkedDocumentDialog()}</div>
      <div> {getArchDialog()}</div>

      <GlobalModal
        showModal={showGeneratePDFModal}
        setShowModal={setShowGeneratePDFModal}
        modalHandler={handleGenerateModal}
        headerText={"DOCUMENTS.ITEMLIST.TOOLBAR.GENERATE_PDF"}
        CustomComponent={generateCustomModal}
        customClassName={
          get(respMessage, "status") === 404 || get(respMessage, "status") === 500
            ? styles.generate_pdf_error
            : styles.generate_pdf
        }
        showCancel={false}
        okDisabled={postLoading}
      />

      <ConfirmCancelDialog
        modalColor={"red"}
        openModal={showSameDocNumbModal}
        modalHeader={Localize.get("DOCUMENTS.MODAL.DOCUMENT_ALREADY_EXIST")}
        modalContent={Localize.get("DOCUMENTS.MODAL.DOCUMENT_WITH_SAME_DOC_NUMBER_ALREADY_EXISTS")}
        onModalCancelClick={onModalSameDocNumberCancel}
        onModalConfirmClick={
          state.panelMode === constant.panelMode.edit
            ? onModalEditSameDocNumberConfirm
            : onModalSameDocNumberConfirm
        }
      />
      <div>
        <div>
          <Dimmer
            active={
              isLoading || editMode || isPreviewLoading
                ? true
                : false || isDownloadDocLoading || isDownloadLoading || sendLoading
            }
            inverted
          >
            <Loader
              size="large"
              disabled={
                !isLoading &&
                !isDownloadDocLoading &&
                !isDownloadLoading &&
                !sendLoading &&
                !isPreviewLoading
              }
              className={styles.loader_center}
            >
              {sendLoading
                ? Localize.get("DOCUMENTS.MODAL.SENDING_MAIL")
                : Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
            </Loader>
          </Dimmer>

          <div
            className={`ui right attached button ${styles.bms_fixed} ${
              sideBarVisibility || editMode ? styles.bms_zindex : null
            }`}
            onClick={settingVisibleSidebar}
          >
            <i className="ellipsis vertical icon"></i>
          </div>
          <Sidebar.Pushable
            as={Segment}
            className={editMode ? styles.bms_segment_zi : styles.bms_segment}
          >
            <SideBar
              privileges={privileges}
              documentsPanelState={documentsPanelState}
              handler={handler}
              sideBarVisibility={sideBarVisibility}
              setSideBarVisibility={setSideBarVisibility}
              dateTypeValue={documentsPanelState.dateTypeValue}
              fetchData={fetchData}
            />

            <Sidebar.Pusher
              dimmed={sideBarVisibility}
              className={
                editMode ||
                state.activeRightPanelMode === constant.rightPanelMode.documentLinkThumbnails ||
                state.activeRightPanelMode === constant.rightPanelMode.projectsPanel
                  ? styles.bms_zi
                  : null
              }
            >
              <div
                className={editMode ? styles.bms_document_no_margin : styles.bms_document_content}
              >
                <div className="ui two column stackable grid padded">
                  <MiddlePanel
                    privileges={privileges}
                    paymentPrivileges={paymentPrivileges}
                    state={state}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    handler={handler}
                    documents={documents}
                    handlePageChange={handlePageChange}
                    handleScanedFilesPageChange={handleScanedFilesPageChange}
                    paginationStatus={paginationStatus}
                    activePaginationPage={activePaginationPage}
                    activePaginationScandedFilesPage={activePaginationScandedFilesPage}
                    documentPreview={documentPreview}
                    isPDFPreview={isPDFPreview}
                    checkedDocs={checkedDocs}
                    accounts={accounts}
                    linkedDocumentsPreview={linkedDocumentsPreview}
                    activeScanesListItemId={state.activeScanesListItemId}
                    dateTypeValue={documentsPanelState.dateTypeValue}
                    scanedFiles={scanedFiles}
                    paginationScanedFiles={paginationScanedFiles}
                    isLoadingScansList={isLoadingScansList}
                    selectedDocument={selectedDocument}
                    documentsPanelState={documentsPanelState}
                    respDownAndSendMessage={downloadAndSendResponse}
                    setActiveItem={setActiveItem}
                    unverifiedPartners={unverifiedPartners}
                  />
                  <RightPanel
                    privileges={privileges}
                    paymentPrivileges={paymentPrivileges}
                    manualPaymentPrivileges={manualPaymentPrivileges}
                    createCommentSave={createCommentSave}
                    projectPrivileges={projectPrivileges}
                    partnerPrivileges={partnerPrivileges}
                    state={state}
                    editMode={editMode}
                    handler={handler}
                    isPDFPreview={isPDFPreview}
                    linkedDocumentsPreview={linkedDocumentsPreview}
                    documentPreview={documentPreview}
                    document={
                      newlyCreatedDocument !== undefined ? newlyCreatedDocument : selectedDocument
                    }
                    actions={selectedDocument ? selectedDocument.actions : {}}
                    setNewlyCreatedDocument={setNewlyCreatedDocument}
                    documents={documents}
                    thumbsToSend={thumbsToSend}
                    setThumbsToSend={setThumbsToSend}
                    showNotificationMessage={showNotificationMessage}
                    documentsCommentHistory={documentsCommentHistory}
                    parentDocumentForDiagram={parentDocumentForLinkedDoc}
                    parentDocumentForTable={
                      selectedDocument && selectedDocument.parentDocument
                        ? { ...selectedDocument.parentDocument, isParent: true }
                        : null
                    }
                    isLoadingForm={isLoadingForm}
                    verificationLoading={verificationLoading}
                    scanedFiles={scanedFiles}
                    isLoadingScansList={isLoadingScansList}
                    setIsLoading={setIsLoadingUpdate}
                    setIsLoadingLoader={setIsLoading}
                    invalidInvoiceAmount={invalidInvoiceAmount}
                    invalidCorrectionAmount={invalidCorrectionAmount}
                  />
                </div>
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>

        <ConfirmDeleteDialog
          numberOfCheckedItems={documents.filter(document => document.checked === true).length}
          showModal={showDelModal}
          confirmClicked={handler.documentList.handleDeleteConfirmButtonClick}
          declineClicked={handler.documentList.handleOnDeclineCancelButtonClick}
        />
      </div>
    </>
  );
};

export default Documents;
