export const panelMode = {
  edit: "edit",
  create: "create",
  details: "details"
};

export const panelInitState = {
  activeMode: panelMode.details
};

export const listMode = {
  list: "list",
  documentPreview: "documentPreview"
};

export const listInitState = {
  activeMode: listMode.list
};

export const filterObjectInitState = {
  name: ""
};

export const projectStatus = {
  new: "NEW",
  closed: "CLOSED",
  process: "IN_PROCESS",
  all: "all"
};

export const partnersTypes = {
  person: "person",
  companies: "companies",
  company: "company",
  employee: "employee",
  all: "all"
};

export const archive = {
  archived: "archived"
};

export const initialFilterStatus = {
  activeStatusFilter: projectStatus.all,
  activeArchiveFilter: ""
};

export const deleteForm = {
  toolbar: "toolbar",
  details: "details"
};

export const initialProject = {
  contacts: [],
  documents: [],
  projectTemplate: {
    roles: [],
    projectType: { name: "" }
  }
};

export const initialProjectTemplate = {
  roles: [],
  projectType: { name: "" }
};

export const autoComplete = {
  none: "none",
  off: "off"
};

//defined what field will be visible in modal in Project > resources >partner settings
export const initSelectedPersonFields = ["phone", "mobile", "email", "address", "web"];

export const initSelectedCompaniesFields = [
  "phone",
  "mobile",
  "email",
  "address",
  "fax",
  "taxNumber",
  "vatin",
  "web"
];

export const initSelectedEmployeeFields = [
  "phone",
  "mobile",
  "email",
  "address",
  "fax",
  "taxNumber",
  "vatin",
  "web"
];
