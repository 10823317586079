import React, { useEffect } from "react";
import useFetch from "../../RestServicesNew/useFetch";
import Localize from "react-intl-universal";
import GlobalList from "../../Components/Lists/GlobalList";
import GlobalOkButton from "../../Components/GlobalOkBtn";
import GlobalCancelBtn from "../../Components/GlobalCancelBtn";
import { Loader, Dimmer, Modal } from "semantic-ui-react";
import { getByPathAndParams } from "../../RestServicesNew/DocumentsServices";
import styles from "./style/InvoiceNotifiers.module.css";
import showNotification from "../../Notifications/NotificationFunction";

const TemplateList = ({ categoryKey, setShowModal, addTemplate, activeInput }) => {
  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.PARTNER"),
      ...param
    });
  };

  const callbackFetchTemplateByCategoryError = err => {
    console.err(err);
    showNotificationMessage({
      type: "warning",
      operation: ""
    });
  };

  const callbackFetchTemplateByCategorySuccess = resp => {};

  const [listOfTemplates, , templatesLoading, fetchTemplatesCall, setListOfTemplates] = useFetch(
    getByPathAndParams,
    [],
    `/templates?categoryKey=${categoryKey}`,
    {},
    callbackFetchTemplateByCategorySuccess,
    callbackFetchTemplateByCategoryError
  );

  useEffect(() => {
    fetchTemplatesCall();
  }, []);

  const itemHandler = item => {
    setListOfTemplates(prevState => {
      let newState = prevState.map(loopedItem => {
        if (item.id === loopedItem.id) {
          loopedItem.checked = !loopedItem.checked;
        } else {
          loopedItem.checked = false;
        }
        return loopedItem;
      });

      return newState;
    });
  };

  const onOkClicked = () => {
    let chosenTemplate = listOfTemplates.find(item => item.checked);
    if (chosenTemplate) {
      let { id, name } = chosenTemplate;
      addTemplate(id, name);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  const isListChecked = listOfTemplates.some(item => item.checked);

  return (
    <>
      <Dimmer active={templatesLoading} inverted>
        <Loader size="large" disabled={!templatesLoading}>
          {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
        </Loader>
      </Dimmer>
      <GlobalList
        currentView="details"
        listState={listOfTemplates}
        itemHandler={itemHandler}
        listContainerCustomCSS={styles.template_list}
        availableItemFieldsForList={[
          {
            name: "checked",
            type: "checkbox",
            width: 1,
            visible: true
          },
          {
            name: "name",
            hederName: "CONTACTS.LIST.HEADER.NAME",
            type: "text",
            width: 5,
            visible: true
          },
          {
            name: "templateCategory.name",
            hederName: "BOOKKEEPING.CATEGORIE",
            type: "text",
            width: 5,
            visible: true
          },
          {
            name: "templateCategory.description",
            hederName: "BOOKKEEPING.HEADER_DESCRIPTION",
            type: "text",
            width: 5,
            visible: true
          }
        ]}
      />
      <Modal.Actions>
        <GlobalOkButton onOkClicked={onOkClicked} disabled={!isListChecked} />
        <GlobalCancelBtn onCancel={() => setShowModal(false)} />
      </Modal.Actions>
    </>
  );
};

export default TemplateList;
