// toggle all checkboxes - arguments: (toolbar check box value , setState)
export const togleAll = (checked, setList) => {
  setList(list =>
    list.map(item => {
      item.checked = checked;
      return item;
    })
  );
};

// toggle one checkbox - arguments: (id , setState)
export const togleOne = (checkedId, setList) => {
  setList(list =>
    list.map(item => {
      if (item.id === checkedId) {
        item.checked = !item.checked;
      }
      return item;
    })
  );
};

export const toggleOnlyOne = (checkedId, setList) => {
  setList(list =>
    list.map(item => {
      if (item.id === checkedId) {
        item.checked = !item.checked;
      } else {
        item.checked = false;
      }
      return item;
    })
  );
};

// intersection of two arrays (ids/items) -
// arguments: (array of ids , array of items) - return list of matching items
export const compareListWithListOfIds = (listOfIds, listOfItems) => {
  // eslint-disable-next-line array-callback-return
  return listOfItems.filter(item =>
    // eslint-disable-next-line array-callback-return
    listOfIds.some(id => {
      if (id === item.id) {
        item.checked = false;
        return item;
      }
    })
  );
};

export const checkCurrentSelectedItem = (listOfItems, currentView, selectedItem) => {
  if (listOfItems.length > 0 && currentView === "details") {
    if (selectedItem) {
      let temp = listOfItems.find(item => item.id === selectedItem.id);
      return temp ? temp : listOfItems[0];
    } else {
      return listOfItems[0];
    }
  } else {
    return null;
  }
};

// get payload object for update of item
export const GetUpdateObject = newItem => {
  if (newItem) {
    return newItem.editedFields.map(function(field) {
      let fieldNameForDB = field;
      let val = newItem[field];

      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  }

  return [];
};
