import { useState } from "react";
import { createPostEditedService } from "../Components/Utils/GlobalUtil";
import showNotification from "../Notifications/NotificationFunction";
import * as constants from "./Constants";
import Localize from "react-intl-universal";
import propTypes from "prop-types";

const useEditItem = (
  editedFields = [],
  setCurrentView,
  currentItem,
  customErrorHandler,
  customValidation,
  currentModule
) => {
  const [editIsLoading, setEditIsLoading] = useState(false);

  const getUpdatedFields = () => {
    return editedFields.map(field => {
      return {
        op: "replace",
        path: "/" + field,
        value:
          typeof currentItem[field] === "string" ? currentItem[field].trim() : currentItem[field]
      };
    });
  };

  const getUpdatedFieldsForProperties = () => {
    return editedFields.map(fieldId => {
      let tempItem = currentItem.find(item => item.id === fieldId);
      return {
        id: fieldId,
        operation: [
          {
            op: "replace",
            path: "/value",
            value: typeof tempItem.value === "string" ? tempItem.value.trim() : tempItem.value
          }
        ]
      };
    });
  };

  const callbackEditSuccess = () => {
    setEditIsLoading(false);
    setCurrentView(constants.panelView.details);
    showNotificationMessage({
      type: constants.notificationType.info,
      serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.SUCESS_SAVED")
    });
  };
  const callbacEditError = error => {
    setEditIsLoading(false);
    if (customErrorHandler && error.response.status !== 401) {
      customErrorHandler(error, showNotificationMessage);
    } else if (error.response.status !== 401) {
      showNotificationMessage({
        type: constants.notificationType.warning,
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    }
  };
  const callbackEditFinaly = () => {};

  const updateItemServiceCall = async () => {
    if (editedFields.length > 0) {
      let service = createPostEditedService(
        currentModule === constants.panelModule.properties ||
          currentModule === constants.panelModule.accounts ||
          currentModule === constants.panelModule.dunning
          ? getUpdatedFieldsForProperties()
          : getUpdatedFields(),
        callbackEditSuccess,
        callbacEditError,
        callbackEditFinaly,
        currentModule === constants.panelModule.properties ||
          currentModule === constants.panelModule.accounts ||
          currentModule === constants.panelModule.dunning
          ? "settings/properties"
          : `${currentModule}/${currentItem.id}`
      );

      if (customValidation) {
        if (customValidation(currentItem)) {
          setEditIsLoading(true);
          await service.call();
        }
      } else {
        setEditIsLoading(true);
        await service.call();
      }
    } else {
      setCurrentView(constants.panelView.details);
    }
  };

  const showNotificationMessage = param => {
    showNotification({
      entity: currentModule,
      ...param
    });
  };

  return [updateItemServiceCall, editIsLoading];
};

useEditItem.propTypes = {
  editedFields: propTypes.arrayOf(propTypes.string).isRequired,
  setCurrentView: propTypes.func,
  currentItem: propTypes.object.isRequired,
  customErrorHandler: propTypes.func,
  customValidation: propTypes.func,
  setErrorFields: propTypes.func,
  currentModule: propTypes.string.isRequired
};
export default useEditItem;
