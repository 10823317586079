export const DEFAULTCONFIGURATION = {
  fields: null
};

export const documentFormDefaultTypes = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["whitespaceBeginFilter"],
  paymentDue: ["whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  partner: ["whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const totalTableDefaultTypes = {
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  paymentDue: ["whitespaceBeginFilter"]
};

export const totalTableInvoices = {
  amount: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus"],
  overpayment: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalTax: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalNetAmount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  paymentDue: ["whitespaceBeginFilter"]
};

export const totalTableAll = {
  totalNetAmount: ["numberFilterAndMinus", "longerThan15"],
  totalDiscount: ["numberFilterAndMinus", "longerThan15"],
  totalSurcharge: ["numberFilterAndMinus", "longerThan15"],
  totalTax: ["numberFilterAndMinus", "longerThan15"],
  overpayment: ["numberFilterAndMinus", "longerThan15"],
  amount: ["numberFilterAndMinus", "longerThan15", "min1Character"],
  earlyPayAmount: ["numberFilterAndMinus", "longerThan15"],
  paymentDue: ["min1Character"]
};

export const deliveryFormBothDirections = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  partner: ["whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const correctionFormExpence = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  paymentDue: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  parentDocument: ["min1Character", "whitespaceBeginFilter"],
  partner: ["min1Character", "whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const correctionFormRevenue = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  parentDocument: ["min1Character", "whitespaceBeginFilter"],
  partner: ["min1Character", "whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const invoiceFormExpence = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  paymentDue: ["min1Character", "whitespaceBeginFilter"],
  deliveryDate: ["min1Character", "whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  partner: ["whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const invoiceFormRevenue = {
  documentType: ["min1Character", "whitespaceBeginFilter"],
  direction: ["min1Character", "whitespaceBeginFilter"],
  documentNumber: ["min1Character", "whitespaceBeginFilter", "longerThan36", "datevFilter"],
  paymentDue: ["whitespaceBeginFilter"],
  documentDate: ["min1Character", "whitespaceBeginFilter"],
  partner: ["whitespaceBeginFilter"],
  currency: ["min1Character", "whitespaceBeginFilter"],
  description: ["whitespaceBeginFilter", "longerThan255"]
};

export const subtotalsTableAll = {
  tax: ["min1Character", "vatAllowed", "longerThan15"],
  netAmount: ["min1Character", "numberFilterAndMinus", "longerThan15", "zeroForbiden"],
  surcharge: ["numberFilterAndMinus", "longerThan15"],
  discount: ["numberFilterAndMinus", "longerThan15"],
  baseAmount: ["numberFilterAndMinus", "longerThan15"],
  calculatedTax: ["numberFilterAndMinus", "longerThan15"],
  grossAmount: ["numberFilterAndMinus", "longerThan15"]
};

export const orderItemForInvoices = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  netPrice: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  quantity: ["min1Character", "whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255", "min1Character"],
  unit: ["whitespaceBeginFilter", "longerThan50"],
  vat: ["whitespaceBeginFilter", "min1Character"],
  surchargeDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"]
};

export const orderItemForDefault = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  listPrice: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  quantity: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  amount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255"],
  unit: ["whitespaceBeginFilter", "longerThan50"],
  vat: ["whitespaceBeginFilter"],
  surchargeDiscount: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"]
};

export const orderItemForDelivery = {
  itemLineId: ["whitespaceBeginFilter", "longerThan64"],
  sellerItemId: ["whitespaceBeginFilter", "longerThan64"],
  quantity: ["whitespaceBeginFilter", "numberFilterAndMinus", "longerThan15"],
  name: ["whitespaceBeginFilter", "longerThan255"],
  unit: ["whitespaceBeginFilter", "longerThan50"]
};

export const formFieldsPaymentForm = {
  purpose: ["whitespaceBeginFilter"]
};

export const checkFieldsPaymentForm = {
  purpose: ["whitespaceBeginFilter"],
  recipientName: ["whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter"],
  accountId: ["whitespaceBeginFilter"]
};

export const checkFieldsManualPaymentForm = {
  partnerName: ["whitespaceBeginFilter"],
  amount: ["whitespaceBeginFilter"]
};

export const filterStrings = {
  // eslint-disable-next-line no-useless-escape
  emailFilter: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  characterFilter: /^[0-9 A-Z a-z _ - äöüÄÖÜß]*$/,
  numberFilter: /^[0-9]*[,.]?[0-9]*$/,
  numberFilterAndChar: /^[0-9.,-]+$/,
  // eslint-disable-next-line no-useless-escape
  numberFilterAndMinus: /^[-+]?(|[0-9]+((([\.\,]){1}([0-9]){1,2})+)?)$/,
  whitespaceBeginFilter: /^\s/,
  zeroForbiden: /[^0|^0.0|^0,0|^0.00|^0,00]/,
  onlyNumberFilter: /^\d+$/,
  textFilter: /^[^A-Za-z]+$/,
  // eslint-disable-next-line no-useless-escape
  specialCharactersPhoneFormat: /^[^\!\@\#\$\%\^\&\*\_\=\|\]\}\[\{\;\:\'\"\?\.\>\,\<\`\~]+$/,
  longerThan11: /^(?=.{0,11}$).*/,
  // eslint-disable-next-line no-useless-escape
  charactersOnThePhoneEnd: /[^\+\-\/\(\)\\\s]$/,
  longerThan12: /^(?=.{0,12}$).*/,
  longerThan15: /^(?=.{0,15}$).*/,
  longerThan16: /^(?=.{0,16}$).*/,
  longerThan19: /^(?=.{0,19}$).*/,
  longerThan20: /^(?=.{0,20}$).*/,
  longerThan24: /^(?=.{0,24}$).*/,
  longerThan30: /^(?=.{0,30}$).*/,
  longerThan34: /^(?=.{0,34}$).*/,
  longerThan36: /^(?=.{0,36}$).*/,
  longerThan50: /^(?=.{0,50}$).*/,
  longerThan64: /^(?=.{0,64}$).*/,
  longerThan70: /^(?=.{0,70}$).*/,
  longerThan100: /^(?=.{0,100}$).*/,
  longerThan224: /^(?=.{0,224}$).*/,
  longerThan255: /^(?=.{0,255}$).*/,
  // eslint-disable-next-line no-useless-escape
  datevFilter: /^[a-zA-Z0-9amp\*\+\-]+$/,
  min1Character: /^(?=.{1,255}$).*/,
  vatAllowed: /^(5|7|0|16|19|0%|5%|7%|16%|19%)+$/
};

export const filterMessages = {
  emailFilter: "VALIDATIONMESSAGES.EMAILFORMAT",
  characterFilter: "VALIDATIONMESSAGES.ALLOWEDCHARACTERS",
  whitespaceBeginFilter: "VALIDATIONMESSAGES.NOWHITESPACE",
  onlyNumberFilter: "VALIDATIONMESSAGES.ONLYNUMBERS",
  numberFilterAndChar: "VALIDATIONMESSAGES.NUMBERSCHARS",
  numberFilterAndMinus: "VALIDATIONMESSAGES.NUMBERSMINUS",
  requiredFilter: "VALIDATIONMESSAGES.REQUIREDDATA",
  textFilter: "VALIDATIONMESSAGES.TEXTFILTERPHONEFILTER",
  amountFilter: "VALIDATIONMESSAGES.AMOUNTALLOWED",
  specialCharactersPhoneFormat: "VALIDATIONMESSAGES.SPECIALCHARACTERSPHONEFORMAT",
  charactersOnThePhoneEnd: "VALIDATIONMESSAGES.CHARACTERSONTHEPHONEEND",
  longerThan11: "VALIDATIONMESSAGES.LONGERTHAN11",
  longerThan12: "VALIDATIONMESSAGES.LONGERTHAN12",
  longerThan15: "VALIDATIONMESSAGES.LONGERTHAN15",
  longerThan16: "VALIDATIONMESSAGES.LONGERTHAN16",
  longerThan19: "VALIDATIONMESSAGES.LONGERTHAN19",
  longerThan20: "VALIDATIONMESSAGES.LONGERTHAN20",
  longerThan24: "VALIDATIONMESSAGES.LONGERTHAN24",
  longerThan30: "VALIDATIONMESSAGES.LONGERTHAN30",
  longerThan34: "VALIDATIONMESSAGES.LONGERTHAN34",
  longerThan36: "VALIDATIONMESSAGES.LONGERTHAN36",
  longerThan50: "VALIDATIONMESSAGES.LONGERTHAN50",
  longerThan64: "VALIDATIONMESSAGES.LONGERTHAN64",
  longerThan70: "VALIDATIONMESSAGES.LONGERTHAN70",
  longerThan100: "VALIDATIONMESSAGES.LONGERTHAN100",
  longerThan224: "VALIDATIONMESSAGES.LONGERTHAN224",
  longerThan255: "VALIDATIONMESSAGES.LONGERTHAN255",
  datevFilter: "VALIDATIONMESSAGES.DATEVFILTER",
  min1Character: "VALIDATIONMESSAGES.MIN1CHARACTER",
  zeroForbiden: "VALIDATIONMESSAGES.ZEROFORBIDEN",
  vatAllowed: "VALIDATIONMESSAGES.VATALLOWED"
};
