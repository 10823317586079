import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  addResponseType(type) {
    this.responseType = type;
    return this;
  }
  addDocumentIds(documentIds) {
    this.parameter.ids = documentIds;
    return this;
  }
  addDocumentFromDate(documentFromDate) {
    this.parameter.fromDate = documentFromDate;
    return this;
  }
  addDocumentToDate(documentToDate) {
    this.parameter.toDate = documentToDate;
    return this;
  }

  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.documentExport
    };
    this.query = new CustomQuery.Builder();
  }
}
