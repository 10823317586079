import * as constant from "./Constants";
import Localize from "react-intl-universal";
import isEmptyObject from "lodash.isempty";

export const createNewProject = data => {
  const deliveryAddresscheck = isEmpty(data.deliveryAddress);
  let deliveryAddressToSend = null;

  if (!deliveryAddresscheck) {
    deliveryAddressToSend = data.deliveryAddress;
  }

  return {
    projectName: data.projectName,
    contacts: data.contacts,
    officeAddress: data.officeAddress,
    deliveryAddress: deliveryAddressToSend,
    projectTemplate: data.projectTemplate,
    documents: data.documents,
    resources: data.resources,
    projectStatus: data.projectStatus,
    projectDescription: data.projectDescription
  };
};

export const isEmpty = data => {
  if (!data) {
    return true;
  }
  return Object.values(data).every(field => field === "");
};

export const GetUpdateObject = newProject => {
  if (newProject) {
    if (newProject.editedFields.length > 0) {
      if (
        newProject.editedFields.includes("deliveryAddress") &&
        isEmptyObject(newProject["deliveryAddress"])
      ) {
        newProject["deliveryAddress"] = null;
      }
    }

    return newProject.editedFields.map(function(field) {
      let fieldNameForDB = field;
      let val = newProject[field];

      return {
        op: "replace",
        path: "/" + fieldNameForDB,
        value: val
      };
    });
  }

  return [];
};

export const localizeStatus = statusName => {
  let status;
  switch (statusName) {
    case constant.projectStatus.new:
      status = Localize.get("PROJECTS.MANAGEMENT.STATUS_NEW");
      break;
    case constant.projectStatus.closed:
      status = Localize.get("PROJECTS.MANAGEMENT.STATUS_CLOSED");
      break;
    case constant.projectStatus.process:
      status = Localize.get("PROJECTS.MANAGEMENT.STATUS_PROCESS");
      break;

    default:
      break;
  }
  return status;
};

export const clearEmptyEntries = data => {
  if (!data) {
    return true;
  }
  return Object.keys(data).forEach(key => !data[key] && delete data[key]);
};

export const getLabel = field => {
  let label;
  switch (field) {
    case "phone":
      label = Localize.get("CONTACTS.DETAILS.FORM.PHONE");
      break;
    case "mobile":
      label = Localize.get("CONTACTS.DETAILS.FORM.MOBILE");
      break;
    case "email":
      label = Localize.get("CONTACTS.DETAILS.FORM.EMAIL");
      break;
    case "address":
      label = Localize.get("CONTACTS.DETAILS.FORM.ADDRESS");
      break;
    case "company":
      label = Localize.get("CONTACTS.DETAILS.FORM.COMPANY_NAME");
      break;
    case "customer":
      label = Localize.get("CONTACTS.SIDEBAR.CONTACT_ROLE_CUSTOMER");
      break;
    case "fax":
      label = Localize.get("CONTACTS.DETAILS.FORM.FAX");
      break;
    case "taxNumber":
      label = Localize.get("CONTACTS.DETAILS.FORM.TAX_NUMBER");
      break;
    case "upin":
      label = Localize.get("CONTACTS.DETAILS.FORM.UPIN");
      break;
    case "vatin":
      label = Localize.get("CONTACTS.DETAILS.FORM.VATIN");
      break;
    case "web":
      label = Localize.get("CONTACTS.DETAILS.FORM.WEB");
      break;
    default:
      break;
  }
  return label;
};

export const getInitialFields = currentTypes => {
  let initial;
  switch (currentTypes) {
    case "person":
      initial = constant.initSelectedPersonFields;
      break;
    case "company":
      initial = constant.initSelectedCompaniesFields;
      break;
    case "employee":
      initial = constant.initSelectedEmployeeFields;
      break;
    default:
      initial = [];
      break;
  }
  return initial;
};
