import axios from "axios";
import { getLocalStorage, logOutOffUnauthorizedCall, getSession } from "../Auth/AuthContext";
import * as constant from "./Constants.js";
import { get } from "lodash";

export class BaseService {
  constructor() {
    this.configuration = constant.DEFAULTCONFIGURATION;
  }

  getServiceURL() {
    const resource = this.configuration.resource;
    let pathParameter = "";
    if (
      this.configuration &&
      this.configuration.pathParameter &&
      this.configuration.pathParameter.toString().includes("id")
    ) {
      pathParameter =
        this.configuration.pathParameter !== undefined
          ? "?" + this.configuration.pathParameter
          : "";
    } else {
      pathParameter =
        this.configuration.pathParameter !== undefined
          ? "/" + this.configuration.pathParameter
          : "";
    }
    const query = this.getQuery();
    return process.env.REACT_APP_API_LINK + resource + pathParameter + query;
  }

  getQuery() {
    // TODO checks for query, is it empty etc
    if (this.query) {
      const query = this.query.build();
      return query.length > 0 ? "?" + query : "";
    }
    return "";
  }

  setCallbackSuccess(callback) {
    this.callbackSuccess = callback;
  }

  setCallbackError(callback) {
    this.callbackError = callback;
  }

  setCallbackFinally(callback) {
    this.callbackFinally = callback;
  }

  setPayload(payload) {
    this.payload = payload;
  }

  async loginCall() {
    const configuration = {
      url: this.getServiceURL(),
      method: this.configuration.method
    };
    if (this.payload) {
      configuration.data = this.payload;
      const token = `${this.payload.username}:${this.payload.password}`;
      const encodedToken = btoa(token);
      configuration.headers = {
        Authorization: `Basic ${encodedToken}`
      };
    }
    try {
      const response = await axios(configuration);
      if (this.callbackSuccess) {
        this.callbackSuccess(response);
      }
    } catch (error) {
      if (this.callbackError) {
        const status = get(error, " response.status");
        const message = get(error, "response.data.message");

        if ((status === 401 && (message === "logout" || message === "expired")) || status === 500) {
          const errorMessage = status === 500 ? "error" : message;
          logOutOffUnauthorizedCall(errorMessage);
        }
        this.callbackError(error);
      }
    } finally {
      if (this.callbackFinally) {
        this.callbackFinally();
      }
    }
  }

  async call() {
    const configuration = {
      url: this.getServiceURL(),
      method: this.configuration.method
    };
    if (this.payload) {
      configuration.data = this.payload;
    }

    const auth = getLocalStorage("auth") || {};
    const session = getSession();
    const { token } = auth;
    if (token) {
      configuration.headers = {
        Authorization: "Bearer " + token,
        Session: session
      };
    } else if (session) {
      configuration.headers = { Session: session };
    }
    if (this.query && this.query["responseType"]) {
      configuration.responseType = this.query["responseType"];
    }
    try {
      const response = await axios(configuration);
      if (this.callbackSuccess) {
        this.callbackSuccess(response);
      }
    } catch (error) {
      if (this.callbackError) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          ((error.response.status === 401 &&
            (error.response.data.message === "logout" ||
              error.response.data.message === "expired")) ||
            error.response.status === 500)
        ) {
          if (error.response.status === 500) {
            error.response.data.message = "error";
          }
          logOutOffUnauthorizedCall(error.response.data.message);
        }
        this.callbackError(error);
      }
    } finally {
      if (this.callbackFinally) {
        this.callbackFinally();
      }
    }
  }
}

export class BaseBuilder {
  constructor() {
    this.parameter = {};
    this.parameter.page = 0;
    this.parameter.size = 20;
  }
  setPage(page) {
    this.parameter.page = page;
    return this;
  }
  clearPage() {
    delete this.parameter.page;
    return this;
  }
  setPageSize(size) {
    this.parameter.size = size;
    return this;
  }
  clearPageSize() {
    delete this.parameter.size;
    return this;
  }
  addPageSort(sort) {
    this.parameter.sort = sort.build();
    return this;
  }
  clearPageSort(sort) {
    delete this.parameter.sort;
    return this;
  }
  build() {
    return new BaseQuery(this.parameter).query;
  }
}

export class BaseQuery {
  constructor(build) {
    this.parameter = {};
    if (build !== undefined) {
      this.query = Reflect.ownKeys(build)
        .filter(key => {
          if (build[key] instanceof Array && build[key].length === 0) return false;
          if (build[key] === null) return false;
          return true;
        })
        .map(key => {
          return key + "=" + build[key];
        })
        .join("&");
      this.parameter = { ...this.parameter, build };
    }
  }
  static get Builder() {
    return BaseBuilder;
  }
}
