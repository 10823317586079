import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../Auth/AuthContext";
import { Button, Input, Segment, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Localize from "react-intl-universal";
import { Token } from "../RestService";
import styles from "./LoginForm.module.css";
import * as notificationConstants from "../Notifications/Constants.js";
import showNotification from "../Notifications/NotificationFunction";
import UserSettingsModal from "./UserSettingsModal";

const LoginForm = props => {
  const { authState, loadLocales } = useContext(AuthContext);
  const { user, sessionExpired } = authState;
  const SIGN_IN_ICON = "sign-in";
  const SIGN_OUT_ICON = "sign-out";
  // eslint-disable-next-line no-unused-vars
  const noop = () => {};

  let textSignType;

  const GetToken = new Token.GetOne.Service();
  const [username, setUsername] = useState(sessionExpired ? user.username : "");
  const [password, setPassword] = useState("");
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [signInSuccess, setSignInSuccess] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const handleOnSigninButtonClick = e => {
    GetToken.setCallbackSuccess(response => {
      const user = response.data.user;
      const token = response.data.token;
      const session = response.data.session;
      props.dispatch({
        type: "set",
        payload: {
          user: {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            username: user.username,
            authorities: user.authorities,
            language: user.language
          },
          token: token,
          session: session
        }
      });
      loadLocales(user.language);
      props.history.replace("/bms");
      props.history.push("/");
    });
    GetToken.setCallbackError(error => {
      if (error && error.response && error.response.status && error.response.status === 403) {
        setLogoutDialog(true);
      } else {
        setSignInSuccess(true);
      }
    });
    GetToken.setPayload({
      username: username,
      password: password
    });
    GetToken.loginCall();
  };

  const handleSignOutForm = () => {
    const Logout = new Token.DeleteToken.Service();
    const userToLogout = user ? user.username : username;
    Logout.query.addUser(userToLogout);

    // call context signout
    Logout.setCallbackSuccess(() => {
      props.dispatch({
        type: "del"
      });
      showNotificationMessage({
        type: notificationConstants.notificationType.info
      });
    });
    Logout.setCallbackError(error => {
      if (error && error.response && error.response.status === 404) {
        showNotificationMessage({
          type: notificationConstants.notificationType.warning
        });
      }
    });
    Logout.call();
  };

  const showNotificationMessage = param => {
    showNotification({
      entity: Localize.get("AUTHORIZATION.LOGOUT"),
      ...param
    });
  };

  const handleOnCancelButtonClick = event => {
    event.stopPropagation();
    setDropdownOpened(false);
  };

  const onFormClick = e => {
    setDropdownOpened(!dropdownOpened);
  };

  const clearLoginForm = () => {
    setPassword("");
    if (!sessionExpired) {
      setUsername("");
    }
  };

  const handleDropdownOnOpen = () => {
    clearLoginForm();
  };

  const onKeyDown = event => {
    if (event.which === 13 /* Enter */) {
      handleOnSigninButtonClick();
    }
  };

  const getLogoutDialog = () => {
    let dialog;
    dialog = (
      <Modal open={logoutDialog} size="tiny">
        <Modal.Header>{Localize.get("AUTHORIZATION.ALREADY_LOGGEDIN")}</Modal.Header>
        <Modal.Content>{Localize.get("AUTHORIZATION.LOGOUT_USER")}</Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              setLogoutDialog(false);
            }}
          >
            {Localize.get("AUTHORIZATION.BUTTON_NO")}
          </Button>
          <Button
            primary
            onClick={() => {
              handleSignOutForm();
              setLogoutDialog(false);
            }}
          >
            {Localize.get("GLOBAL.CONFIRMATION.BUTTON_YES")}
          </Button>
        </Modal.Actions>
      </Modal>
    );
    return dialog;
  };

  let logMenu;
  if (props.signedIn) {
    textSignType = user !== null ? `${user.firstname} ${user.lastname}` : "";
    logMenu = (
      <Dropdown key={1} floating text={textSignType}>
        <Dropdown.Menu>
          <Dropdown.Item
            icon={"setting"}
            text={"Settings"}
            onClick={() => setShowSettings(true)}
            style={{ color: "red" }}
          />
          <Dropdown.Item
            icon={SIGN_OUT_ICON}
            text={Localize.get("AUTHORIZATION.SIGN_OUT")}
            onClick={handleSignOutForm}
            style={{ color: "red" }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    textSignType = Localize.get("AUTHORIZATION.SIGN_IN");
    const labelColor = !props.signedIn && !signInSuccess ? "Black" : "Red";
    const labelText =
      !props.signedIn && !signInSuccess
        ? Localize.get("AUTHORIZATION.SIGNIN_TEXT")
        : Localize.get("AUTHORIZATION.SIGNIN_ERROR");

    logMenu = (
      <Dropdown
        key={2}
        icon={null}
        floating
        trigger={
          <Button icon color={"blue"} labelPosition="right" onClick={onFormClick}>
            <Icon name={SIGN_IN_ICON} />
            {Localize.get("AUTHORIZATION.SIGN_IN")}
          </Button>
        }
        onOpen={handleDropdownOnOpen}
        open={dropdownOpened}
      >
        <Dropdown.Menu>
          <Dropdown.Header
            style={{ color: labelColor }}
            className={styles.loginForm_header}
            content={labelText}
          />
          <Dropdown.Item>
            <Form>
              <Segment attached>
                <Form.Field>
                  <Input
                    value={username}
                    placeholder={Localize.get("AUTHORIZATION.USERNAME_PLACEHOLDER")}
                    fluid
                    onChange={e => {
                      setUsername(e.target.value);
                    }}
                    onBlur={e => e.stopPropagation()}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    type="password"
                    value={password}
                    placeholder={Localize.get("AUTHORIZATION.PASSWORD_PLACEHOLDER")}
                    fluid
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    onBlur={e => e.stopPropagation()}
                    onKeyDown={onKeyDown}
                  />
                </Form.Field>
              </Segment>
              <Button.Group attached="bottom">
                <Button
                  tabIndex={3}
                  fluid
                  positive
                  style={{ width: "150px" }}
                  onClick={handleOnSigninButtonClick}
                >
                  {Localize.get("AUTHORIZATION.SIGN_IN")}
                </Button>
                <Button
                  tabIndex={4}
                  negative
                  fluid
                  style={{ width: "150px" }}
                  onClick={handleOnCancelButtonClick}
                  onKeyDown={handleOnCancelButtonClick}
                >
                  {Localize.get("GLOBAL.FORM.CANCEL")}
                </Button>
              </Button.Group>
            </Form>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  useEffect(() => {
    if (sessionExpired) {
      let data = JSON.parse(window.localStorage.getItem("expired_message")) || null;

      if (data) {
        localStorage.removeItem("expired_message");
        showNotification({
          type: "info",
          serverMessage: Localize.get("AUTHORIZATION.SESSION_EXPIRED")
        });
      }
    }
  }, []);

  return (
    <div>
      {showSettings && (
        <UserSettingsModal
          dispatch={props.dispatch}
          currentUserId={user && user.id ? user.id : undefined}
          currentUsername={user && user.username ? user.username : undefined}
          currentLanguageId={
            user && user.language && user.language.id ? user.language.id : undefined
          }
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          loadLocales={loadLocales}
        />
      )}
      {logMenu}
      {getLogoutDialog()}
    </div>
  );
};

export default withRouter(LoginForm);
