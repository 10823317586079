import React, { useEffect } from "react";
import Localize from "react-intl-universal";
import { Icon, Dropdown, Input, Dimmer, Message } from "semantic-ui-react";
import ContactListItem from "./ContactListItem";
import ListWithPagination from "../../Components/ListWithPagination";
import styles from "./style/ContactList.module.css";
import * as constant from "../Constants.js";
import ToolBar from "../../Components/ToolBar";
import usePartnerServices from "../../RestServicesNew/usePartnerServices.js";
import showNotification from "../../Notifications/NotificationFunction";
import * as notificationConstants from "../../Notifications/Constants.js";
import { BASE } from "../../RestService/Constants.js";

const ContactList = props => {
  const {
    privileges,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    scrollListBottom,
    setScrollListBottom,
    scrollListTop,
    setScrollListTop,
    selectedContact,
    handle,
    handleHeader,
    state,
    contacts,
    listDimmed,
    partnersPanelState,
    postPartnerCall,
    checkedItemsIds,
    checkedAll
  } = props;

  const {
    sortDirection,
    sortColumn,
    number,
    name,
    city,
    search,
    activeArchiveFilter,
    activeTypeFilter
  } = partnersPanelState;

  const { onSearchChange, onResetToolbarSearch } = handleHeader;

  const { verifyAllPartners } = usePartnerServices();

  const contactList = contacts.map(contactItem => (
    <div key={contactItem.id}>
      <ContactListItem
        key={contactItem.id}
        name={contactItem.id}
        item={contactItem}
        itemHandler={handle.onToggleItem}
        checkedItemsIds={checkedItemsIds}
        setSelectedItem={handle.onListItemClick}
        selectedItem={selectedContact && selectedContact.id}
      />
    </div>
  ));
  const selectAllDisable = contacts.length === 0;

  const URL_PATH = "/partners";
  const updatedArr = ids =>
    ids &&
    ids.map(el => {
      return {
        id: el,
        operation: [
          {
            op: "replace",
            path: "/verified",
            value: "true"
          }
        ]
      };
    });

  const handleVerifyAllPartners = async () => {
    showNotification({
      custom: "TAKE_SOME_TIME",
      type: notificationConstants.notificationType.info
    });

    await verifyAllPartners({}, BASE.resource.verifyAll, null, "PATCH");
  };

  const actionButtonArr = [
    {
      icon: "check",
      text: Localize.get("CONTACTS.LIST.TOOLBAR.VERIFY_PARTNERS"),
      handler: async () => {
        await postPartnerCall(updatedArr(checkedItemsIds), URL_PATH, false, "PATCH");
      },
      disabled: null
    },
    {
      icon: "check square outline",
      text: Localize.get("CONTACTS.LIST.TOOLBAR.VERIFY_ALL"),
      handler: handleVerifyAllPartners,
      disabled: false
    }
  ];

  // Handlers
  const handleOnDeleteClick = (event, data) => {
    event.preventDefault();
    handle.onDeleteClick();
  };

  const resartFiltersAndSearchClick = e => {
    e.preventDefault();
    handle.onClearClick();
  };

  useEffect(() => {
    let element = document.getElementById("listToScroll");

    if (element && scrollListBottom) {
      element.scrollTo(0, 700);
      setScrollListBottom(false);
    }

    if (element && scrollListTop) {
      element.scrollTo(0, 0);
      setScrollListTop(false);
    }
  }, [state.activeListItem]);

  return (
    <>
      <div className={`ten wide column items_list ${styles.middle_panel}`}>
        <Dimmer active={listDimmed} inverted></Dimmer>

        <div className={styles.header}>
          <ToolBar
            searchName={onSearchChange}
            enteredValue={search}
            resetSearches={e => resartFiltersAndSearchClick(e)}
            privileges={privileges}
            resetNameSearch={onResetToolbarSearch}
            emptyList={selectAllDisable}
            onToggleAll={handle.onToggleAll}
            onArchiveClick={handle.onArchiveClick}
            onDeleteClick={handleOnDeleteClick}
            archiveOrUnarchive={activeArchiveFilter}
            // deleteEnable is prepared for backend rules integration, see example on documents module
            deleteEnable={true}
            popupContentText={
              activeTypeFilter === constant.contactType.all
                ? Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PARTNERS_POPUP")
                : Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_COMPANY_POPUP")
            }
            disableSearch={number !== "" || name !== "" || city !== ""}
            actionButtonArr={actionButtonArr}
            checkedItemsIds={checkedItemsIds}
            checkedAll={checkedAll}
            activePaginationPage={activePaginationPage}
          />
        </div>
        <div
          className={`ui six column stackable middle aligned grid ${styles.table_headerContactList}`}
        >
          <div className={`row ${styles.header_main} ${styles.bms_header_small_screen}`}>
            <div className="one wide column" />
            <div className="two wide column">
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("CONTACTS.LIST.HEADER.NUMBER")}</h5>
                    {sortDirection === "desc" && sortColumn === "number" ? (
                      <Icon name="angle down" />
                    ) : (
                      ""
                    )}
                    {sortDirection === "asc" && sortColumn === "number" ? (
                      <Icon name="angle up" />
                    ) : (
                      ""
                    )}
                    {number !== "" ? <Icon name="filter" /> : ""}
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NUMBER_ASC")}
                    onClick={() => handleHeader.onSortButtonClick("Number", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NUMBER_DESC")}
                    onClick={() => handleHeader.onSortButtonClick("Number", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    name="number"
                    icon="search"
                    disabled={search !== "" && search !== null}
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={number}
                    onClick={e => e.stopPropagation()}
                    onChange={onSearchChange}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={`two wide column ${styles.product_typ}`}>
              <div className={styles.product_role_st}>
                <h5>{Localize.get("CONTACTS.LIST.HEADER.ROLE")}</h5>
              </div>
            </div>
            <div className={`two wide column ${styles.product_typ}`}>
              <h5> {Localize.get("CONTACTS.LIST.HEADER.TYPE")}</h5>
            </div>
            <div className={`four wide column ${styles.product_name}`}>
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("CONTACTS.LIST.HEADER.NAME")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "name" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "name" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {name !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => handleHeader.onSortButtonClick("Name", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => handleHeader.onSortButtonClick("Name", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    name="name"
                    icon="search"
                    disabled={search !== "" && search !== null}
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={name}
                    onClick={e => e.stopPropagation()}
                    onChange={onSearchChange}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={`five wide column ${styles.product_address}`}>
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("CONTACTS.LIST.HEADER.ADDRESS")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "city" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "city" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {city !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_ADDRESS_ASC")}
                    onClick={() => handleHeader.onSortButtonClick("City", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_ADDRESS_DESC")}
                    onClick={() => handleHeader.onSortButtonClick("City", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    name="city"
                    icon="search"
                    disabled={search !== "" && search !== null}
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={city}
                    onClick={e => e.stopPropagation()}
                    onChange={onSearchChange}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        {contactList.length > 0 ? (
          <ListWithPagination
            list={contactList}
            state={state}
            id="contactList"
            defaultActivePage={paginationStatus.pageCount}
            totalPage={paginationStatus.totalCount}
            perPage={paginationStatus.perPage}
            handlePageChange={handlePageChange}
            activePaginationPage={activePaginationPage}
          />
        ) : (
          <Message icon>
            <Icon name="info circle" />
            {Localize.get("CONTACTS.LIST.TOOLBAR.EMPTY")}
          </Message>
        )}
      </div>
    </>
  );
};

export default ContactList;
