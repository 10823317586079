import React, { useEffect, useState } from "react";
import ExportSideBar from "./ExportSideBar";
import DatevListStatus from "./DatevListStatus";
import styles from "./style/Exported.module.css";
import { Sidebar, Segment } from "semantic-ui-react";

const Exported = props => {
  const { history } = props;

  const [sideBarVisibility, setSideBarVisibility] = useState(false);
  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  return (
    <>
      <div className={`ui container fluid `}>
        <div>
          <div
            className={`ui right attached button ${styles.bms_fixed} ${
              sideBarVisibility ? styles.bms_zindex : null
            }`}
            onClick={settingVisibleSidebar}
          >
            <i className="ellipsis vertical icon"></i>
            <span className="text">Settings</span>
          </div>

          <Sidebar.Pushable as={Segment} className={styles.bms_segment}>
            <ExportSideBar
              history={history}
              sideBarVisibility={sideBarVisibility}
              setSideBarVisibility={setSideBarVisibility}
            />
            <Sidebar.Pusher dimmed={sideBarVisibility} className={styles.pusher}>
              <div className={styles.bms_partners_content}>
                <DatevListStatus />
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </div>
    </>
  );
};

export default Exported;
