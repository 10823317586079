import React from "react";
import { Menu, Grid, GridColumn } from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as constant from "./Constants.js";
import styles from "./style/TransactionListItem.module.css";

const TransactionListItem = props => {
  const currentLanguage = navigator.language;
  const colorBalanceLabel =
    props.transaction.price > 0
      ? `${styles.positive} ${styles.wrapColumn}`
      : `${styles.negative} ${styles.wrapColumn}`;
  const signPrice = props.transaction.price > 0 ? "+" : "";

  const transactionLabel = name => {
    switch (name) {
      case constant.assignment.assigned:
        return (
          <span className={`${styles.spanLabelStyle} ${styles.labelLinked}`}>
            {Localize.get("TRANSACTIONS.ITEMLIST.LABEL_LINKED")}
          </span>
        );
      case constant.assignment.notAssigned:
        return (
          <span className={`${styles.spanLabelStyle} ${styles.labelUnlinked}`}>
            {Localize.get("TRANSACTIONS.ITEMLIST.LABEL_UNLINKED")}
          </span>
        );
      case constant.assignment.assignedWithoutDoc:
        return (
          <span className={`${styles.spanLabelStyle} ${styles.labelWithoutDoc}`}>
            {Localize.get("TRANSACTIONS.ITEMLIST.LABEL_LINKED_WITHOUT_DOCUMENT")}
          </span>
        );
      default:
        return "ERROR";
    }
  };

  const directionSign = name => {
    switch (name) {
      case constant.direction.revenue:
        return (
          <div>
            <i className="olive credit card outline icon" />{" "}
            {Localize.get("TRANSACTIONS.DIRECTION.REVENUE")}
          </div>
        );
      case constant.direction.expense:
        return (
          <div>
            <i className="red credit card outline icon" />{" "}
            {Localize.get("TRANSACTIONS.DIRECTION.EXPENSE")}
          </div>
        );
      default:
        return "ERROR";
    }
  };

  const handleItemClick = (event, data) => {
    props.onListItemClick(data.item_id);
  };

  return (
    <Menu.Item
      item_id={props.transaction.id}
      active={props.state.activeListItem === props.transaction.id ? true : null}
      onClick={handleItemClick}
      className={styles.list_item}
    >
      <Grid columns="6" stackable verticalAlign="middle" padded="vertically">
        <GridColumn largeScreen={3} className={styles.colFixedHeight}>
          {transactionLabel(props.transaction.assignmentType)}
        </GridColumn>
        <GridColumn className={styles.item_column} largeScreen={2}>
          <div className={`content ${styles.center_align}`}>
            {directionSign(props.transaction.directionType)}
          </div>
        </GridColumn>
        <GridColumn className={styles.item_column} largeScreen={4}>
          <div className={`content ${styles.wrapColumn}`}>
            <div className={styles.list_name}>{props.transaction.companyName}</div>
            <div className={styles.list_desc}>
              {props.transaction.customerId !== "null" ? props.transaction.customerId : ""}
            </div>
          </div>
        </GridColumn>
        <GridColumn className={styles.item_column} largeScreen={3}>
          <div className={`content ${styles.wrap_column}`}>{props.transaction.purpose}</div>
        </GridColumn>
        <GridColumn className={styles.item_column} largeScreen={2}>
          <div className={`content ${styles.date_wrap}`}>
            {props.transaction.date.toLocaleDateString(currentLanguage, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            })}
          </div>
        </GridColumn>
        <GridColumn className={styles.item_column} largeScreen={2}>
          <div className={colorBalanceLabel}>
            {signPrice}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR"
            }).format(props.transaction.price)}
          </div>
        </GridColumn>
      </Grid>
    </Menu.Item>
  );
};

export default TransactionListItem;
