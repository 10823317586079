import React, { useEffect, useState } from "react";
import { Icon, Table, Popup, Button, Modal, Divider } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/DocNewItemTable.module.css";
import * as constant from "../../Constants";
import TableCellInputOut from "../../../Components/Lists/TableCellInputOut";
import TableCellDropdownOut from "../../../Components/Lists/TableCellDropdownOut";
import { checkIsValidObject, priceOptions, checkValidationList } from "../DocumentTotalUtil";
import GlobalCancelBtn from "../../../Components/GlobalCancelBtn";

const DocNewItemTable = props => {
  const {
    items,
    deleteItem,
    itemsHandler,
    itemLineIdText,
    sellerItemIdText,
    itemNameText,
    vatText,
    unitText,
    quantityText,
    changeItemAmountType,
    netPriceText,
    surchargeText,
    discountText,
    checkEdit,
    setCheckEdit,
    documentItemsValidator,
    validateOrderItemField,
    documentType,
    sectionConfig,
    amountType,
    updateOrderItemsValidator,
    currency
  } = props;

  const [orderItems, setOrderItems] = useState(items ? [...items] : []);
  const [editedIndex, setEditedIndex] = useState(null);
  const [requirementsModal, setRequirementsModal] = useState(false);

  const handleOnBlur = (event, data) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    validateOrderItemField(fieldName, fieldValue, editedIndex);
  };

  const getIcon = () => {
    if (currency) {
      switch (currency.id) {
        case "EUR":
          return <Icon name="euro sign" />;
        case "GBP":
          return <Icon name="pound" />;
        case "USD":
          return <Icon name="dollar sign" />;
        default:
          break;
      }
    } else {
      return "";
    }
  };

  const onItemChange = (event, data) => {
    let newOrderItems = [...orderItems];
    if (
      documentItemsValidator.parameterList &&
      documentItemsValidator.parameterList[editedIndex] &&
      documentItemsValidator.parameterList[editedIndex][data.name] !== ""
    ) {
      validateOrderItemField(data.name, data.value, editedIndex);
    }

    newOrderItems[editedIndex] = {
      ...newOrderItems[editedIndex],
      [event.target.name]: event.target.value
    };

    setOrderItems(newOrderItems);
  };

  const onDropdownClick = (event, data) => {
    validateOrderItemField(data.name, data.value, editedIndex);
    let newOrderItems = [...orderItems];
    if (data.name === "unit") {
      let myUNcode = constant.unitOptions.find(item => item.value === data.value);
      newOrderItems[editedIndex] = {
        ...newOrderItems[editedIndex],
        [data.name]: {
          unit: data.value,
          commonCodeUN: myUNcode.key
        }
      };
    } else if (data.name === "vat") {
      newOrderItems[editedIndex] = {
        ...newOrderItems[editedIndex],
        vat: Number(data.value)
      };
    } else {
      newOrderItems[editedIndex] = {
        ...newOrderItems[editedIndex],
        [data.name]: data.value
      };
    }

    setOrderItems(newOrderItems);
  };

  const onEditItemClick = (item, index) => {
    setEditedIndex(index);
    setCheckEdit({ type: "editItems" });
  };

  const onEditItemChecked = index => {
    if (checkIsValidObject(documentItemsValidator.parameterList[index])) {
      itemsHandler(orderItems);
      setEditedIndex(null);
    }
  };

  const onEditItemCancel = () => {
    setEditedIndex(null);
    setOrderItems(items ? [...items] : []);
    setCheckEdit({ type: "nonEditState" });
  };

  const onAddNewItem = () => {
    orderItems.push(constant.initDocumentItem);
    setEditedIndex(orderItems.length - 1);
    setOrderItems(orderItems);
    setCheckEdit({ type: "editItems" });
    updateOrderItemsValidator(orderItems);
  };

  useEffect(() => {
    setOrderItems(items ? [...items] : []);
  }, [items]);

  return (
    <>
      <Table selectable className={`${styles.new_item_table}`}>
        <Table.Header>
          <Table.Row>
            {sectionConfig.itemLineId && (
              <Table.HeaderCell className={styles.cell_width_2}>
                {Localize.get(itemLineIdText)}
              </Table.HeaderCell>
            )}
            {sectionConfig.sellerItemId && (
              <Table.HeaderCell className={styles.cell_width_2}>
                {Localize.get(sellerItemIdText)}
              </Table.HeaderCell>
            )}
            {sectionConfig.name && (
              <Table.HeaderCell className={styles.cell_width_3}>
                {Localize.get(itemNameText)}
              </Table.HeaderCell>
            )}

            {sectionConfig.vat && (
              <Table.HeaderCell className={styles.cell_width_1}>
                {Localize.get(vatText)}
              </Table.HeaderCell>
            )}
            {sectionConfig.unit && (
              <Table.HeaderCell className={styles.cell_width_1}>
                {Localize.get(unitText)}
              </Table.HeaderCell>
            )}
            {sectionConfig.quantity && (
              <Table.HeaderCell className={styles.cell_width_1}>
                {Localize.get(quantityText)}
              </Table.HeaderCell>
            )}

            {sectionConfig.netPrice && (
              <Table.HeaderCell className={styles.cell_width_2}>
                {Localize.get(netPriceText)}
                {getIcon()}
              </Table.HeaderCell>
            )}
            {sectionConfig.amount && (
              <Table.HeaderCell className={styles.cell_width_2}>
                {" "}
                <TableCellDropdownOut
                  editedIndex={1}
                  dropdownOptions={priceOptions(currency)}
                  placeholder={"Price type"}
                  index={1}
                  inputValue={amountType}
                  customInputClassName={styles.tax_dropdown_opened}
                  icon="dropdown"
                  inputName={"priceType"}
                  onCellChange={changeItemAmountType}
                  disableDropdown={checkEdit.editMode}
                />
                {getIcon()}
              </Table.HeaderCell>
            )}

            {sectionConfig.discount && (
              <Table.HeaderCell className={styles.cell_width_2}>
                <span>
                  {Localize.get(surchargeText)}/
                  <br />
                  {Localize.get(discountText)}
                </span>
                {getIcon()}
              </Table.HeaderCell>
            )}
            {sectionConfig.plusIcon && (
              <Table.HeaderCell className={styles.cell_width_1} textAlign="center">
                <Popup
                  content={"Add item"}
                  size="tiny"
                  trigger={
                    <Button
                      icon="plus"
                      size="small"
                      onClick={onAddNewItem}
                      className={styles.plus_button}
                      disabled={editedIndex === 0 || checkEdit.editMode}
                    />
                  }
                />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orderItems ? (
            orderItems.map((item, index) => {
              let itemsLength = orderItems.length;
              let {
                itemLineId,
                sellerItemId,
                name,
                unit,
                vat,
                quantity,
                listPrice,
                amount,
                surchargeDiscount
              } = item;
              return (
                <Table.Row key={index} className={styles.table_row}>
                  {sectionConfig.itemLineId && (
                    <Table.Cell width={2} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={itemLineId}
                        inputName={"itemLineId"}
                        onCellChange={onItemChange}
                        onCellBlur={handleOnBlur}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "itemLineId"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.sellerItemId && (
                    <Table.Cell width={2} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={sellerItemId}
                        inputName={"sellerItemId"}
                        onCellChange={onItemChange}
                        onCellBlur={handleOnBlur}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "sellerItemId"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.name && (
                    <Table.Cell width={3} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={name}
                        placeholder={"Name"}
                        showPopup={name && name.length > 40 ? true : false}
                        inputName={"name"}
                        onCellBlur={handleOnBlur}
                        onCellChange={onItemChange}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "name"
                        )}
                      />
                    </Table.Cell>
                  )}

                  {sectionConfig.vat && (
                    <Table.Cell width={1} className={styles.dropdown_cell}>
                      <TableCellDropdownOut
                        upward={index >= itemsLength - 2}
                        editedIndex={editedIndex}
                        dropdownOptions={constant.taxOptions}
                        placeholder={"Vat"}
                        index={index}
                        inputValue={vat}
                        customInputClassName={styles.dropdown_opened}
                        icon="dropdown"
                        inputName={"vat"}
                        onCellChange={onDropdownClick}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "vat"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.unit && (
                    <Table.Cell width={1} className={styles.dropdown_cell}>
                      <TableCellDropdownOut
                        upward={index >= itemsLength - 2}
                        editedIndex={editedIndex}
                        dropdownOptions={constant.unitOptions}
                        customInputClassName={styles.dropdown_opened}
                        index={index}
                        inputValue={unit ? unit.unit : ""}
                        inputName={"unit"}
                        onCellChange={onDropdownClick}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "unit"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.quantity && (
                    <Table.Cell width={1} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={quantity}
                        inputName={"quantity"}
                        onCellBlur={handleOnBlur}
                        onCellChange={onItemChange}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "quantity"
                        )}
                      />
                    </Table.Cell>
                  )}

                  {sectionConfig.netPrice && (
                    <Table.Cell width={2} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={listPrice}
                        inputName={"listPrice"}
                        onCellBlur={handleOnBlur}
                        onCellChange={onItemChange}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "listPrice"
                        )}
                      />
                    </Table.Cell>
                  )}

                  {sectionConfig.amount && (
                    <Table.Cell width={2} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={amount}
                        inputName={"amount"}
                        onCellBlur={handleOnBlur}
                        onCellChange={onItemChange}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "amount"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.discount && (
                    <Table.Cell width={2} className={styles.default_cell}>
                      <TableCellInputOut
                        editedIndex={editedIndex}
                        index={index}
                        inputValue={surchargeDiscount}
                        inputName={"surchargeDiscount"}
                        fluid={true}
                        onCellBlur={handleOnBlur}
                        onCellChange={onItemChange}
                        hasError={checkValidationList(
                          documentItemsValidator.parameterList,
                          index,
                          "surchargeDiscount"
                        )}
                      />
                    </Table.Cell>
                  )}
                  {sectionConfig.plusIcon && (
                    <Table.Cell width={1} className={styles.edit_remove_save_cancel}>
                      {editedIndex === index ? (
                        <div>
                          <Popup
                            content={Localize.get("GLOBAL.FORM.SAVE")}
                            size="tiny"
                            trigger={
                              <Icon
                                className={styles.edit_icon}
                                name="check circle outline"
                                onClick={() => onEditItemChecked(index)}
                              />
                            }
                          />
                          <Popup
                            content={Localize.get("GLOBAL.FORM.CANCEL")}
                            size="tiny"
                            trigger={
                              <Icon
                                className={styles.edit_icon}
                                name="cancel"
                                onClick={onEditItemCancel}
                              />
                            }
                          />
                        </div>
                      ) : (
                        <div>
                          <Popup
                            content={Localize.get("CONTACTS.DETAILS.TOOLBAR.EDIT")}
                            size="tiny"
                            trigger={
                              <Icon
                                className={styles.edit_icon}
                                name="edit"
                                disabled={
                                  editedIndex === 0 ||
                                  (editedIndex && editedIndex !== index) ||
                                  checkEdit.editMode
                                }
                                onClick={() => onEditItemClick(item, index)}
                              />
                            }
                          />
                          <Popup
                            content={Localize.get("CONTACTS.DETAILS.TOOLBAR.DELETE")}
                            size="tiny"
                            trigger={
                              <Icon
                                className={styles.minus_icon}
                                name="minus circle"
                                disabled={
                                  editedIndex === 0 ||
                                  (editedIndex && editedIndex !== index) ||
                                  checkEdit.editMode
                                }
                                onClick={() => deleteItem(index)}
                              />
                            }
                          />
                        </div>
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell>{Localize.get("DOCUMENTS.NEWDOCUMENT.NO_DATA")}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {(documentType === "invoice" || documentType === "correction") &&
        !documentItemsValidator.parameterList.every(subtotalValidation =>
          Object.values(subtotalValidation).every(val => val === "")
        ) && (
          <div>
            <div className={styles.validation_message_red}>
              {Localize.get("GLOBAL.INFO.DOCUMENT_CAN_BE_SAVED")}
            </div>
            <Popup
              content={Localize.get("GLOBAL.INFO.CHECK_DATEV")}
              trigger={
                <Icon
                  className={styles.info_format}
                  circular
                  name="info"
                  color="red"
                  size="small"
                  onClick={() => setRequirementsModal(true)}
                />
              }
            />
          </div>
        )}
      {requirementsModal && (
        <Modal
          closeIcon
          onClose={() => setRequirementsModal(false)}
          size="small"
          open={requirementsModal}
        >
          <Modal.Header>{Localize.get("GLOBAL.INFO.DATEV_REQIREMENTS")}</Modal.Header>
          <Modal.Content>
            <div>{Localize.get("GLOBAL.INFO.DATEV_MANDATORY")}</div>
            <Divider />
            <div>{Localize.get("GLOBAL.INFO.DATEV_FORMAT_QNASD")}</div>
          </Modal.Content>
          <Modal.Actions className={styles.modal_actions_padding}>
            <GlobalCancelBtn onCancel={() => setRequirementsModal(false)} />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default DocNewItemTable;
