import React from "react";
import { Icon, Segment, Message } from "semantic-ui-react";
import Localize from "react-intl-universal";
import Notifier from "./Notifier";
import styles from "./style/InvoiceNotifiers.module.css";

const InvoiceNotifiers = ({
  notifiers = [],
  parametersRecipients,
  checkEditedFields,
  validateRecipientListField,
  deleteItemFromList,
  addItemToList,
  handleOnChangeList,
  sendInvoiceToParent = false,
  addNotifierInValidator,
  removeNotifierFromValidator
}) => {
  const handleDelete = index => {
    removeNotifierFromValidator(index);
    deleteItemFromList("recipients", index);
    checkEditedFields("contacts");
  };
  const handleOnChange = (index, notifier) => {
    handleOnChangeList("recipients", notifier, index);
    checkEditedFields("contacts");
  };

  const handleOnPlusClick = () => {
    let newContact = {
      email: "",
      first: "",
      jobTitle: "",
      phone: "",
      contactType: "invoice"
    };
    addNotifierInValidator(newContact);
    addItemToList("recipients", newContact);
    checkEditedFields("contacts");
  };

  return (
    <Segment.Group disabled={sendInvoiceToParent} className={styles.segment_group}>
      <Segment disabled={sendInvoiceToParent} className={styles.title}>
        <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.INVOICE_NOTIFIERS")}</strong>
        <div className={styles.formAddButton}>
          <Icon name="plus" onClick={handleOnPlusClick} disabled={sendInvoiceToParent} />
        </div>
      </Segment>

      <Segment disabled={sendInvoiceToParent} className={styles.form_lines}>
        {notifiers && notifiers.length > 0 ? (
          notifiers.map((notifier, index) => {
            return (
              <Notifier
                key={index}
                index={index}
                notifier={notifier}
                handleOnChangeProp={handleOnChange}
                handleDelete={handleDelete}
                title={Localize.get("CONTACTS.NEWCONTACT.FORM.NOTIFIER_TITLE")}
                errorFields={parametersRecipients[index]}
                validateRecipientListField={validateRecipientListField}
              />
            );
          })
        ) : (
          <Message
            className={styles.message_block}
            attached="bottom"
            header={Localize.get("CONTACTS.NEWCONTACT.FORM.NO_NOTIFIERS")}
            content={Localize.get("CONTACTS.NEWCONTACT.FORM.CLICK_PLUS_NOTIFIER")}
          />
        )}
      </Segment>
    </Segment.Group>
  );
};
export default InvoiceNotifiers;
