import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Accordion, Icon, Form, Input, Label, Button, Dropdown, Divider } from "semantic-ui-react";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import styles from "./style/EditFormRole.module.css";

const EditFormRole = props => {
  const { role, index, generatedRoles } = props;

  const [open, setOpen] = useState(false);
  const [validator] = useState(
    props.validator === undefined
      ? new Validator(validatorConstants.requiredFieldsTemplateRoles)
      : props.validator
  );

  const handleAccordionClick = (e, titleProps) => {
    return setOpen(!open);
  };

  const handleOnChange = (event, data) => {
    if (event.target.name in validatorConstants.requiredFieldsTemplateRoles) {
      validator.validateField(event.target.value, event.target.name);
    }
    props.onChange(index, { ...role, [data.name]: data.value });
  };

  const handleOnDeleteClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.handleDelete(index, role.resourceRole);
  };

  const handleNumberUp = event => {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget.name === "roleMax") {
      let newRolemax = role.max + 1;
      props.onChange(index, { ...role, max: newRolemax });
    }
    if (event.currentTarget.name === "roleMin") {
      let newRole = role;
      newRole.min = role.min + 1;
      if (newRole.min > role.max) {
        newRole.max = newRole.min;
      }
      props.onChange(index, newRole);
    }
  };

  const handleNumberDown = event => {
    if (event.currentTarget.name === "roleMax" && role.max - 1 >= 1) {
      let newRole = role;
      newRole.max = role.max - 1;
      if (newRole.max < role.min) {
        newRole.min = newRole.max;
      }
      props.onChange(index, newRole);
    }
    if (event.currentTarget.name === "roleMin" && role.min - 1 >= 0) {
      let newRolemin = role.min - 1;
      props.onChange(index, { ...role, min: newRolemin });
    }
  };

  const errorBorder = { border: "3px red solid", borderRadius: "0px" };

  useEffect(() => {
    validator.setConfiguration(validatorConstants.requiredFieldsTemplateRoles);
  }, [props.role]);

  const roleOptions = generatedRoles
    ? generatedRoles.map((role, index) => ({
        key: index,
        text: role.name,
        value: role.name
      }))
    : [];

  const handleSelectRole = (event, data) => {
    validator.validateField(data.value, "name");

    const selectedRole = generatedRoles.find(r => r.name === data.value);
    props.onChange(index, {
      ...role,
      resourceRole: {
        id: selectedRole.id,
        name: selectedRole.name,
        description: selectedRole.description
      }
    });
  };

  return (
    <Accordion className={styles.role_form_in_template} styled>
      <Accordion.Title
        className={styles.roles_details_title}
        active={validator.parameter.name ? true : open}
        onClick={handleAccordionClick}
      >
        <Icon name="dropdown" />
        {role && role.resourceRole && role.resourceRole.name ? role.resourceRole.name : props.title}
        <i
          className={`minus circle icon ${styles.minus_icon}`}
          size="small"
          color="red"
          onClick={handleOnDeleteClick}
        />
      </Accordion.Title>

      <Accordion.Content active={validator.parameter.name ? true : open}>
        <div className={styles.role_description}>
          {role && role.resourceRole && role.resourceRole.id === undefined ? (
            <>
              <Dropdown
                selection
                placeholder="Role"
                search
                className={styles.select_role_dropdown}
                style={
                  validator.parameter.name
                    ? {
                        ...errorBorder
                      }
                    : null
                }
                defaultValue={
                  role && role.resourceRole && role.resourceRole.name ? role.resourceRole.name : ""
                }
                options={roleOptions}
                onChange={handleSelectRole}
              />
              {validator.parameter.name && (
                <Label basic color="red" pointing="left" content={validator.parameter.name} />
              )}
            </>
          ) : (
            <>
              <strong>{Localize.get("PROJECTS.ROLES.ROLE_DESCRIPTION")}</strong>
              <br />{" "}
              {role.resourceRole ? role.resourceRole.description : role.id.resourceRole.description}
            </>
          )}
          <Divider />
        </div>

        <Form id="roleMinMax">
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{Localize.get("PROJECTS.TEMPLATES.ROLE_MIN")}</label>
              <Input
                fluid
                name="roleMin"
                style={{
                  border: validator.parameter.bankName ? errorBorder : ""
                }}
                icon={
                  <Button.Group icon vertical compact size="mini">
                    <Button
                      className={styles.mini_button_arrow}
                      name="roleMin"
                      icon="chevron up"
                      onClick={handleNumberUp}
                    />
                    <Button
                      className={styles.mini_button_arrow}
                      name="roleMin"
                      icon="chevron down"
                      onClick={handleNumberDown}
                    />
                  </Button.Group>
                }
                placeholder={Localize.get("PROJECTS.TEMPLATES.ROLE_MIN")}
                value={role.min === undefined ? "" : role.min}
                onChange={handleOnChange}
              />
              {validator.parameter.bankName && (
                <Label basic color="red" pointing content={validator.parameter.bankName} />
              )}
            </Form.Field>

            <Form.Field required>
              <label>{Localize.get("PROJECTS.TEMPLATES.ROLE_MAX")}</label>
              <Input
                fluid
                name="roleMax"
                style={{
                  border: validator.parameter.bankName ? errorBorder : ""
                }}
                icon={
                  <Button.Group icon vertical compact size="mini">
                    <Button
                      className={styles.mini_button_arrow}
                      name="roleMax"
                      icon="chevron up"
                      onClick={handleNumberUp}
                    />
                    <Button
                      className={styles.mini_button_arrow}
                      name="roleMax"
                      icon="chevron down"
                      onClick={handleNumberDown}
                    />
                  </Button.Group>
                }
                placeholder={Localize.get("PROJECTS.TEMPLATES.ROLE_MAX")}
                value={role.max === undefined ? "" : role.max}
                onChange={handleOnChange}
              />
              {validator.parameter.bankName && (
                <Label basic color="red" pointing content={validator.parameter.bankName} />
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default EditFormRole;
