import React, { useState } from "react";
import {
  Menu,
  Icon,
  Dropdown,
  Sidebar,
  Button,
  Container,
  Grid,
  Dimmer,
  Loader
} from "semantic-ui-react";
import * as constant from "../Constants.js";
import Localize from "react-intl-universal";
import styles from "./style/SideBar.module.css";
import OpenFilterModule from "./OpenFilterModule";
import GlobalModal from "../../Components/Panels/GlobalModal.js";
import useDocumentsServices from "../../RestServicesNew/useDocumentsServices.js";
import { BASE } from "../../RestService/Constants.js";

const SideBar = props => {
  const convertDocumentTypesStrings = docType => {
    return Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR." + docType.toUpperCase());
  };

  const convertDocumentDueStrings = docDue => {
    return Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_" + docDue.toUpperCase());
  };

  const {
    privileges,
    dateTypeValue,
    sideBarVisibility,
    setSideBarVisibility,
    documentsPanelState,
    fetchData
  } = props;

  const { syncFFO, syncFFOLoading } = useDocumentsServices(fetchData);

  const [showSinhroModal, setShowSinhroModal] = useState(false);

  const onButtonDropdown = (e, data) => {
    if (data.value === "upload") {
      props.handler.sidebar.onNewDocumentButtonClick();
      return;
    }
    if (data.value === "synhronize") {
      setShowSinhroModal(true);
      return;
    }
    if (data.value === "create") {
      return;
    }
  };

  const makeFFOCall = async () => {
    setShowSinhroModal(false);
    await syncFFO({}, BASE.resource.syncWithFFO, null, null, null, { ignoreInvalid: true });
  };

  const modalMessageComponent = () => (
    <div>{Localize.get("DOCUMENTS.SIDEBAR.CONFIRM_SYNCHRONIZE_MESSAGE")}</div>
  );

  return (
    <>
      <Dimmer active={syncFFOLoading} inverted>
        <Loader className={styles.loader_center} size="large" disabled={!syncFFOLoading}>
          {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENTS_LOADING")}
        </Loader>
      </Dimmer>

      <GlobalModal
        showModal={showSinhroModal}
        setShowModal={setShowSinhroModal}
        headerText={"DOCUMENTS.SIDEBAR.CONFIRM_SYNCHRONIZE"}
        CustomComponent={modalMessageComponent}
        modalHandler={makeFFOCall}
      />
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Container>
          {documentsPanelState.activeDocumentDirectionFilter ===
          constant.documentDirection.incoming ? (
            <Button
              primary
              disabled={!privileges.create}
              className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
              onClick={props.handler.sidebar.onNewDocumentButtonClick}
            >
              {Localize.get("DOCUMENTS.SIDEBAR.NEW_DOCUMENT")}
              <Icon name="plus" />
            </Button>
          ) : (
            <Dropdown
              button
              className={`${"icon"} ${styles.dropdown_button}`}
              floating
              labeled
              icon="plus"
              text={Localize.get("DOCUMENTS.SIDEBAR.NEW_DOCUMENT")}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="sync alternate"
                  text={Localize.get("DOCUMENTS.SIDEBAR.SYNC")}
                  value="synhronize"
                  onClick={onButtonDropdown}
                />
                <Dropdown.Item
                  icon="upload"
                  text={Localize.get("DOCUMENTS.SIDEBAR.UPLOAD")}
                  value="upload"
                  onClick={onButtonDropdown}
                />
                <Dropdown.Item
                  icon="plus square outline"
                  text={Localize.get("DOCUMENTS.SIDEBAR.CREATE")}
                  value="create"
                  onClick={onButtonDropdown}
                  disabled={true}
                />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Container>

        <OpenFilterModule
          filter={constant.sideBarDirectionConfig}
          onFilterClick={props.handler.sidebar.onDocumentDirectionFilterClick}
          activeFilter={documentsPanelState.activeDocumentDirectionFilter}
        />

        <Menu.Item>
          <Menu.Header>{Localize.get("DOCUMENTS.SIDEBAR.HEADER_DOCUMENT_TYPE")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item>
              <Dropdown
                text={convertDocumentTypesStrings(documentsPanelState.activeDocumentTypeFilter)}
              >
                <Dropdown.Menu className={styles.dropdown_style}>
                  <Dropdown.Item
                    name={constant.documentType.inquiry}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.inquiry
                    }
                  >
                    <Icon name="question circle" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_INQUIRY")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.offer}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.offer
                    }
                  >
                    <Icon name="envelope open" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_OFFER")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.order}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.order
                    }
                  >
                    <Icon name="list" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_ORDER")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.receipt}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.receipt
                    }
                  >
                    <Icon name="barcode" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_RECEIPT")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.deliveryNote}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter ===
                      constant.documentType.deliveryNote
                    }
                  >
                    <Icon name="sticky note" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_DELIVERY_NOTE")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.imported_invoice}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter ===
                      constant.documentType.imported_invoice
                    }
                  >
                    <Icon name="sign-in" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_IMPORTED")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.annex}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.annex
                    }
                  >
                    <Icon name="plus" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_ANNEX")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.invoice}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.invoice
                    }
                  >
                    <Icon name="file alternate outline" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_INVOICE")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.correction}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter ===
                      constant.documentType.correction
                    }
                  >
                    <Icon name="edit" />
                    {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.CORRECTION")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    name={constant.documentType.other}
                    onClick={props.handler.sidebar.onDocumentTypeFilterClick}
                    active={
                      documentsPanelState.activeDocumentTypeFilter === constant.documentType.other
                    }
                  >
                    <Icon name="file" />
                    {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_TYPE_OTHER")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
          <Grid columns="equal">
            <Grid.Column>
              <Menu.Header className={styles.dueHeader}>
                {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE")}
              </Menu.Header>
              <Menu.Menu>
                <Menu.Item className={styles.dueItem}>
                  <Dropdown
                    text={convertDocumentDueStrings(documentsPanelState.activeDueStatusFilter)}
                    disabled={dateTypeValue === constant.toolbarDayPicker.paymentDate}
                  >
                    <Dropdown.Menu className={styles.dropdown_style}>
                      <Dropdown.Item
                        name={constant.documentDue.overdue}
                        onClick={props.handler.sidebar.onDocumentDueFilterClick}
                        active={
                          documentsPanelState.activeDueStatusFilter === constant.documentDue.overdue
                        }
                      >
                        <Icon name="bell outline" />
                        {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_OVERDUE")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        name={constant.documentDue.today}
                        onClick={props.handler.sidebar.onDocumentDueFilterClick}
                        active={
                          documentsPanelState.activeDueStatusFilter === constant.documentDue.today
                        }
                      >
                        <Icon name="bell outline" />
                        {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_TODAY")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        name={constant.documentDue.thisWeek}
                        onClick={props.handler.sidebar.onDocumentDueFilterClick}
                        active={
                          documentsPanelState.activeDueStatusFilter ===
                          constant.documentDue.thisWeek
                        }
                      >
                        <Icon name="bell outline" />
                        {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_THISWEEK")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        name={constant.documentDue.nextWeek}
                        onClick={props.handler.sidebar.onDocumentDueFilterClick}
                        active={
                          documentsPanelState.activeDueStatusFilter ===
                          constant.documentDue.nextWeek
                        }
                      >
                        <Icon name="bell outline" />
                        {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_NEXTWEEK")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        name={constant.documentDue.thisMonth}
                        onClick={props.handler.sidebar.onDocumentDueFilterClick}
                        active={
                          documentsPanelState.activeDueStatusFilter ===
                          constant.documentDue.thisMonth
                        }
                      >
                        <Icon name="bell outline" />
                        {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_THISMONTH")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              </Menu.Menu>
            </Grid.Column>
            <Grid.Column>
              {documentsPanelState.activeDocumentTypeFilter === constant.documentType.invoice &&
                documentsPanelState.activeDocumentDirectionFilter ===
                  constant.documentDirection.incoming && (
                  <>
                    <Menu.Header className={styles.dueHeader}>
                      {Localize.get("DOCUMENTS.SIDEBAR.EARLY_PAYMENT_DUE")}
                    </Menu.Header>
                    <Menu.Menu>
                      <Menu.Item className={styles.dueItem}>
                        <Dropdown
                          text={convertDocumentDueStrings(
                            documentsPanelState.activeEarlyDueStatusFilter
                          )}
                        >
                          <Dropdown.Menu className={styles.earlypayment_dropdown}>
                            <Dropdown.Item
                              name={constant.documentDue.overdue}
                              onClick={props.handler.sidebar.onDocumentEarlyDueFilterClick}
                              active={
                                documentsPanelState.activeEarlyDueStatusFilter ===
                                constant.documentDue.overdue
                              }
                            >
                              <Icon name="bell outline" />
                              {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_OVERDUE")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              name={constant.documentDue.today}
                              onClick={props.handler.sidebar.onDocumentEarlyDueFilterClick}
                              active={
                                documentsPanelState.activeEarlyDueStatusFilter ===
                                constant.documentDue.today
                              }
                            >
                              <Icon name="bell outline" />
                              {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_TODAY")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              name={constant.documentDue.thisWeek}
                              onClick={props.handler.sidebar.onDocumentEarlyDueFilterClick}
                              active={
                                documentsPanelState.activeEarlyDueStatusFilter ===
                                constant.documentDue.thisWeek
                              }
                            >
                              <Icon name="bell outline" />
                              {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_THISWEEK")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              name={constant.documentDue.nextWeek}
                              onClick={props.handler.sidebar.onDocumentEarlyDueFilterClick}
                              active={
                                documentsPanelState.activeEarlyDueStatusFilter ===
                                constant.documentDue.nextWeek
                              }
                            >
                              <Icon name="bell outline" />
                              {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_NEXTWEEK")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              name={constant.documentDue.thisMonth}
                              onClick={props.handler.sidebar.onDocumentEarlyDueFilterClick}
                              active={
                                documentsPanelState.activeEarlyDueStatusFilter ===
                                constant.documentDue.thisMonth
                              }
                            >
                              <Icon name="bell outline" />
                              {Localize.get("DOCUMENTS.SIDEBAR.DOCUMENT_DUE_THISMONTH")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Menu.Item>
                    </Menu.Menu>{" "}
                  </>
                )}
            </Grid.Column>
          </Grid>
        </Menu.Item>

        {documentsPanelState.activeDocumentDirectionFilter ===
        constant.documentDirection.incoming ? (
          <>
            {" "}
            <OpenFilterModule
              filter={constant.sideBarVerificationConfig}
              onFilterClick={props.handler.sidebar.onVerificationStatusFilterClick}
              activeFilter={documentsPanelState.activeVerificationStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarApprovalConfig}
              onFilterClick={props.handler.sidebar.onPaymentStatusFilterClick}
              activeFilter={documentsPanelState.activePaymentStatusFilter}
            />{" "}
          </>
        ) : (
          <>
            <OpenFilterModule
              filter={constant.sideBarVerificationConfig}
              onFilterClick={props.handler.sidebar.onVerificationStatusFilterClick}
              activeFilter={documentsPanelState.activeVerificationStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarPayStatusConfig}
              onFilterClick={props.handler.sidebar.onPaymentRevenueStatusFilterClick}
              activeFilter={documentsPanelState.activeRevenuePaymentStatusFilter}
            />
            <OpenFilterModule
              filter={constant.sideBarStatus}
              onFilterClick={props.handler.sidebar.onProgressStatusFilterClick}
              activeFilter={documentsPanelState.activeProgressStatusFilter}
            />
          </>
        )}
        <Menu.Item>
          <Menu.Menu className={styles.active_menu_item}>
            <Menu.Item
              name={constant.archive.archived}
              onClick={props.handler.sidebar.onArchiveFilterClick}
              active={documentsPanelState.activeArchiveFilter === constant.archive.archived}
            >
              <Icon name="archive" /> {Localize.get("CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default SideBar;
