export const notificationType = {
  info: "info",
  warning: "warning",
  custom: "custom"
};

export const notificationEntityType = {
  account: "account",
  partner: "partner",
  company: "company",
  person: "person",
  employee: "employee",
  document: "document"
};

export const notificationOperation = {
  create: "create",
  delete: "delete",
  update: "update",
  verification: "verification",
  payment: "payment",
  archive: "archive",
  unarchive: "unarchive",
  createFailed: "createFailed",
  upload: "upload",
  added: "added"
};
