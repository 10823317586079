import React, { useState, useEffect } from "react";
import GlobalPanel from "../../Components/Panels/GlobalPanel";
import * as constants from "../Constants";
import AccountMiddlePanel from "./AccountMiddlePanel";
import AccountRightPanel from "./AccountRightPanel";
import { account_leftPanelConfig, myConfigForAccounts } from "../BookkeepingConfig";
import useAccountOverviewServices from "../../RestServicesNew/useAccountOverviewServices";

const AccountOverview = props => {
  const [currentModule, setCurrentModule] = useState(constants.accountOverviewModule.allAccounts);
  let delayTimer;

  let initialSearchObject = {};
  const [currentView, setCurrentView] = useState("details");
  const [searchObject, setSearchObject] = useState(initialSearchObject);

  const {
    bookingAccountList,
    bookingAccountLoading,
    bookingAccountCall,
    setBookingAccountList,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    filterObject,
    setFilterObject,
    paginationStatus,
    activePaginationPage,
    setActivePaginationPage
  } = useAccountOverviewServices(currentView);

  const handlePageChange = async activePage => {
    setActivePaginationPage(activePage);
  };

  //function used for filter in side bar
  const filterHandler = (fieldName, fieldValue, currentFilters) => {
    if (!currentFilters.hasOwnProperty(fieldName)) {
      currentFilters = {};
      setFilterObject({ ...currentFilters, [fieldName]: fieldValue });
    } else {
      delete currentFilters[fieldName];
      setFilterObject({ ...currentFilters });
    }
  };

  //function used for search
  const searchFilter = (e, data, currentFilters, reset = false) => {
    if (reset) {
      setFilterObject(constants.initialFilterAccountObject);
      setSearchObject(initialSearchObject);
    } else {
      setActivePaginationPage(1);
      clearTimeout(delayTimer);
      setSearchObject({ ...currentFilters, [data.name]: data.value });
    }
  };

  //function used for sort
  const sortHandler = (fieldName, fieldValue, currentFilters) => {
    if (currentFilters.sortBy === fieldName) {
      if (currentFilters.sortDirection === fieldValue) {
        setFilterObject({ ...currentFilters, sortBy: "", sortDirection: "" });
      } else {
        setFilterObject({
          ...currentFilters,
          sortBy: fieldName,
          sortDirection: fieldValue
        });
      }
    } else {
      setFilterObject({
        ...currentFilters,
        sortBy: fieldName,
        sortDirection: fieldValue
      });
    }
  };

  useEffect(() => {
    bookingAccountCall();
  }, [filterObject, activePaginationPage]);

  const leftPanelProps = {
    leftPanelConfig: account_leftPanelConfig,
    setCurrentModule: setCurrentModule,
    currentModule: currentModule,
    setCurrentView: setCurrentView,
    filterObject: filterObject,
    filterHandler: filterHandler,
    setSelectedItem: setSelectedItem,
    forbidenResponse: forbidenResponse,
    setListState: setBookingAccountList,
    setActivePaginationPage: setActivePaginationPage
  };

  const middlePanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    listState: bookingAccountList,
    setListState: setBookingAccountList,
    searchFilter: searchFilter,
    filterObject: filterObject,
    searchObject: searchObject,
    forbidenResponse: forbidenResponse,
    listDimmed:
      currentView === constants.panelView.edit || currentView === constants.panelView.create,
    activePaginationPage: activePaginationPage,
    handlePageChange: handlePageChange,
    selectedItem: selectedItem,
    setSelectedItem: setSelectedItem,
    paginationStatus: paginationStatus,
    callGetAll: bookingAccountCall,
    sortHandler: sortHandler
  };

  const rightPanelProps = {
    currentView: currentView,
    setCurrentView: setCurrentView,
    currentModule: currentModule,
    selectedItem: selectedItem,
    callGetAll: bookingAccountCall,
    activePaginationPage: activePaginationPage,
    setActivePaginationPage: setActivePaginationPage,
    lenghtOfList: bookingAccountList.length
  };

  return (
    <GlobalPanel
      currentView={currentView}
      setCurrentModule={setCurrentModule}
      customizedMiddlePanelComponent={AccountMiddlePanel}
      customizedRightPanelComponent={AccountRightPanel}
      leftPanelProps={leftPanelProps}
      middlePanelProps={middlePanelProps}
      rightPanelProps={rightPanelProps}
      isLoading={bookingAccountLoading}
      globalPanelConf={currentView === "edit" ? myConfigForAccounts : undefined}
    />
  );
};

export default AccountOverview;
