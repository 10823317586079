import { useContext } from "react";
import { AuthContext } from "../Auth/AuthContext";

const useFilterStates = currentModule => {
  const { filtersState, setFiltersState } = useContext(AuthContext);
  const { documents, partners } = filtersState;

  const setPathOnlyOneAtTime = myNewState => {
    setFiltersState({
      type: "set",
      payload: { ...filtersState, [currentModule]: myNewState }
    });
  };
  const setFilter = () => {};
  const resetDocumentsFilters = () => {
    setFiltersState({ type: "resetDocuments" });
  };
  const resetPartnersFilters = () => {
    setFiltersState({ type: "resetPartners" });
  };

  return {
    setPathOnlyOneAtTime,
    setFilter,
    resetDocumentsFilters,
    resetPartnersFilters,
    documentsPanelState: documents,
    partnersPanelState: partners
  };
};

export default useFilterStates;
