export const sideBarConstants = {
  settings: "settings"
};
//datePicker locales

export const WEEKDAYS_SHORT = {
  de: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
};

export const WEEKDAYS_LONG = {
  de: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};

export const MONTHS = {
  de: [
    " Jan.",
    "Feb.",
    "Mrz.",
    "Apr.",
    "Mai.",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Okt.",
    "Nov.",
    "Dez."
  ]
};

export const LABELS = {
  de: { nextMonth: "nächsten Monat", previousMonth: "vorheriger Monat" }
};

export const FIRST_DAY_OF_WEEK = {
  de: 1
};

// ---- datePicker Locales END

export const availableItemFieldsForListProperties = [
  {
    name: "validationSubject",
    hederName: "SETTINGS.HEADERS.DOCUMENT_NUMBER",
    type: "text",
    width: 6,
    visible: true
  },
  {
    name: "validationMessage",
    hederName: "SETTINGS.HEADERS.ERROR_MESSAGE",
    type: "message",
    width: 10,
    visible: true
  }
];

export const notFound = "404 NOT_FOUND";
