import React from "react";
import { Dropdown, Label, FormField } from "semantic-ui-react";
import Localize from "react-intl-universal";
import DatepickerWithDropdown from "./DatepickerWithDropdown";
import styles from "./style/GlobalDatedown.module.css";

const GlobalDatedown = props => {
  const {
    isRequired = false,
    dropdownRef,
    labelName,
    dropdownName,
    handleOnClick = function() {},
    handleOnBlur = function() {},
    dropdownOpen = false,
    hasError,
    dropdownText,
    selectedDays,
    handleDateClick,
    disabledDays,
    disableDropdown = false,
    icon,
    disable,
    showLabel = true,
    dropdownStyleError,
    direction
    // reverseIcon
  } = props;

  return (
    <>
      <div className="content" ref={dropdownRef}>
        <FormField required={isRequired}>
          <label className={`${styles.label_style} ${isRequired && styles.label_required}`}>
            {labelName && Localize.get(labelName)}
          </label>
        </FormField>

        <div className={`ui calendar ${styles.dropdown_in_form}`}>
          <Dropdown
            icon={icon ? (!disable ? icon : null) : "dropdown"}
            className={disable ? styles.disable : `${styles.text_align} ${"icon"}`}
            fluid
            floating
            labeled
            button
            disabled={disableDropdown}
            upward={false}
            direction={direction ? direction : "left"}
            pointing="top"
            open={dropdownOpen}
            text={dropdownText}
            name={dropdownName}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            style={{
              border: hasError ? (dropdownStyleError ? dropdownStyleError : "3px red solid") : ""
            }}
          >
            <Dropdown.Menu>
              <DatepickerWithDropdown
                selectedDays={selectedDays}
                handleDateClick={handleDateClick}
                disabledDays={disabledDays}
              />
            </Dropdown.Menu>
          </Dropdown>
          {hasError && showLabel && <Label basic color="red" pointing="above" content={hasError} />}
        </div>
      </div>
    </>
  );
};

export default GlobalDatedown;
