import React from "react";
import { Button } from "semantic-ui-react";
import propTypes from "prop-types";
import Localize from "react-intl-universal";
import styles from "./style/GlobalCancelBtn.module.css";

const GlobalCancelBtn = props => {
  const {
    onCancel = function(arg) {},
    floatedDirection,
    customStyle,
    negativeText = "GLOBAL.FORM.CANCEL"
  } = props;

  return (
    <>
      <Button
        style={customStyle ? customStyle : null}
        className={styles.cancel_btn}
        onClick={onCancel}
        floated={floatedDirection ? floatedDirection : null}
      >
        {Localize.get(negativeText)}
      </Button>
    </>
  );
};

GlobalCancelBtn.prototype = {
  onCancel: propTypes.func,
  floatedDirection: propTypes.string,
  customStyle: propTypes.any,
  negativeText: propTypes.string
};
export default GlobalCancelBtn;
