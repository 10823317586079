import React from "react";
import { Dimmer } from "semantic-ui-react";

const GlobalMiddlePanel = props => {
  const {
    currentView,
    setCurrentView,
    listState,
    forbidenResponse,
    selectedItem,
    setSelectedItem,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    listDimmed,
    searchFilter,
    filterObject,
    searchObject,
    currentModule,
    CustomComponent,
    callGetAll,
    setIsLoading,
    privileges,
    setListState,
    sortHandler
  } = props;

  const BuildedCustomComponent =
    CustomComponent &&
    React.createElement(
      CustomComponent,
      {
        currentView,
        setCurrentView,
        listState,
        forbidenResponse,
        selectedItem,
        setSelectedItem,
        paginationStatus,
        handlePageChange,
        activePaginationPage,
        searchFilter,
        currentModule,
        filterObject,
        searchObject,
        callGetAll,
        setIsLoading,
        privileges,
        setListState,
        sortHandler
      },
      null
    );

  return (
    <>
      <div>
        {currentView !== "display" ? (
          <>
            <Dimmer active={listDimmed} inverted></Dimmer>
            {BuildedCustomComponent}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default GlobalMiddlePanel;
