import React from "react";
import useDeleteItem from "../../CustomHooks/useDeleteItem";

const GlobalRightPanel = props => {
  const {
    currentView,
    setCurrentView,
    currentModule,
    CustomComponent,
    selectedItem,
    callGetAll,
    setIsLoading,
    privileges,
    activePaginationPage,
    setActivePaginationPage,
    lenghtOfList
  } = props;

  const [deleteItem, myDialog] = useDeleteItem(
    callGetAll,
    setIsLoading,
    currentModule,
    activePaginationPage,
    setActivePaginationPage,
    lenghtOfList
  );

  const BuildedCustomComponent =
    CustomComponent &&
    React.createElement(
      CustomComponent,
      {
        currentView,
        setCurrentView,
        currentModule,
        selectedItem,
        deleteItem,
        privileges,
        callGetAll,
        setActivePaginationPage
      },
      null
    );

  return (
    <>
      {myDialog}
      <div>{BuildedCustomComponent}</div>
    </>
  );
};

export default GlobalRightPanel;
