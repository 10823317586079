import { useState, useEffect } from "react";
import * as constants from "./Constants.js";

function useViewportSize() {
  const getDevice = width => {
    if (width < 768) return constants.deviceSize.mobile;
    else if (width < 992) return constants.deviceSize.tablet;
    else return constants.deviceSize.computer;
  };

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    device: getDevice(window.innerWidth)
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        device: getDevice(window.innerWidth)
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useViewportSize;
