import React, { useState } from "react";
import { Accordion, List, Icon, Checkbox, Modal } from "semantic-ui-react";
import styles from "./style/ProjectDetailsContact.module.css";
import { getLabel, getInitialFields } from "../Util";
import Localize from "react-intl-universal";
import GlobalCancelBtn from "../../../Components/GlobalCancelBtn";
import GlobalOkButton from "../../../Components/GlobalOkBtn";

const ProjectDetailsContact = props => {
  const { partner, partnersRole, handleOnDeletePersonClick, newDetailsFields } = props;
  const [activeAccordion, setActiveAccordion] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let selObj = { ...partner.id };
  delete selObj["partner"];
  delete selObj["resourceRole"];
  const [modalSelection, setModalSelection] = useState({});

  const arrayOfSelected = () => {
    let array = [];
    for (const key of Object.keys(selObj)) {
      if (partner.id[key] === true) {
        array.push(key);
      }
    }
    return array;
  };

  const handleAccordionClick = (e, titleProps) => {
    setActiveAccordion(!activeAccordion);
  };

  const OnDeletePersonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    handleOnDeletePersonClick(event.target.id, partnersRole.id);
  };

  const OnSettingPersonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setModalSelection(selObj);
    setShowModal(true);
  };

  const checkboxHandler = (e, data) => {
    setModalSelection({ ...modalSelection, [data.name]: data.checked });
  };

  const modalHandler = event => {
    setShowModal(false);
    let newObj = { ...modalSelection };
    newObj.partner = partner.id.partner;
    newObj.resourceRole = partner.id.resourceRole;
    newDetailsFields(newObj, partner.id.partner.id, partnersRole.id);
  };

  const cancelHandler = () => {
    setModalSelection(selObj);
    setShowModal(false);
  };

  const modalContent = (
    <>
      <List>
        {getInitialFields(partner.id.partner.dtype).map((field, index) => (
          <List.Item key={index}>
            {" "}
            <Checkbox
              label={getLabel(field)}
              name={field}
              checked={modalSelection[field]}
              onChange={checkboxHandler}
            />
          </List.Item>
        ))}
      </List>
    </>
  );

  const getPartnerInfo = field => {
    let list;
    let fieldValue = partner.id.partner[field];
    switch (typeof fieldValue) {
      case "object":
        if (fieldValue !== null && fieldValue !== undefined) {
          if (!(fieldValue instanceof Array)) {
            list = Object.keys(fieldValue).map(
              (key, i) =>
                key !== "id" && (
                  <List.Item key={i}>
                    {key} : {fieldValue[key]}
                  </List.Item>
                )
            );
          }
        }
        break;
      default:
        list = partner.id.partner[field];
        break;
    }
    return list;
  };

  const fieldsList = arrayOfSelected().map((field, index) => (
    <List.Item key={index}>
      <List.Content>
        <strong>{getLabel(field)}</strong>
      </List.Content>
      <List.Content>{getPartnerInfo(field)}</List.Content>
    </List.Item>
  ));

  return (
    partner.id.partner !== undefined && (
      <>
        <Modal open={showModal} size="small" dimmer="blurring" onClose={cancelHandler}>
          <Modal.Header>{Localize.get("PROJECTS.MANAGEMENT.CHOOSE_INFO")}</Modal.Header>
          <Modal.Content>{modalContent}</Modal.Content>
          <Modal.Actions>
            <GlobalOkButton onOkClicked={modalHandler} />
            <GlobalCancelBtn onCancel={cancelHandler} />
          </Modal.Actions>
        </Modal>
        <Accordion styled className={styles.projectDetailsContacts}>
          <Accordion.Title
            active={activeAccordion}
            onClick={handleAccordionClick}
            className={styles.title}
          >
            <Icon name="dropdown" />

            <span>
              {partner.id.partner.name
                ? partner.id.partner.name
                : partner.id.partner.first + " " + partner.id.partner.last}
            </span>

            {props.activeMode !== "details" && (
              <>
                <Icon
                  name="minus circle"
                  onClick={OnDeletePersonClick}
                  className={styles.minus_icon}
                  id={partner.id.partner.id}
                />
                <Icon
                  name="setting"
                  onClick={OnSettingPersonClick}
                  className={styles.minus_icon}
                  id={partner.id.partner.id}
                />
              </>
            )}
          </Accordion.Title>
          <Accordion.Content active={activeAccordion} className={styles.acordian_content}>
            <List divided verticalAlign="middle">
              {fieldsList}
            </List>
          </Accordion.Content>
        </Accordion>{" "}
      </>
    )
  );
};

export default ProjectDetailsContact;
