import React from "react";
import Localize from "react-intl-universal";
import { Menu, Sidebar, Button, Container, Icon } from "semantic-ui-react";
import styles from "./style/ProjectStatusesSideBar.module.css";

const ProjectStatusesSideBar = props => {
  const { externalStatusPrivileges } = props;
  const { sideBarVisibility } = props;
  const { setSideBarVisibility } = props;

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Container>
          <Button
            disabled={!externalStatusPrivileges.create}
            primary
            className={`bms_sidebar_button ${styles.bms_button}`}
            onClick={props.handle.createNewStatusButtonClick}
          >
            {Localize.get("PROJECTS.STATUSES.NEW_STATUS")}
            <Icon name="plus" />
          </Button>
        </Container>
      </Sidebar>
    </>
  );
};

export default ProjectStatusesSideBar;
