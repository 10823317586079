import React, { useState, useEffect } from "react";
import ConfirmArchiveDialog from "../Dialogs/ConfirmArchiveDialog";
import * as RestService from "../RestService";
import showNotification from "../Notifications/NotificationFunction";
import GlobalItem from "../Components/Lists/GlobalItem";
import {
  compareListWithListOfIds,
  GetUpdateObject,
  togleOne
} from "../Components/Utils/GlobalListsUtil";
import styles from "./style/CustomHooks.module.css";
import propTypes from "prop-types";

const useCannotBeDeleted = (
  callGetAll = function() {},
  setIsLoading = function(arg) {},
  currentModule,
  activePaginationPage,
  handlePageChange = function(arg) {},
  listState,
  availableItemFieldsForList,
  cannotBeDeleted = [],
  setCannotBeDeleted = function(arg) {}
) => {
  const [localList, setLocalList] = useState([]);

  // cannotBeDeleted is list of ids, so here compare return list of matching items
  useEffect(() => {
    if (cannotBeDeleted.length > 0) {
      let myArr = JSON.parse(cannotBeDeleted);
      let myList = compareListWithListOfIds(myArr, listState);
      setLocalList(myList);
    } else {
      setLocalList([]);
    }
  }, [cannotBeDeleted]);

  const callbackUpdateSuccess = response => {
    showNotificationMessage({
      type: "info",
      operation: "update"
    });
    if (activePaginationPage > 1 && cannotBeDeleted.length === listState.length) {
      handlePageChange({ activePage: activePaginationPage - 1 });
    } else {
      callGetAll();
    }
  };

  const callbackUpdateError = error => {
    showNotificationMessage({
      type: "warning",
      operation: "update"
    });
  };

  const callbackUpdateFinally = response => {
    setLocalList([]);
    setCannotBeDeleted([]);
  };

  const archiveServiceCall = async () => {
    let updatedObject = localList
      .filter(item => item.checked)
      .map(item => {
        item.archived = !item.archived;
        item.editedFields = ["archived"];
        let getUpdateItem = GetUpdateObject(item);
        return { id: item.id, operation: getUpdateItem };
      });
    const projectTypeService = new RestService.GlobalRest.Update.Service(`/${currentModule}`);

    projectTypeService.setPayload(updatedObject);
    projectTypeService.setCallbackSuccess(callbackUpdateSuccess);
    projectTypeService.setCallbackError(callbackUpdateError);
    projectTypeService.setCallbackFinally(callbackUpdateFinally);
    setIsLoading(true);
    await projectTypeService.call();
  };

  const itemHandler = item => {
    togleOne(item.id, setLocalList);
  };

  // list of items for list in confirmation modal
  const giveMeItems = items => {
    return items.map(item => {
      return (
        <GlobalItem
          key={item.id}
          item={item}
          itemHandler={itemHandler}
          availableItemFieldsForList={availableItemFieldsForList}
          customItemCss={styles.item_border}
        />
      );
    });
  };

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: currentModule,
      ...param
    });
  };

  // return confirmation modal that is open only if cannotBeDeleted is > than 0
  return [
    <ConfirmArchiveDialog
      numberOfCheckedItems={cannotBeDeleted.length}
      confirmClicked={archiveServiceCall}
      declineClicked={() => setCannotBeDeleted([])}
      activeArchiveFilter={""}
      showModal={cannotBeDeleted.length > 0}
      cannotBeDeleted={giveMeItems(localList)}
      archiveBtnEnable={false}
    />
  ];
};

useCannotBeDeleted.propTypes = {
  callGetAll: propTypes.func,
  setIsLoading: propTypes.func,
  currentModule: propTypes.string.isRequired,
  activePaginationPage: propTypes.number.isRequired,
  handlePageChange: propTypes.func,
  listState: propTypes.array.isRequired,
  availableItemFieldsForList: propTypes.array.isRequired,
  cannotBeDeleted: propTypes.arrayOf(propTypes.string),
  setCannotBeDeleted: propTypes.func
};

export default useCannotBeDeleted;
