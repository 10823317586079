export const invoiceInc = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "deliveryDate",
          required: true,
          labelName: "DOCUMENTS.FORM.DELIVERY_DATE"
        },
        {
          fieldName: "paymentDue",
          required: true,
          labelName: "DOCUMENTS.FORM.DUE_DATE"
        }
      ],
      [
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        },
        { fieldName: "partner" },
        { fieldName: "addPartner" }
      ]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      overpayment: true,
      amount: true,
      paymentDue: true,
      earlyPayAmount: true,
      earlyPayDate: true,
      savings: true
    }
  }
];

export const invoiceOut = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "deliveryDate",
          required: false,
          labelName: "DOCUMENTS.FORM.DELIVERY_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [{ fieldName: "partner", required: true }, { fieldName: "addPartner" }]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      overpayment: true,
      amount: true,
      paymentDue: true,
      earlyPayAmount: true,
      earlyPayDate: true,
      savings: true
    }
  }
];

export const defaultDocument = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        { fieldName: "partner" },
        { fieldName: "addPartner" }
      ]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  }
];

export const correctionInc = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "deliveryDate",
          required: true,
          labelName: "DOCUMENTS.FORM.DELIVERY_DATE"
        },
        {
          fieldName: "paymentDue",
          required: true,
          labelName: "DOCUMENTS.FORM.DUE_DATE"
        }
      ],
      [
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [
        { fieldName: "partner", required: true },
        { fieldName: "addPartner&searchInvoice" },
        { fieldName: "relatedInvoice", required: true }
      ]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      overpayment: true,
      amount: true,
      paymentDue: true,
      earlyPayAmount: true,
      earlyPayDate: true,
      savings: true
    }
  }
];

export const correctionRev = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "deliveryDate",
          required: true,
          labelName: "DOCUMENTS.FORM.DELIVERY_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [
        { fieldName: "partner", required: true },
        { fieldName: "addPartner&searchInvoice" },
        { fieldName: "relatedInvoice", required: true }
      ]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      overpayment: true,
      amount: true,
      paymentDue: true,
      earlyPayAmount: true,
      earlyPayDate: true,
      savings: true
    }
  }
];

export const delivery = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [{ fieldName: "partner" }, { fieldName: "addPartner" }]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      unit: true,
      quantity: true,
      plusIcon: true
    }
  }
];

export const inquiry = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [{ fieldName: "partner" }, { fieldName: "addPartner" }]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  }
];

export const offer = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [{ fieldName: "partner" }, { fieldName: "addPartner" }]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      amount: true
    }
  }
];

export const receipt = [
  {
    sectionName: "form",
    numberOfColumns: 3,
    configList: [
      [
        {
          fieldName: "documentNumber",
          required: true,
          placeholder: "DOCUMENTS.FORM.DOCUMENT_NUMBER"
        },
        {
          fieldName: "direction",
          required: true,
          labelName: "TRANSACTIONS.DIRECTION.HEADER_DIRECTION"
        },
        {
          fieldName: "documentType",
          required: true,
          labelName: "DOCUMENTS.FORM.DOCUMENT_TYPE"
        }
      ],
      [
        {
          fieldName: "documentDate",
          required: true,
          labelName: "DOCUMENTS.FORM.CREATION_DATE"
        },
        {
          fieldName: "currency",
          required: true,
          labelName: "DOCUMENTS.FORM.CURRENCY"
        }
      ],
      [{ fieldName: "partner" }, { fieldName: "addPartner" }]
    ]
  },
  {
    sectionName: "description",
    labelName: "DOCUMENTS.FORM.DESCRIPTION",
    placeholder: "DOCUMENTS.FORM.DESCRIPTION_PLACEHOLDER"
  },
  {
    sectionName: "orderItems",
    configList: {
      itemLineId: true,
      sellerItemId: true,
      name: true,
      vat: true,
      unit: true,
      quantity: true,
      netPrice: true,
      amount: true,
      discount: true,
      plusIcon: true
    }
  },
  {
    sectionName: "taxSubtotals",
    configList: [
      { fieldName: "tax", required: true },
      { fieldName: "netAmount", required: true },
      { fieldName: "discount" },
      { fieldName: "surcharge" },
      { fieldName: "baseAmount" },
      { fieldName: "calculatedTax" },
      { fieldName: "grossAmount" },
      { fieldName: "plusIcon" }
    ]
  },
  {
    sectionName: "total",
    configList: {
      totalNetAmount: true,
      totalDiscount: true,
      totalSurcharge: true,
      totalBaseAmount: true,
      totalTax: true,
      totalGrossAmount: true,
      overpayment: true,
      amount: true
    }
  }
];
