import React, { useState } from "react";
import Localize from "react-intl-universal";
import { Segment, Icon, Message } from "semantic-ui-react";
import ContactFormContactPerson from "./ContactFormContactPerson";
import styles from "./style/ContactFormContactPersons.module.css";

const ContactFormContactPersons = props => {
  const {
    parametersContacts,
    contacts = [],
    validateContactsListField,
    handleOnChangeList,
    deleteItemFromList,
    addItemToList,
    checkEditedFields,
    addContactInValidator,
    removeContactFromValidator
  } = props;

  const [activeAccordionArray, setActiveAccordionArray] = useState(contacts.map(() => false));

  const handler = {
    accordionHandler: index => {
      let newElement = !activeAccordionArray[index];
      let newArray = [...activeAccordionArray];
      newArray.splice(index, 1, newElement);
      setActiveAccordionArray(newArray);
    }
  };

  const handleOnPlusClick = () => {
    let newContact = {
      contactType: null,
      first: "",
      last: "",
      jobTitle: "",
      phone: "",
      mobile: "",
      email: ""
    };
    addContactInValidator(newContact);
    addItemToList("contacts", newContact);
    checkEditedFields("contacts");
  };

  const handleDelete = index => {
    removeContactFromValidator(index);
    deleteItemFromList("contacts", index);
    checkEditedFields("contacts");
  };

  const handleOnChange = (index, contact) => {
    handleOnChangeList("contacts", contact, index);
    checkEditedFields("contacts");
  };

  const contactForms = () =>
    contacts.map((contact, index) => (
      <ContactFormContactPerson
        key={index}
        validator={parametersContacts[index]}
        index={index}
        contact={contact}
        onChange={handleOnChange}
        handleDelete={handleDelete}
        handler={handler}
        activeAccordion={activeAccordionArray[index]}
        title={Localize.get("CONTACTS.NEWCONTACT.FORM.CONTACT_PERSONS_TITLE")}
        validateContactsListField={validateContactsListField}
      />
    ));

  return (
    <Segment.Group>
      <Segment className={styles.title}>
        <div>
          <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.CONTACT_PERSONS")}</strong>
        </div>
        <div className={styles.formAddButton}>
          <Icon name="plus" onClick={handleOnPlusClick} />
        </div>
      </Segment>
      <Segment className={styles.form_lines}>
        {contacts && contacts.length > 0 ? (
          contactForms()
        ) : (
          <Message
            className={styles.message_block}
            attached="bottom"
            header={Localize.get("CONTACTS.NEWCONTACT.FORM.NO_CONTACTS")}
            content={Localize.get("CONTACTS.NEWCONTACT.FORM.CLICK_PLUS_CONTACT")}
          />
        )}
      </Segment>
    </Segment.Group>
  );
};

export default ContactFormContactPersons;
