import * as constant from "../Constants";
import { BaseService, BaseBuilder, BaseQuery } from "../Base";

class CustomBuilder extends BaseBuilder {
  uniqueCheck(value) {
    this.parameter.uniqueCheck = value;

    return this;
  }

  build() {
    delete this.parameter.page;
    delete this.parameter.size;
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor(id) {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.patch,
      resource: constant.BASE.resource.documents,
      pathParameter: id
    };
    this.query = new CustomQuery.Builder();
  }
}
