import React, { useState } from "react";
import { Icon, Accordion } from "semantic-ui-react";
import styles from "./style/GlobalAccordition.module.css";
import propTypes from "prop-types";
import Localize from "react-intl-universal";

const GlobalAccordition = props => {
  const { customContent, customTitle = "" } = props;
  const [activeAccordion, setActiveAccordion] = useState(false);
  return (
    <>
      <Accordion fluid={true} padded="true" className={styles.accordition_container}>
        <Accordion.Title
          active={activeAccordion}
          onClick={() => setActiveAccordion(!activeAccordion)}
        >
          <Icon className={styles.dropdown_icon} name="dropdown" />
          {Localize.get(customTitle)}
        </Accordion.Title>
        <Accordion.Content className={styles.ac_content} active={activeAccordion}>
          {customContent}
        </Accordion.Content>
      </Accordion>
    </>
  );
};

GlobalAccordition.propTypes = {
  customContent: propTypes.object.isRequired,
  customTitle: propTypes.string
};

export default GlobalAccordition;
