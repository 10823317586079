import React from "react";
import Localize from "react-intl-universal";
import { Menu, Grid, Checkbox } from "semantic-ui-react";
import * as constant from "../Constants.js";
import styles from "./style/ContactListItem.module.css";

const ContactListItem = ({
  setSelectedItem = function(arg) {},
  itemHandler = function(arg) {},
  item,
  selectedItem,
  availableItemFieldsForList,
  checkedItemsIds
}) => {
  const { address } = item;
  const contactLabel = name => {
    switch (name) {
      case constant.contactType.person:
        return (
          <span className={`${styles.label_row} ${styles.orange}`}>
            {Localize.get("CONTACTS.LIST.ITEM_LABEL.PERSON")}
          </span>
        );
      case constant.contactType.company:
        return (
          <span className={`${styles.label_row} ${styles.blue}`}>
            {Localize.get("CONTACTS.LIST.ITEM_LABEL.COMPANY")}
          </span>
        );
      case constant.contactType.employee:
        return (
          <span className={`${styles.label_row} ${styles.green}`}>
            {Localize.get("CONTACTS.LIST.ITEM_LABEL.EMPLOYEE")}
          </span>
        );
      case constant.contactRole.customer:
        return (
          <span className={`${styles.label_row} ${styles.purple}`}>
            {Localize.get("CONTACTS.LIST.ITEM_LABEL.CUSTOMER")}
          </span>
        );
      case constant.contactRole.supplier:
        return (
          <span className={`${styles.label_row} ${styles.cyan}`}>
            {Localize.get("CONTACTS.LIST.ITEM_LABEL.SUPPLIER")}
          </span>
        );
      default:
        return "ERROR";
    }
  };

  // HANDLERS
  const handleItemClick = (event, data) => {
    setSelectedItem(data.item_id, item);
  };

  const toggleItem = (event, data) => {
    event.stopPropagation();
    itemHandler(data, item);
  };

  return (
    <Menu.Item
      item_id={item.id}
      onClick={handleItemClick}
      active={selectedItem === item.id ? true : null}
      className={styles.bms_partners_item}
    >
      <Grid columns="6" stackable container verticalAlign="middle">
        <Grid.Row className={styles.grid_row_padding}>
          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[0].visible) ? (
            <Grid.Column width={1}>
              <Checkbox
                item_id={item.id}
                checked={checkedItemsIds && checkedItemsIds.some(el => el === item.id)}
                onClick={toggleItem}
              />
            </Grid.Column>
          ) : null}

          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[1].visible) ? (
            <Grid.Column width={2} className={styles.labels_container}>
              <div>
                <p className={styles.customer_number}>{item.customerNumber}</p>
                <p className={styles.supplier_number}></p>
                {item.dtype === "employee" && (
                  <p className={styles.employee_number}>{item.employeeNumber}</p>
                )}
              </div>
            </Grid.Column>
          ) : null}

          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[2].visible) ? (
            <Grid.Column width={2} className={styles.labels_container}>
              {item.isCollective ? contactLabel(constant.contactRole.collective) : null}

              {item.isCustomer ? contactLabel(constant.contactRole.customer) : null}

              {item.isSupplier ? contactLabel(constant.contactRole.supplier) : null}
            </Grid.Column>
          ) : null}

          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[3].visible) ? (
            <Grid.Column width={2} className={styles.labels_container}>
              {contactLabel(item.dtype)}
            </Grid.Column>
          ) : null}

          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[4].visible) ? (
            <Grid.Column className={styles.wrap_column} width={4}>
              <div className="content">
                {item.dtype === constant.contactType.company ? <div>{item.name}</div> : <div />}
                {item.dtype === constant.contactType.employee ? (
                  <div>{item.firstName + " " + item.lastName}</div>
                ) : (
                  <div />
                )}
                {item.dtype === constant.contactType.person ? (
                  <div>{item.firstName + " " + item.lastName}</div>
                ) : (
                  <div />
                )}
              </div>
            </Grid.Column>
          ) : null}

          {!availableItemFieldsForList ||
          (availableItemFieldsForList && availableItemFieldsForList[5].visible) ? (
            <Grid.Column className={styles.wrap_column} width={5}>
              {address && address.street}
              <br />
              {address && address.zip && `${address.zip} , `}
              {address && address.city}
            </Grid.Column>
          ) : null}
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );
};

export default ContactListItem;
