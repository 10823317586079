import React, { useState, useRef } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import styles from "./style/DatepickerWithDropdown.module.css";

import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG, LABELS, FIRST_DAY_OF_WEEK } from "../Util";

const DatepickerWithDropdown = props => {
  const {
    selectedDays,
    handleDateClick,
    disabledDays,
    setExportDateRange,
    setDateRangeErr = function(arg) {},
    numberOfMonths
  } = props;

  const [dateRange, setDateRange] = useState({
    from: DateUtils.addMonths(new Date(), -1),
    to: new Date()
  });

  const selectedRangeDays = dateRange.from;
  const pickerEl = useRef(null);

  const getYear = () => {
    let year = "";
    if (selectedDays && selectedDays !== "") {
      year = new Date(selectedDays).getFullYear();
    } else if (numberOfMonths && selectedRangeDays) {
      year = new Date(selectedRangeDays).getFullYear();
    } else {
      year = new Date().getFullYear();
    }
    return year;
  };

  const getMonth = () => {
    let month = "";
    if (selectedDays && selectedDays !== "") {
      month = new Date(selectedDays).getMonth();
    } else if (numberOfMonths && selectedRangeDays) {
      month = new Date(selectedRangeDays).getMonth();
    } else {
      month = new Date().getMonth();
    }

    return month;
  };

  const langauge = navigator.language.toLowerCase().includes("de") ? "de" : "en";

  const modifiers = { start: dateRange.from, end: dateRange.to };

  const handleDateRangeClick = day => {
    const newDateRange = DateUtils.addDayToRange(day, dateRange);
    if (newDateRange.from !== null) {
      setDateRange(newDateRange);
      setExportDateRange(newDateRange);

      setDateRangeErr(false);
    } else {
      if (dateRange.from === null && dateRange.to === null) {
        setDateRangeErr(true);
      }
    }
  };
  const handler = {
    onDateChange: day => {}
  };
  const handleDateSelect = day => {
    const newDateRange = DateUtils.addDayToRange(day, dateRange);
    handler.onDateChange(newDateRange);
  };

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 10, 0);
  const toMonth = new Date(currentYear + 10, 11);

  const [selectedDate, setSelectedDate] = useState(new Date(getYear(), getMonth()));

  const handleYearMonthChange = month => {
    setSelectedDate(month);
  };

  const YearMonthForm = ({ date, localeUtils, onChange }) => {
    const months = langauge === "de" ? MONTHS[langauge] : localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    const monthsArray = months.map((month, i) => month);

    const handleChange = e => {
      const year = e.target.value;
      setSelectedDate(new Date(year, pickerEl.current.state.currentMonth.getMonth()));
    };

    return (
      <>
        <div className="DayPicker-Caption">
          <select
            name="year"
            onChange={handleChange}
            value={
              !numberOfMonths
                ? pickerEl && pickerEl.current
                  ? pickerEl.current.state.currentMonth.getFullYear()
                  : selectedDate.getFullYear()
                : selectedDate.getFullYear()
            }
            className={styles.pickerMonth}
          >
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <div className={styles.month_header}>
            {pickerEl && pickerEl.current
              ? monthsArray[pickerEl.current.state.currentMonth.getMonth()]
              : null}
          </div>
          <div className={styles.month_header}>
            {numberOfMonths
              ? pickerEl && pickerEl.current
                ? monthsArray[
                    pickerEl.current.state.currentMonth.getMonth() === 11
                      ? 0
                      : pickerEl.current.state.currentMonth.getMonth() + 1
                  ]
                : null
              : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {numberOfMonths ? (
        <DayPicker
          id="myCal"
          ref={pickerEl}
          locale={langauge}
          months={MONTHS[langauge]}
          weekdaysLong={WEEKDAYS_LONG[langauge]}
          weekdaysShort={WEEKDAYS_SHORT[langauge]}
          firstDayOfWeek={FIRST_DAY_OF_WEEK[langauge]}
          labels={LABELS[langauge]}
          className={`${styles.selectable} ${styles.bms_day_picker}`}
          numberOfMonths={numberOfMonths}
          modifiers={modifiers}
          month={selectedDate}
          selectedDays={[dateRange.from, { from: dateRange.from, to: dateRange.to }]}
          onDayClick={handleDateRangeClick}
          onDayMouseDown={handleDateSelect}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
          )}
        />
      ) : (
        <DayPicker
          ref={pickerEl}
          locale={langauge}
          className={styles.bms_day_picker}
          months={MONTHS["de"]}
          weekdaysLong={WEEKDAYS_LONG["de"]}
          weekdaysShort={WEEKDAYS_SHORT[langauge]}
          firstDayOfWeek={FIRST_DAY_OF_WEEK[langauge]}
          labels={LABELS[langauge]}
          month={selectedDate}
          selectedDays={new Date(selectedDays)}
          onDayClick={handleDateClick}
          disabledDays={disabledDays ? disabledDays : null}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm date={date} localeUtils={localeUtils} onChange={handleYearMonthChange} />
          )}
        />
      )}
    </>
  );
};

export default DatepickerWithDropdown;
