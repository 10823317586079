import React from "react";
import Localize from "react-intl-universal";
import { Form, Checkbox, Button, Icon, Input, Popup, Dropdown } from "semantic-ui-react";
import styles from "./style/ToolBar.module.css";

const ToolBar = props => {
  const {
    privileges,
    resetSearches,
    resetNameSearch,
    searchName,
    enteredValue,
    onToggleAll,
    onArchiveClick,
    onDeleteClick,
    emptyList,
    archiveOrUnarchive,
    deleteEnable,
    customStyle,
    popupContentText,
    disableSearch,
    actionButtonArr = [],
    checkedItemsIds = [],
    checkedAll,
    activePaginationPage
  } = props;
  return (
    <div className={styles.toolbar}>
      <Form className={`${styles.toolBarForm} ${customStyle}`}>
        <Checkbox
          label={Localize.get("CONTACTS.LIST.TOOLBAR.ALL")}
          className={styles.checkboxAll}
          checked={
            checkedAll &&
            (checkedAll[activePaginationPage] === undefined
              ? false
              : checkedAll[activePaginationPage])
          }
          onChange={onToggleAll}
          disabled={emptyList}
        />
        <Button
          basic
          className={styles.toolbar_button}
          disabled={!privileges.update || emptyList}
          onClick={onArchiveClick}
        >
          {archiveOrUnarchive !== "archived"
            ? Localize.get("CONTACTS.LIST.TOOLBAR.ARCHIVE")
            : Localize.get("CONTACTS.LIST.TOOLBAR.UNARCHIVE")}
        </Button>
        <Button
          basic
          className={styles.toolbar_button}
          disabled={!privileges.delete || emptyList || !deleteEnable}
          onClick={onDeleteClick}
        >
          {Localize.get("CONTACTS.LIST.TOOLBAR.DELETE")}
        </Button>
        <Button basic className={styles.toolbar_button} onClick={resetSearches}>
          {Localize.get("CONTACTS.LIST.TOOLBAR.RESET")}
        </Button>
        <Popup
          content={
            popupContentText
              ? popupContentText
              : Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PLACEHOLDER")
          }
          on={["hover", "click"]}
          trigger={
            <Input
              disabled={disableSearch ? disableSearch : false}
              icon={
                enteredValue ? (
                  <Icon name="close" onClick={resetNameSearch} circular link size="small" />
                ) : (
                  <Icon name="search" />
                )
              }
              className={styles.header_search}
              name="search"
              onChange={searchName}
              autoComplete={"off"}
              value={enteredValue ? enteredValue : ""}
              type="text"
              placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
            />
          }
        />
      </Form>
      {actionButtonArr.length > 0 && (
        <Dropdown
          text={Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.ACTIONS")}
          button
          className={styles.headerActions}
        >
          <Dropdown.Menu className={styles.dropdowmMenu}>
            {actionButtonArr.map((item, index) => (
              <Dropdown.Item
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.handler}
                disabled={item.disabled != null ? item.disabled : checkedItemsIds.length === 0}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default ToolBar;
