export const external_settings = [
  {
    name: "integration.name",
    hederName: "PROPERTIES.FIELDS.integration_name",
    type: "text",
    isRequired: true
  },
  {
    name: "host.server",
    hederName: "PROPERTIES.FIELDS.host_server",
    type: "text",
    isRequired: true
  },
  {
    name: "host.port",
    hederName: "PROPERTIES.FIELDS.host_port",
    type: "text"
  },
  {
    name: "username",
    hederName: "PROPERTIES.FIELDS.username",
    type: "text",
    isRequired: true
  },
  {
    name: "password",
    hederName: "PROPERTIES.FIELDS.password",
    type: "password",
    isRequired: true,
    inputDisabled: false
  },
  {
    name: "integration.enabled",
    hederName: "PROPERTIES.FIELDS.integration_enabled",
    type: "boolean",
    isRequired: true
  }
];
export const finapi_integration = [
  {
    name: "finapi.host.url",
    hederName: "PROPERTIES.FIELDS.finapi_host_url",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.username",
    hederName: "PROPERTIES.FIELDS.finapi_username",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.password",
    hederName: "PROPERTIES.FIELDS.finapi_password",
    type: "password",
    inputDisabled: false
  },
  {
    name: "finapi.token",
    hederName: "PROPERTIES.FIELDS.finapi_token",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.token.refresh",
    hederName: "PROPERTIES.FIELDS.finapi_token_refresh",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.client.id",
    hederName: "PROPERTIES.FIELDS.finapi_client_id",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.client.secret",
    hederName: "PROPERTIES.FIELDS.finapi_client_secret",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.webform.sleepPeriod.numeric",
    hederName: "PROPERTIES.FIELDS.finapi_webform_sleepPeriod_numeric",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.api.paymentVersion.numeric",
    hederName: "PROPERTIES.FIELDS.finapi_api_paymentVersion_numeric",
    type: "text",
    isRequired: true
  },
  {
    name: "finapi.api.paymentInterface",
    hederName: "PROPERTIES.FIELDS.finapi_api_paymentInterface",
    type: "text",
    isRequired: true
  }
];
export const document_recognition_integration = [
  {
    name: "documentrecognition.host.server",
    hederName: "PROPERTIES.FIELDS.documentrecognition_host_server",
    type: "text",
    isRequired: true
  },
  {
    name: "documentrecognition.host.username",
    hederName: "PROPERTIES.FIELDS.documentrecognition_host_username",
    type: "text",
    isRequired: true
  },
  {
    name: "documentrecognition.host.password",
    hederName: "PROPERTIES.FIELDS.documentrecognition_password",
    type: "password",
    inputDisabled: false,
    isRequired: true
  },
  {
    name: "documentrecognition.scan.filepath.interval.numeric",
    hederName: "PROPERTIES.FIELDS.documentrecognition_scan_filepath_interval_numeric",
    type: "text",
    isRequired: true
  },
  {
    name: "documentrecognition.temp.filepath",
    hederName: "PROPERTIES.FIELDS.documentrecognition_temp_filepath",
    type: "text",
    isRequired: true
  },
  {
    name: "documentrecognition.scan.filepath.linux",
    hederName: "PROPERTIES.FIELDS.documentrecognition_scan_filepath_linux",
    type: "text",
    isRequired: true
  },
  {
    name: "documentrecognition.integration.enabled.boolean",
    hederName: "PROPERTIES.FIELDS.documentrecognition_integration_enabled_boolean",
    type: "boolean",
    isRequired: true
  }
];
export const datev_integration_properties = [
  {
    name: "datev.schema.location",
    hederName: "PROPERTIES.FIELDS.datev_schema_location",
    type: "text",
    isRequired: true
  }
];
export const application_properties = [];
export const email_properties = [
  {
    name: "mail.smtp.host",
    hederName: "PROPERTIES.FIELDS.mail_smtp_host",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.port",
    hederName: "PROPERTIES.FIELDS.mail_smtp_port",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.ssl.trust",
    hederName: "PROPERTIES.FIELDS.mail_smtp_ssl_trust",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.user",
    hederName: "PROPERTIES.FIELDS.mail_smtp_user",
    type: "text",
    isRequired: true
  },
  {
    name: "mail.smtp.pass",
    hederName: "PROPERTIES.FIELDS.mail_smtp_pass",
    type: "password",
    inputDisabled: false
  },
  {
    name: "mail.smtp.starttls.enable",
    hederName: "PROPERTIES.FIELDS.mail_smtp_starttls_enable",
    type: "boolean",
    isRequired: true
  },
  {
    name: "mail.smtp.auth",
    hederName: "PROPERTIES.FIELDS.mail_smtp_auth",
    type: "boolean",
    isRequired: true
  }
];
export const company_profile = [
  {
    name: "company.name",
    hederName: "PROPERTIES.FIELDS.company_name",
    type: "text",
    isRequired: true
  },
  {
    name: "company.vatNumber",
    hederName: "CONTACTS.DETAILS.FORM.VATIN",
    type: "text"
  },
  {
    name: "company.taxNumber",
    hederName: "CONTACTS.DETAILS.FORM.TAX_NUMBER",
    type: "text"
  },
  {
    name: "company.phone",
    hederName: "CONTACTS.DETAILS.FORM.PHONE",
    type: "text"
  },
  {
    name: "company.fax",
    hederName: "CONTACTS.DETAILS.FORM.FAX",
    type: "text"
  },
  {
    name: "company.email",
    hederName: "CONTACTS.DETAILS.FORM.EMAIL",
    type: "text"
  },
  {
    name: "company.web",
    hederName: "CONTACTS.DETAILS.FORM.WEB",
    type: "text"
  },
  {
    name: "company.street",
    hederName: "CONTACTS.DETAILS.FORM.STREET",
    type: "text"
  },
  {
    name: "company.city",
    hederName: "CONTACTS.DETAILS.FORM.CITY",
    type: "text"
  },
  {
    name: "company.postcode",
    hederName: "CONTACTS.DETAILS.FORM.POSTCODE",
    type: "text"
  },
  {
    name: "company.country",
    hederName: "CONTACTS.DETAILS.FORM.COUNTRY",
    type: "text"
  }
];
