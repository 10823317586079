import React from "react";
import { Checkbox } from "semantic-ui-react";
import styles from "./CustomAccountingSystem.module.css";

const AccSystemOption = ({ account, onCheckboxChange }) => {
  const { accountKey = "not available", description = "", enabled } = account;
  return (
    <div className={styles.test_padded}>
      <div className={styles.test_grid}>
        <p>{accountKey}</p>
        <p>{description}</p>
        <Checkbox checked={enabled} onClick={(e, data) => onCheckboxChange(account, data)} />
      </div>
    </div>
  );
};

export default AccSystemOption;
