import React, { useState, useRef, useEffect } from "react";
import * as constant from "../Constants.js";
import { Message, Icon, Dropdown, Input, Popup } from "semantic-ui-react";
import DocumentItem from "./DocumentItem";
import PaymentForm from "./PaymentForm";
import Localize from "react-intl-universal";
import ListWithPagination from "../../Components/ListWithPagination";
import DocumentsToolBar from "../MiddlePanel/DocumentsToolBar";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import styles from "./style/DocumentList.module.css";
import * as RestService from "../../RestService";
import QuickModalComponent from "./QuickModalComponent";
import GlobalModal from "../../Components/Panels/GlobalModal";
import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG, LABELS, FIRST_DAY_OF_WEEK } from "../Util";
import HeaderSettingsModal from "../../Components/Panels/HeaderSettingsModal.js";

const DocumentList = props => {
  const {
    privileges,
    paymentPrivileges,
    paginationStatus,
    handlePageChange,
    activePaginationPage,
    documents,
    editMode,
    handler,
    state,
    dateTypeValue,
    setEditMode,
    accounts,
    selectedDocument,
    documentsPanelState,
    headerIcon = true,
    respDownAndSendMessage,
    setActiveItem,
    unverifiedPartners
  } = props;

  const { activeDocumentTypeFilter, activeDocumentDirectionFilter } = documentsPanelState;

  let checkedDocs = [];
  checkedDocs = documents.filter(doc => doc.checked || (doc.uncheckedInPaymentMode && editMode));

  const refSelectedItem = useRef(null);

  const [companyProfiles, setCompanyProfiles] = useState();
  const [quickInvoicingModal, setQuickInvoicingModal] = useState(false);

  //Quick invoicing Modal
  const [activeModalRadio, setActiveModalRadio] = useState("last");
  const [exportDateRange, setExportDateRange] = useState({
    from: DateUtils.addMonths(new Date(), -1),
    to: new Date()
  });
  //End

  const [settingModal, setSettingModal] = useState(false);

  const setLocalStorage = value => {
    let data = window.localStorage.getItem("store") || null;
    data = JSON.parse(data) || {};
    data.headerSettings = value;
    window.localStorage.setItem("store", JSON.stringify(data));
  };

  const getLocalStorage = key => {
    let data = window.localStorage.getItem("store") || null;
    data = JSON.parse(data);
    if (!data) return null;
    return key in data ? data[key] : null;
  };

  const localStorageHeaderSettings = getLocalStorage("headerSettings") || {};

  const onOkSettingsClicked = () => {
    setSettingModal(false);
    let direction = documentsPanelState.activeDocumentDirectionFilter;
    let type = documentsPanelState.activeDocumentTypeFilter;
    let typeObj =
      localStorageHeaderSettings && localStorageHeaderSettings[direction]
        ? localStorageHeaderSettings[direction]
        : {};
    typeObj[type] = fieldsForList;
    setLocalStorage({
      ...localStorageHeaderSettings,
      [documentsPanelState.activeDocumentDirectionFilter]: typeObj
    });
  };

  const choseAvailableFields = () => {
    let type =
      documentsPanelState.activeDocumentTypeFilter === constant.typeFilter.imported
        ? constant.documentType.imported_invoice
        : documentsPanelState.activeDocumentTypeFilter;
    let localInc = localStorageHeaderSettings && localStorageHeaderSettings.incoming;
    let localOut = localStorageHeaderSettings && localStorageHeaderSettings.outgoing;
    // eslint-disable-next-line default-case
    switch (documentsPanelState.activeDocumentDirectionFilter) {
      case "incoming":
        switch (documentsPanelState.activeDocumentTypeFilter) {
          case "invoice":
            return localInc && localStorageHeaderSettings.incoming.invoice
              ? localStorageHeaderSettings.incoming.invoice
              : constant.availableItemFieldsForListInvoiceIncoming;
          default:
            return localInc && localStorageHeaderSettings.incoming[type]
              ? localStorageHeaderSettings.incoming[type]
              : constant.availableItemFieldsForListOther[type];
        }
      // eslint-disable-next-line no-fallthrough
      case "outgoing":
        switch (documentsPanelState.activeDocumentTypeFilter) {
          case "invoice":
            return localOut && localStorageHeaderSettings.outgoing.invoice
              ? localStorageHeaderSettings.outgoing.invoice
              : constant.availableItemFieldsForListInvoiceOutgoing;
          default:
            return localOut && localStorageHeaderSettings.outgoing[type]
              ? localStorageHeaderSettings.outgoing[type]
              : constant.availableItemFieldsForListOther[type];
        }
    }
  };

  const [fieldsForList, setFieldsForList] = useState(choseAvailableFields());

  useEffect(() => {
    setFieldsForList(choseAvailableFields());
  }, [
    documentsPanelState.activeDocumentDirectionFilter,
    documentsPanelState.activeDocumentTypeFilter
  ]);

  useEffect(() => {
    if (refSelectedItem.current !== null) {
      refSelectedItem.current.scrollIntoView();
    }
  }, [editMode, state.deleteItem]);

  const currentLanguage = navigator.language.toLowerCase().includes("de") ? "de" : "en";

  //DATEPICKER in List
  const [dateInListOpen, setDateInListOpen] = useState(false);

  const dateInList = {
    from: new Date(documentsPanelState.minDate),
    to: new Date(documentsPanelState.maxDate)
  };

  const handleDateInListClick = day => {
    if (!pickerTouched) {
      handler.onDateChange({
        from: day,
        to: day
      });
    } else {
      const newDateInList = DateUtils.addDayToRange(day, dateInList);
      if (newDateInList.from && newDateInList.to) {
        handler.onDateChange({
          from: newDateInList.from,
          to: newDateInList.to
        });
      }
    }
  };

  //End DATEPICKER in List

  const setPayMode = () => {
    conpanyProfile();
  };

  const conpanyProfile = async () => {
    const serviceProfile = new RestService.Properties.GetAll.Service();
    serviceProfile.setCallbackSuccess(callbackProfileSuccess);
    serviceProfile.setCallbackError(callbackProfileError);
    serviceProfile.setCallbackFinally(callbackProfiletFinaly);
    await serviceProfile.call();
  };

  const callbackProfileSuccess = response => {
    setCompanyProfiles(response.data.data);
    handler.setPayMode();
  };
  const callbackProfileError = response => {};
  const callbackProfiletFinaly = response => {};

  const dateModifiersInList = { start: dateInList.from, end: dateInList.to };

  const restartFiltersButton = e => {
    e.preventDefault();
    handler.documentList.onReset();
    handler.documentList.onClearClick();
  };

  const deleteButtonEnable = () => {
    let deleteStaus;
    checkedDocs.length > 0
      ? (deleteStaus = checkedDocs.every(chekedItem =>
          chekedItem.actions && chekedItem.actions.ToolbarRules
            ? chekedItem.actions &&
              chekedItem.actions.ToolbarRules &&
              chekedItem.actions.ToolbarRules.delete
            : null
        ))
      : selectedDocument
      ? (deleteStaus =
          selectedDocument.actions &&
          selectedDocument.actions.ToolbarRules &&
          selectedDocument.actions.ToolbarRules.delete)
      : (deleteStaus = true);
    return deleteStaus;
  };

  const selectAll =
    documents.length > 0 &&
    documents.filter(document => document.checked).length === documents.length;

  let documentList;

  if (state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay) {
    documentList = checkedDocs.map(d => (
      <DocumentItem
        key={d.id}
        id={d.id}
        document={d}
        state={documentsPanelState}
        onListItemClick={handler.documentList.onListItemClick}
        onToggleItem={handler.documentList.onToggleItem}
        checkedDocs={checkedDocs}
        editMode={editMode}
        paymentIconOk={d.isValidPayment}
        fieldsForList={fieldsForList}
      />
    ));
  } else {
    documentList = documents.map(d => (
      <div
        className={styles.bottom_margin}
        ref={selectedDocument && selectedDocument.id === d.id ? refSelectedItem : null}
        key={d.id}
      >
        <DocumentItem
          key={d.id}
          document={d}
          state={documentsPanelState}
          onListItemClick={handler.documentList.onListItemClick}
          onToggleItem={handler.documentList.onToggleItem}
          selectedDocumentId={selectedDocument ? selectedDocument.id : undefined}
          checkedDocs={checkedDocs}
          editMode={editMode}
          paymentIconOk={false}
          fieldsForList={fieldsForList}
        />
      </div>
    ));
  }
  const payMode = state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay;
  const { sortDirection, sortColumn, partnerSearch, number, amount, savings } = documentsPanelState;
  const { approval, pickerTouched } = state;

  const handleOnModalChange = (e, data) => {
    setActiveModalRadio(data.value);
  };

  const modalHandler = () => {
    setQuickInvoicingModal(false);
    handler.documentList.onMultipleQuickInvoicingClick(activeModalRadio, exportDateRange);
  };

  const showQuickInvoicingModal = () => {
    return (
      <GlobalModal
        setShowInvoicesModal={setQuickInvoicingModal}
        headerText={"DOCUMENTS.MODAL.MODAL_QUICK_INVOICING_HEADER"}
        CustomComponent={QuickModalComponent}
        customClassName={styles.quick_modal}
        modalHandler={modalHandler}
        handleOnChange={handleOnModalChange}
        showModal={quickInvoicingModal}
        setShowModal={setQuickInvoicingModal}
        activeModalRadio={activeModalRadio}
        exportDateRange={exportDateRange}
        setExportDateRange={setExportDateRange}
        showActions={true}
      />
    );
  };

  const incominInvoiceNotPay =
    activeDocumentTypeFilter === constant.documentType.invoice &&
    !payMode &&
    activeDocumentDirectionFilter === constant.documentDirection.incoming;

  const isVisible = name => {
    let field = fieldsForList.find(item => item.name === name);
    if (field) {
      return field.visible;
    } else {
      return false;
    }
  };
  const checkFieldsVisibility = () => !fieldsForList.every(el => el.visible === false);

  return (
    <>
      {quickInvoicingModal ? showQuickInvoicingModal() : null}
      <div
        className={
          editMode
            ? `header ${styles.bms_before_header_edit}`
            : `header ${styles.bms_before_header}`
        }
        style={payMode ? { marginTop: "1.6rem" } : null}
      >
        {!payMode && (
          <div className={styles.document_header_search}>
            <DocumentsToolBar
              checkedDocs={checkedDocs}
              handler={handler}
              state={documentsPanelState}
              dateTypeValue={dateTypeValue}
              paymentPrivileges={paymentPrivileges}
              emptyList={!(documentList.length > 0)}
              privileges={privileges}
              selectAll={selectAll}
              onToggleAll={handler.documentList.onToggleAll}
              onArchiveClick={handler.documentList.onMultipleArchiveClick}
              archiveOrUnarchive={documentsPanelState.activeArchiveFilter}
              onDeleteClick={handler.documentList.onMiddlePanelDeleteClick}
              resetSearches={restartFiltersButton}
              disableSearch={partnerSearch !== ""}
              enteredValue={
                documentsPanelState.activeInputNumberSearch
                  ? documentsPanelState.activeInputNumberSearch
                  : ""
              }
              resetNameSearch={handler.documentList.resetToolbarSearch}
              searchName={handler.handleSearchByDocumentNumber}
              // deleteEnable is a part of backend ruless
              deleteEnable={deleteButtonEnable()}
              popupContentText={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_DOCUMENT_PLACEHOLDER")}
              quickInvoicingModal={quickInvoicingModal}
              setQuickInvoicingModal={setQuickInvoicingModal}
              setPayMode={setPayMode}
              respDownAndSendMessage={respDownAndSendMessage}
              setActiveItem={setActiveItem}
              unverifiedPartners={unverifiedPartners}
            />
          </div>
        )}
      </div>
      <div
        className={
          payMode
            ? `ui six column stackable middle aligned grid  ${styles.table_header_documents} ${styles.headerMarginOnPay}`
            : `ui six column stackable middle aligned grid container ${styles.table_header_documents}`
        }
      >
        <div className={`row ${styles.bms_header_small_screen}`}>
          <div className="one wide column" />
          {isVisible("status") && (
            <div className="two wide column">
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.STATUS")}</h5>
                    <div className={styles.dropdown_item_status}>
                      {sortDirection === "desc" && sortColumn === "status" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "status" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {approval !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => handler.documentList.onSortButtonClick("status", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => handler.documentList.onSortButtonClick("status", "desc")}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {isVisible("number") && (
            <div className="two wide column">
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_NUMBER")}</h5>
                    <div className={styles.dropdown_item_status}>
                      {sortDirection === "desc" && sortColumn === "number" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "number" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {number !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => handler.documentList.onSortButtonClick("number", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => handler.documentList.onSortButtonClick("number", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    disabled={documentsPanelState.activeInputNumberSearch !== null}
                    name="number"
                    icon="search"
                    iconPosition="left"
                    className="search"
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={number}
                    onClick={e => e.stopPropagation()}
                    onChange={handler.documentList.onSearchChange}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {isVisible("partner") && (
            <div className={incominInvoiceNotPay ? "three wide column" : "four wide column"}>
              <Dropdown
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.PARTNER")}</h5>
                    <div className={styles.dropdown_item_status}>
                      {sortDirection === "desc" &&
                      (sortColumn === "partnerName" || sortColumn === "partnerOriginalId") ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" &&
                      (sortColumn === "partnerName" || sortColumn === "partnerOriginalId") ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {partnerSearch !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Dropdown
                      className={styles.dropdown_sort}
                      icon="sort amount up"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handler.documentList.onSortButtonClick("partnerOriginalId", "asc")
                          }
                        >
                          {Localize.get("DOCUMENTS.ITEMLIST.HEADER.SORT_BY_ID")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handler.documentList.onSortButtonClick("partnerName", "asc")
                          }
                        >
                          {Localize.get("DOCUMENTS.ITEMLIST.HEADER.SORT_BY_NAME")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Dropdown
                      className={styles.dropdown_sort}
                      icon="sort amount down"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handler.documentList.onSortButtonClick("partnerOriginalId", "desc")
                          }
                        >
                          {Localize.get("DOCUMENTS.ITEMLIST.HEADER.SORT_BY_ID")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handler.documentList.onSortButtonClick("partnerName", "desc")
                          }
                        >
                          {Localize.get("DOCUMENTS.ITEMLIST.HEADER.SORT_BY_NAME")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Popup
                    content={Localize.get("DOCUMENTS.ITEMLIST.HEADER.SEARCH_ID_NAME")}
                    on={["hover", "click"]}
                    trigger={
                      <Input
                        name="partnerSearch"
                        value={partnerSearch}
                        icon="search"
                        iconPosition="left"
                        className="search"
                        placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                        onClick={e => e.stopPropagation()}
                        onChange={handler.documentList.onSearchChange}
                      />
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {(isVisible("earlyPaymentDate") || isVisible("documentType")) && (
            <div
              className={
                activeDocumentTypeFilter === constant.documentType.invoice &&
                !payMode &&
                activeDocumentDirectionFilter === constant.documentDirection.incoming
                  ? "two wide column"
                  : "two wide column"
              }
            >
              {activeDocumentTypeFilter === constant.documentType.invoice &&
              !payMode &&
              activeDocumentDirectionFilter === constant.documentDirection.incoming
                ? isVisible("earlyPaymentDate") && (
                    <Dropdown
                      multiple
                      icon={null}
                      trigger={
                        <div className={styles.trigger_style}>
                          <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.EARLY_PAYMENT_DATE")}</h5>
                          {/* ----- TO DO ------  */}
                          <div className={styles.dropdown_item_status}>
                            {sortDirection === "desc" && sortColumn === "savingsDue" ? (
                              <Icon name="angle down" />
                            ) : (
                              ""
                            )}
                            {sortDirection === "asc" && sortColumn === "savingsDue" ? (
                              <Icon name="angle up" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon="sort amount up"
                          text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                          onClick={() =>
                            handler.documentList.onSortButtonClick("savingsDue", "asc")
                          }
                        />
                        <Dropdown.Item
                          icon="sort amount down"
                          text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                          onClick={() =>
                            handler.documentList.onSortButtonClick("savingsDue", "desc")
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                : isVisible("documentType") && (
                    <Dropdown
                      multiple
                      icon={null}
                      trigger={
                        <div className={styles.trigger_style}>
                          <h5 className={styles.break_word}>
                            {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_TYPE")}
                          </h5>
                          <div className={styles.dropdown_item_status}>
                            {sortDirection === "desc" && sortColumn === "documentType" ? (
                              <Icon name="angle down" />
                            ) : (
                              ""
                            )}
                            {sortDirection === "asc" && sortColumn === "documentType" ? (
                              <Icon name="angle up" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon="sort amount up"
                          text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                          onClick={() =>
                            handler.documentList.onSortButtonClick("documentType", "asc")
                          }
                        />
                        <Dropdown.Item
                          icon="sort amount down"
                          text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                          onClick={() =>
                            handler.documentList.onSortButtonClick("documentType", "desc")
                          }
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
            </div>
          )}
          {(isVisible("paymentDate") || isVisible("documentDate")) && (
            <div className="two wide column">
              {activeDocumentTypeFilter === constant.documentType.invoice &&
              !payMode &&
              activeDocumentDirectionFilter === constant.documentDirection.incoming &&
              isVisible("paymentDate") ? (
                <Dropdown
                  multiple
                  icon={null}
                  trigger={
                    <div className={styles.trigger_style}>
                      <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.PAYMENT_DATE")}</h5>

                      <div className={styles.dropdown_item_status}>
                        {sortDirection === "desc" && sortColumn === "paymentDate" ? (
                          <Icon name="angle down" />
                        ) : (
                          ""
                        )}
                        {sortDirection === "asc" && sortColumn === "paymentDate" ? (
                          <Icon name="angle up" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="sort amount up"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                      onClick={() => handler.documentList.onSortButtonClick("paymentDate", "asc")}
                    />
                    <Dropdown.Item
                      icon="sort amount down"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                      onClick={() => handler.documentList.onSortButtonClick("paymentDate", "desc")}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                isVisible("documentDate") && (
                  <Dropdown
                    className={styles.right_padding}
                    multiple
                    icon={null}
                    closeOnBlur
                    onClick={() => setDateInListOpen(true)}
                    onBlur={() => setDateInListOpen(false)}
                    trigger={
                      <div className={styles.trigger_style}>
                        <h5>{Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENT_DATE")}</h5>
                        <div className={styles.dropdown_item_status}>
                          {sortDirection === "desc" &&
                          sortColumn === constant.sortColumn.documentDate ? (
                            <Icon name="angle down" />
                          ) : (
                            ""
                          )}
                          {sortDirection === "asc" &&
                          sortColumn === constant.sortColumn.documentDate ? (
                            <Icon name="angle up" />
                          ) : (
                            ""
                          )}
                          {pickerTouched ? <Icon name="filter" /> : ""}
                        </div>
                      </div>
                    }
                  >
                    <Dropdown.Menu open={dateInListOpen}>
                      <Dropdown.Item
                        icon="sort amount up"
                        text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                        onClick={() =>
                          handler.documentList.onSortButtonClick(
                            constant.sortColumn.documentDate,
                            "asc"
                          )
                        }
                      />
                      <Dropdown.Item
                        icon="sort amount down"
                        text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                        onClick={() =>
                          handler.documentList.onSortButtonClick(
                            constant.sortColumn.documentDate,
                            "desc"
                          )
                        }
                      />
                      <Dropdown.Divider />
                      <DayPicker
                        id="myCal"
                        locale={currentLanguage}
                        months={MONTHS[currentLanguage]}
                        weekdaysLong={WEEKDAYS_LONG[currentLanguage]}
                        weekdaysShort={WEEKDAYS_SHORT[currentLanguage]}
                        firstDayOfWeek={FIRST_DAY_OF_WEEK[currentLanguage]}
                        labels={LABELS[currentLanguage]}
                        className={styles.selectableDayPicker}
                        modifiers={dateModifiersInList}
                        selectedDays={{
                          from: dateInList.from,
                          to: dateInList.to
                        }}
                        onDayClick={handleDateInListClick}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )
              )}
            </div>
          )}
          {isVisible("amount") && (
            <div
              className={
                activeDocumentTypeFilter === constant.documentType.invoice &&
                !payMode &&
                activeDocumentDirectionFilter === constant.documentDirection.incoming
                  ? `two wide column ${styles.lastColumn}`
                  : `three wide column ${styles.lastColumn}`
              }
            >
              <Dropdown
                className={styles.paddingRight}
                multiple
                icon={null}
                trigger={
                  <div className={styles.trigger_style}>
                    <h5>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</h5>
                    <div>
                      {sortDirection === "desc" && sortColumn === "amount" ? (
                        <Icon name="angle down" />
                      ) : (
                        ""
                      )}
                      {sortDirection === "asc" && sortColumn === "amount" ? (
                        <Icon name="angle up" />
                      ) : (
                        ""
                      )}
                      {amount !== "" ? <Icon name="filter" /> : ""}
                    </div>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="sort amount up"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                    onClick={() => handler.documentList.onSortButtonClick("amount", "asc")}
                  />
                  <Dropdown.Item
                    icon="sort amount down"
                    text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                    onClick={() => handler.documentList.onSortButtonClick("amount", "desc")}
                  />
                  <Dropdown.Divider />
                  <Input
                    name="amount"
                    icon="search"
                    iconPosition="left"
                    className="search "
                    placeholder={Localize.get("GLOBAL.LIST.FILTER")}
                    value={amount}
                    onClick={e => e.stopPropagation()}
                    onChange={handler.documentList.onSearchChange}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {activeDocumentTypeFilter === constant.documentType.invoice &&
            !payMode &&
            activeDocumentDirectionFilter === constant.documentDirection.incoming &&
            isVisible("savings") && (
              <div className={`two wide column ${styles.lastColumn}`}>
                <Dropdown
                  className={styles.paddingRight}
                  multiple
                  icon={null}
                  trigger={
                    <div className={styles.trigger_style}>
                      <h5>{Localize.get("DOCUMENTS.ITEMLIST.HEADER.SAVINGS")}</h5>
                      <div>
                        {sortDirection === "desc" && sortColumn === "savings" ? (
                          <Icon name="angle down" />
                        ) : (
                          ""
                        )}
                        {sortDirection === "asc" && sortColumn === "savings" ? (
                          <Icon name="angle up" />
                        ) : (
                          ""
                        )}
                        {savings !== "" ? <Icon name="filter" /> : ""}
                      </div>
                    </div>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="sort amount up"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                      onClick={() => handler.documentList.onSortButtonClick("savings", "asc")}
                    />
                    <Dropdown.Item
                      icon="sort amount down"
                      text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                      onClick={() => handler.documentList.onSortButtonClick("savings", "desc")}
                    />
                    <Dropdown.Divider />
                    <Input
                      name="savings"
                      icon="search"
                      iconPosition="left"
                      className="search "
                      placeholder={Localize.get("CONTACTS.LIST.HEADER.SEARCH_NAME")}
                      value={savings}
                      onClick={e => e.stopPropagation()}
                      onChange={handler.documentList.onSearchChange}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          {headerIcon && (
            <Icon
              name="setting"
              className={styles.set_icon}
              onClick={() => setSettingModal(true)}
            />
          )}
        </div>
      </div>

      {documentList.length > 0 && checkFieldsVisibility() ? (
        <ListWithPagination
          list={documentList}
          state={state}
          defaultActivePage={paginationStatus.pageCount}
          totalPage={paginationStatus.totalCount}
          handlePageChange={handlePageChange}
          activePaginationPage={activePaginationPage}
          perPage={paginationStatus.perPage}
        />
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("DOCUMENTS.ITEMLIST.TOOLBAR.EMPTY")}
        </Message>
      )}
      {state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay && editMode && (
        <PaymentForm
          checkedDocs={checkedDocs}
          editMode={editMode}
          setEditMode={setEditMode}
          handler={handler}
          accounts={accounts}
          selectedDocument={selectedDocument}
          companyName={
            companyProfiles &&
            companyProfiles.content &&
            companyProfiles.content.find(el => el.name === "company.name")
          }
        />
      )}
      {settingModal && (
        <HeaderSettingsModal
          settingModal={settingModal}
          setSettingModal={setSettingModal}
          availableItemFieldsForList={fieldsForList}
          setFieldsForList={setFieldsForList}
          onOkSettingsClicked={onOkSettingsClicked}
        />
      )}
    </>
  );
};

export default DocumentList;
