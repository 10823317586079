import React, { useState } from "react";
import Localize from "react-intl-universal";
import * as RestService from "../../../RestService";
import { Grid, Modal, Input, Label, Form } from "semantic-ui-react";

import { formatDate, formatDateToString, formatAmount } from "../../../Documents/Util";
import Validator from "../../../Utils/Validator";
import * as validatorConstants from "../../../Utils/Constant";
import styles from "../../MiddlePanel/style/PaymentForm.module.css";
import * as constant from "../../../Documents/Constants.js";
import showNotification from "../../../Notifications/NotificationFunction";
import * as notificationConstants from "../../../Notifications/Constants.js";
import GlobalOkBtn from "../../../Components/GlobalOkBtn";
import GlobalOkCancelBtn from "../../../Components/GlobalOkCancelBtn";
import GlobalDatedown from "../GlobalDatedown";

const AddManualPayment = props => {
  const { document, paymentForm, documentForm, setShowManualPaymant } = props;
  const partner = document.partner ? document.partner : "";
  const [executionDate, setExecutionDate] = useState(new Date());
  const [openMsgPayDialog, setOpenMsgPayDialog] = useState(false);
  const [creationDateMenuOpen, setCreationDateMenuOpen] = useState(false);
  const [refreshValidation, setRefreshValidation] = useState(false);

  const payment = {
    docId: document.id,
    docNo: document.documentNumber,
    amount: document.actions.PaymentRules.maxPayment,
    partnerName: document.partner.name,
    executionDate: formatDate(new Date()),
    isValidPayment: false,
    checkAmount: false,
    zeroAmount: false,
    paymentValidator: new Validator(validatorConstants.checkFieldsManualPaymentForm),
    paymentsPaid:
      document.payments && document.payments.length > 0
        ? document.payments.reduce(
            (prvAmt, pay) => (pay.amount ? prvAmt + pay.amount : prvAmt + 0),
            0
          )
        : 0
  };

  const [paymentObject, setPaymentObject] = useState({
    payment: payment,
    executionDate: formatDate(new Date()),
    displayAmount: payment.amount.toString()
  });

  const validator =
    props.validator === undefined
      ? new Validator(validatorConstants.formFieldsPaymentForm)
      : props.validator;

  let formPurpose = paymentObject.payment.purpose;
  let formAmount = paymentObject.displayAmount;

  const getCurrency = () => {
    let currency;
    if (document.currency && document.currency.id) {
      currency = document.currency.id;
    } else {
      currency = null;
    }

    return currency;
  };

  const handleOnChange = (e, data) => {
    if (e.target.name === "amount") {
      if (!validator.isValidNumber(e.target.value)) {
        e.target.value = payment.amount;
        return;
      }
      let t = e.target.value;
      if (t.indexOf(".") >= 0) {
        e.target.value = t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3);
      } else if (t.indexOf(",") >= 0) {
        e.target.value = t.substr(0, t.indexOf(",")) + t.substr(t.indexOf(","), 3);
      }

      let newAmount = formatAmount(e.target.value);
      paymentObject.payment.zeroAmount = false;
      if (parseFloat(newAmount) > document.actions.PaymentRules.maxPayment) {
        paymentObject.payment.checkAmount = true;
      } else {
        paymentObject.payment.checkAmount = false;
      }

      paymentObject.payment.amount = newAmount;
      paymentObject.displayAmount = e.target.value;

      setPaymentObject({ ...paymentObject, payment: paymentObject.payment });
    } else {
      paymentObject.payment[e.target.name] = e.target.value;
    }
    setPaymentObject({ ...paymentObject, payment: paymentObject.payment });
    paymentObject.payment.isValidPayment =
      paymentObject.payment.paymentValidator.validateForm(
        validatorConstants.checkFieldsManualPaymentForm,
        paymentObject.payment
      ) && !paymentObject.payment.checkAmount;
    paymentObject.payment.paymentValidator.parameter = [];
    paymentForm.onPaymentChange(e, {
      ...data,
      payments: [].concat(paymentObject.payment)
    });
  };

  const handleOnBlur = e => {
    if (e && e.target && e.target.name === "amount") {
      if (!validator.isValidNumber(e.target.value)) {
        e.target.value = payment.amount;
        return;
      }

      let newAmount = formatAmount(e.target.value);
      paymentObject.payment.zeroAmount =
        newAmount === "0" ||
        newAmount === "0." ||
        newAmount === "0.0" ||
        newAmount === "0.00" ||
        newAmount === 0 ||
        newAmount === null ||
        isNaN(newAmount);
    }
    setRefreshValidation(!refreshValidation);
  };

  const handleCreationDateClick = (day, modifiers = {}, event) => {
    if (modifiers.disabled) return true;

    event.stopPropagation();
    setCreationDateMenuOpen(false);
    setExecutionDate(day);
    setPaymentObject({
      ...paymentObject,
      executionDate: formatDate(day)
    });
  };

  const onPayButtonClick = () => {
    paymentObject.payment.amount = parseFloat(paymentObject.payment.amount).toString();
    paymentObject.payment.zeroAmount =
      paymentObject.payment.amount === "0" ||
      paymentObject.payment.amount === null ||
      isNaN(paymentObject.payment.amount);
    let validationArray = {
      isValidPayment:
        paymentObject.payment.paymentValidator.validateForm(
          validatorConstants.checkFieldsManualPaymentForm,
          {
            ...paymentObject.payment
          }
        ) &&
        !paymentObject.payment.checkAmount &&
        !paymentObject.payment.zeroAmount,
      docId: paymentObject.payment.docId,
      docNo: document.documentNumber
    };

    if (validationArray.isValidPayment) {
      paymentObject.payment.executionDate = paymentObject.executionDate;

      setPaymentObject({ ...paymentObject });
      onPayConfirmationButtonClick();
      closeModal();
    } else {
      setOpenMsgPayDialog(true);
    }
  };

  const showNotificationMessage = param => {
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.MANUAL_PAYMENT"),
      ...param
    });
  };

  const onPayConfirmationButtonClick = () => {
    const servicePayment = new RestService.Payment.ManualPayment.Service();
    servicePayment.query.addDocumentId(document.id);
    servicePayment.setCallbackSuccess(callbackPaymentSuccess);
    servicePayment.setCallbackError(callbackPaymentError);
    servicePayment.setCallbackFinally(callbackPaymentFinaly);
    servicePayment.setPayload(paymentObject.payment);
    servicePayment.call();
  };

  const setDocumentHistory = (actionType, note) => {
    const history = {};
    history.timestamp = new Date();
    history.action = actionType;
    history.note = note;

    if (!document.history) {
      document.history = [];
    }
    document.history.push(history);
  };

  const callbackPaymentSuccess = () => {
    documentForm.updateDocumentForManualPayment(document.id);
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.added
    });
    setDocumentHistory(
      constant.actionType.addManualPayment,
      Localize.get("GLOBAL.DETAILS.AMOUNT") +
        ": " +
        new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR"
        }).format(paymentObject.payment.amount) +
        (paymentObject.payment.purpose && paymentObject.payment.purpose !== ""
          ? " " +
            Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT") +
            ": " +
            paymentObject.payment.purpose
          : "")
    );
  };

  const callbackPaymentError = () => {
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      operation: notificationConstants.notificationOperation.added
    });
  };

  const callbackPaymentFinaly = () => {};

  const closeMsgPayModal = () => {
    setOpenMsgPayDialog(false);
  };

  const closeModal = () => {
    setShowManualPaymant();
  };

  return (
    <>
      <div className={styles.manualPaymentFormStyle} style={props.editMode ? { zIndex: 1001 } : {}}>
        <Grid columns="equal">
          <Grid.Row divided className={styles.row_bottom}>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>
                    {document.direction === constant.documentDirection.incoming
                      ? Localize.get("DOCUMENTS.PAYMENT.TO")
                      : Localize.get("DOCUMENTS.PAYMENT.FROM")}{" "}
                    {Localize.get("DOCUMENTS.PAYMENT.PARTNER")}
                  </label>
                  <Input fluid disabled value={partner.name} />
                </Form.Field>
                <Form.Field>
                  <label>{Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT")}</label>
                  <Input
                    autoComplete={constant.autoComplete.none}
                    name="purpose"
                    fluid
                    defaultValue={formPurpose}
                    placeholder={Localize.get("DOCUMENTS.PAYMENT.PURPOSE_OF_PAYMENT")}
                    onChange={handleOnChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{Localize.get("GLOBAL.DETAILS.AMOUNT")}</label>
                  <Input
                    autoComplete={constant.autoComplete.none}
                    name="amount"
                    fluid
                    value={formAmount}
                    placeholder={Localize.get("GLOBAL.DETAILS.AMOUNT")}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                  />
                  {paymentObject.payment.checkAmount && (
                    <Label
                      className={styles.payment_labele}
                      basic
                      color="red"
                      pointing
                      content={Localize.get("DOCUMENTS.PAYMENT.AMMOUNT_MSG")}
                    />
                  )}
                  {paymentObject.payment.zeroAmount && (
                    <Label
                      className={styles.payment_labele}
                      basic
                      color="red"
                      pointing
                      content={Localize.get("DOCUMENTS.PAYMENT.AMOUNT_ZERO_MESSAGE")}
                    />
                  )}
                </Form.Field>
                <Form.Field>
                  <label>{Localize.get("DOCUMENTS.PAYMENT.CURRENCY")}</label>
                  <Input fluid disabled value={getCurrency()} />
                </Form.Field>
                <Form.Field className={styles.globaldatedown_field}>
                  <label>{Localize.get("DOCUMENTS.PAYMENT.DATE")}</label>

                  <GlobalDatedown
                    name="documentDate"
                    icon="calendar alternate outline"
                    isRequired={false}
                    dropdownName={"documentDate"}
                    handleOnClick={() => setCreationDateMenuOpen(true)}
                    handleOnBlur={() => setCreationDateMenuOpen(false)}
                    dropdownOpen={creationDateMenuOpen}
                    dropdownText={formatDateToString(executionDate)}
                    handleDateClick={handleCreationDateClick}
                    showLabel={false}
                    dropdownStyleError={"1px red solid"}
                    selectedDays={executionDate}
                    disabledDays={[
                      {
                        after: new Date()
                      }
                    ]}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign="right">
            <Grid.Column className={styles.btn_column}>
              <GlobalOkCancelBtn
                disabledBtn={paymentObject.payment.checkAmount || paymentObject.payment.zeroAmount}
                onConfirmClicked={onPayButtonClick}
                onCancel={closeModal}
                btnText={Localize.get("DOCUMENTS.PAYMENT.MANUAL.ADD")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal open={openMsgPayDialog} onClose={closeMsgPayModal} closeIcon size="small">
          <Modal.Header>{Localize.get("DOCUMENTS.PAYMENT.MODAL.INCOMPLETE_HEADER")}</Modal.Header>
          <Modal.Content>
            <p>{Localize.get("DOCUMENTS.PAYMENT.MODAL.MANDATORY_FIELDS")}</p>
          </Modal.Content>
          <Modal.Actions>
            <GlobalOkBtn onOkClicked={closeMsgPayModal} />
          </Modal.Actions>
        </Modal>
      </div>
    </>
  );
};

export default AddManualPayment;
