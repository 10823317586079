export const partnerCompanySchema = {
  name: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  vatin: { type: "string", filters: ["whitespaceBeginFilter", "longerThan19"] },
  taxNumber: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan19"]
  },
  phone: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  fax: {
    type: "string",
    filters: ["faxFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  email: {
    type: "string",
    filters: ["emailFormat", "whitespaceBeginFilter", "longerThan224"]
  },
  url: { type: "string", filters: ["whitespaceBeginFilter", "longerThan255"] },
  note: { type: "string", filters: ["whitespaceBeginFilter"] }
};

export const addressSchema = {
  street: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan64"]
  },
  city: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan64"]
  },
  zip: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan12"]
  },
  country: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan64"]
  },
  state: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan64"]
  }
};

export const contactPersonSchema = {
  first: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  last: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  jobTitle: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan255"]
  },
  phone: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  mobile: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  email: {
    type: "string",
    filters: ["emailFormat", "whitespaceBeginFilter", "longerThan224"]
  },
  url: { type: "string", filters: ["whitespaceBeginFilter", "longerThan255"] }
};

export const contactAccountSchema = {
  bankName: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan255"]
  },
  accountOwner: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  iban: {
    type: "string",
    filters: ["min1Character", "characterFilter", "whitespaceBeginFilter", "longerThan34"]
  },
  bic: {
    type: "string",
    filters: ["min1Character", "characterFilter", "whitespaceBeginFilter", "longerThan11"]
  }
};

export const partnerPersonSchema = {
  firstName: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan100"]
  },
  lastName: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan100"]
  },
  phone: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  mobile: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  url: { type: "string", filters: ["whitespaceBeginFilter", "longerThan255"] },
  email: {
    type: "string",
    filters: ["emailFormat", "whitespaceBeginFilter", "longerThan224"]
  },
  note: { type: "string", filters: ["whitespaceBeginFilter"] }
};

export const partnerEmployeeSchema = {
  first: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan100"]
  },
  last: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan100"]
  },
  jobTitle: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan255"]
  },
  phone: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  mobile: {
    type: "string",
    filters: ["phoneFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  url: { type: "string", filters: ["whitespaceBeginFilter", "longerThan255"] },
  email: {
    type: "string",
    filters: ["emailFormat", "whitespaceBeginFilter", "longerThan224"]
  },
  note: { type: "string", filters: ["whitespaceBeginFilter"] }
};

export const companyEmpleyeeSchema = {
  name: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter"]
  }
};

export const invoiceReceipmentSchema = {
  email: {
    type: "string",
    filters: ["min1Character", "emailFormat", "whitespaceBeginFilter", "longerThan255"]
  },
  first: {
    type: "string",
    filters: ["min1Character", "whitespaceBeginFilter", "longerThan255"]
  },
  jobTitle: {
    type: "string",
    filters: ["whitespaceBeginFilter", "longerThan255"]
  },
  phone: {
    type: "string",
    filters: ["whitespaceBeginFilter", "phoneFormat", "longerThan255"]
  }
};
