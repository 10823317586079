import React, { useEffect, useState } from "react";
import { Icon, Table, Button, Popup } from "semantic-ui-react";
import styles from "./style/TaxSubtotals.module.css";
import * as constant from "../Constants";
import Localize from "react-intl-universal";
import TableCellDropdownOut from "../../Components/Lists/TableCellDropdownOut";
import TableCellInputOut from "../../Components/Lists/TableCellInputOut";
import { calculateSubtotal, checkIsValidObject, checkValidationList } from "./DocumentTotalUtil";

const TaxSubtotals = props => {
  const {
    subtotalsHandler,
    document,
    checkEdit,
    setCheckEdit,
    documentSubtotalsValidator,
    validateSubtotalField,
    validateSubtotalsFunc,
    setSubtotalsAreValid
  } = props;

  // TODO: call service for available taxes
  const taxOptions = [
    { key: 1, text: "0%", value: 0 },
    { key: 2, text: "5%", value: 5 },
    { key: 3, text: "7%", value: 7 },
    { key: 4, text: "16%", value: 16 },
    { key: 5, text: "19%", value: 19 }
  ];
  const [subtotals, setSubtotals] = useState(
    document.taxSubtotals ? [...document.taxSubtotals] : []
  );
  const [editedIndex, setEditedIndex] = useState(null);

  const onEditSubtotalClick = (subtotal, index) => {
    setEditedIndex(index);
    setCheckEdit({ type: "editSubtotals" });
  };
  const getIcon = () => {
    if (document && document.currency && document.currency.id) {
      switch (document.currency.id) {
        case "EUR":
          return <Icon name="euro sign" />;
        case "GBP":
          return <Icon name="pound" />;
        case "USD":
          return <Icon name="dollar sign" />;
        default:
          break;
      }
    }
  };

  const handleValidationOnBlur = (event, data) => {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;

    validateSubtotalField(fieldName, fieldValue, editedIndex);
  };

  const onSubtotalChange = (event, data) => {
    if (
      documentSubtotalsValidator.parameterList &&
      documentSubtotalsValidator.parameterList[editedIndex] &&
      documentSubtotalsValidator.parameterList[editedIndex][event.target.name] !== ""
    ) {
      validateSubtotalField(event.target.name, event.target.value, editedIndex);
    }

    let newSubtotals = [...subtotals];

    let mySubtotal = {
      ...newSubtotals[editedIndex],
      [event.target.name]: event.target.value
    };

    newSubtotals[editedIndex] = calculateSubtotal(mySubtotal);
    setSubtotals(newSubtotals);
    setEditedIndex(editedIndex);
  };

  const onDropdownClick = (event, data) => {
    validateSubtotalField(data.name, data.value, editedIndex);
    let newSubtotals = [...subtotals];

    let mySubtotal = {
      ...newSubtotals[editedIndex],
      [data.name]: data.value
    };

    newSubtotals[editedIndex] = calculateSubtotal(mySubtotal);
    setSubtotals(newSubtotals);
  };

  const onEditItemChecked = index => {
    if (checkIsValidObject(documentSubtotalsValidator.parameterList[index])) {
      setSubtotalsAreValid(true);
      subtotalsHandler(subtotals);
      setEditedIndex(null);
    }
  };

  const onEditSubtotalCancel = () => {
    setEditedIndex(null);
    setSubtotals(document.taxSubtotals ? [...document.taxSubtotals] : []);
    setCheckEdit({ type: "nonEditState" });
  };

  const onAddNewItem = () => {
    subtotals.push(constant.initSubtotals);
    setEditedIndex(subtotals.length - 1);
    setSubtotals(subtotals);
    setCheckEdit({ type: "editSubtotals" });
    validateSubtotalsFunc({ ...document, taxSubtotals: subtotals });
  };

  const deleteItem = index => {
    let deletedArr = [...subtotals];
    deletedArr.splice(index, 1);
    subtotalsHandler(deletedArr);
    setEditedIndex(null);
    validateSubtotalsFunc({ ...document, taxSubtotals: deletedArr });
  };

  const filterOptions = (options, subtotalList, selectedOption) => {
    let myNewOptions = options.filter(
      option =>
        !subtotalList.some(subtotal =>
          selectedOption === subtotal.tax ? false : subtotal.tax === parseFloat(option.value)
        )
    );
    return myNewOptions;
  };

  useEffect(() => {
    setSubtotals(document.taxSubtotals ? [...document.taxSubtotals] : []);
  }, [document.taxSubtotals]);

  return (
    <div className={styles.subtotal_container}>
      <Table selectable className={styles.subtotal_table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell> {Localize.get("DOCUMENTS.FORM.TAX")}</Table.HeaderCell>
            <Table.HeaderCell>
              {Localize.get("DOCUMENTS.FORM.BASE_NETTO")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {Localize.get("DOCUMENTS.NEWDOCUMENT.DISCOUNT")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {Localize.get("DOCUMENTS.NEWDOCUMENT.SURCHARGE")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              {Localize.get("DOCUMENTS.FORM.NETO_SUM")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {Localize.get("DOCUMENTS.FORM.TAX_VALUE")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              {Localize.get("DOCUMENTS.FORM.BRUTO_SUM")}
              {getIcon()}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <Popup
                content={"Add subtotal"}
                size="tiny"
                trigger={
                  <Button
                    icon
                    size="small"
                    onClick={onAddNewItem}
                    className={styles.plus_button}
                    disabled={editedIndex === 0 || checkEdit.editMode}
                  >
                    <Icon name="plus" />
                  </Button>
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subtotals &&
            subtotals.map((subtotal, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellDropdownOut
                      editedIndex={editedIndex}
                      dropdownOptions={filterOptions(taxOptions, subtotals, subtotal.tax)}
                      placeholder={"Tax"}
                      index={index}
                      inputValue={subtotal.tax}
                      icon="dropdown"
                      inputName={"tax"}
                      onCellChange={onDropdownClick}
                      hasError={checkValidationList(
                        documentSubtotalsValidator.parameterList,
                        index,
                        "tax"
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={editedIndex}
                      index={index}
                      inputValue={subtotal.netAmount}
                      placeholder={"Amount"}
                      inputName={"netAmount"}
                      onCellChange={onSubtotalChange}
                      onCellBlur={handleValidationOnBlur}
                      hasError={checkValidationList(
                        documentSubtotalsValidator.parameterList,
                        index,
                        "netAmount"
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={editedIndex}
                      index={index}
                      inputValue={subtotal.discount}
                      placeholder={"Amount"}
                      inputName={"discount"}
                      onCellChange={onSubtotalChange}
                      onCellBlur={handleValidationOnBlur}
                      hasError={checkValidationList(
                        documentSubtotalsValidator.parameterList,
                        index,
                        "discount"
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={editedIndex}
                      index={index}
                      inputValue={subtotal.surcharge}
                      placeholder={"Amount"}
                      inputName={"surcharge"}
                      onCellChange={onSubtotalChange}
                      onCellBlur={handleValidationOnBlur}
                      hasError={checkValidationList(
                        documentSubtotalsValidator.parameterList,
                        index,
                        "surcharge"
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={"noIndexProvided"}
                      index={index}
                      inputValue={subtotal.baseAmount}
                      placeholder={"Amount"}
                      inputName={"baseAmount"}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={"noIndexProvided"}
                      index={index}
                      inputValue={subtotal.calculatedTax}
                      placeholder={"Amount"}
                      inputName={"calculatedTax"}
                    />
                  </Table.Cell>
                  <Table.Cell className={styles.default_cell}>
                    <TableCellInputOut
                      editedIndex={"noIndexProvided"}
                      index={index}
                      inputValue={subtotal.grossAmount}
                      placeholder={"Amount"}
                      inputName={"grossAmount"}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {editedIndex === index ? (
                      <>
                        <Popup
                          content={"Save"}
                          size="tiny"
                          trigger={
                            <Icon
                              className={styles.edit_check}
                              name="check circle outline"
                              onClick={() => onEditItemChecked(index)}
                            />
                          }
                        />
                        <Popup
                          content={"Cancel"}
                          size="tiny"
                          trigger={
                            <Icon
                              className={styles.edit_cancel}
                              name="cancel"
                              onClick={onEditSubtotalCancel}
                            />
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Popup
                          content={"Edit"}
                          size="tiny"
                          trigger={
                            <Icon
                              className={styles.edit_icon}
                              name="edit"
                              disabled={
                                editedIndex === 0 ||
                                (editedIndex && editedIndex !== index) ||
                                checkEdit.editMode
                              }
                              onClick={() => onEditSubtotalClick(subtotal, index)}
                            />
                          }
                        />
                        <Popup
                          content={"Delete"}
                          size="tiny"
                          trigger={
                            <Icon
                              className={styles.minus_icon}
                              name="minus circle"
                              disabled={
                                editedIndex === 0 ||
                                (editedIndex && editedIndex !== index) ||
                                checkEdit.editMode
                              }
                              onClick={() => deleteItem(index)}
                            />
                          }
                        />
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TaxSubtotals;
