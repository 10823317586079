import React, { useState, useContext } from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { AuthContext } from "../Auth/AuthContext";

// Components
import AppNavBar from "../AppNavBar/AppNavBar";
import Transactions from "../Finances/Transactions/Transactions";
import BankAccounts from "../Finances/BankAccounts/BankAccounts";
import Documents from "../Documents/Documents";
import ProjectManagement from "../Projects/Management/ProjectManagement";
import ProjectTimeTracker from "../Projects/ProjectTimeTracker";
import ProjectTypes from "../Projects/Types/ProjectTypes";
import ProjectTemplates from "../Projects/Templates/ProjectTemplates";
import ProjectRoles from "../Projects/Roles/ProjectRoles";
import ProjectStatuses from "../Projects/Statuses/ProjectStatuses";
import Contacts from "../Contacts/Contacts";
import WelcomePage from "../WelcomePage/WelcomePage";
import Dashboard from "../Dashboard/Dashboard";
import Exports from "../Exports/ExportDatev";
import Exported from "../Exports/Exported";
import Settings from "../SettingsModule/Settings";
// eslint-disable-next-line no-unused-vars
import { roles } from "../Auth/RolePrivileges";
import { mappedPaths } from "./Constants";
import Unauthorised from "./Unauthorised";
import VerticalAppNavBar from "../AppNavBar/VerticalAppNavBar";
import Registration from "./Registration";
import BookingOverview from "../Bookkeeping/BookingOverview/BookingOverview";
import AccountOverview from "../Bookkeeping/AccountOverview/AccountOverview";

const compareRolePrivileges = (path, userAuthorites, customConfig) => {
  if (customConfig.disablePath.length > 0) {
    customConfig.disablePath.map(path => (userAuthorites[path] = { get: false }));
  }
  return path in mappedPaths ? userAuthorites[mappedPaths[path]]["get"] : true;
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (restricted ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const { authState, customConfig, dispatch } = useContext(AuthContext);

  const [verticalBarVisible, setVerticalBarVisible] = useState(false);
  const [headerDimmed, setHeaderDimmed] = useState(false);
  const [activeItem, setActiveItem] = useState();

  return (
    <>
      <Sidebar.Pushable>
        <AppNavBar
          verticalBarVisible={verticalBarVisible}
          setVerticalBarVisible={setVerticalBarVisible}
          headerDimmed={headerDimmed}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
        <VerticalAppNavBar
          verticalBarVisible={verticalBarVisible}
          setVerticalBarVisible={setVerticalBarVisible}
        />
        <Sidebar.Pusher dimmed={verticalBarVisible}>
          <Route
            {...rest}
            render={props => {
              return authState.token ? (
                compareRolePrivileges(
                  props.location.pathname,
                  authState.userAuthorites,
                  customConfig
                ) ? (
                  <Component
                    {...props}
                    setHeaderDimmed={setHeaderDimmed}
                    setActiveItem={setActiveItem}
                  />
                ) : (
                  <Unauthorised />
                )
              ) : (
                <WelcomePage />
              );
            }}
          />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export const AppRouter = props => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/documents" component={Documents} />
        <PrivateRoute exact path="/bookkeeping/booking" component={BookingOverview} />
        <PrivateRoute exact path="/bookkeeping/account" component={AccountOverview} />
        <PrivateRoute exact path="/finances/accounts" component={BankAccounts} />
        <PrivateRoute exact path="/finances/transactions" component={Transactions} />
        <PrivateRoute exact path="/projects/management" component={ProjectManagement} />
        <PrivateRoute exact path="/projects/timetracker" component={ProjectTimeTracker} />
        <PrivateRoute exact path="/projects/roles" component={ProjectRoles} />
        <PrivateRoute exact path="/projects/types" component={ProjectTypes} />
        <PrivateRoute exact path="/projects/statuses" component={ProjectStatuses} />
        <PrivateRoute exact path="/projects/templates" component={ProjectTemplates} />
        <PrivateRoute exact path="/contacts" component={Contacts} />
        <PrivateRoute exact path="/exports/export" component={Exports} />
        <PrivateRoute exact path="/exports/exported" component={Exported} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PublicRoute restricted={true} path="/registration/:uuid" component={Registration} />
        <PublicRoute restricted={false} exact path="*" component={WelcomePage} />
      </Switch>
    </Router>
  );
};
