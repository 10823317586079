import React from "react";
import GlobalForm from "../../Components/Panels/GlobalForm";
import RolesForm from "./RolesForm";
import { myEmptyRole } from "./ProjectRoles.config";
import useValidator from "../../Utils/useValidator";
import Localize from "react-intl-universal";
import { projectRoleSchemma } from "../../Utils/ProjectsSchema";

const RolesRightPanel = props => {
  const {
    currentView,
    setCurrentView,
    currentModule,
    selectedItem,
    callGetAll,
    setActivePaginationPage
  } = props;

  const [parameters, , validateOnSave, validateField, setParameters] = useValidator(
    projectRoleSchemma
  );

  const customProperties = {
    selectedItem,
    setCurrentView,
    currentView,
    currentModule
  };

  const customOnSaveValidation = currentItem => {
    let validateRole = validateOnSave(currentItem);

    if (validateRole) {
      return true;
    }
    return false;
  };

  const customOnChangeValidation = (field, value) => {
    if (parameters && parameters[field] && parameters[field] !== "") {
      validateField(field, value);
    }
  };

  const customErrorHandler = (error, showNotificationMessage) => {
    if (error && error.response && error.response.status === 409) {
      setParameters(prevParameters => {
        return {
          ...prevParameters,
          name: Localize.get("GLOBAL.NOTIFICATIONS.NOT_UNIQUE")
        };
      });
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("SETTINGS.NOTIFICATIONS.ERROR_SAVED")
      });
    } else {
      showNotificationMessage({
        type: "warning",
        serverMessage: Localize.get("AUTHORIZATION.UNHANDLED_ERROR")
      });
    }
  };

  return (
    <>
      {selectedItem && currentView === "edit" && (
        <GlobalForm
          formHeaderText={"SETTINGS.HEADERS.EDIT_ROLE"}
          {...customProperties}
          CustomForm={RolesForm}
          customOnSaveValidation={customOnSaveValidation}
          customOnChangeValidation={customOnChangeValidation}
          parameters={parameters}
          customErrorHandler={customErrorHandler}
        />
      )}

      {currentView === "create" && (
        <GlobalForm
          formHeaderText={"SETTINGS.HEADERS.CREATE_ROLE"}
          {...{
            ...customProperties,
            selectedItem: myEmptyRole
          }}
          CustomForm={RolesForm}
          emptyObject={myEmptyRole}
          customOnSaveValidation={customOnSaveValidation}
          customOnChangeValidation={customOnChangeValidation}
          callGetAll={callGetAll}
          setActivePaginationPage={setActivePaginationPage}
          parameters={parameters}
          customErrorHandler={customErrorHandler}
        />
      )}
    </>
  );
};

export default RolesRightPanel;
