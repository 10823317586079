import React, { useEffect, useState } from "react";
import { FlowChartWithState } from "@mrblenny/react-flow-chart";
import NodeInnerCustom from "./DiagramNodeContent";
import PortCustom from "./DiagramPort";
import { Grid } from "semantic-ui-react";

const DocumentFlowDiagram = props => {
  const { parentContainer } = props;
  const [document] = useState(props.document ? props.document : null);
  const [chartConfig, setChartConfig] = useState(null);
  const setNodePosition = (index, parentPosition, node, nodesCount, rowsCount, rowOrder) => {
    let rowHeight = Math.floor(index / 44) + 1;
    if (nodesCount % 2 === 0) {
      if (index % 2 === 0) {
        node.position = {
          x: parentPosition.x - (rowsCount + 1) * 110,
          y: parentPosition.y + 370 * rowHeight
        };
      } else {
        node.position = {
          x: parentPosition.x + rowsCount * 110,
          y: parentPosition.y + 370 * rowHeight
        };
      }
    } else {
      if (index === 0) {
        node.position = {
          x: parentPosition.x,
          y: parentPosition.y + 370 * rowHeight
        };
      } else if (index % 2 === 0) {
        node.position = {
          x: parentPosition.x - (rowsCount - 1) * 110 - 110,
          y: parentPosition.y + 370 * rowHeight
        };
      } else {
        node.position = {
          x: parentPosition.x + rowsCount * 110 + 110,
          y: parentPosition.y + 370 * rowHeight
        };
      }
    }
  };

  useEffect(() => {
    if (parentContainer && document) {
      var availableWidth = parentContainer.current.offsetWidth;
      const masterNodePosition = {
        x: (availableWidth - 100) / 2,
        y: 100
      };
      //parent node
      const parentDocId = "doc" + document.id;
      let nodesObject = {};
      let linksObject = {};

      nodesObject[parentDocId] = {
        id: parentDocId
      };
      nodesObject[parentDocId]["position"] = masterNodePosition;
      nodesObject[parentDocId]["type"] = "output-only";

      nodesObject[parentDocId]["ports"] = {
        port1: {
          id: "port1",
          type: "output"
        }
      };

      nodesObject[parentDocId]["documentData"] = {
        id: document.id,
        docNo: document.documentNumber,
        date: document.documentDate,
        type: document.documentType,
        direction: document.direction,
        binaryContent: document.binaryContent,
        docLevel: document.docLevel ? document.docLevel : "children"
      };

      document.position = masterNodePosition;

      configureNodes(document.id, document.documents, masterNodePosition, nodesObject, linksObject);

      let configObject = {
        offset: {
          x: 0,
          y: 0
        },
        nodes: nodesObject,
        links: linksObject,
        selected: {},
        hovered: {}
      };
      setChartConfig(configObject);
    }
  }, []);

  const configureNodes = (currentId, array, masterNodePosition, nodesObject, linksObject) => {
    if (array.length > 0) {
      let rowOrder = 1;
      array.forEach(function(doc, index, array) {
        const rowsCount = index % 44;

        setNodePosition(index, masterNodePosition, doc, array.length, rowsCount, rowOrder);
        rowOrder++;

        const nodeId = "doc" + doc.id;
        nodesObject[nodeId] = {
          id: nodeId
        };
        nodesObject[nodeId]["position"] = doc.position;
        nodesObject[nodeId]["type"] = "input";
        let linkObject = {};

        if (doc.parentDocument && doc.parentDocument.id) {
          linkObject = {
            id: doc.parentDocument.id + "link" + doc.id,
            from: {
              nodeId: "doc" + doc.parentDocument.id,
              portId: "port1"
            },
            to: {
              nodeId: nodeId,
              portId: "port2"
            }
          };
          linksObject[doc.parentDocument.id + "link" + doc.id] = linkObject;
        } else {
          linkObject = {
            id: document.id + "link" + doc.id,
            from: {
              nodeId: "doc" + document.id,
              portId: "port1"
            },
            to: {
              nodeId: nodeId,
              portId: "port2"
            }
          };
          linksObject[document.id + "link" + doc.id] = linkObject;
        }

        doc["ports"] = {};
        doc["ports"]["port2"] = {
          id: "port2",
          type: "input"
        };

        if (doc.documents && doc.documents.length > 0) {
          doc["ports"]["port1"] = {
            id: "port1",
            type: "output"
          };

          nodesObject[nodeId]["type"] = "input-output";
        }

        nodesObject[nodeId]["ports"] = doc["ports"];

        nodesObject[nodeId]["documentData"] = {
          id: doc.id,
          docNo: doc.documentNumber,
          date: doc.documentDate,
          type: doc.documentType,
          direction: doc.direction,
          binaryContent: doc.binaryContent,
          docLevel: doc.docLevel ? doc.docLevel : "children"
        };
        if (doc && doc.docPropagation) {
          configureNodes(doc.id, doc.documents, doc.position, nodesObject, linksObject);
        }
      });
    } else {
      return true;
    }
  };

  let configDD = null;
  if (chartConfig) {
    configDD = {
      ...chartConfig
    };

    for (const key in configDD.nodes) {
      configDD.nodes[key].handler = props.handler;
      configDD.nodes[key].viewFlow = props.viewFlow;
      configDD.nodes[key].enableCompare = props.enableCompare;
      if (
        props.selectedDocuments.find(
          selDoc => selDoc === configDD.nodes[key].documentData.binaryContent.id
        )
      ) {
        configDD.nodes[key].checked = true;
      } else {
        configDD.nodes[key].checked = false;
      }
    }
  }

  return (
    <Grid>
      {chartConfig && (
        <FlowChartWithState
          initialValue={configDD}
          Components={{
            NodeInner: NodeInnerCustom,
            Port: PortCustom
          }}
          config={{ readonly: true }}
        />
      )}
    </Grid>
  );
};

export default DocumentFlowDiagram;
