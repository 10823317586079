import React from "react";
import { Button } from "semantic-ui-react";
import propTypes from "prop-types";
import GlobalCancelBtn from "./GlobalCancelBtn";
import styles from "./style/GlobalOkCancelBtn.module.css";

const GlobalOkCancelBtn = props => {
  const {
    onConfirmClicked = function(arg) {},
    disabledBtn,
    onCancel = function(arg) {},
    btnText
  } = props;

  return (
    <>
      <div className={styles.button_container}>
        <Button
          className={styles.confirm_btn}
          disabled={disabledBtn ? disabledBtn : false}
          onClick={onConfirmClicked}
          positive
          labelPosition="right"
          icon="checkmark"
          content={btnText}
        />
        <GlobalCancelBtn onCancel={onCancel} />
      </div>
    </>
  );
};

GlobalOkCancelBtn.prototype = {
  onCancel: propTypes.func,
  disabledBtn: propTypes.bool,
  onConfirmClicked: propTypes.func,
  btnText: propTypes.string
};
export default GlobalOkCancelBtn;
