import axios from "axios";
import { getLocalStorage, logOutOffUnauthorizedCall, getSession } from "../Auth/AuthContext";
import { get } from "lodash";

const getDefaultConfig = ({ path = "", params = {}, data = {}, url = "" }) => {
  const auth = getLocalStorage("auth") || {};
  const session = getSession();
  const { token } = auth;
  const myBaseUrl = `${process.env.REACT_APP_API_LINK}${path}`;
  return {
    baseURL: myBaseUrl,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Session: session
    },
    params,
    data,
    url
  };
};

const myErrorFunc = response => {
  let msg = get(response, "data.message");
  let status = get(response, "status");
  if (msg && ((status === 401 && (msg === "logout" || msg === "expired")) || status === 500)) {
    if (status === 500) {
      msg = "error";
    }
    logOutOffUnauthorizedCall(msg);
  }
};

export const fetchDefaultTemplate = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    err => {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: "GET" });
};

export const patchOrPostService = (url = "", params, postOrPatchMethod = "PUT") => {
  const instance = axios.create(getDefaultConfig({ url, params }));
  instance.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: postOrPatchMethod });
};
