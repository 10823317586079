import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Accordion, List, Icon, Transition } from "semantic-ui-react";
import styles from "./style/DetailsContactPerson.module.css";

const DetailsContactPerson = props => {
  const [activeAccordion, setActiveAccordion] = useState(props.open);

  const handleAccordionClick = (e, titleProps) => {
    return setActiveAccordion(!activeAccordion);
  };

  useEffect(() => {
    setActiveAccordion(props.open);
  }, [props.contactPerson]);

  return props.contactPerson !== undefined ? (
    <Accordion
      styled
      className={`${styles.contact_person_details} ${styles.details_align_long_name}`}
    >
      <Accordion.Title
        className={styles.contact_person_header}
        active={activeAccordion}
        onClick={handleAccordionClick}
      >
        <Icon name="dropdown" />
        {props.contactPerson.name}
      </Accordion.Title>
      <Transition visible={activeAccordion} animation="fade down" duration={300}>
        <Accordion.Content active={activeAccordion}>
          <List divided verticalAlign="middle" className={styles.details_devided}>
            <List.Item key={1}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.NEWCONTACT.FORM.JOB_TITLE")}</strong>
              </List.Content>
              <List.Content>{props.contactPerson.jobTitle}</List.Content>
            </List.Item>
            <List.Item key={2}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.PHONE")}</strong>
              </List.Content>
              <List.Content>{props.contactPerson.phone}</List.Content>
            </List.Item>
            <List.Item key={3}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.MOBILE")}</strong>
              </List.Content>
              <List.Content>{props.contactPerson.mobile}</List.Content>
            </List.Item>
            <List.Item key={4}>
              <List.Content>
                <strong>{Localize.get("CONTACTS.DETAILS.FORM.EMAIL")}</strong>
              </List.Content>
              <List.Content>{props.contactPerson.email}</List.Content>
            </List.Item>
          </List>
        </Accordion.Content>
      </Transition>
    </Accordion>
  ) : null;
};

export default DetailsContactPerson;
