import React, { useState, useEffect, useRef } from "react";
import Localize from "react-intl-universal";
import { SemanticToastContainer } from "react-semantic-toasts";
// context
import { AuthContextProvider } from "./Auth/AuthContext";
import customConfig from "./config.json";

// router
import { AppRouter } from "./Router/Router";

const locales = {
  en: require("./locales/en-us.json"),
  de: require("./locales/de-de.json")
};

const App = props => {
  const langauge = navigator.language.toLowerCase().includes("de") ? "de" : "en";

  const getLocalStorage = key => {
    let data = window.localStorage.getItem("store") || null;
    data = JSON.parse(data);
    if (!data) return null;
    return key in data ? data[key] : null;
  };

  const authObj = getLocalStorage("auth");

  const loadLocales = myLang => {
    Localize.init({
      currentLocale:
        myLang && myLang.code
          ? myLang.code
          : authObj && authObj.user && authObj.user.language && authObj.user.language.code
          ? authObj.user.language.code
          : langauge,
      locales
    }).then(() => {
      setLocalizeInitDone(true);
      document.title = Localize.get("APPLICATION.TITLE");
    });
  };
  const toastElement = useRef(null);
  const [localizeInitDone, setLocalizeInitDone] = useState(false);
  useEffect(() => {
    loadLocales();
  });

  useEffect(() => {
    window.addEventListener(
      "dragenter",
      function(e) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "none";
        e.dataTransfer.dropEffect = "none";
      },
      false
    );

    window.addEventListener("dragover", function(e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.dropEffect = "none";
    });

    window.addEventListener("drop", function(e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.dropEffect = "none";
    });
  }, []);

  return (
    <AuthContextProvider
      toastElement={toastElement}
      loadLocales={loadLocales}
      customConfig={customConfig.ffo}
    >
      {localizeInitDone && <AppRouter />}
      <SemanticToastContainer ref={toastElement} position="bottom-right" />
    </AuthContextProvider>
  );
};

export default App;
