import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../Auth/AuthContext";
import { Modal, Button, Sidebar, Segment, Dimmer, Loader } from "semantic-ui-react";
import ProjectStatusesSideBar from "./LeftPanel/ProjectStatusesSideBar";
import ProjectStatusesItemList from "./MiddlePanel/ProjectStatusesItemList";
import RightPanel from "./RightPanel/RightPanel";
import * as constant from "./Constants";
import { createNewStatus, getUpdateObject } from "./Util.js";
import * as RestService from "../../RestService";
import showNotification from "../../Notifications/NotificationFunction";
import * as notificationConstants from "../../Notifications/Constants";
import Localize from "react-intl-universal";
import styles from "./ProjectStatuses.module.css";

const ProjectStatuses = props => {
  // eslint-disable-next-line no-unused-vars
  const { authState, rolePrivileges } = useContext(AuthContext);
  const externalStatusPrivileges = rolePrivileges["projectExternalStatus"];
  const [isLoading, setIsLoading] = useState(false);

  const [panelState, setPanelState] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [externalStatuses, setExternalStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sideBarVisibility, setSideBarVisibility] = useState(false);

  const settingVisibleSidebar = () => {
    setSideBarVisibility(true);
  };

  const { setHeaderDimmed } = props;

  setHeaderDimmed(editMode);

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: Localize.get("NOTIFICATIONS.NOTIFICATIONMESSAGES.EXTERNAL_STATUS"),
      ...param
    });
  };

  const callbackGetAllSuccess = response => {
    const statuses = response.data;

    if (statuses.length > 0) {
      if (selectedStatus === undefined) {
        setSelectedStatus(statuses[0]);
      }
      setExternalStatuses(statuses.filter(status => status.id !== "66_undefined"));
    }

    if (deleteStatus !== null) {
      if (deleteStatus === externalStatuses[0].id) {
        setSelectedStatus(statuses[0]);
      } else {
        let externalStatusIds = externalStatuses.map(status => status.id);
        let indexPosition = externalStatusIds.indexOf(deleteStatus);
        setSelectedStatus(statuses[indexPosition - 1]);
      }
    }
  };

  const callbackGetAllError = response => {};

  const callbackGetAllFinally = response => {
    setIsLoading(false);
    setDeleteStatus(null);
  };

  const callbackCreateSuccess = response => {
    getAllExternalStatusService();
    setSelectedStatus(response.data);
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.create
    });
  };

  const callbackCreateError = response => {
    showNotificationMessage({
      serverMessage: Localize.get(
        `NOTIFICATIONS.NOTIFICATIONCUSTOM.${response.response.data.message}`
      ),
      type: notificationConstants.notificationType.warning,
      operation: notificationConstants.notificationOperation.createFailed
    });
  };

  const callbackCreateFinally = response => {};

  const callbackDeleteSuccess = response => {
    getAllExternalStatusService();
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.delete
    });
  };

  const callbackDeleteError = response => {
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      operation: notificationConstants.notificationOperation.delete
    });
  };

  const callbackDeleteFinally = response => {};

  const callbackUpdateSuccess = response => {
    getAllExternalStatusService();
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.update
    });
  };

  const callbackUpdateError = response => {
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      operation: notificationConstants.notificationOperation.update
    });
  };

  const callbackUpdateFinally = response => {};

  const getAllExternalStatusService = data => {
    const serviceCreate = new RestService.ProjectStatuses.GetAll.Service();
    serviceCreate.setCallbackSuccess(callbackGetAllSuccess);
    serviceCreate.setCallbackError(callbackGetAllError);
    serviceCreate.setCallbackFinally(callbackGetAllFinally);

    serviceCreate.call();
  };

  const createExternalStatusService = async data => {
    const serviceCreate = new RestService.ProjectStatuses.Create.Service();
    serviceCreate.setCallbackSuccess(callbackCreateSuccess);
    serviceCreate.setCallbackError(callbackCreateError);
    serviceCreate.setCallbackFinally(callbackCreateFinally);
    serviceCreate.setPayload(data);
    await serviceCreate.call();
    setIsLoading(false);
  };

  const updateExternalStatusService = async data => {
    const statusId = selectedStatus.id;
    const serviceCreate = new RestService.ProjectStatuses.Update.Service(statusId);
    serviceCreate.setPayload(data);
    serviceCreate.setCallbackSuccess(callbackUpdateSuccess);
    serviceCreate.setCallbackError(callbackUpdateError);
    serviceCreate.setCallbackFinally(callbackUpdateFinally);
    await serviceCreate.call();
    setIsLoading(false);
  };

  const deleteStatusServiceCall = async id => {
    const deleteStatusService = new RestService.ProjectStatuses.Delete.Service(id);
    deleteStatusService.setPayload();
    deleteStatusService.setCallbackSuccess(callbackDeleteSuccess);
    deleteStatusService.setCallbackError(callbackDeleteError);
    deleteStatusService.setCallbackFinally(callbackDeleteFinally);
    await deleteStatusService.call();
    setIsLoading(false);
  };

  useEffect(() => {
    // disable back button
    window.history.pushState(null, null, null);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, null, null);
      return;
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAllExternalStatusService();
  }, []);

  const handle = {
    statusesPanel: {
      statusesFormPanel: {
        onSaveButtonClick: newStatus => {
          setIsLoading(true);
          if (panelState.activeMode === constant.panelMode.create) {
            createExternalStatusService(createNewStatus(newStatus));
          }
          if (panelState.activeMode === constant.panelMode.edit) {
            updateExternalStatusService(getUpdateObject(newStatus));
          }
          setPanelState({
            ...panelState,
            activeMode: constant.panelMode.details
          });
          setEditMode(false);
        },
        onSaveAndNewButtonClick: newStatus => {
          createExternalStatusService(createNewStatus(newStatus));
          setPanelState({
            ...panelState,
            activeMode: constant.panelMode.create
          });
          setEditMode(true);
        },
        onCancelButtonClick: () => {
          setPanelState({
            ...panelState,
            activeMode: constant.panelMode.details
          });
          setEditMode(false);
        }
      }
    },
    statusesSideBar: {
      createNewStatusButtonClick: (event, data) => {
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.create
        });
        setEditMode(true);
        setSideBarVisibility(false);
      }
    },
    statusesList: {
      onListItemClick: itemId => {
        setSelectedStatus(
          externalStatuses.find(selected => (selected.id === itemId ? selected : ""))
        );
      },
      onDeleteItemClick: itemId => {
        setDeleteStatus(itemId);
        setShowModal(true);
      },
      onEditItemClick: id => {
        setSelectedStatus(
          externalStatuses.find(selected => (selected.id === id ? selected : null))
        );
        setPanelState({
          ...panelState,
          activeMode: constant.panelMode.edit
        });
        setEditMode(true);
      }
    }
  };

  const handleDeleteConfirmButtonClick = () => {
    setIsLoading(true);
    deleteStatusServiceCall(deleteStatus);
    setShowModal(false);
  };

  const handleOnCancelButtonClick = () => {
    setShowModal(false);
  };

  const getDialog = () => {
    let dialog = null;
    let modalColor = "#c00";

    let modalHeader = Localize.get("MODAL.CONFIRM_DELETE");
    if (showModal) {
      dialog = (
        <Modal
          size="tiny"
          dimmer="inverted"
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header style={{ backgroundColor: modalColor, color: "white" }}>
            {modalHeader}
          </Modal.Header>
          <Modal.Actions>
            <Button onClick={handleOnCancelButtonClick} secondary>
              {Localize.get("GLOBAL.FORM.CANCEL")}
            </Button>
            <Button
              onClick={handleDeleteConfirmButtonClick}
              positive
              labelPosition="right"
              icon="checkmark"
              content={Localize.get("MODAL.MODAL_CONFIRM")}
            />
          </Modal.Actions>
        </Modal>
      );
    }
    return dialog;
  };

  let class1 = "ui container fluid finances_bank_transactions new_bank_account";
  let class2 = "ui two column stackable grid";

  return (
    <>
      <div className={class1}>
        <Dimmer active={isLoading || editMode} inverted>
          <Loader size="large" disabled={!isLoading}>
            {Localize.get("PROJECTS.STATUSES.PROJECT_STATUSES_LOADER")}
          </Loader>
        </Dimmer>
        <div
          className={`ui right attached button ${styles.bms_fixed} ${
            sideBarVisibility || editMode ? styles.bms_zindex : null
          }`}
          onClick={settingVisibleSidebar}
        >
          <i className="ellipsis vertical icon"></i>
        </div>

        <Sidebar.Pushable
          as={Segment}
          className={editMode ? styles.bms_segment_zi : styles.bms_segment}
        >
          <ProjectStatusesSideBar
            externalStatusPrivileges={externalStatusPrivileges}
            handle={handle.statusesSideBar}
            sideBarVisibility={sideBarVisibility}
            setSideBarVisibility={setSideBarVisibility}
          />
          <Sidebar.Pusher
            dimmed={sideBarVisibility}
            className={editMode ? styles.bms_zi : styles.pusher_height}
          >
            <div className={styles.bms_statuses_content}>
              <div className={class2}>
                <ProjectStatusesItemList
                  externalStatuses={externalStatuses}
                  externalStatusPrivileges={externalStatusPrivileges}
                  state={selectedStatus}
                  handle={handle.statusesList}
                  editMode={editMode}
                />
                <RightPanel
                  state={panelState}
                  editMode={editMode}
                  externalStatusPrivileges={externalStatusPrivileges}
                  handle={handle.statusesPanel}
                  selectedStatus={selectedStatus}
                  updateSelectedStatus={setSelectedStatus}
                />
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <div>{getDialog()}</div>
      </div>
    </>
  );
};
export default ProjectStatuses;
