import React from "react";
import { Grid, Message, Icon, Dimmer } from "semantic-ui-react";
import ProjectStatusesItem from "./ProjectStatusesItem";
import Localize from "react-intl-universal";
import { localizeStatus } from "../../Management/Util";
import styles from "./style/ProjectStatusesItemList.module.css";

const ProjectStatusesItemList = props => {
  const { externalStatusPrivileges, externalStatuses, handle, state } = props;
  // TODO Mapping through list setup

  const { editMode } = props;

  const statusesList = externalStatuses.map(status => (
    <ProjectStatusesItem
      key={status.id}
      externalStatus={status}
      handle={handle}
      externalStatusPrivileges={externalStatusPrivileges}
      onEditItemClick={handle.onEditItemClick}
      state={state}
      onDeleteItemClick={handle.onDeleteItemClick}
      bmsStatus={localizeStatus(status.status.name)}
    />
  ));

  return (
    <div
      className={
        editMode
          ? `ten wide column ${styles.list_margin}`
          : `sixteen wide column ${styles.list_margin}`
      }
    >
      <Dimmer active={editMode} inverted></Dimmer>
      <Grid
        stackable
        verticalAlign="middle"
        className={`table_header_projects ${styles.header_padding}`}
      >
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <h5>{Localize.get("PROJECTS.STATUSES.EXTERNAL_STATUS_ID")}</h5>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <h5>{Localize.get("PROJECTS.STATUSES.EXTERNAL_STATUS_NAME")}</h5>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <h5>{Localize.get("PROJECTS.STATUSES.BMS_STATUS_NAME")}</h5>
            </div>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className="content">
              <h5>{Localize.get("PROJECTS.STATUSES.EDIT_DELETE")}</h5>
            </div>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
      </Grid>
      {statusesList.length > 0 ? (
        <div className={`ui vertical menu ${styles.statuses_list} ${styles.vertical_menu}`}>
          {statusesList}
        </div>
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("PROJECTS.STATUSES.EMPTY")}
        </Message>
      )}
    </div>
  );
};

export default ProjectStatusesItemList;
