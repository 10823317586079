import React from "react";
import { Input, Popup, Label } from "semantic-ui-react";
import propTypes from "prop-types";
import styles from "./style/TableCellInputOut.module.css";

const TableCellInputOut = props => {
  const {
    editedIndex,
    inputValue = "",
    inputName,
    index,
    customLabelClassName = "",
    customInputClassName = "",
    onCellChange = function(arg) {},
    onCellBlur = function(arg) {},
    validatorBlur,
    validator,
    showPopup = false,
    validationUpload,
    hasError
  } = props;
  const errorBorder = { border: "1px red solid", borderRadius: "5px" };

  return (
    <>
      {editedIndex === index ? (
        <>
          {showPopup ? (
            <Popup
              content={inputValue}
              wide
              trigger={
                <Input
                  name={inputName}
                  index={index}
                  autoComplete="off"
                  className={`${styles.input_style} ${customInputClassName}`}
                  fluid
                  onBlur={onCellBlur}
                  value={inputValue ? inputValue : ""}
                  onChange={onCellChange}
                  style={
                    (editedIndex === index &&
                      validatorBlur &&
                      validatorBlur.parameter[inputName]) ||
                    (validator && validator.parameter[inputName]) ||
                    validationUpload ||
                    hasError
                      ? errorBorder
                      : {}
                  }
                />
              }
            />
          ) : (
            <Input
              name={inputName}
              index={index}
              className={`${styles.input_style} ${customInputClassName}`}
              fluid
              onBlur={onCellBlur}
              autoComplete="off"
              value={inputValue ? inputValue : ""}
              onChange={onCellChange}
              style={
                (editedIndex === index && validatorBlur && validatorBlur.parameter[inputName]) ||
                (validator && validator.parameter[inputName]) ||
                validationUpload ||
                hasError
                  ? errorBorder
                  : {}
              }
            />
          )}
        </>
      ) : (
        <>
          {showPopup ? (
            <Popup
              content={inputValue}
              size="tiny"
              trigger={
                <Label
                  name={inputName}
                  index={index}
                  className={`${styles.label_style} ${customLabelClassName}`}
                  fluid="true"
                  transparent="true"
                  content={inputValue}
                  style={
                    (editedIndex === index && validator && validator.parameter[inputName]) ||
                    validationUpload ||
                    hasError
                      ? errorBorder
                      : {}
                  }
                />
              }
            />
          ) : (
            <Label
              name={inputName}
              index={index}
              className={`${styles.label_style} ${customLabelClassName}`}
              fluid="true"
              transparent="true"
              content={inputValue}
              style={
                (editedIndex === index && validator && validator.parameter[inputName]) ||
                validationUpload ||
                hasError
                  ? errorBorder
                  : {}
              }
            />
          )}
        </>
      )}
    </>
  );
};

TableCellInputOut.propTypes = {
  editedIndex: propTypes.oneOfType([propTypes.number, propTypes.string]),
  inputValue: propTypes.oneOfType([propTypes.number, propTypes.string]),
  inputName: propTypes.string,
  index: propTypes.number,
  customLabelClassName: propTypes.string,
  customInputClassName: propTypes.string,
  customInputStyle: propTypes.object,
  customLabelStyle: propTypes.object,
  fluid: propTypes.bool,
  popupText: propTypes.oneOfType([propTypes.number, propTypes.string])
};
export default TableCellInputOut;
