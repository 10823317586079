import React from "react";
import { Icon, Message, Input, Dropdown, Dimmer } from "semantic-ui-react";
import ProjectManagementItem from "./ProjectManagementItem";
import Localize from "react-intl-universal";
import * as constant from "../Constants";
import ListWithPagination from "../../../Components/ListWithPagination";
import ToolBar from "../../../Components/ToolBar";
import styles from "./style/ProjectManagementList.module.css";

const ProjectManagementList = props => {
  const {
    privileges,
    selectAll,
    editMode,
    onArchiveButtonCLick,
    paginationStatus,
    handlePageChange,
    activePaginationPage
  } = props;
  const { name, sortDirection, sortColumn, number, search } = props.state;

  const { onSearchChange } = props.handlerHeader;

  const projectList = props.projects.map(r => (
    <ProjectManagementItem
      key={r.id}
      project={r}
      onListItemClick={props.handlerItem.onListItemClick}
      onListToggleClick={props.handlerHeader.onToggleItem}
      onEditItemClick={props.handlerItem.onEditItemClick}
      state={props.state}
      onDeleteItemClick={props.handlerItem.onDeleteItemClick}
    />
  ));

  const resetSearches = async event => {
    event.preventDefault();
    await props.searchFunctions.resetFiltersSearchsAndSorts();
    props.handlerHeader.onReset();
  };

  const resetToolbarSearch = (e, data) => {
    onSearchChange(e, data, true);
  };

  const handleOnDeleteClick = (event, data) => {
    event.preventDefault();
    props.handlerHeader.onDeleteClick(constant.deleteForm.toolbar);
  };

  return (
    <div className={`ten wide column ${styles.items_list} ${styles.project_list}`}>
      <Dimmer active={editMode} inverted></Dimmer>
      <div className={styles.header}>
        <ToolBar
          emptyList={!(projectList.length > 0)}
          searchName={onSearchChange}
          enteredValue={search}
          resetSearches={resetSearches}
          onArchiveClick={onArchiveButtonCLick}
          archiveOrUnarchive={props.archivedFilter}
          onToggleAll={props.handlerHeader.onToggleAll}
          privileges={privileges}
          selectAll={selectAll}
          resetNameSearch={resetToolbarSearch}
          onDeleteClick={handleOnDeleteClick}
          // deleteEnable is prepared for backend rules integration, see example on documents module
          deleteEnable={true}
          popupContentText={Localize.get("DOCUMENTS.THUMBNAILS.SEARCH_PROJECT_MANAGEMENT_POPUP")}
          disableSearch={number !== "" || name !== ""}
        />
      </div>
      <div
        className={`ui five column stackable middle aligned grid container ${styles.table_header_projects}`}
      >
        <div className="row">
          <div className="one wide column" />
          <div className="three wide column">
            <Dropdown
              multiple
              icon={null}
              trigger={
                <div className={styles.dropdown_trigger}>
                  <h5> {Localize.get("PROJECTS.MANAGEMENT.PROJECT_NUMBER_ONLY")}</h5>
                  <div>
                    {sortDirection === "desc" && sortColumn === "number" ? (
                      <Icon name="angle down" />
                    ) : (
                      ""
                    )}
                    {sortDirection === "asc" && sortColumn === "number" ? (
                      <Icon name="angle up" />
                    ) : (
                      ""
                    )}
                    {number !== "" ? <Icon name="filter" /> : ""}
                  </div>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="sort amount up"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                  onClick={() => props.handlerHeader.onSortButtonClick("number", "asc")}
                />
                <Dropdown.Item
                  icon="sort amount down"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                  onClick={() => props.handlerHeader.onSortButtonClick("number", "desc")}
                />
                <Dropdown.Divider />
                <Input
                  name="number"
                  disabled={search !== "" && search !== null}
                  icon="search"
                  iconPosition="left"
                  className="search"
                  placeholder={"Number..."}
                  value={number}
                  onClick={e => e.stopPropagation()}
                  onChange={onSearchChange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="five wide column">
            <Dropdown
              multiple
              icon={null}
              trigger={
                <div className={styles.dropdown_trigger}>
                  <h5>{Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME")}</h5>
                  <div>
                    {sortDirection === "desc" && sortColumn === "name" ? (
                      <Icon name="angle down" />
                    ) : (
                      ""
                    )}
                    {sortDirection === "asc" && sortColumn === "name" ? (
                      <Icon name="angle up" />
                    ) : (
                      ""
                    )}
                    {name !== "" ? <Icon name="filter" /> : ""}
                  </div>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="sort amount up"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_ASC")}
                  onClick={() => props.handlerHeader.onSortButtonClick("name", "asc")}
                />
                <Dropdown.Item
                  icon="sort amount down"
                  text={Localize.get("CONTACTS.LIST.HEADER.SORT_NAME_DESC")}
                  onClick={() => props.handlerHeader.onSortButtonClick("name", "desc")}
                />
                <Dropdown.Divider />
                <Input
                  name="name"
                  icon="search"
                  disabled={search !== "" && search !== null}
                  iconPosition="left"
                  className="search"
                  placeholder={Localize.get("PROJECTS.MANAGEMENT.PROJECT_NAME_ONLY")}
                  value={name}
                  onClick={e => e.stopPropagation()}
                  onChange={onSearchChange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="three wide column">
            <h5> {Localize.get("PROJECTS.MANAGEMENT.PROJECT_TEMPLATE")}</h5>
          </div>
          <div className="three wide column">
            <h5> {Localize.get("PROJECTS.MANAGEMENT.PROJECT_TYPE")}</h5>
          </div>
        </div>
      </div>
      {projectList.length > 0 ? (
        <ListWithPagination
          list={projectList}
          state={props.projectState}
          defaultActivePage={paginationStatus.pageCount}
          totalPage={paginationStatus.totalCount}
          handlePageChange={handlePageChange}
          activePaginationPage={activePaginationPage}
          perPage={paginationStatus.perPage}
        />
      ) : (
        <Message icon>
          <Icon name="info circle" />
          {Localize.get("PROJECTS.MANAGEMENT.EMPTY_LIST")}
        </Message>
      )}
    </div>
  );
};

export default ProjectManagementList;
