import axios from "axios";
import { convert2Contacts } from "../Contacts/Util";
import { getLocalStorage, logOutOffUnauthorizedCall, getSession } from "../Auth/AuthContext";

import { get } from "lodash";

const getDefaultConfig = ({ path = "", params = {}, data = {}, url = "" }) => {
  const auth = getLocalStorage("auth") || {};
  const session = getSession();
  const { token } = auth;

  const myBaseUrl = `${process.env.REACT_APP_API_LINK}${path}`;
  return {
    baseURL: myBaseUrl,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      Session: session
    },
    params,
    data,
    url
  };
};

const myErrorFunc = response => {
  if (
    response &&
    response.data &&
    response.data.message &&
    ((response.status === 401 &&
      (response.data.message === "logout" || response.data.message === "expired")) ||
      response.status === 500)
  ) {
    if (response.status === 500) {
      response.data.message = "error";
    }
    logOutOffUnauthorizedCall(response.data.message);
  }
};

export const fetchPartners = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));
  instance.interceptors.response.use(
    function(response) {
      return {
        data: convert2Contacts(response.data.data),
        paging: response.data.paging
      };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: "GET" });
};

export const fetchPartner = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    function(response) {
      return {
        data: convert2Contacts([response.data])
      };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: "GET" });
};

export const fetchTemplates = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    function(response) {
      return { data: response.data.templates };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: "GET" });
};

export const postPartner = (data, url = "", postOrPatchMethod = "POST") => {
  const instance = axios.create(getDefaultConfig({ data, url }));

  instance.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      myErrorFunc(err.response);
      return err;
    }
  );

  return instance({ method: postOrPatchMethod });
};

export const partnerSynchronizeFFO = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));
  return instance({ method: "POST" });
};

export const fetchDocTypesService = (path, params) => {
  const instance = axios.create(getDefaultConfig({ path, params }));

  instance.interceptors.response.use(
    function(response) {
      let res = get(response, "data.subCategories").sort(function(a, b) {
        return a.id - b.id;
      });

      return { data: res };
    },
    function(err) {
      myErrorFunc(err.response);
      return err;
    }
  );
  return instance({ method: "GET" });
};
