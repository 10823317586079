import React, { useState, useEffect } from "react";
import styles from "./style/ProjectsPanel.module.css";
import { Loader, Dimmer, Message, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import * as RestService from "../../RestService";

import { repackProjects } from "../Util.js";
import EditProjectForm from "./DocumentFormComponents/EditProjectForm";
import DocNewProjectTable from "./DocumentFormComponents/DocNewProjectTable";
import DocNewProjectForm from "./DocumentFormComponents/DocNewProjectForm";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";

import WarningOkDialog from "../../Dialogs/WarningOkDialog";

const ProjectsPanel = props => {
  const { projectPrivileges, document } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [projects, setProjects] = useState(props.projects ? props.projects : []);
  const [documentZugfredObject, setDocumentZugfredObject] = useState(
    document === undefined ? {} : document
  );
  const [editingProject, setEditingProject] = useState(false);
  const [editedFields, setEditedFields] = useState([]);
  const [currentProject, setCurrentProject] = useState();
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [editedDropdown, setEditedDropdown] = useState(false);

  const [restAmount, setRestAmount] = useState();

  const [selectedProject, setSelectedProject] = useState({
    projectname: "",
    amount: "",
    description: ""
  });

  const [previousSelectedProject, setPreviousSelectedProject] = useState();

  const callbackGetProjectSuccess = response => {};

  const callbackGetAllError = error => {};

  const callbackGetAllFinally = response => {
    setIsLoading(false);
  };

  const resolveProjectsService = () => {
    let projectService = new RestService.Project.GetAll.Service();
    projectService.setCallbackError(callbackGetAllError);
    projectService.setCallbackFinally(callbackGetAllFinally);
    projectService.setCallbackSuccess(callbackGetProjectSuccess);
    return projectService;
  };

  const handleProjectSearch = (e, data) => {
    if (data.searchQuery.length >= 3) {
      const service = resolveProjectsService();
      service.query.addSearchParam(data.searchQuery);
      service.query.addSearchMatchParam("containing");
      service.query.setPageSize(5);
      service.call();
    }
  };

  const callbackGetAssignProjectsSuccess = response => {
    setSelectedProject({
      amount: response.data.restAmount,
      description: ""
    });

    let projectsArr = response.data.notAssignedProjects;
    const projectLst = projectsArr.map(project => ({
      text: project.projectName,
      projectname: project.projectName,
      amount: "",
      disabled: project.archived,
      icon: project.archived ? "archive" : false,
      value: project.id,
      key: project.id,
      id: project.id,
      projectexternalnumber: project.projectExternalNumber
    }));

    setRestAmount(response.data.restAmount);
    setProjectsOptions(projectLst);
  };

  const callbackGetAssignProjectsError = response => {};
  const callbackGetAssignProjectsFinally = response => {
    setIsLoading(false);
  };

  const addProject = project => {
    let newProjects = [...projects];
    newProjects.push(project);

    setProjects(newProjects);

    setSelectedProject({
      ...selectedProject,
      amount: restAmount
    });

    setDocumentZugfredObject({
      ...documentZugfredObject,
      projects: newProjects
    });

    if (!editedFields.includes("projects")) {
      const newArr = [...editedFields];
      newArr.push("projects");
      setEditedFields(newArr);
    }
  };

  const onSave = () => {
    if (editedDropdown) {
      setOpenWarningDialog(true);
    } else {
      props.handler.documentForm.onUpdateDocumentSave({
        ...documentZugfredObject,
        editedFields: editedFields
      });
    }
  };

  const cancelProject = () => {
    setEditingProject(false);
  };

  const deleteProject = projectId => {
    let deletedProject = projects.filter(project => project.id !== projectId);

    setProjects(deletedProject);
    setDocumentZugfredObject({
      ...documentZugfredObject,
      projects: deletedProject
    });

    if (!editedFields.includes("projects")) {
      const newArr = [...editedFields];
      newArr.push("projects");
      setEditedFields(newArr);
    }
  };

  const updateProject = updatedProject => {
    let newProjects = projects.filter(project => project.id !== previousSelectedProject.id);
    newProjects.push(updatedProject);
    setProjects(newProjects);

    setEditingProject(false);

    setDocumentZugfredObject({
      ...documentZugfredObject,
      projects: newProjects
    });

    if (!editedFields.includes("projects")) {
      const newArr = [...editedFields];
      newArr.push("projects");
      setEditedFields(newArr);
    }
  };

  const editRow = project => {
    setPreviousSelectedProject({
      id: project.id,
      projectname: project.projectname,
      projectexternalnumber: project.projectexternalnumber,
      amount: project.amount
    });
    setEditingProject(true);
    setCurrentProject({
      id: project.id,
      projectname: project.projectname,
      projectexternalnumber: project.projectexternalnumber,
      amount: project.amount,
      description: project.description
    });
  };

  useEffect(() => {
    let payload = repackProjects(projects);
    const serviceGetAssignProjects = new RestService.Document.GetAssignProjects.Service(
      document.id
    );
    serviceGetAssignProjects.setCallbackSuccess(callbackGetAssignProjectsSuccess);
    serviceGetAssignProjects.setCallbackError(callbackGetAssignProjectsError);
    serviceGetAssignProjects.setCallbackFinally(callbackGetAssignProjectsFinally);
    serviceGetAssignProjects.setPayload(payload);
    serviceGetAssignProjects.call();
  }, [documentZugfredObject]);

  useEffect(() => {
    if (projectPrivileges.get) {
      let service = resolveProjectsService();
      service.call();
      setIsLoading(true);
    }
  }, []);

  return (
    <>
      <WarningOkDialog
        modalHeader={Localize.get("MODAL.WARNING")}
        open={openWarningDialog}
        modalContent={Localize.get("MODAL.NOT_ADDED_PROJECT")}
        onOkFunction={() => setOpenWarningDialog(false)}
      />
      <div className={styles.bms_header}>
        {" "}
        {Localize.get("DOCUMENTS.DETAILS.HEADER_PROJECTS_ASSIGNED")}
      </div>
      {isLoading ? (
        <Dimmer active inverted>
          <Loader inverted>{Localize.get("DASHBOARD.LOADING")}</Loader>
        </Dimmer>
      ) : (
        <>
          <div className={styles.project_form}>
            {editingProject ? (
              <>
                <EditProjectForm
                  projectPrivileges={projectPrivileges}
                  currentProject={currentProject}
                  setCurrentProject={setCurrentProject}
                  updateProject={updateProject}
                  cancelProject={cancelProject}
                  projectsOptions={projectsOptions}
                  title={Localize.get("DOCUMENTS.FORM.PROJECT_ASSIGN")}
                  projectNameText={Localize.get("DOCUMENTS.NEWDOCUMENT.PROJECT_ASSIGNED")}
                  projectExternalNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_EXTERNAL")}
                  projectNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_NUMBER")}
                  projectAmountText={Localize.get("GLOBAL.DETAILS.AMOUNT")}
                  setEditedDropdown={setEditedDropdown}
                  restAmount={restAmount}
                  previousSelectedProject={previousSelectedProject}
                />
              </>
            ) : (
              <DocNewProjectForm
                document={document}
                projectPrivileges={projectPrivileges}
                addProject={addProject}
                projectsOptions={projectsOptions}
                handleProjectSearch={handleProjectSearch}
                title={Localize.get("DOCUMENTS.FORM.PROJECT_ASSIGN")}
                projectNameText={Localize.get("DOCUMENTS.FORM.PROJECT")}
                projectExternalNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_EXTERNAL")}
                projectNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_NUMBER")}
                projectAmountText={Localize.get("GLOBAL.DETAILS.AMOUNT")}
                setEditedDropdown={setEditedDropdown}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                restAmount={restAmount}
              />
            )}
            {projects && projects.length > 0 ? (
              <div className="item">
                <div className={`content ${styles.table_width}`}>
                  <DocNewProjectTable
                    projectNameText={Localize.get("DOCUMENTS.NEWDOCUMENT.PROJECT_ASSIGNED")}
                    projectExternalNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_EXTERNAL")}
                    projectNumberText={Localize.get("DOCUMENTS.FORM.PROJECT_NUMBER")}
                    projectAmountText={Localize.get("GLOBAL.DETAILS.AMOUNT")}
                    projectDescription={Localize.get("DOCUMENTS.FORM.DESCRIPTION")}
                    projects={projects}
                    deleteProject={deleteProject}
                    editRow={editRow}
                    editing={editingProject}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Message icon>
                  <Icon name="info circle" className={styles.empty} />
                  {Localize.get("DOCUMENTS.NEWDOCUMENT.NO_PROJECT_ASSIGNED")}
                </Message>
              </div>
            )}
          </div>

          <GlobalSaveNewCancelBtn
            disabledBtn={editingProject}
            onSave={onSave}
            onCancel={props.handler.documentForm.onCancelButtonClick}
          />
        </>
      )}
    </>
  );
};

export default ProjectsPanel;
