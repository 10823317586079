import React from "react";
import { Button, Sidebar, Menu, Container, Icon } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/BankAccountsSideBar.module.css";

const BankAccountsSideBar = props => {
  const { privileges, sideBarVisibility, setSideBarVisibility, handlerButton } = props;

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Container>
          <Button
            className={`${styles.bms_sidebar_button} ${styles.bms_button}`}
            disabled={!privileges.create}
            primary
            onClick={handlerButton.createNewBankAccountButtonHandler}
          >
            {Localize.get("ACCOUNTS.SIDEBAR.NEW_BANK_ACCOUNT")}
            <Icon name="plus" />
          </Button>

          <Button
            className={`${styles.bms_sidebar_button} ${styles.bms_button}  ${styles.bms_paypal_btn}`}
            onClick={handlerButton.createNewPaypalAccountButtonHandler}
            disabled={!privileges.create}
          >
            {Localize.get("ACCOUNTS.SIDEBAR.NEW_PAYPAL_ACCOUNT")}
            <Icon name="plus" />
          </Button>
        </Container>
      </Sidebar>
    </>
  );
};

export default BankAccountsSideBar;
