import Localize from "react-intl-universal";

export const calculateTotalTableChanged = myTotal => {
  let totalBaseAmountTemp =
    formatAmount(myTotal.totalNetAmount) -
    Math.abs(formatAmount(myTotal.totalDiscount)) +
    Math.abs(formatAmount(myTotal.totalSurcharge));

  let totalGrossAmountTemp =
    formatAmount(limitDecimals(totalBaseAmountTemp)) + formatAmount(myTotal.totalTax);

  let amountTemp =
    formatAmount(limitDecimals(totalGrossAmountTemp)) - Math.abs(formatAmount(myTotal.overpayment));

  const currentDate = new Date();
  const earlyPaymentDate =
    myTotal.documentDiscount && myTotal.documentDiscount.earlyPayDate
      ? new Date(myTotal.documentDiscount.earlyPayDate)
      : null;
  let savingsTemp =
    myTotal &&
    myTotal.documentDiscount &&
    myTotal.documentDiscount.earlyPayAmount &&
    currentDate < earlyPaymentDate &&
    formatAmount(myTotal.documentDiscount.earlyPayAmount) !== 0
      ? formatAmount(limitDecimals(amountTemp)) -
        formatAmount(myTotal.documentDiscount.earlyPayAmount)
      : 0;

  let calculatedTotal = {
    totalNetAmount: myTotal.totalNetAmount,
    totalDiscount: myTotal.totalDiscount,
    totalSurcharge: myTotal.totalSurcharge,
    totalBaseAmount: limitDecimals(totalBaseAmountTemp),
    totalTax: myTotal.totalTax,
    totalGrossAmount: limitDecimals(totalGrossAmountTemp),
    overpayment: myTotal.overpayment,
    paymentDue: myTotal.paymentDue,
    amount: limitDecimals(amountTemp),
    documentDiscount: {
      earlyPayAmount: myTotal.documentDiscount.earlyPayAmount,
      savings: limitDecimals(savingsTemp),
      earlyPayDate: myTotal.documentDiscount.earlyPayDate,
      id: myTotal.documentDiscount.id,
      description: myTotal.documentDiscount.description
    }
  };

  return calculatedTotal;
};

export const calculateTotalFormChanged = myTotal => {
  const currentDate = new Date();
  const earlyPaymentDate =
    myTotal.documentDiscount && myTotal.documentDiscount.earlyPayDate
      ? new Date(myTotal.documentDiscount.earlyPayDate)
      : null;
  let savingsTemp =
    myTotal &&
    // myTotal.amount &&
    myTotal.documentDiscount &&
    myTotal.documentDiscount.earlyPayAmount &&
    myTotal.documentDiscount.earlyPayDate &&
    currentDate < earlyPaymentDate &&
    formatAmount(myTotal.documentDiscount.earlyPayAmount) !== 0
      ? formatAmount(myTotal.amount) - formatAmount(myTotal.documentDiscount.earlyPayAmount)
      : 0;

  let calculatedTotal = {
    totalNetAmount: myTotal.totalNetAmount,
    totalDiscount: myTotal.totalDiscount,
    totalSurcharge: myTotal.totalSurcharge,
    totalBaseAmount: myTotal.totalBaseAmount,
    totalTax: myTotal.totalTax,
    totalGrossAmount: myTotal.totalGrossAmount,
    overpayment: myTotal.overpayment,
    paymentDue: myTotal.paymentDue,
    amount: myTotal.amount,
    documentDiscount: {
      earlyPayAmount: myTotal.documentDiscount.earlyPayAmount,
      savings: limitDecimals(savingsTemp),
      earlyPayDate: myTotal.documentDiscount.earlyPayDate,
      id: myTotal.documentDiscount.id,
      description: myTotal.documentDiscount.description
    }
  };

  return calculatedTotal;
};

export const calculateSubtotal = mySubtotal => {
  let newBaseAmount =
    formatAmount(mySubtotal.netAmount) -
    Math.abs(formatAmount(mySubtotal.discount)) +
    Math.abs(formatAmount(mySubtotal.surcharge));

  let newTaxAmount =
    (formatAmount(limitDecimals(newBaseAmount)) * formatAmount(mySubtotal.tax)) / 100;

  let newGrossAmount =
    formatAmount(limitDecimals(newBaseAmount)) + formatAmount(limitDecimals(newTaxAmount));

  let myNewSubtotal = {
    id: mySubtotal.id,
    tax: mySubtotal.tax,
    netAmount: mySubtotal.netAmount,
    surcharge: mySubtotal.surcharge,
    discount: mySubtotal.discount,
    baseAmount: limitDecimals(newBaseAmount),
    calculatedTax: limitDecimals(newTaxAmount),
    grossAmount: limitDecimals(newGrossAmount)
  };

  return myNewSubtotal;
};

export const limitDecimals = amount => {
  let decimalLimit = 2;
  return amount.toFixed(decimalLimit);
};

export const formatAmount = amount => {
  if (typeof amount === "string") {
    if (amount.length === 0 || amount === "-" || isNaN(parseFloat(amount))) {
      amount = 0;
    } else {
      amount = parseFloat(amount.replace(/,/g, "."));
    }
  } else if (amount === null || amount === undefined) {
    amount = 0;
  }
  return amount;
};

export const checkIsValid = val => {
  if (val && val.parameter) {
    return !Object.keys(val.parameter)
      .map(field => {
        return val.parameter[field] !== "" ? false : true;
      })
      .includes(false);
  }
  return true;
};

export const checkIsValidObject = editedLine => {
  return !Object.keys(editedLine)
    .map(field => {
      return editedLine[field] !== "" ? false : true;
    })
    .includes(false);
};

export const checkValidationList = (myList, myIndex, field) => {
  if (myList && myList[myIndex] && myList[myIndex][field] && myList[myIndex][field] !== "") {
    return true;
  } else {
    return false;
  }
};

const getIcon = currency => {
  if (currency) {
    switch (currency.id) {
      case "EUR":
        return "euro sign";
      case "GBR":
        return "pound";
      case "USD":
        return "dollar sign";
      default:
        break;
    }
  } else {
    return "";
  }
};

export const priceOptions = currency => {
  let icon = getIcon(currency);

  return [
    {
      key: 1,
      text: Localize.get("DOCUMENTS.FORM.NETTO_AMOUNT_SHRT"),
      value: false,
      icon: icon
    },
    {
      key: 2,
      text: Localize.get("DOCUMENTS.FORM.BRUTO_AMOUNT_SHRT"),
      value: true,
      icon: icon
    }
  ];
};
