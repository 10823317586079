import React from "react";
import styles from "../style/RolesDetails.module.css";
import GlobalList from "../../Components/Lists/GlobalList";
import GlobalDetailsItem from "../../Components/Details/GlobalDetailsItem";

const RolesDetails = props => {
  const { selectedItem } = props;

  return (
    <>
      <GlobalDetailsItem
        itemName={"SETTINGS.HEADERS.ROLE_NAME"}
        itemValue={selectedItem.name}
        type="text"
      />
      <GlobalList
        listState={selectedItem.permissions}
        listPaginationCustomCSS={styles.permission_item}
        availableItemFieldsForList={[
          {
            name: "name",
            hederName: "SETTINGS.HEADERS.PERMISSION_LIST",
            type: "text",
            width: 12,
            visible: true
          }
        ]}
      />
    </>
  );
};
export default RolesDetails;
