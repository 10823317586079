import React, { useState } from "react";
import { Icon, Segment, Grid, Input, Dimmer, Loader } from "semantic-ui-react";
import Localize from "react-intl-universal";
import GlobalModal from "../../Components/Panels/GlobalModal";
import TemplateList from "./TemplateList";
import styles from "./style/InvoiceNotifiers.module.css";
import { get } from "lodash";
import useDefaultTemplateServices from "../../RestServicesNew/useDefaultTemplateServices";

const InvoiceTemplate = ({
  types,
  header,
  handleOnChange,
  // checkEditedFields,
  // editedFields,
  defaultTemplates,
  loading,
  sendInvoiceToParent,
  contactId
}) => {
  const [showModal, setShowModal] = useState();
  const [categoryKey, setCategoryKey] = useState();

  const { putDefaultTemplate } = useDefaultTemplateServices();

  const addTemplate = async (templId, templName) => {
    let newDefaultTemplate = {
      ...defaultTemplates,
      [categoryKey]: { templateId: templId, name: templName }
    };
    handleOnChange("defaultTemplates", newDefaultTemplate);
    //------Waiting for BE refactoring
    // if (!editedFields.includes("documentTemplates")) {
    //   checkEditedFields("documentTemplates");
    // }
    await putDefaultTemplate(`partners/${contactId}/categories/${categoryKey}/templates`, {
      templateId: templId
    });
    setShowModal(false);
  };

  const removeTemplate = async (e, data) => {
    const categorykey = data.categorykey;
    let newDefaultTemplate = { ...defaultTemplates };
    delete newDefaultTemplate[categorykey];
    handleOnChange("defaultTemplates", newDefaultTemplate);
    //------Waiting for BE refactoring
    // if (!editedFields.includes("documentTemplates")) {
    //   checkEditedFields("documentTemplates");
    // }
    await putDefaultTemplate(`partners/${contactId}/categories/${categorykey}/templates`, {
      templateId: ""
    });
  };

  const checkForTemplateValue = (defaultTemplates = {}, key) => {
    const obj = get(defaultTemplates, key);
    return get(obj, "name") ? get(obj, "name") : "";
  };

  const checkDocType = docType => {
    return docType === "inv" || docType === "inv-cor";
  };

  const handleSearch = (e, data) => {
    setShowModal(true);
    setCategoryKey(get(data, "categorykey"));
  };

  return (
    <>
      {showModal && (
        <GlobalModal
          showModal={showModal ? true : false}
          categoryKey={categoryKey}
          setShowModal={setShowModal}
          addTemplate={addTemplate}
          CustomComponent={TemplateList}
          showActions={false}
          headerText={"SETTINGS.SIDEBAR_OPTIONS.TEMPLATE"}
        />
      )}
      <Segment.Group disabled={sendInvoiceToParent} className={styles.segment_group}>
        <Segment disabled={sendInvoiceToParent} className={styles.title}>
          <strong>{Localize.get(`${header}`)}</strong>
        </Segment>
        <Segment className={styles.form_lines}>
          <Dimmer active={loading} inverted>
            <Loader size="large" disabled={!loading}>
              {Localize.get("PROJECTS.TEMPLATES.PROJECT_TEMPLATE_LOADER")}
            </Loader>
          </Dimmer>
          <Grid>
            {types.map((item, index) => {
              let myValue = checkForTemplateValue(defaultTemplates, item.categoryKey);

              let disableCauseParent = sendInvoiceToParent && checkDocType(item.categoryKey);
              return (
                <Grid.Row
                  key={index}
                  className={types.length - 1 === index ? "" : styles.bottom_padd}
                >
                  <Grid.Column width={5}>
                    {Localize.get(`PROPERTIES.FIELDS.${item.categoryKey}`)}
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Input
                      className={styles.temp_in}
                      placeholder={
                        disableCauseParent
                          ? Localize.get("CONTACTS.DETAILS.FORM.PARENT_TEMPLATE")
                          : Localize.get("CONTACTS.DETAILS.FORM.DEFAULT_TEMPLATE")
                      }
                      // disabled={true}
                      value={myValue}
                      icon={
                        myValue ? (
                          <Icon
                            name="close"
                            categorykey={item.categoryKey}
                            className={styles.temp_in_icon}
                            onClick={removeTemplate}
                            link
                          />
                        ) : (
                          <Icon
                            name="search"
                            categorykey={item.categoryKey}
                            className={disableCauseParent ? "" : styles.temp_in_icon}
                            onClick={handleSearch}
                            link
                            disabled={disableCauseParent}
                          />
                        )
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Segment>
      </Segment.Group>
    </>
  );
};

export default InvoiceTemplate;
