import React from "react";
import GlobalTextInputField from "../../Components/Inputs/GlobalTextInputField";
import { Form } from "semantic-ui-react";
import GlobalTextArea from "../../Components/Inputs/GlobalTextArea";

const TypesForm = props => {
  const {
    editedFields,
    setEditedFields,
    parameters,
    currentItem,
    setCurrentItem,
    customOnChangeValidation
  } = props;

  const handleNameChange = (e, data) => {
    setCurrentItem({ ...currentItem, [data.name]: data.value });
    if (!editedFields.includes(data.name)) {
      let tempArray = editedFields;
      tempArray.push(data.name);
      setEditedFields(tempArray);
    }
    customOnChangeValidation(data.name, data.value);
  };

  return (
    <>
      <Form>
        <GlobalTextInputField
          labelName={"PROJECTS.TYPES.TYPE_NAME"}
          isRequired={true}
          inputName={"name"}
          itemValue={currentItem.name}
          onInputChange={handleNameChange}
          errorFields={parameters}
        />
        <GlobalTextArea
          inputName="description"
          inputValue={currentItem.description}
          labelName={"PROJECTS.TYPES.TYPE_DESCRIPTION"}
          onInputChange={handleNameChange}
          errorFields={parameters}
        />
      </Form>
    </>
  );
};

export default TypesForm;
