import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  parameter = {};
  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor(id) {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.transactions,
      pathParameter: id,
      query: []
    };
    this.query = new CustomQuery.Builder();
  }
}
