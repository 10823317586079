import React from "react";
import ContactDetailsPanel from "./ContactDetailsPanel";
import ContactFormPanel from "./ContactFormPanel";
import { panelMode } from "../Constants.js";
import styles from "./style/ContactPanel.module.css";

const ContactPanel = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    privileges,
    selectedContact,
    activeMode,
    handle,
    setPanelState,
    panelState,
    postPartnerCall,
    fetchPartnerCall,
    postLoading,
    partnerFetchLoading
  } = props;

  const content = (() => {
    let content;
    switch (activeMode) {
      case panelMode.details:
        content = (
          <ContactDetailsPanel
            handle={handle.details}
            privileges={privileges}
            selectedContact={selectedContact}
            partnerFetchLoading={partnerFetchLoading}
          />
        );
        break;
      case panelMode.edit:
        content = (
          <ContactFormPanel
            mode={panelMode.edit}
            selectedContact={selectedContact}
            setPanelState={setPanelState}
            panelState={panelState}
            postPartnerCall={postPartnerCall}
            fetchPartnerCall={fetchPartnerCall}
            postLoading={postLoading}
          />
        );
        break;
      case panelMode.create:
        content = (
          <ContactFormPanel
            mode={panelMode.create}
            selectedContact={selectedContact}
            setPanelState={setPanelState}
            panelState={panelState}
            postPartnerCall={postPartnerCall}
            postLoading={postLoading}
          />
        );
        break;
      default:
        break;
    }

    return content;
  })();

  return (
    <>
      <div className={`six wide column ${styles.right_panel}`}>{content}</div>
    </>
  );
};

export default ContactPanel;
