import * as constant from "../Constants";
import { BaseBuilder, BaseQuery, BaseService } from "../Base";

class CustomBuilder extends BaseBuilder {
  addMinDate(value) {
    this.parameter.minDate = value;
    return this;
  }
  addMaxDate(value) {
    this.parameter.maxDate = value;
    return this;
  }
  clearMinDate() {
    delete this.parameter.minDate;
    return this;
  }
  clearMaxDate() {
    delete this.parameter.maxDate;
    return this;
  }
  addTransactionAssignmentLinked() {
    this.parameter.assignment = constant.TRANSACTION.assignment.linked;
    return this;
  }
  addTransactionAssignmentUnlinked() {
    this.parameter.assignment = constant.TRANSACTION.assignment.unlinked;
    return this;
  }
  addTransactionAssignmentLinkedWithoutDocument() {
    this.parameter.assignment = constant.TRANSACTION.assignment.linkedWithoutDocument;
    return this;
  }
  clearTransactionAssignment() {
    delete this.parameter.assignment;
    return this;
  }
  addTransactionDirectionExpense() {
    this.parameter.direction = constant.TRANSACTION.direction.expense;
    return this;
  }
  addTransactionDirectionRevenue() {
    this.parameter.direction = constant.TRANSACTION.direction.revenue;
    return this;
  }
  clearTransactionDirection() {
    delete this.parameter.direction;
    return this;
  }
  addCustomColumnSearch(column, value) {
    this.parameter[column] = value;
    return this;
  }
  clearCustomColumnSearch(value) {
    delete this.parameter[value];
    return this;
  }
  addSortColumnParam(value) {
    this.parameter.sortBy = value;
    return this;
  }
  clearSortColumnParam(value) {
    delete this.parameter.sortBy;
    return this;
  }
  addSortDirectionParam(value) {
    this.parameter.sortDirection = value;
    return this;
  }
  clearSortDirectionParam(value) {
    delete this.parameter.sortDirection;
    return this;
  }
  build() {
    return new CustomQuery(this.parameter).query;
  }
}

class CustomQuery extends BaseQuery {
  constructor(build) {
    if (build !== undefined) {
      super(build);
    }
    super();
  }
  static get Builder() {
    return CustomBuilder;
  }
}

export class Service extends BaseService {
  constructor() {
    super();
    this.configuration = {
      ...this.configuration,
      method: constant.BASE.method.get,
      resource: constant.BASE.resource.transactions
    };
    this.query = new CustomQuery.Builder();
  }
}
