import React from "react";
import { Pagination, Icon } from "semantic-ui-react";
import * as constant from "../Documents/Constants.js";
import styles from "./style/ListWithPagination.module.css";

const ListWithPagination = props => {
  const {
    list,
    state,
    handlePageChange,
    defaultActivePage,
    totalPage,
    activePaginationPage,
    perPage
  } = props;

  return (
    <>
      <div
        id="listToScroll"
        className={
          state.activeMiddlePanelMode === constant.middlePanelMode.documentForPay
            ? `ui fluid vertical menu ${styles.payListHeight}`
            : `ui vertical menu ${styles.listOfItems}`
        }
      >
        {list}
      </div>
      {defaultActivePage > 1 &&
      state.activeMiddlePanelMode !== constant.middlePanelMode.documentForPay ? (
        <Pagination
          pointing
          secondary
          className={styles.pagination_position}
          activePage={activePaginationPage}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={{
            content: <Icon disabled={activePaginationPage === 1} name="angle double left" />
          }}
          lastItem={{
            content: (
              <Icon
                disabled={activePaginationPage === Math.ceil(totalPage / perPage)}
                name="angle double right"
              />
            )
          }}
          prevItem={{
            content: (
              <Icon
                disabled={activePaginationPage === 1}
                name="angle left"
                className={styles.disable_border}
              />
            )
          }}
          nextItem={{
            content: (
              <Icon
                disabled={activePaginationPage === Math.ceil(totalPage / perPage)}
                name="angle right"
              />
            ),
            icon: true
          }}
          totalPages={Math.ceil(totalPage / perPage)}
          onPageChange={(e, pageInfo) => handlePageChange(pageInfo)}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ListWithPagination;
