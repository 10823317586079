// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import * as constant from "./Constants.js";
import * as notificationConstants from "../Notifications/Constants.js";
import * as RestService from "../RestService";
import { getEmptyDocumentObject, getOCRDocumentReadObject } from "./Util.js";

const useCreateDocument = (
  state,
  setState,
  setIsLoadingForm,
  setIsLoadingScansList,
  showNotificationMessage,
  setActivePaginationPage,
  setLinkedDocumentsPreview,
  setThumbsToSend,
  setEditMode,
  documentsPanelState
) => {
  const [originalDocument, setOriginalDocument] = useState(null);
  const [newlyCreatedDocument, setNewlyCreatedDocument] = useState(undefined);
  const [showSameDocNumbModal, setShowSameDocNumbModal] = useState(false);
  const [invalidCorrectionAmount, setInvalidCorrectionAmount] = useState(false);
  const [invalidInvoiceAmount, setInvalidInvoiceAmount] = useState(false);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [isPDFPreview, setIsPDFPreview] = useState(false);

  ////////////////////// CREATE DOCUMENT FROM UPLOAD ////////////////////
  // Service call for creating new document from uploaded file no matter Zugfert or PDF
  const createDocumentFromUploaded = file => {
    var form = new FormData();
    form.append("file", file);

    if (file.type.includes("pdf")) {
      setIsPDFPreview(true);
      setDocumentPreview(URL.createObjectURL(file));
    } else if (file.type.includes("image")) {
      setIsPDFPreview(false);
      let reader = new FileReader();

      reader.onloadend = () => {
        setDocumentPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }

    const serviceCreate = new RestService.Document.CreateFromFile.Service();
    serviceCreate.setCallbackSuccess(callbackCreateSuccess);
    serviceCreate.setCallbackError(callbackUploadFileError);
    serviceCreate.setCallbackFinally(callbackCreateFromFileFinally);
    serviceCreate.setPayload(form);

    serviceCreate.call();
  };

  // Create New Document from uploaded, new service call for geting document meta data by document ID
  const callbackCreateSuccess = response => {
    if (response.data.binaryContent) {
      setOriginalDocument(response.data.binaryContent);
      if (response.data.document) {
        let newDoc = response.data.document;
        setNewlyCreatedDocument({
          ...newDoc,
          direction: documentsPanelState.activeDocumentDirectionFilter
        });
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentForm
        });
        setIsLoadingForm(false);
      } else if (response.data.binaryContent && response.data.binaryContent.id) {
        readOCRDocumentFromUploaded(response.data.binaryContent.id);
      }
    }
  };

  const callbackUploadFileError = response => {
    if (
      response &&
      response.response &&
      response.response.data &&
      response.response.data.message &&
      response.response.data.message ===
        "Could not create a new file, provided file already exists!"
    ) {
      showNotificationMessage({
        type: notificationConstants.notificationType.warning,
        custom: "DOCUMENT_ALREADY_EXIST"
      });
    } else {
      showNotificationMessage({
        type: notificationConstants.notificationType.warning,
        custom: "DOCUMENT_SERVER_ERROR"
      });
    }
    setIsLoadingForm(false);
    setNewlyCreatedDocument(getEmptyDocumentObject());
    setState({
      ...state,
      activeRightPanelMode: constant.rightPanelMode.documentForm
    });
  };

  const callbackCreateFromFileFinally = async () => {};
  /////////////////////////////////////////////////////

  ////////////////////// CREATE DOCUMENT FROM FILE //////////////////
  // CREATE DOC FROM FILE
  const createDocFromFile = (newDoc, uniqueCheck, savePlusNew) => {
    const serviceCreate = new RestService.Document.Create.Service();
    if (savePlusNew) {
      serviceCreate.setCallbackSuccess(callbackCreateAndNewFileSuccess);
    } else {
      serviceCreate.setCallbackSuccess(callbackCreateFromFileSuccess);
    }

    serviceCreate.setCallbackError(callbackCreateError);
    serviceCreate.setCallbackFinally(callbackCreateFinaly);
    serviceCreate.setPayload(newDoc);
    serviceCreate.query.uniqueCheck(uniqueCheck);
    return serviceCreate;
  };

  const callbackCreateAndNewFileSuccess = async response => {
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.create
    });
    setState(prevState => {
      let updatedValues = {
        ...state,
        selectAll: false,
        activeMiddlePanelMode: constant.middlePanelMode.documentNotSelected,
        activeRightPanelMode: constant.rightPanelMode.documentUpload,
        selectFirst: false
      };
      return { ...prevState, ...updatedValues };
    });
    setDocumentPreview(null);
    setActivePaginationPage(1);
    setNewlyCreatedDocument(undefined);
    setLinkedDocumentsPreview(undefined);
    setOriginalDocument(null);
    setThumbsToSend([]);
  };

  const callbackCreateFromFileSuccess = async response => {
    showNotificationMessage({
      type: notificationConstants.notificationType.info,
      operation: notificationConstants.notificationOperation.create
    });

    setState({
      ...state,
      selectAll: false,
      activeMiddlePanelMode: constant.middlePanelMode.documentList,
      activeRightPanelMode: constant.rightPanelMode.documentDetails,
      activeSideBarDimmer: false,
      selectFirst: true
    });
    setDocumentPreview(null);
    setActivePaginationPage(1);
    setNewlyCreatedDocument(undefined);
    setLinkedDocumentsPreview(undefined);
    setOriginalDocument(null);
    setThumbsToSend([]);
    setEditMode(false);
  };

  // use also in updateDocFromFile
  const callbackCreateError = response => {
    if (response && response.response && response.response.data && response.response.data.message) {
      switch (response.response.data.message) {
        case "{DOCUMENTS.DOCUMENT_WITH_THE_SAME_NUMBER_ALREADY_EXISTS}":
          setShowSameDocNumbModal(true);
          setState({
            ...state,
            activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
            activeSideBarDimmer: true
          });
          break;
        case "DOC_PARENT_AMOUNT_LESS_THEN_CORRECTION":
          showNotificationMessage({
            type: notificationConstants.notificationType.warning,
            custom: "DOC_PARENT_AMOUNT_LESS_THEN_CORRECTION"
          });
          setInvalidCorrectionAmount(true);
          setState({
            ...state,
            activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
            activeSideBarDimmer: true
          });
          break;
        case "DOC_AMOUNT_ZERO_OR_LESS":
          showNotificationMessage({
            type: notificationConstants.notificationType.warning,
            custom: "DOC_AMOUNT_ZERO_OR_LESS"
          });
          setInvalidInvoiceAmount(true);
          setState({
            ...state,
            activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
            activeSideBarDimmer: true
          });
          break;
        default:
          break;
      }
    }
  };

  const callbackCreateFinaly = response => {};
  //////////////////////////////////////////////

  ////////////////////// READ OCR DOCUMNET FROM UPLOADED ///////////////////////
  const readOCRDocumentFromUploaded = async id => {
    const serviceCreate = new RestService.Document.GetOCRFromFile.Service(id);
    serviceCreate.setCallbackSuccess(callbackReadOCRSuccess);
    serviceCreate.setCallbackError(callbackReadOCRError);
    serviceCreate.setCallbackFinally(callbackReadOCRFinally);
    await serviceCreate.call();
  };

  // Create New Document callback that handles sucessful response where document is read by OCR engine
  function callbackReadOCRSuccess(response) {
    let emptyObj = getEmptyDocumentObject();
    switch (response.status) {
      case 202:
        setIsLoadingForm(true);
        setIsLoadingScansList(false);
        let url = response.request.responseURL;
        let id = parseInt(url.substr(url.lastIndexOf("/") + 1));
        setTimeout(() => readOCRDocumentFromUploaded(id), 4000);
        clearTimeout(5000);
        break;
      case 204:
        setIsLoadingForm(false);
        setNewlyCreatedDocument();
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentForm
        });
        break;
      case 200:
        setIsLoadingForm(false);
        setIsLoadingScansList(false);
        if (response.data) {
          let readDocument = getOCRDocumentReadObject(response.data);
          setNewlyCreatedDocument({
            ...readDocument,
            direction: documentsPanelState.activeDocumentDirectionFilter
          });
        } else {
          setNewlyCreatedDocument({
            ...emptyObj,
            direction: documentsPanelState.activeDocumentDirectionFilter
          });
        }
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentForm
        });
        break;
      default:
        setIsLoadingForm(false);
        setIsLoadingScansList(false);
        setNewlyCreatedDocument({
          ...emptyObj,
          direction: documentsPanelState.activeDocumentDirectionFilter
        });
        setState({
          ...state,
          selectAll: false,
          activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
          activeRightPanelMode: constant.rightPanelMode.documentForm
        });
        break;
    }
  }
  // Create New Document callback that handles error response where document is read by OCR engine
  const callbackReadOCRError = error => {
    let emptyObj = getEmptyDocumentObject();
    setNewlyCreatedDocument({
      ...emptyObj,
      direction: documentsPanelState.activeDocumentDirectionFilter
    });
    setLinkedDocumentsPreview(undefined);
    setThumbsToSend([]);
    setIsLoadingForm(false);
    setIsLoadingScansList(false);
    showNotificationMessage({
      type: notificationConstants.notificationType.warning,
      custom: "DOCUMENT_SERVER_ERROR"
    });

    // // this part of code is left here in case we decide to handle different error messages
    // // for the user based on the response, for now, the general message is left that error occurred 27.04.2020
    // switch (error.response.status) {
    //   case 400:
    //     setIsLoadingForm(false);
    //     setNewlyCreatedDocument(getEmptyDocumentObject());
    //     showNotificationMessage({
    //       type: notificationConstants.notificationType.warning,
    //       custom: "DOCUMENT_SERVER_ERROR"
    //     });
    //     break;
    //   case 404:
    //     setIsLoadingForm(false);
    //     setNewlyCreatedDocument(getEmptyDocumentObject());
    //     showNotificationMessage({
    //       type: notificationConstants.notificationType.warning,
    //       custom: "DOCUMENT_SERVER_ERROR_NOT_FOUND"
    //     });
    //     break;
    //   case 500:
    //     setIsLoadingForm(false);
    //     setNewlyCreatedDocument(getEmptyDocumentObject());
    //     showNotificationMessage({
    //       type: notificationConstants.notificationType.warning,
    //       custom: "DOCUMENT_SERVER_ERROR"
    //     });
    //     break;
    //   default:
    //     setIsLoadingForm(false);
    //     setNewlyCreatedDocument(getEmptyDocumentObject());
    //     break;
    // }

    setState({
      ...state,
      selectAll: false,
      activeMiddlePanelMode: constant.middlePanelMode.documentDisplay,
      activeRightPanelMode: constant.rightPanelMode.documentForm
    });
  };

  const callbackReadOCRFinally = response => {};
  ////////////////////////////////////////////////

  useEffect(() => {
    if (invalidCorrectionAmount) {
      setInvalidCorrectionAmount(false);
    }
    if (invalidInvoiceAmount) {
      setInvalidInvoiceAmount(false);
    }
  }, [invalidCorrectionAmount, invalidInvoiceAmount]);

  return [
    originalDocument,
    setOriginalDocument,
    newlyCreatedDocument,
    setNewlyCreatedDocument,
    showSameDocNumbModal,
    setShowSameDocNumbModal,
    invalidCorrectionAmount,
    setInvalidCorrectionAmount,
    invalidInvoiceAmount,
    setInvalidInvoiceAmount,
    documentPreview,
    setDocumentPreview,
    isPDFPreview,
    setIsPDFPreview,
    createDocumentFromUploaded,
    createDocFromFile,
    callbackCreateError,
    readOCRDocumentFromUploaded
  ];
};

export default useCreateDocument;
