import React from "react";
import { Icon, Menu, Sidebar, Container, Button, Dimmer } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "./style/ProjectManagementSideBar.module.css";

import * as constants from "../Constants";

const ProjectManagementSideBar = props => {
  const { privileges, archivedFilter, sideBarVisibility, setSideBarVisibility, editMode } = props;

  return (
    <>
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisibility}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisibility(false)}
      >
        <Dimmer active={editMode} inverted>
          {" "}
        </Dimmer>
        <Container>
          <Button
            className={`ui primary button ${styles.bms_button}`}
            onClick={props.handlerButton.createNewProjectButtonHandler}
            disabled={!privileges.create}
          >
            {Localize.get("PROJECTS.MANAGEMENT.NEW_PROJECT")}
            <Icon name="plus" />
          </Button>
        </Container>

        <Menu.Item>
          <Menu.Header>{Localize.get("PROJECTS.MANAGEMENT.STATUS")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name={constants.projectStatus.new}
              onClick={props.handlerSidebar.onStatusFilterClick}
              active={props.filterStatus.activeStatusFilter === constants.projectStatus.new}
            >
              <Icon name="hourglass start" /> {Localize.get("PROJECTS.MANAGEMENT.STATUS_NEW")}
            </Menu.Item>
            <Menu.Item
              name={constants.projectStatus.process}
              onClick={props.handlerSidebar.onStatusFilterClick}
              active={props.filterStatus.activeStatusFilter === constants.projectStatus.process}
            >
              <Icon name="hourglass half" /> {Localize.get("PROJECTS.MANAGEMENT.STATUS_PROCESS")}
            </Menu.Item>
            <Menu.Item
              name={constants.projectStatus.closed}
              onClick={props.handlerSidebar.onStatusFilterClick}
              active={props.filterStatus.activeStatusFilter === constants.projectStatus.closed}
            >
              <Icon name="hourglass end" /> {Localize.get("PROJECTS.MANAGEMENT.STATUS_CLOSED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Menu className={styles.active_menu_item}>
            <Menu.Item
              name={constants.archive.archived}
              active={archivedFilter === constants.archive.archived}
              onClick={(e, data) => {
                props.handlerSidebar.onArchiveFilterClick(e, data);
              }}
            >
              <Icon name="archive" /> {Localize.get("CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default ProjectManagementSideBar;
