import React, { useState, useEffect } from "react";
import { Label } from "semantic-ui-react";
import { Accounts } from "../../RestService";
import Localize from "react-intl-universal";
import Validator from "../../Utils/Validator";
import * as constant from "../../Utils/Constant";
import styles from "./style/BankAccountsItemPanel.module.css";
import * as constants from "../Constants.js";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";

const BankAccountsItemPanel = props => {
  const {
    panelState,
    account,
    formErrorMessage,
    isNewAccountCreated,
    handlerItem,
    handlerButton
  } = props;

  const panelLabel =
    panelState.activeMode === "edit"
      ? Localize.get("ACCOUNTS.EDITACCOUNT.EDIT_BANK_ACCOUNT")
      : Localize.get("ACCOUNTS.NEWACCOUNT.NEW_BANK_ACCOUNT");

  const [accountLocal, setAccountLocal] = useState(
    panelState.activeMode === "edit"
      ? account
      : {
          id: "",
          accountOwner: "",
          bankName: "",
          iban: "",
          bic: ""
        }
  );

  const [accountBankName, setAccountBankName] = useState();

  const [validator, setValidator] = useState(
    props.validator === undefined ? new Validator(constant.formFieldsNewAccount) : props.validator
  );

  const [refreshValidation, setRefreshValidation] = useState(false);

  const callbackGetAllSuccess = response => {
    let bankNameResponse = response.data;
    setAccountBankName(bankNameResponse);
  };

  const callbackGetAllError = error => {
    setAccountLocal({
      ...accountLocal,
      bankName: ""
    });
  };

  const callbackGetAllFinally = response => {};

  const onItemChange = event => {
    const data = event.target;
    if (!data.value) {
      // clear validation messages after field is cleared out
      validator.validateField(data.value, data.name);
    } else {
      const validationOnField = validator.parameter[data.name];

      if (validationOnField) {
        validator.validateField(data.value, data.name);
      }
    }
    setAccountLocal({
      ...accountLocal,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (event, data) => {
    if (event.target.name in constant.formFieldsNewAccount) {
      validator.validateField(event.target.value, event.target.name);
      if (validator.isThereValidation()) {
        setRefreshValidation(!refreshValidation);
      }
    }
  };

  useEffect(() => {
    if (formErrorMessage) {
      validator.addErrorMsgParam(formErrorMessage);
    }
    if (panelState.activeMode === "edit") {
      setAccountLocal(account);
    }

    if (isNewAccountCreated || !account) {
      setAccountLocal({
        ...accountLocal,
        accountOwner: "",
        bankName: "",
        iban: "",
        bic: ""
      });
      handlerItem.resetIsNewAccountCreated();
    }
    return function cleanup() {
      validator.clearMessages();

      setValidator({ ...validator });
    };
  }, [account, formErrorMessage]);

  useEffect(() => {
    if (accountBankName) {
      setAccountLocal({ ...accountLocal, bankName: accountBankName });
    }
  }, [accountLocal, accountBankName]);

  const onSaveClick = () => {
    if (validator.validateForm(constant.formFieldsNewAccount, accountLocal)) {
      handlerButton.saveButtonFormHandler(accountLocal);
    }
    setValidator({ ...validator });
  };

  const errorBorder = "3px red solid";

  // eslint-disable-next-line no-unused-vars
  const onSaveNewClick = event => {
    if (validator.validateForm(constant.formFieldsNewAccount, accountLocal)) {
      handlerButton.saveNewButtonHandler(accountLocal);
    }
    setValidator({ ...validator });
  };

  const bicFocusOut = () => {
    validator.validateField(accountLocal.bic, "bic");
    if (validator.isThereValidation()) {
      setRefreshValidation(!refreshValidation);
    }
    if (accountLocal.bic !== "" && accountLocal.bic.length >= 8 && accountLocal.bic.length <= 11) {
      const getBankName = new Accounts.GetBankName.Service();

      getBankName.query.addAccountIBAN(accountLocal.iban);
      getBankName.query.addAccountBIC(accountLocal.bic);

      getBankName.setCallbackSuccess(callbackGetAllSuccess);
      getBankName.setCallbackError(callbackGetAllError);
      getBankName.setCallbackFinally(callbackGetAllFinally);

      getBankName.call();
    } else {
      callbackGetAllError();
    }
  };

  return (
    <div className="six wide column items_details">
      <div className={styles.item_details_container}>
        <div className={`"header" ${styles.bms_header}`}>{panelLabel}</div>
        <div className={styles.company_details_container_account}>
          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content">
                <div className={styles.str_lable}>
                  <strong>{Localize.get("ACCOUNTS.FORM.ACCOUNT_NAME")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    name="accountOwner"
                    type="text"
                    value={accountLocal.accountOwner}
                    placeholder={Localize.get("ACCOUNTS.FORM.ACCOUNT_OWNER_PLACEHOLDER")}
                    onChange={onItemChange}
                    onBlur={handleOnBlur}
                    style={{
                      border: validator.parameter.accountOwner ? errorBorder : ""
                    }}
                  />
                </div>
                {validator.parameter.accountOwner && (
                  <Label basic color="red" pointing content={validator.parameter.accountOwner} />
                )}
              </div>
            </div>
          </div>

          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content">
                <div className={styles.str_lable}>
                  <strong>{Localize.get("ACCOUNTS.FORM.BIC")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    type="text"
                    name="bic"
                    value={accountLocal.bic}
                    placeholder={Localize.get("ACCOUNTS.FORM.BIC_PLACEHOLDER")}
                    onChange={onItemChange}
                    style={{
                      border: validator.parameter.bic ? errorBorder : ""
                    }}
                    disabled={panelState.activeMode === "edit" ? true : false}
                    onBlur={bicFocusOut}
                  />
                </div>
                {validator.parameter.bic && (
                  <Label basic color="red" pointing content={validator.parameter.bic} />
                )}
              </div>
            </div>
          </div>

          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content">
                <div className={styles.str_lable}>
                  <strong>{Localize.get("ACCOUNTS.FORM.IBAN")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    type="text"
                    name="iban"
                    value={accountLocal.iban}
                    placeholder={Localize.get("ACCOUNTS.FORM.IBAN_PLACEHOLDER")}
                    onChange={onItemChange}
                    onBlur={handleOnBlur}
                    style={{
                      border: validator.parameter.iban ? errorBorder : ""
                    }}
                    disabled={panelState.activeMode === "edit" ? true : false}
                  />
                </div>
                {validator.parameter.iban && (
                  <Label basic color="red" pointing content={validator.parameter.iban} />
                )}
              </div>
            </div>
          </div>

          <div className="ui middle aligned divided list">
            <div className="item">
              <div className="content">
                <div className={styles.str_lable}>
                  <strong>{Localize.get("ACCOUNTS.FORM.BANK_NAME")}</strong>
                </div>
                <div className="ui fluid input">
                  <input
                    autoComplete={constants.autoComplete.off}
                    disabled
                    type="text"
                    name="bankName"
                    value={accountLocal.bankName}
                    placeholder={Localize.get("ACCOUNTS.FORM.BANK_NAME_PLACEHOLDER")}
                    onChange={onItemChange}
                    onBlur={handleOnBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.btn_group}>
          <GlobalSaveNewCancelBtn
            disabledBtn={
              accountLocal.bankName === "" || accountLocal.iban === "" || accountLocal.bic === ""
                ? true
                : false
            }
            onSave={onSaveClick}
            onCancel={handlerButton.closeNewButtonFormHandler}
          />
        </div>
      </div>

      <div style={{ clear: "both", display: "block" }} />
    </div>
  );
};

export default BankAccountsItemPanel;
