import React, { useEffect } from "react";
import Localize from "react-intl-universal";
import { Form, Divider, Header, Button, Icon, Pagination, Dimmer, Loader } from "semantic-ui-react";
import CustomAccountingOption from "./CustomAccountingOption";
import useAccountingServices from "../../RestServicesNew/useAccountingServices";
import styles from "./CustomAccountingSystem.module.css";

const CustomSystemForm = ({ setCurrentView }) => {
  const {
    customChartAccountsList,
    paging,
    activePaginationPage,
    setActivePaginationPage,
    setEditedAccounts,
    editedAccounts,
    setCustomChart,
    fetchCustomChartLoading,
    fetchCustomChartCall,
    patchOrPostCall,
    patchOrPostLoading
  } = useAccountingServices(setCurrentView, "edit");

  useEffect(() => {
    fetchCustomChartCall();
  }, [activePaginationPage]);

  const checkEditedAccList = (myList, editedAccount, editedAccountNewValue) => {
    let itemAlreadyExist = false;
    myList.reduce((acc, item) => {
      if (item.accountKey === editedAccount.accountKey) {
        itemAlreadyExist = true;
        item.operation[0].value = editedAccountNewValue;
      }
      acc.push(item);
      return acc;
    }, []);
    if (!itemAlreadyExist) {
      let newAccount = {
        accountKey: editedAccount.accountKey,
        operation: [{ op: "replace", path: "/enabled", value: editedAccountNewValue }]
      };
      myList.push(newAccount);
    }
    return myList;
  };

  const onCheckboxChange = (account, data) => {
    let { accountKey } = account;
    if (customChartAccountsList && customChartAccountsList.length > 0) {
      setCustomChart(prevState => {
        return prevState.map(item => {
          if (item.accountKey === accountKey) {
            item.isChanged = true;
            item.enabled = data.checked;
          }
          return item;
        });
      });
      setEditedAccounts(prevState => {
        return checkEditedAccList(prevState, account, data.checked);
      });
    }
  };

  const onSaveClicked = () => {
    if (editedAccounts && editedAccounts.length > 0) {
      patchOrPostCall(editedAccounts, `/accountings/charts/3/accounts`);
    } else {
      setCurrentView("details");
    }
  };

  const handlePageChange = myPage => {
    setActivePaginationPage(myPage);
  };

  return (
    <div>
      <Dimmer active={fetchCustomChartLoading || patchOrPostLoading} inverted>
        <Loader size="large" disabled={!fetchCustomChartLoading && !patchOrPostLoading}>
          {Localize.get("DOCUMENTS.ITEMLIST.ITEM.DOCUMENTS_LOADER")}
        </Loader>
      </Dimmer>
      <Form style={{ height: "100vh" }}>
        <div className={styles.header_grid}>
          <div>
            {/* <Input
              value={searchedValue}
              onChange={handleSearchChange}
              icon="search"
              placeholder={Localize.get("GLOBAL.LIST.SEARCH_WITH_DOTS")}
            /> */}
            <Header className={styles.form_header} size="medium">
              {Localize.get("SETTINGS.HEADERS.ACCOUNT_CLASS_0")}
            </Header>
            <Divider className={styles.header_divider} />
            <p className={styles.text_assets}>
              {Localize.get("SETTINGS.HEADERS.ASSETS_AND_CAPITALS")}
            </p>
          </div>
          <div className={styles.buttons_group}>
            <Button
              className={`${styles.form_button} ${styles.red_button}`}
              content={Localize.get("SETTINGS.HEADERS.RESET_TO_DEFAULT")}
              color="red"
            />
            <Button
              className={styles.form_button}
              content={Localize.get("GLOBAL.FORM.SAVE")}
              color="blue"
              onClick={onSaveClicked}
            />
            <Button
              className={styles.form_button}
              content={Localize.get("GLOBAL.FORM.CANCEL")}
              color="grey"
              onClick={() => setCurrentView("details")}
            />
          </div>
        </div>

        <Divider hidden />
        <div className={styles.container_res}>
          {customChartAccountsList.map((item, index) => {
            return (
              <CustomAccountingOption
                key={index}
                account={item}
                onCheckboxChange={onCheckboxChange}
              />
            );
          })}
        </div>
        <Pagination
          pointing
          secondary
          className={styles.pagination_position}
          activePage={activePaginationPage}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={paging.pageCount}
          onPageChange={(e, pageInfo) => handlePageChange(pageInfo.activePage)}
        />
      </Form>
    </div>
  );
};

export default CustomSystemForm;
