import React from "react";
import Localize from "react-intl-universal";
import { Label, Menu, Icon } from "semantic-ui-react";
import styles from "./style/GlobalHederRightPanel.module.css";
import propTypes from "prop-types";

const GlobalHederRightPanel = props => {
  const {
    currentView = "details",
    selectedItem,
    setCurrentView = function(pro) {},
    formHeaderText = "",
    detailsHeaderText = "",
    deleteItem = function(pro) {},
    disabledIcons = [],
    hideIcon = false,
    customCss
  } = props;

  return (
    <>
      {currentView === "details" || currentView === "preview" ? (
        <Menu.Item className={`${styles.header_above_list} ${customCss}`} verticalalign="middle">
          <Label className={styles.heder_text_details}>{Localize.get(detailsHeaderText)}</Label>
          {!hideIcon ? (
            <>
              {" "}
              <Icon
                className={styles.details_heder_icon}
                name="trash alternate"
                floated="right"
                disabled={!selectedItem || disabledIcons.includes("delete")}
                size="large"
                onClick={() => deleteItem(`/${selectedItem.id}`, 1)}
              />
              <Icon
                className={styles.details_heder_icon}
                name="edit"
                floated="right"
                disabled={!selectedItem || disabledIcons.includes("edit")}
                size="large"
                onClick={() => setCurrentView("edit")}
              />
            </>
          ) : null}
          {currentView === "preview" ? (
            <Icon
              className={styles.details_heder_icon}
              name="eye slash"
              floated="right"
              size="large"
              onClick={() => setCurrentView("details")}
            />
          ) : null}
        </Menu.Item>
      ) : (
        <Menu.Item className={styles.header_above_list_edit} verticalalign="middle">
          {currentView === "edit" || currentView === "create" ? (
            <Label className={styles.heder_text}>{Localize.get(formHeaderText)}</Label>
          ) : null}
        </Menu.Item>
      )}
    </>
  );
};

GlobalHederRightPanel.propTypes = {
  currentView: propTypes.string,
  selectedItem: propTypes.oneOfType([propTypes.object, propTypes.array]),
  setCurrentView: propTypes.func,
  currentModule: propTypes.string,
  formHeaderText: propTypes.string,
  detailsHeaderText: propTypes.string,
  deleteItem: propTypes.func
};

export default GlobalHederRightPanel;
