import React, { useEffect } from "react";
import { Container, Dimmer, Divider, Form, Grid, Loader } from "semantic-ui-react";
import Localize from "react-intl-universal";
import styles from "../style/AccountNumbering.module.css";
import GlobalHederRightPanel from "../../Components/Details/GlobalHederRightPanel";
import GlobalSaveNewCancelBtn from "../../Components/GlobalSaveNewCancelBtn";
import useAccountingServices from "../../RestServicesNew/useAccountingServices";
import * as constants from "../Constants";

const AccountingNumbering = props => {
  const { currentView, selectedItem, setCurrentView, currentModule } = props;

  const {
    docNumberingList,
    fetchDocNumberingLoading,
    fetchDocNumberingCall,
    partnerNumberingList,
    fetchPartnerNumberingLoading,
    fetchPartnerNumberingCall
  } = useAccountingServices(setCurrentView, currentView);

  useEffect(() => {
    if (currentView === "details") {
      fetchDocNumberingCall();
      fetchPartnerNumberingCall();
    }
  }, [currentView]);

  const FourInputFields = props => {
    const { header, item } = props;

    return (
      <Form className={styles.four_input_form}>
        <h4>{header}</h4>
        <Form.Group>
          <Form.Input
            className={styles.disable_input}
            label={Localize.get("SETTINGS.ACCOUNTING.ABBREVIATION")}
            placeholder={item.prefix}
            disabled
            width={3}
          />
          <Form.Input
            className={styles.disable_input}
            name={"freeText"}
            label={Localize.get("SETTINGS.ACCOUNTING.FREETEXT")}
            placeholder={item && item.freeText}
            disabled={currentView === "details"}
            width={4}
          />

          <Form.Input
            className={styles.disable_input}
            customtype={item.type}
            name="nextValue"
            label={Localize.get("SETTINGS.ACCOUNTING.NEXT_VALUE")}
            value={item && item.nextValue}
            disabled={currentView === "details"}
            width={4}
          />
          <Form.Input
            customtype={item.type}
            name="minLength"
            className={styles.disable_input}
            label={Localize.get("SETTINGS.ACCOUNTING.MIN_LENGTH")}
            value={item && item.minLength}
            disabled={currentView === "details"}
            width={4}
          />
        </Form.Group>
      </Form>
    );
  };

  const OneInputField = props => {
    const { header, item } = props;
    return (
      <>
        <Grid.Column>
          <Form>
            <h4>{header}</h4>
            <Form.Field>
              <label className={styles.label_input}>
                {Localize.get("SETTINGS.ACCOUNTING.NEXT_VALUE")}
              </label>
              <input value={item.nextValue} disabled={currentView === "details"} />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column className={styles.between_info}>
          {Localize.get("SETTINGS.ACCOUNTING.VALUE_BETWEEN_1") +
            " " +
            item.minValue +
            " " +
            Localize.get("SETTINGS.ACCOUNTING.VALUE_BETWEEN_2") +
            item.maxValue +
            ")"}
        </Grid.Column>
      </>
    );
  };

  const getHeader = type => {
    switch (type) {
      case constants.type.invoice:
        return Localize.get("SETTINGS.ACCOUNTING.INVOICE_NUMBERS");
      case constants.type.offer:
        return Localize.get("SETTINGS.ACCOUNTING.OFFER_NUMBERS");
      case constants.type.order:
        return Localize.get("SETTINGS.ACCOUNTING.ORDER_NUMBERS");
      case constants.type.deliveryNote:
        return Localize.get("SETTINGS.ACCOUNTING.DELIVERY_NOTE_NUMBERS");
      case constants.type.invoiceCorrection:
        return Localize.get("SETTINGS.ACCOUNTING.INVOICE_CORRECTION_NUMBERS");
      case constants.type.receipt:
        return Localize.get("SETTINGS.ACCOUNTING.RECEIPT_NUMBERS");
      case constants.type.inquiiry:
        return Localize.get("SETTINGS.ACCOUNTING.INQUIRY_NUMBERS");
      case constants.type.other:
        return Localize.get("SETTINGS.ACCOUNTING.OTHER_NUMBERS");
      case constants.type.customer:
        return Localize.get("SETTINGS.ACCOUNTING.INVOICE_NUMBERS");
      case constants.type.employee:
        return Localize.get("SETTINGS.ACCOUNTING.EMPLOYEE_NUMBERS");
      case constants.type.supplier:
        return Localize.get("SETTINGS.ACCOUNTING.SUPPLIER_NUMBERS");
      default:
        break;
    }
  };

  return (
    <>
      <Dimmer active={fetchDocNumberingLoading} inverted>
        <Loader disabled={!fetchPartnerNumberingLoading} />
      </Dimmer>
      <GlobalHederRightPanel
        selectedItem={selectedItem}
        currentView={currentView}
        setCurrentView={setCurrentView}
        currentModule={currentModule}
        disabledIcons={["delete", "edit"]}
        formHeaderText={"SETTINGS.HEADERS.NUMBER_RANGE_EDIT"}
        detailsHeaderText={"SETTINGS.HEADERS.NUMBER_RANGE_DETAILS"}
      />

      <Container
        className={
          currentView === "details" ? styles.form_container_details : styles.form_container
        }
      >
        {docNumberingList.map((item, index) => (
          <FourInputFields key={index} header={getHeader(item.type)} item={item} />
        ))}
        <Divider />

        <Grid columns={2} className={styles.partner_grid}>
          {partnerNumberingList.map((item, index) => (
            <OneInputField key={index} header={getHeader(item.type)} item={item} />
          ))}
        </Grid>
      </Container>
      {currentView !== "details" && (
        <GlobalSaveNewCancelBtn editMode={true} onCancel={() => setCurrentView("details")} />
      )}
    </>
  );
};

export default AccountingNumbering;
