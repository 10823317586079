import { useState } from "react";
import { createPostService } from "../Components/Utils/GlobalUtil";
import propTypes from "prop-types";

const useCreateItem = (
  currentItem,
  currentModule,
  customValidation,
  customCallbackSucess = function() {},
  customCallbackError = function() {},
  customCallbackFinaly = function() {}
) => {
  const [createIsLoading, setCreateIsLoading] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const callbackCreateSuccess = (res, save) => {
    setCreateIsLoading(false);
    customCallbackSucess(res, save);
  };

  const callbackCreateAndEditError = error => {
    setCreateIsLoading(false);
    customCallbackError(error);
  };
  const callbackCreateAndEditFinaly = res => {
    customCallbackFinaly(res);
  };

  const createServiceCall = async save => {
    let service = createPostService(
      currentItem,
      callbackCreateSuccess,
      callbackCreateAndEditError,
      callbackCreateAndEditFinaly,
      currentModule,
      save
    );
    setDisableSaveBtn(true);
    if (customValidation) {
      if (customValidation(currentItem)) {
        setCreateIsLoading(true);
        await service.call();
      }
    } else {
      setCreateIsLoading(true);
      await service.call();
    }
    setDisableSaveBtn(false);
  };
  return [createServiceCall, createIsLoading, disableSaveBtn];
};

useCreateItem.propTypes = {
  currentItem: propTypes.object.isRequired,
  currentModule: propTypes.string.isRequired,
  customValidation: propTypes.func,
  customCallbackSucess: propTypes.func,
  customCallbackError: propTypes.func,
  customCallbackFinaly: propTypes.func
};
export default useCreateItem;
