// eslint-disable-next-line no-unused-vars
import React, { useState, useContext } from "react";
import { Menu, Sidebar, Accordion } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import Localize from "react-intl-universal";
import styles from "./VerticalAppNavBar.module.css";
// context
// eslint-disable-next-line no-unused-vars
import { AuthContext } from "../Auth/AuthContext";

const VerticalAppNavBar = props => {
  const { customConfig } = useContext(AuthContext);
  const menuItemFinances = Localize.get("APPNAVBAR.FINANCES");
  const menuItemProjects = Localize.get("APPNAVBAR.PROJECTS");
  const menuItemExports = Localize.get("APPNAVBAR.EXPORTS");

  const [activeItem, setActiveItem] = useState();
  const [, setBoldedHeader] = useState();

  const { verticalBarVisible } = props;
  const { setVerticalBarVisible } = props;

  const [activeIndex, setActiveIndex] = useState();

  const handleClick = (e, titleProps) => {
    const index = titleProps.index;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleItemClick = (e, { name }) => {
    if (name === menuItemFinances || name === menuItemProjects || name === menuItemExports) {
      setBoldedHeader(name);
    } else {
      setBoldedHeader();
    }
    setVerticalBarVisible(false);
    return setActiveItem(name);
  };

  const getMenuItem = item => {
    switch (item.type) {
      case "item":
      case "settings":
        return (
          !item.hidden && (
            <Menu.Item
              key={item.name}
              className={styles.bms_accordion_item}
              name={Localize.get(item.name)}
              active={activeItem === Localize.get(item.name)}
              onClick={handleItemClick}
              as={NavLink}
              to={item.to}
              exact
            />
          )
        );
      case "dropdown":
        return (
          !item.hidden && (
            <Menu.Item key={item.name} className={styles.bms_accordion_item}>
              <Accordion.Title
                active={activeIndex === 0}
                content={Localize.get(item.name)}
                index={item.index}
                onClick={handleClick}
              />
              <Accordion.Content active={activeIndex === item.index}>
                <Menu.Menu>
                  {item.dropdownItems.map(dropdownItem => (
                    <Menu.Item
                      key={dropdownItem.name}
                      name={Localize.get(dropdownItem.name)}
                      as={NavLink}
                      onClick={handleItemClick}
                      content={Localize.get(dropdownItem.name)}
                      value={dropdownItem.value}
                      to={dropdownItem.to}
                      exact
                    />
                  ))}
                </Menu.Menu>
              </Accordion.Content>
            </Menu.Item>
          )
        );

      default:
        return <></>;
    }
  };

  return (
    <Sidebar
      className={`${styles.bms_tablet_show} ${styles.bms_margin_top}`}
      animation="overlay"
      onHide={() => setVerticalBarVisible(false)}
      visible={verticalBarVisible}
      direction="top"
    >
      <Accordion as={Menu} vertical fluid className={styles.bms_accordion}>
        {customConfig.header.menuItems.map(item => getMenuItem(item))}
      </Accordion>
    </Sidebar>
  );
};

export default VerticalAppNavBar;
