import React, { useState, useEffect } from "react";
import Localize from "react-intl-universal";
import { Sidebar, Menu, Icon, Container, Dropdown, Dimmer, Loader } from "semantic-ui-react";
import * as constant from "../Constants.js";
import styles from "./style/ContactSideBar.module.css";
import GlobalModal from "../../Components/Panels/GlobalModal";
import usePartnerServices from "../../RestServicesNew/usePartnerServices.js";
import { BASE } from "../../RestService/Constants.js";

const ContactSideBar = props => {
  // HANDLERS
  const {
    privileges,
    sideBarVisiblity,
    setSideBarVisiblity,
    handle,
    state,
    contactListState,
    partnersPanelState,
    setSelectedId,
    selectedId,
    setContactPartners
  } = props;

  const { listOfPartners, syncPartnerFFO, syncPartnerFFOLoading } = usePartnerServices(
    { ...contactListState, ...partnersPanelState },
    undefined,
    selectedId,
    setSelectedId
  );

  const [showModal, setShowModal] = useState(false);

  const onTypeFilterClick = (event, data) => {
    handle.onTypeFilterClick(data.name);
  };
  const onRoleFilterClick = (event, data) => {
    handle.onRoleFilterClick(data.name);
  };
  const onArchiveFilterClick = (event, data) => {
    handle.onArchiveFilterClick(data.name);
  };
  const onNotificationClick = () => {
    handle.onNotificationClick();
  };

  useEffect(() => {
    setContactPartners(listOfPartners);
  }, [listOfPartners]);

  const makeFFOCall = async () => {
    setShowModal(false);
    await syncPartnerFFO(BASE.resource.syncPartnerFFO, { ignoreInvalid: true });
  };

  const onButtonDropdown = (e, data) => {
    if (data.value === "synhronize") {
      setShowModal(true);
      return;
    }
    if (data.value === "create") {
      handle.onNewContactButtonClick();
      return;
    }
  };

  const modalMessageComponent = () => {
    return <div>{Localize.get("CONTACTS.SIDEBAR.CONFIRM_SYNCHRONIZE_MESSAGE")}</div>;
  };

  return (
    <>
      <Dimmer active={syncPartnerFFOLoading} inverted>
        <Loader className={styles.loader_center} size="large" disabled={!syncPartnerFFOLoading}>
          {Localize.get("CONTACTS.LIST.TOOLBAR.PARTNERS_LOADING")}
        </Loader>
      </Dimmer>
      <GlobalModal
        showModal={showModal}
        setShowModal={setShowModal}
        headerText={"CONTACTS.SIDEBAR.CONFIRM_SYNCHRONIZE"}
        CustomComponent={modalMessageComponent}
        modalHandler={makeFFOCall}
      />
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        visible={sideBarVisiblity}
        width="thin"
        className={styles.bms_sidebar}
        onHide={() => setSideBarVisiblity(false)}
      >
        <Container>
          <Dropdown
            disabled={!privileges.create}
            button
            className={`${"icon"} ${styles.dropdown_button}`}
            floating
            labeled
            icon="plus"
            text={Localize.get("CONTACTS.SIDEBAR.NEW_CONTACT")}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text={Localize.get("DOCUMENTS.SIDEBAR.SYNC")}
                icon="sync alternate"
                value="synhronize"
                onClick={onButtonDropdown}
              />
              <Dropdown.Item
                text={Localize.get("DOCUMENTS.SIDEBAR.CREATE")}
                icon="plus square outline"
                value="create"
                onClick={onButtonDropdown}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Container>
        {/* Partner Type Filter */}
        <Menu.Item>
          <Menu.Header>{Localize.get("CONTACTS.SIDEBAR.HEADER_CONTACT_TYPE")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name={constant.contactType.person}
              onClick={onTypeFilterClick}
              active={state.activeTypeFilter === constant.contactType.person}
            >
              <div className={`${styles.circle_icon} ${styles.orange}`}></div>{" "}
              {Localize.get("CONTACTS.SIDEBAR.CONTACT_TYPE_PERSON")}
            </Menu.Item>
            <Menu.Item
              name={constant.contactType.company}
              onClick={onTypeFilterClick}
              active={state.activeTypeFilter === constant.contactType.company}
            >
              <div className={`${styles.circle_icon} ${styles.blue}`}></div>{" "}
              {Localize.get("CONTACTS.SIDEBAR.CONTACT_TYPE_COMPANIES")}
            </Menu.Item>
            <Menu.Item
              name={constant.contactType.employee}
              onClick={onTypeFilterClick}
              active={state.activeTypeFilter === constant.contactType.employee}
            >
              <div className={`${styles.circle_icon} ${styles.green}`}></div>{" "}
              {Localize.get("CONTACTS.SIDEBAR.CONTACT_TYPE_EMPLOYEE")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        {/* Partner Role Filter */}
        <Menu.Item>
          <Menu.Header>{Localize.get("CONTACTS.SIDEBAR.HEADER_CONTACT_ROLE")}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name={constant.contactRole.customer}
              onClick={onRoleFilterClick}
              active={
                state.activeRoleFilter === constant.contactRole.customer ||
                state.activeRoleFilter === constant.contactRole.customer_supplier
              }
            >
              <div className={`${styles.circle_icon} ${styles.purple}`}></div>{" "}
              {Localize.get("CONTACTS.SIDEBAR.CONTACT_ROLE_CUSTOMER")}
            </Menu.Item>
            <Menu.Item
              name={constant.contactRole.supplier}
              onClick={onRoleFilterClick}
              active={
                state.activeRoleFilter === constant.contactRole.supplier ||
                state.activeRoleFilter === constant.contactRole.customer_supplier
              }
            >
              <div className={`${styles.circle_icon} ${styles.cyan}`}></div>{" "}
              {Localize.get("CONTACTS.SIDEBAR.CONTACT_TYPE_SUPPLIER")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        {/* Notifications */}
        <Menu.Item disabled={true}>
          <Menu.Header>{"Partner notifications"}</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              disabled={true}
              name="newNotifications"
              onClick={onNotificationClick}
              active={state.activeNotification}
            >
              <Icon name="bell outline" className={styles.notif_icon} />
              {"New partners"}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        {/* Notifications */}
        {/* Archive Status Filter */}
        <Menu.Item>
          <Menu.Menu className={styles.active_menu_item}>
            <Menu.Item
              name={constant.archive.archived}
              onClick={onArchiveFilterClick}
              active={state.activeArchiveFilter === constant.archive.archived}
            >
              <Icon name="archive" className={styles.archive_filter} />{" "}
              {Localize.get("CONTACTS.SIDEBAR.ARCHIVE_STATUS_ARCHIVED")}
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default ContactSideBar;
