import React, { useState } from "react";
import * as RestService from "../RestService";
import showNotification from "../Notifications/NotificationFunction";
import * as constants from "../Components/Constants";
import ConfirmDeleteDialog from "../Dialogs/ConfirmDeleteDialog";
import { Modal, Button } from "semantic-ui-react";
import styles from "./style/CustomHooks.module.css";
import propTypes from "prop-types";

const useDeleteItem = (
  callGetAll = function() {},
  setIsLoading = function() {},
  currentModule,
  activePaginationPage,
  handlePageChange = function() {},
  lenghtOfList,
  archivedFilter
) => {
  const [openModal, setOpenModal] = useState({
    show: false,
    customPath: "",
    lenghtOfItems: 0
  });
  const [cannotBeDeleted, setCannotBeDeleted] = useState([]);
  const [cannotDeleteInfo, setCannotDeleteInfo] = useState(false);

  const callbackDeleteSuccess = response => {
    if (response && response.status && response.status === 226) {
      if (!archivedFilter) {
        setCannotBeDeleted(response.data.message);
      } else {
        setCannotDeleteInfo(true);
      }

      //TODO - temp solution until we standardize errors
      showNotificationMessage({
        type: constants.notificationType.warning,
        serverMessage: "Item cannot be deleted"
      });
    } else {
      showNotificationMessage({
        type: constants.notificationType.info,
        serverMessage: "Item successfuly deleted."
      });
    }
    if (activePaginationPage > 1 && openModal.lenghtOfItems === lenghtOfList) {
      handlePageChange({ activePage: activePaginationPage - 1 });
    } else {
      callGetAll();
    }
  };
  const callbackDeleteError = response => {
    showNotificationMessage({
      type: constants.notificationType.warning,
      serverMessage: response.response.data.message
    });
  };
  const callbackDeleteFinally = () => {
    setOpenModal({
      show: false,
      customPath: "",
      lenghtOfItems: 0
    });
  };

  const confirmDelete = async () => {
    const projectRoleService = new RestService.GlobalRest.Delete.Service(
      `/${currentModule}${openModal.customPath}`
    );
    projectRoleService.setPayload();
    projectRoleService.setCallbackSuccess(callbackDeleteSuccess);
    projectRoleService.setCallbackError(callbackDeleteError);
    projectRoleService.setCallbackFinally(callbackDeleteFinally);
    setIsLoading(true);
    await projectRoleService.call();
    setIsLoading(false);
  };

  const showNotificationMessage = param => {
    // TODO: params can be => type, operation, serverMessage, time
    showNotification({
      entity: currentModule,
      ...param
    });
  };

  const deleteItem = (customPath, lenghtOfItems) => {
    setOpenModal({ show: true, customPath, lenghtOfItems });
  };

  return [
    deleteItem,
    <ConfirmDeleteDialog
      numberOfCheckedItems={openModal.lenghtOfItems}
      confirmClicked={confirmDelete}
      declineClicked={() =>
        setOpenModal({
          show: false,
          customPath: "",
          lenghtOfItems: 0
        })
      }
      showModal={openModal.show}
    />,
    cannotBeDeleted,
    setCannotBeDeleted,
    <Modal
      size="tiny"
      dimmer="inverted"
      open={cannotDeleteInfo}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header
        className={styles.delete_not_possible}
        style={{
          backgroundColor: "#c00"
        }}
      >
        THIS ITEM CANNOT BE DELETED
      </Modal.Header>
      <Modal.Actions>
        <Button onClick={() => setCannotDeleteInfo(false)}>OK</Button>
      </Modal.Actions>
    </Modal>
  ];
};

useDeleteItem.propTypes = {
  callGetAll: propTypes.func,
  currentModule: propTypes.string.isRequired,
  activePaginationPage: propTypes.number.isRequired,
  handlePageChange: propTypes.func,
  lenghtOfList: propTypes.number.isRequired,
  archivedFilter: propTypes.bool.isRequired
};
export default useDeleteItem;
